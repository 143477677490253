<header id="side" class="header-home">
  <app-navbar class="fixed" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}"></app-navbar>
  <ng-container *ngIf="!isSticky">
      <div class="container center-box mt-5">

          <app-engine [payLater]="paylater"  [color]="'#fff'" (offsetHeight)="getHeight()"></app-engine>
      </div>
  </ng-container>
</header>
<section class="video-header">
  <ng-container>
      <app-home-video-main-section [images]="images" (finishedLoading)="hasLoadedMainSec = $event" [offsetHeight]="elementHeight">
      </app-home-video-main-section>
  </ng-container>
</section>
<!-- <app-important-links></app-important-links> -->
<app-footer></app-footer>
