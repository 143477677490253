import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-room-details-v2',
  templateUrl: './room-details-v2.component.html',
  styleUrls: ['./room-details-v2.component.css']
})
export class RoomDetailsV2Component implements OnInit {
  roomDetails: any;
  roomId = 1;
  paramsPage: any;
  roomIamgesOptions: OwlOptions = {
    dots: true,
    autoplay: true,
    loop: true,
    mouseDrag: true,
    nav: false,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  imagesSilder: any;
  roomsImages: any;


  linked() {
    throw new Error('Method not implemented.');
  }
  cop() {
    throw new Error('Method not implemented.');
  }
  hotelDetails: any;
  copied: any;

  constructor(private hotelService: HotelsApisService, private route: ActivatedRoute) {
    this.route.paramMap.subscribe({
      next: (res: any) => {
        this.paramsPage = res.params
      }
    })
  }

  ngOnInit(): void {
    this.getRoomData()
  }
  getRoomData() {
    const roomData = {
      room_id: this.paramsPage?.roomCode,
      hotel_code: this.paramsPage?.hotelCode
    }
    this.hotelService.roomDetailsApi(roomData).subscribe(
      (roomData: any) => {
        console.log(roomData);
        this.roomDetails = roomData.data.HotelRoomInfo;
        this.hotelDetails = roomData.data.HotelDetail;
        this.roomsImages = this.roomDetails.room_images
        this.getSildersImages()
      },
      (err) => {
        console.log(err);
      }
    );

    
  }
  changeRoomName() {
    // if()
    const newName = this.roomDetails?.room_description_name.toString().replace(' - Booking.com rate', '');
    const roomArrayName = newName?.split('- ');
    return roomArrayName;
  }
  getSildersImages() {
    var imagesLenght = this.roomsImages.length;
    const startItem = (this.currentPage - 1) * 6;
    const endItem = Math.min(startItem + 6, imagesLenght);
    this.imagesSilder = this.roomsImages.slice(startItem, endItem);
  }
  pageChanged(event: any) {
    this.currentPage = +event
    this.getSildersImages()
  }
  currentPage = 1
}
