<!-- ........................................................ Display airport ............................ -->
<div class="navBotoom" *ngIf="airport">
    <app-navbar></app-navbar>
</div>
<div class="container" *ngIf="airport">
    <div class="row g-0">
        <div class="col-12 g-0 py-4" style=" margin: 25px 0px 0px 10px">
            <h1 class="fao_mobile_title_large">{{ airport.name }} in {{ airport.city_name}}</h1>
            <small class="location"><a class="location-btn mt-2" [href]=location target="blank">location</a></small>
        </div>
        <h1 class="flight">Cheap Flight Tickets</h1>
        <div class="row g-0 mainRow">
            <div class="col-8 ">
                <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let item of images; index as i ">
                        <div class="picsum-img-wrapper">
                            <img src="{{item}}" width="100%" height="430" style="border-radius: 12px"
                                 alt="Random second slide"
                                 alt="{{ airport.name }}" title="{{ airport.name }}"/>
                        </div>
                        <div class="carousel-caption"></div>
                    </ng-template>
                </ngb-carousel>
            </div>
            <div class="col-lg-4 px-5 ">
                <div class="portfolio-info">
                    <h3>{{airport.name}} Detailes</h3>
                    <ul>
                        <li class="li-description"><strong class="st-description">Airport :</strong><span
                                class="details-airport">{{ airport.name }}</span></li>
                        <li class="li-description"><strong class="st-description">Address :</strong><span
                                class="details-airport">{{ airport.country_name}}</span></li>
                    </ul>
                </div>
                <div class="portfolio-description mx-2 py-1 readMore">
                    <h2>{{airport.name}} Info</h2>
                    <app-read-more [text]="about" maxLength="15" class="aboutText"></app-read-more>
                </div>
                <section>
                    <div class="col-lg-1 pt-4 order-lg-first mb-5">
                        <div class="sticky-content">
                            <div class="social-icons social-icons-colored social-icons-vertical">
                                <div class="icon-share my-5 mb-5" >
                                    <span class="social-label Font-Family">SHARE:</span>
                                    <a class="social-icon image-item" (click)="cop()" target="_blank">
                                        <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                                             src="./assets/newIcon/copy-blue-icon.svg" />
                                        <img *ngIf="copied" title="copied" class="copied" alt="copied"
                                             src="./assets/newIcon/copied.png" />
                                    </a>
                                    <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                                        <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                                      <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                                        <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                                      <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                                        <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
        <div class="container ">
            <div class="row g-0">
                <div class="col-7">
                    <app-show-rate [reviewData]="reviewData"></app-show-rate>
                    <app-add-reviews [keyId]="'airport_id'" (addReview)="setReview($event)" [cityId]="airportId"></app-add-reviews>
                </div>
                <div class="col-5 offerSlide mt-4">
                  <app-point-slider-offers></app-point-slider-offers>
                    <!--            ////////////////////////////////////////////////////////////////////////////////-->
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class=" col-7 handel-pos mt-4 pt-0">
                    <button class="fao_Show_btn_large" (click)="showReviews()" *ngIf="!showRev">Show All Reviews</button>
                    <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="showRev">Hide All Reviews</button>
                </div>
            </div>
        
        <div class="all-reviews">
            <div class="row">
                <owl-carousel-o *ngIf="showRev" [options]="customOptions"
                                class="d-flex justify-content-center mt-5">
                    <ng-container *ngFor='let item of reviewData.reviews ; let i = index'>
                        <ng-container>
                            <ng-container>
                                <ng-template carouselSlide>
                                    <app-share-reviews [data]="item" [image]="item.main_image"></app-share-reviews>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>

        </div>

    <!--            ////////////////////////////////////////////////////////////////////////////////-->
    <div class="row pt-5">
        <div class=" py-5" *ngIf="relatedAirPorts">
            <h2 id="nearst" class="fao_mobile_title_large text-center " >Other Airports</h2>
            <div style="
              background-color: #000000;
              height: 3px;
              width: 100px;
              margin: auto;
            "></div>
        </div>
        <div class="row" *ngIf="relatedAirPorts">
            <ng-container *ngFor="let item of relatedAirPorts; let i = index">
                <div class="col-4 pt-4" *ngIf="i < 3">
                    <div>
                        <div class="box-side">

                            <div class="thing mainRow">
                                <a [routerLink]="['/airport-description/','item.code']"
                                   (click)="refresh(item.code)">
                                    <img src="{{item.main_image}}" width="100%" height="230px"
                                         style=" border-radius: 6px"
                                         title="{{ item.title_tag }}" alt="{{ item.title_tag }}"/>
                                </a>
                                <h6 class="text-center fao_mobile_title_meduim mt-2 text-dark"  >
                                    <a style="text-decoration: none; color:#000000" [routerLink]="['/airport-description/','item.code']"
                                       (click)="refresh(item.code)">
                                        {{item.name}}
                                    </a>
                                </h6>
                            </div>
                            <div style="
                background-color: #ddd4d4;
                height: 1px;
                width: 95%;
                margin: auto;
              "></div>
                            <div class="col-12 g-0 ">
                                <p class="fao_mobile_prgraph">
                                    {{item.description | slice:0:100 }} ....
                                </p>
                            </div>
                            <div class="center_div">
                                <button class="fao_des_btn_med mb-3" [routerLink]="['/airport-description/',item.code]"
                                        (click)="refresh(item.code)">
                                    MORE DETAILS
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
</div>
<div class="footerTop" *ngIf="airport">
    <app-footer></app-footer>
</div>
