import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {RoundTripCalenderDesktopDialogComponent} from '../round-trip-calender-desktop-dialog/round-trip-calender-desktop-dialog.component';
import {TravellersClassesDesktopDialogComponent} from '../travellers-classes-desktop-dialog/travellers-classes-desktop-dialog.component';
import {OneWayCalenderDesktopDialogComponent} from '../one-way-calender-desktop-dialog/one-way-calender-desktop-dialog.component';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';

@Component({
  selector: 'app-multi-city-form',
  templateUrl: './multi-city-form.component.html',
  styleUrls: ['./multi-city-form.component.css']
})

export class MultiCityFormComponent implements OnInit {
  @Output() applyModificationEvent = new EventEmitter();
  @Output() searchInputClose = new EventEmitter();
  @Output() offsetHeight = new EventEmitter();
  @Input() color;
  @Input() payLater;
  @Input() className: any ;
  @Input() classTrip = false;

  totalTravellers: any = '1';
  flights: any = Array(2);
  travellers: any = 1;
  travellersList: any;
  adult: any = '1';
  child: any = '0';
  seat: any = '0';
  lap: any = '0';
  departureDates: any = [];
  origins: any;
  originsCode: any;
  destinations: any;
  destinationsCode = [];
  loading = false;
  errors = false;
  errorMessage = 'City or Airport, Date of Departing, and Returning are required';
  datesPrices: any;
  dialogRef: any;
  directFlight: any;
  stops: any;
  airline: any;
  originCodes: string;
  originCodesLegnth:number;
  destinationCodes: string;
  destinationCodesLegnth:number;
  departDates: string;
  flightMax = false;
  searchRequest: any;
  destination: any;
  departureDate: any;
  origin: any;

  destinationApi: string;
  destinationCodeApi: string;
  originApi: string;
  originCoeApi: string;
  dateApi: string;
  nextOrigin: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,

  ) {
  }

  searchClose() {
    this.searchInputClose.emit('close daigram');
  }

  ngOnInit(): void {
   if(this.localStorage.getItem('MultiCity')!=undefined){
    this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequestMultiCity'));
    // this.loadOldSearch(this.searchRequest)
   }

    // this.loadOldSearch(this.searchRequest);

    if (typeof window !== 'undefined') {
      this.searchRequest = this.route.snapshot.queryParams;
      if (Object.keys(this.searchRequest).length > 0) {
      }
    }
    // if (this.localStorage.getItem('searchRequestOffers') != undefined) {
    //   this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequestOffers'));
    //   this.loadOldSearch(this.searchRequest);
    // }
  }

  openTravellersDialog(data: any) {
    if (data) {
      data.className ? this.className = data.className : this.className = 'Economy';
      data.totalTravellers ? this.totalTravellers = data.totalTravellers : this.totalTravellers = '1';
      data.adult ? this.adult = data.adult : this.adult = '1';
      data.child ? this.child = data.child : this.child = '0';
      data.lap ? this.lap = data.lap : this.lap = '0';
      data.seat ? this.seat = data.seat : this.seat = '0';
    }
    localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
  }

  addFlight(): void {
    if (this.flights.length < 5) {
      this.flights = new Array((this.flights.length + 1));
      this.setOrigin(this.nextOrigin,this.flights.length-1);
      
      this.flightMax = false;
    } else {
      this.flightMax = true;
    }
    this.offsetHeight.emit();
  }

  deleteFlight(index): void {
    this.flights.splice(index, 1);
    this.origins.pop();
    this.originsCode.pop()
    this.originApi = this.origins.join(',');
    this.originCoeApi = this.originsCode.join(',');
    if (this.flights.length <= 5) {
      this.flightMax = false;
    }
  }

  setOrigin(e, index) {
    if (!this.origins) {
      this.origins = new Array(0);
      this.originsCode = new Array(0);
    }
    this.origins[index] = e;
    this.originApi = this.origins.join(',');
    if (e.length > 3) {
      this.originsCode.push(e.slice(0, 3));
    }

      this.originCoeApi = this.originsCode.join(',');
      this.originCodesLegnth=this.origins.length
  }

  setDestination(e , index) {
    if (!this.destinations) {
      this.destinations = new Array(0);
      this.destinationsCode = new Array(0);
    }
    this.destinations[index] = e;
    this.destinationApi = this.destinations.join(',');
    if (e.length > 3) {
      this.destinationsCode.push(e.slice(0, 3));
    }
    this.destinationCodeApi = this.destinationsCode.join(',');
    this.destinationCodesLegnth=this.destinations.length
    this.nextOrigin=e
    if(index+1<this.flights.length){

      this.setOrigin(e , index+1)
    }
  }

  setDirectFlight(status): void {
    this.directFlight = status;
    if (this.directFlight === true) {
      this.stops = '0';
    } else {
      this.stops = null;
    }
  }

  setAirLine(airline): void {
    this.airline = airline;
  }

  openDesktopCalender(index): void {
    this.dialogRef = this.matDialog.open(OneWayCalenderDesktopDialogComponent, {
      panelClass: 'roundTripCalenderDesktopDialog',
      maxWidth: '35vw',
      data: {
        origins: this.origins ? this.origins.slice(0, 3) : undefined,
        destinations: this.destinations ? this.destinations.slice(0, 3) : undefined,
        departDates: this.departureDates ? this.departureDates : undefined,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (!this.departureDates) {
          this.departureDates = new Array(0);
        }
        const date = this.datePipe.transform(data.departureDate, 'yyyy-MM-dd');
        this.departureDates[index] = date;
        this.dateApi = this.departureDates.join(',');
      }

    });
    this.departDates = this.departureDates?.toString();

  }

  loadOldSearch(searchRequest:any) {
    // const searchRequest = JSON.parse(localStorage.getItem('searchRequest'));
    if (searchRequest) {
      this.travellers = searchRequest.travellers;
      if (searchRequest.adult) {
        this.adult = searchRequest.adult.currentValue;
      }
      if (searchRequest.child) {
        this.child = searchRequest.child.currentValue;
      }
      if (searchRequest.lap) {
        this.lap = searchRequest.lap.currentValue;
      }
      if (searchRequest.seat) {
        this.seat = searchRequest.seat.currentValue;
      }
      this.className = searchRequest.class;
      
      this.originCodes = searchRequest.origin;
      for (let i = 0; i < searchRequest.fullOrigin.length; i++) {
        this.origins[i] = searchRequest.fullOrigin[i];
        this.setOrigin(this.origins[i],i)
        this.destinations[i] = searchRequest.fullDestination;
        this.setDestination(this.destination[i],i)
      }
      
      this.destinationsCode = searchRequest.destination;

      this.stops = searchRequest.stops;

      this.airline = searchRequest.airlines;

    }
  }

  initRequest() {
    // if (!this.travellersList) {
      return {
        trip_type: 'OpenJaw',
        travellers: this.travellers,
        class: this.className,
        departure_date: this.dateApi,
        fullOrigin: this.originApi,
        origin: this.originCoeApi,
        fullDestination: this.destinationApi,
        destination: this.destinationCodeApi,
        stops: this.stops,
        airlines: this.airline,
        adult: this.adult,
        child: this.child,
        lap: this.lap,
        seat: this.seat,
        payLater:this.payLater
      };
    // } else {
    //   return {
    //     trip_type: 'OpenJaw',
    //     travellers: this.travellers,
    //     adult: this.travellersList[0].currentValue,
    //     child: this.travellersList[1].currentValue,
    //     lap: this.travellersList[2].currentValue,
    //     seat: this.travellersList[3].currentValue,
    //     class: this.className,
    //     departure_date: this.dateApi,
    //     fullOrigin: this.originApi,
    //     origin: this.originCoeApi,
    //     fullDestination: this.destinationApi,
    //     destination: this.destinationCodeApi,
    //     stops: this.stops,
    //     airlines: this.airline,
    //   };
    // }
  }

  appendToRoute() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.initRequest(),
      queryParamsHandling: 'merge',
      skipLocationChange: true
    }).then(() => {
      this.applyModificationEvent.emit(this.route.snapshot.queryParams);
    });
  }

  submit() {


    if (!this.travellers || !this.className || !this.departDates || !this.originsCode || !this.destinationsCode) {
      this.errors = true;
    } else if(this.payLater!=true) {
      this.appendToRoute();
      localStorage.setItem('searchRequestMultiCity', JSON.stringify(this.initRequest()));
      this.router.navigate(['flights'], {queryParams: this.initRequest()});
    }
    else if( this.payLater==true ){
      this.appendToRoute();
      localStorage.setItem('searchRequestMultiCityPayLater', JSON.stringify(this.initRequest()));
      this.router.navigate(['flights'], {queryParams: this.initRequest()});
    }
  }
}
