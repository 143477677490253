import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ApisService} from 'src/app/services/apis.service';
import {Router, NavigationExtras} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit {
  token: string;
  upcoming: any;
  complete: any;
  @Output() ticketView = new EventEmitter();

  constructor(
    private apis: ApisService,
    private router: Router,
    private cookie: CookieService,
    private localStorage: LocalStorageService
  ) {
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
  }

  selectedItem = 'tab1';

  ngOnInit() {
    if (this.token) {
      this.apis.upcomingBooking(this.token).subscribe((data: any) => {
        this.upcoming = data.data;
        this.localStorage.setItem('upcomingBookings', JSON.stringify(this.upcoming));
      }, (err: any) => {
      });

      this.apis.completedBooking(this.token).subscribe((data: any) => {
        this.complete = data.data;
        this.localStorage.setItem('completedBookings', JSON.stringify(this.complete));
      }, (err: any) => {
        console.log(err);
      });

    } else {
      this.router.navigate(['/sign-in']);
    }
  }

  listClick(event, newValue) {
    this.selectedItem = newValue;
  }
}

