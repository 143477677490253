import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ApisService } from "../../../services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { LocalStorageService } from "src/app/services/webApis/local-storage.service";

@Component({
  selector: "app-edit-member",
  templateUrl: "./edit-member.component.html",
  styleUrls: ["./edit-member.component.css"],
})
export class EditMemberComponent implements OnInit {
  @Input() id: any;
  @Input() familyMembers: any;
  @Output() closeForm = new EventEmitter();
  @Output() reloadFamilyMember = new EventEmitter();
  token: any;
  ImagePath: any;
  name: any;
  memberForm: any;
  countries: any;
  personalCountries: any;
  errorKeys: any;
  message: any;
  loading: any = false;
  relations: any;
  genders: any = [{ name: "Female" }, { name: "Male" }];
  spinner: any = false;
  filteredPersonalCountries: Observable<string[]>;
  filteredPersonalNationalities: Observable<string[]>;
  filteredGender: Observable<string[]>;
  filteredFamilyRelation: Observable<string[]>;
  familyRelations: any;
  personalNationalities: any;
  right: boolean = false;
  imageAlt: string;
  maleImage: any = "";
  image: any;
  fileToUpload: File = null;
  fileValidation: any;
  loadingSpinner: any;

  constructor(
    private apis: ApisService,
    private cookie: CookieService,
    private router: Router,
    public datePipe: DatePipe,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.memberForm = this.createComposeForm();
    this.imageAlt = "image";
    this.image = "/assets/newIcon/male.png";
  }

  carouselOptions: OwlOptions = {
    navText: [
      "<i class='arrow arrowNext fa fa-chevron-left'></i>",
      "<i class='arrow arrowPrev fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 },
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: false,
    merge: true,
  };

  ngOnInit(): void {
    this.getCountries();
    this.token = this.localStorage.getItem("flyallover_token");
    this.getFamilyRelation();
    this.getAccountInfo();
    this.info();
    this.gendersListener();
  }
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => {
        this.countries = data;
        this.personalCountries = data;
        this.personalNationalities = data;
        this.filteredPersonalCountries =
          this.memberForm.controls.country_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPersonalCountries(value.toString()))
          );
        this.filteredPersonalNationalities =
          this.memberForm.controls.nationality_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPersonalNationalities(value.toString()))
          );
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getAccountInfo() {
    this.apis.getAccountInfo(this.token).subscribe(
      (data: any) => {
        this.name =
          data.data.first_name.toUpperCase() +
          " " +
          data.data.last_name.toUpperCase();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  info() {
    for (let member of this.familyMembers) {
      if (member.id == this.id) {
        this.memberForm.controls.first_name.value = member.first_name;
        this.memberForm.controls.middle_name.value = member.middle_name;
        this.memberForm.controls.last_name.value = member.last_name;
        this.memberForm.controls.country_id.value = member.country.name;
        this.memberForm.controls.nationality_id.value = member.nationality.name;
        this.memberForm.controls.phone.value = member.phone;
        this.memberForm.controls.gender.value = member.gender;
        this.memberForm.controls.family_relation_id.value =
          member.family_relation.name;
        this.memberForm.controls.image.value = member.image;
        this.ImagePath = member.image;
        // this.memberForm.controls.image.value = member.image;
        this.memberForm.controls.date_of_birth.value = member.date_of_birth;
        this.memberForm.controls.email.value = member.email;
      }
    }
  }
  getFamilyRelation() {
    this.apis.familyRelations(this.token).subscribe(
      (data: any) => {
        this.relations = data.data;
        this.familyRelations = data;

        this.filteredFamilyRelation =
          this.memberForm.controls.family_relation_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterFamilyRelation(value.toString()))
          );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  gendersListener() {
    this.filteredGender = this.memberForm.controls.gender.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterGenders(value.toString()))
    );
  }
  private _filterGenders(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.genders.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberForm.controls["gender"].setErrors({
        incorrect: true,
        someProp: "Gender Not Found Please Select Valid Gender",
      });
    } else {
      return this.genders.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  createComposeForm(): FormGroup {
    return new FormGroup({
      first_name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
      ]),
      middle_name: new FormControl("", [Validators.minLength(2)]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
      ]),
      country_id: new FormControl("", [Validators.required]),
      nationality_id: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.minLength(3)]),
      gender: new FormControl("", [Validators.required]),
      family_relation_id: new FormControl("", [Validators.required]),
      image: new FormControl(null),
      date_of_birth: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.minLength(3)]),
    });
  }
  getCountryObject(country): any {
    const index = this.countries.findIndex((obj) => obj.name === country);
    if (index > -1) {
      return this.countries[index];
    }
  }

  getFamilyObject(relation): any {
    const index = this.relations.findIndex((obj) => obj.name === relation);
    if (index > -1) {
      return this.relations[index];
    }
  }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  initRequest(): any {
    const formData: FormData = new FormData();
    formData.append("image", this.fileToUpload);
    formData.append("first_name", this.memberForm.value["first_name"]);
    formData.append("middle_name", this.memberForm.value["middle_name"]);
    formData.append("last_name", this.memberForm.value["last_name"]);
    formData.append(
      "country_id",
      this.getCountryObject(this.memberForm.value.country_id).id
    );
    formData.append(
      "nationality_id",
      this.getCountryObject(this.memberForm.value.nationality_id).id
    );
    formData.append("phone", this.memberForm.value["phone"]);
    formData.append("gender", this.memberForm.value["gender"]);
    formData.append(
      "family_relation_id",
      this.getFamilyObject(this.memberForm.value.family_relation_id).id
    );
    formData.append(
      "date_of_birth",
      this.convertDate(String(this.memberForm.value.date_of_birth))
    );
    formData.append("email", this.memberForm.value.email);
    return formData;
  }
  checkMail() {
    this.message = "";
    const mailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.memberForm.value.email.match(mailFormat)) {
      this.message = "";
      this.right = true;
    } else {
      this.message = "You have entered an invalid email address!";
      this.memberForm.controls["email"].setErrors({
        incorrect: true,
        someProp: "You have entered an invalid email address",
      });
    }
  }
  editMember() {
    this.loadingSpinner = true;
    this.apis
      .editFamilyMember(this.initRequest(), this.id, this.token)
      .subscribe(
        (data: any) => {
          this.reloadFamilyMember.emit();
        },
        (err) => {
          console.log(err);
          this.loadingSpinner = false;
        }
      );
  }
  convertDate(d) {
    const parts = d.split(" ");
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
  }
  onFileSelected(files: FileList): void {
    this.fileToUpload = files.item(0);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {
        this.image = event.target.result;
      };
      this.fileValidation = true;
    }
  }
  private _filterPersonalCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberForm.controls["country_id"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private _filterPersonalNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberForm.controls["nationality_id"].setErrors({
        incorrect: true,
        someProp: "Nationality Not Found Please Select Valid Country",
      });
    } else {
      return this.personalNationalities.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private _filterFamilyRelation(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.familyRelations.data.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberForm.controls["family_relation_id"].setErrors({
        incorrect: true,
        someProp: "Relation Not Found Please Select Valid Relation",
      });
    } else {
      return this.familyRelations.data.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
}
