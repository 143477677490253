<div class="tabs">
  <div class="col-sm-12 col-md-12 col-xl-10 col-5">
    <div class="head-tab">
      <div class="btn-tab-top" [ngClass]="{ active: isActive('pnr-created') }" (click)="openTab('pnr-created')">
        <div class="svg-span">
          <div class="">
            <span>Pnr Created</span>
          </div>
        </div>
      </div>
      <div class="btn-tab" [ngClass]="{ active: isActive('pnr-canceld') }" (click)="openTab('pnr-canceld')">
        <div class="svg-span">
          <div class="">

            <span>Pnr Canceld</span>
          </div>
        </div>
      </div>
      <div class="btn-tab-dwon" [ngClass]="{ active: isActive('payment-fail') }" (click)="openTab('payment-fail')">
        <div class="svg-span">
          <div class="">
            <span>Payment Fail</span>
          </div>
        </div>
      </div>
    <!--  <div class="btn-tab-dwon" [ngClass]="{ active: isActive('high-risk-score') }"
        (click)="openTab('high-risk-score')">
        <div class="svg-span">
          <div class="">
            <span>Hight Risk Score</span>
          </div>
        </div>
      </div>
       <div class="btn-tab-dwon" [ngClass]="{ active: isActive('pnr-error') }" (click)="openTab('pnr-error')">
        <div class="svg-span">
          <div class="">
            <span>Pnr Error</span>
          </div>
        </div>
      </div>
      <div class="btn-tab-dwon" [ngClass]="{ active: isActive('manual-review') }" (click)="openTab('manual-review')">
        <div class="svg-span">
          <div class="">
            <span>Manual Review</span>
          </div>
        </div>
      </div>
      <div class="btn-tab-dwon" [ngClass]="{ active: isActive('issue-ticket-error') }"
        (click)="openTab('issue-ticket-error')">
        <div class="svg-span">
          <div class="">
            <span>Issue Ticket Error</span>
          </div>
        </div>
      </div> -->
      <div class="btn-tab-dwon" [ngClass]="{ active: isActive('success') }" (click)="openTab('success')">
        <div class="svg-span">
          <div class="">
            <span>Success</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-0 body-tab">
    <div class="col-12  p-0">

      <div class="cards-ticket" *ngIf="ordersData">
        <div style="padding-bottom: 17px"  *ngFor="let item of ordersData"
        (click)="flightDetails(item.id)"
          >
          <app-order-pnr [item]="item"></app-order-pnr>

        </div>
      </div>
    </div>

  </div>
</div>
