import {Component, OnInit, ViewChild} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {SEOService} from '../../services/seo.service';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, merge, OperatorFunction} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-new-blog',
  templateUrl: './new-blog.component.html',
  styleUrls: ['./new-blog.component.css']
})
export class NewBlogComponent implements OnInit {
  model: any;
  word = '';
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  page: any = 0;
  pageSize = 10;
  collectionSize: number;
  data: any = [];
  latestNews: any;


  constructor(private apis: ApisService, private seoService: SEOService,) {
  }

  ngOnInit(): void {
    this.getpaginationblogs(this.page);
    this.seoUpdate();
    // this.getblogs();
  }

  // getblogs(): void{
  //   this.apis.getblogs().subscribe((data: any) => {
  //       this.latestNews = data.data;
  //     },
  //     (err: any) => {
  //     });
  // }
  getpaginationblogs(pageNumber: any) {
    if (typeof document !== 'undefined') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.apis.paginationblogs(pageNumber + 1).subscribe((data: any) => {
        this.data = data.data.data;
        this.collectionSize = data.data.total;
        this.pageSize = data.data.per_page;
        this.page = pageNumber ;
        console.log(this.data ,'this.BlogData')
        if (typeof document !== 'undefined') {
          localStorage.setItem('pageNumber', this.page);
        }
      },
      err => {
        console.log(err);
      });
  }

  seoUpdate() {
    this.seoService.updateTitle('Blog | Fllyallover');
    this.seoService.updateDescription('Flyallover\'s Blog | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/blog/header.png');
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.data
        : this.data.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  };

}
