<div class="search-container">
  <input type="text" class="form-control search-input" #input (click)="input.select()" [ngbTypeahead]="flightsSearch"
    (selectItem)="selectAirport($event)" [(ngModel)]="inputValue" (focus)="onFocus()" (blur)="onFocus()"
    [resultTemplate]="rt" placeholder="City ,Hotel , Airport or Landmark .." />
  <ng-template #rt let-r="result" let-t="term">
    <div class="search-result">
      <img [src]="r.icon" alt="icon" class="icon">
      <div class="text-container">
        <div class="name" [innerHTML]="highlight(r.name, t)"></div>
        <div class="description">{{ r.description }}</div>
      </div>
    </div>
  </ng-template>
</div>


<!-- <div style="position: relative">
  <input type="text" class="form-control" #input (click)="input.select()" [ngbTypeahead]="flightsSearch"
    (selectItem)="selectAirport($event)" [(ngModel)]="inputValue" (focus)="onFocus()" (blur)="onFocus()"  [resultTemplate]="rt" />

    <ng-template #rt let-r="result" let-t="term">
      <div class="d-flex align-items-center">
        <img [src]="r.icon" alt="icon" class="icon">
        <span [innerHTML]="highlight(r.name, t)"></span>
      </div>
    </ng-template>

  <ng-container *ngIf="!isFocused && !inputValue">
    <div class="placeHolder" (click)="input.select()">
      <div>
        <span class="star">*</span>
        <span>City ,hotel ,airport or landmark</span>
      </div>
      <div>
        <img alt="location" title="Destination" style="width: 24px;height: 24px;"
          src="/assets/images/icons/location-pin.svg">
      </div>
    </div>
  </ng-container>
</div> -->
