import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  ViewChild,
  LOCALE_ID,
  AfterViewChecked,
  AfterContentChecked
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, DOCUMENT, formatNumber} from '@angular/common';
import {ApisService} from 'src/app/services/apis.service';
import {LocalStorageService} from '../../services/webApis/local-storage.service';
import {SEOService} from '../../services/seo.service';
import {MatDialog} from '@angular/material/dialog';
import {AlternativeDatesComponent} from '../alternative-dates/alternative-dates.component';
import {FlightResultsSearchComponent} from '../flight-results-search/flight-results-search.component';
import {LabelType, Options} from 'ng5-slider';
import {ChangeContext} from '@angular-slider/ngx-slider';
import {log} from 'util';
import {analyzeAndValidateNgModules} from '@angular/compiler';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})

export class FiltersComponent implements OnInit, OnChanges {

  @Output() applyFiltersEvent = new EventEmitter();
  @Input() requestId: any;
  @Input() searchResults: any;
  airlineFlag: boolean = false;
  stopFlag: boolean = false;
  obj: any = [];
  time: any;
  airlines: any;
  airlineStops: any;
  moreFilters = false;
  show: false;
  filterValues;
  PriceSliderValue: any;
  PriceSliderHighValue: any;
  PriceSliderOptions: any;
  arrivalSliderValue: any;
  arrivalSliderHighValue: any;
  arrivalSliderOptions: any;
  durationTimeSliderValue: any;
  durationTimeSliderHighValue: any;
  durationTimeSliderOptions: any;
  connectionTimeSliderValue: any;
  connectionTimeSliderHighValue: any;
  connectionTimeSliderOptions: any;
  goingArrivalTimeSliderValue: any;
  goingArrivalTimeSliderOptions: any;
  goingArrivalTimeSliderHighValue: any;
  departureTime: any;
  departureTimeValue: any;
  departureTimeSliderHighValue: any;
  departureTimeSliderOptions: any;
  bagsNumber: any[] = [];
  selectedAirlines: any = {};
  selectedStopsAirlines: any = {};
  selectedBags: any = {};
  selectedStop: any = {};
  showTime: any;
  @Output() filterAlternativeDatesEvent = new EventEmitter();
  dialogRef: any;
  fullDest: any;
  min: any;
  max: any;
  maxd: any;
  mind: any;
  value: any = 3;
  highValue: any = 20;
  options: Options = {
    floor: 0,
    ceil: 24
  };
  returnTripMinValue: any;
  returnTripMaxValue: any;
  returnTripOption: any;
  returnArriveTripMinValue: any;
  returnArriveTripMaxValue: any;
  returnArriveTripOption: any;
  durationReturnTimeSliderValue: any;
  durationReturnTimeSliderHighValue: any;
  durationReturnTimeSliderOptions: any;
  display: boolean = true;
  stops = ['Non Stop', 'One Stop', '1+ Stops'];
  length: number = 5;
  entriesAirlines: any[] = [];
  tripType: string;
  selectedValue: string;
  maxPrice: any;
  minPrice: any;
  goingDepartTimeMin: any;
  goingDepartTimeMax: any;
  goingArriveTimeMax: any;
  goingArriveTimeMin: any;
  returnDepartTimeMin: any;
  returnDepartTimeMax: any;
  returnArriveTimeMin: any;
  returnArriveTimeMax: any;
  goingLayoverTimeMax: any;
  returnLayoverTimeMax: any;
  localFlterData: any;

  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private seoService: SEOService,
    private router: Router,
    private apis: ApisService,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.tripType = this.route.snapshot.queryParamMap.get('trip_type');
    this.localStorage.removeItem('filterFormData');
  }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      this.display = false;
    } else {
      this.display = true;
    }
    this.dataHandler();
  }

  ngOnChanges(changes) {

    if (changes?.searchResults?.currentValue) {
      this.getFilterValues();

      //       if(this.fullDest.offers == "false"){
      //   this.selectedAirlines['MS'] = 'MS';
      //   this.obj = { airlines: Object.values(this.selectedAirlines).join(',') };
      //   this.appendToRoute(this.obj);
      // }
    }
  }

  msToTime(value) {
    const h = Math.floor(value);
    const hh = h === 0 ? 12 : h < 10 ? `0${h}` : h < 13 ? h : h - 12;
    const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
    const aa = value < 12 ? 'AM' : 'PM';
    return `${hh}:${mm}${aa}`;
  }

  hoursFormat(value) {
    let h = Math.floor(value);
    h = h % 60;
    return `${h}` + '' + 'H';
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'am';
    }

    return value;
  }

  onChangeRange(rangeValue: any) {
    this.showTime = rangeValue.value;
  }

  setGoingLayover(e) {
    let h_max: any = e.value * 60;
    this.obj = {going_layover_time_max: h_max};
    this.goingLayoverTimeMax = h_max;
    this.appendToRoute(this.obj);
  }

  setReturnLayover(e) {
    let h_max: any = e.value * 60;
    this.obj = {return_layover_time_max: h_max};
    this.returnLayoverTimeMax = h_max;
    this.appendToRoute(this.obj);
  }
  loadingFilter = true
  getFilterValues() {
    this.apis.getFilterValues(this.requestId).subscribe((response: any) => {
      this.filterValues = response.data;
      this.localFlterData = JSON.parse(this.localStorage.getItem('filterFormData'));
      this.airlines = this.filterValues.airlines;
      this.entriesAirlines = Object.entries(this.airlines);

this.loadingFilter= false
      this.airlineStops = this.filterValues.stop_at;
      this.departureTime = this.filterValues.departureTime;
      this.durationTimeSlider();
      this.connectionTimeSlider();
      this.initPricesSlider();
      this.departTimeSlider();

      if (this.tripType != 'OneWay') {
        this.returnArriveTripTimeSlider();
        this.arrivalTimeSlider();
        this.returnTripTimeSlider();
        this.returnTripTimeSlider();
      }

      this.durationReturnTimeSlider();
      this.bags();
      this.min = this.filterValues.departureTime.min;
      this.max = this.filterValues.departureTime.max;
      this.mind = this.filterValues.durationTime.min / 60;
      this.maxd = this.filterValues.durationTime.max / 60;

    }, err => {
      this.loadingFilter= false
    });
  }

  initPricesSlider(data = this.filterValues.prices) {
    this.PriceSliderValue = this.localFlterData?.minPrice.value ? this.localFlterData?.minPrice.value : Math.floor(data?.min);
    this.PriceSliderHighValue = this.localFlterData?.maxPrice.value ? this.localFlterData?.maxPrice.value : Math.floor(data?.max);
    
    const t = this.filterValues.arrivalTime.max;
    const r = Number(t?.split(':')[0]) * 60 + Number(t?.split(':')[1]) * 1000;
    this.PriceSliderOptions = {
      floor: Math.floor(data?.min),
      ceil: Math.floor(data?.max),
      step: 1,
      draggableRange: true,
      translate: (value: number): string => {
        return ' $ ' + value;
      }
    };
  }

  durationReturnTimeSlider(data = this.filterValues.return_layover) {

    this.durationReturnTimeSliderValue = data?.min;
    this.durationReturnTimeSliderHighValue = this.localFlterData?.returnLayoverTimeMax.value ? this.localFlterData?.returnLayoverTimeMax.value / 60 : data.max / 60;
    this.durationReturnTimeSliderOptions = {
      floor: 0,
      ceil: Math.floor(data?.max / 60),
      showSelectionBar: true,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        let h = Math.floor(value);
        h = h % 60;
        return `${h}` + ' ' + 'H';
      }
    };
  }

  durationTimeSlider(data = this.filterValues.going_layover) {
    this.durationTimeSliderValue = data?.min;
    this.durationTimeSliderHighValue = this.localFlterData?.goingLayoverTimeMax.value ? this.localFlterData?.goingLayoverTimeMax.value / 60 : this.filterValues?.going_layover.max / 60;
    this.durationTimeSliderOptions = {
      floor: 0,
      ceil: Math.floor(data?.max / 60),
      showSelectionBar: true,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        let h = Math.floor(value);
        h = h % 60;
        return `${h}` + ' ' + 'H';
      }
    };
  }

  arrivalTimeSlider(data = this.filterValues.going_arrive_time) {
    const min = this.localFlterData?.goingArriveTimeMin.value ? this.splitNumberTime(this.localFlterData?.goingArriveTimeMin.value) : this.splitNumberTime(data.min);
    const max = this.localFlterData?.goingArriveTimeMax.value ? this.splitNumberTime(this.localFlterData?.goingArriveTimeMax.value) : this.splitNumberTime(data.max);
    this.goingArrivalTimeSliderValue = min;
    this.goingArrivalTimeSliderHighValue = max;
    this.goingArrivalTimeSliderOptions = {
      floor: this.splitNumberTime(data?.min),
      ceil: this.splitNumberTime(data?.max),
      step: 1 / 60,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        const h = Math.floor(value);
        const hh = h === 0 ? 12 : h < 10 ? `0${h}` : h < 13 ? h : h - 12;
        const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
        const aa = value < 12 ? 'AM' : 'PM';
        return `${hh}:${mm} ${aa}`;
      }
    };
  }

  splitNumberTime(kay: any) {
    const value = (Number(kay?.split(':')[0]) * 60 + Number(kay?.split(':')[1])) / 60;
    return value;
  }

  departTimeSlider(data = this.filterValues.going_depart_time) {
    const min = this.localFlterData?.goingDepartTimeMin.value ? this.localFlterData?.goingDepartTimeMin.value : data?.min;
    const max = this.localFlterData?.goingDepartTimeMax.value ? this.localFlterData?.goingDepartTimeMax.value : data?.max;
    this.departureTimeValue = this.splitNumberTime(min);
    this.departureTimeSliderHighValue = this.splitNumberTime(max);
    this.departureTimeSliderOptions = {
      floor: this.splitNumberTime(data?.min),
      ceil: this.splitNumberTime(data?.max),
      step: 1 / 60,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        const h = Math.floor(value);
        const hh = h === 0 ? 12 : h < 10 ? `0${h}` : h < 13 ? h : h - 12;
        const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
        const aa = value < 12 ? 'AM' : 'PM';
        return `${hh}:${mm} ${aa}`;
      }
    };
  }

  returnTripTimeSlider(data = this.filterValues.return_depart_time) {
    const min = this.localFlterData?.returnDepartTimeMin.value ? this.localFlterData?.returnDepartTimeMin.value : data?.min;
    const max = this.localFlterData?.returnDepartTimeMax.value ? this.localFlterData?.returnDepartTimeMax.value : data?.max;
    this.returnTripMinValue = this.splitNumberTime(min);
    this.returnTripMaxValue = this.splitNumberTime(max);
    this.returnTripOption = {
      floor: (Number(data?.min?.split(':')[0]) * 60 + Number(data?.min?.split(':')[1])) / 60,
      ceil: (Number(data?.max?.split(':')[0]) * 60 + Number(data?.max?.split(':')[1])) / 60,
      step: 1 / 60,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        const h = Math.floor(value);
        const hh = h === 0 ? 12 : h < 10 ? `0${h}` : h < 13 ? h : h - 12;
        const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
        const aa = value < 12 ? 'AM' : 'PM';
        return `${hh}:${mm} ${aa}`;
      }
    };
  }

  returnArriveTripTimeSlider(data = this.filterValues.return_arrive_time) {
    const min = this.localFlterData?.returnArriveTimeMin.value ? this.splitNumberTime(this.localFlterData?.returnArriveTimeMin.value) : this.splitNumberTime(data?.min);
    const max = this.localFlterData?.returnArriveTimeMax.value ? this.splitNumberTime(this.localFlterData?.returnArriveTimeMax.value) : this.splitNumberTime(data?.max);
    this.returnArriveTripMinValue = min;
    this.returnArriveTripMaxValue = max;
    this.returnArriveTripOption = {
      floor: this.splitNumberTime(data?.min),
      ceil: this.splitNumberTime(data?.max),
      step: 1 / 60,
      draggableRange: true,
      translate: (value: number, label: LabelType): string => {
        const h = Math.floor(value);
        const hh = h === 0 ? 12 : h < 10 ? `0${h}` : h < 13 ? h : h - 12;
        const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
        const aa = value < 12 ? 'AM' : 'PM';
        return `${hh}:${mm} ${aa}`;
      }
    };
  }

  convertToTimeFormat(decimalTimeString) {
    let decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours: any = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes: any = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    let seconds: any = Math.round(decimalTime);
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return '' + hours + ':' + minutes;
  }

  setGoingDepartTime(e) {
    this.obj = {going_depart_time_min: this.convertToTimeFormat(e.value), going_depart_time_max: this.convertToTimeFormat(e.highValue)};
    this.goingDepartTimeMin = this.convertToTimeFormat(e.value);
    this.goingDepartTimeMax = this.convertToTimeFormat(e.highValue);
    this.appendToRoute(this.obj);
  }

  setArrivalTime(e) {
    this.obj = {going_arrive_time_min: this.convertToTimeFormat(e.value), going_arrive_time_max: this.convertToTimeFormat(e.highValue)};
    this.goingArriveTimeMin = this.convertToTimeFormat(e.value);
    this.goingArriveTimeMax = this.convertToTimeFormat(e.highValue);
    this.appendToRoute(this.obj);
  }

  setDepartArriveTime(e) {
    this.obj = {return_depart_time_min: this.convertToTimeFormat(e.value), return_depart_time_max: this.convertToTimeFormat(e.highValue)};
    this.returnDepartTimeMin = this.convertToTimeFormat(e.value);
    this.returnDepartTimeMax = this.convertToTimeFormat(e.highValue);
    this.appendToRoute(this.obj);
  }

  setReturnArriveTime(e) {
    this.obj = {return_arrive_time_min: this.convertToTimeFormat(e.value), return_arrive_time_max: this.convertToTimeFormat(e.highValue)};
    this.returnArriveTimeMin = this.convertToTimeFormat(e.value);
    this.returnArriveTimeMax = this.convertToTimeFormat(e.highValue);
    this.appendToRoute(this.obj);
  }

  connectionTimeSlider(data = this.filterValues.connectionTime) {
    this.connectionTimeSliderValue = data?.min;
    this.connectionTimeSliderHighValue = data?.max;
    this.connectionTimeSliderOptions = {
      floor: data?.min,
      ceil: data?.max,
      draggableRange: true,
    };
  }

  bags(data = this.filterValues.bags) {
    this.bagsNumber = data;

  }

  setBagsNumber(e, event) {
    if (event.checked) {
      this.selectedBags[e] = e;
      
      this.obj = {baggage_pieces: Object.values(this.selectedBags).join(',')};
      this.appendToRoute(this.obj);
    } else {
      delete this.selectedBags[e];
      this.obj = {baggage_pieces: Object.values(this.selectedBags).join(',')};
      this.appendToRoute(this.obj);
    }
  }

  appendToRoute(obj) {
    this.setFilterDataLocal();
    this.router.navigate([], {
      queryParams: obj,
      queryParamsHandling: 'merge',
      skipLocationChange: true
    }).then(() => {
      const request = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
      request.requestID = this.requestId;
      
      this.applyFiltersEvent.emit(request);
    });
  }

  
  setStopsValue(stopsval, event) {

    if (event.checked) {
      this.selectedStop[stopsval] = stopsval;
      this.obj = {stops: Object.values(this.selectedStop).join(',')};
      this.appendToRoute(this.obj);
    } else {
      delete this.selectedStop[stopsval];
      this.obj = {stops: Object.values(this.selectedStop).join(',')};
      this.appendToRoute(this.obj);
    }
  }

  setPriceValue(e) {
    this.obj = {max_price: e.highValue, min_price: e.value};
    this.maxPrice = e.highValue;
    this.minPrice = e.value;
    this.appendToRoute(this.obj);
  }

  setAirlineValue(event, airline) {
    if (event.checked) {
      this.selectedAirlines[airline] = airline;
      this.obj = {airlines: Object.values(this.selectedAirlines).join(',')};
      this.appendToRoute(this.obj);
    } else {
      delete this.selectedAirlines[airline];
      this.obj = {airlines: Object.values(this.selectedAirlines).join(',')};
      this.appendToRoute(this.obj);
    }
  }

  setStopsAtlineValue(airline, event) {
    if (event.checked) {
      this.selectedStopsAirlines[airline] = airline;
      this.obj = {stop_at: Object.values(this.selectedStopsAirlines).join(',')};
      this.appendToRoute(this.obj);
    } else {
      delete this.selectedStopsAirlines[airline];
      this.obj = {stop_at: Object.values(this.selectedStopsAirlines).join(',')};
      this.appendToRoute(this.obj);
    }
  }

  clearSort() {
    this.obj = '';
    // this.setSortTypeValue(this.obj);
    // location.reload();
    this.selectedValue = null;
    this.appendToRoute(this.obj);
  }

  setSortTypeValue(value) {
    this.obj = {sort_by: value};
    this.appendToRoute(this.obj);
  }


  filterByRefundable(value) {
    this.obj = {refundable: value};
    this.appendToRoute(this.obj);
  }

  filterByExchangeable(value) {
    this.obj = {exchangeable: value};
    this.appendToRoute(this.obj);
  }

  showFilters() {

  }

  public clearFilters() {
    location.reload();
  }

  openAlternativeDates(): void {
    this.dialogRef = this.matDialog.open(AlternativeDatesComponent, {
      panelClass: 'alternativeDateDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '50%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data && data.departureDate && data.returnDate) {
        this.filterAlternativeDatesEvent.emit(data);
      }
    });
  }

  dataHandler(): void {
    this.fullDest = this.route.snapshot.queryParams;
  }

  changeAirFlag(airlines) {
    this.airlineFlag = !this.airlineFlag;
    if (this.airlineFlag) {
      this.length = Object.entries(airlines).length;
    } else {
      this.length = 5;
    }
  }

  changeStopFlag() {
    this.stopFlag = !this.stopFlag;
  }

  setFilterDataLocal() {
    var searchOptions = {
      maxPrice: {
        value: this.maxPrice ? this.maxPrice : this.filterValues.prices.max,
      },
      minPrice: {
        value: this.minPrice ? this.minPrice : this.filterValues.prices.min,
      },
      goingDepartTimeMin: {
        value: this.goingDepartTimeMin ? this.goingDepartTimeMin : this.filterValues.going_depart_time.min,
      },
      goingDepartTimeMax: {
        value: this.goingDepartTimeMax ? this.goingDepartTimeMax : this.filterValues.going_depart_time.max,
      },
      goingArriveTimeMax: {
        value: this.goingArriveTimeMax ? this.goingArriveTimeMax : this.filterValues.going_arrive_time.max,
      },
      goingArriveTimeMin: {
        value: this.goingArriveTimeMin ? this.goingArriveTimeMin : this.filterValues.going_arrive_time.min,
      },
      returnDepartTimeMin: {
        value: this.returnDepartTimeMin ? this.returnDepartTimeMin : this.filterValues.return_depart_time.min,
      },
      returnDepartTimeMax: {
        value: this.returnDepartTimeMax ? this.returnDepartTimeMax : this.filterValues.return_depart_time.max,
      },
      returnArriveTimeMin: {
        value: this.returnArriveTimeMin ? this.returnArriveTimeMin : this.filterValues.return_arrive_time.min,
      },
      returnArriveTimeMax: {
        value: this.returnArriveTimeMax ? this.returnArriveTimeMax : this.filterValues.return_arrive_time.max,
      },
      goingLayoverTimeMax: {
        value: this.goingLayoverTimeMax ? this.goingLayoverTimeMax : this.filterValues.going_layover.max,
      },
      returnLayoverTimeMax: {
        value: this.returnLayoverTimeMax ? this.returnLayoverTimeMax : this.filterValues.return_layover.max,

      },

    };
    localStorage.setItem('filterFormData', JSON.stringify(searchOptions));
  }
}


