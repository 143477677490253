import { Component, OnInit,NgModule  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';






@Component({
  selector: 'app-city-details',
  templateUrl: './city-details.component.html',
  styleUrls: ['./city-details.component.css']
})

export class CityDetailsComponent implements OnInit {
  show:boolean=false;
  data: any = [];
  text:any;
  cityName:any;
  countryName:any;
  imageUrl:any;
  about:any;
  latitude:any;
  longitude:any;
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';
  isvalid: boolean = true;
  val: number = 0;
  id:any;
  related:any=[];
  popItemData:any;
  thingsData: any=[];
  thingsallData: any=[];
  relatedThingsData:any=[];
  count:any;



  constructor(private apis: ApisService, private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    // this.getPopularDestination();
    this.getthings();
  }

  refresh(id)
  {

     this.id=id;
     this.getPopularDestination();
    this.getthings();
     window.scrollTo(0, 0) ;


  }

  getPopularDestination() {
    this.related=[];
    this.apis.getPopularDest().subscribe((data: any) => {

        this.data = data.data;
        this.popItemData = this.data.find(obj => {
          return obj.id === this.id
        })
        this.cityName=this.popItemData.city.name;
        this.countryName=this.popItemData.country.name;

this.imageUrl=this.popItemData.image;
this.latitude=this.popItemData.city.lat;
this.longitude=this.popItemData.city.lng;
this.about=this.popItemData.about;
  for(let i=0;i<this.data.length;i++)
  { this.count=this.data[i].country.name;
    if(this.count==this.countryName&&this.data[i].id!=this.id)
    {
      // this.related.push(this.data[i]);
      if (this.related.includes(this.data[i]) === false&&this.data[i].id!=this.id) this.related.push(this.data[i]);

  }}
  if(this.related.length<20)
  {for(let i=0;i<this.data.length;i++)
    // this.related.push(this.data[i]);
    if (this.related.includes(this.data[i]) === false&&this.data[i].id!=this.id) this.related.push(this.data[i]);

  }
  // this.image0=this.related[0].image;
  // this.image1=this.related[1].image;
  // this.image2=this.related[2].image;
      },
      err => {
        console.log(err);
      });
      this.getthings();
  }
  getthings()
  {

    this.apis.getThingsToDo().subscribe((data: any) => {
      this.thingsallData=data.data;
      this.relatedThingsData=this.thingsallData;


      for(let i=0;i<this.thingsallData.length;i++)
      {this.count=this.thingsallData[i].country.name;

         if(this.count==this.popItemData.country.name)
 {
    this.relatedThingsData[i]=this.thingsallData[i];
}
      }

    });

  }


  /**
   * Animation on scroll
   */


}
