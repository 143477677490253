<app-navbar></app-navbar>
<div class="hotels-page">
  <div>
    <div class="hotel-header">
      <div class="row">
        <div class="d-flex p-0 col-8">
          <div style="width: 70%;">
            <h1 class="hotelName">
              {{hotelDetails.title }}</h1>
          </div>
          <div style="bottom: 1vh;width: 100%;margin-top: 1.5vh;">
            <!-- {{ rate }} -->
            <span class="rate rate{{ rate }}" style="margin-left:4vw ;"><i class="fas fa-star"></i><i
                class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                class="fas fa-star"></i></span>
          </div>


        </div>


        <div class="col-4 share ">
          <div class="sticky-content">
            <div class="social-icons social-icons-colored social-icons-vertical">
              <div class="icon-share" style="margin-top: 0; ">
                <span class="social-label ">SHARE:</span>
                <p class="social-icon image-item" target="_blank">
                  <img title="copy" class="copy" alt="copy" src="./assets/newIcon/copy-blue-icon.svg" />
                  <!-- <img  title="copied" class="copied" alt="copied"
                         src="./assets/newIcon/copied.png"/> -->
                </p>
                <p class="social-icon social-facebook" title="Facebook"><i class="icon-facebook-f"></i></p>
                <p class="social-icon social-twitter" title="Twitter"><i class="icon-twitter"></i></p>
                <p class="social-icon social-linkedin" title="Linkedin"><i class="icon-linkedin"></i></p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- <p>
      <a style="margin-left: 1vh;" href="http://maps.google.com/maps?q={{
          hotelDetails.address.latitude
        }},{{ hotelDetails.address.longitude }} " target="_blank">
        <i class="fas fa-map-marker-alt"></i>
        {{hotelDetails.address.city}} City - Show Map
      </a>
    </p> -->


    <div class="hotel-photos">
      <div class="photos" gallerize>
        <div class="photo" *ngFor="let img of hotelImages; let i = index" (click)="openGallery($event, i)"
          [ngClass]="{ count: i == 7 }" title="+{{
            hotelImages?.length > 8 ? hotelImages?.length - 8 : ''
          }}">
          <img title="Hotel Image" alt="Hotel" [src]="img.full_url " width="60%" />
        </div>
      </div>
    </div>
    <div class="hotelDescription">
      <h3>DESCRIPTON</h3>
      <p *ngIf="less" >{{hotelDetails.hotel.about_hotel | slice:0:1346}}....
        <button (click)="showMore()"  class="btn--transparent">show more</button></p>
        <p *ngIf="!less" >{{hotelDetails.hotel.about_hotel}}
          <button (click)="showLess()"  class="btn--transparent">show less</button></p>
    </div>
    <div class="col-12 d-flex">
      <div class="col-6 amenities">
        <h1>AMINITEIS</h1>
        <div class="col-12" *ngFor="let item of aminities|keyvalue" >
        <h4>{{item.key}}</h4>

          <div class="col-12 row"  >
            <div class="col-6"*ngFor="let ami of item.value">
            <p >- {{ami.amenity}}</p>
          </div>
        </div>
      </div>
          <!-- <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
        </div> -->

        <!-- <h4>CATEGORY NAME</h4>
        <div class="d-flex">
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
        </div>
        <h4>CATEGORY NAME</h4>
        <div class="d-flex">
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
        </div>
        <h4>CATEGORY NAME</h4>
        <div class="d-flex">
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
        </div>
        <h4>CATEGORY NAME</h4>
        <div class="d-flex">
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
          <div class="col-6">
            <p>- 1 Lounge(s) 6 Restaurant(s)</p>
          </div>
        </div> -->
      </div>
      <div class="col-6 map">
        <app-google-map [longitude]="hotelDetails.address.longitude" [Latitude]="hotelDetails.address.latitude"></app-google-map>
      </div>
    </div>
  </div>
</div>



<app-footer></app-footer>
