import {Component, Input, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {OwlOptions} from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-important-links',
  templateUrl: './important-links.component.html',
  styleUrls: ['./important-links.component.css']
})
export class ImportantLinksComponent implements OnInit {
  @Input() airLines: any;
  data: any = [];
  thingstodo: any = [];
  populardestinations: any = [];
  airlines: any = [];
  airports: any = [];
  cities: any = [];
  show: any;
  constructor(    private apis: ApisService,
  ) { }

  ngOnInit(): void {
    this.getFooterInfo();
    this.getPopularDestinations();
    this.getThingsToDo();
    this.getAirLines();
    this.getAirports();
  }
  getFooterInfo() {
    this.apis.FooterInfo().subscribe((data: any) => {
        this.data = data.data;
        this.cities = data.data[0].cities;
      },
      err => {
      });
  }
  getThingsToDo(){
    this.apis.getbHomeThingsToDo().subscribe((data: any) => {
        this.thingstodo = data.data;
        
      },
      err => {
      });
  }
  getPopularDestinations(){
    this.apis.getbHomePopularDestinations(147).subscribe((data: any) => {
        this.populardestinations = data.data;
      },
      err => {
      });
  }
  getAirLines(){
    this.apis.getHomeAirLines().subscribe((data: any) => {
      this.airLines = data.data;
    },
      err => {
      });
  }
  getAirports(){
    this.apis.getbHomeAirports().subscribe((data: any) => {
        this.airports = data.data;
      },
      err => {
      });
  }

  showLinkes() {
    this.show = !this.show;
  }

  toggleArrow(e) {
    e.target.childNodes[1].classList.toggle('hide');
   
  }
  removeArrow(e){
    e.target.childNodes[1].add('hide');
    const x = e.target.childNodes[1].parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling as HTMLElement;
    x.style.visibility = 'hidden';
  }
}
