import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ApisService} from 'src/app/services/apis.service';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  formSubmitted: boolean = false;

  // form

  resetPasswordForm: FormGroup;
  // validation messages
  validationMessages = {
    'firstName': [
      {type: 'required', message: 'First name is required'},
      // { type: 'pattern', message: 'First name cannot contains numbers' },
      {type: 'maxlength', message: 'First name cannot be more than 255 characters long'}
    ],
    'LastName': [
      {type: 'required', message: 'Last name is required'},
      {type: 'maxlength', message: 'Last name cannot be more than 255 characters long'}
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 6 characters long'},
    ],
    'confirmPassword': [
      {type: 'required', message: 'Confirm password is required'},
      {type: 'minlength', message: 'Password must be at least 6 characters long'},
    ]
  }

  // spinner
  roundSpinner: boolean = false;
  preLoading: boolean = true;
  // backend error
  passwordMissMatch: boolean = false;
  backendError: boolean = false;
  backednErrorMsg: string = '';
  sessionExpiration: boolean = false;
  apiToken = '';
  email = '';

  constructor(
    private seoService: SEOService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private centerApis: HelpCenterServices,
    private cookie: CookieService,
    private apis: ApisService) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {
      this.apiToken = paramMap.params.token;
      if (this.apiToken) {
        this.centerApis.passwordReset(this.apiToken).subscribe(
          (res: any) => {

            this.email = res.data.email;
            this.preLoading = false;
            this.createForm();
          },
          (err) => {
            this.preLoading = false;
            this.sessionExpiration = true;
          });
      }
    });

  }

  seoUpdate() {

    this.seoService.updateTitle('Reset Password');
    this.seoService.updateDescription('If you forget your password that easily to reset your password with flyallover to getback your account');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ]))
    })
  }

  onSubmitResetPasswordForm(formValue) {
    this.backendError = false;

    if (formValue.password !== formValue.confirmPassword) {
      this.passwordMissMatch = true;
    } else {
      this.passwordMissMatch = false;
      let resetPasswordData = {
        email: this.email,
        password: formValue.password,
        confirm_password: formValue.confirmPassword,
        token: this.apiToken
      }
      this.roundSpinner = true;
      this.centerApis.newPassword(resetPasswordData).subscribe(
        (res: any) => {
          this.roundSpinner = false;
          this.cookie.set('flyallover_token', res.data.access_token)
          this.router.navigate(['/profile'])
        },
        (err: any) => {
          this.roundSpinner = false;
          this.backednErrorMsg = err.error.error[Object.keys(err.error.error)[0]][0];
          this.backendError = true;
        }
      )
    }
  }

  resetError() {
    this.passwordMissMatch = false;
  }
}
