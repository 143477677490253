<app-navbar></app-navbar>


<div class="container" *ngIf="data">
    <div class="row mt-5">
      <h2 class="fao_mobile_title_large mb-4 font-weight-bold Font-Family-bold ">{{data.title}}</h2>
      <div class="col-12">
        <ngb-carousel>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper m-auto">
              <img src="{{data.image}}" width="100%" height="400" style="object-fit: cover;"
                class="rounded" alt="Random second slide" alt="{{ data.title}}"
                title="{{data.title }}" />
  
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ngb-carousel>
      </div>
  </div> 
  <div class="row">
      <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" >
        <div class="icon-box" >
          <!-- <h4><a href="">{{data.title}}</a></h4> -->
          <p [innerHTML]="data.description"> </p>
        </div>
      </div>
     
    </div>
  
  <div class="my-5">
  </div>
   <div class="container" *ngIf="data">
   
     
      <div class="row w-100 px-2 my-5 icon-box">
        <div class="col-12 px-2">
            <div class="item follow Font-Family">
              
              <ul class=" p-4 my-2 font-icon">
                <li><h6 class="font-span_1 ">Share Now  </h6></li>
                <li>
                  <a class="social-icon image-item" (click)="cop()" target="_blank">
                    <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                      src="./assets/newIcon/copy-blue-icon.svg" />
                    <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                      src="./assets/newIcon/copied.png" />
                  </a>
                </li>
                <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                      class="fab fa-facebook-f"></i></a>                </li>
                <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                      class="fab fa-twitter"></i></a></li>
                <!-- <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><i
                      class="fab fa-youtube"></i></a></li>
                <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><i
                      class="fab fa-instagram"></i></a></li> -->
                <li><a (click)="linked()" target="_blank" rel="nofollow"
                    href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
              </ul>
            </div>
        </div>
  
      </div>
   
  
  </div> 
</div>
  <app-footer></app-footer>
  