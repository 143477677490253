<div class="details">
  <div>
    <!-- <div class="col-12 flightDetailsData" >
        <div class="col-4">
       <span>
            <strong>flight no.</strong>
            <p>{{flightDetailsData.id}}</p>
        </span>
      </div>

      <div class="col-4">
        <span>
            <strong>terminal</strong>
            <p *ngIf="flightDetailsData.trip_type.toLowerCase() == 'return'">Round trip</p>
            <p *ngIf="flightDetailsData.trip_type.toLowerCase() != 'return'">{{flightDetailsData.trip_type}}</p>
        </span>
      </div>
      <div class="col-4">
        <span>
          <strong>pnr</strong>
          <p>{{flightDetailsData.pnr}}</p>
      </span>
    </div>
    </div> -->
    <div class="transport">
      <span style="font-size: 26px;">{{flightDetailsData.origin}}</span>
      <div class="line">
        <span class="airplane">&#9992;</span>
      </div>
      <span style="font-size: 26px;">{{flightDetailsData.destination}}</span>
    </div>
    <div class="contentData">
      <div class="col-10 mx-auto" style="width:58%">
        <span class="col">
          <strong>flight no.</strong>
          <p>{{flightDetailsData.id}}</p>
        </span>
        <span class="col">
          <strong>pnr</strong>
          <p>{{flightDetailsData.pnr}}</p>
        </span>
        <span class="col">
          <strong>terminal</strong>
          <p *ngIf="flightDetailsData.trip_type.toLowerCase() == 'return'">Round trip</p>
          <p *ngIf="flightDetailsData.trip_type.toLowerCase() != 'return'">{{flightDetailsData.trip_type}}</p>
        </span>
      </div>
    </div>
    <hr>
    <div>
      <div class="col-12 d-flex">
        <div class="d-flex col-12">
          <div class="col-3" style="width: 14%;">
            <strong style="font-size: 26px;color: #328ef1;text-transform: uppercase;">order status :</strong>
          </div>
          <div class="col-7">
            <p style="color: #f00;font-size: 26px;">{{flightDetailsData.order_status}}</p>
            <div *ngFor="let item of errorMessage" >
              <ng-container *ngFor="let error of item">
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="d-flex alert alert-danger p-2 col-12"  *ngFor="let err of error.Message">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">

                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>

                    </svg>
                    <p class="m-0 p-0" style="max-width: max-content;font-weight: 200;">{{err.content}}</p>
                    </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="row " style="margin-top: -3vh;">
      <div class="p-0 pt-4">
        <div>
          <div class="col-12 px-4" *ngFor="let item of itinerary.flights; let j = index">

            <ng-container *ngIf="itinerary.flights && itinerary.flights[j]" class="allEntrtainment">

              <app-flight-details-header [flightType]='"Depart"' [itinerary]=itinerary>
              </app-flight-details-header>

              <ng-container *ngFor="let flight of itinerary.flights[j] ,let counter = index;">
                <div *ngIf="itinerary.flights">
                  <div *ngIf="counter != 0">
                    <div class="line">Layover
                      <span>{{convertTime(itinerary.flights[j][counter]?.FlightLayoverTime)}}</span>
                    </div>
                  </div>
                </div>
                <app-flight-details-single-flight [flightType]='"Depart"' [itinerary]=itinerary [origin]=origin
                  [destination]=destination [flight]=flight [counter]="counter">
                </app-flight-details-single-flight>
              </ng-container>
              <div class="pl-2 col-12 d-flex">
                <small class="total-trip col-4" *ngIf="itinerary.flights[j][1]">Total Trip Duration :
                  {{convertTime(itinerary.flights[j][1]?.FlightLayoverTime+itinerary.flights[j][1]?.ElapsedTime+itinerary.flights[j][0]?.ElapsedTime)}}</small>
              </div>
              <div *ngIf="itinerary.flights[j][0]?.BrandFeatures"
                class="col-12 d-flex align-items-center 0ustify-content-center">
                <div class="d-flex position-relative" (mouseover)="dialog.classList.add('show')"
                  (mouseout)="dialog.classList.remove('show')">
                  <div *ngFor="let bgItem of itinerary.flights[j][0].BrandFeatures.BG">
                    <div *ngIf="bgItem.commercialName.indexOf('CHECKED BAG') >= 0"
                      style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">
                      <img width="20" height="20" style="margin-right: 4px;"
                        src="../../../assets/icons/icons/baggage.png">
                      <span class="otherFont flightType"><span
                          *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC')
                          - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                        Checked bags</span>
                    </div>
                    <div *ngIf="bgItem.commercialName.indexOf('CABIN BAGGAGE') >= 0"
                      style="padding-right: 10px;margin-right: 4px;">
                      <img width="20" height="20" style="margin-right: 4px;"
                        src="../../../assets/icons/icons/travel-baggage.png">
                      <span class="otherFont flightType"><span
                          *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC')
                          - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                        Capin baggage</span>
                    </div>
                  </div>
                  <div class="dialog hide" #dialog>
                    <div class="arrow-up"></div>
                    <span>
                      <span class="content-dialog"
                        *ngFor="let item of itinerary.flights[j][0].BrandFeatures.BG ;let i=index">
                        <img width="20" height="20" style="margin-right: 4px;" src="../../../assets/icons/bags.svg">
                        <small class="mr-auto">{{item.commercialName}}</small>
                        <small style="color: green;" *ngIf="item.application=='C'">CHARGABLE</small>
                        <small style="color: green;" *ngIf="item.application=='F'">&nbsp;FREE</small> <br>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="d-flex" *ngIf="itinerary.flights[j][0].allow">
                  <div><span class="otherFont"
                      style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">{{itinerary.flights[j][0].allow.Description1}}</span>
                  </div>
                  <div><span class="otherFont"
                      style="padding-right: 10px;margin-right: 4px;">{{itinerary.flights[j][0].allow.Description2}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <hr>

        </div>
      </div>
    </div>
    <div class="col-12 flightDetailsData">
      <div class="col-6" *ngFor="let item of flightDetailsData.family_members; let i = index ">
        <div>
          <img *ngIf="item.family_member.image" [src]="item.family_member.image" alt="Family Member">
          <img *ngIf="!item.family_member.image" [src]="image" alt="Family Member">
        </div>
        <div class="col-12" style="width: 84%;">
          <span *ngIf="item.family_member">
            <div class="d-flex">
              <div class="col-6">
                <p><strong>name : </strong></p>
              </div>
              <div class="col-6 passport-numbe-data">
                <p>{{item.family_member.first_name+' '}}{{item.family_member.middle_name+'
                  '}}{{item.family_member.last_name}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="col-6">
                <p><strong>Gender : </strong></p>
              </div>
              <div class="col-6 passport-numbe-data">
                <p>{{item.family_member.gender}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="col-6">
                <p><strong class="passport-number">Passport No : </strong></p>
              </div>
              <div class="col-6 passport-numbe-data">
                <p>{{item.family_member.passport.passport_number}}</p>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div> -->
  </div>
</div>
<hr>
<app-flight-details-drop-down [itinerary]="itinerary" [origin]="origin"
[destination]="destination">
</app-flight-details-drop-down>
<hr>
<div class="col-12 flightDetailsData">
  <div class="col-4" *ngFor="let item of flightDetailsData.family_members; let i = index ">
    <div>
      <img *ngIf="item.family_member.image" [src]="item.family_member.image" alt="Family Member">
      <img *ngIf="!item.family_member.image" [src]="image" alt="Family Member">
    </div>
    <div class="col-12" style="width: 84%;">
      <span *ngIf="item.family_member">
        <div class="d-flex">
          <div class="col-6">
            <p><strong>name : </strong></p>
          </div>
          <div class="col-6 passport-numbe-data">
            <p>{{item.family_member.first_name+' '}}{{item.family_member.middle_name+'
              '}}{{item.family_member.last_name}}</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6">
            <p><strong>Gender : </strong></p>
          </div>
          <div class="col-6 passport-numbe-data">
            <p>{{item.family_member.gender}}</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6">
            <p><strong class="passport-number">Passport No : </strong></p>
          </div>
          <div class="col-6 passport-numbe-data">
            <p>{{item.family_member.passport.passport_number}}</p>
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
