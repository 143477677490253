import {Component, OnInit} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-thanks',
  templateUrl: './review-flight-thanks.component.html',
  styleUrls: ['./review-flight-thanks.component.css']
})

export class ReviewFlightThanksComponent implements OnInit {

  invoice: any = true;

  constructor(
    private seoService: SEOService) {
  }

  ngOnInit() {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
