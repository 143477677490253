<div class="details">
  <div>
    <div class="row">
      <div class="p-0 pt-4">
        <div>
        <div class="d-flex">

          <div class="col-3"></div>
                 <div class="d-flex justify-content-end col-9" >
              <div [ngClass]="(brand.key=='BG')? 'd-none':''"  style="margin-right: 1vw;" *ngFor="let brand of itinerary.flights[0][0].BrandFeatures | keyvalue">
                <div *ngIf="brand.key != 'BG'"
                (mouseover)="arrow_brands.classList.add('shoow');arrow_brands.classList.remove('hidden');brands.classList.add('shoow');brands.classList.remove('hidden')"
                (mouseout)="arrow_brands.classList.remove('shoow');arrow_brands.classList.add('hidden');brands.classList.remove('shoow');brands.classList.add('hidden')">
                <ng-container *ngIf="brand.key != 'BG'">
                  <img width="20"  [src]="'assets/icons/brand-futures/'+brand.key+'.png'">
                </ng-container>
                <span #arrow_brands class="arrow-top hidden"></span>
              </div>
              <div class="brands-popup hidden" #brands >
                <span class="d-flex justify-content-between my-2 align-items-center" *ngFor="let item of brand.value ;let i=index">
                  <span class="d-flex align-items-center">
                    <img width="20" style="margin-right: 4px;" [src]="'assets/icons/brand-futures/'+item.commercialName+'.png'">
                    <small class="mr-auto">{{item.commercialName}}</small>
                  </span>
                  <small style="color: blue;" *ngIf="item.application=='C'">CHARGABLE</small>
                  <small style="color: green;" *ngIf="item.application=='F'">FREE</small>
                  <small style="color: red; text-transform: uppercase;" *ngIf="item.application=='D'">not offered</small>
                </span>
              </div>
            </div>
            </div>
          </div>
          <div class="col-12 px-4" *ngFor="let item of itinerary.flights; let j = index">
            <ng-container *ngIf="itinerary.flights && itinerary.flights[j]" class="allEntrtainment">
              <app-flight-details-header [flightType]='"Depart"' [itinerary]=itinerary>
              </app-flight-details-header>
              <ng-container *ngFor="let flight of itinerary.flights[j] ,let counter = index;">
                <div *ngIf="itinerary.flights">
                  <div *ngIf="counter != 0">
                    <div class="line">Layover
                      <span>{{convertTime(itinerary.flights[j][counter]?.FlightLayoverTime)}}</span></div>
                  </div>
                </div>
                <app-flight-details-single-flight [flightType]='"Depart"' [itinerary]=itinerary [origin]=origin
                  [destination]=destination [flight]=flight [counter]="counter">
                </app-flight-details-single-flight>
              </ng-container>
            <div class="pl-2 col-12 d-flex">
              <small class="total-trip col-4" *ngIf="itinerary.flights[j][1]">Total Trip Duration :
                {{convertTime(itinerary.flights[j][1]?.FlightLayoverTime+itinerary.flights[j][1]?.ElapsedTime+itinerary.flights[j][0]?.ElapsedTime)}}</small>
            </div>
            <div *ngIf="itinerary.flights[j][0]?.BrandFeatures" class="col-12 d-flex align-items-center justify-content-center mb-2">
              <div class="d-flex position-relative"(mouseover)="dialog.classList.add('show')" (mouseout)="dialog.classList.remove('show')">
                <div *ngFor="let bgItem of itinerary.flights[j][0].BrandFeatures.BG">
                  <div *ngIf="bgItem.commercialName.indexOf('CHECKED BAG') >= 0" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/baggage.png">
                    <span  class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Checked bags</span>
                  </div>
                  <div *ngIf="bgItem.commercialName.indexOf('CABIN BAGGAGE') >= 0" style="padding-right: 10px;margin-right: 4px;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/travel-baggage.png">
                    <span class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Capin baggage</span>
                  </div>
                </div>
                <div class="dialog hide" #dialog >
                  <div class="arrow-up"></div>
                  <span>
                    <span class="content-dialog" *ngFor="let item of itinerary.flights[j][0].BrandFeatures.BG ;let i=index">
                      <img width="20" height="20" style="margin-right: 4px;" src="../../../assets/icons/bags.svg">
                      <small class="mr-auto">{{item.commercialName}}</small>
                      <small style="color: green;" *ngIf="item.application=='C'">CHARGABLE</small>
                      <small style="color: green;" *ngIf="item.application=='F'">&nbsp;FREE</small> <br>
                    </span>
                  </span>
                </div>
              </div>
              <div class="d-flex" *ngIf="itinerary.flights[j][0].allow">
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">{{itinerary.flights[j][0].allow.Description1}}</span></div>
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;">{{itinerary.flights[j][0].allow.Description2}}</span></div>
              </div>
            </div>
          </ng-container>
          <hr *ngIf="itinerary.flights.length - 1 > j">
          </div>
          <!-- <div class="col-12 px-4 mb-4">
            <ng-container *ngIf="itinerary.flights && itinerary.flights[1]">
              <app-flight-details-header [flightType]='"Return"' [itinerary]=itinerary>
              </app-flight-details-header>
              <ng-container *ngFor="let flight of itinerary.flights[1] ,let counter = index;">
                <div *ngIf="itinerary.flights">
                  <div *ngIf="counter != 0">
                    <div class="line">Layover
                      <span>{{convertTime(itinerary.flights[1][counter]?.FlightLayoverTime)}}</span></div>
                  </div>
                </div>
                <app-flight-details-single-flight [flightType]='"Return"' [itinerary]=itinerary [origin]=origin
                  [destination]=destination [flight]=flight [counter]="counter">
                </app-flight-details-single-flight>
              </ng-container>
            <div class="pl-2 col-12 d-flex">
              <small class="total-trip" *ngIf="itinerary.flights[1]">Total Trip Duration :
                {{convertTime(itinerary.flights[1][1]?.FlightLayoverTime+itinerary.flights[1][1]?.ElapsedTime+itinerary.flights[1][0]?.ElapsedTime)}}</small>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="d-flex position-relative"(mouseover)="dialog.classList.add('show')" (mouseout)="dialog.classList.remove('show')">
                <div *ngFor="let bgItem of itinerary.flights[0][0].BrandFeatures.BG">
                  <div *ngIf="bgItem.commercialName.indexOf('CHECKED BAG') >= 0" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/baggage.png">
                    <span  class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Checked bags</span>
                  </div>
                  <div *ngIf="bgItem.commercialName.indexOf('CABIN BAGGAGE') >= 0" style="padding-right: 10px;margin-right: 4px;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/travel-baggage.png">
                    <span class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Capin baggage</span>
                  </div>
                </div>
                <div class="dialog hide" #dialog >
                  <div class="arrow-up"></div>
                  <span>
                    <span class="content-dialog" *ngFor="let item of itinerary.flights[1][0].BrandFeatures.BG ;let i=index">
                      <img width="20" height="20" style="margin-right: 4px;" src="../../../assets/icons/bags.svg">
                      <small class="mr-auto">{{item.commercialName}}</small>
                      <small style="color: green;" *ngIf="item.application=='C'">CHARGABLE</small>
                      <small style="color: green;" *ngIf="item.application=='F'">&nbsp;FREE</small> <br>
                    </span>
                  </span>
                </div>
              </div>
              <div class="d-flex" *ngIf="itinerary.flights[1][0].allow">
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">{{itinerary.flights[1][0].allow.Description1}}</span></div>
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;">{{itinerary.flights[1][0].allow.Description2}}</span></div>
              </div>
            </div>
          </ng-container>
          </div> -->
          <!-- <div class="col-12 px-4 mb-4">
            <ng-container *ngIf="itinerary.flights && itinerary.flights[2]">
              <app-flight-details-header [flightType]='"Return"' [itinerary]=itinerary>
              </app-flight-details-header>
              <ng-container *ngFor="let flight of itinerary.flights[2] ,let counter = index;">
                <div *ngIf="itinerary.flights">
                  <div *ngIf="counter != 0">
                    <div class="line">Layover
                      <span>{{convertTime(itinerary.flights[2][counter]?.FlightLayoverTime)}}</span></div>
                  </div>
                </div>
                <app-flight-details-single-flight [flightType]='"Return"' [itinerary]=itinerary [origin]=origin
                  [destination]=destination [flight]=flight [counter]="counter">
                </app-flight-details-single-flight>
              </ng-container>
            <div class="pl-2 col-12 d-flex">
              <small class="total-trip" *ngIf="itinerary.flights[2]">Total Trip Duration :
                {{convertTime(itinerary.flights[2][1]?.FlightLayoverTime+itinerary.flights[2][1]?.ElapsedTime+itinerary.flights[2][0]?.ElapsedTime)}}</small>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="d-flex position-relative"(mouseover)="dialog.classList.add('show')" (mouseout)="dialog.classList.remove('show')">
                <div *ngFor="let bgItem of itinerary.flights[0][0].BrandFeatures.BG">
                  <div *ngIf="bgItem.commercialName.indexOf('CHECKED BAG') >= 0" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/baggage.png">
                    <span  class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Checked bags</span>
                  </div>
                  <div *ngIf="bgItem.commercialName.indexOf('CABIN BAGGAGE') >= 0" style="padding-right: 10px;margin-right: 4px;">
                    <img width="20" height="20" style="margin-right: 4px;"  src="../../../assets/icons/icons/travel-baggage.png">
                    <span class="otherFont flightType"><span *ngIf="bgItem.commercialName.indexOf('PC') > 0">{{bgItem.commercialName.substring(bgItem.commercialName.indexOf('PC') - 1, bgItem.commercialName.indexOf('PC') )}}</span>
                      Capin baggage</span>
                  </div>
                </div>
                <div class="dialog hide" #dialog >
                  <div class="arrow-up"></div>
                  <span>
                    <span class="content-dialog" *ngFor="let item of itinerary.flights[2][0].BrandFeatures.BG ;let i=index">
                      <img width="20" height="20" style="margin-right: 4px;" src="../../../assets/icons/bags.svg">
                      <small class="mr-auto">{{item.commercialName}}</small>
                      <small style="color: green;" *ngIf="item.application=='C'">CHARGABLE</small>
                      <small style="color: green;" *ngIf="item.application=='F'">&nbsp;FREE</small> <br>
                    </span>
                  </span>
                </div>
              </div>
              <div class="d-flex" *ngIf="itinerary.flights[1][0].allow">
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;border-right: 1px solid #ddd;">{{itinerary.flights[1][0].allow.Description1}}</span></div>
                <div><span class="otherFont" style="padding-right: 10px;margin-right: 4px;">{{itinerary.flights[1][0].allow.Description2}}</span></div>
              </div>
            </div>
          </ng-container>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="col-12 p-0" *ngIf="itinerary.flights && itinerary.flights">
          <ng-container *ngFor="let flights of itinerary.flights ,let counter = index;">
            <div class="pt-4 px-4">
              <div class="p-2">
                <app-flight-details-header
                  [flightType]='counter == 0 ? "Depart" : "Return"'
                  [flights]=flights>
                </app-flight-details-header>
              </div>
                  <div class="px-4">
                    <div *ngIf="itinerary.flights">
                      <div *ngIf="counter != 0">
                        <div class="line">Layover <span>{{convertTime(flights.FlightLayoverTime)}}</span></div>
                      </div>
                    </div>
                    <app-flight-details-single-flight
                      [flightType]='counter == 0 ? "Depart" : "Return"'
                      [itinerary]=itinerary
                      [origin]=origin
                      [destination]=destination
                      [flight]=flights[counter]
                      [counter]="counter">
                    </app-flight-details-single-flight>
                  </div>
              <div class="p-2">
                <small class="total-trip">Total Trip Duration : {{convertTime(flights[1]?.FlightLayoverTime+itinerary.flights[0][1]?.ElapsedTime+itinerary.flights[0][0]?.ElapsedTime)}}</small>
              </div>
            </div>
            <hr *ngIf="counter == 0">
          </ng-container>
      </div> -->


<!--
    <div class="row">
      <div class="col-12 box-shad">
        <ng-container *ngIf="itinerary.flights && itinerary.flights[0]" class="allEntrtainment">
          <div class="flight-details-sections flight-details-depart-section">


            <app-flight-details-header
                [flightType]='"Depart"'
                [itinerary]=itinerary>
              </app-flight-details-header>
            <ng-container *ngFor="let flight of itinerary.flights[0] ,let counter = index;">
              <div *ngIf="itinerary.flights[1]">
                <div class="flight-details-layover" *ngIf="counter != 0">
                  <div class="flight-details-layover-div">
                    <span class="otherFont"> Layover : </span>
                    <span class="otherFont">{{itinerary.flights[0][counter]['DepartureAirport']}} - </span>
                   <span class="otherFont">{{convertTime(itinerary.flights[0][counter]?.FlightLayoverTime)}}</span>
                  </div>
                </div>
              </div>
              <app-flight-details-single-flight
                [flightType]='"Depart"'
                [itinerary]=itinerary
                [origin]=origin
                [destination]=destination
                [flight]=flight
                [counter]="counter">
              </app-flight-details-single-flight>
            </ng-container>
          </div>
        </ng-container>
       <div class="mt-3"><b class="durate otherFont">Total Trip Duration : {{convertTime(itinerary.flights[0][1]?.FlightLayoverTime+itinerary.flights[0][1]?.ElapsedTime+itinerary.flights[0][0]?.ElapsedTime)}}</b></div>
      </div>
      <div class="col-12 box-shad">
        <ng-container *ngIf="itinerary.flights && itinerary.flights[1]">
          <div class="flight-details-sections flight-details-depart-section">
            <!-- <app-flight-details-header
              [flightType]='"Return"'
              [itinerary]=itinerary>
            </app-flight-details-header>
            <ng-container *ngFor="let flight of itinerary.flights[1] ,let counter = index;">
              <div class="flight-details-layover" *ngIf="counter != 0">
                <div class="flight-details-layover-div">
                  <span class="otherFont"> Layover : </span>
                <span class="otherFont">{{itinerary.flights[1][counter]?.DepartureAirport}} - </span>
               <span class="otherFont">{{convertTime(itinerary.flights[1][counter]?.FlightLayoverTime)}}</span>
                </div>
              </div>
              <app-flight-details-single-flight
              [flightType]='"Return"'
                [itinerary]=itinerary
                [origin]=origin
                [destination]=destination
                [flight]=flight
                [counter]="counter">
              </app-flight-details-single-flight>
            </ng-container>
          </div>
        </ng-container>
         <div class="mt-3">
           <b class="durate otherFont">Total Trip Duration : {{convertTime(itinerary.flights[1][1]?.FlightLayoverTime+itinerary.flights[0][1]?.ElapsedTime+itinerary.flights[0][0]?.ElapsedTime)}}</b></div>
      </div>
    </div> -->
<!-- <ng-container *ngIf="!reviewFlight" >
    <button (click)="select(itinerary.TagID)" class="selectFlight btn mb-1">
    select flight
    </button>
    </ng-container> -->
