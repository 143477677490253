<!-- start navbar -->
<app-navbar class="login_navbar"></app-navbar>
<!-- end navbar -->

<!-- start page body -->
<div class="help-center-page" [ngClass]="{ hide: closedLogin }">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family">Login</h1>
    </div>
  </div>
  <div class="help-center-body">
    <!-- start contact-us -->
    <div class="container">
      <div class="contact-us">
        <div class="logo text-center">
          <img
            title="Login"
            alt="sign-up"
            src="/assets/images/home/coparess/logo-sign.png"
          />
        </div>
        <form
          [formGroup]="loginForm"
          (ngSubmit)="onSubmitLoginForm(loginForm.value)"
        >
          <div class="inside">
            <h2 class="text-center Font-Family">Welcome to FlyAllOver</h2>
            <div class="field" *ngIf="backendError">
              <h4 class="text-danger text-center Font-Family" >
                Invalid email or password!
              </h4>
            </div>
            <!-- Email -->
            <div class="field">
              <label
                for="email"
                class="Font-Family"
                [ngClass]="
                  loginForm.get('email').invalid &&
                  loginForm.get('email').touched &&
                  !backendError
                    ? 'text-danger'
                    : ''
                "
                >Email Address</label
              >
              <input
                id="email"
                type="email"
                formControlName="email"
                required
                [ngClass]="
                  loginForm.get('email').invalid &&
                  loginForm.get('email').touched &&
                  !backendError
                    ? 'has-error'
                    : 'no-error'
                "
              />
              <span *ngFor="let validation of validationMessages.email">
                <small
                  class="text-danger"
                  *ngIf="
                    loginForm.get('email').hasError(validation.type) &&
                    (loginForm.get('email').dirty ||
                      loginForm.get('email').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="field">
              <label
                for="mobile"
                class="Font-Family"
                [ngClass]="
                  loginForm.get('password').invalid &&
                  loginForm.get('password').touched
                    ? 'text-danger'
                    : ''
                "
                >Password</label
              >
              <input
                id="mobile"
                type="password"
                required
                formControlName="password"
                [ngClass]="
                  loginForm.get('password').invalid &&
                  loginForm.get('password').touched
                    ? 'has-error'
                    : 'no-error'
                "
              />
              <span *ngFor="let validation of validationMessages.password">
                <small
                  class="text-danger"
                  *ngIf="
                    loginForm.get('password').hasError(validation.type) &&
                    (loginForm.get('password').dirty ||
                      loginForm.get('password').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="box-accept mb-2">
              <div class="field row checkBox">
                <div class="checkbox-field">
                  <input
                    type="checkbox"
                    id="checkbox1"
                    formControlName="checkbox1"
                  />
                </div>
                <div class="col">
                  <label for="checkbox1" class="Font-Family mt-1">Remember me</label>
                  <span class="forget-password Font-Family" routerLink="/forget-password">
                    Forget password?</span
                  >
                </div>
              </div>
            </div>
            <div class="submit mt-3">
              <button
                [ngClass]="
                  loginForm.valid ? 'button active' : 'button disabled'
                "
                [disabled]="!loginForm.valid"
                type="submit"
              >
                LOGIN
                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
            <span class="Font-Family">Don't have an account? </span>
            <div class="submit">
              <button class="btn btn-sinUp" [routerLink]="['/sign-up']">
                SIGN UP
<!--                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>-->
              </button>
            </div>
            <div class="social-login">
              <h6 class="text Font-Family">Or Login with</h6>
              <div class="icons">
                <div class="google" (click)="signInWithGoogle()">
                  <img
                    title="Login With google"
                    alt="google"
                    src="./assets/images/help-center/google-logo.png"
                  />
                </div>
              </div>
<!--              <h3 class="redirect row">-->
<!--                <span>Don't have an account? </span>-->
<!--                <span class="link" [routerLink]="['/sign-up']"> Sign Up</span>-->
<!--              </h3>-->
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- end contact-us -->
  </div>
</div>
<!-- end page body -->

<!-- start footer -->
<app-footer class="login_footer"></app-footer>
<!-- end footer -->
