import {Component, Inject, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {CovidPopupComponent} from '../../../components/covid-popup/covid-popup.component';
import {MatDialog} from '@angular/material/dialog';
import { FlightDetailsDashboardComponent } from 'src/app/components/flight-details-dashboard/flight-details-dashboard.component';

@Component({
  selector: 'app-booking-dashboard',
  templateUrl: './booking-dashboard.component.html',
  styleUrls: ['./booking-dashboard.component.css']
})
export class BookingDashboardComponent implements OnInit {
  hideRules = false;
  details: boolean = false;
  itinerary: any;
  token: any;
  flights: any;
  flightData: any;
  closedStatus = true;
  travellersNum: number;
  recommendedPlaces: any;
  purpose: any;
  elemNumber;
  addOne:boolean=false
  addTwo:boolean=false
  visaData: any;
  isHealthRequirementsOpened: boolean = true;
  readMoreEntryHealthRequirements: boolean = false;
  readMoreCountryExperience: boolean = false;
  readMoreTransitRestrictions: boolean = false;
  readMoreEntryRequirements: boolean = false;
  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 2,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true,
    merge: true,
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    public dialog: MatDialog
  ) {
    this.recommended();
  }


  ngOnInit(): void {
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
    this.apis.completedBooking(this.token).subscribe((data: any) => {
        this.flights = data.data;
        console.log(data)
      
      },
      err => {
        console.log(err);
      });

  }
  // openDialog() {
  //   const dialogRef = this.dialog.open(CovidPopupComponent);

  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
  public closeCovidPopUp() {
    this.closedStatus = true;
  }

  toggleFlightDetails() {
    this.document.querySelector('.flightDetails.flightNumber').classList.toggle('hide');
  }

  // hoverable divs
  collapse_2(event) {
    event.target.closest('.services_icon').querySelector('.icons').classList.toggle('hide');
    event.target.closest('.services_icon').querySelector('.icon_rows').classList.toggle('show');

  }

  // hoverable divs
  hideShowDetails(event) {
    event.target.closest('.flights-details').classList.toggle('left-border-green');
    event.target.closest('.flights-details').querySelector('.flights').classList.toggle('hide');
    // (this.document.querySelector('.flights-details') as HTMLElement ).style.borderLeft = '3px solid #0A0';
  }

  hideShowFareRules(event) {
    event.target.closest('.flights-details').classList.toggle('left-border-blue');
    event.target.closest('.flights-details').querySelector('.fare-rules').classList.toggle('hide');
  }

  hideShowBaggage(event) {
    event.target.closest('.bag_price').querySelector('.baggage').classList.toggle('hide');
  }

  hideShowStops(event) {
    const item = event.target.closest('.items-container').querySelector('.stops-tooltip');
    if (item) {
      item.classList.toggle('hide');
    }
  }
  openFlighetDetailsDailog(details){
    const dialogRef=this.dialog.open(FlightDetailsDashboardComponent, {
      maxHeight: '90vh',
      minWidth: '60%',
      maxWidth:'59vw',
      data: {
        details: details,
        flights:this.flights,
        flightData:this.flightData
      },panelClass: 'custom-container'})
    
   }

  flightDetails(orderId) {
    this.apis.flightDetails(this.token, orderId).subscribe((data: any) => {
        this.flightData = data.data;
        this.travellersNum = +this.flightData.traveller_num;
        this.itinerary = JSON.parse(this.flightData.itinerary_details);
        this.details = true;
        this.getHealthRequirements(this.flightData.origin_country , this.flightData.destination_country);
        this.openFlighetDetailsDailog(this.itinerary);
      },
      err => {
        console.log(err);
      });
  }

  calcTotalFlightTime(flights) {
    let totalTime = 0;
    flights.forEach(function(flight) {
      if (Array.isArray(flight)) {
        flight.forEach(function(stop) {
          totalTime += stop.ElapsedTime;
          totalTime += stop.FlightLayoverTime;
        });
      } else {
        totalTime += flight.ElapsedTime;
        totalTime += flight.FlightLayoverTime;
      }
    });
    return (`${Math.floor(totalTime / 60)}h ${totalTime % 60}m`);
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}h`;
    }
    convertedMinutes = `${minutes % 60}m`;
    return convertedHours + ' ' + convertedMinutes;
  }

  counter(i: number) {
    return new Array(i);
  }

  recommended() {
    this.apis.recommendedPlaces().subscribe((data: any) => {
        this.recommendedPlaces = data.data.slice(0, 3);
      },
      err => {
        console.log(err);
      });

  }
  getHealthRequirements(origin, destination){
    this.purpose = 'T';
    this.apis.homeVisa(origin, destination, this.purpose).subscribe((response: any) => {
      this.visaData = response.data;
    });
  }
  getdataLegnth(legnth){
    if(legnth%3==1){
      this.addTwo=true;
    }
    else if(legnth%3==2){
      this.addOne=true
    }
    }
  click(e){
    if (this.elemNumber !=undefined) {
      document.getElementById(this.elemNumber).style.border="1px solid white "
    }
   // document.getElementById(e).style.visibility="visible"
  let elem = document.getElementById(e)
  elem.style.border="1px solid red"
  this.elemNumber = e
}
}
