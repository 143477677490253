<ng-template #rt let-r="result" let-t="term">
  <img
    title="location"
    alt="location"
    src="/assets/icons/flights.svg"
    style="width: 24px; height: 24px; margin-right: 8px"
  />
  <ngb-highlight ngStyle="highlight" ng [result]="r" [term]="t"></ngb-highlight>
</ng-template>
<input
  type="text"
  [resultTemplate]="rt"
  class="form-control"
  #input
  (click)="input.select()"
  [ngbTypeahead]="flightsSearch"
  (selectItem)="selectAirport($event)"
  [(ngModel)]="inputValue"
  placeHolder="From Where ?"
/>
<ng-container *ngIf="!isFocused && !inputValue">
  <div class="placeHolder" (click)="input.select()">
    <!-- <span>From Where ?</span> -->
    <div>
      <img
        title="From Where"
        alt="location"
        style="width: 24px; height: 17px"
        src="/assets/images/icons/location-pin.svg"
      />
    </div>
  </div>
</ng-container>
