<div class="pointer trvel-btn" >
  <div class="form-control d-flex justify-content-between align-items-center" (click)="openTravelMenu()">
    <div>
      <div *ngIf="totalTravellers == 1 && hotelStatus == false" class="d-flex" style="
    flex-direction: column;
"
      ><span class="num-travel text-holder-up"  style="
      font-weight: 900;
      font-size: 18px;
  ">{{ totalTravellers }}  </span><span class="text-holder-dow" style="
    font-size: 13px;    font-weight: 700;
"> Traveller </span></div
      >
      <div *ngIf="totalTravellers > 1 && hotelStatus == false" class="d-flex" style="
    flex-direction: column;
"
      ><span class="num-travel text-holder-up"  style="
      font-weight: 900;
      font-size: 18px;
  ">{{ totalTravellers }}  </span><span style="
    font-size: 13px;    font-weight: 700;
"> Travellers </span></div
      >
      <div *ngIf="hotelStatus" class="d-flex" style="
    flex-direction: column;
"
      ><span class="num-travel text-holder-up">{{ rooms }}  </span><span> Rooms {{ totalTravellers }} Travellers</span></div
      >
    </div>
    <div>
      <img
        style="width: 24px; height: 24px"
        alt="Seat-img"
        alt="travellers-classes"
        alt="title"
        src="/assets/images/icons/seat.svg"
      />
    </div>
  </div>
<div class="show-travel-class" #showInputTR [ngClass]="{'menu-show': travelView}"  >
    <div class="custom-container">

      <div class="warnings" *ngIf="error">
        <span role="alert" class="alert alert-dismissible alert-warning">{{error}}</span>
      </div>
      <div class="row">
        <div class="col-12 p0" [formGroup]="travelForm">
            <ng-container *ngFor="let traveller of travellers | keyvalue">
              <div class="section-select">
                <div class="header d-flex ">
                  <span class="name">{{traveller.value.name}}</span>
                  <input type="text" class="form-control" [value]="traveller.value.id" [formControlName]="traveller.value.id" hidden >
                  <span class="ages" *ngIf="traveller.value.ageFrom">({{traveller.value.ageFrom}} - {{traveller.value.ageTo}})</span>
                </div>
                <div class="value d-flex align-items-baseline">
                  <button class="btn valueBtn" [disabled]="traveller.value.currentValue < 1 || totalTravellers < 2" [ngClass]="{unvalueBtn : traveller.value.currentValue < 1 || totalTravellers < 2}"
                          (click)="dec(traveller)">-
                  </button>
                  <div class="number">
                    <span>{{traveller.value.currentValue}}</span>
                  </div>
                  <button class="btn valueBtn" [disabled]="totalTravellers > 8" (click)="inc(traveller)"[ngClass]="{unvalueBtn :totalTravellers > 8}">+</button>
                </div>
              </div>
            </ng-container>
        </div>


        <div class="col-12 " >
            <div class="classes" *ngIf="classTrip">
              <mat-select [(ngModel)]="className" class="matSelect">
                <mat-option [value]="'Economy'">Economy</mat-option>
                <mat-option [value]="'Premium economy'">Premium Economy</mat-option>
                <mat-option [value]="'First'">First</mat-option>
                <mat-option [value]="'Business'">Business</mat-option>
              </mat-select>
            </div>
            <button class="col-12 btn selectClassBtn"
                    (click)="setTravellersDetails() ;showInputTR.classList.remove('menu-show') "
                    [disabled]="errorInfants">
              Done
            </button>
        </div>

      </div>
    </div>
    <div class="error" *ngIf="errorInfants" >{{seatExceedError}}</div>

</div>
</div>
