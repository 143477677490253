import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit {

  searchText = '';
  loading: boolean = false;
  searchResultsFound: boolean = false;
  topicId = 0;
  topicTitle = '';
  searchResults = [];
  backEndErr = false;

  constructor(
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private apis: HelpCenterServices,
    private router: Router,
  ) {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle('Topics | FlyAllOver');
    this.seoService.updateDescription('Topics | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnInit(): void {
    this.topicTitle = this.activatedRoute.snapshot.params.title;
    this.topicId = this.activatedRoute.snapshot.params.id;
    this.getTopic(this.topicId);
  }

  getTopic(id) {

    this.loading = true
    this.apis.getTopic(id).subscribe(
      (res: any) => {
        this.searchResultsFound = true
        this.searchResults = res.data;

        // this.modifyMetaTags(this.searchResults[0]?.meta_data?.data)
        // this.page = res.meta.current_page;
        // this.collectionSize = res.meta.total;
        // this.pageSize = res.meta.per_page
        // this.nextPage = res.links.next;
        // this.prevPage = res.links.prev;
        this.loading = false;
      },
      (err) => {
        this.searchResultsFound = false
        this.loading = false;
        if (err.error.code === 404) {
          //TODO display no content
          this.backEndErr = true;
        } else {
          this.router.navigate(['/no-result'])
        }
      }
    )

  }

  getPostDetails(topic, title, id) {
    let titleWithoutSpaces = title.split(' ').join('-')
    this.router.navigate([`/post-details/${topic}/${titleWithoutSpaces}/${id}`])
  }

  onSearch(value) {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: value
      }
    })
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
