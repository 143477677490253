import { CalunderComponent } from './components/home/calendar/calendar.component';
import { CheckoutComponent } from './pages/review-flight/checkout/checkout.component';
import { UpdatePaymentComponent } from './components/review-flight/update-payment/update-payment.component';
import { ReviewFlightPaymentWithOutSearchComponent } from './pages/review-flight-payment-without-search/review-flight-payment-without-search.component';
import { NgModule } from "@angular/core";
import { OfferComponent } from "./pages/offer/offer.component";
import { ExploreDetailsComponent } from "./pages/explore-details/explore-details.component";
import { ExploreComponent } from "./pages/explore/explore.component";
import { ThingsTodoComponent } from "./pages/things-todo/things-todo.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { AboutComponent } from "./pages/about/about.component";
import { HelpDisclaimerComponent } from "./pages/help-disclaimer/help-disclaimer.component";
import { HelpNewsletterComponent } from "./pages/help-newsletter/help-newsletter.component";
import { HelpFaqComponent } from "./pages/help-faq/help-faq.component";
import { HelpPrivacyComponent } from "./pages/help-privacy/help-privacy.component";
import { HelpTermsComponent } from "./pages/help-terms/help-terms.component";
import { HelpContactUsComponent } from "./pages/help-contact-us/help-contact-us.component";
import { HelpBookingsComponent } from "./pages/help-bookings/help-bookings.component";
import { HelpSearchResultsComponent } from "./pages/help-search-results/help-search-results.component";
import { HelpCenterComponent } from "./pages/help-center/help-center.component";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { ForgetPasswordComponent } from "./pages/forget-password/forget-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { FlightsResultsComponent } from "./pages/flights-results/flights-results.component";
import { NoResultsComponent } from "./pages/no-results/no-results.component";
import { ConfirmationComponent } from "./pages/confirmation/confirmation.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { HotelsComponent } from "./pages/hotels/hotels.component";
import { HotelsResultsComponent } from "./pages/hotels-results/hotels-results.component";
import { OffersComponent } from "./pages/offers/offers.component";
import { HotelConfirmationComponent } from "./pages/hotel-confirmation/hotel-confirmation.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { NoResultComponent } from "./pages/no-result/no-result.component";
import { ViewItineraryComponent } from "./pages/emails/view-itinerary/view-itinerary.component";
import { BirthdayEmailComponent } from "./pages/emails/birthday-email/birthday-email.component";
import { WelcomeComponent } from "./pages/emails/welcome/welcome.component";
import { LoginComponent } from "./pages/login/login.component";
import { TopicComponent } from "./pages/topic/topic.component";
import { CovidTestingLocationsComponent } from "./pages/covid-testing-locations/covid-testing-locations.component";
import { CovidStateComponent } from "./pages/covid-state/covid-state.component";
import { CentreComponent } from "./pages/centre/centre.component";
import { CentreArticleComponent } from "./pages/centre-article/centre-article.component";
import { OfferFlightComponent } from "./pages/offer-flight/offer-flight.component";
import { CovidCountrySearchComponent } from "./pages/covid-country-search/covid-country-search.component";
import { FlightPageComponent } from "./components/flight-page/flight-page.component";
import { HotelPageComponent } from "./components/hotel-page/hotel-page.component";
import { VisaComponent } from "./pages/visa/visa.component";
import { HealthEntryComponent } from "./pages/health-entry/health-entry.component";
import { SelfServiceComponent } from "./pages/self-service/self-service.component";
import { HomeComponent } from "./pages/home/home.component";
import { TravelDetailsComponent } from "./pages/dashboard/travel-details/travel-details.component";
import { BookingDashboardComponent } from "./pages/dashboard/booking-dashboard/booking-dashboard.component";
import { DashboardComponent } from "./pages/dashboard/dashboard/dashboard.component";
import { VisaDataComponent } from "./components/visa-data/visa-data.component";
import { FeedbackDashboardComponent } from "./pages/dashboard/feedback-dashboard/feedback-dashboard.component";
import { DashSidebarComponent } from "./components/dashboard/dash-sidebar/dash-sidebar.component";
import { AccountInfoComponent } from "./pages/dashboard/account-info/account-info.component";
import { ReviewFlightThanksComponent } from "./components/review-flight/review-flight-thanks/review-flight-thanks.component";
import { ReviewFlightComponent } from "./pages/review-flight/review-flight.component";
import { TestComponent } from "./components/test/test.component";
import { BaggageFeesComponent } from "./pages/baggage-fees/baggage-fees.component";
import { ThingsToDoPageComponent } from "./pages/things-to-do-page/things-to-do-page.component";
import { AllPopularDestComponent } from "./components/all-popular-dest/all-popular-dest.component";
import { PopularDestDescriptionComponent } from "./pages/popular-dest-description/popular-dest-description.component";
import { MorePopularDestinationsComponent } from "./pages/more-popular-destinations/more-popular-destinations.component";
import { CityDetailsComponent } from "./pages/city-details/city-details.component";
import { PageOffersComponent } from "./pages/page-offers/page-offers.component";
import { MoreOffersComponent } from "./pages/more-offers/more-offers.component";
import { NewBlogComponent } from "./pages/new-blog/new-blog.component";
import { BlogDescriptionComponent } from "./pages/blog-description/blog-description.component";
import { AirportDescriptionComponent } from "./pages/airport-description/airport-description.component";
import { TravelComponent } from "./pages/travel/travel.component";
import { AirlineBaggingInformationComponent } from "./pages/airline-bagging-information/airline-bagging-information.component";
import { AirlineFAQComponent } from "./pages/airline-faq/airline-faq.component";
import { AirlineOnlineCheckComponent } from "./pages/airline-online-check/airline-online-check.component";
import { AirlineDescriptionComponent } from "./pages/airline-description/airline-description.component";
import { ThanksDialogComponent } from "./pages/review-flight/thanks-dialog/thanks-dialog.component";
import { ApisResolver } from "./resolver/apis.resolver";
import { ShareReviewsComponent } from "./pages/share-reviews/share-reviews.component";
import { EgyptairSearchComponent } from "./pages/egyptair-search/egyptair-search.component";
import { ViewReviewDetailsComponent } from "./pages/view-review-details/view-review-details.component";
import { AddReviewsComponent } from "./components/add-reviews/add-reviews.component";
import { PointSliderOffersComponent } from "./components/point-slider-offers/point-slider-offers.component";
import { VacationsResultComponent } from "./pages/vacations-result/vacations-result.component";
import { VacationsHotelsComponent } from "./pages/vacations-hotels/vacations-hotels.component";
import { RoomDetailsComponent } from "./pages/room-details/room-details.component";
import { VacationHotelDetailsComponent } from "./components/vacation-hotel-details/vacation-hotel-details.component";
import { FormVacationHotelPaymentComponent } from "./components/form-vacation-hotel-payment/form-vacation-hotel-payment.component";
import { NewHotelsComponent } from "./pages/new-hotels/new-hotels.component";
import { FormVacationHotelTravellerDataComponent } from "./components/form-vacation-hotel-traveller-data/form-vacation-hotel-traveller-data.component";
import { PayLaterComponent } from "./pages/pay-later/pay-later.component";
import { AllVillasComponent } from "./pages/all-villas/all-villas.component";
import { VillasDetailsComponent } from "./pages/villas-details/villas-details.component";
import { ReviewFlightPaymentComponent } from './components/review-flight/review-flight-payment/review-flight-payment.component';
import { ShareFlightDetailsComponent } from "./pages/share-flight-details/share-flight-details.component";
import {NewHelpCenterComponent} from "./pages/new-help-center/new-help-center.component";
import {ChangePasswordComponent} from './pages/change-password/change-password.component';
import { ChargebackPolicyComponent} from "./pages/chargeback-policy/chargeback-policy.component";
import {PaymentDetailsComponent} from './components/review-flight/payment-details/payment-details.component';
import { CancellationPolicyComponent } from "./pages/cancellation-policy/cancellation-policy.component";
import {CommonModule} from '@angular/common';
import { AirlineCancellationPolicyComponent } from "./pages/airline-cancellation-policy/airline-cancellation-policy.component";
import { PrivacyDetailsComponent } from "./pages/privacy-details/privacy-details.component";
import { HelpHomeComponent } from './pages/help-home/help-home.component';
import { ArticlesLinksDetailsComponent } from './pages/articles-links-details/articles-links-details.component';
import { NewHotelsDetailsComponent } from './pages/new-hotels-details/new-hotels-details.component';
import { BgHomeComponent } from './components/home/bg-home/bg-home.component';
import { RoomDetailsV2Component } from './pages/room-details-v2/room-details-v2.component';
import { TripDetailsComponent } from './pages/trip-details/trip-details.component';
import { NewTripComponent } from './pages/new-trip/new-trip.component';
import { TripsComponent } from './pages/trips/trips.component';
import { CheckHotelDateComponent } from './components/home/ceck-hotel-date/ceck-hotel-date.component';
import { HotelsFormComponent } from './components/home/hotels-form/hotels-form.component';
import { EngineComponent } from './components/home/engine/engine.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "hotel/confirmation", component: HotelConfirmationComponent },
  { path: "hotel-payment/:roomKey", component: PaymentComponent },
  { path: "hotel/:hotelId", component: HotelsComponent },
  { path: "hotels", component: HotelPageComponent },
  {path: 'faq', component: HelpFaqComponent},
  { path: "thing-to-do", component: ThingsToDoPageComponent },
  { path: "terms", component: HelpTermsComponent },
  { path: "center", component: HelpCenterComponent },
  { path: "contact-us", component: HelpContactUsComponent },
  { path: "search-results", component: HelpSearchResultsComponent },
  { path: "privacy", component: HelpPrivacyComponent },
  { path: "post-details/:topic/:title/:id", component: HelpBookingsComponent },
  { path: "disclaimer", component: HelpDisclaimerComponent },
  { path: "newsletter", component: HelpNewsletterComponent },
  { path: "support", component: CentreComponent },
  { path: "support/:category", component: CentreComponent },
  { path: "support/search/:query", component: CentreComponent },
  { path: "support/:category/:slug", component: CentreArticleComponent },
  { path: "topic/:title/:id", component: TopicComponent },
  { path: "hsa", component: HotelsResultsComponent },
  { path: "about", component: AboutComponent },
  { path: "sign-up", component: SignUpComponent },
  { path: "sign-in", component: LoginComponent },
  { path: "forget-password", component: ForgetPasswordComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  { path: "no-result", component: NoResultComponent },
  { path: "review-flight", component: ReviewFlightComponent },
  {path: 'review-flight/:tagId/:tripType', component: ReviewFlightComponent},
  {path: 'review-flight/:param', component: ReviewFlightComponent},
  {path: 'review-flight-payment', component: ReviewFlightPaymentComponent},
  { path: "thanks2", component: ReviewFlightThanksComponent },
  { path: "flights", component: FlightsResultsComponent },
  { path: "flight/confirmation", component: ConfirmationComponent },
  { path: "search-error", component: NoResultsComponent },
  // { path: "profile", component: ProfileComponent },
  { path: "blog", component: NewBlogComponent },
  { path: "add-reviews", component: AddReviewsComponent },
  { path: "blog-description/:slug", component: BlogDescriptionComponent },
  {
    path: "pcr-time-slider-for-travel",
    component: CovidCountrySearchComponent,
  },
  {
    path: "pcr-time-slider-for-travel/:country",
    component: CovidTestingLocationsComponent,
  },
  {
    path: "pcr-time-slider-for-travel/:country/:state",
    component: CovidStateComponent,
  },
  { path: "emails/forget-password", component: ForgetPasswordComponent },
  { path: "emails/view-itinerary", component: ViewItineraryComponent },
  { path: "emails/birthday", component: BirthdayEmailComponent },
  { path: "emails/welcome", component: WelcomeComponent },
  { path: "search/flights", component: FlightPageComponent },
  { path: "visa", component: VisaComponent },
  { path: "visa/:country", component: HealthEntryComponent },
  { path: "self-services", component: SelfServiceComponent },
  {path: 'profile',
    children:[
      {path: "account-info", component: AccountInfoComponent },
      {path: "travel-details-dashboard", component: TravelDetailsComponent },
      {path: "feedback-dashboard", component: FeedbackDashboardComponent },
      {path: "my-booking", component: BookingDashboardComponent },
      {path: "dashboard", component: DashboardComponent },
      {path: "sidebar", component: DashSidebarComponent },
      {path:'',redirectTo:'dashboard',pathMatch:'full'},
    ]
  },
  // {path: "dashboard", component: DashboardComponent },

  { path: "visa-dashboard", component: VisaDataComponent },
  { path: "404", component: NotFoundComponent },
  { path: "baggage-fees", component: BaggageFeesComponent },
  { path: "test", component: TestComponent },
  { path: "popular-destinations", component: AllPopularDestComponent },
  {
    path: "popular-destination-description",
    component: PopularDestDescriptionComponent,
  },
  { path: "things-to-do", component: ThingsToDoPageComponent },
  { path: "things-to-do/:city/:slug", component: ExploreDetailsComponent },
  {
    path: "popular-destinations/:city/:slug",
    component: MorePopularDestinationsComponent,
  },
  { path: "city-details", component: CityDetailsComponent },
  { path: "offers/:slug", component: PageOffersComponent },
  { path: "offers", component: MoreOffersComponent },
  { path: "thanks", component: ThanksDialogComponent },
  { path: "airport-description/:code", component: AirportDescriptionComponent },
  { path: "travel", component: TravelComponent },
  {
    path: "Bagging-Information/:name",
    component: AirlineBaggingInformationComponent,
  },
  {path: 'share-reviews/:code', component: ShareReviewsComponent},
  { path: 'Online-Check/:name', component: AirlineOnlineCheckComponent },
  { path: 'FAQ/:name', component: AirlineFAQComponent },
  { path: 'airline-description/:name', component: AirlineDescriptionComponent },
  { path: 'cancellation-policy/:name', component: AirlineCancellationPolicyComponent },
  { path: 'reviews/:id', component: ViewReviewDetailsComponent },
  {path: 'offers-point' , component: PointSliderOffersComponent},
  { path: 'Egyptair-Offers', component: EgyptairSearchComponent },
  { path: 'vacations', component: VacationsResultComponent},
  { path: 'vacations-details/:id', component: VacationsHotelsComponent },
  { path: 'hotel-results', component: HotelsResultsComponent},
  { path: 'room-details', component: RoomDetailsComponent},
  { path: 'vacation-hotel-details/:id', component: VacationHotelDetailsComponent},
  { path: 'vacation-hotel-payment-form', component: FormVacationHotelPaymentComponent},
  { path: 'new-hotels', component: NewHotelsComponent},
  { path: 'villas-details', component: VillasDetailsComponent},
  { path: 'all-villas', component: AllVillasComponent},
  { path: 'vacation-hotel-traveller-form', component: FormVacationHotelTravellerDataComponent},
  { path: 'pay-later', component: PayLaterComponent},
  { path: 'flight-details/:tagId/:tripType', component:ShareFlightDetailsComponent},
  {path:'addhotels' , loadChildren: () => import('./views/hotels/hotels.module').then(m => m.HotelsModule)},
  { path: "help-center", component: NewHelpCenterComponent },
  {path: 'change-password/:token', component: ChangePasswordComponent},
  { path: "chargeback-policy", component: ChargebackPolicyComponent },
  {path: 'payment-details/:status/:id', component: PaymentDetailsComponent},
  {path:"cancellation-policy", component: CancellationPolicyComponent},
  {path:"details/:privacyId", component:PrivacyDetailsComponent},
  {path:"help-home", component:HelpHomeComponent},
  {path:"hotel-details/:HotelCode", component:NewHotelsDetailsComponent},
  {path:"articles/:id", component:ArticlesLinksDetailsComponent},
  {path:"review-flight-payment-method/:orderId", component: CheckoutComponent},
  {path:"review-book/:orderId", component: CheckoutComponent},
  {path: 'update-payment/:status/:id', component: UpdatePaymentComponent},
  {path: 'room-details/:hotelCode/:roomCode', component:RoomDetailsV2Component},
  {path:"vacation", component:TripsComponent},
  {path:"vacation-details/:slug", component:TripDetailsComponent},
  {path:"fffff", component:HotelsFormComponent},
  // {path:"trip-details/:tripId", component:TripDetailsComponent},

  // {path: 'update', component: BgHomeComponent},





  { path: '**', redirectTo: '/404' },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: "enabled",
  onSameUrlNavigation: "reload",
  initialNavigation: "enabled",
  relativeLinkResolution: "legacy",
  scrollPositionRestoration: "top",
};

@NgModule({
  imports: [CommonModule,
    RouterModule.forRoot(routes, routerOptions),],
  exports: [RouterModule],
})
export class AppRoutingModule {}
