<app-navbar></app-navbar>
<div class="container" *ngIf="!hotelDetails">
  <section id="first-Section">
    <div class="row ">
      <div class="col-md-8">
        <div class="row ">
          <div class="col-md-2 vt-placeholder-loading">
            <div class="vt-animated-background-loading" title="copy" style="width: 90px;height: 60px;"></div>
          </div>
          <div class="col-md-10 vt-placeholder-loading">
            <div class="fao_mobile_title_large mb-4 font-weight-bold Font-Family-bold vt-animated-background-loading">
            </div>
          </div>
        </div>
      </div>

      <div class="m-b10">
        <div class="wt-divider bg-gray-dark"></div>
      </div>
      <section class="gallery">
        <div class="container-fluid">
          <div class="row">
            <!-- Mixit Up Controls -->


            <div class="loading-part vt-placeholder-loading">
              <ng-container *ngFor="let item of placholderLodaing">

                <div class=" p-3" Style="min-width:150px">
                  <div style="border-radius:5px ; height:25px; padding: 12px;" class="vt-animated-background-loading ">
                  </div>
                </div>
              </ng-container>

            </div>
          </div>

          <div class="row">
            <ng-container>
              <div class=" row ">
                <!-- style="padding: 0;" -->
                <div class="col-4 vt-placeholder-loading" style="padding: 3px;" *ngFor="let image of placholderLodaing">
                  <div style="padding: 3px; height:150px" class="vt-animated-background-loading"></div>
                  <!-- class="my-1 py-2" -->
                </div>
              </div>
            </ng-container>

          </div>
        </div>

        <!-- End gallery Section  -->
      </section>
    </div>
  </section>
</div>
<!-- loading hotel -->
<div class="container" *ngIf="hotelDetails">
  <section id="first-Section">
    <div class="row ">
      <div class="col-md-8">
        <div class="row ">
          <div class="col-md-2">
            <img *ngIf="hotelDetails.logo" title="copy" style="width: 90px;height: 60px;" [src]="hotelDetails.logo" />
          </div>
          <div class="col-md-10">
            <h2 class="fao_mobile_title_large mb-4 font-weight-bold Font-Family-bold" *ngIf="hotelDetails.chain_name">
              {{hotelDetails.chain_name}}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item follow Font-Family pl-3 mt-2">
          <ul class=" my-2 font-icon">
            <li>
              <span class="font-span_1 ">Share Now : </span>
            </li>
            <li>
              <a class="social-icon image-item d-flex" (click)="cop()" target="_blank">
                <img *ngIf="!copied" title="copy" style="width: 24px" alt="copy"
                  src="./assets/newIcon/copy-blue-icon.svg" />
                <img *ngIf="copied" title="copied" style="width: 24px" alt="copied" src="./assets/newIcon/copied.png" />
              </a>
            </li>
            <li><a target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                  class="fab fa-facebook-f"></i></a></li>
            <li><a target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                  class="fab fa-twitter"></i></a></li>
            <li><a (click)="linked()" target="_blank" rel="nofollow"
                href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="m-b10">
        <div class="wt-divider bg-gray-dark"></div>
      </div>
      <section class="gallery">
        <div class="container-fluid">
          <div class="row">
            <!-- Mixit Up Controls -->


            <div class="controls">
              <button type="button" class="control" [ngClass]="{'mixitup-control-active':categoryActicert=='all'}"
                (click)="getImages('all')">All</button>
              <ng-container *ngFor="let category of hotelDetails.HotelMediaInfo| keyvalue">
                <button type="button" class="control "
                  [ngClass]="{'mixitup-control-active':categoryActicert==category.key}"
                  (click)="getImages(category.key)">{{category.key}}</button>
              </ng-container>

            </div>
          </div>

          <div class="row">
            <ng-container>
              <div class=" row ">
                <!-- style="padding: 0;" -->
                <div class="col-4 " style="padding: 3px;" *ngFor="let image of imagesSilder">
                  <img style="padding: 3px;" *ngIf="image.image_url" [src]="image.image_url" alt="" height="300px"
                    width="100%" />
                  <img style="padding: 3px;" *ngIf="!image.image_url" [src]="image[0].Url" alt="" height="300px"
                    width="100%" />
                  <!-- class="my-1 py-2" -->
                </div>
              </div>
            </ng-container>

          </div>
        </div>
        <ngb-pagination *ngIf="hotelsImages.length>6" class="d-flex justify-content-center"
          [collectionSize]="hotelsImages?.length" [pageSize]="itemSilderImages" [maxSize]="5" (pageChange)="pageChanged($event)"
          [page]="currentPage">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
        <!-- End gallery Section  -->
      </section>
    </div>
  </section>
  <div class="m-b10">
    <div class="wt-divider bg-gray-dark"></div>
  </div>

  <div class="col-12 " style="padding: 3px;" *ngFor="let description of hotelDetails.HotelDescription; let i= index">
    <div class="col-lg-12" style="padding: 20px;" *ngIf="description.type == 'ShortDescription'">
      <div class="title">
        <small>About Hotel</small>
        <h3>More about us</h3>
        <p>{{ description.value }}</p>
      </div>
    </div>
    <div class="col-md-12" style="padding: 20px;" *ngIf="description.type == 'Attractions'">
      <div class="title">
        <small>Attractions</small>
        <h3>Around us</h3>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li class="col-12 " style="padding: 3px;"
                *ngFor="let attractionsItem of description.value; let attractionIndex = index">
                <ng-container *ngIf="attractionsItem">

                  {{ attractionsItem }}
                </ng-container>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <app-google-map [longitude]="+hotelDetails.LocationInfo?.Longitude"
              [Latitude]="+hotelDetails.LocationInfo?.Latitude"></app-google-map>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" style="padding: 20px;" *ngIf="description.type == 'Policies'">
      <div class="title">
        <small>Policies</small>
        <h3>Check our Policie</h3>
        <div class="row">

          <ul class="row">
            <li class="col-md-6 " style="padding: 3px;"
              *ngFor="let attractionsItem of description.value; let attractionIndex = index">
              <ng-container *ngIf="attractionsItem">

                {{ attractionsItem }}
              </ng-container>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <!-- <div class="col-md-12" style="padding: 20px;" *ngIf="description.type == 'Services'">
      <div class="title">
        <small>Services</small>
        <h3>Meet our Services</h3>
        <div class="row">
          <ul class="col-md-4 services">
            <span class="col-md-12 pe-3" style="padding: 3px;">
              services
            </span>
            <ng-container *ngFor="let service of checkService(description.value,'services'); let serviceIndex = index">
              <ng-container *ngIf="service">
                <li class="col-md-12 pe-3" style="padding: 3px;">
                  {{ service }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <ul class="col-4 noCharge">
            <span class="col-md-12 pe-3" style="padding: 3px;">
              No Charge
            </span>
            <ng-container *ngFor="let service of checkService(description.value,'noCharge'); let serviceIndex = index">
              <ng-container *ngIf="service">
                <li class="col-md-12 pe-3" style="padding: 3px;">
                  {{ service }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <ul class="col-4 Charge">
            <span class="col-md-12 pe-3" style="padding: 3px;">
              Charge
            </span>
            <ng-container *ngFor="let service of checkService(description.value,'Charge'); let serviceIndex = index">
              <ng-container *ngIf="service">
                <li class="col-md-12 pe-3" style="padding: 3px;">
                  {{ service }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
  <div class="title text-center mb-5">
    <small data-cue="slideInUp">Paradise Hotel</small>
    <h2 data-cue="slideInUp" data-delay="100">Main Facilities</h2>
  </div>
  <div class="row mt-4">
    <div class="col-xl-3 col-md-6">
      <div class="box_facilities no-border" data-cue="slideInUp">
        <i class="customicon-private-parking"></i>
        <h3>Private Parking</h3>
        <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.</p>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="box_facilities" data-cue="slideInUp">
        <i class="customicon-wifi"></i>
        <h3>High Speed Wifi</h3>
        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="box_facilities" data-cue="slideInUp">
        <i class="customicon-cocktail"></i>
        <h3>Bar & Restaurant</h3>
        <p>Similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="box_facilities" data-cue="slideInUp">
        <i class="customicon-swimming-pool"></i>
        <h3>Swimming Pool</h3>
        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
      </div>
    </div>
  </div>
  <!-- /Row -->

  <section id="service-icon">
    <div class="col-12">
      <div class="row gy-3">
        <div class="col-12 p-0">
          <div class="details-room-with-icon">
            <div class="col-md-4 pe-3">
              <div class="service-card-bady">
                <div class="gdlr-core-column-service-media gdlr-core-media-icon"
                  style="margin-top: 0px; margin-right: 26px;">
                  <img class="gdlr-icon-group" style="font-size: 40px; line-height: 40px; width: 40px; color: #0a0a0a;"
                    title="Search about trip" alt="search" src="./assets/images/hotel-info/self-service.png"
                    width="32" />
                </div>
                <div class="gdlr-core-column-service-content-wrapper">
                  <div class="gdlr-core-column-service-title-wrap">
                    <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                      style="font-size: 19px; font-weight: 600; text-transform: none;">Check in</h3>
                    <div class="gdlr-core-column-service-caption gdlr-core-info-font gdlr-core-skin-caption"
                      style="font-size: 17px; font-weight: 500; font-style: normal; margin-top: 0px;">
                      {{ hotelDetails.check_in|date: "hh:mm a"}}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-md-4 pe-3">
              <div class="service-card-bady">
                <div class="gdlr-core-column-service-media gdlr-core-media-icon"
                  style="margin-top: 0px; margin-right: 26px;">
                  <img class="gdlr-icon-group" style="font-size: 40px; line-height: 40px; width: 40px; color: #0a0a0a;"
                    title="Search about trip" alt="search" src="./assets/images/hotel-info/checkout-counter.png"
                    width="32" />
                </div>
                <div class="gdlr-core-column-service-content-wrapper">
                  <div class="gdlr-core-column-service-title-wrap">
                    <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                      style="font-size: 19px; font-weight: 600; text-transform: none;">Check out</h3>
                    <div class="gdlr-core-column-service-caption gdlr-core-info-font gdlr-core-skin-caption"
                      style="font-size: 17px; font-weight: 500; font-style: normal; margin-top: 0px;">
                      {{ hotelDetails.check_out |date: "hh:mm a"}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="Rooms" class="Rooms section">
    <div class="header-rooms-gategory">
      <div class="controls m-0">
        <button type="button" class="control" [ngClass]="{'mixitup-control-active':categoryActicert=='all'}"
          (click)="getRooms('all')">All Rooms</button>
        <button type="button" class="control" [ngClass]="{'mixitup-control-active':categoryActicert=='one-bad'}"
          (click)="getRooms('one-bads')">1 bad</button>
        <button type="button" class="control" [ngClass]="{'mixitup-control-active':categoryActicert=='tow-bads'}"
          (click)="getRooms('tow-bads')">2 bads</button>
        <button type="button" class="control" [ngClass]="{'mixitup-control-active':categoryActicert=='three-bads'}"
          (click)="getRooms('three-bads')">3 bads</button>
        <!-- <ng-container *ngFor="let category of hotelDetails.HotelMediaInfo| keyvalue">
          <button type="button" class="control "
            [ngClass]="{'mixitup-control-active':categoryActicert==category.key}"
            (click)="getRooms(category.key)">{{category.key}}</button>
        </ng-container> -->
      </div>
      <div class="view-part">
        Showing {{11 }}of{{hotelDetails.Rooms.length}}rooms
      </div>
    </div>
    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
      <h2>Rooms</h2>
      <p class="">Most booked
      </p>
    </div><!-- End Section Title -->

    <div class="container">

      <div class="row">

        <owl-carousel-o>
          <ng-container *ngFor="let item of hotelDetails.Rooms">
            <ng-template carouselSlide>
              <div class=" col-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <app-card-room-details [room]="item" [hotelCode]="hotelCode"></app-card-room-details>
                <!-- <div class="room-item mx-auto">
                  <ngb-carousel>
                    <ng-template ngbSlide>
                      <div class="picsum-img-wrapper m-auto">
                        <img src="../../../assets/images/hotels/img/room.jpg" class="img-fluid rounded " alt="...">
                      </div>
                      <div class="carousel-caption"></div>
                    </ng-template>
                  </ngb-carousel>
                  <div class="room-content">
                    <ng-container *ngFor="let item of changeRoomName(item.room_description_name); let i = index">
                      <h3 *ngIf="i==0"><a routerLink="/room-details/{{hotelCode}}/{{hotelDetails.id}}">{{item}}</a></h3>
                      <ul>
                        <li *ngIf="i>0">{{item}}</li>
                      </ul>
                    </ng-container>
                    <div class=" d-flex flex-colunm ourmaster-info-wrap clearfix mt-3">
                      <div class="col-6 tourmaster-info tourmaster-info-bed-type my-2 "><i
                          class="gdlr-icon-double-bed2 " style="width: 30px; font-size: 20px;"></i><span
                          class="tourmaster-tail ">1 King Bed</span></div>
                      <div class="col-6 tourmaster-info tourmaster-info-bed-type my-2"><i class="gdlr-icon-group "
                          style="width: 30px; font-size: 20px;"></i><span class="tourmaster-tail ">{{item.occupancy}}
                          Guests</span></div>
                    </div>
                    <div class=" d-flex justify-content-between align-items-center">
                      <div class="tourmaster-price-wrap tourmaster-with-bg">
                        <span class="tourmaster-head">From</span><span class="tourmaster-price-discount">$80</span><span
                          class="tourmaster-price">{{item.RatePlans?.AmountAfterTax
                          |currency:item.RatePlans?.CurrencyCode}}</span>
                      </div>
                      <div class="d-flex justify-content-end align-items-center mb-3">
                        <p class="category">Booking Now</p>
                      </div>
                    </div>
                  </div>
                </div> -->

              </div> <!-- End Course Item-->
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </div>

  </section>
</div>
<app-footer></app-footer>
