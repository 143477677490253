<div class="engine-body">
  <mat-tab-group class="mainTabGroup" dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label class="mat-tab-header" >
        <div class="mainTab">
          <img alt="hotel" title="hotel" style="width: 19px;height: 19px;"
               src="/assets/images/home/carousel/airplane-icon.png">
          <span class="type" style="color: #fd5021" (click)="viewClickap(1)">Flights</span>
        </div>

      </ng-template>
<div class="up-port">
      <div class="nav-tab" [ngClass]="{ active: isActive(1) }" (click)="openTab(1)">

        <div class="secondaryTab">
          <span class="type">Round Trip</span>
        </div>
      </div>
      <div class="nav-tab" [ngClass]="{ active: isActive(2) }" (click)="openTab(2)">

        <div class="secondaryTab">
          <span class="type">One Way</span>
        </div>
      </div>
      <div class="nav-tab" [ngClass]="{ active: isActive(3) }" (click)="openTab(3)">

        <div class="secondaryTab">
          <span class="type">Multi City</span>
        </div>
      </div>


  <div class="col-12 col-sm-3 col-lg-2">
    <div class="row flex-column justify-content-between select">
      <div class="classes">
        <select [(ngModel)]="className" class="select-trip " (change)="setClassName()">
          <option class="select-option" [value]="'Economy'">Economy</option>
          <option class="select-option" [value]="'Premium economy'">Premium Economy</option>
          <option class="select-option" [value]="'First'">First</option>
          <option class="select-option" [value]="'Business'">Business</option>
        </select>
      </div>
    </div>
  </div>
</div>
      <div class="body-tab" [hidden]="!isActive(1)">
        <app-round-trip-form [color]="'#fff'" [payLater]="payLater" [disabeled]="disabeled" [inputCodeAirLine]="airLine" [className]="className"
                             (applyModificationEvent)="applyModificationEvent($event)" [classTrip]="classTrip"
                             (searchInputClose)="close()"></app-round-trip-form>
      </div>


      <div class="body-tab" [hidden]="!isActive(2)">
        <app-one-way-trip-form [color]="'#fff'" [payLater]="payLater"  [className]="className"
                               (applyModificationEvent)="applyModificationEvent($event)" [classTrip]="classTrip"
                               (searchInputClose)="close()"></app-one-way-trip-form>
      </div>

      <div class="body-tab" [hidden]="!isActive(3)">
        <app-multi-city-form [color]="'#fff'" [payLater]="payLater" [className]="className"
                             (applyModificationEvent)="applyModificationEvent($event)" [classTrip]="classTrip"
                             (offsetHeight)="setOffsetHeight()" (searchInputClose)="close()"></app-multi-city-form>
      </div>


    </mat-tab>

    <!-- *ngIf="disabeled && false" -->
    <!-- *ngIf="disabeled&&!payLater"  -->

    <!-- ------------------------------------------------------- hotel in search engin ------------------------------------------------ -->
    <mat-tab  >
      <ng-template mat-tab-label >
        <div class="mainTab hotelsTab" (click)="viewClickap(2)" >
          <img alt="hotel" title="hotel" style="width: 36px;height: 36px;" src="/assets/images/icons/hotel2.png">
          <span class="type" style="color:#fd5021 !important" >Hotels</span>
        </div>
      </ng-template>
      <!-- <app-hotels-engine></app-hotels-engine> -->
      <div class="body-tab" *ngIf="openTapHeade!=2" >
        <div class="loader"></div>
          </div>
      <div class="body-tab" *ngIf="openTapHeade===2" >
        <app-hotels-form></app-hotels-form>
      </div>
    </mat-tab>
 </mat-tab-group>

</div>
