<section class="price-details-section"  *ngIf="offers != 'false'">
  <div class=" pricing-container">
    <h3>Total Price Details</h3>
    <hr/>
    <div class="row">
      <div class="col-sm-3">
        <p class="mb-0">Subtotal:</p>
        <div></div>
      </div>
      <div class="col-12">
        <div class="row">
          <ng-container *ngFor="let passenger of itineraries[0]?.passengerDetails">
            <ng-container *ngIf="passenger.Quantity && passenger.Quantity > 0">
              <div class="col-6">
                <p class="text-muted ml-3 pt-2">
                  {{passenger.Quantity}}x
                  {{passenger.PassengerType}}
                  <ng-container *ngIf="passenger.Quantity > 1">s</ng-container>
                </p>

              </div>
              <div class="col-6 text-right single-price">
                <p>{{passenger.BaseFare * passenger.Quantity | currency}}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <hr/>
    <!-- taxes and fees -->
    <div class="row">
      <div class="col-sm-3">
        <p>Taxes and Fees</p>
      </div>
      <div class="col-12">
        <div class="row">
          <ng-container *ngFor="let passenger of itineraries[0]?.passengerDetails">
            <ng-container *ngIf="passenger.Quantity && passenger.Quantity > 0">
              <div class="col-6">
                <p class="text-muted ml-3 pt-2">
                  {{passenger.Quantity}}x
                  {{passenger.PassengerType}}
                  <ng-container *ngIf="passenger.Quantity > 1">s</ng-container>
                </p>
              </div>
              <div class="col-6 text-right">
                <p *ngIf="passenger.PassengerType === 'Adult'">
                  {{(passenger.Tax * passenger.Quantity) + itineraries[0]?.Fees | currency}}
                </p>
                <p *ngIf="passenger.PassengerType !== 'Adult'">
                  {{passenger.Tax * passenger.Quantity | currency}}
                </p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="insurance">
      <hr>
      <div class="row">
        <div class="col-6">
          <p>Travel Insurance</p>
        </div>
        <div class="col-6 text-right">
          <p>{{insurance?.PlanCost?.Amount | currency}}</p>
        </div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="!offerSlug">
      <hr>
      <div class="row coupon">
        <div class="col-lg-4 pr-0">
          <span>Have a Coupon or Promo Code?</span>
        </div>
        <form (ngSubmit)="validateCoupon()" class="col-md-8">
          <input type="text" [formControl]="coupon" placeholder="Promo Code">
          <button [disabled]="validatingCoupon" type="submit">Apply</button>
          <app-print-error [control]="coupon"></app-print-error>
        </form>
        <div *ngIf="coupon.valid" class="col-lg-4 col-md-5">
          <i *ngIf="validatingCoupon" class="fas fa-spinner fa-spin mt-2"></i>
          <p *ngIf="validCoupon && !validatingCoupon" class="valid mb-0"> your coupon is valid ! </p>
          <p *ngIf="validCoupon && !validatingCoupon" class="valid"> you got {{couponDiscount | currency}} off
          </p>
          <p *ngIf="!validCoupon && couponError" class="invalid mb-0 pt-2"> {{couponError}}</p>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngIf="validCoupon">
      <hr>
      <div class="row coupon-discount">
        <div class="col-md-3 col-8">
          Coupon Discount
        </div>
        <div class="col-md-9 col-4 text-right">
          <div class="row">
            <div class="col">
              <p>-{{couponDiscount | currency}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="itineraries[0]?.prices?.AdultPriceWithOffer">
      <hr>
      <div class="row coupon-discount">
        <div class="col-md-3 col-8">
          Offer Discount
        </div>
        <div class="col-md-9 col-4 text-right">
          <div class="row">
            <div class="col">
              <p>-{{offerAmount | currency}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row total-row">
      <div class="col-6">
        <h3 class="total-price">Total</h3>
      </div>
      <div class="col-6 text-right">
        <h3 class="total-price line-through"
            *ngIf="itineraries[0]?.prices?.AdultPriceWithOffer || validCoupon">
          <ng-container *ngIf="validCoupon">
            {{ calcTotalPrice() + (+couponDiscount) | currency }}
          </ng-container>
          <ng-container *ngIf="itineraries[0]?.prices?.AdultPriceWithOffer">
            {{ calcTotalPrice() + offerAmount | currency }}
          </ng-container>
        </h3>
        <h3 class="total-price">
          {{ calcTotalPrice() | currency }}
        </h3>
      </div>
    </div>
  </div>
</section>
<app-review-flight-confirm-section (checkboxesEmitter)="check($event);" [itineraries]="itineraries" ></app-review-flight-confirm-section>
<div class="navigationButtons">
  <div class="col-12">
    <div class="row justify-content-center d-flex flex-row">
      <button class="btn navBtn" (click)="previousStepEvent.emit()">Payment</button>
      <button *ngIf="offers != 'false'" class="btn btn-block btn-primary pt-3 pb-3" (click)="orderEventOffer.emit();spinnerLoader();" [disabled] = 'checkboxesCounts<4 || spinner '
      [ngClass]="checkboxesCounts<4 || spinner  ? 'my_class_btn1' : 'my_class_btn2'"> BOOK NOW
        <i *ngIf="spinner" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
      </button>
      <button *ngIf="offers == 'false'" class="btn btn-block btn-primary pt-3 pb-3" (click)="orderEventOffer.emit();spinnerLoader();" [disabled] = 'checkboxesCounts<4 || spinner '
      [ngClass]="checkboxesCounts<4 || spinner  ? 'my_class_btn1' : 'my_class_btn2'"> BOOK NOW
        <i *ngIf="spinner" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
      </button>
    </div>
  </div>
</div>
