import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApisService } from "../../../services/apis.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SEOService } from "../../../services/seo.service";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { validators } from "sitemap";
import { Console, log } from "console";

@Component({
  selector: "app-review-flight-add-traveller-form",
  templateUrl: "./review-flight-add-traveller-form.component.html",
  styleUrls: ["./review-flight-add-traveller-form.component.css"],
})
export class ReviewFlightAddTravellerFormComponent implements OnInit {
  @Input() searchRequest: any;
  @Input() disabeled :Boolean = false;
  @Output() setOwnerEvent = new EventEmitter();
  form: FormGroup;
  errorMessage:any[]=[];
  relations: any =[];
  message: any;
  spinner: any = false;
  right: any;
  errorCheckEmail: any;
  loginBtn: any = false;
  numberOfTeravellers = [];
  email: any = null;
  filteredCountries: Observable<string[]>;
  genderErrors: any;
  formData: any;
  travellers:any[]=[]
  errors: any = [[]];
  months:any = [
    { text: 'January', num: '01' },
    { text: 'February', num: '02' },
    { text: 'March', num: '03' },
    { text: 'April', num: '04' },
    { text: 'May', num: '05' },
    { text: 'June', num: '06' },
    { text: 'July', num: '07' },
    { text: 'August', num: '08' },
    { text: 'September', num: '09' },
    { text: 'October', num: '10' },
    { text: 'November', num: '11' },
    { text: 'December', num: '12' }
  ];
  Passenger: any[] = []
  tagId: any;
  allTraveller: any[] = [];
  // DO Reactive Form Array
  currentDate = new Date();
  formGroupAddTraveller: FormGroup;
  genders: any = [{ name: "Female", value: 'F' }, { name: "Male", value: 'M' }];
  filteredCountry: Observable<any>;
  countries: any[] = [];
  passportIssuedCountries: any;

  initFormContact(): FormGroup {
    return new FormGroup({
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-zA-Z]{2,}"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      cardHolderName :new FormControl("",[Validators.required]),
      email :new FormControl("",[Validators.required,Validators.email]),
      phone : new FormControl('', [Validators.required,Validators.pattern(/^-?([0-9]\d*)?$/)]),
    });
  }
  get forminfo() {
    return this.form.controls;
  }
  initForminfo(): FormGroup {
    return this.fb.group({
      // email:['',[ Validators.email,Validators.required]],
      first_name: ['', Validators.required,],
      middle_name: ['',],
      last_name: ['', Validators.required],
      // family_member_id: ['0', Validators.required],
      date_of_birth: ['', Validators.required],
      nationality_id: ['', Validators.required],
      nationality_code: ['', Validators.required],
      country_id: ['', Validators.required],
      country_code: ['', Validators.required],
      'country-code': ['', Validators.required],
      country: [null, Validators.required],
      nationality: ['', Validators.required],
      gender: ['', Validators.required],
      // phone_type: ['H', Validators.required],
      // phone: ['', [Validators.required,Validators.pattern(/^-?([0-9]\d*)?$/)]],

      PassengerType: ['',Validators.required]?this.disabeled==false:[''] ,
      color: '#e67e22',
      passport: this.fb.group({
        passport_number: ['', Validators.required],
        expire_date: ['', Validators.required],
        issue_date: ['', Validators.required],
        issued_country: [null, Validators.required],
        country: [null, Validators.required],
        issueDate: this.fb.group({
          issue_date_day: ['', Validators.required],
          issue_date_month: ['', Validators.required],
          issue_date_year: ['', Validators.required]
        }),
        expireDate: this.fb.group({
          expiry_date_day: ['', Validators.required],
          expiry_date_month: ['', Validators.required],
          expiry_date_year: ['', Validators.required],
        }),
        travellerDob: this.fb.group({
          traveller_dob_day: ['', Validators.required],
          traveller_dob_month: ['', Validators.required],
          traveller_dob_year: ['', Validators.required],


        }),
      }),
    });
  }
  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  // ***********************
  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private seoService: SEOService,
    @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute

  ) {
    this.form = this.initFormContact();
    this.tagId = this._ActivatedRoute.snapshot.queryParamMap.get('tagId');
  }

  ngOnInit() {
    this.formData = this.searchRequest;
    for (let i = 0; i < this.formData?.adult; i++) {
      this.allTraveller.push('adult')
    }
    for (let i = 0; i < this.formData?.child; i++) {
      this.allTraveller.push('child')
    }
    for (let i = 0; i < this.formData?.lap; i++) {
      this.allTraveller.push('lap')
    }
    for (let i = 0; i < this.formData?.seat; i++) {
      this.allTraveller.push('seat')

    }
    // form Array
    this.getCountries();
    this.getFamilyRelations();
    this.formGroupAddTraveller = this.fb.group({
      formArrayAddTraveller: this.fb.array([])
    });
    for (let index = 0; index < this.searchRequest.travellers; index++) {
      this.memberTraveller.push(this.initForminfo());
    }
    this.formGroupAddTraveller.valueChanges.subscribe((res:any)=>{
      // console.log(this.formGroupAddTraveller.value)
      if(this.formGroupAddTraveller.valid){
        this.checkValidations();
      }
    })
    // *************************
    // console.log(this.formData);
    this.numberOfTeravellers.length = this.searchRequest.travellers;
    this.travellers.length = this.numberOfTeravellers.length;
    // console.log(this.travellers.length);
    // this.getFamilyRelations();
    // this.gendersListener();
    // this.errors = Array(this.numberOfTeravellers.length)
    //   .fill(0)
    //   .map(() => Array(15).fill(0));
    // console.log(this.allTraveller)
  }

  // form arrar **********************
  get memberTraveller() {
    return <FormArray>this.formGroupAddTraveller.controls["formArrayAddTraveller"];
  }
  getFamilyRelations() {
    this.apis.familyRelations().subscribe(
      (data: any) => {
        this.relations = data.data;
        // console.log('this.relations',data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => { this.countries = data },
      (err) => { console.log(err); }
    );
  }
    // auto compelete filter Country
    getfilterCountry(i) {
      if (this.countries) {
        this.filteredCountry = ((<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).controls[i].get('passport').get('issued_country')).valueChanges.pipe(
          startWith(''),
          map((value) => this._filterCountries(value)),
        );
      }
    }
    private _filterCountries(value: string): string[] {
      const filterValue = value.toLowerCase();
      if (
        this.countries.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        ).length == 0
      ) {
        this.form.controls["CreditCardCountry"].setErrors({
          incorrect: true,
          someProp: "Country Not Found Please Select Valid Country",
        });
      } else {
        return this.countries.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
      }
    }
//     isDOBOlderThan18(day,month,year ){
//       var maxBirthDate =new Date();
//       maxBirthDate=new Date(maxBirthDate.setFullYear(maxBirthDate.getFullYear()-18));
//        var maxYear = maxBirthDate.getFullYear();
//       var maxMonthOnMaxYear =maxBirthDate.getMonth();
//       var maxDayOnMaxMonthOnMaxYear =maxBirthDate.getDate();
//       if (year > maxYear) {
//         return true
//       }
//       if (year == maxYear) {
//         if (month > maxMonthOnMaxYear) {
//             return true;
//         }
//         if (month == maxMonthOnMaxYear) {
//             if (day >= maxDayOnMaxMonthOnMaxYear) {
//                 return true;
//             }

//     }
//   }
//   return false;
// }
 calculateDiff(index) {
  let thisDate = new Date();
  let dateSent = new Date(this.currentDate);
  //  console.log("hazem check date"+Math.floor((Date.UTC(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24*365)))
   if(Math.floor((Date.UTC(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24*365))<18){
     this.errorMessage[index] ="you should be older than 18"
   }
   else{
    this.errorMessage[index]=null;
 }
 return true;
}
    checkDate(index, formGroupName) {
      this.memberTraveller.controls[index].get('passport').get(`${formGroupName}`).valueChanges.subscribe(res => {
        switch (formGroupName != undefined) {
          case formGroupName == 'travellerDob': {

             this.currentDate = new Date(parseInt(res.traveller_dob_year), parseInt(res.traveller_dob_month), parseInt(res.traveller_dob_day), 0, 0, 0);


              if (this.allTraveller[index]=="adult" &&this.calculateDiff(index)){
                  this.memberTraveller.controls[index].patchValue({
                    date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month}-${ this.padLeft(res.traveller_dob_day, "0", 2)}`,

                  })
                  // console.log(this.format(res.traveller_dob_day))
                  break;
                  // if(this.allTraveller[index]!="adult")
              }else{
              this.memberTraveller.controls[index].patchValue({
                date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month}-${ this.padLeft(res.traveller_dob_day, "0", 2)}`
              })
              break;
            }


          }
          case formGroupName == 'issueDate': {
            // console.log('issueDate');
            this.memberTraveller.controls[index].get('passport').patchValue({
              issue_date: `${res.issue_date_year}-${res.issue_date_month}-${ this.padLeft(res.issue_date_day, "0", 2)}`
            })
            break;
          }
          case formGroupName == 'expireDate': {
            this.memberTraveller.controls[index].get('passport').patchValue({
              expire_date: `${res.expiry_date_year}-${res.expiry_date_month}-${ this.padLeft(res.expiry_date_day, "0", 2)}`
            })
            break;
          }
          default: {
            // console.log("No date exists!");
            break;
          }
        }
      })
    }
    countryPatchValue(index) {
      this.memberTraveller.controls[index].get('passport').get('issued_country').valueChanges.subscribe(res => {
        // console.log('passport', res)
        this.memberTraveller.controls[index].patchValue({
          country_id: this.getCountryObject(res)?.id,
          country_code: this.getCountryObject(res)?.code,
          'country-code': this.getCountryObject(res)?.code,
          country: this.getCountryObject(res),
          nationality_id: this.getCountryObject(res)?.id,
          nationality_code: this.getCountryObject(res)?.code,
          nationality: this.getCountryObject(res),
        })
        this.memberTraveller.controls[index].get('passport').patchValue({
          country:this.getCountryObject(res)
        })
      })
    }
  // get object country
  getCountryObject(country): any {
    let objCountry = this.countries.find((obj) => obj.name === country);
    return objCountry
  }
  getFamilyObject(relation): any {
    const objRelation = this.relations.find((obj) => obj.name === relation);
    return objRelation
  }
  get formMember() {
    return this.memberTraveller.controls
  }
  setEmails(e) {
    if (e) {
      // console.log('email  =======>  ',e);
      this.message = "";
      this.right = false;
      this.errorCheckEmail = false;
      this.spinner = true;
      this.apis.checkMail(e).subscribe(
        (data: any) => {
          if (data.status) {
            this.spinner = false;
            this.right = true;
            this.email = e
            this.errorCheckEmail = false;
          }
        },
        (err) => {
          this.right = false;
          this.spinner = false;
          this.loginBtn = true;
          this.errorCheckEmail = true;
          this.message = "The email has already been taken.";
        }
      );
    } else {
      this.spinner = false;
      this.right = false;
      this.loginBtn = false;
      this.errorCheckEmail = true;
      this.message = "You have entered an invalid email address!";
    }
  }
  checkValidations(){
    if(this.formGroupAddTraveller.valid ){
    this.changeTravellersFormat();
    // console.log("done", this.travellers);
    this.setOwnerEvent.emit(this.travellers);
  } else {
    // console.log("still not done", this.travellers);
  }
  }
  // format Traveller data
  changeTravellersFormat() {
    this.travellers = []
    for (let i = 0; i < this.numberOfTeravellers.length; i++) {
      let formTraveller = (<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).value[i]
      // console.log('index ',i,formTraveller);
      var travellerGender = "";
      if (formTraveller.gender === "Male") {
        travellerGender = "M";
      }
      if (formTraveller.gender === "Female") {
        travellerGender = "F";
      }

      /* We need to double check with egypt air offers  */
      this.travellers.push({
        GivenName: formTraveller.first_name,
        MiddleName: formTraveller.middle_name?formTraveller.middle_name:'',
        Surname: formTraveller.last_name,
        // family_member_id: "0",
        // family_relation_id: this.getFamilyObject(formTraveller.PassengerType).id.toString(),
        family_relation_id:  0,
        traveller_dob: formTraveller.date_of_birth,
        passport_number: formTraveller.passport.passport_number,
        expiry_date: formTraveller.passport.expire_date,
        issue_date: formTraveller.passport.issue_date,
        nationality_id: this.getCountryObject(formTraveller.passport.issued_country).id.toString(),
        nationality_code: this.getCountryObject(formTraveller.passport.issued_country).code,
        country_id: this.getCountryObject(formTraveller.passport.issued_country).id.toString(),
        country_code: this.getCountryObject(formTraveller.passport.issued_country).code,
        'country-code': this.getCountryObject(formTraveller.passport.issued_country).code,
        countryPassport: this.getCountryObject(formTraveller.passport.issued_country).code,
        nationality: this.getCountryObject(formTraveller.passport.issued_country).code,
        issuedCountry: this.getCountryObject(formTraveller.passport.issued_country).code,
        traveller_gender: travellerGender,
        phone_type: "H",
        // PassengerType: this.getFamilyObject(formTraveller.PassengerType).passenger_type,
        PassengerType: this.allTraveller[i],
        color: "#e67e22"
      });
    }
  }
    // format payload
    // allFormData(travellerForm) {
    //   const object: any = {};
    //   for (let i = 0; i < travellerForm.length; i++) {
    //     object[i + 1] = {};
    //     object[i + 1] = travellerForm[i];
    //   }
    //   object.tagId = this.tagId;
    //   object.origin = this.searchRequest.origin;
    //   object.destination = this.searchRequest.destination;
    //   object.departure_date = this.searchRequest.departure_date;
    //   object.return_date = this.searchRequest.return_date;
    //   object.passengerscount = this.searchRequest.travellers.toString();
    //   object.trip_type = this.searchRequest.trip_type;
    //   object.origin_country = this.getOriginCountry();
    //   object.destination_country = this.getDestinationCountry();
    //   object.email = this.email;
    //   object.phone = this.form.value.CreditCardZip;
    //   object.CreditCardCity = this.form.value.CreditCardCity;
    //   object.CreditCardstreetAddress = this.form.value.CreditCardstreetAddress;
    //   object.cardHolderName = this.form.value.cardHolderName;
    //   object.CreditCardCountry = this.form.value.CreditCardCountry;
    //   object.CreditCardZip = this.form.value.CreditCardZip;
    //   console.log('object ===> ',object);

    //   return object;
    // }
  getRelationObject(relation): any {
    const index = this.Passenger.findIndex((obj) => obj.member === relation);
    if (index > -1) {
      return this.Passenger[index];
    }
  }
  getOriginCountry() {
    const arr = this.searchRequest.origin.split(",");
    return arr[arr.length - 1];
  }
  getDestinationCountry() {
    const arr = this.searchRequest.destination.split(",");
    return arr[arr.length - 1];
  }
  format(e){

    e.target.value = this.padLeft(e.target.value, "0", 2);
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);

  }

}
