<div class="container text-center">
    <img title="no-result-image" alt="no-result"  class="image404" src="./assets/images/help-center/no-result.png" />

    <h3>Sorry, We couldn't find what you're looking for!</h3>
    <p>We can't find any data that match your search. Update your choices so we can try again or contact us.
    </p>
    <div class="button-container">
        <button class="button-home" [routerLink]="[homePath]">Home</button>
        <button class="button-contact" [routerLink]="['/contact-us']">Contact Us</button>
    </div>

</div>
