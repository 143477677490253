<app-navbar></app-navbar>
<div class="container">
  <div class="hotale-body-wrapper clearfix hotale-with-frame">
    <div class="hotale-page-wrapper" id="hotale-page-wrapper">
      <div class="row">
        <div class="col-md-12 ps-3">
          <div class="row  mt-4">
            <div class="col-md-12">
              <div class="d-flex align-items-center mb-4">
                <div class="">
                  <img *ngIf="hotelDetails?.logo" title="copy" style="width: 90px;height: 60px;"
                    [src]="hotelDetails?.logo" />
                  <img *ngIf="!hotelDetails?.logo" title="copy" style="width: 90px;height: 60px;"
                    src="../../../favicon.ico" />
                </div>
                <div class="ps-3">
                  <h2 class="fao_mobile_title_large font-weight-bold Font-Family-bold" *ngIf="hotelDetails?.hotel_name">
                    {{hotelDetails?.hotel_name}}</h2>
                  <h2 class="fao_mobile_title_large font-weight-bold Font-Family-bold"
                    *ngIf="!hotelDetails?.hotel_name">
                    hotel Name </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-page-builder-body">
            <div class="col-12">
              <div class="row">
                <ng-container>
                  <div class=" row ">
                    <!-- style="padding: 0;" -->
                    <div class="col-4 " style="padding: 3px;" *ngFor="let image of imagesSilder">
                      <img style="padding: 3px;" *ngIf="image.image_url" [src]="image.image_url" alt=""
                        class="rooms-images" height="300px" width="100%" />
                      <img style="padding: 3px;" *ngIf="!image.image_url" src="../../../favicon.ico" alt=""
                        class="rooms-images" height="300px" width="100%" />
                      <!-- class="my-1 py-2" -->
                    </div>
                  </div>
                </ng-container>
              </div>
              <ngb-pagination *ngIf="roomsImages?.length>6" class="d-flex justify-content-center"
                [collectionSize]="roomsImages?.length" [pageSize]="6" [maxSize]="5" (pageChange)="pageChanged($event)"
                [page]="currentPage">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
              </ngb-pagination>
            </div>
            <section>
              <div class="row">
                <div class=" col-md-9 ">
                  <div class="col-12 p-0" id="gdlr-core-column-1">
                    <div class="gdlr-core-pbf-column-content-margin gdlr-core-js"
                      style="margin: 0px 0px 0px 0px; padding: 10px 0px 20px 0px;">
                      <div class="gdlr-core-pbf-column-content clearfix row">
                        <div class="col-md-7 p-0">
                          <div class="tourmaster-room-title-item tourmaster-item-mglr tourmaster-item-pdb clearfix"
                            style="padding-bottom: 35px;">
                            <ng-container *ngFor="let item of changeRoomName(); let i = index">
                              <h3 *ngIf="i==0" class="tourmaster-room-title-item-title" style="text-transform: none;">
                                {{item}}
                              </h3>
                              <ul>
                                <li *ngIf="i>0"
                                  [ngClass]="{'color-red':item=='Non-refundable'||item=='Non-refundable '}">{{item}}
                                </li>
                              </ul>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="item follow Font-Family pl-3 mt-2">
                            <ul class=" my-2 font-icon">
                              <li>
                                <span class="font-span_1 ">Share Now : </span>
                              </li>
                              <li>
                                <a class="social-icon image-item d-flex" (click)="cop()" target="_blank">
                                  <img *ngIf="!copied" title="copy" style="width: 24px" alt="copy"
                                    src="./assets/newIcon/copy-blue-icon.svg" />
                                  <img *ngIf="copied" title="copied" style="width: 24px" alt="copied"
                                    src="./assets/newIcon/copied.png" />
                                </a>
                              </li>
                              <li><a target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                                    class="fab fa-facebook-f"></i></a></li>
                              <li><a target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><svg
                                    class="d-flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                  </svg></a></li>
                              <li><a (click)="linked()" target="_blank" rel="nofollow"
                                  href="https://www.linkedin.com/company/flyallover"><i
                                    class="fab fa-linkedin-in"></i></a>
                              </li>
                            </ul>
                          </div>

                        </div>
                        <div class="gdlr-core-pbf-element">
                          <div
                            class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                            <div class="gdlr-core-divider-line gdlr-core-skin-divider"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 " id="gdlr-core-column-3">
                      <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div class="gdlr-core-pbf-background-wrap"></div>
                        <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-with-caption gdlr-core-item-pdlr"
                              style="padding-bottom: 20px;">
                              <div class="gdlr-core-column-service-media gdlr-core-media-icon"
                                style="margin-top: 0px; margin-right: 26px;">
                                <i class="gdlr-icon-group"
                                  style="font-size: 34px; line-height: 34px; width: 34px; color: #0a0a0a;"></i>
                              </div>
                              <div class="gdlr-core-column-service-content-wrapper">
                                <div class="gdlr-core-column-service-title-wrap">
                                  <h3 class="service-title gdlr-core-skin-title">Max Guest</h3>
                                  <div class="service-caption gdlr-core-info-font gdlr-core-skin-caption">
                                    {{ roomDetails.occupancy}} Guests
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="roomDetails?.area" class="col-4" id="gdlr-core-column-4">
                      <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div class="gdlr-core-pbf-background-wrap"></div>
                        <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-with-caption gdlr-core-item-pdlr"
                              style="padding-bottom: 20px;">
                              <div class="gdlr-core-column-service-media gdlr-core-media-icon"><i
                                  class="gdlr-icon-resize"
                                  style="font-size: 34px; line-height: 34px; width: 34px; color: #0a0a0a;"></i></div>
                              <div class="gdlr-core-column-service-content-wrapper">
                                <div class="gdlr-core-column-service-title-wrap">
                                  <h3 class="service-title gdlr-core-skin-title">Room Space</h3>
                                  <div class="service-caption gdlr-core-info-font gdlr-core-skin-caption">
                                    {{roomDetails?.area}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 " id="gdlr-core-column-5">
                      <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div class="gdlr-core-pbf-background-wrap"></div>
                        <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-with-caption gdlr-core-item-pdlr"
                              style="padding-bottom: 20px;">
                              <div class="gdlr-core-column-service-media gdlr-core-media-icon"
                                style="margin-right: 27px;">
                                <i class="gdlr-icon-nature"
                                  style="font-size: 34px; line-height: 34px; width: 34px; color: #0a0a0a;"></i>
                              </div>
                              <div class="gdlr-core-column-service-content-wrapper">
                                <div class="gdlr-core-column-service-title-wrap">
                                  <h3 class="service-title gdlr-core-skin-title">Room View</h3>
                                  <div class="service-caption gdlr-core-info-font gdlr-core-skin-caption">
                                    Forest View
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                      <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                              <div class="gdlr-core-title-item-title-wrap">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                  style="font-size: 25px; font-weight: 500; letter-spacing: 0px; text-transform: none;">
                                  Beds<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngFor="let item of roomDetails.Beds ; let i =index">
                      <div class="col-4 " id="gdlr-core-column-2">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div class="gdlr-core-pbf-background-wrap"></div>
                          <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div class="gdlr-core-pbf-element">
                              <div
                                class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-with-caption gdlr-core-item-pdlr"
                                style="padding-bottom: 20px;    align-items: center;">
                                <div class="gdlr-core-column-service-media gdlr-core-media-icon">
                                  <i class="gdlr-icon-double-bed2"
                                    style="font-size: 34px; line-height: 34px; width: 34px; color: #0a0a0a;"></i>
                                </div>
                                <div class="gdlr-core-column-service-content-wrapper">
                                  <div class="gdlr-core-column-service-title-wrap">
                                    <!-- <h3  class="service-title gdlr-core-skin-title">Bed</h3> -->
                                    <div class="service-caption gdlr-core-info-font gdlr-core-skin-caption">
                                      {{item.count}} {{item.BedType[0].ota_code_list_name}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first" id="gdlr-core-column-6">
                    <div class="gdlr-core-pbf-column-content-margin gdlr-core-js" style="padding: 10px 0px 0px 0px;">
                      <div class="gdlr-core-pbf-background-wrap"></div>
                      <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                        <div class="gdlr-core-pbf-element">
                          <div
                            class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                            <div class="gdlr-core-text-box-item-content" style="font-size: 18px; text-transform: none;">
                              <p>
                                {{roomDetails.room_description_text}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-3">
                  <!-- <app-price-room></app-price-room> -->
                  <app-book-room></app-book-room>
                </div>
                <div class="hr-room">

                </div>
              </div>
            </section>
            <section>
              <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                  <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                    <div class="gdlr-core-pbf-element">
                      <div
                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                        <div class="gdlr-core-title-item-title-wrap">
                          <h3 class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                            style="font-size: 25px; font-weight: 500; letter-spacing: 0px; text-transform: none;">
                            Amenities<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row p-0  my-3">
                <ng-container *ngFor="let item of roomDetails.Amenities">
                  <div class="col-md-3 p-0">
                    <div class="">
                      <div class="gdlr-core-pbf-background-wrap" ></div>
                      <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                        <div class="gdlr-core-pbf-element">
                          <div
                            class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                            style="padding-bottom: 10px;">
                            <div class="gdlr-core-title-item-left-icon"
                              style="margin: -12px 16px 0px 0px; font-size: 35px;">
                              <i class="gdlr-icon-watch-tv"
                                style="font-size: 34px; line-height: 34px; width: 34px; color: #0a0a0a;"></i>
                            </div>
                            <div class="gdlr-core-title-item-left-icon-wrap">
                              <div class="gdlr-core-title-item-title-wrap">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                  style="font-size: 17px; font-weight: 500; letter-spacing: 0px; text-transform: none; color: #848484;">
                                  {{item.description}}<span
                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="hr-room">

              </div>
              <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                  <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                    <div class="gdlr-core-pbf-element">
                      <div
                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                        <div class="gdlr-core-title-item-title-wrap">
                          <h3 class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                            style="font-size: 25px; font-weight: 500; letter-spacing: 0px; text-transform: none;">
                           Check our Price list<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-4">
                <ng-container *ngFor="let item of roomDetails.RatePlans">
                  <div class="col-md-4">
                    <app-package-room [data]="item"></app-package-room>
                  </div>
                </ng-container>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
