import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ApisService} from 'src/app/services/apis.service';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  newPassword: string;
  ConfirmPassword: string;
  errorMessage: string;
  roundSpinner = false;

  constructor(
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private centerApis: HelpCenterServices,
    private cookie: CookieService,
    private apis: ApisService) {
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required
      ]),
    })
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {
      this.token = paramMap.params.token;
    });

  }
  resetError() {
    this.checkpass()
  }

  createPassword() {
    if (this.checkpass() ==false && this.ConfirmPassword) {
      const formValue = this.resetPasswordForm.value
      let resetPasswordData = {
        password: formValue.password,
        token: this.token
      }
      this.roundSpinner = true;
      this.centerApis.newPassword(resetPasswordData).subscribe(
        (res: any) => {
          this.roundSpinner = false;
          this.router.navigate(['/sign-in'])
        },
        (err: any) => {
          this.roundSpinner = false;
          this.errorMessage = err.error.error.token[0]
        }
      )
    }
  }

  checkpass() {
    if (this.ConfirmPassword) {
      if (this.ConfirmPassword != this.newPassword) {
        return true
      }
    }

    return false

  }
}
