import {Component, Inject, OnInit} from '@angular/core';
import {AppComponent} from "../../../app.component";
import {SEOService} from "../../../services/seo.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HelpCenterServices} from "../../../services/help-center.service";
import {MetaTagsService} from "../../../services/meta-tags.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-birthday-email',
  templateUrl: './birthday-email.component.html',
  styleUrls: ['./birthday-email.component.css']
})
export class BirthdayEmailComponent implements OnInit {

  constructor(
    private seoService: SEOService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }

  seoUpdate() {

    this.seoService.updateTitle('Birthday Email | Flyallover');
    this.seoService.updateDescription('Birthday Email');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

}
