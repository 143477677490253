<div class="details-traveler">
  <div class="container section-deatails">
    <div class="row">
      <div class="form-details">
        <div class="d-flex header-content container">
          <img
            title="Family Members"
            class="mt-2"
            alt="Family Members"
            src="./assets/images/dashboard/Additional-Info.svg"
          />
          <h4 style="margin-top: 2vh">Add Family Member</h4>
        </div>
        <div class="item-header">
          <p>
            When you book your trip you can Automatically select your
            co-traveller And book faster.
          </p>
        </div>
        <hr class="hr" />
        <form
          class="row passport-form form-selection"
          [formGroup]="memberForm"
          (submit)="editMember()"
        >
          <div class="col-12 row">
            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label class="star">First Name</mat-label>
                <input
                  matInput
                  required
                  formControlName="first_name"
                  type="text"
                  name="firstName"
                />
              </mat-form-field>
              <div
                *ngIf="memberForm.get('first_name').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('first_name').dirty ||
                  memberForm.get('first_name').touched
                "
              >
                <mat-error
                  *ngIf="memberForm.get('first_name').errors?.required"
                >
                  First Name Is Required
                </mat-error>
              </mat-error>
            </div>
            <div class="col-4 remove-astrick">
              <mat-form-field appearance="fill">
                <mat-label>Middle Name</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="middle_name"
                  name="MiddleName"
                />
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  required
                  formControlName="last_name"
                  type="text"
                  name="LastName"
                />
              </mat-form-field>
              <div
                *ngIf="memberForm.get('last_name').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('last_name').dirty ||
                  memberForm.get('last_name').touched
                "
              >
                <mat-error *ngIf="memberForm.get('last_name').errors?.required">
                  Please Enter Last Name
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-8 row">
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Country</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="country_id"
                  placeholder="Select your Country"
                  (click)="$event.target.select()"
                  [matAutocomplete]="country_id"
                />
                <mat-autocomplete #country_id="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredPersonalCountries | async"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div
                *ngIf="memberForm.get('country_id').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('country_id')?.dirty ||
                  memberForm.get('country_id')?.touched
                "
              >
                <mat-error
                  *ngIf="memberForm.get('country_id')?.errors?.someProp"
                >
                  {{ memberForm.get("country_id")?.errors?.someProp }}
                </mat-error>
                <mat-error
                  *ngIf="memberForm.get('country_id')?.errors?.required"
                >
                  Country Is Required
                </mat-error>
              </mat-error>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Nationality</mat-label>
                <input
                  type="text"
                  formControlName="nationality_id"
                  placeholder="Select your Nationality"
                  matInput
                  (click)="$event.target.select()"
                  [matAutocomplete]="nationality_id"
                />
                <mat-autocomplete #nationality_id="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredPersonalNationalities | async"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div
                *ngIf="memberForm.get('nationality_id').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('nationality_id')?.dirty ||
                  memberForm.get('nationality_id')?.touched
                "
              >
                <mat-error
                  *ngIf="memberForm.get('nationality_id')?.errors?.someProp"
                >
                  {{ memberForm.get("nationality_id")?.errors?.someProp }}
                </mat-error>
                <mat-error
                  *ngIf="memberForm.get('nationality_id')?.errors?.required"
                >
                  Nationality Is Required
                </mat-error>
              </mat-error>
            </div>
            <div class="col-6 mobile-number position-relative">
              <div class="phone-container">
                <label>Phone Number</label>
                <input
                  type="text"
                  matInput
                  (keypress)="numericOnly($event)"
                  placeholder="Select Your Phone Number"
                  class="phone"
                  ng2TelInput
                  formControlName="phone"
                />
                <div
                  *ngIf="memberForm.get('phone').valid"
                  class="ml-2 validateMark"
                >
                  <img
                    src="assets/icons/check.png"
                    class="check-icon"
                    alt="check"
                    title="check"
                  />
                </div>
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('phone').touched ||
                  memberForm.get('phone').dirty
                "
              >
                <mat-error *ngIf="memberForm.get('phone').errors?.required">
                  Phone Number Is Required
                </mat-error>
                <mat-error *ngIf="memberForm.get('phone').errors?.pattern">
                  Phone Number Is Type Only Numbers
                </mat-error>
              </mat-error>
            </div>
            <div class="col-6 position-relative">
              <div class="d-flex justify-content-between align-items-baseline">
                <mat-form-field appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    type="email"
                    placeholder="Select Your Email"
                    (focusout)="checkMail()"
                    formControlName="email"
                  />
                </mat-form-field>

                <div *ngIf="right" class="ml-2 validateMark">
                  <img
                    src="assets/icons/check.png"
                    class="check-icon"
                    alt="check"
                    title="check"
                  />
                </div>
              </div>
              <mat-error *ngIf="memberForm.get('email').errors?.someProp">
                {{ memberForm.get("email").errors?.someProp }}
              </mat-error>
              <mat-error
                *ngIf="
                  memberForm.get('email').touched ||
                  memberForm.get('email').dirty
                "
              >
                <mat-error *ngIf="memberForm.get('email').errors?.required">
                  Email Is Required
                </mat-error>
                <mat-error *ngIf="memberForm.get('email').errors?.email">
                  Email Is Invalid
                </mat-error>
              </mat-error>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Gender</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="gender"
                  placeholder="Select your gender"
                  (click)="$event.target.select()"
                  [matAutocomplete]="gender"
                />
                <mat-autocomplete #gender="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredGender | async"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div
                *ngIf="memberForm.get('gender').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('gender').dirty ||
                  memberForm.get('gender').touched
                "
              >
                <mat-error *ngIf="memberForm.get('gender').errors?.required">
                  Gender is Required
                </mat-error>
              </mat-error>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Family Relation</mat-label>
                <input
                  type="text"
                  required
                  matInput
                  formControlName="family_relation_id"
                  name="travellerCountry"
                  placeholder="Select your Family Relation"
                  [matAutocomplete]="family_relation_id"
                />
                <mat-autocomplete #family_relation_id="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredFamilyRelation | async"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div
                *ngIf="memberForm.get('family_relation_id').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
              <mat-error
                *ngIf="
                  memberForm.get('family_relation_id').dirty ||
                  memberForm.get('family_relation_id').touched
                "
              >
                <mat-error
                  *ngIf="memberForm.get('family_relation_id').errors?.required"
                >
                  Passenger is Required
                </mat-error>
              </mat-error>
              <div
                *ngIf="memberForm.get('family_relation_id').valid"
                class="ml-2 validateMark"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
            </div>

            <div class="col-6" (click)="travellerDOBCalender.open()">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  placeholder="Select Your Date Of Birth"
                  matInput
                  [matDatepicker]="travellerDOBCalender"
                  formControlName="date_of_birth"
                  matInput
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="travellerDOBCalender"
                ></mat-datepicker-toggle>
                <mat-datepicker #travellerDOBCalender></mat-datepicker>
              </mat-form-field>
              <mat-error
                *ngIf="memberForm.get('date_of_birth').errors?.someProp"
              >
                {{ memberForm.get("date_of_birth").errors?.someProp }}
              </mat-error>
              <div
                *ngIf="memberForm.get('date_of_birth').valid"
                class="ml-2 validateMark"
                style="bottom: 6vh !important"
              >
                <img
                  src="assets/icons/check.png"
                  class="check-icon"
                  alt="check"
                  title="check"
                />
              </div>
            </div>
          </div>
          <div class="col-4 Picture">
            <mat-form-field>
              <mat-label>Picture</mat-label>
              <button mat-icon-button matPrefix (click)="selectFile.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <input type="text" readonly matInput [formControl]="image" />
              <input
                multiple
                hidden
                #selectFile
                id="pic"
                type="file"
                formControlName="image"
                (change)="onFileSelected($event.target.files)"
                accept="image/*"
              />
              <mat-error>this field is required</mat-error>
            </mat-form-field>
            <div (click)="selectFile.click()">
              <img
                style="width: 12vw"
                [title]="imageAlt"
                [alt]="imageAlt"
                [src]="ImagePath"
              />
            </div>
          </div>
          <hr class="hr" />
          <div class="col-12 btns-group">
            <div class="btn-groups">
              <button class="btn button-light" (click)="closeForm.emit()">
                CANCEL
              </button>
              <button [disabled]="memberForm.status == 'INVALID'" class="btn submit-btn" type="submit">
                UPDATE
                <i *ngIf="loadingSpinner" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
