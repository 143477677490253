import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';

@Component({
  selector: 'app-vacations-details',
  templateUrl: './vacations-details.component.html',
  styleUrls: ['./vacations-details.component.css']
})
export class VacationsDetailsComponent implements OnInit {
id;
  constructor(private activatedRoute: ActivatedRoute,
             private hotelsService:HotelsApisService,) {}

  ngOnInit(): void {


}


}
