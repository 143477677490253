<!-- <div class="container-fluid"> -->
  <!-- <div> -->
    <!-- <hr *ngIf="flightType== 'Return'" class="horizental"> -->
    <!-- <div class="content"> -->
      <h6>
        <span>{{flightType}}&nbsp;&nbsp;</span>
        <span *ngIf="flightType=='Depart'">{{itinerary.flights[0][0]['DepartureDateTime'] | date: 'EEE , MMM d'}}</span>
        <span *ngIf="flightType!=='Depart'">{{itinerary.flights[1][0]['DepartureDateTime'] | date: 'EEE , MMM d'}}</span>
        
      
      </h6>
      <!-- <div class="">
         <span class="dropdown allFeatures" *ngIf="itinerary.BrandFeatures.BF||itinerary.BrandFeatures.LG">
              <span style="margin-right: 56px; cursor: pointer;"  *ngIf="itinerary.BrandFeatures.BG">get all features</span>
            <span class="dropdown-content " *ngIf="itinerary.BrandFeatures.BG">
              <span class="d-flex">
              <div> <span *ngIf="itinerary.BrandFeatures.BF">BF</span><br>
                <span  *ngIf="itinerary.BrandFeatures.BF">
                   <span *ngFor="let item of BF ;let i=index">
                   <small  >{{item.commercialName}}</small>
                   <small  *ngIf="item.application=='C'"> &nbsp; &nbsp; &nbsp;CHARGABLE</small>
                  <small  *ngIf="item.application!='C'">&nbsp; &nbsp; &nbsp;FREE</small> <br>
                </span>
              </span>
               </div>
               <div *ngIf="itinerary.BrandFeatures.LG">
                <span >LG</span><br>
                <span  *ngIf="itinerary.BrandFeatures.LG">
                   <span *ngFor="let item of LG ;let i=index">
                   <small  >{{item.commercialName}}</small>
                   <small  *ngIf="item.application=='C'"> &nbsp; &nbsp; &nbsp;CHARGABLE</small>
                  <small  *ngIf="item.application!='C'">&nbsp; &nbsp; &nbsp;FREE</small> <br>
                </span></span>
               </div>
               </span>
            </span>
            </span>
      </div> -->
    <!-- </div>
  </div> -->
<!-- </div> -->
