<ng-template #rt let-r="result" let-t="term">
  <img alt="location" title="location" src="/assets/icons/flights.svg"
    style="width: 24px; height: 24px; margin-right: 8px" />
  <ngb-highlight style="font-weight: 900;
    font-size: 16px;
    font-family: -apple-syestem, BlinkMacSystemFont, Arial, Helvetica Neue, Segoe UI, Roboto, sans-serif;" [result]="r"
    [term]="t"></ngb-highlight>
</ng-template>
<input type="text" [resultTemplate]="rt" class="form-control" #input (click)="input.select()"
  [ngbTypeahead]="flightsSearch" (selectItem)="selectAirport($event)" [(ngModel)]="inputValue" (focus)="onFocus()"
  (blur)="onFocus()" style="
    opacity: 0;
    color: #fff0;
" />
<div class="placeHolder input-search" (click)="input.select() ; input.style.opacity='1'  ">
  <ng-container *ngIf="!isFocused && !inputValue">
    <div>
      <span class="star">*</span>
      <span>To Where ?</span>
    </div>
    <div>
      <img style="width: 24px; height: 24px" alt="location-pin" title="To Where ?"
        src="/assets/images/icons/location-pin.svg" />
    </div>

  </ng-container>
  <ng-container *ngIf=" inputValue">
    <div class="text-holder-port row" >
      <span class="text-holder-up col-12 p-0" style="
      font-weight: 900;
      font-size: 18px;
      ">{{inputValue|slice:0:3}}</span>
      <span class="text-holder-down col-12 p-0" style="
      font-size: 13px;
      ">{{inputValue|slice:5:100}}</span>
    </div>

  </ng-container>
</div>
