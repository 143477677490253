import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-round-trip-calender-desktop-dialog',
  templateUrl: './round-trip-calender-desktop-dialog.component.html',
  styleUrls: ['./round-trip-calender-desktop-dialog.component.css']
})

export class RoundTripCalenderDesktopDialogComponent implements OnInit, AfterViewInit {

  @Output() setDepartDateEvent = new EventEmitter();
  @Output() setReturnDateEvent = new EventEmitter();
  departDate: any;
  returnDate: any;
  date = new Date();
  origin: any;
  destination: any;
  departDateText: any;
  priceAPiDateFormat: any;
  returnDateText: any;
  confirmedDepartDate: any;
  confirmedReturnDate: any;
  displayedDaysCounter = 1;
  datesPrices: any;
  maxPrice: any;
  minPrice: any;
  avgPrice: any;
  daysText = 'Days';
  diff: any;
  activeInput = 'departInput';
  displayedMonth: any = 0;
  days = [
    {
      shortName: 'Mon',
      longName: 'Mon',
      number: '1',
    },
    {
      shortName: 'Tue',
      longName: 'Tue',
      number: '2',
    },
    {
      shortName: 'Wed',
      longName: 'Wed',
      number: '3',
    },
    {
      shortName: 'Thu',
      longName: 'Thu',
      number: '4',
    },
    {
      shortName: 'Fri',
      longName: 'Fri',
      number: '5',
    },
    {
      shortName: 'Sat',
      longName: 'Sat',
      number: '6',
    },
    {
      shortName: 'Sun',
      longName: 'Sun',
      number: '7',
    },
  ];
  months = [
    {
      shortName: 'January',
      longName: 'January',
      number: '0',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'February',
      longName: 'February',
      number: '1',
      firstDayWeekPosition: '1',
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'March',
      longName: 'March',
      number: '2',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'April',
      longName: 'April',
      number: '3',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'May',
      longName: 'May',
      number: '4',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'June',
      longName: 'June',
      number: '5',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'July',
      longName: 'July',
      number: '6',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'August',
      longName: 'August',
      number: '7',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'September',
      longName: 'September',
      number: '8',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'October',
      longName: 'October',
      number: '9',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'November',
      longName: 'November',
      number: '10',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'December',
      longName: 'December',
      number: '11',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
  ];
  rightCalender = [
    {
      shortName: 'January',
      longName: 'January',
      number: '0',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'February',
      longName: 'February',
      number: '1',
      firstDayWeekPosition: '1',
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'March',
      longName: 'March',
      number: '2',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'April',
      longName: 'April',
      number: '3',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'May',
      longName: 'May',
      number: '4',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'June',
      longName: 'June',
      number: '5',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'July',
      longName: 'July',
      number: '6',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'August',
      longName: 'August',
      number: '7',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'September',
      longName: 'September',
      number: '8',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'October',
      longName: 'October',
      number: '9',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'November',
      longName: 'November',
      number: '10',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'December',
      longName: 'December',
      number: '11',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
  ];
  counter = 0;
  type: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: ApisService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<RoundTripCalenderDesktopDialogComponent>
  ) {
  }

  ngOnInit(): void {
    console.log('displayedMonth',this.displayedMonth);
    if (this.data.origin) {
      this.origin = this.data.origin;
    }
    if (this.data.destination) {
      this.destination = this.data.destination;
    }
    if (this.data.departDate) {
      this.departDate = this.data.departDate;
    }
    if (this.data.returnDate) {
      this.returnDate = this.data.returnDate;
    }
    if (this.data.type) {
      this.type = this.data.type;
    }
    this.initMonths();
    this.initRightCalender();

  }

  ngAfterViewInit(): void {
    this.pricesApi();
    this.handleSelectedDates();
  }

  handleSelectedDates(): void {

    if(this.data.type == "depart"){
      if (this.departDate) {
        const date = new Date(this.departDate);
        setTimeout(() => {
          this.displayedMonth = date.getMonth()
          var x = 0 ;
          const currentDate = new Date();
          if(currentDate.getFullYear() < date.getFullYear()){
            // if (this.displayedMonth < 0) {
            //   this.displayedMonth = x;
            // }
            x = 12 - currentDate.getMonth() + date.getMonth();
            this.displayedMonth = x;
          }else{

            this.displayedMonth =this.displayedMonth  ;
          }

        });
      }
      if (this.departDate) {
        const className = '.day-' + new Date(this.departDate).getTime().toString();
        document.querySelectorAll(className).forEach((item) => {
          item.classList.add('selectedDay');
          item.classList.add('selectedDatesDepartureDate');
        });
      }
    }

    if(this.data.type == "return"){
      if (this.returnDate) {
        const date = new Date(this.returnDate);
        setTimeout(() => {
          this.displayedMonth = date.getMonth()
          var x = 0 ;
          const currentDate = new Date();
          if(currentDate.getFullYear() < date.getFullYear()){
            // if (this.displayedMonth < 0) {
            //   this.displayedMonth = x;
            // }
            x = 12 - currentDate.getMonth() + date.getMonth();
            this.displayedMonth = x;
          }else{

            this.displayedMonth = this.displayedMonth ;
          }

        });
      }else{
        const date = new Date(this.departDate);
        setTimeout(() => {
          this.displayedMonth = date.getMonth()
          var x = 0 ;
          const currentDate = new Date();
          if(currentDate.getFullYear() < date.getFullYear()){
            // if (this.displayedMonth < 0) {
            //   this.displayedMonth = x;
            // }
            x = 12 - currentDate.getMonth() + date.getMonth();
            this.displayedMonth = x;
          }else{

            this.displayedMonth =  this.displayedMonth  ;
          }

        });
      }
      if (this.returnDate) {
        const className = '.day-' + new Date(this.returnDate).getTime().toString();
        document.querySelectorAll(className).forEach((item) => {
          item.classList.add('selectedDay');
          item.classList.add('selectedDatesReturnDate');
        });
      }
    }

    // if (this.returnDate) {
    //   const className = '.day-' + new Date(this.returnDate).getTime().toString();
    //   document.querySelectorAll(className).forEach((item) => {
    //     item.classList.add('selectedDay');
    //     item.classList.add('selectedDatesReturnDate');
    //   });
    // }
    if (this.departDate && this.returnDate) {
      this.calculateDaysBetween();
      this.selectDatesBetween();
    }

    if (this.departDate && !this.returnDate) {
      this.calculateDaysBetween();
      this.selectDatesBetween();
    }
  }

  initRightCalender(): void {
    for (let i = 0; i < this.date.getUTCMonth(); i++) {
      const poppedItem = this.rightCalender.shift();
      poppedItem.year = this.date.getFullYear() + 1;
      this.rightCalender.push(poppedItem);
    }
    for (const item of this.rightCalender) {
      item.firstDayWeekPosition = this.firstDayWeekPosition(item);
      const monthNumber = parseFloat(item.number);
      const date = new Date(item.year, monthNumber + 1, 0);
      item.numberOfDays = Array(date.getDate());
      if ((this.date.getMonth() + 1).toString() === item.number) {
        item.displayedMonth = true;
      }
    }
  }

  initMonths(): void {
    for (let i = 0; i < this.date.getUTCMonth(); i++) {
      const poppedItem = this.months.shift();
      poppedItem.year = this.date.getUTCFullYear() + 1;
      this.months.push(poppedItem);
    }
    for (const item of this.months) {
      item.firstDayWeekPosition = this.firstDayWeekPosition(item);
      /***/
      item.numberOfDays = Array(new Date(item.year, parseFloat(item.number) + 1, 0).getDate());

      /***/

      if(this.data.type == "depart"){
        const departDateObj = new Date(this.departDate);
        if ((departDateObj.getUTCMonth()).toString() === item.number) {
          item.displayedMonth = true;
        }
      }

      if(this.data.type == "return"){
        const returnDateObj = new Date(this.returnDate);
        if ((returnDateObj.getUTCMonth()).toString() === item.number) {
          item.displayedMonth = true;
        }
      }

    }

  }

  timeStamp(year, month, day) {
    return new Date(year, month, day).getTime();
  }

  daysInMonth(month): any {
    const days = new Date(month.year, month.number, 0).getDate();
    return Array(days);
  }

  firstDayWeekPosition(month): any {
    return new Date(month.year, month.number, 1).getDay();
  }

  toArray(numberToConvert): any {
    if (numberToConvert === 0) {
      return Array(6);
    }
    return Array(numberToConvert - 1);
  }

  selectDay(month, day): void {
    if ((this.date.getDate() ) > day && this.date.getMonth() == month.number) {

    } else if (this.type === 'depart') {
      this.setDepartDate(month, day);
    } else if (this.type === 'return') {
      this.setReturnDate(month, day);
    }
    if (this.departDate && this.returnDate) {
      this.calculateDaysBetween();
      this.selectDatesBetween();
    }
    this.pricesApi('Return');
  }

  setDepartDate(month, day, reset = false): void {
    this.departDate = new Date(month.year, month.number, day);
    if (this.returnDate && !this.checkIfReturnDateBiggerThanDepartDate()) {
      this.clearDates();
      this.departDate = new Date(month.year, month.number, day);
    }
    const className = '.year-' + month.year + '.month-' + month.number + '.day-' + day;
    document.querySelectorAll(className).forEach((item) => {
      item.classList.toggle('selectedDay');
      item.classList.add('selectedDatesDepartureDate');
    });
    if (!reset) {
      this.dialogRef.close({departureDate: this.departDate, returnDate: this.returnDate });
    }
  }

  setReturnDate(month, day): void {
    
    this.clearReturnDate();
    this.returnDate = new Date(month.year, month.number, day);
    if (this.checkIfReturnDateBiggerThanDepartDate()) {
      const className = '.year-' + month.year + '.month-' + month.number + '.day-' + day;
      document.querySelectorAll(className).forEach((item) => {
        item.classList.toggle('selectedDay');
        item.classList.add('selectedDatesReturnDate');
      });
      this.dialogRef.close({departureDate: this.departDate, returnDate: this.returnDate});
    } else {
      this.setDepartDate(month, day, true);
    }
  }

  checkIfReturnDateBiggerThanDepartDate(): any {
    return new Date(this.returnDate).getTime() > new Date(this.departDate).getTime();
  }

  selectDatesBetween(): void {
    if (this.departDate && this.returnDate) {
      let className = '';
      for (let i = new Date(this.departDate).getTime(); i <= new Date(this.returnDate).getTime(); i = i + (1000 * 60 * 60 * 24)) {
        className = '.day-' + i.toString();
        document.querySelectorAll(className).forEach((item) => {
          item.classList.add('selectedDay');
        });
      }
    }
  }

  clearReturnDate(): void {
    const dates = this.document.querySelectorAll('.selectedDatesReturnDate');
    Array.from(dates).forEach(date => {
      date.classList.remove('selectedDay');
      date.classList.remove('selectedDatesReturnDate');
    });
  }

  clearDates(): void {
    this.confirmedDepartDate = null;
    this.confirmedReturnDate = null;
    this.departDate = null;
    this.returnDate = null;
    this.departDateText = '';
    this.returnDateText = '';
    const dates = this.document.querySelectorAll('.monthDay');
    Array.from(dates).forEach(date => {
      date.classList.remove('selectedDay');
      date.classList.remove('selectedDatesDepartureDate');
      date.classList.remove('selectedDatesReturnDate');
    });
    this.activeInput = 'departInput';
  }

  calculateDaysBetween() {
    if (this.returnDate && this.departDate) {
      this.diff = Math.round((this.returnDate - this.departDate) / (1000 * 60 * 60 * 24)) + 1;
      if (this.diff === 1) {
        this.daysText = 'Day';
      } else {
        this.daysText = 'Days';
      }
    }
  }

  nextMonth(): void {
    this.displayedMonth++;
  }

  previousMonth(): void {
    this.displayedMonth--;
  }

  pricesApi(tripType = 'Return') {
    if (this.origin && this.destination && this.origin.length >= 3 && this.destination.length >= 3) {
      if (this.departDate) {
        this.priceAPiDateFormat = this.datePipe.transform(this.departDate, 'yyyy-MM-dd');
        tripType = 'Return';
      }
      this.apis.leadPriceCalendar(this.origin, this.destination, tripType, this.priceAPiDateFormat).subscribe((response: any) => {
        this.datesPrices = response.data.calendar;
        this.minPrice = response.data.min_price;
        this.maxPrice = response.data.max_price;
        this.avgPrice = response.data.avg_price;
        this.viewPrices();
      }, err => {
        this.datesPrices = undefined;
      });
    }
  }

  viewPrices() {
    document.querySelectorAll('.price').forEach((item) => {
      item.innerHTML = '';
    });
    this.datesPrices.forEach((el: any, i) => {
      const date = new Date(el.date);
      const className = '.year-' + date.getFullYear() + '.month-' + date.getMonth() + '.day-' + date.getDate() + ' .price';
      document.querySelectorAll(className).forEach((item) => {
        item.innerHTML = '$' + el.price;
        if (el.price === this.minPrice) {
          item.classList.add('minPrice');
        } else if (el.price === this.maxPrice) {
          item.classList.add('maxPrice');
        } else if (el.price === this.avgPrice) {
          item.classList.add('avgPrice');
        }
      });
    });
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

}
