<!-- ======= Events Section ======= -->
<section id="events" class="events">
  <div class="container-fluid" data-aos="fade-up">

    <div class="section-header">
      <h2>Rooms</h2>
      <p>Share <span>Your Moments</span> In our Hotel </p>
    </div>
    <div class="row">
      <owl-carousel-o  [options]="customOptions" class="d-flex justify-content-center">
        <ng-container>
          <ng-container>
            <ng-template carouselSlide>
              <div class="col-12 hover"  mat-button (click)="openDialog()">
                <a  class="roomLink">
                <div class="swiper-slide event-item d-flex flex-column justify-content-end" style="background-image: url(assets/images/hotels/4.jpg)">
                  <h3>Custom Parties</h3>
                  <div class="price align-self-start">$99</div>
                  <p class="description">
                    In delectus sint qui et enim. Et ab repudiandae inventore quaerat doloribus. Facere nemo vero est ut dolores ea assumenda et. Delectus saepe accusamus aspernatur.
                  </p>
                </div><!-- End Event item -->
                </a>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-12  hover" mat-button (click)="openDialog()">
                <div class="swiper-slide event-item d-flex flex-column justify-content-end" style="background-image: url(assets/images/hotels/3.jpg)">
                  <h3>Private Parties</h3>
                  <div class="price align-self-start">$289</div>
                  <p class="description">
                    In delectus sint qui et enim. Et ab repudiandae inventore quaerat doloribus. Facere nemo vero est ut dolores ea assumenda et. Delectus saepe accusamus aspernatur.
                  </p>
                </div><!-- End Event item -->
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-12 hover" mat-button (click)="openDialog()" >
                <div class="swiper-slide event-item d-flex flex-column justify-content-end" style="background-image: url(assets/images/hotels/4.jpg)">
                  <h3>Custom Parties</h3>
                  <div class="price align-self-start">$99</div>
                  <p class="description">
                    In delectus sint qui et enim. Et ab repudiandae inventore quaerat doloribus. Facere nemo vero est ut dolores ea assumenda et. Delectus saepe accusamus aspernatur.
                  </p>
                </div><!-- End Event item -->
              </div>
            </ng-template>

            <ng-template carouselSlide>
              <div class="col-12  hover" mat-button (click)="openDialog()" >
                <div class="swiper-slide event-item d-flex flex-column justify-content-end" style="background-image: url(assets/images/hotels/2.jpg)">
                  <h3>Birthday Parties</h3>
                  <div class="price align-self-start">$499</div>
                  <p class="description">
                    Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
                  </p>
                </div><!-- End Event item -->
              </div>
            </ng-template>

          </ng-container>
        </ng-container>
      </owl-carousel-o>
    </div>




  </div>
</section><!-- End Events Section -->

