<div class="err" *ngIf=" control && control.touched && control.errors">
    <div *ngIf="control.errors.required">Required</div>
    <div *ngIf="control.errors.email || control.errors.pattern">Invalid email format</div>
    <div *ngIf="control.errors.maxlength">Maximum length {{control.errors.maxlength.requiredLength}} characters</div>
    <div *ngIf="control.errors.minlength">Minimum length {{control.errors.minlength.requiredLength}} characters</div>
    <!-- these errors are related to file-input-accessor package -->
    <div *ngIf="control.errors.maxWidth">Maximum allowed width for an image is 3000px</div>
    <div *ngIf="control.errors.maxHeight">Maximum allowed height for an image is 1000px</div>
    <div *ngIf="control.errors.minWidth">Minimum allowed width for an image is 1000px</div>
    <div *ngIf="control.errors.minHeight">Minimum allowed height for an image is 200px</div>
</div>