<mat-dialog-content class="mat-typography">
<div class="container" >
  <div class="row mt-5">
    <h1 class="name-handel"> Suite Junior </h1>
    <ngb-carousel>
      <ng-template ngbSlide>
          <img
            src="assets/images/hotels/img/bar.jpg"
            alt=""
            width="100%"
            style="border-radius: 12px ; height: 580px;object-fit: cover;"
          />

        <div class="carousel-caption"></div>
      </ng-template>
      <ng-template ngbSlide>
          <img
            src="assets/images/hotels/img/gallery2.jpg"
            alt=""
            width="100%"
            style="border-radius: 12px;height: 580px; object-fit: cover;"
          />

        <div class="carousel-caption"></div>
      </ng-template>
      <ng-template ngbSlide>
          <img
            src="assets/images/hotels/img/gallery1.jpg"
            alt=""
            width="100%"
            style="border-radius: 12px ; height: 580px; object-fit: cover"
          />
        <div class="carousel-caption"></div>
      </ng-template>
    </ngb-carousel>
  </div>
  <!-- slider -->


  <!-- =======  Room Details Section ======= -->
  <div class="row">
    <div class="col-12 p-3">

      <div class="sleep mt-4">
        <h2> Sleeps </h2>
        <span> Sleeps 2 people</span>
      </div>
      <div class="Room-details mt-5">
        <h2>Room details</h2>
        <span> 667-sq-foot room with a balcony</span>

        <p class="mt-5 "> Internet - <span> Free WiFi </span></p>
        <p> Entertainment - <span> Smart television with cable channels and pay movies </span></p>
        <p> Food & Drink - <span> Room service, free minibar items, and free bottled water </span></p>
        <p> Sleep - <span> Memory foam bed, blackout drapes/curtains, and linens </span></p>
        <p> Bathroom - <span> Private bathroom, jetted bathtub and separate shower </span></p>
        <p> Practical - <span> Safe, iron/ironing board, and laptop workspace </span></p>
        <p> Comfort - <span> Air conditioning and daily housekeeping </span></p>

        <div class="mt-5">
          <span >Room is accessed via exterior corridors</span><br>
          <span>Connecting/adjoining rooms can be requested, subject to availability</span>
        </div>

      </div>

      <div class="booking  py-2 mt-4" >
        <button >Book Now</button>
      </div>

    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
<!--    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>-->
  </mat-dialog-actions>
<!--  <div class="row ">-->
<!--    <div class="col-12 pading">-->
<!--      <app-show-rate  ></app-show-rate>-->
<!--      <app-add-reviews [keyId]="'flight_offer_id'" ></app-add-reviews>-->

<!--    </div>-->
<!--  </div>-->
</div>
</mat-dialog-content>
