import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-discount',
  templateUrl: './dialog-discount.component.html',
  styleUrls: ['./dialog-discount.component.css']
})
export class DialogDiscountComponent implements OnInit {
  discount:any = [
    {
      'title':'Discount applied as follows :',
      'offers':[
        {'offer':'HOF (Head of the Family) no discount.'},
        {'offer':'SPS (Spouse) (25% Low Season & 15% Shoulder & 5% High Season).'},
        {'offer':'FNN (Child) (33% Low Season & 25% in both Shoulder and High Seasons). Age till 15Y.(age15 years means till passenger reach his 16th birthday).'},
      ]
    },
    {
      'title':' Discount applied to/from Egypt on direct international flights only.',
      'offers':[]
    },
    {
      'title':'Family could be any combination of the following :',
      'offers':[
        {'offer':'HOF, SPS, and up to 2 FNN. (HOF could be father or mother, SPS could be father or mother).'},
        {'offer':'HOF and SPS only. (HOF could be father or mother, SPS could be father or mother).'},
        {'offer':'HOF and up to 2 FNN only. (HOF could be father or mother).'},
      ]
    },
    {
      'title':' All family must travel together outbound and inbound.',
      'offers':[]
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
