<div class="">
  <div ng-app="traveler-card">
    <div ng-controller="TravelerController as travelerCtrl" class="row">
      <div class="traveler-card bg-light" [formGroup]="getFamliyMember">
        <div class="header-part" *ngIf="travelerData">
          <div class="title-part text-uppercase">{{travelerData.first_name+' '+travelerData.last_name}}&nbsp;</div>


        </div>
        <div class="form-group mb-4">
          <input type="text" id="firstName" placeholder="first Name" formControlName="firstName"
            [(ngModel)]="setFamliyMember.firstName" class="input-form-customize" required>
        </div>

        <div class="form-group mb-4">

          <input type="text" id="middleName" placeholder="middle Name" formControlName="middleName"
            [(ngModel)]="setFamliyMember.middleName" name="middleName" class="input-form-customize" required>
        </div>

        <div class="form-group mb-4">

          <input type="text" id="lastName" placeholder="last Name" formControlName="lastName"
            [(ngModel)]="setFamliyMember.lastName" name="lastName" class="input-form-customize" required>
        </div>

        <div class="form-group mb-4" formGroupName="birthData">
          <div class="date-form d-flex">
            <div class="month col-5">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Date of birth</mat-label>
                <mat-select required formControlName="birthOfMonth" [(ngModel)]="setFamliyMember.birthOfMonth"
                  (ngModelChange)="checkDate('travellerDob')" placeholder="Month">
                  <mat-option *ngFor="let month of months" [value]="month?.num">{{month?.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="days col-3">
              <mat-form-field class="example-full-width">
                <input formControlName="birthOfDay" [(ngModel)]="setFamliyMember.birthOfDay" type="text"
                  (ngModelChange)="checkDate('travellerDob')" placeholder="Day" class="example-right-align"
                  maxlength="2" matInput>
              </mat-form-field>
            </div>
            <div class="years col-4">
              <mat-form-field class="example-full-width">
                <input formControlName="birthOfYear" [(ngModel)]="setFamliyMember.birthOfYear" type="text"
                  (ngModelChange)="checkDate('travellerDob')" placeholder="Years" maxlength="4" minlength="4"
                  class="example-right-align" matInput>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="title-part">Gender:&nbsp;</div>
        <div class="form-group mb-4">
          <div class="d-flex">
            <div class="form-check pe-4">
              <input class="form-check-input" type="radio" id="Male" value="Male" formControlName="gender"
                [checked]="setFamliyMember.gender === 'Male'"> <label class="form-check-label" for="Male">Male</label>
            </div>
            <div class="form-check ">
              <input class="form-check-input" type="radio" id="Female" value="Female" formControlName="gender"
                [checked]="setFamliyMember.gender === 'Female'">
              <label class="form-check-label" for="Female">Female</label>
            </div>
          </div>
        </div>
        <div class="title-part">Passport Details&nbsp;</div>
        <div class="form-group mb-4">
          <input type="text" id="passportNumber" name="passport_number" placeholder="Passport Number" ng-model="$testFR"
            formControlName="passport_number" [(ngModel)]="setFamliyMember.passport_number" class="input-form-customize"
            required>
        </div>

        <div class="form-group mb-4" formGroupName="issue_date">
          <div class="date-form d-flex">
            <div class="month col-5">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Issuing Date</mat-label>
                <mat-select required formControlName="issueOfMonth" [(ngModel)]="setFamliyMember.issueOfMonth"
                  (ngModelChange)="checkDate('issueDate')" placeholder="Month">
                  <mat-option *ngFor="let month of months" [value]="month?.num">{{month?.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="days col-3">
              <mat-form-field class="example-full-width">
                <input formControlName="issueOfDay" [(ngModel)]="setFamliyMember.issueOfDay" type="text"
                  (ngModelChange)="checkDate('issueDate')" placeholder="Day" class="example-right-align" maxlength="2"
                  matInput>
              </mat-form-field>
            </div>
            <div class="years col-4">
              <mat-form-field class="example-full-width">
                <input formControlName="issueOfYear" [(ngModel)]="setFamliyMember.issueOfYear" type="text"
                  (ngModelChange)="checkDate('issueDate')" placeholder="Years" maxlength="4" minlength="4"
                  class="example-right-align" matInput>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="form-group mb-4" formGroupName="expiry_date">
          <div class="date-form d-flex">
            <div class="month col-5">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Expiry Date</mat-label>
                <mat-select required formControlName="expiryOfMonth" [(ngModel)]="setFamliyMember.expiryOfMonth"
                  (ngModelChange)="checkDate('expireDate')" placeholder="Month">
                  <mat-option *ngFor="let month of months" [value]="month?.num">{{month?.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="days col-3">
              <mat-form-field class="example-full-width">
                <input formControlName="expiryOfDay" [(ngModel)]="setFamliyMember.expiryOfDay" type="text"
                  [disabled]="disableForm" (ngModelChange)="checkDate('expireDate')" placeholder="Day"
                  class="example-right-align" maxlength="2" matInput>
              </mat-form-field>
            </div>
            <div class="years col-4">
              <mat-form-field class="example-full-width">
                <input formControlName="expiryOfYear" [(ngModel)]="setFamliyMember.expiryOfYear" type="text"
                  (ngModelChange)="checkDate('expireDate')" placeholder="Years" maxlength="4" minlength="4"
                  class="example-right-align" matInput>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-group mb-4">
          <div class="input-form-customize px-5">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Issuing Country</mat-label>
              <mat-select required formControlName="issuedCountry" [(ngModel)]="setFamliyMember.issuedCountry"
                placeholder="Issuing Country">
                <mat-option *ngFor="let country of countries" [value]="country?.id">{{country?.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="set-pnt">
          <div type="button" class="btn btn-danger">
            <span *ngIf="disableForm" (click)="deleteTraveler()">Delete</span>
            <span *ngIf="!disableForm" (click)="close()">close</span>
          </div>
          <div type="button" class="btn btn-success">
            <span *ngIf="disableForm" (click)="editForm()">Edit</span>
            <span *ngIf="!disableForm" (click)="submit()">Save</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
