import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
declare var $: any
interface Location {
  name: string;
  type: string;
  icon: string;
  latitude: string;
  longitude: string;
  country_code: string;
  model_type: string;
}

@Component({
  selector: 'app-search-engine-hotel',
  templateUrl: './search-engine-hotel.component.html',
  styleUrls: ['./search-engine-hotel.component.css']
})
export class SearchEngineHotelComponent implements OnInit, AfterViewInit {
  @Output() sendNewrequset = new EventEmitter<any>();
  hotelSearchForm!: FormGroup
  checkinDate: any;
  checkoutDate: any;
  todayDate: Date = new Date();
  locations: Location[] = [];
  filteredLocations: Location[] = [];
  isLoading = false;
  selectedLocation: Location | null = null;
  tomorrow: any;
  thrdDay: any;
  viewwCheckinDate: string;
  viewwCheckoutDate: string;
  travellersObj: any;
  errorMessage: any;
  trevallerValue: any;
  travellersNumberSelected: any;
  localData: any
  hotelDest: any = {
    country_code: "",
    hotelCode: "",
    latitude: "",
    longitude: "",
    model_type: "",
    name: "",
  };

  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('hotelSearch')) {

      this.getLocalData()
    }
    this.setValues();
    console.log('sda',this.router.url)
  }

  ngAfterViewInit(): void {
    this.datePicker();
    this.getTrevallerValues();

  }
  datePicker() {

    $(".check-hotel").daterangepicker({
      autoUpdateInput: false,
      startDate: this.checkinDate ? this.datePipe.transform(this.checkinDate, "MM/dd/YYYY") : this.tomorrow,
      endDate: this.checkoutDate ? this.datePipe.transform(this.checkoutDate, "MM/dd/YYYY") : this.thrdDay,
      locale: {
        cancelLabel: 'close',
        format: 'MM/DD/YYYY'
      },
      minDate: this.todayDate,
      isInvalidDate: (date) => {
        // Mark dates as invalid based on some condition
        return false;
      },
    });
    $('.check-hotel').on('apply.daterangepicker', (ev, picker) => {
      this.checkinDate = picker.startDate
      this.checkoutDate = picker.endDate
      this.viewwCheckinDate = this.datePipe.transform(picker.startDate, "EE MM-dd")
      this.viewwCheckoutDate = this.datePipe.transform(picker.endDate, "EE MM-dd")
      console.log('this.date => ', picker);
    });

    $('.check-hotel').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });
  }
  setValues() {
    this.tomorrow = this.todayDate.getDate() + 1
    this.thrdDay = this.todayDate.getDate() + 2
    this.hotelSearchForm = this.fb.group({
      location: [''],
      checkin: [''],
      checkout: [''],
      guests: [''],
    });

    this.travellersObj =
    {
      room: {
        id: 'room',
        name: 'Room',
        initialValue: 1,
        currentValue: 1,
        minValue: 1,
        maxValue: 8,
      },
      adult: {
        id: 'adult',
        name: 'Adults',
        initialValue: 1,
        currentValue: 1,
        minValue: 1,
        maxValue: 12,
      },
      child: {
        id: 'child',
        name: 'Children',
        ageFrom: 2,
        ageTo: 12,
        initialValue: 0,
        currentValue: 0,
        minValue: 0,
        maxValue: 12,
      },
    }
  }

  onLocationInput() {
    this.isLoading = true;
    this.locations = [];
    const input =
      this.hotelSearchForm.get('location')?.value?.toLowerCase() || '';
    this.apis.searchHotelByName(input).subscribe(
      (data: any) => {
        this.locations = data.data;
        this.isLoading = false;
        console.log('locations', this.locations);

      },
      (err) => {
        this.isLoading = false;
      }
    );
  }


  getIconForType(type: string): string {
    const typeToIcon: { [key: string]: string } = {
      City: 'assets/hotel-icon-search/cityIcon.svg',
      hotel: 'assets/hotel-icon-search/bedIcon.svg',
      location: 'assets/hotel-icon-search/locationIcon.svg',
      airport: 'assets/hotel-icon-search/airportIcon.svg',
      Place: 'assets/hotel-icon-search/locationIcon.svg',
    };
    return typeToIcon[type] || 'assets/hotel-icon-search/airportIcon.svg';
  }

  selectLocation(location: Location) {
    this.hotelSearchForm.patchValue({ location: location.name });
    this.selectedLocation = location;
    this.hotelDest = this.selectedLocation
    this.locations = [];
  }
  inc(traveller) {
    console.log(traveller);

    const num = +this.travellersObj[traveller].currentValue;
    if (this.travellersObj[traveller].minValue < num) {

      this.travellersObj[traveller].currentValue = num - 1
    } else {
      this.errorMessage = "لقد وصلت الي الحد الادني من التحديد"
    }
    this.getTrevallerValues()
  }
  plus(traveller) {
    const num = +this.travellersObj[traveller].currentValue;
    if (this.travellersObj[traveller].maxValue > num) {

      this.travellersObj[traveller].currentValue = num + 1
    } else {
      this.errorMessage = "لقد وصلت الي الحد الاقصي من التحديد"
    }
    this.getTrevallerValues()
  }
  getTrevallerValues() {
    const numAdult = +this.travellersObj.adult.currentValue
    const numChild = +this.travellersObj.child.currentValue
    this.travellersNumberSelected = numAdult + numChild
    this.trevallerValue = `${this.travellersObj.room.currentValue} room ${this.travellersNumberSelected} guests`;
  }
  initHotelRequest() {
    switch (this.hotelDest.model_type) {
      case "City":
        return {
          start_date: this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'),
          adults: this.travellersObj.adult.currentValue,
          children: this.travellersObj.child.currentValue,
          rooms: this.travellersObj.room.currentValue,
          children_ages: this.travellersObj.child.currentValue,
          travellers: this.travellersNumberSelected,
          radius: 20,
          sabre_rating_min: 3,
          sabre_rating_max: 5,
          type: this.hotelDest.model_type.toLowerCase(),
          country: this.hotelDest.country_code,
          city_name: this.hotelDest.name,
        }
      case "airport":
        return {
          city_name: this.hotelDest.name,
          start_date: this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'),
          adults: this.travellersObj.adult.currentValue,
          children: this.travellersObj.child.currentValue,
          rooms: this.travellersObj.room.currentValue,
          children_ages: this.travellersObj.child.currentValue,
          travellers: this.travellersNumberSelected,
          radius: 20,
          sabre_rating_min: 3,
          sabre_rating_max: 5,
          type: 'location',
          latitude: this.hotelDest.latitude,
          longitude: this.hotelDest.longitude,
        }
      // case "airport"
    }
  }

  onSearch() {
    localStorage.setItem('hotelSearch', JSON.stringify(this.initHotelRequest()));
    this.router.navigate(['new-hotels'], { queryParams: this.initHotelRequest() });
    // window.location.reload()
  }

  getLocalData(){

  }

}
