<div class="container">
  <div class="row">
    <div class="col-md-12 visa-header">
      <h4>Do You Need A Visa ?</h4>
      <span>Over 10 Million Travelers Have Used CIBT visas</span>
    </div>
  </div>
  <div class="visa-inputs">
    <form [formGroup]="form">
      <div *ngIf="destinationError || nationalityError || residencyError || purposeError" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{error}}
      </div>
      <div class="row">
        <div class="col-md-4" [ngClass]="{'errorBorder': destinationError}">
          <label class="star">Destination</label>
          <input class="form-control"
                 type="text"
                 formControlName="destination"
                 placeholder="Select your Destination"
                 matInput
                 (click)="$event.target.select()"
                 [matAutocomplete]="destinations">
          <mat-autocomplete #destinations="matAutocomplete">
            <mat-option *ngFor="let option of filteredDestinations | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-4" [ngClass]="{'errorBorder': nationalityError}">
          <label class="star">Nationality</label>
          <input class="form-control"
                 type="text"
                 formControlName="nationality"
                 placeholder="Select your Nationality"
                 matInput
                 (click)="$event.target.select()"
                 [matAutocomplete]="nationalities">
          <mat-autocomplete #nationalities="matAutocomplete">
            <mat-option *ngFor="let option of filteredNationalities | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-4" [ngClass]="{'errorBorder': residencyError}">
          <label class="star">Residency</label>
          <input class="form-control"
                 type="text"
                 formControlName="residency"
                 placeholder="Select your Residency"
                 matInput
                 (click)="$event.target.select()"
                 [matAutocomplete]="residency">
          <mat-autocomplete #residency="matAutocomplete">
            <mat-option *ngFor="let option of filteredResidency | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" [ngClass]="{'errorBorder': purposeError}">
          <label class="star">purpose of trip is</label>
          <input class="form-control"
                 type="text"
                 formControlName="purpose"
                 placeholder="Select your purpose"
                 matInput
                 (click)="$event.target.select()"
                 [matAutocomplete]="purpose">
          <mat-autocomplete #purpose="matAutocomplete">
            <mat-option *ngFor="let option of filteredPurpose | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-4 submit">
          <button class="btn" (click)="submit()">Check Requirements</button>
        </div>
      </div>
    </form>
  </div>
</div>

