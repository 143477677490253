<app-navbar></app-navbar>
<!-- /////////////////Airline Bagging Page/////////////////// -->
<div class="container" *ngIf="airlineInfo">
    <div class="row">
        <!-- /////////////////Airline Name/////////////////// -->
        <div class="col-12">
            <h1 class="fao_mobile_title_large mt-5 mb-3 ">&nbsp;{{ airlineInfo.name }} Online Check</h1>
        </div>
        <!-- /////////////////End of Airline Name & Start Of Airline Info/////////////////// -->
        <div class=" col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div class="row g-0 mainRow">
                <div class="col-12">
                    <ngb-carousel>
                        <ng-template ngbSlide *ngFor="let item of images; index as i ">
                            <div class="picsum-img-wrapper  mb-3">
                                <img class="carsolImages" src={{item}} width="100%"
                                     height="540" alt="{{ airlineInfo.name }}" title="{{ airlineInfo.name }}"/>
                            </div>
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                    </ngb-carousel>
                    <div class="booking py-4 text-center mt-2">
                    <a   style="text-decoration: none;" [href]=airlineInfo?.online_check>Go To {{airlineInfo.name}} Online Check </a>
                    </div>
                    <!-- <div class="d-flex justify-content-center ">
                        <button class=" fao_des_btn_large py-4 text-center mt-3"  style=" width: 480px !important; font-size: 22px !important" [routerLink]="[airlineInfo?.online_check]">
                            Go To {{airlineInfo.name}} Online Check 
                        </button>
                    </div> -->
                    
                    <!--          <div class="row pt-4">-->
                    <!--            <div class="col-4 tit-description">-->
                    <!--              <h4 class="title"> MORE ON Ailines</h4>-->
                    <!--                <ng-container *ngFor="let item of data; let i = index">-->
                    <!--                  <div *ngIf="i<20 && i>5 " [routerLink]="['/Online-Check/',item.name]">-->
                    <!--                    <a class="otherName" [routerLink]="['/Online-Check/',item.name]">{{item.name}}</a>-->
                    <!--                    <div class="aboutOtherAirlines"><small class="otherDesc">{{item?.online_check_descrption |-->
                    <!--                      slice:0:75}}</small></div>-->
                    <!--                  </div>-->
                    <!--                </ng-container>-->
                    <!--            </div>-->
                    <!--            <div class="col-8">-->
                    <!--              <p class="aboutText"> {{airlineInfo?.online_check_descrption| slice:0:2800}} </p>-->
                    <!--              <div class="airLink">-->
                    <!--              <a class="fontlast" [href]=airlineInfo?.online_check>For Latest Updates Go-->
                    <!--                To {{airlineInfo.name}} Online Check</a></div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
        <!-- /////////////////End of Airline Info & Start Of Other Airlines/////////////////// -->
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 otherAirlinesPhoto">
          <div class="container mb-5">
            <div class="row">
              <div class="col-12">
                <div class="link text-center">
                  <a [routerLink]="['/airline-description/',airlineInfo.callsign]"><img
                    src="{{ airlineInfo.image}}"
                    height="100"
                    width="200"
                  /></a>
                  <a
                    [routerLink]="['/Bagging-Information/',airlineInfo.callsign]"
                  >
                    <br/>
                    Bagging Information
                  </a>
                  <br/>
                  <a class=" Font-family" [routerLink]="['/cancellation-policy/',airlineInfo.callsign]">
                    Cancellation Policy
                  </a>
                  <br/>
                  <a [routerLink]="['/Online-Check/',airlineInfo.callsign]">
                    Online Check
                  </a>
                  <br/>

                  <a [routerLink]="['/FAQ/',airlineInfo.callsign]">
                    FAQ
                  </a>
                </div>
              </div>
            </div>
          </div>
            <div class="row readMore mb-5 Font-family">
                <app-read-more
                        *ngIf="about"
                        [text]="about"
                        maxLength="20"
                        class="aboutText Font-family"
                ></app-read-more>
            </div>
          <section>
            <div class="col-lg-1 pt-2 order-lg-first  mb-5">
              <div class="sticky-content">
                <div class="social-icons social-icons-colored social-icons-vertical">
                  <div class="icon-share  mb-5">
                    <span class="social-label Font-family ">SHARE:</span>
                    <a class="social-icon image-item" (click)="cop()"
                       target="_blank">
                      <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                           src="./assets/newIcon/copy-blue-icon.svg"/>
                      <img *ngIf="copied" title="copied" class="copied" alt="copied"
                           src="./assets/newIcon/copied.png"/>
                    </a>
                    <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                        <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                      <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                        <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                      <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                        <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
</div>
<!-- -------  rating  ----- -->
<div class="container">
    <div class="row g-0">
        <div class="col-7">
            <app-show-rate [reviewData]="reviewData" ></app-show-rate>
            <app-add-reviews [cityId]="airlineId" [keyId]="'airline_id'" (addReview)="setReview($event)" ></app-add-reviews>
        </div>

        <div class="col-5 offerSlide">
            <app-point-slider-offers></app-point-slider-offers>
        </div>
    </div>
    <!-- show review -->
    <div class="">
        <div class="">
            <div class="mb-5 ">
                <div class="col-7 handel-pos mt-4">
                    <button class="fao_Show_btn_large" (click)="showReviews()" *ngIf="!showRev">Show All Reviews</button>
                    <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="showRev">Hide All Reviews</button>
                </div>

                <div class="all-reviews">
                    <div class="row">
                        <owl-carousel-o *ngIf="showRev" [options]="customOptions"
                                        class="d-flex justify-content-center mt-5">
                            <ng-container *ngFor='let item of reviewData.reviews ; let i = index'>
                                <ng-container>
                                    <ng-template carouselSlide>
                                        <app-share-reviews [data]="item" [image]="item.main_image"></app-share-reviews>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row my-5">
        <h2 class="fao_mobile_title_large text-center mt-5"> MORE ON Airlines </h2>
        <div class="horizontalLine mb-5"></div>
        <!--  <div class="horizontalLine"></div>-->
        <owl-carousel-o *ngIf="data" [options]="customOptions" class="d-flex justify-content-center">
            <ng-container *ngFor="let item of data; let i = index">
                <ng-container *ngIf="i<data.length && i>0 ">
                    <ng-template carouselSlide>
                        <div class="col col-md-12 col-lg-12 hover">
                            <div class="offer card-body">
                                <div class="offer-img">
                                    <a [routerLink]="['/Online-Check/',item.callsign]">
                                        <img
                                                src="{{item.main_image}}"
                                                alt="{{ item.name}}"
                                                class="rounded w-100 p-3"
                                                height="250px"
                                        />
                                    </a>
                                    <div class="name">
                                        <a [routerLink]="['/Online-Check/',item.callsign]">
                                            <h3 class="fao_mobile_title_meduim text-center mt-3">
                                                {{ item.name }}
                                            </h3>
                                        </a>
                                    </div>
                                </div>
                                <div
                                        style="
                        background-color: #ddd4d4;
                        height: 1px;
                        width: 95%;
                        margin: auto;
                      "
                                ></div>
                                <div>
                                    <p class="fao_mobile_prgraph ">
                                        {{ item.online_check_descrption |slice:0:100 }}...
                                    </p>
                                    <div class="center-class">
                                        <button class="fao_des_btn_med my-4" [routerLink]="['/Online-Check/',item.callsign]">
                                            MORE DETAILS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>
<!-- /////////////////Start Of airlines /////////////////// -->
<div class="mb-5">
    <div class="section-header text-center">
        <h2 class="fao_mobile_title_large"> Other Airlines</h2>
        <div class="horizontalLine mb-5"></div>
    </div>
    <app-home-airlines-logos *ngIf="data" [airLines]="data"></app-home-airlines-logos>

    <!-- /////////////////End Of airlines/////////////////// -->
    <!-- /////////////////Start Of Offers /////////////////// -->
<!--    <div class="mb-5">-->
<!--        <app-home-slider-offers></app-home-slider-offers>-->
<!--    </div>-->
    <!-- /////////////////End Of Offers/////////////////// -->
</div>
<app-footer></app-footer>
