import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-search-engine',
  templateUrl: './search-engine.component.html',
  styleUrls: ['./search-engine.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchEngineComponent implements OnInit {
  tabsObj: any;
  tabs: any;
  constructor() { }

  ngOnInit(): void {
    this.setValue()
  }
  setValue() {
    this.tabsObj = [
      { name: 'Flights', code: 'flight',image:'/assets/hotel-icon-search/flight.svg'},
      { name: 'Hotels', code: 'hotel' ,image:'/assets/hotel-icon-search/bedIcon.svg'},
    ]
    this.tabs = {
      name: 'Flight', code: 'flight'
    }
  }
  setTab(tab: any) {
    this.tabs = tab
  }
}
