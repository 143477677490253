import {Component, ElementRef, ViewChild, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SEOService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  playVideo: Boolean = false;
  aboutUsData = {};
  aboutUsDataExists: boolean = false;
  videoUrl;
  safeUrl;
  formSubmitted: boolean = false;
  pageUrl;
  contactUsForm: FormGroup;
  validationMessages = {
    'fullName': [
      {type: 'required', message: 'Full name is required'},
      {type: 'minlength', message: 'Full name must be at least 6 characters long'},
      {type: 'maxlength', message: 'Full name cannot be more than 40 characters long'},
      {type: 'pattern', message: 'Full name must not contains numbers'}
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}
    ],
    'mobileNumber': [
      {type: 'required', message: 'Mobile number is required'},
      // { type: 'validCountryPhone', message: 'Phone incorrect for the country selected' }
    ],
    'subject': [
      {type: 'required', message: 'Subject is required'}
    ],
    'message': [
      {type: 'required', message: 'Message is required'}
    ]
  };
  queryParams = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  };
  captchaKey: string = '';
  siteKey: string = '';

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    public dom: DomSanitizer,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.siteKey = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
    this.apis.getAboutUs().subscribe(
      (res) => {
        this.aboutUsData = res['data'];
        this.videoUrl = this.aboutUsData['video_link'];
        this.aboutUsDataExists = true;
      },
      (err) => {
      }
    );
    this.activatedRoute.url.subscribe(
      (activeUrl) => {
        this.pageUrl = window.location.pathname;
      }
    );
    this.queryParams.name = this.activatedRoute.snapshot.queryParams.name;
    this.queryParams.email = this.activatedRoute.snapshot.queryParams.email;
    this.queryParams.phone = this.activatedRoute.snapshot.queryParams.phone;
    this.queryParams.subject = this.activatedRoute.snapshot.queryParams.subject;
    this.queryParams.message = this.activatedRoute.snapshot.queryParams.message;
    this.createForm();
  }

  seoUpdate() {

    this.seoService.updateTitle('About Us | Flyallover');
    this.seoService.updateDescription('No Matter The Accasion, flyallover Mission is to make the planning and booking of your trip to not only be efficient, but Easy!');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/about/header2.png');
  }

  @ViewChild('iframe') iframe: ElementRef;

  play() {
    this.playVideo = true;
    this.iframe.nativeElement.src += '?autoplay=1';
    this.safeUrl = this.returnVideosUrl();
  }

  returnVideosUrl() {
    return this.dom.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videoUrl}`);
  }

  createForm() {
    this.contactUsForm = this.fb.group({
      fullName: new FormControl(this.queryParams.name, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$')
      ])),
      email: new FormControl(this.queryParams.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$')
      ])),
      mobileNumber: new FormControl(this.queryParams.phone, Validators.compose([
        Validators.required,
      ])),
      subject: new FormControl(this.queryParams.subject, Validators.required),
      message: new FormControl(this.queryParams.message, Validators.required)
    });
  }

  navigateOnSamePage() {

    this.queryParams.name = this.contactUsForm.value.fullName;
    this.queryParams.email = this.contactUsForm.value.email;
    this.queryParams.phone = this.contactUsForm.value.mobileNumber;
    this.queryParams.subject = this.contactUsForm.value.subject;
    this.queryParams.message = this.contactUsForm.value.message;
    this.router.navigate([`${this.pageUrl}`], {
      queryParams: {
        name: this.contactUsForm.value.name,
        email: this.contactUsForm.value.email,
        phone: this.contactUsForm.value.mobileNumber,
        subject: this.contactUsForm.value.subject,
        message: this.contactUsForm.value.message
      }
    });
  }

  onSubmitContactUsForm(formValue) {
    // check for captcha key
    if (this.captchaKey) {
      this.apis.postToContactUs(formValue.fullName, formValue.email,
        formValue.mobileNumber, formValue.subject, formValue.message, this.captchaKey).subscribe(
        (res) => {
          this.formSubmitted = true;
        },
        (err) => {
        }
      );
    }
  }

  resolved(captchaResponse: string) {
    this.captchaKey = captchaResponse;
  }

}
