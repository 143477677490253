import {Component, OnInit, Inject} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from 'src/app/services/apis.service';
import {FormControl, Validators} from '@angular/forms';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {DOCUMENT} from '@angular/common';
import {BlogService} from 'src/app/services/blog/blog.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  cityGuides: any;
  cityGuidesExist: boolean = false;
  offers: any;
  loadingOffers: boolean = true;
  offersExist: boolean = false;
  emailSubscription = new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  message: string = '';
  spinner: boolean = false;

  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };

  siteKey: string = '';
  captchaKey: string = '';
  showCaptchaComponent: boolean = false;

  pageSize = 8;
  articles: any;
  loading = true;

  constructor(
    private apis: ApisService,
    private centerApis: HelpCenterServices,
    @Inject(DOCUMENT) private document: Document,
    private blogService: BlogService,
  ) {
  }


  ngOnInit(): void {
    this.siteKey = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
    this.getAllArticles();

    const params = {
      page: 1,
      paginate: 6
    };
    // getting offers
    this.centerApis.getOffersData(params).subscribe(
      (data: any) => {
        this.offers = data.data;
        this.loadingOffers = false;
        this.offersExist = true;
      },
      err => {
        this.loadingOffers = false;
        this.offersExist = false;
      }
    );

    // getting city guides
    // this.apis.getCitiesGuide().subscribe(
    //   (data: any) => {
    //     this.cityGuides = data.cityGuides;
    //     this.cityGuidesExist = true;
    //   },
    //   err => {
    //     this.cityGuidesExist = false;
    //   }
    // )


  }

  submitSubscription() {
    this.spinner = true;
    this.message = '';

    if (this.emailSubscription.valid) {
      this.showCaptchaComponent = true;

    } else {
      this.spinner = false;
      this.emailSubscription.markAsTouched();
    }
  }

  resolved(captchaResponse: string) {
    this.captchaKey = captchaResponse;
    this.spinner = false;
    if (this.captchaKey && this.emailSubscription.valid) {
      this.apis.subscribeByEmail(this.emailSubscription.value, this.captchaKey).subscribe(data => {
        this.spinner = false;
        this.showCaptchaComponent = false;
        this.message = 'Successfully Subscribed!';
        let messageElement = this.document.getElementById('subscriptionMsg');
        messageElement.classList.toggle('d-none');
        messageElement.classList.remove('text-success', 'text-danger');
        messageElement.classList.add('text-success');

      }, err => {
        this.spinner = false;
        this.showCaptchaComponent = false;
        this.message = err.error.error[Object.keys(err.error.error)[0]][0] || 'Something wrong happened';
        let messageElement = document.getElementById('subscriptionMsg');
        messageElement.classList.toggle('d-none');
        messageElement.classList.remove('text-success', 'text-danger');
        messageElement.classList.add('text-danger');
      });
    }
  }

  getAllArticles(page: number = 1) {
    const params = {
      page: page,
      paginate: this.pageSize
    };
    this.blogService.getAllArticles(params).subscribe((res: any) => {
      this.articles = res.data;
      this.loading = false;
    }, err => {
      this.articles = [];
      this.loading = false;
    });
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
