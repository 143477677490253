<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family "> Help Home </h1>
            <!-- <div class="frm">
                <input type="text" placeholder="How can we help you?" [(ngModel)]="searchText"
                    (keyup.enter)="onSearch()" />
                <button (click)="onSearch()"><img title="Terms and Conditions" alt="Search"  src="./assets/images/help-center/search.svg" height="30" /></button>
            </div> -->
        </div>
    </div>
    <!-- start skeleton preloader -->
    <div class="help-center-body" *ngIf="loading">
        <div class="container">
            <div class="search-results">
                <div class="search-results-header Font-Family">
                    <h4 class="Font-Family">
                        <ngx-skeleton-loader count="1" [theme]="{width:'200px'}"></ngx-skeleton-loader>
                    </h4>
                </div>

                <div class="search-results-body">
                    <div class="item Font-Family">
                        <ngx-skeleton-loader count="1" [theme]="{width:'300px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="4" [theme]="{width:'100%', height:'15px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <!-- end skeleton preloader -->
    <!-- start search-results -->

    <div class="container">
        <div class="row mt-5">
            <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of helpPolicy; let i = index" >
            <div class="icon-box" (click)="termsDetails(item.id)" >
                <h4><a>{{item.title}}</a></h4>
                <p [innerHTML]="item.description"> </p>
            </div>
            </div>
        
        </div>
    </div>
    <div class="container">
        <div class="row mt-5" >
            <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100"   >
            <div class="icon-box">
                <h2 class="mb-3"> Related articles </h2>
                <div *ngFor="let item of ArticlesLinks; let i = index">
                    <h5><a (click)="ArticlesLinksDetails(item.id)">{{item.title}}</a> </h5>
                </div>
                
                
            </div>
            </div>
        
        </div>
    </div>
    

    <!-- <div class="help-center-body" *ngIf="!loading && !backEndError">
        <div class="container">
            <div class="search-results my-5 Font-Family" >
                <div class="search-results-header Font-Family">
                    <h6 class="fao_mobile_title_large" [innerHTML]="termsConditions['title_content']"></h6>
                </div>

                <div class="search-results-body">
                    <div class="item">
                        <p class="Font-Family my-5" [innerHTML]="termsConditions['content']"></p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- end search-results -->
    <div *ngIf="backEndError && !loading">
        <app-no-result-error [homePath]="'/support'"></app-no-result-error>
    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
