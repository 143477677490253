import {Component, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {Observable, of} from 'rxjs';
import {switchMap, map, catchError} from 'rxjs/operators';
import {ApisService} from 'src/app/services/apis.service';
import {Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from '../../services/seo.service';
import { HttpClient  } from '@angular/common/http';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {

  constructor(
    private seoService: SEOService,
    private centerApis: HelpCenterServices,
    private apis: ApisService,
    private router: Router,
    private http: HttpClient) {
    this.seoUpdate();
  }

  popularDestinations = [];
  popularDestinationsExist: boolean = false;
  peopleLovedData = [];
  peopleLovedDataExist: boolean = false;
  thingsToDoData = [];
  thingsToDoDataExist: boolean = false;
  selectArray = [];
  selectArray2 = [];
  hotelCityLat;
  hotelCityLong;
  citiesPhotos = [];
  peopleLovedDataPhotos = [];
  thingsToDoDataPhotos = [];
  popularDestinationsSectionExist: boolean = true;
  peopleLovedSectionExist: boolean = true;
  thingsToDoSectionExist: boolean = true;
  images = {
    popularPhotosExists: false,
    peopleLovedDataPhotosExists: false,
    thingsToDoDataPhotosExists: false
  };
  url: string;
  ipAddress: any;

  ngOnInit(): void {
    this.url = this.router.url;
      this.getPopularDestinations();
  }

  seoUpdate() {

    this.seoService.updateTitle('City Guides | FlyAllOver');
    this.seoService.updateDescription('Flyallover City guides will help you to Plan your trip and Find flights, hotels, vacation rentals, things to do, and more in one place');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/explore/header.png');
  }

  getPopularDestinations() {
    return this.centerApis.getPopularDestinations().subscribe(
      (res: any) => {
        this.popularDestinations = res.data;
        if (this.popularDestinations) {
          this.popularDestinations.forEach((city, i) => {
            this.getCardSectionData(city);
            this.citiesPhotos.push(undefined);
          });
        }
        this.popularDestinationsExist = true;
      },
      (err) => {
        this.popularDestinationsSectionExist = false;
        this.popularDestinationsExist = false;
      }
    );
  }

  getPhotos(photoRef, arrayName, loaderFlag, index) {
    this.centerApis.getPhotosOfDestinations(photoRef).subscribe(
      (res: any) => {
        arrayName.splice(index, 1, res.data);
        this.images[`${loaderFlag}`] = true;
      },
      (err) => {
        arrayName.push('../../../assets/images/explore-details/No_picture_available.png');
      }
    );
  }

  getCardSectionData(city) {
    const index = this.popularDestinations.indexOf(city);
    this.centerApis.getCardSectionsData(city.name, city.location.lat, city.location.lng).subscribe(
      (res: any) => {
        if (index === 0) {
          this.peopleLovedData = res.data.basis_data;
          res.data.basis_data.forEach((element, i) => {
            element.photos.forEach(photo => {
              this.peopleLovedDataPhotos.push(undefined);
              // this.getPhotos(photo.photo_reference, this.peopleLovedDataPhotos, 'peopleLovedDataPhotosExists', i)
            });
          });
          this.peopleLovedDataExist = true;
        }
        if (index === 1) {
          this.thingsToDoData = res.data.basis_data;
          res.data.basis_data.forEach((element, i) => {
            element.photos.forEach(photo => {
              this.thingsToDoDataPhotos.push(undefined);
              // this.getPhotos(photo.photo_reference, this.thingsToDoDataPhotos, 'thingsToDoDataPhotosExists', i)
            });
          });
          this.thingsToDoDataExist = true;
        }

      },
      (err) => {
        this.peopleLovedSectionExist = false;
        this.thingsToDoSectionExist = false;
        this.peopleLovedDataExist = false;
        this.thingsToDoDataExist = false;
      }
    );
  }

  navigateToExploreDetails(city) {
    let cityName = city.formatted_address.split(',')[0];
    let countryCode = city.formatted_address.split(',')[2].trim();
    this.router.navigate([`/explore-details/${cityName}/${countryCode}`]);
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      switchMap(term => {
        if (term.length >= 3) {
          return this.apis.oldGeoAutocomplete(term, 'CITY').pipe(
            map(el => {
              this.selectArray = el.data.arr;
              this.selectArray2 = el.data.res;
              return el.data.arr;
            }),
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }

  selectItem(event) {
    let cityDetails = {
      name: event.item,
      countryCode: event.item.split(',')[2]
    }
    let index = this.selectArray.indexOf(event.item)
    this.selectArray2.forEach(element => {
      if (this.selectArray2.indexOf(element) === index) {
        this.hotelCityLat = element.latitude;
        this.hotelCityLong = element.longitude;
      }
    });
    this.router.navigate([`/explore-details/${cityDetails.name.split(',')[0].replace(' ', '-')}/${cityDetails.countryCode.trim()}`])
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
