<app-navbar></app-navbar>

<!-- <div *ngIf="!data">
  <div class=" all-card col-12 p-0">
    <div class="vt-placeholder-loading " *ngFor="let Loading of loadingsShare" [style]="{height: Loading.width}">
      <div class="vt-animated-background-loading" [style]="{height: Loading.width}"></div>
    </div>
  </div>

</div> -->

<div class="container" *ngIf="data">
  <div class="row mt-5">
    <h2 class="fao_mobile_title_large mb-4 font-weight-bold Font-Family-bold">{{data.title}}</h2>
    <div class="col-7">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper m-auto">
            <img src="{{data.image}}" width="100%" height="400" style="object-fit: cover;"
              class="rounded" alt="Random second slide" alt="{{ data.title}}"
              title="{{data.title }}" />

          </div>
          <div class="carousel-caption"></div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="col-5">
     
      <div class="row gy-4">
        <div class="col-md-6">
          <div class="info-item" data-aos="fade" data-aos-delay="200">
            <i class="bi bi-geo-alt"></i>
            <h3>Address</h3>
            <p>99 Madison Ave</p>
            <p>New York, NY 10016</p>
          </div>
        </div><!-- End Info Item -->

        <div class="col-md-6">
          <div class="info-item" data-aos="fade" data-aos-delay="300">
            <i class="bi bi-telephone"></i>
            <h3>Call Us</h3>
            <p>+1 212-381-1167</p>
            <p>For issues or complaints</p>
          </div>
        </div><!-- End Info Item -->

        <div class="col-md-6">
          <div class="info-item" data-aos="fade" data-aos-delay="400">
            <i class="bi bi-envelope"></i>
            <h3>Email Us</h3>
            <p>info@flyallover.com</p>
            <p>support@flyallover.com</p>
          </div>
        </div><!-- End Info Item -->

        <div class="col-md-6">
          <div class="info-item" data-aos="fade" data-aos-delay="500">
            <i class="bi bi-clock"></i>
            <h3>Open Hours</h3>
            <p>Monday - Friday</p>
            <p>9:00AM - 05:00PM</p>
          </div>
        </div><!-- End Info Item -->

      </div>
      <div class="item follow Font-Family pl-3 mt-1">
            
        <ul class=" my-2 font-icon">
          <li><h6 class="font-span_1 ">Share Now : </h6></li>
          <li>
            <a class="social-icon image-item" (click)="cop()" target="_blank">
              <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                src="./assets/newIcon/copy-blue-icon.svg" />
              <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                src="./assets/newIcon/copied.png" />
            </a>
          </li>
          <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                class="fab fa-facebook-f"></i></a></li>
          <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                class="fab fa-twitter"></i></a></li>
          <!-- <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><i
                class="fab fa-youtube"></i></a></li> -->
          <!-- <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><i
                class="fab fa-instagram"></i></a></li> -->
          <li><a (click)="linked()" target="_blank" rel="nofollow"
              href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
        </ul>
      </div>
    </div>
</div> 
<div class="row">
    <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" >
      <div class="icon-box" >
        <!-- <h4><a href="">{{data.title}}</a></h4> -->
        <p [innerHTML]="data.description"> </p>
      </div>
    </div>
   
  </div>

<div class="my-5">
</div>
 <div class="container" *ngIf="data">
 
   
    <div class="row w-100 px-2 my-5">
      <div class="col-6 px-2">
          <div class="item follow Font-Family">
            
            <ul class=" p-4 my-2 font-icon">
              <li><h6 class="font-span_1 ">Share Now : </h6></li>
              <li>
                <a class="social-icon image-item" (click)="cop()" target="_blank">
                  <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                    src="./assets/newIcon/copy-blue-icon.svg" />
                  <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                    src="./assets/newIcon/copied.png" />
                </a>
              </li>
              <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                    class="fab fa-facebook-f"></i></a></li>
              <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                    class="fab fa-twitter"></i></a></li>
              <!-- <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><i
                    class="fab fa-youtube"></i></a></li>
              <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><i
                    class="fab fa-instagram"></i></a></li> -->
              <li><a (click)="linked()" target="_blank" rel="nofollow"
                  href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>
      </div>

    </div>
 

</div> 
</div>
<app-footer></app-footer>
