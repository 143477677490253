<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 col-xxl-3 col-lg-5">
      <app-dash-sidebar></app-dash-sidebar>
    </div>
    <div class="col-xl-8 col-xxl-9 col-lg-7">
    <div class="row mainRow headline" style="margin-right: -10vw; margin-top: 6.5vh" *ngIf="flights" >
          <div style="padding-bottom: 17px"  class="col-4 position-relative" *ngFor="let item of flights"
           (click)="click(item.id)"    (click)="details = !details; flightDetails(item.id)"  >
              <div class="booking-confirmed" id={{item.id}}>
                  <div class="booking-top">
                      <!-- *ngIf="itinerary" -->
                      <div class="transport"   >
                          <span>{{item.origin}}</span>
                          <div class="line">
                            <span class="airplane">&#9992;</span>
                          </div>
                          <span>{{item.destination}}</span>
                      </div>
                      <!-- *ngIf="itinerary" -->
                      <div class="content" >
                          <div>
                              <span>
                                  <strong>flight no.</strong>
                                  <p>{{item.id}}</p>
                              </span>
                              <span>
                                  <strong>class</strong>
                                  <p>{{item.fare_class}}</p>
                              </span>
                              <span>
                                  <strong>terminal</strong>
                                  <p *ngIf="item.trip_type.toLowerCase() == 'return'">Round trip</p>
                                  <p *ngIf="item.trip_type.toLowerCase() != 'return'">{{item.trip_type}}</p>
                              </span>
                          </div>
                          <div>
                              <span>
                                  <strong>date</strong>
                                  <p>{{item.departure_date | date:'mediumDate'}}</p>
                              </span>
                              <span>
                                  <strong>time</strong>
                                  <p >{{item.departure_date | date:'shortTime'}}</p>
                              </span>
                              <span>
                                  <strong>pnr</strong>
                                  <p>{{item.pnr}}</p>
                              </span>
                          </div>
                      </div>

                  </div>


              </div>
          </div>
          <div *ngIf="addOne"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
          <div *ngIf="addTwo"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
          <div *ngIf="addTwo"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
        </div>
        <app-orders-tab></app-orders-tab>
        <div class="recommended" *ngIf="flights == 0">
          <h2 class="text-center" *ngIf="recommendedPlaces">We Recommend you try these places</h2>
          <div class="recommended-section">
            <div
              class="recommended-box"
              *ngFor="let place of recommendedPlaces"
            >
              <div class="head-img">{{ place.origin_city }}</div>
              <img
                src="{{ place.image }}"
                title="Recommended Place "
                [alt]="place.title"
              />
              <p>{{ place.title }}</p>
              <span>{{ place.offer_name }}</span>
              <span>
                Popular with travellers in {{ place.origin }},
                {{ place.origin_city }}</span
              >
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
<app-footer></app-footer>
