<div class="col-12">
  <div class="custom-container">
    <div class="row">
      <div class="col-md-6 left">
        <div class="month" *ngFor="let month of months , let i = index"
             [ngClass]="{'active': displayedMonth == i }">
          <div class="header">
            <div class="monthHeader">
              <div class="monthName"><span>{{month.longName}}</span></div>
              <div class="monthYear"><span>{{month.year}}</span></div>
              <div *ngIf="displayedMonth >0" class="monthArrow previousMonthArrow" (click)="previousMonth()">
                <img src="./assets/images/back.png" alt="">
              </div>
            </div>
            <div class="weekDays">
              <span *ngFor="let day of days">{{day.shortName}}</span>
            </div>
          </div>
          <div class="monthDays">
            <div class="monthDayContainer disabledDate" *ngFor="let day of toArray(month.firstDayWeekPosition);"></div>
            <ng-container *ngFor="let day of month.numberOfDays; let i = index">
              <div class="monthDayContainer">
                <div
                  class="monthDay {{'year-' +month.year}} {{'month-'+month.number}} {{'day-'+ (i+1)}} {{'day-'+timeStamp(month.year ,month.number ,i+1)}}"
                  (click)="selectDay(month , i+1)"
                  [ngClass]="{'disabledDate': (this.date.getDate()) > i+1 && this.date.getMonth() == month.number}">
                  <span class="day">{{i + 1}}</span>
                  <span class="price"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-6 right">
        <div class="month" *ngFor="let month of months , let i = index"
             [ngClass]="{'active': displayedMonth+1 == i}">
          <div class="header">
            <div class="monthHeader">
              <div class="monthName"><span>{{month.longName}}</span></div>
              <div class="monthYear"><span>{{month.year}}</span></div>
              <div *ngIf="displayedMonth < 10" class="monthArrow nextMonthArrow" (click)="nextMonth()">
                <img src="./assets/images/next.png" alt="">

              </div>
            </div>
            <div class="weekDays">
              <span *ngFor="let day of days">{{day.shortName}}</span>
            </div>
          </div>
          <div class="monthDays">
            <div class="monthDayContainer disabledDate" *ngFor="let day of toArray(month.firstDayWeekPosition);"></div>
            <ng-container *ngFor="let day of month.numberOfDays; let i = index">
              <div class="monthDayContainer">
                <div
                  class="monthDay {{'year-' +month.year}} {{'month-'+month.number}} {{'day-'+ (i+1)}} {{'day-'+timeStamp(month.year ,month.number ,i+1)}}"
                  (click)="selectDay(month , i+1)"
                  [ngClass]="{'disabledDate': (this.date.getDate() ) > i+1 && this.date.getMonth() == month.number}">
                  <span class="day">{{i + 1}}</span>
                  <span class="price"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
