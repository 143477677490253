<div class="container"  *ngIf="visaData && visaDestinationCountryName">
  <div class="row pos-x">
    <!-- <button class="close mat-button"  (click)="close()">X</button> -->
    <!-- <i class="fa-solid fa-xmark"></i> -->
    <!-- <i class="fa-thin fa-xmark"></i> -->
    <span (click)="close()" class="close">X</span>
    <div class="handelBeforeGo">
      <h1>Know before you go to <span class="font-count" *ngIf="countryName">{{countryName}}</span></h1>
      <div class="d-flex">
        <p class="handel-flight" >{{data.origin.country_name}} </p>
        <!-- <img src="../../../assets/images/flights/arrow-right-solid.svg" width="15px" class="mx-2" > -->
        <img src="../../../assets/images/flights/direction-arrow.png"  class="mx-2 handelRetImg" >
        <p class="handel-flight"> <span class="">{{visaDestinationCountryName.name}}</span></p>
      </div>
    </div>
    <div class="row box-details-2 mt-3">
      <mat-tab-group  (selectedIndexChange)="getFreshScore($event)">
<!-- ............................................................................ Depart ............................... -->
        <mat-tab class="box-1 depart " label="Depart" (click)="isHealthRequirementsOpened= !isHealthRequirementsOpened">
          <ng-template matTabContent >
            <div class="row mt-3 p-3">
              <div class="message">
                <span *ngIf="visaRequired">Visa is Required To </span>
                <span *ngIf="!visaRequired">Visa is NOT Required To </span>
                <span>{{visaDestinationCountryName.name}}</span>
                <a style="margin-left: 10px" target="_blank"  [routerLink]="['/visa']">
                  <!-- href="{{visaData?.landingurl}}" -->
                  <button  class="link link-visa btn " *ngIf="visaRequired">Get Your Visa</button>
                </a>
              </div>
            </div>

            <div class="row mt-3 box-details">
              <mat-tab-group>
                <div class="Health-requirements">
                  <mat-tab label="Entry & Health Requirements">
                    <div class="row p-3 depart-des">
                      <p class="text-muted " *ngIf="readMoreEntryHealthRequirements"
                      [innerHTML]="visaData?.HealthEntryData?.entry_health_requirements "></p>
                    </div>

                  </mat-tab>
                  </div>
                <div class="icon-table">
                  <mat-tab label="Entry Restrictions">
                    <div class="row p-3 depart-des">
                      <p class="text-muted " [innerHTML]="visaData?.HealthEntryData?.entry_restrictions "></p>
                      </div>
                    </mat-tab>
                </div>
                <div class="In-country">
                  <mat-tab label="In-country Experience">
                    <div class="row p-3 depart-des">
                    <p class="text-muted " *ngIf="readMoreCountryExperience"
                 [innerHTML]="visaData?.HealthEntryData?.country_experience "></p>
                </div>
                  </mat-tab>
                  </div>
                <div>
                <mat-tab label="Transit Restrictions">
                  <div class="row p-3 depart-des">
                    <p class="text-muted " *ngIf="readMoreTransitRestrictions"
                    [innerHTML]="visaData?.HealthEntryData?.transit_restrictions "></p>
                    </div>
                </mat-tab>
                </div>
                <div>
                   <mat-tab label="Re-Entry Requirements">
                      <div class="row p-3 depart-des">
                        <p class="text-muted " *ngIf="readMoreEntryRequirements"
                          [innerHTML]="visaData?.HealthEntryData?.re_entry_requirements "></p>
                      </div>
                   </mat-tab>
                  </div>
              </mat-tab-group>
            </div>
          </ng-template>
        </mat-tab>

<!-- ................................................................................Retuen........................................... -->

        <mat-tab label="Return" (click)="isHealthRequirementsOpenedR= !isHealthRequirementsOpenedR " (selectedIndexChange)="returnFunction()">
          <ng-template matTabContent>
            <div class="row mt-3 p-3">
              <div class="message">
                <span *ngIf="visaRequired">Visa is Required To </span>
                <span *ngIf="!visaRequired">Visa is NOT Required To </span>
                <span>{{data.origin.country_name}}</span>
                <a style="margin-left: 10px" target="_blank"  [routerLink]="['/visa']">
                  <!-- href="{{visaData?.landingurl}}" -->
                  <button  class="link link-visa btn " *ngIf="visaRequired">Get Your Visa</button>
                </a>
                <p class="text-muted fs-2 text-dur">Return to {{countryName}} </p>
              </div>
            </div>
            <div class="row mt-3 box-details">
              <mat-tab-group>
                <div class="Health-requirements">
                  <mat-tab label="Entry & Health Requirements">
                    <div class="row p-3 depart-des">
                      <p class="text-muted " *ngIf="readMoreEntryHealthRequirements"
                      [innerHTML]="visaDataR?.HealthEntryData?.entry_health_requirements"></p>
                    </div>

                  </mat-tab>
                  </div>
                <div class="icon-table">
                  <mat-tab label="Entry Restrictions">
                    <div class="row p-3 depart-des">
                      <p class="text-muted " [innerHTML]="visaDataR?.HealthEntryData?.entry_restrictions"></p>
                      </div>
                    </mat-tab>
                </div>
                <div class="In-country">
                  <mat-tab label="In-country Experience">
                    <div class="row p-3 depart-des">
                    <p class="text-muted " *ngIf="readMoreCountryExperience"
                 [innerHTML]="visaDataR?.HealthEntryData?.country_experience "></p>
                </div>
                  </mat-tab>
                  </div>
                <div>
                <mat-tab label="Transit Restrictions">
                  <div class="row p-3 depart-des">
                    <p class="text-muted " *ngIf="readMoreTransitRestrictions"
                    [innerHTML]="visaDataR?.HealthEntryData?.transit_restrictions "></p>
                    </div>
                </mat-tab>
                </div>
                <div>
                   <mat-tab label="Re-Entry Requirements">
                      <div class="row p-3 depart-des">
                        <p class="text-muted " *ngIf="readMoreEntryRequirements"
                          [innerHTML]="visaDataR?.HealthEntryData?.re_entry_requirements "></p>
                      </div>
                   </mat-tab>
                  </div>
              </mat-tab-group>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>
</div>


