import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../services/webApis/local-storage.service';
import { ApisService } from '../../../services/apis.service';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { PaymentDialogComponent } from 'src/app/pages/review-flight/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  errorMessages;
  ticketData;
  ticketArray;
  paymentErrorMessages;
  pageParams
  errorData: any[];
  plaidReady: boolean;
  private plaidInstance: any;
  plaidToken: any;
  errorDialog: any;
  checkId: any;
  openDocument: boolean;


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private route: ActivatedRoute,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private matDialog: MatDialog,
  ) {
    this.pageParams = this.route.snapshot.params
  }
  ngOnInit(): void {
    // this.getPlaidToken()
    // if (isPlatformBrowser(this.platformId)) {
    //   this.loadPlaidScript().then(() => {
    //     this.plaidReady = true; // Enable the button
    //   }).catch(error => {
    //     console.error('Failed to load the Plaid script', error);
    //   });
    // }
  }

  goToReview() {
    this.router.navigate(['/profile/dashboard']);
  }


  payment() {


    this.apis.continueToPaymentStep.next(3);


  }

  verifyDocument() {
    this.openDocument = true
    this.checkId=this.localStorage.getItem('check_id')

      const checkId=this.checkId?.split('-')[0]
    if (!this.checkId&&(checkId!==this.pageParams.id)) {

      this.apis.plaidIdentityVerification(this.pageParams.id).subscribe({
        next: (res: any) => {
          console.log('res',res);

          const data = res;
          window.open(res.shareable_url);
          this.localStorage.setItem('check_id', `${this.pageParams.id}-ver-${data.id}`)
          this.openDocument = false
        },
        error: error => {
          this.openDocument = false
          this.openErorPaymentModal()
        }
      })
    }else{

      const id= this.checkId.split('-')[2]
      this.apis.getPlaidIdentityVerification(id).subscribe({
        next: (res: any) => {
          const data = res;
          this.openDocument = false
          window.open(res.shareable_url);
        },
        error: error => {
          this.openDocument = false
          this.openErorPaymentModal()
        }
      })

  }
  }
  openErorPaymentModal(): void {
    this.errorDialog = this.matDialog.open(PaymentDialogComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      width: '840px',
      maxWidth:'95%',
      backdropClass: "#f5f5f5",
      data: {
        error: '',
        order_id :this.pageParams.id
      },
    });
    this.errorDialog.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }
  loadPlaidScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).Plaid) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.onload = () => resolve();
      script.onerror = () => reject('Failed to load the Plaid script');
      document.body.appendChild(script);
    });
  }
  getPlaidToken() {
    this.apis.plaidCreateToken().subscribe({
      next: (res: any) => {
        this.plaidToken = res.data;
        this.initPlaid();
      }
    })
  }

  initPlaid() {
    this.plaidInstance = (window as any).Plaid.create({
      clientName: 'Fly All Over',
      env: 'sandbox',
      token: 'link-sandbox-86a04a10-41f4-4401-a67e-26e1298ee1aa',
      product: ['identity'],
      onSuccess: (public_token: string, metadata: any) => {
        console.log('Public Token:', public_token);
        console.log('metadata :', metadata);
      },
      onExit: (err: any, metadata: any) => {
        if (err != null) {
          console.error('Error:', err);
        }
      }
    });
  }
}
