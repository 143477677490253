import {Component, Input, OnInit,Output,EventEmitter} from '@angular/core';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {SEOService} from '../../../services/seo.service';
import { timer } from 'rxjs';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReviewFlightTimerReminderDialogComponent } from '../review-flight-timer-reminder-dialog/review-flight-timer-reminder-dialog.component';
import { ReviewFlightEndTimerReminderDialogComponent } from '../review-flight-end-timer-reminder-dialog/review-flight-end-timer-reminder-dialog.component';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-review-flight-trip-info',
  templateUrl: './review-flight-trip-info.component.html',
  styleUrls: ['./review-flight-trip-info.component.css']
})
export class ReviewFlightTripInfoComponent implements OnInit {
  @Input() searchRequest: any;
  @Output() setOpenDetailsEvent = new EventEmitter();
  @Input() openDetails: any = false;
  @Input() itineraries;
  @Input() offers;
  @Input() itineraryNumber;
  // private isButtonVisible = true;
  countDown: any;
  counter = (30 * 60);
  tick = 1000;
  dialogRef: any;
  select_Flights:any[]=[];

  constructor(
    private localStorage: LocalStorageService, private seoService: SEOService,    private matDialog: MatDialog,
    private router: Router,
    public toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.seoUpdate();
    this.countDown = timer(0, this.tick).subscribe(() => this.countrHandller());
  }
  splitString(str:any){
    let f = str.slice(0,5);
    let l = str.substring(str.indexOf(",") + 1);
    return f + l
  }
  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  isShown: boolean = false ; // hidden by default

  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
  countrHandller() {
    if (this.counter == 0 && this.router.url === '/review-flight') {
      this.countDown.unsubscribe();
      this.openEndTimerReminderDialog();
    } else {
      --this.counter;
      if (this.counter == 55 && this.router.url === '/review-flight') {
        this.openTimerReminderDialog();
      }
    }
  }
  openTimerReminderDialog(): void {
    // this.dialogRef = this.matDialog.open(ReviewFlightTimerReminderDialogComponent, {
    //   panelClass: 'TimerReminderDialog',
    //   maxHeight: '100vh',
    //   width: '380px',
    // });
    this.toastr.info('please only 10 minutes left', 'Attention', {
      timeOut: 5000,
    });
  }
  openEndTimerReminderDialog(): void {
    this.dialogRef = this.matDialog.open(ReviewFlightEndTimerReminderDialogComponent, {
      panelClass: 'EndTimerReminderDialog',
      maxHeight: '100vh',
      width: '50%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequest'));
      this.router.navigate(['flights'], {queryParams: this.searchRequest});
    });
  }
toggleShow() {

this.isShown = ! this.isShown;

}

}
