import {Component, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from '../../services/meta-tags.service';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-faq',
  templateUrl: './help-faq.component.html',
  styleUrls: ['./help-faq.component.css']
})
export class HelpFaqComponent implements OnInit {

  accordion = '';
  oldAccordion = '';
  searchResults = true;
  faqs = [];
  loading: boolean = true;
  backendError: boolean = false;
  url: string;

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
    private metaService: MetaTagsService,
    private title: Title
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.getFAQs();
    this.url = this.router.url;
  }

  seoUpdate() {

    this.seoService.updateTitle('FAQ | flyallover ');
    this.seoService.updateDescription('find  your answers to frequently asked questions about Flyallover, our site and how it works. If you cannot find a suitable answer, please feel free to contact us');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  runAccordion(answer) {
    if (this.oldAccordion == answer) {
      this.accordion = '';
      this.oldAccordion = '';
    } else {
      this.accordion = answer;
      this.oldAccordion = answer;
    }
  }

  onSearch(value) {
    this.loading = true;
    if (value !== '') {
      this.apis.searchForFAQs(value).subscribe(
        (res: any) => {
          this.searchResults = true;
          this.faqs = res.data
          this.loading = false;

        },
        (err) => {
          this.loading = false;
          this.backendError = true;

        }
      )
    } else {
      this.getFAQs();

    }

  }

  getFAQs() {
    this.apis.getFAQs().subscribe(
      (res: any) => {
        this.searchResults = true;
        this.faqs = res.data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.backendError = true;
      }
    )
  }

  setMetaTags() {
    const hostUrl = AppComponent.hostUrl;
    const metaData = {
      title: "Frequently Asked Questions | FlyAllOver",
      "meta_data": {
        "og:title": "Frequently Asked Questions | FlyAllOver",
        "og:url": `${hostUrl}${this.url}`,
        "og:type": "website",
        "og:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "og:description": "Frequently Asked Questions on all Flyallover travel services. Answers to most FAQs on flights, hotels, cruises, vacations and travel insurance and all you need in Booking",
        "twitter:card": "summary_large_image",
        "twitter:title": "Frequently Asked Questions | FlyAllOver",
        "twitter:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "twitter:description": "Frequently Asked Questions on all Flyallover travel services. Answers to most FAQs on flights, hotels, cruises, vacations and travel insurance and all you need in Booking",
        "twitter:url": `${hostUrl}${this.url}`,
        "title": "Frequently Asked Questions | FlyAllOver",
        "description": "Frequently Asked Questions on all Flyallover travel services. Answers to most FAQs on flights, hotels, cruises, vacations and travel insurance and all you need in Booking",
      }
    }
    this.title.setTitle(metaData.title);
    this.metaService.modifyMetaTags(metaData.meta_data);
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
