import { Component, OnInit } from '@angular/core';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-home-brazilian-banner',
  templateUrl: './home-brazilian-banner.component.html',
  styleUrls: ['./home-brazilian-banner.component.css']
})
export class HomeBrazilianBannerComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  scrollToEngine() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
