import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-flight-details-dashboard',
  templateUrl: './flight-details-dashboard.component.html',
  styleUrls: ['./flight-details-dashboard.component.css']
})
export class FlightDetailsDashboardComponent implements OnInit {
  itinerary;
  origin;
  destination;
  flightDetailsData;
  image="./assets/images/personImage.jpg"
  error;
  errorMessage:any[]=[];
  errorMessageDetails:any[]=[];
  constructor(
    public dialogRef: MatDialogRef<FlightDetailsDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {

      this.itinerary=this.data.details;
      this.origin= this.data.flights.origin
      this.destination=this.data.flights.destenation
      this.flightDetailsData=this.data.flightData
      this.error=JSON.parse(this.flightDetailsData.issue_ticket_response).AirTicketRS.ApplicationResults.Warning
      for (let i = 0; i < this.error.length; i++) {
        this.errorMessage[i]=this.error[i].SystemSpecificResults

      }

  }
  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}h`;
    }
    convertedMinutes = `${minutes % 60}m`;
    return convertedHours + ' ' + convertedMinutes;
  }

}
