<div id="flightsHeader" class="customContainer" *ngIf="flights">
  <ng-container *ngFor="let flight of flights , let i = index">
    <div class="d-flex item align-items-center"
         [ngClass]="{'active': currentFlightIndex == i , 'selected': currentFlightIndex > i}"
         (click)="jumpToFlight(i)">
      <img alt="{{flight.OperatingAirline}}" title="filght-image"
           src="https://c.fareportal.com/n/common/air/3x/{{flight.OperatingAirline}}.png">
      <span>{{flight.DepartureCityName}}</span>
      <span>({{flight.DepartureAirport}})</span>
      <i class="fas fa-arrow-right"></i>
      <span>{{flight.ArrivalCitytName}}</span>
      <span>({{flight.ArrivalAirport}})</span>
    </div>
  </ng-container>
</div>
<div class="customContainer" *ngIf="travellers">
  <ng-container *ngFor="let traveller of travellers , let i = index">
    <div class="d-flex flex-column item justify-content-center align-items-center"
         [ngStyle]="{background:traveller.color}"
         [ngClass]="{'active': currentTravellerIndex == i}" (click)="jumpToTraveller(i)">
      <div>
        <span class="mr-1">{{traveller.GivenName}}</span>
        <span>{{traveller.MiddleName}}</span>
      </div>
      <ng-container *ngIf="traveller.seatNumber">
        <div>
          <strong>{{traveller.seatNumber[currentFlightIndex]}}</strong>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
