import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-visa-faqs',
  templateUrl: './visa-faqs.component.html',
  styleUrls: ['./visa-faqs.component.css']
})
export class VisaFaqsComponent implements OnInit {

  faqs: any;
  response: any;
  // pagination
  page = 1;
  pageSize = 10;
  totalSize;
  url: string;

  constructor(
    private apis: ApisService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.url = this.activatedRoute.snapshot.url[0].path;
    this.callFAQAPi();
  }

  callFAQAPi(page = 1): void {
    this.apis.getVisaFAQ(page).subscribe(response => {
      this.response = response;
      this.faqs = this.response.data;
      this.assignPaginationVars(this.response?.meta.current_page, this.response?.meta.total, this.response?.meta.per_page);
    }, err => {
      console.log(err)
    });
  }

  changePage(page: number) {
    this.faqs = undefined;
    this.callFAQAPi(page);
  }

  assignPaginationVars(currentPage, total, pageSize) {
    this.page = currentPage;
    this.totalSize = total;
    this.pageSize = pageSize;
  }

}
