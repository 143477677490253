<div class="dialog-custom-container">
  <div class="dialog-header">
    <div ></div>
    <div class="flightDetails" >
      <!-- <div>
        <span>{{ itinerary.flights[0][0].DepartureAirport }} , </span>
        <span>{{ itinerary.flights[0][0].DepartureCityName }}</span>
        <ng-container *ngIf="itinerary.flights[0].length > 1">
          <i
            class="fas fa-arrow-right d-md-inline d-block"
            aria-hidden="true"
          ></i>
          <span
            >{{
              itinerary.flights[0][itinerary.flights.length - 1].ArrivalAirport
            }}
            ,
          </span>
          <span>{{
            itinerary.flights[0][itinerary.flights.length - 1].ArrivalCitytName
          }}</span>
        </ng-container>
      </div> -->

      <!-- <div>
        <span>{{ itinerary.flights[1][0].DepartureAirport }} , </span>
        <span>{{ itinerary.flights[1][0].DepartureCityName }}</span>
        <ng-container
          *ngIf="itinerary.flights[1][itinerary.flights.length - 1]"
        >
          <i
            class="fas fa-arrow-right d-md-inline d-block"
            aria-hidden="true"
          ></i>
          <span
            >{{
              itinerary.flights[1][itinerary.flights.length - 1].ArrivalAirport
            }}
            ,
          </span>
          <span>{{
            itinerary.flights[1][itinerary.flights.length - 1].ArrivalCitytName
          }}</span>
        </ng-container>
      </div> -->
         <!-- <ng-container >
        <ng-container *ngFor="let flight of fareRules.flights;index as i">

          <ng-container *ngFor="let item of flight;index as ii">
            <div class="d-flex col-12 justify-content-center">
              <div class="col-5 text-end">
                <span class="trip-info">{{item?.DepartureAirport}}</span>
              </div>
              <div class="col-2 text-center">
                <span class="icon-arrow">
                  <img src="assets/icons/icon8-right.png" alt="icons">
                </span>
                <i
                class="fas fa-arrow-right d-md-inline d-block"
                aria-hidden="true"
              ></i>
              </div>
              <div class="col-5 text-start">
                <span class="trip-info">{{item?.ArrivalAirport}}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container> -->
<div class="d-flex justify-content-center">
  <h1>FARE RULES</h1>
</div>
    </div>
    <div class="col-4 closeHeaderBtn">
      <span (click)="closeCalenderDialog()">
        <img
          alt="close icon"
          title="close icon"
          src="assets/icons/close.png"
          class="close-icon"
        />
      </span>
    </div>
  </div>
  <ng-container *ngIf="!airlinesRules">
    <div class="row">
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '100px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-12 d-flex mt-10 justify-content-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ height: '50px', width: '70%' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>
  <div class="rules">
    <div
      *ngIf="error"
      role="alert"
      class="alert alert-dismissible alert-danger"
    >
      <span
        >Dear traveller, we're sorry but the Fare Rules for the selected trip
        was not found.</span
      >
    </div>
    <ng-container *ngIf="airlinesRules">
      <div class="requirements col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div *ngFor="let airlinesRule of airlinesRules">
              <div class="requirement">
                <p class="requirementTitle">{{ airlinesRule.Title }}</p>
                <p class="requirement-description">{{ airlinesRule.Text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
