import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';

@Component({
  selector: 'app-vacation-hotel-details',
  templateUrl: './vacation-hotel-details.component.html',
  styleUrls: ['./vacation-hotel-details.component.css']
})
export class VacationHotelDetailsComponent implements OnInit {
id:number;
hotelDetails:any;
hotelImages:any[]=[];
galleryImages: GalleryItem[];
rate: number;
  aminities: any;
  branchId: any;
  less :boolean=true;
  constructor(private activatedRoute:ActivatedRoute,
              private hotelsService:HotelsApisService,
              private gallery: Gallery,) { }

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    // console.log(this.id);
    this.hotelsService.vacationDetails(this.id).subscribe((res:any)=>{
      this.hotelDetails=res.data;
      this.branchId=this.hotelDetails.hotel.Branches[0].id
      this.hotelImages=this.hotelDetails.images
      this.rate = parseInt(this.hotelDetails.hotel.hotel_rate);
      this.galleryMethod()
      this.hotelsService.getVacationsAminities(this.branchId).subscribe((res:any)=>{
        this.aminities = res.data;
        // console.log('aminities',this.aminities);
        // console.log(this.branchId);


      })
      // console.log('rate',this.rate);

      // console.log(this.hotelDetails);

    })
  }
  openGallery(e, i) {
    if (i === 4) {
      e.srcElement.childNodes[0]?.click();
    }
  }
  galleryMethod() {
    if (this.hotelDetails) {
    this.galleryImages = this.hotelImages.map(item => new ImageItem({src: item.full_url, thumb: item.full_url}));
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Bottom
    });
    lightboxRef.load(this.galleryImages);
  }
}
showMore(){
  this.less=false;
}
showLess(){
  this.less=true;
}
}
