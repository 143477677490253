<!-- ........................................................ Display Things To Do  one one ............................ -->

<div class="navBotoom">
  <app-navbar></app-navbar>
</div>


<div class="loading" *ngIf="!data && !relatedThingsToDo && !display">
  <div class="box-img" >
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
<div class="box-text" style="min-width: 70ch !important;">
  <div class="typewriter2">Please be patient while fetching your request <span class="fadein">...</span></div>
</div>
</div>

<div class="container">
  <div class="row g-0" *ngIf="images && display">
    <div
      class="col-12 g-0 py-4"
      style=" margin: 25px 0px 0px 10px"
    >
      <h1 class="fao_mobile_title_large">{{ thingName }} in {{ cityName }}</h1>
    </div>
    <h1 class="flight">Cheap Flight Tickets</h1>
    <div class="row g-0 mainRow">
      <div class="col-7 " *ngIf="images">
        <ngb-carousel [interval]="5000">
          <ng-template ngbSlide *ngFor="let item of images; index as i ">
            <div class="picsum-img-wrapper">
              <img
                [src]="item.full_url"
                alt="Random second slide"
                width="100%"
                height="540"
                style="border-radius: 12px"
                alt="{{ item?.name }}"
                title="{{ item?.name }}"
              />
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ngb-carousel>


        <div class=" py-4 d-flex justify-content-end">
          <button class="fao_mobile_btn_meduim"  (click)="openDialog()">Book Now</button>
        </div>

      </div>
      <div class="col-5 px-5 pt-4">
        <div *ngIf="cityName" class="portfolio-info">
          <h2 class="Font-family" >{{ cityName }} Detailes</h2>
          <ul>
            <li class="li-description Font-family"><strong class="st-description Font-family">Country </strong> : {{ countryName }}
            </li>
            <li class="li-description Font-family"><strong
              class="st-description Font-family">Address </strong> : {{ formatted_address }}</li>
          </ul>
        </div>
        <div *ngIf="about" class="portfolio-description mx-2 py-4 readMore">
          <h2 class="Font-family">{{ thingName }} Info </h2>
          <app-read-more
            [text]="about"
            maxLength="30"
            class="aboutText Font-family"
          ></app-read-more>
        </div>
        <section>
          <div class="col-lg-1 pt-4 order-lg-first ">
            <div class="sticky-content">
              <div class="social-icons social-icons-colored social-icons-vertical">
                <div class="icon-share">
                  <span class="social-label Font-family ">SHARE:</span>
                  <p class="social-icon image-item" (click)="cop()"
                     target="_blank">
                    <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                         src="./assets/newIcon/copy-blue-icon.svg"/>
                    <img *ngIf="copied" title="copied" class="copied" alt="copied"
                         src="./assets/newIcon/copied.png"/>
                  </p>
                  <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                    <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                  <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                    <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                  <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                    <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!--       test rating  -->


    <!-- -------  rating  ----- -->

    <div class="row g-0">
      <div class="col-7">
        <app-show-rate [reviewData]="reviewData" ></app-show-rate>
        <app-add-reviews [keyId]="'city_things_to_do_id'"  (addReview)="setReview($event)" [cityId]="cityId" ></app-add-reviews>

      </div>

      <div class="col-5 offerSlide">
        <app-point-slider-offers></app-point-slider-offers>
      </div>
    </div>
    <!-- show reviews -->
    <div class="">
      <div class="col-7 mb-5 handel-pos mt-4">
        <button class="fao_Show_btn_large" (click)="showReviews()" *ngIf="!showRev">Show All Reviews</button>
        <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="showRev">Hide All Reviews</button>
      </div>

      <div class="all-reviews">
        <div class="row">
          <owl-carousel-o *ngIf="showRev" [options]="customOptions" class="d-flex justify-content-center mt-5">
            <ng-container *ngFor='let item of reviewData.reviews.data ; let i = index'>
                <ng-container>
                    <ng-container>
                        <ng-template carouselSlide>
                            <app-share-reviews [data]="item" [image]="item.main_image"></app-share-reviews>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </ng-container>
        </owl-carousel-o>
        </div>
      </div>
        <!--         show reviews  -->

      <!--         show video   -->
      <section class="my-5">
        <div class="mt-5 mx-auto">
          <app-show-video></app-show-video>
        </div>
      </section>

        <div class="row">
          <div *ngIf="relatedThingsToDo" class="  mt-5 ">
            <h2 id="nearst" class="fao_mobile_title_large text-center"> Other Things To Do</h2>
            <div
              style="
                background-color: #000000;
                height: 3px;
                width: 100px;
                margin: auto;
              "
            ></div>
          </div>
          <div class="col-4 pt-4" *ngFor="let item of relatedThingsToDo; let i = index">
            <div *ngIf="i <3">
              <div class="box-side">

                <div class="thing mainRow">
                  <a
                    [routerLink]="['/things-to-do/', item.city.name,item.slug]"
                    (click)="refresh(item.slug)"
                  >
                    <img
                      src="{{
                        'https://api.flyallover.com/storage/' + item.featured_image
                      }}"
                      width="100%"
                      height="250px"
                      style=" border-radius: 6px"
                      title="{{ item.title_tag }}"
                      alt="{{ item.title_tag }}"
                    />
                  </a>
                  <h6 class="text-center fao_mobile_title_meduim text-dark mt-3" style=" min-height: 60px;">
                    <a style="text-decoration: none; color:#000000;"
                      [routerLink]="['/things-to-do/', item.city.name,item.slug]"
                      (click)="refresh(item.slug)"
                    >
                      {{ item.name }}
                    </a>
                  </h6>

                </div>
                <div
                  style="
                  background-color: #ddd4d4;
                  height: 1px;
                  width: 95%;
                  margin: auto;
                "
                ></div>
                <div class="col-12 g-0 ">
                  <p class="fao_mobile_prgraph">
                    {{ item.about | slice:0:155 }} ....
                  </p>
                </div>
                <div class="center_div">
                  <button class="fao_des_btn_med mb-3" [routerLink]="['/things-to-do/', item.city.name,item.slug]"
                          (click)=" refresh(item.slug) ">
                    MORE DETAILS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
    <div class="footerTop">
      <app-footer></app-footer>
    </div>
