import {Component, OnInit, EventEmitter, Pipe, PipeTransform} from '@angular/core';
import {ApisService} from '../../services/apis.service';


@Component({
  selector: 'app-things-to-do',
  templateUrl: './things-to-do.component.html',
  styleUrls: ['./things-to-do.component.css']
})

export class ThingsToDoComponent implements OnInit {
  data: any;
  page = 1;
  pageSize = 10;
  collectionSize: number;

  constructor(private apis: ApisService,) {
    this.getThingsToDo(this.page);
  }

  ngOnInit(): void {

  }

  getThingsToDo(pageNumber: any) {
    this.apis.paginationThingsToDo(1).subscribe((data: any) => {
      // console.log(data)
        this.data = data.data.data;
      },
      err => {
        console.log(err);
      });
  }
}

