import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  // domain
  private domain = environment.domain;

  constructor(private http: HttpClient) { }

  couponValidation(couponString: string, token: string = undefined) {
    const payload = {coupon_code: couponString}
    if (token) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this.http.post(`${this.domain}/api/coupon-validate`, payload, {headers})
    } else {
      return this.http.post(`${this.domain}/api/coupon-validate`, payload)
    }
  }
}
