import { Pipe, PipeTransform } from '@angular/core';
import {filter} from 'rxjs/operators';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(blog: any , word: string) {
    return blog.filter(  blog =>{
      return blog.title.toLowerCase().includes(word.toLowerCase()) ||
              blog.description.toLowerCase().includes(word.toLowerCase())
    })
  }

}
