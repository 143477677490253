<div class="hotel-search-container">
  <form [formGroup]="flightSearchForm" (ngSubmit)="onSearch()">
    <div class="search-box">
      <!-- Location Input -->
      <div class="form-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/airportIcon.svg" alt="origin Icon" width="24" height="24" />
        </span>
        <input type="text" id="origin" formControlName="origin" autocomplete="off"
          placeholder="Enter a city, airport or flight" required (input)="onOriginInput()" />
        <div class="dropdown" *ngIf="origin?.length > 0 || isLoadingOrigin">
          <!-- Loading Spinner -->
          <div class="loading-spinner" *ngIf="isLoadingOrigin">
            <div class="spinner"></div>
          </div>
          <div class="dropdown-item" *ngFor="let origine of origin" (click)="selectLocation(origine,'origin')">
            <div class="icon-container">
              <img src="assets/hotel-icon-search/airportIcon.svg" alt="Icon" width="24" height="24" />
            </div>
            <div class="location-details">
              <span class="location-name">{{ origine|slice:0:3 }}</span>
              <span class="location-type">{{ origine|slice:5 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group"><img src="assets/hotel-icon-search/swich-flight.svg" width="30" style="
        padding: 6px;
    " alt="" (click)="switchFlights()"></div>
      <div class="form-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/airportIcon.svg" alt="destenation Icon" width="24" height="24" style="transform: rotatey(163deg) rotate(298deg);" />
        </span>
        <input type="text" id="destenation" formControlName="destenation" autocomplete="off"
          placeholder="Enter a city, airport or flight" required (input)="onDestenationInput()" />
        <div class="dropdown" *ngIf="locations?.length > 0 || isLoading">
          <!-- Loading Spinner -->
          <div class="loading-spinner" *ngIf="isLoading">
            <div class="spinner"></div>
          </div>
          <div class="dropdown-item" *ngFor="let destenation of locations" (click)="selectLocation(destenation,'destenation')">
            <div class="icon-container">
              <img src="assets/hotel-icon-search/airportIcon.svg" alt="Icon" width="24" height="24" style="transform: rotatey(163deg) rotate(298deg);" />
            </div>
            <div class="location-details">
              <span class="location-name">{{destenation|slice:0:3 }}</span>
              <span class="location-type">{{ destenation|slice:5 }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Check-in and Check-out Dates Input -->
      <div class="form-group date-picker-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/calendarIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        <input type="text"  formControlName="Depart" placeholder="Wed 7/24" class="check-hotel"
          [(ngModel)]="viewDepartDate" required />
        <span class="icon">
          <img src="assets/hotel-icon-search/twoarrowsIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        </div>
        <div class="form-group date-picker-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/lineIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        <input type="text" formControlName="Return" placeholder="Fri 7/26" class="check-hotel"
          [(ngModel)]="viewReturnDate" required />
        <span class="icon">
          <img src="assets/hotel-icon-search/twoarrowsIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
      </div>
      <!-- Search Button -->
      <button type="submit" [disabled]="flightSearchForm.invalid" class="search-button">
        <span class="icon">
          <img src="assets/hotel-icon-search/searchIcon.svg" alt="Search Icon" width="24" height="24" />
        </span>
      </button>
    </div>
  </form>

</div>
