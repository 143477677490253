<section>
  <div class="row">
    <h2>Price Details</h2>
    <div class="priceDesc">
      <div class="travellerDesc travellerHedear">
        <p class="Font-Family">Travelers</p>
        <p class="Font-Family">Subtotal</p>
      </div>
      <div class="travellerDesc" *ngIf="itineraries[0]?.passengerDetails[0].Quantity > 0">
          <p> {{itineraries[0]?.passengerDetails[0].Quantity}} x {{itineraries[0]?.passengerDetails[0].PassengerType}}</p>
          <p> {{itineraries[0]?.passengerDetails[0].Price | currency}}</p>
      </div>
      <div class="travellerDesc" *ngIf="itineraries[0]?.passengerDetails[1].Quantity > 0">
          <p> {{itineraries[0]?.passengerDetails[1].Quantity}} x {{itineraries[0]?.passengerDetails[1].PassengerType}}</p>
          <p> {{itineraries[0]?.passengerDetails[1].Price | currency}}</p>
      </div>
      <div class="travellerDesc" *ngIf="itineraries[0]?.passengerDetails[2].Quantity > 0">
          <p> {{itineraries[0]?.passengerDetails[2].Quantity}} x {{itineraries[0]?.passengerDetails[2].PassengerType}}</p>
          <p> {{itineraries[0]?.passengerDetails[2].Price | currency}}</p>
      </div>
      <div class="travellerDesc" *ngIf="itineraries[0]?.passengerDetails[3].Quantity > 0">
          <p> {{itineraries[0]?.passengerDetails[3].Quantity}} x {{itineraries[0]?.passengerDetails[3].PassengerType}}</p>
          <p> {{itineraries[0]?.passengerDetails[3].Price| currency}}</p>
      </div>
      <div class="travellerDesc totalPrice fw-bolder fs-larger">
        <p class="Font-Family">Total Price (USD)</p>
        <p>{{itineraries[0]?.prices.ItinTotalFare  | currency}}</p>
      </div>
    </div>
    <p class="content Font-Family">All fares are quoted in USD. Some airlines may charge baggage fees. Your credit/debit card may be billed in multiple charges totaling the final total price. Promo Code is off our service fees.</p>
    <div class="promoCode">
      <div class="d-flex row justify-content-between">
        <input class="d-flex" type="text" placeholder="promo code or gift card number">
        <button  class="Font-Family" type="button">check</button>
      </div>
    </div>
  </div>
</section>