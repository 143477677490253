<div  class="customContainer" *ngIf="visaData && visaDestinationCountryName">
  <div class="header d-flex">
    <div class="col-xs-12 ">
      <div class="message">
        <span *ngIf="visaRequired">Visa is Required To </span>
        <span *ngIf="!visaRequired">Visa is NOT Required To </span>
        <span>{{visaDestinationCountryName.name}}</span>
      </div>
    </div>
    <div class="col-xs-12 ">
      <div class="message d-flex">
        <div>
          <a href="javascript:void(0)" (click)="isHealthRequirementsOpened= !isHealthRequirementsOpened">
            <button>Entry & Health Requirements</button>
            <!-- <span class="link link-text font-size-17-weight-600">Check {{visaDestinationCountryName.name}} Entry & Health Requirements</span> -->
          </a>
        </div>
        <div *ngIf="visaRequired">
          <a style="margin-left: 10px" target="_blank" href="{{visaData?.landingurl}}">
            <button>Get Your Visa</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="body" *ngIf="isHealthRequirementsOpened">
    <div class="row">
    <div class="col-md-12">
        <div class="requirement">
          <span class="requirementTitle">Entry Restrictions</span>
          <p [innerHTML]="visaData?.HealthEntryData?.healthentry[0].content | slice :0 : 200"></p>
        </div>
    </div>
    <div class="col-md-6">
        <div class="requirement">
          <span class="requirementTitle">Entry & Health Requirements</span>
          <p *ngIf="readMoreEntryHealthRequirements"
             [innerHTML]="visaData?.HealthEntryData?.healthentry[1].content  | slice :0 : 200"></p>
          <p *ngIf="!readMoreEntryHealthRequirements"
             [innerHTML]="visaData?.HealthEntryData?.entry_health_requirements"></p>
          <a href="javascript:void(0)"
            *ngIf="visaData?.HealthEntryData?.entry_health_requirements?.length > 200 && readMoreEntryHealthRequirements"
            class="readMore"
            (click)="readMoreEntryHealthRequirements = !readMoreEntryHealthRequirements">Read More</a>
          <a href="javascript:void(0)" *ngIf="!readMoreEntryHealthRequirements"
             (click)="readMoreEntryHealthRequirements = !readMoreEntryHealthRequirements" class="readMore">Read
            Less</a>

        </div>
    </div>
    <div class="col-md-6">
        <div class="requirement">
            <span class="requirementTitle">In-country Experience</span>
            <p *ngIf="readMoreCountryExperience"
               [innerHTML]="visaData?.HealthEntryData?.healthentry[2].content | slice :0 : 200"></p>
            <p *ngIf="!readMoreCountryExperience" [innerHTML]="visaData?.HealthEntryData?.country_experience"></p>
            <a href="javascript:void(0)" *ngIf="visaData?.HealthEntryData?.country_experience?.length > 200 && readMoreCountryExperience"
               class="readMore"
               (click)="readMoreCountryExperience = !readMoreCountryExperience">Read More</a>
            <a href="javascript:void(0)" *ngIf="!readMoreCountryExperience" (click)="readMoreCountryExperience = !readMoreCountryExperience"
               class="readMore">Read Less</a>
        </div>
    </div>
    <div class="col-md-6">
        <div class="requirement">
            <span class="requirementTitle">Transit Restrictions</span>
            <p *ngIf="readMoreTransitRestrictions"
               [innerHTML]="visaData?.HealthEntryData?.healthentry[3].content  | slice :0 : 200"></p>
            <p *ngIf="!readMoreTransitRestrictions"
               [innerHTML]="visaData?.HealthEntryData?.transit_restrictions"></p>
            <a href="javascript:void(0)"
              *ngIf="visaData?.HealthEntryData?.transit_restrictions?.length > 200 && readMoreTransitRestrictions"
              class="readMore"
              (click)="readMoreTransitRestrictions = !readMoreTransitRestrictions">Read More</a>

            <a href="javascript:void(0)" *ngIf="!readMoreTransitRestrictions"
               (click)="readMoreTransitRestrictions = !readMoreTransitRestrictions" class="readMore">Read Less</a>
          </div>
    </div>
    <div class="col-md-6">
        <div class="requirement">
            <span class="requirementTitle">Re-Entry Requirements</span>
            <p *ngIf="readMoreEntryRequirements"
               [innerHTML]="visaData?.HealthEntryData?.healthentry[4].content | slice :0 : 200"></p>
            <p *ngIf="!readMoreEntryRequirements"
               [innerHTML]="visaData?.HealthEntryData?.re_entry_requirements"></p>
            <a href="javascript:void(0)" *ngIf="visaData?.HealthEntryData?.re_entry_requirements?.length > 200 && readMoreEntryRequirements"
               class="readMore"
               (click)="readMoreEntryRequirements = !readMoreEntryRequirements">Read More</a>
            <a href="javascript:void(0)" *ngIf="!readMoreEntryRequirements" (click)="readMoreEntryRequirements = !readMoreEntryRequirements"
               class="readMore">Read Less</a>
          </div>
    </div>
    </div>
  </div>
</div>
