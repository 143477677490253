import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fly-hotels-search',
  templateUrl: './fly-hotels-search.component.html',
  styleUrls: ['./fly-hotels-search.component.css']
})
export class FlyHotelsSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
