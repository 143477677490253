<div class="custom-container">
  <!-- <i class="fas fa-times" [mat-dialog-close]="true">x</i> -->
  <div class="row">
    <div class="col-md-9 col-lg-9">
      <div class="row">
        <ng-container *ngFor="let traveller of travellers">
          <div class="col-md-3 mb-3 mr-3">
            <div class="numberContainer">
              <div class="header d-flex flex-column">
                <span class="name">{{traveller.name}}</span>
                <span class="ages">({{traveller.ageFrom}} - {{traveller.ageTo}})</span>
              </div>
              <div class="value ">
                <button class="valueBtn"  (click)="dec(traveller)">
                  <div class="minus">-</div>
                </button>
                <div class=" number">
                  <span>{{traveller.currentValue}}</span>
                </div>
                <button class="valueBtn" (click)="inc(traveller)">
                  <div class="plus">+</div>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let room of rooms">
          <div class="col-md-3 mb-3">
            <div class="numberContainer ">
              <div class="header" style="margin-bottom: 3.5rem;">
                <span class="name">{{room.name}}</span>
              </div>
              <div class="value">
                <button class="valueBtn"  (click)="decRooms(room)">
                  <div class="minus">-</div>
                </button>
                <div class=" number">
                  <span>{{room.currentValue}}</span>
                </div>
                <div class="valueBtn"  (click)="incRooms(room)">
                  <div class="plus">+</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="childrenAges?.length > 0" class="row flex-row col-9">
          <p style="font-weight: bold">Children Ages :</p>
          <div class="col-2" *ngFor="let child of childrenAges; let i = index">
            <p class=" mx-5">children[{{i+1}}] </p>
           
            
            <select [(ngModel)]="childrenAges[i]" class="">
              <option value="2"><span> 2 </span>years old</option>
              <option value="3"> <span> 3 </span> <span>   years old </span> </option>
              <option value="4"> <span> 4 </span><span> years old</span>  </option>
              <option value="5"> <span> 5 </span> years old</option>
              <option value="6"> <span> 6 </span>years old </option>
              <option value="7"> <span> 7 </span>years old </option>
              <option value="8"> <span> 8 </span>years old </option>
              <option value="9"> <span> 9 </span>years old</option>
              <option value="10"><span> 10 </span>years old</option>
              <option value="11"><span class=""> 11 </span>years old</option>
              <option value="12"><span> 12 </span>years old</option>
              <!-- <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 d-flex flex-column justify-content-center">
      <button class="selectClassBtn" [mat-dialog-close]="{className:className,travellers:totalTravellers,travellersObject: travellers,
      childrenAges:childrenAges,rooms:rooms}">
        Select
      </button>
    </div>
  </div>
</div>
