<div class="container" xmlns="http://www.w3.org/1999/html">
    <h2 class="title text-center mb-5">Flyallover Features</h2>
</div>
<div class="features">
  <div class="feature">
    <img src="../../../assets/images/icons/2.svg" alt="feature-image" title="Flyallover Features">
    <span>More Than 7000 Airlines Around The World</span>
  </div>
  <div class="feature">
    <img src="../../../assets/images/icons/hotel.svg" alt="hotel" title="Flyallover Features">
    <span>More Than A Million Hotels Around The World</span>
  </div>
  <div class="feature">
    <img src="../../../assets/images/icons/Group%2015261.svg" alt="Group" title="Flyallover Features">
    <span>Safe and Secure Payment Methods</span>
  </div>
</div>
