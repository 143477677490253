<app-navbar></app-navbar>
<!-- start header-more-offers -->
<div class="header-blog">
  <div class="overlay">
    <div class="container">
      <h1 class=" fao_mobile_title_large text-center" style="color: #fff !important;">Vacation</h1>
    </div>
  </div>
</div>

<div class="search-box">
  <input type="text" [(ngModel)]="word" placeholder="Search here..."/>
  <button (click)="search()">
    <img
      title="Search about trip"
      alt="search"
      src="./assets/images/blog/search.svg"
      width="30"
    />
  </button>
</div>
<!-- <div class="container mt-5" data-aos="fade-up">
  <div class="row">
    <div class="section-header text-center">
      <h2 class=" fao_mobile_title_large" style="color: #fff !important;">Trips</h2>
      <div class="underLine-travel" >

      </div>
    </div>
  </div>
</div> -->                                   
<!-- end header-more-offers -->

<!-- cards offers Section -->
 <section id="offers" class="section-with-bg mt-5">
  <div class="container">
    <div class="row mainRow headline">
      <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of dataGnrl; let i = index ">
        <div class="offer" *ngIf="item.price">
          <div class="offer-img my-auto">
            <ngb-carousel>
              <ng-container *ngIf="item?.images?.length > 0">
                <ng-template ngbSlide *ngFor="let itemImg of item?.images ; let i = index">
                  <div class="picsum-img-wrapper">
                    <img
                      src="{{ itemImg.full_url }}"
                      alt="{{itemImg.file_name}}"
                      class=" rounded w-100"
                      style="object-fit: cover;"
                      width="100%"
                      height="380px"
                    />
                  </div>
                </ng-template>
              </ng-container>
             
            </ngb-carousel>
            <!-- <img
              src="{{ item.image}}"
              alt="{{item.title}}"
              class=" rounded w-100 p-2"
              style=" object-fit: cover;"
              width="100%"
              height="250px"
            /> -->
            <div class=" py-1">
              <h3 class="fao_mobile_title_meduim titleHandal " style="">
                {{ item.title }}
              </h3>
              <!-- <p class="text-center fw-bold">
                <span class="To-color pt-1 DataFont">{{ item.country.name}} </span>
              </p> -->
            </div>
          </div>
          <div
            style="
              background-color: #ddd4d4;
              height: 1px;
              width: 95%;
              margin: auto;
            "
          ></div>
          <div>
            <p class="prgraph" [innerHTML]="item.description | slice:0:200  ">
              <!-- {{ item.description | slice:0:200 }} -->
            </p>
             <p class="prgraph1"> Subscribers so far : <span class="mx-2" style="color: red; font-weight: 600;">{{item.count_contacts}}</span></p>
             <div class="line-height mr-3 my-2">
              <!-- <h4 class="price Font-Family">USD {{item.price}}</h4> -->
               <h5 class="d-flex justify-content-end price  Font-Family">USD {{item.price}}</h5>
            </div>

             <div class=" center_div">
              <button class="fao_des_btn_med my-4" [routerLink]="['/vacation-details/',item.slug]">
                MORE DETAILS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</section> 
<!-- <div class="container">
  <div class="row icon-box my-5 " *ngFor="let item of data ; let i = index">
   
    <div class=" col-md-7 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100"  >
      <div class=""  >
        <div class="day">
          <h4> Day : {{item.day}}</h4>
          <h5>{{ item.country.name}} - {{item.city.name}}</h5>
        </div>
       
        <h1><a>{{item.title}}</a></h1>
        <p > {{ item.description  }}</p>
      </div>
    </div>
    <div class="col-4">
      <img
                  src="{{ item.image}}"
                  alt="{{item.title}}"
                  class=" rounded w-100 mt-5"
                  style="object-fit: cover;"
                  width="100%"
                  height="330px"
                />
    </div>


  </div>
</div> -->
<ngb-pagination class="d-flex justify-content-center pagination  mb-5 " [collectionSize]="collectionSize"
         [(page)]="page"  [pageSize]="pageSize"  (pageChange)="changePage($event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
  </ngb-pagination>


<app-footer></app-footer>
