<div class="col-12">
  <div class="row justify-content-center">
    <div class="col-12">
      <div
        class="d-flex align-items-center"
        style="box-shadow: 0 0 10px #00000033; min-height: 540px"
      >
        <ng-container *ngIf="!invoice">
          <ngx-skeleton-loader
            style="width: 100%"
            [theme]="{ height: '400px' }"
          ></ngx-skeleton-loader>
        </ng-container>
        <ng-container *ngIf="invoice">
          <table
            height="100"
            border="0"
            cellpadding="20"
            cellspacing="0"
            align="center"
            class="border-complete deviceWidth"
            bgcolor="#F5F5F5"
          >
            <tr>
              <td>
                <table
                  height="100"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-complete deviceWidth"
                  bgcolor="white"
                  style="border-radius: 16px 16px 0px 0px; margin-top: 2%"
                >
                  <tr>
                    <td id="logo" align="center" style="padding: 4%">
                      <img
                        alt="Logo"
                        title="Logo-Image"
                        src="https://shopwise.qhoopoe.com/images/Logo.png"
                        width="300"
                      />
                    </td>
                  </tr>
                </table>
                <table
                  width="580"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px dashed lightgrey; text-align: center"
                ></table>
                <table
                  height="100"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-complete deviceWidth"
                  bgcolor="white"
                >
                  <tr>
                    <td align="center" style="font-size: x-large">
                      <img
                        style="height: 30px"
                        title=" Congratulations! You booked now "
                        alt="Iconfeather-check-circle"
                        src="https://flyallover.com/assets/images/emails/Iconfeather-check-circle.png"
                      />
                      Congratulations! You booked now .
                    </td>
                  </tr>
                </table>
                <table
                  width="580"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px dashed lightgrey; text-align: center"
                ></table>
                <table
                  height="100"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-complete deviceWidth"
                  bgcolor="white"
                  style="padding: 20px"
                >
                  <tr>
                    <td align="start" style="font-size: x-large">
                      This is an Elctronic Invoice
                    </td>
                  </tr>
                  <tr>
                    <td align="start">
                      <strong>Prepared For</strong> :
                      <a
                        style="
                          font-size: large;
                          text-decoration: none;
                          color: black;
                        "
                        >Lind Fowley</a
                      >
                    </td>
                  </tr>
                </table>
                <table
                  width="580"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px dashed lightgrey; text-align: center"
                ></table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="
                    padding: 20px;
                    text-align: center;
                    margin-bottom: 2rem;
                    border-radius: 0 0 16px 16px;
                  "
                >
                  <tr>
                    <td align="start">SALES PERSON</td>
                    <td align="end">AM</td>
                  </tr>
                  <tr style="height: 50px">
                    <td align="start">INVOICE NUMBER</td>
                    <td align="end">000000</td>
                  </tr>
                  <tr>
                    <td align="start">INVOICE ISSUE DATE</td>
                    <td align="end">23 Apr 2021</td>
                  </tr>
                  <tr style="height: 50px">
                    <td align="start">RECORD LOCATOR</td>
                    <td align="end"><strong>LFKAJW</strong></td>
                  </tr>
                </table>

                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="padding: 20px; border-radius: 16px 16px 0 0"
                >
                  <tr style="height: 50px">
                    <td align="start">
                      <strong>DATE: Fri, Apr 30</strong>
                    </td>
                    <td align="end">
                      <strong>Flight : EGYPTAIR 986</strong>
                    </td>
                  </tr>
                </table>

                <table
                  width="580"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px dashed lightgrey; text-align: center"
                ></table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="
                    padding: 20px;
                    border-radius: 0 0 16px 16px;
                    margin-bottom: 2rem;
                  "
                >
                  <tr align="start" style="height: 30px">
                    <td>From:</td>
                    <td>
                      <strong> NEW YORK JFK, NY</strong>
                    </td>
                    <td align="end">Departs:</td>
                    <td align="end">
                      <strong>18:30</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 50px">
                    <td>To:</td>
                    <td><strong>CAIRO, EGYPT</strong></td>
                    <td align="end">Arrives:</td>
                    <td align="end">
                      <strong> 11:00 (+1 day)</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 50px">
                    <td>Departure Terminal:</td>
                    <td><strong>4</strong></td>
                    <td align="end">Arrival Terminal:</td>
                    <td align="end">
                      <strong>3</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 50px">
                    <td>Duration:</td>
                    <td>
                      <strong>10hr(s) 30min(s)</strong>
                    </td>
                    <td align="end">Cabin:</td>
                    <td align="end">
                      <strong> Economy</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 50px">
                    <td>Stop(s):</td>
                    <td><strong>Non Stop</strong></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="padding: 20px; border-radius: 16px 16px 0 0"
                >
                  <tr style="height: 30px">
                    <td align="start">
                      <strong>DATE: Fri, Apr 30</strong>
                    </td>
                    <td align="end">
                      <strong>Flight : EGYPTAIR 986</strong>
                    </td>
                  </tr>
                </table>

                <table
                  width="580"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px dashed lightgrey; text-align: center"
                ></table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="
                    padding: 20px;
                    border-radius: 0 0 16px 16px;
                    margin-bottom: 2rem;
                  "
                >
                  <tr align="start" style="height: 40px">
                    <td>From:</td>
                    <td>
                      <strong> NEW YORK JFK, NY</strong>
                    </td>
                    <td align="end">Departs:</td>
                    <td align="end">
                      <strong>18:30</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 40px">
                    <td>To:</td>
                    <td><strong>CAIRO, EGYPT</strong></td>
                    <td align="end">Arrives:</td>
                    <td align="end">
                      <strong> 11:00 (+1 day)</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 40px">
                    <td>Departure Terminal:</td>
                    <td><strong>4</strong></td>
                    <td align="end">Arrival Terminal:</td>
                    <td align="end">
                      <strong> 3</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 40px">
                    <td>Duration:</td>
                    <td>
                      <strong> 10hr(s) 30min(s)</strong>
                    </td>
                    <td align="end">Cabin:</td>
                    <td align="end">
                      <strong>Economy</strong>
                    </td>
                  </tr>
                  <tr align="start" style="height: 50px">
                    <td>Stop(s):</td>
                    <td><strong>Non Stop</strong></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>

                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border-radius: 16px 16px 0 0; padding: 10px"
                >
                  <tr>
                    <td
                      style="
                        font-weight: bold;
                        font-size: x-large;
                        text-align: center;
                      "
                    >
                      Ticket Information
                    </td>
                  </tr>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="
                    padding: 20px;
                    margin-bottom: 2rem;
                    border-radius: 0 0 16px 16px;
                  "
                >
                  <tr>
                    <td align="start"><strong>Ticket Number :</strong></td>
                    <td align="end">MS 7525685795</td>
                  </tr>
                  <tr style="height: 50px">
                    <td align="start"><strong>Passenger :</strong></td>
                    <td align="end">Lind Fowley</td>
                  </tr>
                  <tr>
                    <td align="start"><strong>USD :</strong></td>
                    <td align="end">$936.35</td>
                  </tr>
                  <tr style="height: 50px">
                    <td align="start"><strong>SubTotal :</strong></td>
                    <td align="end">USD 936.35</td>
                  </tr>
                  <tr>
                    <td align="start"><strong>Total Amount Due :</strong></td>
                    <td align="end"><strong>USD 936.35</strong></td>
                  </tr>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border-radius: 16px 16px 0 0; padding: 20px"
                >
                  <tr>
                    <td align="center">
                      <a
                        style="
                          font-weight: bold;
                          font-size: xx-large !important;
                          text-decoration: none;
                          color: #fd5021;
                        "
                        >Caution !!</a
                      >
                    </td>
                  </tr>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="padding: 20px"
                >
                  <tr>
                    <td>
                      The carriage of certain hazardous materials, like
                      aerosols, fireworks, and flammable liquids, aboard the
                      aircraft is forbidden. If you do not understand these
                      restrictions, further information may be obtained from
                      your airline
                    </td>
                  </tr>
                </table>
                <table
                  width="560"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="border: 1px groove lightgrey; text-align: center"
                ></table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  class="border-lr deviceWidth"
                  bgcolor="white"
                  style="
                    padding: 20px;
                    border-radius: 0 0 16px 16px;
                    margin-bottom: 2rem;
                  "
                >
                  <tr>
                    <td>
                      Your travel arranger provides the information contained in
                      this document. If you have any questions about the
                      content, please contact your travel arranger. For Credit
                      Card Service fees, please see eTicket receipt for total
                      charges.
                    </td>
                  </tr>
                </table>

                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  bgcolor="white"
                  class="border-complete deviceWidth"
                  bgcolor="white"
                  style="
                    margin-top: 2%;
                    border-radius: 16px 16px 0 0;
                    padding: 10px 20px;
                    border-left: 3px solid #3dd2a4;
                  "
                >
                  <tr>
                    <td align="start" style="font-size: xx-large">
                      DO YOU NEED A VISA?
                    </td>
                  </tr>
                  <tr>
                    <td align="start" style="height: 50px">
                      Start Your Visa Request Now.
                    </td>
                  </tr>
                  <tr>
                    <td align="start" style="height: 70px">
                      <strong>London</strong> : USA Citizen, New York, Tourist,
                      One Entry, Consulate Decides Validity
                    </td>
                  </tr>
                </table>
                <table
                  height="120"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  bgcolor="white"
                  style="
                    margin-bottom: 2rem;
                    border-radius: 0 0 16px 16px;
                    border-left: 3px solid #3dd2a4;
                  "
                  class="border-lr deviceWidth"
                >
                  <tr>
                    <td align="center">
                      <a href="https://flyallover.com/visa">
                        <button
                          style="
                            border-radius: 5px;
                            width: 50%;
                            height: 50%;
                            font-weight: bold;
                            border: none;
                            background: #fd5021;
                            color: white;
                            font-size: large;
                          "
                        >
                          Get My Visa
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  bgcolor="white"
                  class="border-complete deviceWidth"
                  bgcolor="white"
                  style="
                    margin-top: 2%;
                    border-radius: 20px;
                    margin-bottom: 2%;
                    padding: 16px;
                  "
                >
                  <tbody>
                    <tr>
                      <td>
                        <h3
                          style="
                            color: #148181;
                            font-size: 1.8rem;
                            font-weight: bolder;
                            text-align: center;
                            margin-bottom: 0px;
                            margin-top: 0px;
                          "
                        >
                          Share Your Adventures!
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <a
                          href="#."
                          style="
                            color: #777777;
                            font-size: 1.2rem;
                            text-align: center;
                            line-height: 1.7;
                            width: 500px;
                            padding-top: 0;
                            padding-left: 10px;
                            padding-right: 10px;
                          "
                        >
                          As part of the Follow us on Instagram and share your
                          best travel shots with us. Don’t forget to tag us
                          @FlyAllOverUS for a chance be featured.
                          <span style="color: #fd5021">#FlyForLess</span>
                        </a>
                      </td>
                    </tr>
                    <tr style="margin-top: 2rem">
                      <td valign="top" style="width: 100%; text-align: center">
                        <a
                          href="https://www.flyallover.com/"
                          style="
                            color: #f1f1f1;
                            font-size: 1.2rem;
                            text-decoration: none;
                            display: block;
                            background: #fd5021;
                            width: 300px;
                            padding: 15px;
                            border-radius: 4px;
                            margin: 0px auto;
                          "
                        >
                          <img
                            src="http://81.10.36.145/img/flyallover/instagram.svg"
                            title="Follow on Instagram"
                            alt="instagram"
                          />
                          Follow on Instagram
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
