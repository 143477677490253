import {Component, Input, OnInit} from '@angular/core';
import {RoundTripCalenderDesktopDialogComponent} from '../round-trip-calender-desktop-dialog/round-trip-calender-desktop-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-engine-static',
  templateUrl: './home-specific-search-engine.component.html',
  styleUrls: ['./home-specific-search-engine.component.css']
})
export class HomeSpecificSearchEngineComponent implements OnInit {
  @Input() destination;
  @Input() origin;
  @Input() stops: any;
  @Input() airline: any;
  @Input() tripType: any;
  // @Input() shortOrigin: any = 'New York';
  // @Input() shortDestination: any = 'Cairo';
  @Input() shortOrigin: any ;
  @Input() shortDestination: any;
  @Input() fullOrigin: any = 'JFK -  J.F. Kennedy, NYC, New York, US';
  @Input() fullDestination: any = 'CAI - Cairo, Cairo, EG';
  departureDate: any;
  returnDate: any;
  numberPassenger = [0, 1, 3, 4, 5, 6, 7, 8, 9];
  totalTravellers: any = '1';
  className: any = 'Economy';
  adult: any = '1';
  child: any = '0';
  loading = false;
  errors = false;
  errorMessage = 'City or Airport, Date of Departing, and Returning are required';
  dialogRef: any;
  searchRequest: any;

  constructor(
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  setAdults(e) {
    this.adult = e;
  }

  setChildren(e) {
    this.child = e;
  }

  openDesktopCalender(e): void {
    this.dialogRef = this.matDialog.open(RoundTripCalenderDesktopDialogComponent, {
      panelClass: 'roundTripCalenderDesktopDialog',
      maxWidth: '60vw',
      data: {
        origin: this.origin ? this.origin.slice(0, 3) : undefined,
        destination: this.destination ? this.destination.slice(0, 3) : undefined,
        departDate: this.departureDate ? this.departureDate : undefined,
        returnDate: this.returnDate ? this.returnDate : undefined,
        type: e,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.departureDate = data.departureDate;
        this.returnDate = data.returnDate;
      }
    });
  }

  initRequest() {
    this.totalTravellers = +this.adult + +this.child;
    return {
      fullOrigin: this.fullOrigin,
      fullDestination: this.fullDestination,
      trip_type: this.tripType,
      departure_date: this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'),
      return_date: this.datePipe.transform(this.returnDate, 'yyyy-MM-dd'),
      origin: this.origin,
      destination: this.destination,
      class: this.className,
      travellers: this.totalTravellers,
      adult: this.adult,
      child: this.child,
      stops: this.stops,
      airlines: this.airline,
    };
  }

  submit() {
    if (!this.departureDate || !this.returnDate  || !this.shortOrigin  || !this.shortDestination ) {
      this.errors = true;
    } else {
      localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
      this.router.navigate(['flights'], {queryParams: this.initRequest()});
    }
  }
}
