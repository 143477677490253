<div class="row">
  <h2 *ngIf="title">Fare Rules</h2>
  <div class="priceDesc">
    <div class="travellerDesc travellerHedear">
      <p class="Font-Family">{{itineraries?.PENALTIES?.Title}}</p>
    </div>
  </div>
  <p class="content Font-Family" >{{itineraries?.PENALTIES?.Text }}</p>

</div>
