import {AfterViewChecked, Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-home-about-section',
  templateUrl: './home-about-section.component.html',
  styleUrls: ['./home-about-section.component.css']
})
export class HomeAboutSectionComponent implements AfterViewChecked {
  @Input() autoPlay;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';

  prevent(e): void {
    e.preventDefault();
  }
  ngAfterViewChecked() {
    // you could also do this after a service call of some sort
    this.finishedLoading.emit(true);
  }
}
