import {Component, OnInit, Output, EventEmitter, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'app-hotel-passengers-number',
  templateUrl: './hotel-passengers-number.component.html',
  styleUrls: ['./hotel-passengers-number.component.css']
})
export class HotelPassengersNumberComponent implements OnChanges {

  @Input() passengerData;
  @Output() notify = new EventEmitter();
  passengers = [
    {id: 'adults', disc: 'Adults', count: 1},
    {id: 'children', disc: 'Children', count: 0},
  ];
  childrenAges: string[] = [];
  showPassengerList = false;
  max = 6;
  maxNumbersWarning = false;

  ngOnChanges() {
    if (this.passengerData) {
      this.passengers[0].count = this.passengerData.adults;
      if (this.passengerData.children > 1) {
        this.passengers[1].count = this.passengerData.children;
        if (this.passengerData.children_ages !== '') {
          this.childrenAges = this.passengerData.children_ages.split(',');
        }
      }
    }
  }

  dec(id: string): void {
    switch (true) {
      case id === "adults":
        if (this.passengers[0].count > 1) {
          this.passengers[0].count--;
        }
        break;
      case id === "children":
        if (this.passengers[1].count > 0) {
          this.passengers[1].count--;
          this.childrenAges.pop();
        }
        break;
    }
    this.check();
  }

  // it increments the passenger count depending on the id
  inc(id: string): void {
    switch (true) {
      case id === "adults":
        if (this.passengers[0].count < this.max) {
          this.passengers[0].count++;
        }
        break;
      case id === "children":
        if (this.passengers[1].count < this.max) {
          this.passengers[1].count++;
          this.childrenAges.push('1')
        }
        break;
    }
    this.check();
  }

  showList() {
    this.showPassengerList = !this.showPassengerList
  }

  closeList() {
    /******
     * the list can be also closed by clicking on any part of app-home-search component (visa.component.html line:1)
     * or app-hotel-search component (hotel-search.component.html line:1)
     *****/
    this.showPassengerList = false;
  }

  // do some checks
  check() {
    // 0 is index for adults
    let adultsSum: number = this.passengers[0].count;
    let childrenSum: number = this.passengers[1].count

    // hide all warnings when recalculating the numbers
    this.maxNumbersWarning = false;

    // adults number should be less than or equal max (6) also children
    if ((adultsSum + childrenSum) >= (this.max * 2)) {
      this.maxNumbersWarning = true;
    }
    // emit an event that the user has updated passengers number
    this.notify.emit();
  }

  // this method to return the data of passengers (used in the parent home-search)
  fetchData() {
    return {
      adults: this.passengers[0].count,
      children: this.passengers[1].count,
      children_ages: this.childrenAges.join(',')
    }
  }
}
