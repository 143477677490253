import {Component, OnInit, Output, EventEmitter } from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Router, ActivatedRoute} from '@angular/router';
import {AppComponent} from '../../app.component';
import {SEOService} from '../../services/seo.service';
import {DatePipe, DOCUMENT} from '@angular/common';


@Component({
  selector: 'app-point-slider-offers',
  templateUrl: './point-slider-offers.component.html',
  styleUrls: ['./point-slider-offers.component.css']
})
export class PointSliderOffersComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 10000,
    dots: false,
    navSpeed: 1200,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },

  };

  data: any = [];
  image_slider = [];
  about: any;
  id: any;
  dataObj: any;
  @Output() applyModificationEvent = new EventEmitter();

  constructor(
    private apis: ApisService,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private router: Router, private route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getOffers();
    this.initRequest();
    this.appendToRoute();
  }


  getOffers() {
    this.apis.getOffers().subscribe((data: any) => {
        this.data = data.data.data;
        this.dataObj = this.data.find(obj => {
          return obj.id === this.id;

        });

      },
      err => {
        console.log(err);
      });
  }

  refresh(id) {
    this.id = id;
    this.getOffers();
    window.scrollTo(0, 0);

  }


  initRequest() {
    if (this.dataObj) {
      this.dataObj.airport_destination?.name.toString();
      return {

        adult: 1,
        child: 0,
        class: this.dataObj.class.charAt(0).toUpperCase() + this.dataObj.class.slice(1),
        departure_date: this.datePipe.transform(this.dataObj.departure_date, 'yyyy-MM-dd'),
        fullDestination: this.dataObj.airport_destination?.code + ' - ' + this.dataObj.airport_destination?.name + ',' + this.dataObj.airport_destination?.city_name + ', ' + this.dataObj.airport_destination?.country_code,
        fullOrigin: this.dataObj.airport_origin.code + ' - ' + this.dataObj.airport_origin.name + ',' + this.dataObj.airport_origin.city_name + ', ' + this.dataObj.airport_origin.country_code,
        trip_type: 'Return',
        return_date: this.datePipe.transform(this.dataObj.return_date, 'yyyy-MM-dd'),
        origin: this.dataObj.airport_origin.code,
        destination: this.dataObj.airport_destination?.code,
        travellers: this.dataObj.travellers,
        stops: 1,
        lap: 0,
        seat: 0,
      };

    }

  }

  appendToRoute() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.initRequest(),
      queryParamsHandling: 'merge',
      skipLocationChange: true
    }).then(() => {
      this.applyModificationEvent.emit(this.route.snapshot.queryParams);
    });
  }

  submit() {
    this.appendToRoute();
    localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
    this.router.navigate(['flights'], {queryParams: this.initRequest()});
  }

}
