<app-navbar></app-navbar>

<div class="container">

  <div class="cs-mb20 mt-1 p-3">
    <ul class="cs-list cs-style1">
      <li>
        <div class="cs-list_left" *ngIf="user"><b class="cs-primary_color">Passenger Name: </b> <span
            *ngIf="user?.gender=='Male'">MR</span>
          <span *ngIf="user?.gender!=='Male'">MRS</span>
          {{handelName(user?.last_name) + ' / ' + handelName(user?.first_name) + ' ' + handelName(user?.middle_name) + '
          '}}
        </div>
      </li>

    </ul>
  </div>
  <div class="cs-invoice_head cs-50_col cs-mb15 p-3">
    <div class="cs-invoice_left">
      <ul class="cs-bar_list mb-3">
        <li *ngIf="orderData?.trip_type"><b class="cs-primary_color">Trip Type: </b>
          {{orderData?.trip_type}}</li>

      </ul>
      <ul class="cs-bar_list">

        <li *ngIf="orderData?.origin_city_object"><b class="cs-primary_color">From: </b>
          {{orderData?.origin_city_object.city_name}}
          , {{orderData?.origin_city_object.country_code}}</li>
        <li *ngIf="orderData?.destination_city_object"><b class="cs-primary_color">To: </b>
          {{orderData?.destination_city_object.city_name}}
          , {{orderData?.destination_city_object.country_code}}</li>
      </ul>
    </div>
    <div class="cs-invoice_right">
      <ul class="cs-bar_list mb-3">
        <li *ngIf="orderData?.pnr"><b class="cs-primary_color">PNR: </b>
          {{orderData?.pnr}}</li>

      </ul>
      <ul class="cs-bar_list">

        <li *ngIf="orderData?.departure_date"><b class="cs-primary_color">Department Date: </b>
          {{orderData?.departure_date| date:"dd MMM yyy"}}</li>
        <li *ngIf="orderData?.return_date"><b class="cs-primary_color">Return Date: </b>
          {{orderData?.return_date| date:"dd MMM yyy"}}</li>
      </ul>
    </div>
  </div>
  <div class="shopping-cart">
    <div class="cart-ship-info row">
      <div class="col-sm-7 p-3">
        <h6 class="mb-3 title-header">Dear valued Fly All Over customer,</h6>
        <p class="note-des ">

          We hope this message finds you well. We wanted to take a moment to express our sincere appreciation for your business and continued support. Your trust in our services means a lot to us.

          However, we regret to inform you that we were unable to verify your identity at this time. As a result, we kindly request that you use an alternative method of payment to proceed with your booking. We understand that this may be inconvenient, and we apologize for any inconvenience caused.

          Please rest assured that we take the security of our customers' information and transactions very seriously. To facilitate a secure payment, we offer the following alternative methods:<br>

          1. Zelle: You can use the Zelle payment platform to send a secure payment directly from your bank account. Please follow the instructions provided by your bank to initiate the payment using Zelle.<br>

          2. Wise  Transfer: You can initiate a wire transfer from your bank to our designated bank account. Please contact our customer support team, and they will provide you with the necessary details to complete the transfer.<br>

          3. Plaid Payment: We also offer the option to make a secure payment using Plaid. Plaid is a trusted payment platform that allows you to connect your bank account securely and make payments with ease. Please visit our website or contact our customer support team for further instructions on using Plaid for your payment.<br>

          Should you require any assistance or have any questions regarding these alternative payment methods, our dedicated customer support team is available to help you navigate through this process.

          Once again, we deeply appreciate your understanding and cooperation in this matter. Thank you for choosing Fly All Over, and we look forward to serving you in the future.

          Best regards,


          Fly All Over Customer Support Team</p>
      </div>
      <div class="col-sm-5 p-3">
        <h6 class="mb-3 title-header">Zelle payment</h6>
        <p class="mb-5">Please ensure that your Zelle payment includes the correct total ticket amount and the PNR reservation code.
        </p>
        <div class=" d-flex justify-content-center">
        <div class="order-place ">
          <p *ngIf="orderData?.pnr" class="all-total text-center " style="font-size: 47px !important">PNR: <span> {{orderData?.pnr}}</span></p>
          <div class="body-card-payment-text">
            <img src="./assets/icons/pament-icons/zelle-qr-code.png" style="width: 90%;" alt="">
          </div>
          <div class="order-detail">
            <ng-container  *ngFor="let item of filghtData.passengerDetails">
        <p  class="all-meni" *ngIf="item.Quantity > 0"> {{item.Quantity}} x {{item.PassengerType}} <span>$ {{item.Price}} </span></p>
      </ng-container>
      <p *ngIf="filghtData?.prices?.AdultPriceWithOffer>'0'">Adult Price With Offer <span> ${{filghtData.prices?.AdultPriceWithOffer}}</span></p>


      <p *ngIf="filghtData?.prices?.ItinTotalFare>'0'" class="all-total">Total Price (USD) <span> ${{filghtData?.prices?.ItinTotalFare}}</span></p>
          </div>
          <div class="d-flex justify-content-center">
            <!-- <ul>

        <li>
          <div class="checkbox">
            <input (change)="validateCheckboxes($event);" [formControl]="checkbox1" class="styled" type="checkbox">
            <label for="checkbox3-4" class="red-star"> This itinerary is for an alternate date and nearby airport. Depart
              ({{itinerary?.flights[0][0].DepartureDateTime | date: 'EEE, MMM d ' }}), Return
              ({{itinerary?.flights[itinerary?.flights.length - 1][0].ArrivalDateTime | date: 'EEE, MMM d' }}).
            </label>
            <p class="ps-4">Please check this box to indicate that you've confirmed the date(s) and airport(s) to be correct and that you're ready to book this flight.
            </p>
          </div>
        </li>
        <li>
          <div class="checkbox">
            <input (change)="validateCheckboxes($event);" [formControl]="checkbox2" class="styled" type="checkbox">
            <label for="checkbox3-4" class="red-star">
              Credit Card and Contact Information Matching Confirmation</label>
          </div>
          <p class="ps-4">My credit card information is matching my contact information.
          </p>
        </li>
        <li>
          <p class="red-star ps-4">Please note: your credit/ debit Card may be billed in multiple charges totaling the final total price.
          </p>
        </li>
        <li>
          <div class="checkbox">
            <input (change)="validateCheckboxes($event);" [formControl]="checkbox3" class="styled" type="checkbox">
            <label for="checkbox3-4" > I Agree that I have read and accepted the fly All Over's <a class="color" [routerLink]="['/terms']"> TERMS & CONDITIONS </a>
            </label>
          </div>
        </li>
        <li>
          <div class="checkbox">
            <input (change)="validateCheckboxes($event);" [formControl]="checkbox4" class="styled" type="checkbox">
            <label for="checkbox3-4"> I Agree on the <a class="color" (click)="openFareRulesDialog(itinerary)">Fare Rules </a>of each one of the airlines of this trip.
            </label>
          </div>
        </li>
        <li>
          <div class="checkbox">
            <input (change)="validateCheckboxes($event);" [formControl]="checkbox5" class="styled" value="1" type="checkbox">
            <label for="checkbox3-4">Please Acknowledge Our <a href="https://flyAllOver.com/cancellation-policy/" class="color">Cancellation Policy.</a>
            </label>
          </div>
        </li>
      </ul> -->
            <div class="btn-ticket col-5  me-5" (click)="goToHome()">
              Home
            </div>
            <div class="btn-ticket-v2 col-5" (click)="goToDashbord()">
              <div class="spinner-border text-light cuz-size" role="status" *ngIf="openDocument">
              </div>
              <span class="px-2">
                Confirm
              </span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
