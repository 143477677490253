import {Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FooterAboutDialogComponent } from '../footer-about-dialog/footer-about-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent{
  constructor(public dialog:MatDialog ){

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FooterAboutDialogComponent);
}
}
