<div class="tourmaster-room-booking-bar-wrap">
  <div class="tourmaster-room-booking-bar-title"><span class="tourmaster-active" data-room-tab="booking">Book Your Room</span></div>
  <div class="tourmaster-room-booking-bar-content">
      <div class="tourmaster-room-booking-wrap" class="tourmaster-active" data-room-tab="booking">
          <form
              class="tourmaster-room-booking-form clearfix "
              id="tourmaster-room-booking-form"
              action="#"
              data-action="tourmaster_room_booking_form"
              method="POST"
          >
              <div
                  class="tourmaster-room-date-selection tourmaster-vertical"
              >
                  <div class="tourmaster-custom-start-date gdlr-core-skin-e-background">
                      <div class="tourmaster-head -core-sgdlrkin-e-content">Check In</div>
                      <div class="tourmaster-tail gdlr-core-skin-e-content">Jul 28, 2022</div>
                      <input type="hidden" name="start_date" value="2022-07-28" />
                  </div>
                  <div class="tourmaster-custom-end-date gdlr-core-skin-e-background">
                      <div class="tourmaster-head gdlr-core-skin-e-content">Check Out</div>
                      <div class="tourmaster-tail gdlr-core-skin-e-content">Jul 29, 2022</div>
                      <input type="hidden" name="end_date" value="2022-07-29" />
                  </div>
                  <div class="tourmaster-custom-datepicker-wrap" data-date-format="d M yy">
                      <i class="tourmaster-custom-datepicker-close icon_close"></i>
                      <div class="tourmaster-custom-datepicker-title"></div>
                      <div class="tourmaster-custom-datepicker-calendar"></div>
                  </div>
              </div>
              <div class="tourmaster-room-amount-selection">
                  <div class="tourmaster-custom-amount-display gdlr-core-skin-e-background">
                      <div class="tourmaster-head gdlr-core-skin-e-content">Room</div>
                      <div class="tourmaster-tail gdlr-core-skin-e-content"><span class="tourmaster-space"></span>1</div>
                  </div>
                  <div class="tourmaster-custom-amount-selection-wrap">
                      <div class="tourmaster-custom-amount-selection-item clearfix">
                          <div class="tourmaster-head">Room</div>
                          <div class="tourmaster-tail">
                              <span class="tourmaster-minus"><i class="icon_minus-06"></i></span><span class="tourmaster-amount">1</span>
                              <span class="tourmaster-plus"><i class="icon_plus"></i></span>
                          </div>
                          <input type="hidden" name="room_amount" value="1" />
                      </div>
                  </div>
              </div>
              <div class="tourmaster-room-booking-guest-selection tourmaster-one">
                  <div class="tourmaster-room-amount-selection">
                      <div class="tourmaster-custom-amount-display gdlr-core-skin-e-background">
                          <div class="tourmaster-head gdlr-core-skin-e-content">
                              <span>Room<span class="tourmaster-number">1</span> : </span>Guests
                          </div>
                          <div class="tourmaster-tail gdlr-core-skin-e-content"><span class="tourmaster-space"></span>Adults 2<span class="tourmaster-space"></span>Children 0</div>
                      </div>
                      <div class="tourmaster-custom-amount-selection-wrap">
                          <div class="tourmaster-custom-amount-selection-item clearfix" data-label="Adults">
                              <div class="tourmaster-head">Adults</div>
                              <div class="tourmaster-tail">
                                  <span class="tourmaster-minus"><i class="icon_minus-06"></i></span><span class="tourmaster-amount">2</span>
                                  <span class="tourmaster-plus"><i class="icon_plus"></i></span>
                              </div>
                              <input type="hidden" name="adult[]" value="2" />
                          </div>
                          <div class="tourmaster-custom-amount-selection-item clearfix" data-label="Children">
                              <div class="tourmaster-head">Children</div>
                              <div class="tourmaster-tail">
                                  <span class="tourmaster-minus"><i class="icon_minus-06"></i></span><span class="tourmaster-amount">0</span>
                                  <span class="tourmaster-plus"><i class="icon_plus"></i></span>
                              </div>
                              <input type="hidden" name="children[]" value="0" />
                          </div>
                      </div>
                  </div>
              </div>
              <input type="submit" class="tourmaster-room-button tourmaster-full" value="Book Now" />
          </form>
      </div>

  </div>
</div>
