import {Component, OnInit, Inject, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {DOCUMENT} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.css']
})
export class SignOutComponent implements OnInit {
  token: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private apis: ApisService,
    private matDialog: MatDialog,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.token =  this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
  }
  close(){
    this.matDialog.closeAll();
  }
  goSignOut() {
    this.localStorage.setItem('flyallover_token', undefined);
    this.router.navigate(['/']);
  }
}
