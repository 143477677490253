<div class="details-traveler" *ngIf="owner">
  <div class="section-details">
    <div class="form-details">
      <div class="header-content">
        <img alt="Additional-Info" title="Owner-Info" src="./assets/images/dashboard/Additional-Info.svg">
        <div class="item-header">
          <h4>Main Passenger</h4>
          <p>When you book your trip you can Automatically select your co-traveller And book faster. </p>
        </div>
      </div>
      <hr class="hr">
      <div class="travellers">
        <div class="col-12">
          <ngx-skeleton-loader *ngIf="loading" [theme]="{height: '300px'}"></ngx-skeleton-loader>
        </div>
        <div class="members ">
          <div class="col-12">
            <div class="row justify-content-between align-items-center">
              <div class="col-4 d-flex align-items-center">
                <span class="col-2"><img style=" height: 40px; width: 40px; border-radius: 20px;"
                    src="https://test.api.flyallover.com/storage/images/zVMCIYkZoAwzpJbE2YjSjOGPQdt8B5bIbTchiLQ8.png"
                    title="Owner Information" alt="owner-image"></span>
                <span class="info info-name col-10">{{owner.first_name?.toUpperCase() + ' ' +
                  owner.middle_name?.toUpperCase() + ' ' + owner.last_name?.toUpperCase()}}</span>
              </div>
              <div class="col-3">
                <span *ngIf="owner.passport" class="info col-2">{{owner.passport.passport_number}}</span>
                <span *ngIf="!owner.passport" class="info p-5 mr-5 col-2">----- ----- -----</span>
              </div>
              <div class="col-4">
                <div class="row">
                  <!-- <button class="btn save-btn w-auto"
                          style="margin-right: 10px" (click)="info(owner.id); showTraveler = !showTraveler">
                    <img alt="information" style="height: 35px"
                         title=" Information Image" src="./assets/images/dashboard/information.svg">
                  </button> -->
                  <button class="btn save-btn" *ngIf="owner.passport" (click)="getPassportInfo(owner.id, owner.first_name?.toUpperCase() + ' ' + owner.middle_name?.toUpperCase() + ' ' + owner.last_name?.toUpperCase() );
                  passportInfo = !passportInfo">
                    <img alt="edit" title="Edit Image" src="./assets/images/dashboard/edit.svg">
                  </button>
                  <button class="btn save-btn" *ngIf="!owner.passport"
                  (click)="addPassport = !addPassport ; newPassport(owner.id)">
                  <span class="cursoer-pointer" (click)="passportInfo = !passportInfo"
                    *ngIf="!owner.passport">passport is required</span>
                    <img alt="add" class="mr-1 d-inline-block" title="Add Image"
                      src="./assets/images/dashboard/add%20(2).svg">
                  </button>
                  <button *ngIf="!selectable" class="btn save-btn w-auto" (click)="openDialog(owner.id)">
                    <img alt="Icon-material-delete-forever" title="Delete"
                      src="./assets/images/dashboard/Icon%20material-delete-forever.svg">
                  </button>
                  <ng-container *ngIf="selectable">
                    <mat-checkbox class="w-auto" [disableRipple]="true" (change)="toggleCheck($event ,owner)">
                    </mat-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-add-passport *ngIf="addPassport" [id]="memberId" (closeForm)="closeAddPassport()"></app-add-passport>
    <app-edit-passport *ngIf="passportInfo" [id]="memberId" [memberName]="memberName" [memberRelation]="memberRelation"
      [familyMembers]="familyMembers" (closeForm)="closePassport()"></app-edit-passport>
  </div>
</div>