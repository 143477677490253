import { element } from "protractor";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ApisService } from "src/app/services/apis.service";
import { ActivatedRoute } from "@angular/router";
import { HotelsApisService } from "src/app/services/hotels-apis.service";

@Component({
  selector: "app-form-vacation-hotel-payment",
  templateUrl: "./form-vacation-hotel-payment.component.html",
  styleUrls: ["./form-vacation-hotel-payment.component.css"],
})
export class FormVacationHotelPaymentComponent implements OnInit {
  form: FormGroup;
  formFirstNameAndLastName: FormGroup;
  card: any;
  id: number;
  numberOfTravellers: number;
  hotelDetails: any;
  vendorCode;
  errorMessageMonth;
  errorMessageYear;
  filteredCountries: Observable<string[]>;
  error = false;
  countries;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApisService,
    private _ActivatedRoute: ActivatedRoute,
    private _HotelsApisService: HotelsApisService
  ) {
    this.form = this.initForm();
    this.formFirstNameAndLastName = this.firstAndLastName();
  }

  ngOnInit(): void {
    this.id = +this._ActivatedRoute.snapshot.paramMap.get("idHotel");
    this.numberOfTravellers =
      +this._ActivatedRoute.snapshot.paramMap.get("numberOfTravellers");
    
    this._HotelsApisService.vacationDetails(this.id).subscribe((response) => {
      this.hotelDetails = response;
    });

    this.getCountries();
  }

  firstAndLastName(): FormGroup {
    return new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
    });
  }

  printFAndL() {
    
  }

  initForm(): FormGroup {
    return new FormGroup({
      CreditCardNumber: new FormControl("", []),
      CreditCardVendorCode: new FormControl("", []),
      // cardHolderName: new FormControl("", []),
      CreditCardExpireDate: new FormControl("", []),
      cardHolderFullName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){2,}$"),
      ]),
      card: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{16}"),
      ]),
      cvc: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{3}"),
      ]),
      CreditCardExpireDateMonth: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,}"),
      ]),
      CreditCardExpireDateYear: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
      // addressLine2: new FormControl(""),
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-zA-Z- ]{2,}"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      State: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-zA-Z-]{2,}"),
      ]),
      // stateOrProvince: new FormControl("", [Validators.pattern("[a-zA-Z ]+")]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,}"),
      ]),
    });
  }
  get f() {
    return this.form.controls;
  }

  get formFirstAndLast() {
    return this.formFirstNameAndLastName.controls;
  }
  validationFirstAndLast(FormControlName: any) {
    let element = this.document.getElementById(FormControlName);

    this.formFirstNameAndLastName
      .get(FormControlName)
      .valueChanges.subscribe((value) => {
        if (!this.formFirstAndLast[FormControlName]?.errors?.required) {
          element.classList.remove("is-invalid");
          element.classList.add("is-valid");
        } else {
          element.classList.remove("is-valid");
          element.classList.add("is-invalid");
        }
      });
  }

  validationForm(FormControlName: any) {
    let element = this.document.getElementById(FormControlName);

    this.form.get(FormControlName).valueChanges.subscribe((value) => {
      if (this.f[FormControlName].dirty) {
        if (FormControlName == "email") {
          return;
        } else {
          if (!this.f[FormControlName]?.errors?.required) {
            element.classList.remove("is-invalid");
            element.classList.add("is-valid");
            if (!this.f[FormControlName]?.errors?.pattern) {
              element.classList.remove("is-invalid");
              element.classList.add("is-valid");
            } else {
              element.classList.remove("is-valid");
              element.classList.add("is-invalid");
            }
          } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
          }
        }
      }
    });
  }
  updateExpireDate() {
    const date =
      this.form.value.CreditCardExpireDateYear +
      "-" +
      this.form.value.CreditCardExpireDateMonth;
    this.form.patchValue({
      CreditCardExpireDate: date,
    });
  }
  format(e) {
    e.target.value = this.padLeft(e.target.value, "0", 2);
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }
  validationCountry(FormControlName: any) {
    let element = this.document.getElementById(FormControlName);
    if (!this.f[FormControlName]?.errors?.required) {
      element.classList.remove("is-invalid");
      element.classList.add("is-valid");
      if (!this.f[FormControlName]?.errors?.someProp) {
        element.classList.remove("is-invalid");
        element.classList.add("is-valid");
      } else {
        element.classList.remove("is-valid");
        element.classList.add("is-invalid");
      }
    } else {
      element.classList.remove("is-valid");
      element.classList.add("is-invalid");
    }
  }
  testCreditCard() {
    this.card = this.form.value.card;
    const vendorCode = {
      AX: "American Express",
      CB: "Carte Blanche",
      DC: "Diners Club",
      DS: "Discover",
      CA: "MasterCard",
      VI: "Visa",
      JC: "JCB",
    };
    if (this.checkCreditCard(this.form.value.card, vendorCode["AX"])) {
      this.vendorCode = "AX";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CB"])) {
      this.vendorCode = "CB";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DC"])) {
      this.vendorCode = "DC";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DS"])) {
      this.vendorCode = "DS";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CA"])) {
      this.vendorCode = "CA";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["VI"])) {
      this.vendorCode = "VI";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["JC"])) {
      this.vendorCode = "JC";
      this.error = false;
    } else {
      this.vendorCode = "VI";
      this.error = true;
    }
    const object = {
      card: this.form.value.card,
      error: this.error,
      vendorCode: this.vendorCode,
    };
    // this.setPaymentCardEvent(object);
  }
  checkDate() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const valueMonth = this.form.value.CreditCardExpireDateMonth;
    const valueYear = this.form.value.CreditCardExpireDateYear;
    if (valueYear == null) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Is Required!";
    }
    if (valueYear > year) {
      this.errorMessageYear = null;
      this.errorMessageMonth = null;
    }
    if (valueYear < year) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Should Be More Than " + year;
    }
    if (valueYear == year) {
      this.errorMessageYear = null;
      if (month > valueMonth) {
        this.errorMessageYear = null;
        this.errorMessageMonth = "Month should Be More Than " + month;
      }
      if (month <= valueMonth) {
        this.errorMessageMonth = null;
      }
    }
  }
  getCountries() {
    this.apiService.getCountries().subscribe((res): any => {
      this.countries = res;
      
      this.filteredCountries =
        this.form.controls.CreditCardCountry.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterCountries(value))
        );
    });
  }
  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  checkCreditCard(cardnumber, cardname) {
    let ccErrorNo = 0;
    const ccErrors = [];
    const cards = [];
    ccErrors[0] = "Unknown card type";
    ccErrors[1] = "No card number provided";
    ccErrors[2] = "Credit card number is in invalid format";
    ccErrors[3] = "Credit card number is invalid";
    ccErrors[4] = "Credit card number has an inappropriate number of digits";
    ccErrors[5] =
      "Warning! This credit card number is associated with a scam attempt";

    // Define the cards we support. You may add addtional card types as follows.
    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit

    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      checkdigit: true,
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      checkdigit: true,
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      checkdigit: true,
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305",
      checkdigit: true,
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      checkdigit: true,
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      checkdigit: true,
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35",
      checkdigit: true,
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149",
      checkdigit: true,
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767",
      checkdigit: true,
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
      checkdigit: true,
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      checkdigit: true,
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      checkdigit: true,
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      checkdigit: true,
    };

    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {
      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType == -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length == 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, "");

    // Check that the number is numeric
    let cardNo = cardnumber;
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 != 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo == "5490997771092064") {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(",");

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp("^" + prefix[i]);
      if (exp.test(cardNo)) {
        PrefixValid = true;
      }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(",");
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length == lengths[j]) {
        LengthValid = true;
      }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    }

    // The credit card is in the required format.
    return true;
  }
}
