import {Component, OnInit, Inject, HostListener} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {HotelsApisService} from 'src/app/services/hotels-apis.service';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from '../../services/seo.service';
import { FroalaEditorStylesService } from 'src/app/services/froala-editor-styles/froala-editor-styles.service';

@Component({
  selector: 'app-centre-article',
  templateUrl: './centre-article.component.html',
  styleUrls: ['./centre-article.component.css']
})
export class CentreArticleComponent implements OnInit {

  fragment: string;
  articleSlug: string; // this is what we send to the api to get certain article
  linkToBeShared: string;


  data;
  posts = [' ']; // this is an array of length 1, because an array of length 0 will indicate having an error
  url: string;

  carouselOptions: OwlOptions = {
    navText: ["<i class='arrow arrowNext fa fa-chevron-left'></i>", "<i class='arrow arrowPrev fa fa-chevron-right'></i>"],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    dots: false,
    nav: true
  };

  // booleans
  loading: boolean = true;
  close_status: boolean = true;
  moreHeaders: boolean = false;

  hostUrl: string;

  constructor(
    private seoService: SEOService,
    private route: ActivatedRoute,
    private apis: HelpCenterServices,
    private router: Router,
    private hotelApis: HotelsApisService,
    private metaService: MetaTagsService,
    private title: Title,
    private sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private froalaStyles: FroalaEditorStylesService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.seoUpdate();
  }

  seoUpdate(title = null, description = null, image = null) {

    this.seoService.updateDescription('Frequently Asked Questions on all Flyallover travel services. Answers to most FAQs on flights, hotels, cruises, vacations and travel insurance and all you need in Booking');
    this.seoService.updateUrl();
    this.seoService.updateTitle('Support | Fllyallover');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');
    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }

  ngOnInit(): void {
    this.froalaStyles.addFroalaStylesFile();
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.url = this.router.url;

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
    this.route.paramMap.subscribe(params => {
      this.articleSlug = params.get('slug');
      if (this.articleSlug) {
        this.getArticle();
      }
    });
    this.linkToBeShared = `${hostUrl}${this.url}`;
  }

  getArticle() {
    this.loading = true;
    this.apis.getArticle(this.articleSlug).subscribe((res: any) => {
      this.data = res.data;
      this.posts = this.data?.people_also_read;
      this.loading = false;
      this.seoUpdate(this.data?.meta_data?.data?.title, this.data?.meta_data?.data?.description, this.data?.meta_data?.data['og:image']);
      // this.metaService.modifyMetaTags(res?.data?.meta_data?.data)
    }, err => {
      this.data = undefined;
      this.posts = [];
      this.loading = false;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // const pageScrollPosition = this.document.documentElement.scrollTop;
    const topOfArticle = this.document.getElementById('article').getBoundingClientRect().top;
    const bottomOfArticle = this.document.getElementById('article').getBoundingClientRect().bottom;
    const socialListElement = this.document.getElementById('social');
    const headersTop = this.document.getElementById('headers').getBoundingClientRect().top;
    const articleContainerBottom = this.document.getElementById('article-container-bottom').getBoundingClientRect().bottom;
    const sidebarElement = this.document.getElementById('sidebar');
    const sideBarHeight = this.document.getElementById('sidebar').clientHeight;
    // const bottomOfSidebar = this.document.getElementById('sidebar').getBoundingClientRect().bottom;
    /* if the position of top of the article is smaller -20 , that means that the article is in view,
    * then add sticky class
    * - at the same time make sure that the bottom of the artcile is still in view
    */
    //  adding sticky class to social list
    if (socialListElement) {
      if (topOfArticle < -20 && bottomOfArticle > 300) {
        socialListElement.classList.add('sticky');
      } else {
        socialListElement.classList.remove('sticky');
      }
    }
    // adding sticky class to search component
    if (sidebarElement) {
      // 150 is the height of article footer
      // 21 is the space between the top of the screen and header's top
      if ((headersTop < 21 && topOfArticle < 0) && (articleContainerBottom > 150 && articleContainerBottom > sideBarHeight)) {
        sidebarElement.classList.add('sticky');
      } else {
        sidebarElement.classList.remove('sticky');
      }
    }
  }

  modifyMetaTags(metaData) {
    this.metaService.modifyMetaTags(metaData);
  }

  sanitizeHtml(injectedHtml) {
    return this.sanitizer.bypassSecurityTrustHtml(injectedHtml);
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
