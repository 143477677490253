import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID, OnChanges, SimpleChanges } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../../services/webApis/local-storage.service';
import { FareRulesComponent } from '../fare-rules/fare-rules.component';
import { CovidPopupComponent } from '../covid-popup/covid-popup.component';
import { AlternativeDatesComponent } from '../alternative-dates/alternative-dates.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../services/data.service';
import { response } from 'express';
import { log } from 'console';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.css', './list.component.scss']
})
export class ItineraryComponent implements OnInit, OnChanges {
  TagIDSEL: any;

  constructor(private modalService: NgbModal,
    private router: Router,
    private apis: ApisService,
    private dataFake: DataService,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private localStorage: LocalStorageService,
    public dialog: MatDialog, private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object

  ) {
    this.searchRequest = this.route.snapshot.queryParams;
  }
  selectedIndex: any;
  selectedItineraryTagId: any;
  selectedData: any[] = [];
  dataSearchBFM: any;
  selectRound = false;
  hidden = false;
  showStops2 = false;
  showStops3 = false;
  showStops1 = false;
  showAirCraftPop = false;
  currentTooltipData = '';
  name = 'World';
  @Output() filterAlternativeDatesEvent = new EventEmitter();
  @Input() reviewFlight: any;
  @Input() itineraries: any;
  @Input() sabre: any;
  @Input() flights: any;
  @Input() origin: any;
  @Input() destination: any;
  @Input() alternativeDatesResponse: any;
  @Input() search: any;
  @Input() page: number;
  @Input() resultsArrived: boolean;
  @Input() loading: boolean;
  @Input() skeletons: number;
  @Output() select = new EventEmitter();
  @Output() fareData = new EventEmitter();
  @Input() airLines: any;
  @Input() toggleDetails: boolean;
  @Input() review: boolean;
  @Input() cheapestFlights;
  @Input() flightResultsPage;
  @Input() searchRequest_Trip;
  @Input() shareValue;
  @Input() altrnative_bg;
  @Input() loadCount;
  @Input() tagId;

  // @Input() toggleDetails: boolean;
  @Input() checkSelect: boolean;
  @Output() selectDepartReturn = new EventEmitter();
  val = 0;
  val2 = 1;
  closedStatus = true;
  fareRules;
  cabin: string;
  depatureDate: any;
  returnDate: any;
  statusTrip: boolean;
  dayMillisecond: any;
  url: string;
  airlinesRules = [];
  airlines: string[] = [];
  dialogRef: any;
  lowestPrice: any;
  lowestPriceObj: any;
  lowestPriceDate: any;
  covidSearch: any;
  lowPrice: any;
  formData: any;
  depDates: any;
  depDatesKeys: any;
  depDateKey: any;
  depDatesValues: any;
  tripType: any;
  returnDatesArray: any;
  state: any = 0;
  numOfTravellers: any;
  closeResult = '';
  data: any = [];
  airlineInfo: any;
  airport: any = null;
  flag = false;
  flag2 = false;
  isLast = false;
  isHide = false;
  fakeData: any = [];
  searchRequest: any;
  selectedArray = [];
  Array2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  airlineImg: any = [];
  randomMin: any;
  toggleShow = false;
  interval: any;
  @Input()airlineForSearchData: any;
  itinerariesFackData: any[] = [];
  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   if( this.flights ) {
    //     this.flights = this.airlineForSearchData;
    //   }
    // }, 1000);
    this.itinerariesFackData = [];

    this.tripType = this.searchRequest.trip_type;

    console.log('flights ngOnInit => {' ,this.flights)
    this.counterData(0);
    this.getArrayValues(0);
    this.getAirLines();

    this.lowestPrice = this.alternativeDatesResponse?.lowest_price;

    this.fareRules = this.itineraries;

    this.fareData.emit('hello');
    this.url = this.router.url;
    if (this.fareRules) {
      this.rulesByAirline();
    }
    if (this.search) {
      this.countDiffDays();
    }
    this.getAirLines();


  }
  getAirlineForFilght(){
    // if( this.flights ) {
    //   this.flights = this.airlineForSearchData;
    // }
  }
  selectRoundIti(e, event) {
    this.selectRound = !this.selectRound;
    this.selectedIndex = e;
    this.selectedItineraryTagId = event.TagID;

    this.selectDepartReturn.emit({
      selectedIndex: this.selectedIndex,
      selctedRound: this.selectRound,
      selectedItineraryTagId: this.selectedItineraryTagId
    });

  }
  BactToIti() {
    this.selectRound = false;
    window.location.reload();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.loading?.currentValue) {
      this.counterData(0);
    }
  }
  get(e): boolean {
    if (e == 'Aus-Air') {
      return true;
    }
    return false;
  }
  dataHandler(): void {
    this.searchRequest = this.route.snapshot.queryParams;
  }

  getSelectedTicket(event) {

    this.select.emit(event);
  }
  getArrayValues(index) {
    this.interval = setInterval(() => {
      if (index == this.dataFake.airlinesLog.length) {
        this.airlineImg = [];
        index = 0;
        return;
      }
      this.airlineImg.push(this.dataFake.airlinesLog[index]);
      index++;
    }, 500);
  }
  counterData(count) {
    let refreshIntervalId = setInterval(() => {
      if (count == this.dataFake.data.length || !this.loading) {
        this.itinerariesFackData = [];
        clearInterval(refreshIntervalId);
        return;
      }
      if (this.searchRequest?.offers == 'false') {
        this.itinerariesFackData.splice(0, 0, this.dataFake.dataFakeEgyptair[count]);
      } else {
        this.itinerariesFackData.splice(0, 0, this.dataFake.data[count]);
      }

      count++;
    }, 500);
  }


  toggleFlightDetails(flightNumber) {
    this.document.querySelector('.flightDetails.flightNumber' + flightNumber).classList.toggle('hide');
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = ` ${Math.floor(minutes / 60)}h`;
    }
    if (Math.floor(minutes % 60) > 0) {
      convertedMinutes = ` ${minutes % 60}m`;
    }
    return convertedHours + ' ' + convertedMinutes;
  }

  countDiffDays() {
    this.depatureDate = Date.parse(this.search.departure_date);
    this.returnDate = Date.parse(this.search.return_date);
    this.dayMillisecond = 86400000;
    if ((this.returnDate - this.depatureDate) > this.dayMillisecond) {
      this.statusTrip = false;
    } else {
      this.statusTrip = true;
    }
  }

  rulesByAirline() {
    this.fareRules.forEach((el, i) => {

      if (this.airlines.indexOf(el.OperatingAirlineCode) === -1) {
        const fareData = {
          destination_location: el.flights[0][(el.flights[0].length) - 1].ArrivalAirport,
          origin_location: el.flights[0][0].DepartureAirport,
          marketing_carrier: el.flights[0][0].MarketingAirline,
          fare_basis: this.itineraries[0].FareBasisCode
        };
        this.airlines.push(el.OperatingAirlineCode);
      }
    });

  }

  getNumberOfBags(trip) {
    return trip[0].allow.Pieces;
  }


  createRange(numberVar: any) {
    const items: number[] = [];
    for (let i = 1; i <= numberVar; i++) {
      items.push(i);
    }
    return items;
  }

  public closeCovidPopUp() {
    this.closedStatus = true;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CovidPopupComponent, {
      data: { search: this.covidSearch },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFareRulesDialog(itinerary) {
    this.dialogRef = this.matDialog.open(FareRulesComponent, {
      panelClass: 'FareRules',
      maxHeight: '90vh',
      width: '50%',
    });
    this.dialogRef.componentInstance.itinerary = itinerary;
  }

  selectFlight(TagID, itinerary) {
    this.TagIDSEL = TagID;
    let tripType = 'Return';
    if (itinerary.length === 1) {
      tripType = 'OneWay';
    }
    const oneResult = {
      tagId: TagID,
      class: itinerary.prices.FareClassName,
      trip_type: tripType
    };
    this.matDialog.closeAll();
    this.localStorage.setItem('selectedFlight', JSON.stringify(oneResult));
    this.router.navigate(['review-flight'], { queryParams: oneResult });
  }

  selectDate(returnDate, carrier) {
    const obj = {
      this: this.depDateKey,
      returnDate,
      carrier,
    };
    this.dialogRef.close(obj);
  }

  getReturnDateKey(obj) {
    return Object.keys(obj)[0];
  }

  getAirCarrierName(obj) {
    return obj[Object.keys(obj)[0]].carrireName;
  }

  getReturnDatesLength(obj) {
    const tds = [];
    tds.length = (7 - obj.length);
    return tds;
  }

  scrollRight() {
    document.getElementById('table').scrollLeft += 100;
  }

  scrollLeft() {
    document.getElementById('table').scrollLeft -= 100;
  }

  openAlternativeDates(): void {
    this.dialogRef = this.matDialog.open(AlternativeDatesComponent, {
      panelClass: 'alternativeDateDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '50%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data && data.departureDate && data.returnDate) {
        this.filterAlternativeDatesEvent.emit(data);
      }
    });
  }

  getAirLines() {
    this.apis.getHomeAirLines().subscribe((data: any) => {
      this.data = data.data;

    },
      err => {
      });
  }

  getAirlineInfo(airlineName) {
    this.getAirLines();
    this.airlineInfo = null;
    for (let i = 0; i < this.data.length; i++) {
      if (airlineName == this.data[i].name) {
        this.airlineInfo = this.data[i];
      }
    }
  }


  toggleWithGreeting(tooltip, greeting: string) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({ greeting });
    }
  }

  setData(data) {
    this.currentTooltipData = data;
  }

  getAirport(airportCode): any {
    this.airport = null;
    this.apis.getAirports(airportCode).subscribe((data: any) => {
      this.airport = data.data;
    },
      err => {
      });
  }

  changeFlag() {
    this.flag = !this.flag;
    this.flag2 = false;
  }

  changeFlag2() {
    this.flag2 = !this.flag2;
    this.flag = false;
  }

  isLastest() {
    this.isLast = !this.isLast;
  }

  changeIsHide() {
    this.isHide = !this.isHide;
  }

  out() {
    this.airlineInfo = null;
  }

  // new
  toggle(index) {
    this.toggleShow = !this.toggleShow;
  }
  showDialogStop() {
    document.getElementById('dialog').classList.add('hidden-dialog');
  }
  ShareFlightDetails(TagID) {
    this.router.navigate([`/flight-details/${TagID}/${this.tripType}`]);
  }
  goToReviewFlight(TagID) {

    this.router.navigate([`/review-flight/${TagID}/${this.tripType}`]);
  }

}


