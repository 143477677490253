<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>view itinerary</title>
</head>
<style>
    body {
        display: flex;
        justify-content: center;
        width: 100%;
        font-family: "poppins", sans-serif;
        background-color: #F9F9F9;
    }

    nav {
        background-color: #1573BC;
        height: 180px;
        width: 700px;
    }

    .title {
        width: 700px;
        text-align: center;
        font-size: 30px;
        padding: 20px 10px;
        font-family: "poppins", sans-serif;
    }

    .hr-blue {
        width: 700px;
        height: 10px;
        background-color: #1573BC;
        margin: 0;
    }

    .hr-grey {
        width: 700px;
        height: 10px;
        background-color: #F9F9F9;
        margin: 0;
        /* box-shadow: 0px 0px 10px #00000033; */
    }

    .departing {
        background-color: white;
        display: flex;
        justify-content: center;
        font-size: 25px !important;
        font-weight: 500;
        padding: 40px 10px;
        width: 700px;
    }

    .button {
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .button button {
        background-color: #009FE2;
        border-radius: 5px;
        color: white;
        padding: 10px 20px;
        font-size: 20px;
        border-style: none;
    }

    .arrow {
        width: 20px;
    }
</style>

<body>
    <div style="display: flex; align-items: center; flex-direction: column;">
        <nav style="display: flex; justify-content: center;">
            <img title="flyallover" alt="logo"  src="../../../../assets/images/logo-white2.png" width="350px" style="margin: auto;">
        </nav>
        <div class="banner">
            <img title="flyallover" alt="travel"  src="../../../../assets/images/travel2.PNG" width="700px">
        </div>
        <div class="title">
            <pre style="font-family: poppins,sans-serif; margin-bottom: 0;"><strong><h4>Linda’s Flight To London</h4></strong>
Passenger: <strong>Linda Fowley</strong>
Reservation Number: <strong>KLGEFR</strong></pre>

        </div>
        <hr class="hr-blue">
        <div class="departing">
            <h6>Departing</h6>
            <img alt="flight" title="Departing"  src="../../../../assets/images/emails/flight-icon.png" width="75px" style="margin:0 125px">
            <div style="text-align: center;">
                <pre style="font-family: poppins,sans-serif;">Sat 08 Nov
<strong>01:56 PM</strong>
<strong>London (LHR)</strong></pre>
            </div>
        </div>
        <hr class="hr-grey">
        <div class="departing">
            <h1>Arriving</h1>
            <img alt="flight" title="Arriving"  src="../../../../assets/images/emails/flight-icon.png" width="75px" style="margin:0 125px">
            <div style="text-align: center;">
                <pre style="font-family: poppins,sans-serif;">Sat 08 Nov
<strong>03:55 PM</strong>
<strong>New York (JFK)</strong></pre>
            </div>
        </div>
        <hr class="hr-grey">
        <div class="button">
            <button>View Itinerary <img alt="right-arrow"  title="View Itinerary" src="../../../../assets/images/right-arrow.png" class="arrow"></button>
        </div>
    </div>
</body>

</html>
