import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  formSubmitted: boolean = false;
  backendError: boolean = false;
  // form
  emailForm: FormGroup;
  // validation messages
  validationMessages = {
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}]
  }
  // spinner
  roundSpinner: boolean = false;
  url: string;

  //google recaptcha
  siteKey: string = '';
  captchaKey: string = '';

  constructor(
    private seoService: SEOService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private centerApis: HelpCenterServices,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.siteKey = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
    this.createForm();
  }

  seoUpdate() {

    this.seoService.updateTitle('Forget Password | FlyAllOver');
    this.seoService.updateDescription('Forget Password | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createForm() {
    this.emailForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ]))
    });
  }

  resolved(captchaResponse: string) {
    this.captchaKey = captchaResponse;
  }

  onSubmitEmailForm(formValue) {
    this.roundSpinner = true;
    if (this.captchaKey) {
      this.centerApis.forgetPassword(formValue.email, this.captchaKey).subscribe(
        (res: any) => {
          this.roundSpinner = false;
          this.formSubmitted = true;
        },
        (err) => {
          this.roundSpinner = false;
          this.backendError = true;
        }
      );
    }
  }

}
