<div class="top-deals">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1>Top Destination Deals</h1>
      </div>
      <ng-container *ngFor="let country of countries">
        <div class="col-4 mb-3">
          <div class="position-relative flight-container d-flex flex-column">
            <img alt="par" title="Top Destination Deals" class="d-flex img-fluid" style="height: 230px" src="{{country.banner}}">
            <div class="flights">
              <ng-container *ngFor="let flight of country.flights">
                <div class="d-flex justify-content-between flight">
                  <div class="d-flex flex-column dest">
                    <span>{{flight.dest}}</span>
                    <span class="date">{{flight.date}}</span>
                  </div>
                  <div>
                    <img alt="flight img" title="flight-img" class="d-flex img-fluid" src="{{flight.img}}">
                  </div>
                  <div class="price">
                    <span>{{flight.price}}</span>
                    <sub>{{flight.sub}}</sub>
                  </div>
                </div>
                <div class="engine">
                  <app-engine-static></app-engine-static>
                </div>
                <div class="hr"></div>
              </ng-container>
              <span>All fares are in USD per person</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-12 mt-3 fares">
      <div class="fare-term"><sup>*</sup><span id="trvlDates">All fares above were last found on Friday, June 18, 2021 9:00 AM. Travel dates range Jul 26,2021 - Aug 28,2021.</span>.
        Fares are round trip. Fares incl. all fuel surcharges, our <a href="javascript:ServiceFeesInfo()">service
          fees</a> and <a href="javascript:TaxesFeesInfo()">taxes</a>. Displayed fares are based on historical data, are
        subject to change and cannot be guaranteed at the time of booking. <a href="javascript:showTerms();">See all
          booking terms and conditions.</a>
        <span id="refDate">Ref:FB180620210900.</span></div>
    </div>
  </div>
</div>
