import {Component, OnInit, OnChanges} from '@angular/core';
import {Input, Output, EventEmitter} from '@angular/core';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-passengers-number',
  templateUrl: './passengers-number.component.html',
  styleUrls: ['./passengers-number.component.css']
})
export class PassengersNumberComponent implements OnInit, OnChanges {
  // it is used to maintain the data of passengers the user entered across the three trip types
  @Input() passengerData;
  @Output() notify = new EventEmitter();
  @Output() classType = new EventEmitter();
  @Output() infantsNumberWarningParent = new EventEmitter();
  activeClassType = 'Economy';
  roundTripOldSearch: any;
  @Input() bindClass;
  @Input() activeTravellers;
  passengers = [
    {id: 'adults', disc: 'Adults', count: 1},
    {id: 'seniors', disc: 'Seniors(65+)', count: 0},
    {id: 'youths', disc: 'Youths(16-25)', count: 0},
    {id: 'children', disc: 'Children(2-15)', count: 0},
    {id: 'seatInfants', disc: 'Seat Infants(0-2)', count: 0},
    {id: 'lapInfants', disc: 'Lap Infants(0-2)', count: 0},
  ];
  passengersTotal = 1;
  showPassengerList: boolean = false;
  // warnings variables
  zeroWarning: boolean = false;
  infantsNumberWarning: boolean = false;
  lapInfantsWarning: boolean = false;
  maxNumbersWarning: boolean = false;
  max: number = 9;
  disableList: boolean = false;
  lessThan1Warning: boolean = false;
  childrenNumbers = [];
  oldSearchData: any;
  travellersText: any;

  constructor(private localStorage: LocalStorageService) {
  }

  ngOnChanges() {
    if (this.passengerData) {
      this.passengersTotal = this.passengerData.travellers;
      this.passengers[0].count = this.passengerData.adult;
      this.passengers[1].count = this.passengerData.senior;
      this.passengers[2].count = this.passengerData.youth;
      this.passengers[3].count = this.passengerData.child;
      this.passengers[4].count = this.passengerData.seat;
      this.passengers[5].count = this.passengerData.lap;
    }
  }

  oldSearchHandler() {
    this.oldSearchData = JSON.parse(this.localStorage.getItem('oldSearch'));
    if (this.oldSearchData) {
      this.passengers[0].count = this.oldSearchData.adult;
      this.passengers[1].count = this.oldSearchData.senior;
      this.passengers[2].count = this.oldSearchData.youth;
      this.passengers[3].count = this.oldSearchData.child;
      this.passengers[4].count = this.oldSearchData.lap;
      this.passengers[5].count = this.oldSearchData.seat;
      this.activeClassType = this.oldSearchData.class;
      if (this.activeTravellers === 1) {
        this.travellersText = 'Traveller';
      } else {
        this.travellersText = 'Travellers';
      }
      this.bindClass = this.oldSearchData.travellers + ' ' + this.travellersText + ' , ' + this.oldSearchData.class;
    }
  }


  ngOnInit(): void {
    // this.oldSearchHandler();
  }

  validToDec() {
    let total = this.passengers[0].count +
      this.passengers[1].count +
      this.passengers[2].count +
      this.passengers[3].count +
      this.passengers[4].count +
      this.passengers[5].count;
    if (total == 1) {
      this.lessThan1Warning = true;
      return false;
    }
    return true;
  }

  // it decrements the passenger count depending on the id
  dec(id: string): void {
    if (this.validToDec()) {
      switch (true) {
        case id === 'adults':
          if (this.passengers[0].count > 0 && this.validToDec()) {
            this.passengers[0].count--;
          }
          break;
        case id === 'seniors':
          if (this.passengers[1].count > 0 && this.validToDec()) {
            this.passengers[1].count--;
          }
          break;
        case id === 'youths':
          if (this.passengers[2].count > 0 && this.validToDec()) {
            this.passengers[2].count--;
          }
          break;
        case id === 'children':
          if (this.passengers[3].count > 0 && this.validToDec()) {
            this.passengers[3].count--;
            this.childrenNumbers.pop();
          }
          break;
        case id === 'seatInfants':
          if (this.passengers[4].count > 0 && this.validToDec()) {
            this.passengers[4].count--;
          }
          break;
        case id === 'lapInfants':
          if (this.passengers[5].count > 0 && this.validToDec()) {
            this.passengers[5].count--;
          }
          break;
        default:
          break;
      }
      this.addUpPassenger();
    }
  }

  // it increments the passenger count depending on the id
  inc(id: string): void {
    this.lessThan1Warning = false;
    switch (true) {
      case id === 'adults':
        if (this.passengers[0].count <= this.max) {
          this.passengers[0].count++;
        }
        break;
      case id === 'seniors':
        if (this.passengers[1].count <= this.max) {
          this.passengers[1].count++;
        }
        break;
      case id === 'youths':
        if (this.passengers[2].count <= this.max) {
          this.passengers[2].count++;
        }
        break;
      case id === 'children':
        if (this.passengers[3].count <= this.max) {
          this.passengers[3].count++;
          this.childrenNumbers.push(this.passengers[3].count);
        }
        break;
      case id === 'seatInfants':
        if (this.passengers[4].count <= this.max) {
          this.passengers[4].count++;
        }
        break;
      case id === 'lapInfants':
        if (this.passengers[5].count <= this.max) {
          this.passengers[5].count++;
        }
        break;
      default:
        break;
    }
    this.addUpPassenger();
  }

  // calculate the total number of passengers & do some chesks
  addUpPassenger() {
    // 0, 1 & 2 are indexes for adults, seniors and youth respectively (allowed to travel alone)
    let adultsSum: number = this.passengers[0].count + this.passengers[1].count + this.passengers[2].count;
    let lapInfants: number = this.passengers[5].count;
    let seatInfants: number = this.passengers[4].count;


    // hide all warnings when recalculating the numbers
    this.zeroWarning = false;
    this.infantsNumberWarning = false;
    this.lapInfantsWarning = false;
    this.maxNumbersWarning = false;

    // assign it to zero to calculate correctly
    this.passengersTotal = 0;
    this.passengers.forEach(el => this.passengersTotal += el.count);

    // passengers number should be more than 0
    if (adultsSum <= 0) {
      this.zeroWarning = true;
    }
    // passengers number should be less than or equal max (9)
    if (this.passengersTotal > this.max) {
      this.maxNumbersWarning = true;
    }
    if ((lapInfants + seatInfants) >= (adultsSum * 2)) {
      this.infantsNumberWarning = true;
    }
    if ((lapInfants) == (adultsSum * 2)) {
      this.infantsNumberWarning = true;
    }
    if (lapInfants > adultsSum) {
      this.lapInfantsWarning = true;
    }
    // emit an event that the user has updated passengers number
    this.notify.emit();

  }

  showList() {
    if (this.disableList) {
      this.showPassengerList = false;
      return;
    }
    this.showPassengerList = !this.showPassengerList;
  }

  closeList() {
    /******
     * the list can be also closed by clicking on any part of app-home-search component (visa.component.html line:1)
     * and app-search component
     * ******/
    this.showPassengerList = false;
  }

  // this method to return the data of passengers (used in the parent home-search)
  fetchData() {
    return {
      travellers: this.passengersTotal,
      adult: this.passengers[0].count,
      youth: this.passengers[2].count,
      senior: this.passengers[1].count,
      child: this.passengers[3].count,
      lap: this.passengers[5].count,
      seat: this.passengers[4].count,
    };
  }

  getClass(type) {
    this.classType.emit(type);
    this.activeClassType = type;
  }

  countInfants(infantsNumberWarning) {
    this.infantsNumberWarningParent.emit(infantsNumberWarning);
  }
}
