import {Component, OnInit, Input, Output, EventEmitter, Inject, SimpleChanges,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
@Component({
  selector: 'app-engine-point',
  templateUrl: './engine-point.component.html',
  styleUrls: ['./engine-point.component.css']
})
export class EnginePointComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EnginePointComponent>,@Inject(MAT_DIALOG_DATA) public data: any,location: Location, router: Router) {
    router.events.subscribe(val => {
      if (location.path() != "") {
      //   this.route = location.path();
      // } else {
      //   this.route = "Home";
      this.close();
      }
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }


}
