import {Component, HostListener, OnChanges, OnInit} from '@angular/core';
import {ApisService} from 'src/app/services/apis.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit{
  title = 'chat-float';
  response: any;
  config = {
    title: 'Fridy',
    subTitle: 'Your fly buddy',
  };
  constructor(private apis: ApisService,
  ) {
  }
  getMessage($event) {
  }
  ngOnInit(): void {
    this.getResponse();
  }
  getResponse(): void{
  }
}
