<div class="news-letter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Join News letter</h4>
            <p>Get discounts and gifts every week!</p>
            <form action="" method="post">
              <input type="email" name="email" >
              <!-- placeholder="Enter your Email" -->
              <!-- <span class="subscription-form_icon"></span> -->
              <input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div>