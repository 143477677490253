import {AppComponent} from '../app.component';

export const metaData = {
  title: 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips',
  meta_data: {
    'og:title': 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips',
    'og:url': `${AppComponent.hostUrl}`,
    'og:type': 'website',
    'og:image': `${AppComponent.hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
    'og:description': 'Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now',
    'twitter:card': 'summary_large_image',
    'twitter:title': 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips',
    'twitter:image': `${AppComponent.hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
    'twitter:description': 'Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now',
    'twitter:url': `${AppComponent.hostUrl}`,
    'title': 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips',
    'description': 'Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now',
  }
};
