<app-navbar></app-navbar>
<div class="container m-auto">
  <div class="row " >
    <header class="header">
      <div class="header__info">
        <!-- A resting <span> place that suits you</span> -->
        <h1 class="heading--0 header__heading">{{vacationDetails.title}}</h1>
        <p class="paragraph"  (click)="routToHotelPage()" >Our suites are made of the best failities to bring you the comfort you deserve.</p>
      </div>

      <div class="header__video-container">
        <img [src]="vacationDetails.main_image" class="header__video" alt="">
      </div>

      <div class="header__cta">
        <div class="header__cta-content">
          <span class="header__cta-title">ٍStart Date</span>
          <span class="header__cta-date">{{vacationDetails.start_date | date: 'EEEE, MMMM d, y'}}</span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <span class="header__cta-title">End Date</span>
          <span class="header__cta-date">{{vacationDetails.end_date|date: 'EEEE, MMMM d, y'}}</span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <span class="header__cta-title">Vacation Highlights</span>
          <span class="header__cta-date">{{vacationDetails.number_of_nights}} nights accommodations</span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <a (click)="openDialog()" class = "btn btn--book">Book Now</a>
        </div>
        <div class="warningPriceMessage">
          <p style="color: red;">the 16 dining experiences on the resort</p>
        </div>
      </div>

    </header>
    <section class="section">
      <div class="row mt-4">
        <h1 class="heading heading--1 mb-4"> <span>{{vacationDetails.title}}</span></h1>
        <div class="col-12 ">
          <p class="paragraph">{{vacationDetails.vacation_description_1}}</p>
          <p class="paragraph">{{vacationDetails.vacation_description_2}}</p>
          <p class="paragraph">{{vacationDetails.vacation_description_3}}</p>
          <p class="paragraph">{{vacationDetails.vacation_description_4}}</p>
          <p class="paragraph">{{vacationDetails.vacation_description_5}}</p>

        </div>
      </div>

      <!-- ======= Our Services Section ======= -->
      <section id="services" class="services sections-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-header">
            <h2>Our Details </h2>
            <p class="details-info">{{vacationDetails.title}} | Vacation Package <span class="color-info"> {{vacationDetails.number_of_nights}} nights from ${{' '+vacationDetails.price}} </span> </p> <br>
            <p class="details-info" style="color: red;">Book by: {{vacationDetails.book_by|date: 'EEEE, MMMM d, y'}}</p>
<!--            <p class="details-info">The price above is for travel from June 25 - 29, 2023.</p>-->
          </div>

          <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">

            <div class="col-lg-4 col-md-6 ">
              <div class="service-item  position-relative">
                <div class="icon">
                  <i class="bi bi-house-heart-fill"></i>
                </div>
                <h3>Vacation Highlights</h3>
                <ng-container *ngFor="let item of vacationDetails.vacation_highlight" >

                <p> <img [src]="item.image"width="25px"> <span class="px-2 mt-2">{{item.name}}</span> </p>

              </ng-container>
<!--                <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>-->
              </div>
            </div><!-- End Service Item-->

            <div class="col-lg-4 col-md-6 ">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-chat-square-text"></i>
                </div>
                <h3>Special Notes</h3>
                <p class="bold">{{vacationDetails.special_notes}}</p>
                <!-- <p class="bold">  <span> ENJOY </span> the Rockaway Bay Water Park, with 26 water slides.</p>
                <p>  <span> INDULGE </span>  in one of the 16 dining experiences on the resort</p> -->
              </div>
            </div><!-- End Service Item -->

            <div class="col-lg-4 col-md-6 ">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-info-square"></i>
                </div>
                <h3>Information</h3>
                <p class="bold">  <span> Check in : </span> {{vacationDetails.check_in}} </p>
                <p class="bold">  <span> Check out : </span> {{vacationDetails.check_out}}</p>
                <p>  <span> Room Number </span>  {{vacationDetails.number_of_rooms}} </p>
              </div>
            </div><!-- End Service Item-->

          </div>

        </div>
      </section>
      <!-- End Our Services Section -->

      <!-- ======= Amenities Section ======= -->

        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="content  mb-4">
                <h3> Amenities </h3>
                <p class="my-4 handel-pAmenities">Hotel amenities cater to guests' every need, providing relaxation and convenience.</p>
              </div>
              <mat-accordion class="example-headers-align" multi="true">

                <mat-expansion-panel showToggle="true">
                  <!-- *ngFor="let ami of aminities"  -->
                  <mat-expansion-panel-header *ngFor="let item of aminities|keyvalue"  >
                    {{item.key}}
                  </mat-expansion-panel-header>
                  <div class="row handel-amenities" *ngFor="let item of aminities|keyvalue" >
                    <div class="col-6" *ngFor="let ami of item.value">
                      <p>- {{ami.amenity}}</p>
                    </div>
                  </div >
                </mat-expansion-panel>

              </mat-accordion>
            </div>
            <div class="col-6">
              <app-google-map [longitude]="long" [Latitude]="lat"></app-google-map>
            </div>
          </div>

        </div>

      <!-- End Amenities  Section -->

      <div class="section__img-box">
      <app-hotel-slider [images]="carouselImages"></app-hotel-slider>
      </div>
    </section>

  </div>
</div>


<div class="hotel-room">
  <app-hotel-room></app-hotel-room>
</div>

<app-villas></app-villas>

<app-footer></app-footer>


