import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-flights-airlines-logos',
  templateUrl: './flights-airlines-logos.component.html',
  styleUrls: ['./flights-airlines-logos.component.css']
})
export class FlightsAirlinesLogosComponent implements OnInit {
  @Input() airLines: any;
  @Output() bagging = new EventEmitter();
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay:true,
    autoplayTimeout:3000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class=" icon-arr fas fa-arrow-left"></i>' , '<i class=" icon-arr fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },

  };

  constructor(private router: Router) {
  }

  ngOnInit(): void {


  }
 

}
