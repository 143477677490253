<!-- <div class="custom-container">
  <div class="col-12">
    <div class="row align-items-center justify-content-between align-items-center">
      <span>Attention please only 10 minutes left</span>
      <div class="btn-groups">
        <button class="btn button-light" [mat-dialog-close]="true">Ok</button>
      </div>
    </div>
  </div>
</div> -->
<div class="custom-container ">
  <div class="col-12 d-md-flex">
    <div class="d-grid gap-2 d-md-flex justify-content-md-end ">
      <div class="right"></div>
      <span class="btn btn-outline-warning but-ok"><strong>!</strong></span>
    </div>
    <div class="row align-items-center justify-content-between align-items-center mt-3 d-flex">
      <strong>Attention please only 10 minutes left</strong>
      <p class="fw-lighter mt-1">Attention please only 10 minutes left</p>
    </div>
    <div class="btn-x">
      <button type="submit" class="btn-close" [mat-dialog-close]="true"></button>
    </div>
  </div>
</div>


