import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  firstName = '';
  lastName: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  successMessage: string = '';
  errorMessage: string = '';
  typePassword: string = 'password';
  signupSpinner: boolean = false;
  // public user: any = SocialUser;
  country: string;
  countries: any;

  constructor(
    private router: Router,
    // private SocialAuthService: AuthService,
    private apis: ApisService,
    private cookie: CookieService
  ) {
    // countries name and code
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    }, err => {
    });
  }

  ngOnInit() {
  }

  // sign up
  submitSignUp() {
    if (!this.firstName || !this.lastName || !this.email || !this.password || !this.confirmPassword) {

      this.successMessage = '';
      this.errorMessage = 'All Fields are Required!';

    } else {
      if (this.email.indexOf('@') == -1 || this.email.indexOf('.') == -1) {

        this.successMessage = '';
        this.errorMessage = 'Type Real email!';
      } else {
        if (this.firstName.length < 2 || this.lastName.length < 2) {

          this.successMessage = '';
          this.errorMessage = 'Firstname & Lastname are more than One Character';
        } else {
          if (this.password.length < 4) {

            this.successMessage = '';
            this.errorMessage = 'Password is more than 3 Characters';

          } else {
            if (this.password != this.confirmPassword) {

              this.successMessage = '';
              this.errorMessage = 'Password & confirm Password are not identical';

            } else {
              // CREATE ACCOUNT
              this.signupSpinner = true;
              this.apis.createAccount(this.email, this.password, this.confirmPassword, this.firstName, this.lastName, this.country).subscribe((data: any) => {

                if (data.status == true) {

                  this.apis.signIn(data.data.user.email, this.password).subscribe((data: any) => {

                    if (data.status == true) {
                      this.cookie.set('flyallover_token', data.data.access_token);
                      setTimeout(() => {
                        this.signupSpinner = false;
                        this.errorMessage = '';
                        this.successMessage = data.data.message;

                        setTimeout(() => {
                          this.router.navigate(['/']);
                        }, 500);
                      }, 1500);
                    }
                  }, (err: any) => {
                  });
                }

              }, (err: any) => {
                this.successMessage = '';

                if (err.error.error.error.email[0]) {
                  this.errorMessage = err.error.error.error.email[0];
                } else {
                  this.errorMessage = 'Something went wrong!';
                }
              });

            }
          }
        }
      }
    }
  }

  // show hide password
  showHidePassword() {
    if (this.typePassword == 'password') {
      this.typePassword = 'text';
    } else if (this.typePassword == 'text') {
      this.typePassword = 'password';
    }
  }

}
