import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {

  currentYear = (new Date()).getFullYear();
  selectedItem: string;

  constructor(
    private router: ActivatedRoute,
    private seoService: SEOService) {
    this.seoUpdate();
  }

  ngOnInit() {
    this.selectedItem = this.router.snapshot.paramMap.get('tab');
  }

  listClick(event, newValue) {
    this.selectedItem = newValue;
  }

  seoUpdate() {

    this.seoService.updateTitle('Authorize | Flyallover');
    this.seoService.updateDescription('Authorize | Flyallover');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
