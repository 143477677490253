import {Component, HostListener, Inject, PLATFORM_ID, OnInit} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {isPlatformBrowser} from '@angular/common';
import {HotelsApisService} from 'src/app/services/hotels-apis.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {AppComponent} from 'src/app/app.component';
import {WindowService} from 'src/app/services/webApis/window.service';
import {SEOService} from '../../services/seo.service';
import {Clipboard} from "@angular/cdk/clipboard";
@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit {
  // before rendering variables
  resultsArrived = false;
  loading = true;
  hotelCode: string;
  searchData: any;
  totalGuests: number;
  copied: boolean =false;
  // response variables
  hotelDetails: any;
  rate: number;
  adults: number;
  pricesInfo: any;
  hotelImages: any[]=[];
  facilities: string[];
  description: string[];
  cancellationPolicy: string[];
  services: string[];
  petsState: string;
  checkInTime: string;
  checkOutTime: string;

  selectedRoomPriceInfo: any; // when a user selects a room, its data is stored in this variable
  noRoom = false;

  moreDescription = false;
  moreFacilities = false;

  page = 1;
  pageSize = 8;
  minDate = new Date(1990, 0, 1);
  maxDate = new Date(2020, 0, 1);
  public innerWidth: any;
  public contentHeight;

  galleryImages: GalleryItem[];
  spinner = false;
  degrees = [
    {name: 'Cleanlines', degree: '7.2'},
    {name: 'Comfort', degree: '5.5'},
    {name: 'Facilities', degree: '8.1'},
    {name: 'Location', degree: '3.9'},
    {name: 'Stuff', degree: '9.2'},
    {name: 'Wifi', degree: '6.2'},
  ];
  defaultDate: Date = new Date();
  today: Date = new Date();

  // this is for checking if the host is server or browser
  isBrowser;

  // this is for search popup
  close_status = true;
  hostUrl: string;
  routerUrl: string;
  linkToBeShared: string;

  // Dom in typescript without javascript or jQuery
  constructor(
    private seoService: SEOService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    @Inject(DOCUMENT) private document: Document,
    private hotelApis: HotelsApisService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private metaService: MetaTagsService,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) platformId: Object,
    private windowService: WindowService,
    private clipboard:Clipboard
  ) {
    this.seoUpdate();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.routerUrl = this.router.url;
    this.linkToBeShared = `${hostUrl}${this.routerUrl}`;
    this.defaultDate.setDate(this.today.getDate() + 2);
    // getting hotelCode from URL
    this.hotelCode = this.route.snapshot.paramMap.get('hotelId');
    // getting the meta data for this page
    this.hotelMetaData(this.hotelCode);
    // building the object that we will use to call hotelDetails()
    this.searchData = JSON.parse(this.localStorage.getItem('hotelSearchData')) || {};
    this.adults = this.searchData?.adults;
    const hotelQuery = {
      adults: Number(this.searchData?.adults) || 1,
      children: Number(this.searchData?.children)|| 0,
      start_date: this.searchData?.start_date || this.datePipe.transform(this.today, 'yyyy-MM-dd'),
      end_date: this.searchData?.end_date || this.datePipe.transform(this.defaultDate, 'yyyy-MM-dd'),
      hotel_code: this.hotelCode,
    };
    if (hotelQuery.children > 0) {
      hotelQuery['children_ages'] = this.searchData?.children_ages
    }

    if (this.isBrowser) {
      this.hotelApis.hotelDetails(hotelQuery).subscribe((data: any) => {
        this.hotelDetails = data.data.basis_info;



        this.rate = parseInt(this.hotelDetails.Rate);
        this.pricesInfo = this.hotelDetails.HotelPriceInfo.ConvertedRateInfo || this.hotelDetails.HotelPriceInfo.RateInfo;
        if (this.hotelDetails.HotelImageInfo) {
          this.hotelImages = this.hotelDetails.HotelImageInfo.image;
        }
        this.galleryMethod();
        this.loading = false;
        this.resultsArrived = true;
        this.hotelDetails.Descriptions.Description.forEach(el => {
          if (el.Type === 'ShortDescription') {
            this.description = el.value;
          } else if (el.Type === 'Facilities') {
            this.facilities = el.value;
          } else if (el.Type === 'CancellationPolicy') {
            this.cancellationPolicy = el.value;
          } else if (el.Type === 'Services') {
            this.services = el.value;
          }
        });
        this.checkTimeFormat();
        this.searchForPets();
        if (this.document.getElementById('hotelsPage') && this.document.getElementById('benefit')) {
          setTimeout(() => {
            this.contentHeight = this.document.getElementById('hotelsPage').clientHeight
              + this.document.getElementById('benefit').clientHeight;
          }, 1000);
        }
      }, err => {
        this.loading = false;
        this.resultsArrived = false;
      });
    }
    this.innerWidth = this.windowService.nativeWindow.innerWidth;
    this.totalGuests = +Number(this.searchData?.adults) ;
    this.totalGuests += +Number(this.searchData?.children);
        //  console.log(this.totalGuests);

  }

  seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {

  if (title != null) {
      this.seoService.updateTitle(title);
  }

  if (description != null) {
      this.seoService.updateDescription(description);
  }

  this.seoService.updateUrl();

  if (image != null) {
      this.seoService.updateImage(this.hotelImages[0][0].Url);
  }
}

  openGallery(e, i) {
    if (i === 4) {
      e.srcElement.childNodes[0]?.click();
    }
  }

  galleryMethod() {
      if (this.hotelDetails.HotelImageInfo) {
      this.galleryImages = this.hotelImages.map(item => new ImageItem({src: item.Url, thumb: item.Url}));
      const lightboxRef = this.gallery.ref('lightbox');
      lightboxRef.setConfig({
        imageSize: ImageSize.Cover,
        thumbPosition: ThumbnailsPosition.Bottom
      });
      lightboxRef.load(this.galleryImages);
    }
  }

  searchForPets() {
    let amenitiesArray = this.hotelDetails.Amenities.Amenity;
    for (let i = 0; i < amenitiesArray.length; i++) {
      const amenity = amenitiesArray[i].Description;
      if (amenity.includes('Pet') || amenity.includes('Pets') || amenity.includes('pet')) {
        this.petsState = amenity;
        break;
      }
    }
  }

  selectRoom(event, i: number) {
    const selectButtons = this.document.querySelectorAll('.select button');
    selectButtons.forEach(button => {
      button.classList.remove('selected');
      button.innerHTML = 'SELECT';
    });
    event.target.classList.toggle('selected');
    event.target.innerHTML === 'SELECT' ? event.target.innerHTML = 'SELECTED' : event.target.innerHTML = 'SELECT'

    // the user selected the room
    if (event.target.innerHTML === 'SELECTED') {
      this.selectedRoomPriceInfo = this.pricesInfo[i];
      this.noRoom = false;
    }
    this.reserveRoom();
  }

  reserveRoom() {
    // if the user clicked reserve button without selecting a room, show error message
    if (!this.selectedRoomPriceInfo) {
      this.noRoom = true;
    } else {
      this.spinner = true;
      if (this.hotelImages) {
        this.localStorage.setItem('hotelImgUrl', this.hotelImages[0][0].Url)
      } else {
        this.localStorage.setItem('hotelImgUrl', '../../../assets/images/hotel-img.png')
      }
      this.router.navigate(['hotel-payment', this.selectedRoomPriceInfo.RateKey]);
    }
  }

  // this method is for checking on the time format if it's 14:00 or it is 14 of the
  // checkInTime and checkOutTime
  checkTimeFormat() {
    if (this.hotelDetails.PropertyInfo.Policies.Policy[1].Text.value.includes(':')) {
      this.checkInTime = this.hotelDetails.PropertyInfo.Policies.Policy[1].Text.value
    } else {
      this.checkInTime = this.timeFormat(this.hotelDetails.PropertyInfo.Policies.Policy[1].Text.value);
    }
    if (this.hotelDetails.PropertyInfo.Policies.Policy[0].Text.value.includes(':')) {
      this.checkOutTime = this.hotelDetails.PropertyInfo.Policies.Policy[0].Text.value
    } else {
      this.checkOutTime = this.timeFormat(this.hotelDetails.PropertyInfo.Policies.Policy[0].Text.value);
    }
  }

  // time is in 24 hour system as '1200', so we split it to hrs and mins
  // then format it to 12 hour system
  timeFormat(time) {
    let hour = time.slice(0, 2);
    let min = time.slice(2, 4);
    let part = hour >= 12 ? 'pm' : 'am';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = hour == 0 ? hour = 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  hotelMetaData(hotelCode) {
    this.hotelApis.hotelDetailsMetaData(hotelCode).subscribe((res: any) => {
      let response = res?.data?.meta_data?.data;
      this.title.setTitle(response.title);
      this.modifyMetaTags(response);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = this.windowService.nativeWindow.innerWidth;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.document.getElementById('benefit') && this.innerWidth >= 992) {
      if (this.document.documentElement.scrollTop > 100 && this.document.documentElement.scrollTop < this.contentHeight) {
        this.document.getElementById('benefit').classList.add('top');
      } else {
        this.document.getElementById('benefit').classList.remove('top');
      }
    }
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  modifyMetaTags(metaData) {
    this.metaService.modifyMetaTags(metaData);
  }
  hotelSearchAPI(searchData = this.searchData) {
    this.resultsArrived = false;
    this.loading = true;
    // this.disableSearch();
    this.searchData = searchData;
    this.resultsArrived = false;
    this.hotelApis.searchHotels(searchData).subscribe((data: any) => {
      this.resultsArrived = true;
      this.loading = false;
      this.localStorage.setItem('hotelSearchData', JSON.stringify(this.searchData));
      // searchData.SearchFromComponent is true when search is coming from hotelSearchComponent
      if (this.searchData.SearchFromComponent) {
        delete this.searchData.SearchFromComponent;
        // to update hotelSearchData in local storage with the new searchData
        // this.minValue = 0;
      }
      // this.assignDataToVars(data.data);
      // this.enableSearch();
    }, err => {
      if (this.isBrowser) {
        this.resultsArrived = false;
        this.loading = false;
        this.router.navigate(['/no-result']);
      }
    });
  }
  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied=!this.copied;
    setTimeout(()=>{
        this.copied = false;
    },1500)
}

fb() {
    let url = "www.google.com";

    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "flyallover.com" +
        this.router.url,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
        facebookWindow.focus();
    }

    return false;
}

tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
        Window.focus();
    }
}

in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
        Window.focus();
    }
}
}
