<app-navbar></app-navbar>
<div *ngIf="visaData" class="img-container" [ngStyle]="{ 'background-image': 'url(' + coverImage + ')' }">
  <h1 class="health">Health Requirements</h1>
</div>
<ngx-skeleton-loader *ngIf="loading" count="1" [theme]="{
    height: '480px',
    'vertical-align': 'bottom',
    width: '100%',
    'margin-bottom': '0px'
  }">
</ngx-skeleton-loader>
<div class="container" *ngIf="visaData">
  <div class="row">
    <div class="visa">
      <p class="head-line">{{ visaData.VisaMessage }}</p>
      <div class="head-line" *ngIf="!visaData">
        <div class="mx-md-3 d-md-inline d-block top-result">
          <ngx-skeleton-loader count="1" [theme]="{
              height: '50px',
              'vertical-align': 'bottom',
              width: '50%',
              'margin-bottom': '0px'
            }">
          </ngx-skeleton-loader>
        </div>
      </div>
      <h3>Start Your Visa Request Now.</h3>
      <div class="visaDetails">
        <div *ngIf="requestData && requestData.input" class="visaSearchDetails">
          <p>
            {{ requestData.input.destination }} :
            {{ requestData.input.nationality }} Citizen ,
            {{ requestData.input.residency }} , {{ requestData.input.purpose }}
          </p>
          <p class="link" routerLink="/visa">Edit</p>
        </div>
        <a *ngIf="visaData" target="_blank" href="{{ visaData?.HealthEntryData?.landingurl }}" class="visa-btn">Get My Visa</a>
        <ngx-skeleton-loader *ngIf="!visaData" count="1" [theme]="{
            height: '40px',
            'vertical-align': 'bottom',
            width: '30rem',
            'margin-bottom': '0px'
          }">
        </ngx-skeleton-loader>
      </div>
    </div>
    <p class="line">
      Flyallover Provides Essential,Accurate And Timely Guidance For Entry,
      Curated From Sources Around The World And Updated Daily.
    </p>
    <div class="requirements col-md-12">
      <div class="row">
        <ng-container *ngFor="let item of visaData.HealthEntryData.healthentry">
          <div class="col-md-6">
            <div class="requirement">
              <h2 class="requirementTitle">{{item.header}}</h2>
              <p [innerHTML]="item.content"></p>
            </div>
          </div>
        </ng-container>
        <div class="col-md-12">
          <div class="btns">
            <a target="_blank" href="{{ visaData?.HealthEntryData?.landingurl }}" class="visa-btn">Get My Visa</a>
            <div *ngIf="!visaData" class="mx-md-3 d-md-inline d-block top-result">
              <ngx-skeleton-loader count="1" [theme]="{
                  height: '30px',
                  'vertical-align': 'bottom',
                  width: '100%',
                  'margin-bottom': '0px'
                }">
              </ngx-skeleton-loader>
            </div>
            <a class="share-btn" rel="nofollow" href="https://www.facebook.com/sharer/sharer.php?u={{
                linkToBeShared
              }}" target="_blank">
              Share
            </a>
            <div *ngIf="!visaData" class="mx-md-3 d-md-inline d-block top-result">
              <ngx-skeleton-loader count="1" [theme]="{
                  height: '30px',
                  'vertical-align': 'bottom',
                  width: '100%',
                  'margin-bottom': '0px'
                }">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>