import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-flight-results-search-data',
  templateUrl: './flight-results-search-data.component.html',
  styleUrls: ['./flight-results-search-data.component.css']
})
export class FlightResultsSearchDataComponent implements OnInit {

  searchRequest: any;
  hideSearch = false;
  @Output() applyModifiedSearchEvent = new EventEmitter();
  @Output() modifiedSearchRequestEvent = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.dataHandler();
  }

  applyModificationEvent(searchRequest): void {
    this.searchRequest = searchRequest;
    this.modifiedSearchRequestEvent.emit(this.searchRequest);
  }
  dataHandler(): void {
    this.searchRequest = this.route.snapshot.queryParams;
  }

}

