import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
// import * as Plyr from 'plyr';
// tslint:disable-next-line:prefer-const
// @ts-ignore
// let Plyr = require("plyr");

// import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverUpdateSourceParams, PlyrDriverDestroyParams } from './plyr-driver';
@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.css']
})

export class ShowVideoComponent implements OnInit {
  name = 'Angular';
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  isPlay = false;

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  constructor() { }
  ngOnInit(): void {
  }

  restart() {
    const video: any = document.getElementById('my_video_1');
    video.currentTime = 0;
  }


}
