import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApisService } from '../../../services/apis.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-travel-card',
  templateUrl: './travel-card.component.html',
  styleUrls: ['./travel-card.component.css']
})
export class TravelCardComponent implements OnInit {
  @Input() travelerData
  @Input() countries
  @Output() reloadFamilyMember = new EventEmitter();
  @Input() disableForm = true;
  @Input() formType = 'edit';
  token = localStorage.getItem("flyallover_token")
  getFamliyMember: FormGroup;
  setFamliyMember: any = {
    familyMemberId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    birthOfMonth: '',
    birthOfDay: '',
    birthOfYear: '',
    gender: '',
    passport_number: '',
    expiryOfMonth: '',
    expiryOfDay: '',
    expiryOfYear: '',
    issueOfMonth: '',
    issueOfDay: '',
    issueOfYear: '',
    issuedCountry: '',
    issue_date: '',
    expiry_date: '',
    nationality_id: '',
    nationality_code: '',
    country_code: '',
    countryPassport: '',
    nationality: '',
  }
  testFR = 'ali naser'
  months: any = [
    { text: 'January', num: '01' },
    { text: 'February', num: '02' },
    { text: 'March', num: '03' },
    { text: 'April', num: '04' },
    { text: 'May', num: '05' },
    { text: 'June', num: '06' },
    { text: 'July', num: '07' },
    { text: 'August', num: '08' },
    { text: 'September', num: '09' },
    { text: 'October', num: '10' },
    { text: 'November', num: '11' },
    { text: 'December', num: '12' }
  ];
  set_birth_date: string;
  set_expiry_date: string;
  set_issue_date: string;

  constructor(private apis: ApisService, private formBuilder: FormBuilder) {
  }



  ngOnInit(): void {

    console.log('travelerData', this.travelerData);
    console.log('countries', this.countries);

    this.getFamliyMember = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      gender: [''],
      birthData: this.formBuilder.group({
        birthOfYear: [''],
        birthOfMonth: [''],
        birthOfDay: [''],
      }),
      passport_number: [''],
      nationality_id: [''],
      nationality_code: [''],
      country_code: [''],
      countryPassport: [''],
      nationality: [''],
      issuedCountry: [''],
      traveller_gender: [''],
      phone_type: [''],
      PassengerType: [''],
      expiry_date: this.formBuilder.group({
        expiryOfYear: [''],
        expiryOfMonth: [''],
        expiryOfDay: [''],
      }),
      issue_date: this.formBuilder.group({
        issueOfYear: [''],
        issueOfMonth: [''],
        issueOfDay: [''],
      }),
    });
    if (this.formType === 'edit') {
      this.setFamilyMember()
      this.getFamliyMember.disable()
    }
  }

  setFamilyMember() {
    const member = this.travelerData
    this.setFamliyMember.familyMemberId = member.id;
    this.setFamliyMember.firstName = member.first_name;
    this.setFamliyMember.middleName = member.middle_name;
    this.setFamliyMember.lastName = member.last_name;
this.set_birth_date = member.date_of_birth;
    this.setFamliyMember.birthOfMonth = member.date_of_birth.split('-')[1];
    this.setFamliyMember.birthOfDay = member.date_of_birth.split('-')[2];
    this.setFamliyMember.birthOfYear = member.date_of_birth.split('-')[0];
    this.setFamliyMember.gender = member.gender;
    this.setFamliyMember.passport_number = member.passport?.passport_number;
    this.set_expiry_date = member.passport?.expire_date;
    this.setFamliyMember.expiryOfMonth = member.passport?.expire_date.split('-')[1];
    this.setFamliyMember.expiryOfDay = member.passport?.expire_date.split('-')[2];
    this.setFamliyMember.expiryOfYear = member.passport?.expire_date.split('-')[0];
    this.set_issue_date = member.passport?.issue_date;
    this.setFamliyMember.issueOfMonth = member.passport?.issue_date.split('-')[1];
    this.setFamliyMember.issueOfDay = member.passport?.issue_date.split('-')[2];
    this.setFamliyMember.issueOfYear = member.passport?.issue_date.split('-')[0];
    this.setFamliyMember.issuedCountry = member.passport?.issued_country.id;
    this.setFamliyMember.issue_date = member.passport?.issue_date;
    this.setFamliyMember.expiry_date = member.passport?.expire_date;
    this.setFamliyMember.nationality_id = member.nationality.id;
    this.setFamliyMember.nationality_code = member.nationality.code;
    this.setFamliyMember.country_code = member.passport?.issued_country.code;
    this.setFamliyMember.countryPassport = member.passport?.issued_country.code;
    this.setFamliyMember.nationality = member.passport?.issued_country.cod;
    this.getFamliyMember.patchValue({
      gender: member.gender
    })
  }
  getCountryObject(country): any {
    const index = this.countries.findIndex((obj) => obj.id === country);
    return this.countries[index];

  }
  initRequest(): any {
    const formData: FormData = new FormData();
    // formData.append("image", this.fileToUpload);
    formData.append("first_name", this.getFamliyMember.value["firstName"]);
    formData.append("middle_name", this.getFamliyMember.value["middleName"]);
    formData.append("last_name", this.getFamliyMember.value["lastName"]);
    formData.append( "country_id",  this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.id);
    formData.append( "nationality_id", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.id);
    formData.append("gender", this.getFamliyMember.value.gender);
    formData.append("date_of_birth", this.set_birth_date);
    console.log(`formData()`, formData);

    return formData;
  }
  convertDate(d) {
    const parts = d.split(" ");
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
  }
  checkDate(formGroupName) {
    if (formGroupName === undefined) {
      return
    }
    switch (formGroupName) {
      case "travellerDob": {
        const formRes = this.getFamliyMember.value.birthData;
        // const { formRes.birthOfYear, formRes.birthOfMonth, formRes.birthOfDay } = formGroupName;
        if (formRes.birthOfYear && formRes.birthOfMonth && formRes.birthOfDay) {
          this.set_birth_date = `${formRes.birthOfYear}-${formRes.birthOfMonth}-${formRes.birthOfDay.toString().padStart(2, '0')}`
        }

        break;
      }
      case "expireDate": {
        const formRes = this.getFamliyMember.value.expiry_date;
        if (formRes.expiryOfYear && formRes.expiryOfMonth && formRes.expiryOfDay) {
          this.set_expiry_date = `${formRes.expiryOfYear}-${formRes.expiryOfMonth}-${formRes.expiryOfDay.toString().padStart(2, '0')}`
        }
        break;
      }
      case "issueDate": {
        const formRes = this.getFamliyMember.value.issue_date;
        if (formRes.issueOfYear && formRes.issueOfMonth && formRes.issueOfDay) {
          this.set_issue_date = `${formRes.issueOfYear}-${formRes.issueOfMonth}-${formRes.issueOfDay.toString().padStart(2, '0')}`;
        }
        break;
      }
      default: {
        break;
      }
    }


  }
  editMember() {
    // this.loadingSpinner = true;
    this.apis
      .editFamilyMember(this.initRequest(), this.travelerData.id, this.token)
      .subscribe(
        (data: any) => {
          this.editPassport()
        },
        (err) => {
          console.log(err);
          // this.loadingSpinner = false;
        }
      );
  }

  editPassport() {
    const passportId = this.travelerData.passport?.id
    const passportData = {
      family_member_id: this.travelerData.id,
      passport_number: this.travelerData.passport?.passport_number,
      issue_date: this.set_issue_date,
      expire_date: this.set_expiry_date,
      country_id: this.travelerData.country.id,
      nationality_id: this.travelerData.passport?.nationality_id,
      issued_country_id: this.travelerData.passport?.issued_country_id
    }
    this.apis.editFamilyMemberPassportUpdate(passportId, passportData).subscribe((data: any) => {
      this.reloadFamilyMember.emit();

    },
      err => {
        // this.loadingSpinner = false;
        console.log(err);
      });
  }

  editForm() {
    this.disableForm = false
    this.getFamliyMember.enable();
  }

  sevaDataChange() {


  }

  close() {
    this.disableForm = true
    this.getFamliyMember.disable();
  }
  deleteTraveler() {
    this.apis.deleteFamilyMember(this.travelerData.id, this.token).subscribe({
      next: (success: any) => {
        this.reloadFamilyMember.emit();      }
    })
  }
  submit() {
    if (!this.disableForm) {
      this.disableForm = !this.disableForm
      this.getFamliyMember.enable();
      this.editMember()
      this.editPassport()
    }
  }

}
