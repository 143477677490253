import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-search-engine-flight',
  templateUrl: './search-engine-flight.component.html',
  styleUrls: ['./search-engine-flight.component.css']
})
export class SearchEngineFlightComponent implements OnInit {
  @Input() inPage = false
  tripType: any;
  classType: any;
  travellers: any;
  tripsTypeObj: any;
  classTypeObj: any;
  travellersObj: any;
  trevallerValue: string;
  flightData: any;
  stopsObj: any;
  stopValue: any;
  localData: any;
  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.setValues();
    this.getTrevallerValues();
  }
  setValues() {
    this.tripsTypeObj = [
      { name: 'Round Trip', code: 'Return' },
      { name: 'One Way', code: 'OneWay' },
      { name: 'Multi City', code: 'OpenJaw' },
    ];
    this.classTypeObj = [
      { name: 'Economy', code: 'Economy' },
      { name: 'Premium Economy', code: 'Premium economy' },
      { name: 'First', code: 'First' },
      { name: 'Business', code: 'Business' },
    ];
    this.stopsObj = [
      { name: 'Direct', code: '0' },
      { name: '1 stop', code: '1' },
      // { name: '+1 stops', code: null },
      { name: 'All Flights', code: null },
    ];
    this.stopValue = { name: 'All Flights', code: null };
    this.travellersObj =
    {
      adult: {
        id: 'adult',
        name: 'Adults',
        initialValue: 1,
        currentValue: 1,
        minValue: 1,
        maxValue: 12,
      },
      child: {
        id: 'child',
        name: 'Children',
        ageFrom: 2,
        ageTo: 12,
        initialValue: 0,
        currentValue: 0,
        minValue: 0,
        maxValue: 12,
      },
      lap: {
        id: 'lap',
        name: 'Lap',
        ageFrom: 2,
        ageTo: 12,
        initialValue: 0,
        currentValue: 0,
        minValue: 0,
        maxValue: 12,
      },
      seat: {
        id: 'seat',
        name: 'Seat',
        ageFrom: 2,
        ageTo: 12,
        initialValue: 0,
        currentValue: 0,
        minValue: 0,
        maxValue: 12,
      },
    };
    if (localStorage.getItem('flightSearch')) {
      this.getDataFromLocal()
    }
    this.tripType = { name: 'Round Trip', code: 'Return' };
    this.classType = { name: 'Economy', code: 'economy' };
  }
  selectedTripType(item: any) {
    this.tripType = item
  }
  selectedClassType(item: any) {
    this.classType = item
  }
  selectedStops(item: any) {
    this.stopValue = item
  }
  getTrevallerValues() {
    const numAdult = +this.travellersObj.adult.currentValue
    const numChild = +this.travellersObj.child.currentValue
    const numLap = +this.travellersObj.lap.currentValue
    const numSeat = +this.travellersObj.seat.currentValue
    this.travellers = numAdult + numChild + numLap + numSeat
    const name = this.travellers == 1 ? 'adult' : 'travellers';
    this.trevallerValue = `${this.travellers} ${name}`;
  }

  inc(traveller) {
    const num = +this.travellersObj[traveller].currentValue;
    if (this.travellersObj[traveller].minValue < num) {

      this.travellersObj[traveller].currentValue = num - 1
    } else {
      // this.errorMessage = "لقد وصلت الي الحد الادني من التحديد"
    }
    this.getTrevallerValues()
  }
  plus(traveller) {
    const num = +this.travellersObj[traveller].currentValue;
    if (this.travellersObj[traveller].maxValue > num) {

      this.travellersObj[traveller].currentValue = num + 1
    } else {
      // this.errorMessage = "لقد وصلت الي الحد الاقصي من التحديد"
    }
    this.getTrevallerValues()
  }
  getFlighData(flightData: any) {
    this.flightData = flightData
    localStorage.setItem('flightSearch', JSON.stringify(this.initRequest()));
    if (this.inPage) {
      this.router.navigate(['/flights'], {
        relativeTo: this.route,
        queryParams: this.initRequest()
      });
      // window.location.reload()
    } else {
      this.router.navigate(['flights'], { queryParams: this.initRequest() });

    }
  }
  initRequest() {
    return {
      fullOrigin: this.flightData?.origin,
      fullDestination: this.flightData?.destenation,
      trip_type: this.tripType.code,
      departure_date: this.datePipe.transform(this.flightData?.Depart, 'yyyy-MM-dd'),
      return_date: this.datePipe.transform(this.flightData?.Return, 'yyyy-MM-dd'),
      origin: this.flightData?.origin.slice(0, 3),
      destination: this.flightData?.destenation.slice(0, 3),
      class: this.classType.code,
      travellers: this.travellers,
      adult: this.travellersObj.adult.currentValue,
      child: this.travellersObj.child.currentValue,
      lap: this.travellersObj.lap.currentValue,
      seat: this.travellersObj.seat.currentValue,
      stops: this.stopValue.code,
      // airlines: this.inputCodeAirLine,
      offers: true,
      payLater: true
    };
    switch (this.tripType.code) {
      case 'Return':
        break
    }
  }
  findAndSetData() {
    this.tripType = this.tripsTypeObj.find((item) => item.code == this.localData?.trip_type)
    this.classType = this.classTypeObj.find((item) => item.code == this.localData?.class)
    this.stopValue = this.stopsObj.find((item) => item.code == this.localData?.stops)
  }
  getDataFromLocal() {

    this.localData = JSON.parse(localStorage.getItem('flightSearch'))
    this.travellersObj.adult.currentValue = this.localData.adult
    this.travellersObj.child.currentValue = this.localData.child
    this.travellersObj.lap.currentValue = this.localData.lap
    this.travellersObj.seat.currentValue = this.localData.seat
    this.findAndSetData()
  }

}
