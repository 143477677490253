<ng-container>
  <div
    *ngFor="let itinerary of itinerariesFackData; let i = index"
    class="itinerary"
    [ngClass]="{ 'first-result': i == 0 }"
  >
    <div class="row">
      <div *ngFor="let trip of itinerary.flights; let ii = index" class="list">
        <div class="content col-12">
          <div class="content-img col-3">
            <div class="text-center">
              <img
                class="img"
                src="https://c.fareportal.com/n/common/air/3x/{{
                  trip[val]?.OperatingAirline
                }}.png"
                alt=""
              />
              <small style="display: block">{{
                trip[val]?.OperatingAirlineName
              }}</small>
            </div>
          </div>
          <div class="content-center col-6">
            <div>
              <strong>{{
                trip[0]?.DepartureDateTime | date : "h:mm a"
              }}</strong>
              <small style="display: block">{{
                trip[0]?.DepartureDateTime | date : "EEE , MMM d"
              }}</small>
              <p>{{ trip[0]?.DepartureAirport }}</p>
            </div>
            <div>
              <strong
                style="font-weight: bold"
                *ngIf="trip[1]?.FlightLayoverTime"
                >{{ convertTime(trip[1]?.FlightLayoverTime)
                }}<span *ngIf="trip?.length - 1 == 2" class="mx-1">/</span>
                <span *ngIf="trip?.length - 1 == 2">{{
                  convertTime(trip[2]?.FlightLayoverTime)
                }}</span></strong
              >
              <small
                style="display: block; margin: 6px 0"
                *ngIf="trip[1]?.FlightLayoverTime"
                >layover time</small
              >
              <div class="text-stop">
                <p *ngIf="trip?.length - 1 == 0">Non stop</p>
                <p
                  *ngIf="trip?.length - 1 > 0"
                  (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')"
                >
                  {{ trip?.length - 1 }} stop
                </p>
                <div
                  class="dialog-stop"
                  #dialog
                  (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')"
                >
                  <div class="arrow-up"></div>
                  <span class="border-stop" *ngIf="trip?.length - 1 > 0">
                    <small class="total-trip"
                      ><span style="color: #000; font-weight: bold"
                        >Total Duration :
                      </span>
                      {{
                        convertTime(
                          itinerary.flights[ii][1]?.FlightLayoverTime +
                            itinerary.flights[ii][1]?.ElapsedTime +
                            itinerary.flights[ii][0]?.ElapsedTime
                        )
                      }}</small
                    >
                    <div style="padding-left: 14px">
                      <div class="d-flex">
                        <p class="stops-title white-space-no-wrap mr-1 font">
                          Stop 1 :
                        </p>
                        <span class="font white-space-no-wrap">
                          <span
                            class="mx-1"
                            style="color: #161d4c; font-weight: 600"
                            >{{ convertTime(trip[1].FlightLayoverTime) }}</span
                          >
                          Layover, {{ trip[1].DepartureAirportName }}
                        </span>
                      </div>
                      <div
                        class="d-flex mt-2 position-relative"
                        *ngIf="trip?.length - 1 > 1"
                      >
                        <span class="line-dot"></span>
                        <p class="stops-title white-space-no-wrap mr-1 font">
                          Stop 2 :
                        </p>
                        <span class="font white-space-no-wrap">
                          <span
                            class="mx-1"
                            style="color: #161d4c; font-weight: 600"
                            >{{ convertTime(trip[2].FlightLayoverTime) }}</span
                          >
                          Layover, {{ trip[2].DepartureAirportName }}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <small class="total-trip"
                ><span style="color: #f00; font-weight: bold"
                  >Total Duration afs :
                </span>
                {{
                  convertTime(
                    itinerary.flights[ii][1]?.FlightLayoverTime +
                      itinerary.flights[ii][1]?.ElapsedTime +
                      itinerary.flights[ii][0]?.ElapsedTime
                  )
                }}</small
              >
            </div>
            <div>
              <strong>{{
                trip[trip?.length - 1]?.ArrivalDateTime | date : "h:mm a"
              }}</strong>
              <small style="display: block">{{
                trip[trip?.length - 1]?.ArrivalDateTime | date : "EEE , MMM d"
              }}</small>
              <p>{{ trip[trip?.length - 1]?.ArrivalAirport }}</p>
            </div>
          </div>
          <div class="content-button col-3" *ngIf="ii == 0">
            <div
              class="w-100 border-bottom d-none d-flex justify-content-between position-relative"
            >
              <ul class="d-flex align-items-center" style="margin: 10px 4px">
                <ng-container
                  *ngFor="
                    let brand of itinerary.flights[0][0].BrandFeatures
                      | keyvalue
                  "
                >
                  <li
                    *ngIf="brand.key != 'BG'"
                    class="position-relative"
                    (mouseover)="
                      arrow_brands.classList.add('shoow');
                      arrow_brands.classList.remove('hidden');
                      brands.classList.add('shoow');
                      brands.classList.remove('hidden')
                    "
                    (mouseout)="
                      arrow_brands.classList.remove('shoow');
                      arrow_brands.classList.add('hidden');
                      brands.classList.remove('shoow');
                      brands.classList.add('hidden')
                    "
                  >
                    <span *ngIf="brand.key != 'BG'">
                      <img
                        width="20"
                        style="margin-right: 4px"
                        [src]="
                          'assets/icons/brand-futures/' + brand.key + '.png'
                        "
                      />
                    </span>
                    <span #arrow_brands class="arrow-top hidden"></span>
                  </li>
                  <div class="brands-popup hidden d-none" #brands>
                    <span
                      class="d-flex justify-content-between my-2 align-items-center"
                      *ngFor="let item of brand.value; let i = index"
                    >
                      <span class="d-flex align-items-center">
                        <img
                          width="20"
                          style="margin-right: 4px"
                          [src]="
                            'assets/icons/brand-futures/' +
                            item.commercialName +
                            '.png'
                          "
                        />
                        <small class="mr-auto">{{ item.commercialName }}</small>
                      </span>
                      <small style="color: blue" *ngIf="item.application == 'C'"
                        >CHARGABLE</small
                      >
                      <small
                        style="color: green"
                        *ngIf="item.application == 'F'"
                        >FREE</small
                      >
                      <small
                        style="color: red; text-transform: uppercase"
                        *ngIf="item.application == 'D'"
                        >not offered</small
                      >
                    </span>
                  </div>
                </ng-container>
              </ul>
            </div>

            <div class="content-button col-3" *ngIf="ii == 0">
              <div
                [class]="
                  itinerary.flights[0].length == 1 ? 'top-50' : 'top-50'
                "
              >
                <p
                  [ngClass]="
                    itinerary?.BaseFare?.Amount
                      ? 'cheapest-price'
                      : 'not-cheapest-price'
                  "
                  *ngIf="searchRequest?.offers != 'false'"
                >
                  {{
                    itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount
                      | currency
                  }}
                </p>
                <button class="fao_mobile_btn_V_small" type="button">
                  Book Now</button
                ><br />
                <!-- (click)="ShareFlightDetails(itinerary.TagID);" [routerLink]="['/flight-details/',itinerary.TagID,tripType]" -->
              </div>
            </div>
          </div>
          <div class="content-button col-3" *ngIf="ii == 1">
            <p class="text-content-button">
              price per person (incl. taxes & fees)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
