<!-- <div *ngIf="loading"></div> -->
<!-- <ng-container  >
  <div class="box-img mb-3">
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
  <p class="mx-auto price-loading">Please wait we are working on getting you trip price </p>
</ng-container> -->
<app-navbar></app-navbar>
<div class="allPagePadding">
  <ng-container>
    <div class="">
      <form [formGroup]="form">
        <div class=" " *ngIf="!loading && !messageError">
          <form [formGroup]="form">
            <div class="row title">
            </div>
            <div class="d-flex">
              <div class="review-trip-section" *ngIf="dataPnr_input">
                <app-pnr-header-in-dashboard [item]="dataPnr_input"></app-pnr-header-in-dashboard>
              </div>
            </div>
            <div class="row form border1 herefounddate  review-trip-section">
              <div class="col-12">
                <h6 class=" title">Billing Details</h6>
                <p>
                  Please Enter your card details to proceed the payment process as written on your card to avoid any
                  mistakes.
                </p>
              </div>
              <div class="col-12">
                <div class="col-12 mt-2">

                </div>
                <div class="row p-2" *ngIf="paymentType=='Credit'">
                  <div class="col-6 item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Name On Card</label>
                    </div>
                    <input formControlName="cardHolderFullName" type="text" class="form-control"
                      placeholder="please Enter Name On Card" id="cardHolderFullName"
                      (ngModelChange)="validationForm('cardHolderFullName');checkCardName()" autocomplete="off" required
                      minlength="1" maxlength="50" />
                    <mat-error *ngIf="form.controls.cardHolderFullName.errors?.pattern">
                      Please Type Only Letters At least Three Names</mat-error>
                    <mat-error
                      *ngIf="form.controls.cardHolderFullName.errors?.required && form.controls.cardHolderFullName.dirty">
                      Name On Card Is Requied</mat-error>
                  </div>

                  <div class="col-6 position-relative">
                    <div class="item ">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Card Number</label>
                      </div>
                      <!--              <app-review-flight-credit-card (setPaymentCardEvent)="setPaymentCardEvent($event)"-->
                      <!--                                             [submitError]="submitError">-->
                      <!--              </app-review-flight-credit-card>-->

                      <div class="position-relative">
                        <input type="text" formControlName="card" class="num form-control"
                          placeholder="Please Enter Card Number" maxlength="16" autocomplete="off"
                          (ngModelChange)="testCreditCard();validationForm('card')" id="card" required />
                        <img *ngIf="vendorCode" class="vendor-image" alt="vendor-image" title="Vendor Image"
                          src="assets/images/hotels/{{vendorCode}}.png">
                      </div>
                      <mat-error *ngIf="form.controls.card.errors?.pattern"> Please Type Only
                        Numbers</mat-error>
                      <mat-error *ngIf="form.controls.card.errors?.pattern"> Card Number Should be 16 Number
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">cvc</label>
                      </div>
                      <input maxlength="4" formControlName="cvc" (ngModelChange)="validationForm('cvc')" type="text"
                        class="num form-control" placeholder="cvc" id="cvc" autocomplete="off" required />
                      <img (mouseover)="dialog.classList.add('hidden-dialog')"
                        (mouseout)="dialog.classList.remove('hidden-dialog')" style="top: 60%;" class="vendor-image"
                        alt="" title="Vendor Image" src="assets/images/hotels/cvc.jpg">
                    </div>
                  </div>
                  <div #dialog (mouseover)="dialog.classList.add('hidden-dialog')"
                    (mouseout)="dialog.classList.remove('hidden-dialog')" class="col-9 position-absolute dialog-cvv">
                    <div class="col-12">
                      <h1>Why do we need this?</h1>
                      <p>to Help us keep your data safe! Please provide us with your credit card verification number for
                        added security measures.</p>
                    </div>
                    <div class="col-12 d-flex">
                      <div class="col-6">
                        <img src="assets/images/hotels/visa-cvv.png" alt="">
                        <h2>The verification number for Visa, MasterCard, Discover, Diners Club and Carte Blanche</h2>
                        <p>is the 3-digit number on the back of the credit card.</p>
                      </div>
                      <div class="col-6">
                        <img src="assets/images/hotels/American-express-cvv.png" alt="">
                        <h2>The Verification number for American Express credit cards</h2>
                        <p>is the 4-digit number located on the front of the card.</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Expiration Date</label>
                      </div>
                      <div class="inputAlign" (keyup)="checkDate()">
                        <div class="col-5">
                          <input type="text" formControlName="CreditCardExpireDateMonth" placeholder="MM"
                            class="num month form-control text-center" required maxlength="2"
                            (ngModelChange)="validationForm('CreditCardExpireDateMonth')" id="CreditCardExpireDateMonth"
                            (change)="updateExpireDate()" (focusout)="format($event)" autocomplete="off" />

                        </div>
                        <div class="col-6">
                          <input type="text" formControlName="CreditCardExpireDateYear" placeholder="YYYY"
                            class="num year form-control text-center" required
                            (ngModelChange)="validationForm('CreditCardExpireDateYear')" id="CreditCardExpireDateYear"
                            (change)="updateExpireDate()" autocomplete="off" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="errors col-12 position-relative ">
                    <div class="mat-errors">
                      <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern">
                        <p>Please Type Only 3 Numbers</p>
                        <p>4 Numbers for American Express</p>
                      </div>
                      <div class="b-2" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                        Type Only Two Numbers Such As 07</div>
                      <div class="b-2" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
                      <div class="b-2" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please Type
                        Only Four Numbers Such As 2022</div>
                      <div class="b-2" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
                    </div>
                  </div>
                </div>
                <div class="p-5" *ngIf="paymentType=='Digital'">
                  <mat-radio-group >
                    <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="zelle"> <img height="65" width="150" src="./assets/icons/pament-icons/Zelle.svg" alt=""></mat-radio-button>
                    <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="plaid"> <img height="65" width="150" src="./assets/icons/pament-icons/Plaid.svg" alt=""></mat-radio-button>
                    <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="wisetransfer"> <img height="65" width="200" src="./assets/icons/pament-icons/TransferWise.svg" alt=""></mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="row form billing  review-trip-section">
              <div class="col-12 ">
                <h6 class="title">Billing Address</h6>
                <p>Your billing address is totally secure and confidential.</p>
              </div>
              <div class="col-12 mt-2">
                <div class="row">

                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Address</label>
                      </div>
                      <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                        formControlName="CreditCardstreetAddress" class="form-control input" />
                      <!-- <p>Address: {{ userAddress }}</p> -->
                    </div>
                  </div>
                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Country</label>
                      </div>
                      <!-- [matAutocomplete]="country_id"  -->
                      <!-- (click)="$event.target.select()" -->
                      <!-- (ngModelChange)="validationCountry('CreditCardCountry')" -->
                      <input class="form-control" type="text" formControlName="CreditCardCountry" autocomplete="on"
                        placeholder="Select your Country" id="CreditCardCountry" [ngModel]="country"
                        (ngModelChange)="onChangeCountry($event)">
                      <!-- <mat-autocomplete #country_id="matAutocomplete">
                    <mat-option *ngFor="let option of filteredCountries | async" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete> -->
                    </div>
                    <mat-error *ngIf="form.get('CreditCardCountry').errors?.someProp">
                      {{form.get('CreditCardCountry').errors?.someProp}}
                    </mat-error>
                    <mat-error *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].dirty"> Country
                      Is
                      Required
                    </mat-error>
                  </div>
                  <div class=" col-6 position-relative ">
                    <div class="item letters">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">City</label>
                      </div>
                      <input formControlName="CreditCardCity" type="text" class="form-control" placeholder="City"
                        (ngModelChange)="onChangeCity($event)" id="CreditCardCity" autocomplete="off" required
                        [ngModel]="city" />
                      <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only
                        Letters</mat-error>
                      <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is
                        Required
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Zip</label>
                      </div>
                      <input formControlName="CreditCardZip" type="text" class="num form-control" placeholder="Zip"
                        (ngModelChange)="onChangeZip($event)" id="CreditCardZip" autocomplete="off" required
                        [ngModel]="ZipCode" />
                    </div>
                    <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
                    </mat-error>
                  </div>

                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">State</label>
                      </div>
                      <input formControlName="State" type="text" placeholder="State Code" class="form-control"
                        (ngModelChange)="onChangeState($event)" id="State" autocomplete="off" required
                        [ngModel]="state" />
                      <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
                      </mat-error>
                      <mat-error *ngIf="f['State'].errors?.pattern">Please Type Only Letters</mat-error>
                    </div>
                  </div>
                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">credit Card Region</label>
                      </div>
                      <input formControlName="creditCardRegion" type="text" placeholder="credit Card Region Code"
                        class="form-control" id="creditCardRegion" autocomplete="off" required />

                    </div>
                  </div>

                  <div class="col-12 agree-share-contact my-3">
                    <input type="checkbox" id="agreeShareContact" name="agreeShareContact" checked>
                    <label for="agreeShareContact">I agree to share this contact information with the airline(s)
                      associated with this booking</label>
                  </div>

                </div>
              </div>
            </div>

          </form>
        </div>


      </form>
    </div>
  </ng-container>
  <ng-container>
    <div class="review-trip-section">
      <section class="terms">
        <p class="title billing-note">
          Please note: your credit/ debit Card may be billed in multiple charges totaling the final total
          price.
        </p>
        <div class="col-12">
          <!-- <span class="alert-primary d-block p-2 mb-1">Please check boxes below</span> -->
          <div class="form-check m-3 ">
            <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox1" type="checkbox" checked=""
              class="form-check-input checkbox-lg position"></mat-checkbox>
            <label class="form-check-label">I Agree that I have read and accepted the flyAllOver's
              <a [routerLink]="['/terms']" target="_blank">Terms & Conditions.</a>
            </label>
          </div>
          <div class="form-check m-3">
            <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox2" checked="" type="checkbox"
              class="form-check-input checkbox-lg position"></mat-checkbox>
            <label class="form-check-label">I Agree on the
              <a href="javascript:void(0)" (click)="openFareRulesDialog(itinerary)">Fare Rules</a> of each one of the
              airlines of this trip.
            </label>

          </div>
          <div class="form-check m-3 ">
            <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox3" type="checkbox" checked=""
              class="form-check-input checkbox-lg position"></mat-checkbox>
            <label class="form-check-label">Please Acknowledge Our <a href="https://flyAllOver.com/cancellation-policy/"
                target="_blank"> Cancellation
                Policy.</a></label>
          </div>
        </div>
      </section>
    </div>
    <div class="review-trip-section">
      <div class="">
        <div class="alert-text col-12">
          <div class="d-flex m-3 p-0">
            <div class="alert-Info">i</div>
            <label class="form-check-label">This itinerary is for an alternate date and nearby airport. Depart
              ({{itinerary?.flights[0][0].DepartureDateTime | date: 'EEE, MMM d ' }}), Return
              ({{itinerary?.flights[itinerary?.flights.length - 1][0].ArrivalDateTime | date: 'EEE, MMM d' }}).</label>
          </div>
        </div>
        <div class="alert-text col-12">
          <div class="form-check m-3 ">
            <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox4" type="checkbox" checked=""
              class="form-check-input checkbox-lg position"></mat-checkbox>
            <label class="form-check-label" style="
        font-weight: 500 !important;
    ">Please check this box to indicate that you've confirmed the date(s) and airport(s) to be correct and that you're
              ready to book this flight.</label>
          </div>
        </div>
      </div>
    </div>
    <div class="review-trip-section">
      <div class="">
        <div class="alert-text col-12">
          <div class="d-flex m-3 p-0">
            <div class="alert-Info">i</div>
            <label class="form-check-label title">Credit Card and Contact Information Matching Confirmation</label>
          </div>
        </div>
        <div class="alert-text col-12">
          <div class="form-check m-3 ">
            <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox5" type="checkbox" checked=""
              class="form-check-input checkbox-lg position"></mat-checkbox>
            <label class="form-check-label" style="
        font-weight: 500 !important;
    ">My credit card information is matching my contact information.</label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="text-danger text-center blockquote" *ngIf="!checkName">
    If the information provided for the traveler does not match the information on the Credit Card, additional
    verification may be required. This could involve contacting the cardholder to verify the transaction or requesting
    additional documentation to confirm the traveler's identity. In some cases, a fee of $5.00 may be charged for the
    extra security verification process.
  </div>



  <div class="col-12  btn-ticket-port mt-3 " [ngClass]="{'d-flex justify-content-center': paymentStep==1}">
    <!-- <div class="btn-ticket back" (click)="goToPreviousStep()" [ngClass]="{'d-none': paymentStep==1}">

    <span *ngIf="paymentStep ==2" >BACK</span>

  </div> -->

    <div class="btn-ticket next" [ngClass]="{'back': checkPoliceyBox <5 }" (click)="goToNextStep()">
      <!-- <span *ngIf="paymentStep ==1">NEXT</span> -->
      <span>Review & Book</span>
    </div>
  </div>
</div>
<app-footer></app-footer>
