import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpCenterServices {
  private domain = environment.domain;

  constructor(private http: HttpClient) {
  }

  // help center page
  getFAQs() {
    return this.http.get(`${this.domain}/api/faq`);
  }

  getTopics() {
    return this.http.get(`${this.domain}/api/post/categories`);
  }

  getTopic(id) {
    return this.http.get(`${this.domain}/api/posts/category/${id}`);
  }

  // search-results page
  searchForTopic(text) {
    return this.http.get(`${this.domain}/api/post-search?text=${text}`);
  }

  getPagingData(page) {
    return this.http.get(`${this.domain}/api/post-search?page=${page}`);
  }

  // centre(support) page new apis
  getCategories() {
    return this.http.get(`${this.domain}/api/support-article/categories`);
  }

  getAllArticles(params) {
    return this.http.get(`${this.domain}/api/support-articles`, {params: params});
  }

  searchArticles(params) {
    return this.http.get(`${this.domain}/api/support-article-search`, {params: params});
  }

  getArticle(slug) {
    // return this.http.post(`${this.domain}/api/support-article/${slug}`, {})
    return this.http.get(`${this.domain}/api/support-article/${slug}`);
  }

  // terms and conditions page
  getTermsConditions() {
    return this.http.get(`${this.domain}/api/terms-and-conditions`);
  }
 // terms and conditions page
 getHelpPolicy() {
  return this.http.get(`${this.domain}/api/help-policy`);
}
getArticlesLinks (){
  return this.http.get(`${this.domain}/api/related-articles`);
}
  
  getArticlesOfCategory(categorySlug: string, params) {
    return this.http.get(`${this.domain}/api/support-articles/category/${categorySlug}`, {params: params});
  }

  // Privacy and policy page
  getPrivacyPolicy() {
    return this.http.get(`${this.domain}/api/privacy-and-policy`);
  }
 // Chargeback policy page
  getChargeBackPolicy() {
    return this.http.get(`${this.domain}/api/charge-back`);
  }
   // CancellationPolicy  page
   getCancellationPolicy() {
    return this.http.get(`${this.domain}/api/cancelation-policy`);
  }
  // FAQ page
  searchForFAQs(text) {
    return this.http.get(`${this.domain}/api/faq-search?text=${text}`);
  }

  // disclaimer page
  getDisclaimer() {
    return this.http.get(`${this.domain}/api/general-disclaimer`);
  }

  // newsletter page
  postToNewsLetter(email) {
    return this.http.post(`${this.domain}/api/newsletter?email=${email}`, {});
  }

  // contact-us page
  postToContactUs(name, email, phone, subject, message, captchaKey) {
    return this.http.post(`${this.domain}/api/contact-us`, {}, {
      params: {
        name,
        email,
        phone,
        subject,
        message,
        'g-recaptcha-response': captchaKey

      }
    });
  }

  // about-us page
  getAboutUs() {
    return this.http.get(`${this.domain}/api/about-us`);
  }

  // bookings page
  getPostDetails(id) {
    return this.http.get(`${this.domain}/api/post/${id}`);
  }

  getOffersData(params) {
    return this.http.get(`${this.domain}/api/offers/category/1`, {params: params});
  }

  searchOffers(payload, params) {
    return this.http.post(`${this.domain}/api/offer/search`, payload, {params: params});
  }

  // Explore Pages
  getPopularDestinations() {
    return this.http.get(`${this.domain}/api/google/popular`);
  }

  getPhotosOfDestinations(photoRef) {
    const headers = new HttpHeaders().set('Content-Type', 'image/png');
    return this.http.get(`${this.domain}/api/google/get-photo?photoreference=${photoRef}`, {headers});
  }

  getCardSectionsData(name, lat, lang) {
    return this.http.get(`${this.domain}/api/google/things-to-do?name=${name}&lat=${lat}&long=${lang}`);
  }

  getCityDetails(name, countryCode) {
    return this.http.get(`${this.domain}/api/google/city-details?name=${name}&country_code=${countryCode}`);
  }

  getTravelVideos() {
    return this.http.get(`${this.domain}/api/google/travel-videos`);
  }

  getOtherPlaces(lat, lang) {
    // return this.http.get(`${this.domain}/api/google/other-places?ip=${ip}`);
    return this.http.get(`${this.domain}/api/google/other-places?lat=${lat}&long=${lang}`);
  }

  login(data) {
    return this.http.post(`${this.domain}/api/login`, data);
  }

  register(data) {
    return this.http.post(`${this.domain}/api/signup`, data);
  }

  socialLogin(data) {
    return this.http.post(`${this.domain}/api/social_login`, data);
  }

  countriesAutoComplete(searchTerm: string) {
    return this.http.get(`${this.domain}/api/countries`, {params: {country_name: searchTerm}}).pipe(
      map((response: any) => {
        if (response.length > 0) {
          return response.map(el => {
            return `${el.name}`;
          }).slice(0, 15);
        } else {
          return [' '];
        }
      })
    );
  }

  getAllCovidCountryLocations(countryName) {
    return this.http.get(`${this.domain}/api/covid/pcr-locations/${countryName}`);
    // return this.http.get(`${this.domain}/api/covid/country-pcr-locations`, { params: { country_name: countryName } });
  }

  getCovidLocations(state: string, countryName: string) {
    return this.http.get(`${this.domain}/api/covid/pcr-locations/${countryName}/${state}`);
    // return this.http.get(`${this.domain}/api/covid/pcr-locations`, { params: { name: state , country_name: countryName } })
  }


  forgetPassword(email, captchaKey) {
    return this.http.post(`${this.domain}/api/passwordreset/create`, {
      'email': email,
      'g-recaptcha-response': captchaKey
    });
  }

  passwordReset(token) {
    return this.http.get(`${this.domain}/api/passwordreset/${token}`);
  }

  newPassword(data) {
    return this.http.post(`${this.domain}/api/passwordreset`, data);
  }

}

