<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start page body -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center">Resetting Password</h1>

        </div>
    </div>
    <div class="help-center-body">
        <!-- start contact-us -->
        <div class="container">
            <div class="contact-us" *ngIf="preLoading">
                <div class="inside">
                    <div class="logo">
                        <ngx-skeleton-loader count="1" [theme]="{height:'176px', width:'148px', 'margin-top':'40px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <h4 class="text-center">
                        <ngx-skeleton-loader count="1" [theme]="{height:'25px', width:'200px'}">
                        </ngx-skeleton-loader>
                    </h4>
                    <p class="text-center">
                        <ngx-skeleton-loader count="1" [theme]="{height:'10px', width:'250px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{height:'10px', width:'150px'}">
                        </ngx-skeleton-loader>
                    </p>
                    <div class="field">
                        <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'120px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader count="1" [theme]="{height:'30px', width:'280px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="field">
                        <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'120px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader count="1" [theme]="{height:'30px', width:'280px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="submit">
                        <ngx-skeleton-loader count="1" [theme]="{height:'50px', width:'100%'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class="contact-us" *ngIf="!preLoading">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPasswordForm(resetPasswordForm.value)">
                    <div class="logo" *ngIf="!sessionExpiration">
                        <img title="Create new password" alt="reset-password"  src="../../../assets/images/help-center/reset-password.png">
                    </div>
                    <div class="inside" *ngIf="!sessionExpiration">
                        <h2 class="text-center">Create new password</h2>
                        <p class="text-center">Please set your new password that contain both letters and numbers</p>
                        <div class="field" *ngIf="backendError">
                            <div class="text-danger text-center">{{backednErrorMsg}}</div>
                        </div>

                        <!-- Password -->
                        <div class="field">
                            <label for="password"
                                [ngClass]="resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched || passwordMissMatch ?'text-danger':''">*New
                                Password</label>
                            <input (keyup)="resetError()" id="password" type="password" required
                                formControlName="password" minlength="6"
                                [ngClass]="resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched || passwordMissMatch ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.password">
                                <small class="text-danger"
                                    *ngIf="resetPasswordForm.get('password').hasError(validation.type) && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- confirm password -->
                        <div class="field">
                            <label for="mobile"
                                [ngClass]="resetPasswordForm.get('confirmPassword').invalid && resetPasswordForm.get('confirmPassword').touched || passwordMissMatch ?'text-danger':''">*Confirm
                                Password</label>
                            <input (keyup)="resetError()" type="password" required formControlName="confirmPassword"
                                minlength="6"
                                [ngClass]="resetPasswordForm.get('confirmPassword').invalid && resetPasswordForm.get('confirmPassword').touched || passwordMissMatch ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.confirmPassword">
                                <small class="text-danger"
                                    *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)">
                                    {{validation.message}}</small>
                            </span>
                            <small class="text-danger" *ngIf="passwordMissMatch">Password and confirm password must
                                match</small>
                        </div>

                        <div class="submit">
                            <!-- <button class="button disabled" *ngIf="!signUpForm.valid" disabled type="submit">SIGN
                                UP</button>
                            <button class="button active" *ngIf="signUpForm.valid" type="submit">SIGN UP</button> -->
                            <button [ngClass]="resetPasswordForm.valid ? 'button active':'button disabled'"
                                [disabled]="!resetPasswordForm.valid" type="submit">CONFIRM
                                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i></button>
                        </div>

                    </div>
                </form>
                <div class="inside text-center session-expired " *ngIf="sessionExpiration">
                    <h2 class="text-danger">Your session has been expired</h2>
                    <a routerLink="/forget-password">Reset your password again <i class="fas fa-angle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end contact-us -->
    </div>


</div>
<!-- end page body -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
