<app-navbar></app-navbar>
<div class="service-page">
  <div class="service-header">
    <div class="overlay">
      <div class="container">
        <h1 class="text-center">AIRLINE SELF-SERVICE</h1>
      </div>
    </div>
  </div>
  <div class="service-body container">
    <div class="items row">
      <div class="item">
        <a href="#">
        <img alt="self-services" title="AIRLINE SELF-SERVICE" src="assets/images/self-services/image.png" >
        </a>
        <div class="mobile">
          <a href="#">
          <img alt="call" title="AIRLINE SELF-SERVICE" class="pr-2" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>
        </div>
      </div>
      <div class="item">
        <a href="#">
        <img title="AIRLINE SELF-SERVICE"  alt="self-services" src="assets/images/self-services/1.png">
        </a>
        <div class="mobile">
          <a href="#">
          <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="call" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>
        </div>
      </div>
      <div class="item">
        <a href="#">
        <img title="AIRLINE SELF-SERVICE" alt="self-services" src="assets/images/self-services/3.png">
        </a>
        <div class="mobile">
          <a href="#">
          <img title="AIRLINE SELF-SERVICE" alt="call" class="pr-2" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>
        </div>
      </div>
      <div class="item">
        <a href="#">
        <img title="AIRLINE SELF-SERVICE" alt="self-service" src="assets/images/self-services/4.png" >
        </a>
        <div class="mobile">
          <a href="#">
          <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="call" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>
        </div>
      </div>
      <div class="item">
        <a href="#">
        <img src="assets/images/self-services/image.png" alt="self-services" title="AIRLINE SELF-SERVICE">
        </a>
        <div class="mobile">
          <a href="#">
          <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="call" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>
        </div>
      </div>
      <div class="item">
        <a href="#">
        <img  title="AIRLINE SELF-SERVICE" alt="self-services" src="assets/images/self-services/image.png">
        </a>
        <div class="mobile">
          <a>
          <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="call" src="assets/images/self-services/call.svg">
          <span>800-237-0276</span>
          </a>
        </div>
        <div class="airline">
          <a href="#">Airline Policies</a>
        </div>
        <div class="baggage">
          <a href="#">Baggage Policies</a>
        </div>
        <div class="online">
          <a href="#">Online-Check-In</a>

    <div class="items large-screen row">
      <div class="item row">
        <div class="site">
          <a href="#">
            <img title="AIRLINE SELF-SERVICE" alt="self-services" src="assets/images/self-services/image.png">
          </a>
        </div>
        <div class="spacing">
          <div class="mobile">
            <a href="#">
              <img  title="AIRLINE SELF-SERVICE" alt="call" class="pr-2" src="assets/images/self-services/call.svg">
              <a href="#">800-237-0276</a>
            </a>
          </div>
          <div class="airline">
            <a href="#">
              <img title="AIRLINE SELF-SERVICE" alt="self-services" class="pr-2" src="assets/images/self-services/service1.svg">
              <a href="#">Airline Policies</a>
            </a>
          </div>
        </div>
        <div class="spacing">
          <div class="baggage">
            <a href="#">
              <img title="AIRLINE SELF-SERVICE" alt="self-services"  class="pr-2" src="assets/images/self-services/luggage.svg">
              <a href="#">Baggage Policies</a>
            </a>
          </div>
          <div class="online">
            <a href="#">
              <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="self-services" src="assets/images/self-services/service2.svg">
              <a href="#">Online-Check-In</a>
            </a>
          </div>
        </div>
      </div>
      <div class="item">
      <div class="site">
        <a href="#">
          <img src="assets/images/self-services/image.png" title="AIRLINE SELF-SERVICE" alt="self-services">
        </a>
      </div>
      <div class="spacing">
        <div class="mobile">
          <a href="#">
            <img class="pr-2"  title="AIRLINE SELF-SERVICE" alt="call" src="assets/images/self-services/call.svg">
            <a href="#">800-237-0276</a>
          </a>
        </div>
        <div class="airline">
          <a href="#">
            <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="self-services" src="assets/images/self-services/service1.svg">
            <a href="#">Airline Policies</a>
          </a>
        </div>
      </div>
      <div class="spacing">
        <div class="baggage">
          <a href="#">
            <img class="pr-2" title="AIRLINE SELF-SERVICE" alt="Baggage" src="assets/images/self-services/luggage.svg">
            <a href="#">Baggage Policies</a>
          </a>
        </div>
        <div class="online">
          <a href="#">
            <img class="pr-2" src="assets/images/self-services/service2.svg" title="AIRLINE SELF-SERVICE" alt="self-services">
            <a href="#">Online-Check-In</a>
          </a>
        </div>
      </div>
    </div>
      <div class="item">
        <div class="site">
          <a href="#">
            <img src="assets/images/self-services/image.png" title="AIRLINE SELF-SERVICE" alt="self-services">
          </a>
        </div>
        <div class="spacing">
          <div class="mobile">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/call.svg" title="AIRLINE SELF-SERVICE" alt="call">
              <a href="#">800-237-0276</a>
            </a>
          </div>
          <div class="airline">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/service1.svg" title="AIRLINE SELF-SERVICE" alt="self-services">
              <a href="#">Airline Policies</a>
            </a>
          </div>
        </div>
        <div class="spacing">
          <div class="baggage">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/luggage.svg" title="AIRLINE SELF-SERVICE" alt="Baggage">
              <a href="#">Baggage Policies</a>
            </a>
          </div>
          <div class="online">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/service2.svg" title="AIRLINE SELF-SERVICE" alt="self-services">
              <a href="#">Online-Check-In</a>
            </a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="site">
          <a href="#">
            <img src="assets/images/self-services/image.png" title="AIRLINE SELF-SERVICE" alt="self-services">
          </a>
        </div>
        <div class="spacing">
          <div class="mobile">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/call.svg" title="AIRLINE SELF-SERVICE" alt="call">
              <a href="#">800-237-0276</a>
            </a>
          </div>
          <div class="airline">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/service1.svg" title="AIRLINE SELF-SERVICE" alt="self-services">
              <a href="#">Airline Policies</a>
            </a>
          </div>
        </div>
        <div class="spacing">
          <div class="baggage">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/luggage.svg" title="AIRLINE SELF-SERVICE" alt="Baggage">
              <a href="#">Baggage Policies</a>
            </a>
          </div>
          <div class="online">
            <a href="#">
              <img class="pr-2" src="assets/images/self-services/service2.svg" title="AIRLINE SELF-SERVICE" alt="self-services">
              <a href="#">Online-Check-In</a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
