<div class="customContainer">
  <ng-container *ngIf="seats && seats != 'Not Found' && !loading">
    <div class="col-4">
      <div class="row">
        <div class="left" *ngIf="currentFlight">
          <div>
            <span>{{currentFlight.OperatingAirlineName}}</span>
            <span>{{currentFlight.FlightNumber}}</span>
          </div>
          <div>
            <span>{{currentFlight.DepartureCityName}}</span>
            <span>({{currentFlight.DepartureAirport}})</span>
            <span><i class="fas fa-arrow-right"></i></span>
            <span>{{currentFlight.ArrivalCitytName}}</span>
            <span>({{currentFlight.ArrivalAirport}})</span>
          </div>
          <div class="d-flex">
            <div class="title">
              <span>Date</span>
              <span>:</span>
            </div>
            <span>{{currentFlight.DepartureDateTime | date: 'EEE , d MMM yyyy'}}</span>
          </div>
          <div class="d-flex">
            <div class="title">
              <span>Duration</span>
              <span>:</span>
            </div>
            <span>{{getDurationString(currentFlight.ElapsedTime)}}</span>
          </div>
          <div class="d-flex">
            <div class="title">
              <span>Equipment type</span>
              <span>:</span>
            </div>
            <span>{{currentFlight.AircraftName}}</span>
          </div>
          <div class="hr"></div>
          <div style="margin-bottom: 20px">
            <span>Seat Legend</span>
          </div>
          <div class="row flex-column p-2">
            <div class=" legend">
              <div class="legendBtn plusBtn">
                <i class="fas fa-plus"></i>
              </div>
              <div>
                <span>Paid</span>
              </div>
            </div>
            <div class=" legend">
              <div class="legendBtn unAvailableBtn">
                <i class="fas fa-slash"></i>
              </div>
              <div>
                <span>UnAvailable</span>
              </div>
            </div>
            <div class=" legend">
              <div class="legendBtn unAvailableBtn">
                <i class="fas fa-ban"></i>
              </div>
              <div>
                <span>Blocked</span>
              </div>
            </div>
            <div class=" legend">
              <div class="legendBtn restroomBtn">
                <i class="fas fa-restroom"></i>
              </div>
              <div>
                <span>Lavatory</span>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div style="margin-bottom: 10px" *ngIf="currentTraveller">
            <span class="bold passenger">{{currentTraveller.first_name}}</span>
          </div>
          <div class="d-flex flex-column" *ngIf="!currentTraveller.seatNumber">
            <span>No Seat Selected</span>
          </div>
          <div class="d-flex flex-column" *ngIf="currentTraveller.seatNumber">
            <div class="d-flex align-items-center justify-content-start">
              <div><strong>{{currentTraveller.seatNumber[currentFlightIndex]}}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="row justify-content-center">
        <div class="seatsContainer">
          <div class="d-flex flex-column">
            <ng-container *ngFor="let seat of seats let i = index">
              <div class="d-flex" *ngIf="i == 0">
                <div class="seat"></div>
                <ng-container *ngFor="let header of seat[0]['seatRow']">
                  <div class="seat">{{header.column}}</div>
                </ng-container>
              </div>
              <div class="d-flex flex-column seatsSections">
                <ng-container *ngFor="let seat2 of seat let j = index">
                  <div class="d-flex">
                    <ng-container *ngFor="let seat3 of seat2['seatRow'] let k = index">
                      <div class="seat" *ngIf="k == 0">
                        {{seat3.row}}
                      </div>
                      <div class="seat" [ngClass]="{'empty': !seat3.column}">
                        <div class="seatIsFree" *ngIf="seat3.occupationStatusCode == 'SeatIsFree'"
                             (mouseenter)="showSeatDetails($event, seat3)"
                             (mouseleave)="hideSeatDetails()"
                             (click)="selectSeat(seat3,i,j,k)">
                          <div class="legendBtn plusBtn">
                            <i class="fas fa-plus"></i>
                          </div>
                        </div>
                        <div class="seatIsSelected noHover" [ngStyle]="seat3.color && {background:seat3.color}"
                             *ngIf="seat3.occupationStatusCode == 'seatIsSelected'"
                             (mouseenter)="showSeatDetails($event, seat3)"
                             (mouseleave)="hideSeatDetails()">
                        </div>
                        <div class="seatIsSelected {{currentTraveller.id}}"
                             [ngStyle]="{background:currentTraveller.color}"
                             *ngIf="seat3.column && seat3.occupationStatusCode == 'seatIsSelected' && currentTraveller.seatNumber && (seat3.row + '' + seat3.column) == currentTraveller.seatNumber[currentFlightIndex]"
                             (mouseenter)="showSeatDetails($event ,seat3)"
                             (mouseleave)="hideSeatDetails()"
                             (click)="unSelectSeat(seat3)">
                          <div class="legendBtn minusBtn">
                            <i class="fas fa-minus"></i>
                          </div>
                        </div>
                        <div class="NoSeatLavatory"
                             *ngIf="seat3.column && !seat3.occupationStatusCode && seat3.characteristics[0].description == 'NoSeatLavatory'">
                          <div class="legendBtn restroomBtn">
                            <i class="fas fa-restroom"></i>
                          </div>
                        </div>
                        <div class="Aisle" *ngIf="seat3.column && seat3.occupationStatusCode == 'Aisle'"></div>
                        <div class="SeatIsOccupied"
                             *ngIf="seat3.column && seat3.occupationStatusCode == 'SeatIsOccupied'">
                          <div class="legendBtn unAvailableBtn">
                            <i class="fas fa-slash"></i>
                          </div>
                        </div>
                        <div class="SeatBlockedForOtherReason"
                             *ngIf="seat3.column && seat3.occupationStatusCode == 'SeatBlockedForOtherReason'">
                          <div class="legendBtn unAvailableBtn">
                            <i class="fas fa-ban"></i>
                          </div>
                        </div>
                        <div class="details {{seat3.column+''+seat3.row}} hide">
                          <div class="d-flex align-items-center justify-content-start">
                            <div>
                              <strong>{{seat3.column}}{{seat3.row}}</strong>
                            </div>
                            <div *ngIf="seat3.offer && seat3.offer.price && seat3.offer.price.totalAmount">
                              <span class="price">{{seat3.offer.price.totalAmount.amount | currency}}</span>
                            </div>
                          </div>
                          <div class="d-flex align-items-start justify-content-center flex-column characteristics">
                            <ng-container *ngFor="let characteristic of seat3.characteristics">
                              <div>
                                <span
                                  *ngIf="characteristicObj[characteristic.description]">{{characteristicObj[characteristic.description]}}</span>
                                <span
                                  *ngIf="!characteristicObj[characteristic.description]">{{characteristic.description}}</span>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="seats && seats == 'Not Found' && !loading">
    <div class="col-12">
      <div class="d-flex justify-content-center" style="padding-top: 40px">
        <h4>Not Available To Book Online</h4>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="col-12">
      <div class="row justify-content-center align-items-center" style="height: 650px">
             <app-spinner-loader></app-spinner-loader>
      </div>
    </div>
  </ng-container>
</div>

