import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-card-room-details',
  templateUrl: './card-room-details.component.html',
  styleUrls: ['./card-room-details.component.scss'],

})
export class CardRoomDetailsComponent implements OnInit {
  @Input() room: any;
  @Input() hotelCode: any;

  ngOnInit(): void {
console.log(this.room);

  }


  changeRoomName(romeName) {
    // if()
    const newName = romeName.toString().replace(' - Booking.com rate', '');
    const roomArrayName = newName.split('- ')
    return roomArrayName;
  }
  ViewBad(loh){
    console.log(loh);
    console.log('loh',loh.ota_code_list_name);
    return loh.ota_code_list_name

  }
}
