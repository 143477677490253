<app-navbar></app-navbar>

<!-- start explore-page -->
<div class="explore-page">

    <!-- start explore-header -->
    <div class="explore-header">
        <div class="overlay">
            <div class="container">
                <h1 class="text-center">Explore the world through our eyes</h1>
                <div class="search-box">
                    <input type="text" placeholder="Search here..." [ngbTypeahead]="search" placement="bottom-left"
                        (selectItem)="selectItem($event)" />
                    <button><img title="Explore Search Image" alt="search"  src="./assets/images/explore/search.svg" width="30" /></button>
                </div>
            </div>
        </div>
    </div>
    <!-- end explore-header -->

    <!-- start popular-destinations -->
    <div class="popular-destinations" *ngIf="popularDestinationsSectionExist">
        <!-- begin skeleton loader -->
        <div class="container" *ngIf="!popularDestinationsExist">
            <h4 class="text-center">
                <ngx-skeleton-loader count="1" [theme]="{height:'30px', width:'30%'}">
                </ngx-skeleton-loader>
            </h4>

            <div class="row">
                <div class="col">
                    <div class="destination" style="padding-bottom:0px">
                        <ngx-skeleton-loader count="1" [theme]="{height:'200px','margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="col">
                    <div class="destination" style="padding-bottom:0px">
                        <ngx-skeleton-loader count="1" [theme]="{height:'200px','margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
        <!-- end skeleton loader -->
        <div class="container" *ngIf="popularDestinationsExist">
            <h2 class="text-center">Popular Destinations</h2>
            <div class="row">
                <div class="col">
                    <div class="destination" (click)="navigateToExploreDetails(popularDestinations[0])">
                        <div class="description" [ngStyle]="{'background-image': 'url(' + citiesPhotos[0] + ')'}">
                            <div class="overlay">
                                <h3><i class="fas fa-map-marker-alt"></i> {{popularDestinations[0]?.formatted_address}}
                                </h3>
                                <p>{{popularDestinations[0]?.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="destination" (click)="navigateToExploreDetails(popularDestinations[1])">
                        <div class="description" [ngStyle]="{'background-image': 'url(' + citiesPhotos[1] + ')'}">
                            <div class="overlay">
                                <h3><i class="fas fa-map-marker-alt"></i> {{popularDestinations[1]?.formatted_address}}
                                </h3>
                                <p>{{popularDestinations[1]?.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
    <!-- end popular-destinations -->

    <!-- start loved-todo -->
    <div class="loved-todo" *ngIf="peopleLovedSectionExist">
        <div class="container" *ngIf="!peopleLovedDataExist">
            <h2>
                <ngx-skeleton-loader count="1" [theme]="{height:'20px', width:'30%'}">
                </ngx-skeleton-loader>
            </h2>
            <div class="row">
                <div class="col-lg-3 col-sm-6" *ngFor="let item of createRange(6)">
                    <div class="inner">
                        <div class="photo">
                            <ngx-skeleton-loader count="1" [theme]="{height:'150px'}">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="description">
                            <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'40%'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="peopleLovedDataExist">
            <h3>People Loved In {{popularDestinations[0]?.name}}</h3>
            <div class="row">

                <div [ngClass]="peopleLovedData?.length < 4 ? 'col-lg-4 col-sm-6': 'col-lg-3 col-sm-6'"
                    *ngFor="let place of peopleLovedData; let i =index">
                    <div class="inner">
                        <div class="photo" [ngStyle]="{'background-image': 'url(' + peopleLovedDataPhotos[i] + ')'}">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3 title="{{place.name}}">{{place.name.substring(0, 20)}} <span
                                    *ngIf="place.name?.length > 20">...</span></h3>
                            <div class="reviews">
                                <ng-template #t let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                        <span class="half" [style.width.%]="fill"><i class="fas fa-star"></i></span>
                                        <i class="fas fa-star"></i>
                                    </span>
                                </ng-template>

                                <ngb-rating *ngIf="place?.rating" [(rate)]="place.rating" [starTemplate]="t"
                                    [readonly]="true" [max]="5">
                                </ngb-rating>
                                <label *ngIf="place?.user_ratings_total">{{place.user_ratings_total}} Reviews</label>
                            </div>
                            <div class="vicinity">
                                <p title="{{place?.vicinity}}">{{place?.vicinity?.slice(0, 30)}} <span
                                        *ngIf="place?.vicinity?.length > 30"> ...</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/loved.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Capital Of QaitBay</h3>
                            <div class="reviews">
                                <span class="rate rate1">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/loved.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Capital Of QaitBay</h3>
                            <div class="reviews">
                                <span class="rate rate5">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/loved.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Capital Of QaitBay</h3>
                            <div class="reviews">
                                <span class="rate rate5">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>

    <div class="loved-todo" *ngIf="thingsToDoSectionExist">
        <div class="container" *ngIf="!thingsToDoDataExist">
            <h3>
                <ngx-skeleton-loader count="1" [theme]="{height:'20px', width:'30%'}">
                </ngx-skeleton-loader>
            </h3>
            <div class="row">
                <div class="col-lg-3 col-sm-6" *ngFor="let item of createRange(6)">
                    <div class="inner">
                        <div class="photo">
                            <ngx-skeleton-loader count="1" [theme]="{height:'150px'}">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="description">
                            <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'40%'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{height:'10px'}">
                            </ngx-skeleton-loader>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="container" *ngIf="thingsToDoDataExist">
            <h6>Things To Do In {{popularDestinations[1]?.name}}</h6>
            <div class="row">

                <div [ngClass]="thingsToDoData?.length < 4 ? 'col-lg-4 col-sm-6': 'col-lg-3 col-sm-6'"
                    *ngFor="let place of thingsToDoData; let i = index">
                    <div class="inner">
                        <div class="photo" [ngStyle]="{'background-image': 'url(' + thingsToDoDataPhotos[i] + ')'}">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3 title="{{place.name}}">{{place.name.substring(0, 20)}} <span
                                    *ngIf="place.name?.length > 20">...</span></h3>
                            <div class="reviews">
                                <ng-template #t let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                        <span class="half" [style.width.%]="fill"><i class="fas fa-star"></i></span>
                                        <i class="fas fa-star"></i>
                                    </span>
                                </ng-template>

                                <ngb-rating *ngIf="place?.rating" [(rate)]="place.rating" [starTemplate]="t"
                                    [readonly]="true" [max]="5">
                                </ngb-rating>
                                <label *ngIf="place?.user_ratings_total">{{place.user_ratings_total}} Reviews</label>
                            </div>
                            <div class="vicinity">
                                <p title="{{place?.vicinity}}">{{place?.vicinity?.slice(0, 30)}} <span
                                        *ngIf="place?.vicinity?.length > 30"> ...</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/todo.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Burg Khalifa</h3>
                            <div class="reviews">
                                <span class="rate rate2">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/todo.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Burg Khalifa</h3>
                            <div class="reviews">
                                <span class="rate rate5">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="inner">
                        <div class="photo" style="background-image: url('./assets/images/explore/todo.png')">
                            <div class="overlay"></div>
                        </div>
                        <div class="description">
                            <h3>Burg Khalifa</h3>
                            <div class="reviews">
                                <span class="rate rate3">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                                <label>2,290 reviews</label>
                            </div>
                            <p>15th-century fortress & naval museum</p>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <!-- end loved-todo -->

</div>
<!-- end explore-page -->

<app-footer></app-footer>
