<div class="customContainer">
  <div class="filters">
    <div class="filter Ftitle">
      <h4 class="line">Filter by </h4>
<!--      <div style="width: 100px ; height: 1px ; background-color: gray;"> </div>-->
<!--      <p class="text-muted" style="max-width: max-content;margin: 0 auto;font-size: 24px;"><span style="color:#F65022;">12</span> results found</p>-->
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////// -->
    <div class="filter mb-3">
<!--      <i  class="fas fa-spinner fa-spin airLineSpinner"></i>-->
      <ng-container >
        <div class="title">
          <h4>Popular filters </h4>
        </div>
          <mat-checkbox> Apartment</mat-checkbox>
          <mat-checkbox> Breakfast included </mat-checkbox>
          <mat-checkbox> Villa </mat-checkbox>
          <mat-checkbox> Apart-hotel </mat-checkbox>
      </ng-container>

    </div>
    <!-- ///////////////////////////////////////////////////////////////// -->
    <div class="filter py-3">
        <ng-container >
          <div class="title">
            <h4>Guest Rating</h4>
          </div>
          <mat-radio-group >
            <mat-radio-button class="example-margin" value="0">Any</mat-radio-button>
            <mat-radio-button class="example-margin" value="1">Wonderful 9+</mat-radio-button>
            <mat-radio-button class="example-margin" value="2">Very good 8+</mat-radio-button>
            <mat-radio-button class="example-margin" value="3">Good 7+</mat-radio-button>
          </mat-radio-group>
        </ng-container>
    </div>
    <div class="filter mb-3">
      <!--      <i  class="fas fa-spinner fa-spin airLineSpinner"></i>-->
      <ng-container >
        <div class="title">
          <h4>Star Rating </h4>
        </div>
        <div>
          <mat-checkbox> 1 star </mat-checkbox>
          <span class="text-muted"> 10 </span>
        </div>
        <div>
          <mat-checkbox> 2 star </mat-checkbox>
          <span class="text-muted"> 8 </span>
        </div>
        <div>
          <mat-checkbox> 3 star </mat-checkbox>
          <span class="text-muted"> 13 </span>
        </div>
        <div>
          <mat-checkbox> 4 star </mat-checkbox>
          <span class="text-muted"> 13 </span>
        </div>
        <div>
          <mat-checkbox> Good: 7+ </mat-checkbox>
          <span class="text-muted"> 15 </span>
        </div>

      </ng-container>

    </div>
    <ng-container >
      <div class="filter py-3"></div>
    </ng-container>
    <div class="filter py-3" >
      <div class="title">
        <h4>Amenities</h4>
      </div>
      <ng-container >
        <mat-checkbox >Airport shuttle included</mat-checkbox>
        <mat-checkbox > Pool</mat-checkbox>
        <mat-checkbox > Pet friendly</mat-checkbox>
      </ng-container>
<!--      <button class="btn btn-outline-primary my-2" >see more</button>-->
    </div>
    <div class="filter py-3">
      <div class="title">
        <h4>Meal plans available</h4>
      </div>
      <ng-container >
        <mat-checkbox > Breakfast included </mat-checkbox>
        <mat-checkbox > Lunch included </mat-checkbox>
        <mat-checkbox > Dinner included </mat-checkbox>
        <mat-checkbox > All inclusive </mat-checkbox>

      </ng-container>
      <div class="price-slider">
        <div class="title">
          <h4>Set your own budget</h4>
        </div>
        <ng-container >
          <mat-checkbox > EGP 1,000 – EGP 2,000 </mat-checkbox>
          <mat-checkbox > EGP 2,000 – EGP 3,000</mat-checkbox>
          <mat-checkbox > EGP 3,000 – EGP 4,000 </mat-checkbox>
          <mat-checkbox > EGP 4,000 – EGP 5,000 </mat-checkbox>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Distance from center of London</h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Less than 1 km </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>
          <div>
            <mat-checkbox> Less than 3 km </mat-checkbox>
            <span class="text-muted"> 13 </span>
          </div><div>
          <mat-checkbox> Less than 5 km </mat-checkbox>
          <span class="text-muted"> 15 </span>
        </div>

        </ng-container>
        <!--      <button class="btn btn-outline-primary my-2" >see more</button>-->
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Facilities</h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Parking </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Restaurant </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
          <mat-checkbox> Pet friendly </mat-checkbox>
          <span class="text-muted"> 10 </span>
        </div>
          <div>
            <mat-checkbox> Room service </mat-checkbox>
            <span class="text-muted"> 5 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Bed Preference</h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Twin beds </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Double bed </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Queen bed  </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>

      <div class="filter py-3" >
        <div class="title">
          <h4> Meals </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Kitchen facilities </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Breakfast Included </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> All-inclusive </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4> Property Type </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Hotels </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Apartments </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Guesthouses </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4> Landmarks </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Tower of London </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> London Eye </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> London Zoo </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Neighborhood </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> Kitchen/Kitchenette </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Guests' Favorite Area </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Westminster Borough </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Brands </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox> London Premier </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> OYO Rooms </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Hilton Hotels & Resorts </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Property Accessibility </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox>Wheelchair accessible </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Toilet with grab rails </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Raised toilet </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>
      <div class="filter py-3" >
        <div class="title">
          <h4>Room Accessibility </h4>
        </div>
        <ng-container >
          <div>
            <mat-checkbox>Entire unit located on ground floor </mat-checkbox>
            <span class="text-muted"> 2 </span>
          </div>
          <div>
            <mat-checkbox> Upper floors accessible by elevator </mat-checkbox>
            <span class="text-muted">12 </span>
          </div>
          <div>
            <mat-checkbox> Entire unit wheelchair accessible </mat-checkbox>
            <span class="text-muted"> 10 </span>
          </div>

        </ng-container>
      </div>

    </div>
    <button type="button" class="btn btn-outline-primary w-100" >
      Clear All Filters
    </button>
  </div>
</div>
