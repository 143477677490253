import { Component, OnInit } from '@angular/core';
import { HelpCenterServices } from 'src/app/services/help-center.service';
import { SEOService } from '../../services/seo.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApisService } from 'src/app/services/apis.service';
import { FormBuilder } from '@angular/forms';
import { DatePipe, ViewportScroller } from '@angular/common';
import { AppComponent } from "../../app.component";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  offers = [];

  searchForm = this.fb.group({
    destination: [''],
    category_id: ['1'],
    departure_date: [''],
    return_date: [{ value: null, disabled: true }]
  });

  loading = true;
  searchResults = false;
  roundSpinner = false;
  initialDate: Date = new Date();
  today: Date = new Date();
  url: string;

  // pagination
  page = 1;
  pageSize = 10;
  totalSize;

  constructor(
    private apis: ApisService,
    private helpCenterApis: HelpCenterServices,
    private seoService: SEOService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.url = this.activatedRoute.snapshot.url[0].path;
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.searchData) {
        this.getOffersData(params.page);
      } else {
        const searchDataObj = JSON.parse(params.searchData);
        this.viewSearchData(searchDataObj)
        this.searchOffers({ ...searchDataObj }, params.page);
      }
    })
    this.initialDate.setDate(this.today.getDate() + 2);
  }

  viewSearchData(searchData) {
    this.searchForm.get('destination').setValue(searchData.destination);
    this.searchForm.get('category_id').setValue(searchData.category_id);
    this.searchForm.get('departure_date').setValue(searchData.departure_date);
    this.checkValue({ value: true }); // this to enable return_date control
    this.searchForm.get('return_date').setValue(searchData.return_date);
    this.searchForm.markAsDirty();
  }

  seoUpdate() {

    this.seoService.updateTitle('Flyallover offers: Flights , hotels discounts| Fllyallover ');
    this.seoService.updateDescription('"Get the latest flyallover coupons offers and save your money on your vacation. From hotel coupons to flight discount, get the latest deals here!\t"');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/offers/header.png');
  }

  getOffersData(page = 1) {
    this.loading = true;
    this.searchResults = false;
    this.searchForm.reset();
    const params = {
      page,
      paginate: 9
    };
    this.helpCenterApis.getOffersData(params).subscribe(
      (res: any) => {
        this.offers = res.data;
        this.assignPaginationVars(res?.meta.current_page, res?.meta.total, res?.meta.per_page);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  airportSearch = (text$: Observable<string>) => {
    return text$.pipe(
      switchMap(term => {
        if (term.length >= 1) {
          return this.apis.geoAutocomplete_v2(term, 'AIR', null, null).pipe(
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  };

  searchOffers(searchData, page = 1) {
    if (searchData.destination) {
      searchData.destination = searchData.destination.substring(0, 3);
    }
    this.roundSpinner = true;
    this.loading = true;
    this.searchResults = false;
    const params = {
      page: page,
      paginate: 9
    }
    this.helpCenterApis.searchOffers(searchData, params).subscribe((res: any) => {
      this.offers = res.data;
      this.assignPaginationVars(res?.meta.current_page, res?.meta.total, res?.meta.per_page);
      this.searchResults = true;
      this.roundSpinner = false;
      this.loading = false;
    }, err => {
      this.searchResults = false;
      this.roundSpinner = false;
      this.offers = [];
      this.loading = false;
    });
  }

  changePage(page: number) {
    this.scrollToTop();
    this.router.navigate([this.url], { queryParams: { page: page }, queryParamsHandling: 'merge' })
  }

  activateSearch(activateSearch = true) {
    if (this.searchForm.dirty && activateSearch) {
      const data = {
        destination: this.searchForm.value.destination ? this.searchForm.value.destination : '',
        //category_id: this.searchForm.value.category_id ? this.searchForm.value.category_id : '',
        category_id: '1',
        departure_date: this.datePipe.transform(this.searchForm.value.departure_date, 'yyyy-MM-dd'),
        return_date: this.datePipe.transform(this.searchForm.value.return_date, 'yyyy-MM-dd')
      };
      const jsonSearchData = JSON.stringify(data);
      this.router.navigate([this.url], { queryParams: { searchData: jsonSearchData } });
    } else {
      this.router.navigate([this.url], { queryParams: { searchData: null, page: 1 } });
    }
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  assignPaginationVars(currentPage, total, pageSize) {
    // pagination variable assignment
    this.page = currentPage;
    this.totalSize = total;
    this.pageSize = pageSize;
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  checkValue(event) {
    const ctrl = this.searchForm.get('return_date');
    if (event.value) {
      ctrl.enable();
    } else {
      ctrl.disable();
    }
  }
}
