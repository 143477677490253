import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-room',
  templateUrl: './book-room.component.html',
  styleUrls: ['./book-room.component.css']
})
export class BookRoomComponent implements OnInit {
linked() {
throw new Error('Method not implemented.');
}
cop() {
throw new Error('Method not implemented.');
}
hotelDetails: any;
copied: any;

  constructor() { }

  ngOnInit(): void {
  }

}
