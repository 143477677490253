<div class="cheapest-fastest">
  <div class="filters filter-hover" >
    <!-- <i class="fas fa-chevron-down openDetailsBtn" *ngIf="!openDetails"></i>
    <i class="fas fa-chevron-up openDetailsBtn" *ngIf="openDetails"></i> -->
    <div class="row py-4">
      <div class="small-screen-cols">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 border-r" (click)="setOpenDetailsEvent.emit(response)">
          <div class="option row">
            <div class="d-flex align-items-center">
              <div class="title">
                <span class="Font-Family">Cheapest</span>
              </div>
              <div class="price ">
                <span *ngIf="response && response['cheapest'].length > 0">{{response['cheapest'][0]['price'] | currency}}</span>
              </div>
            <ngx-skeleton-loader *ngIf="!response" count="1" [theme]="{height:'15'}"></ngx-skeleton-loader>
            </div>
            <div *ngIf="response && response['cheapest'].length > 0" class="value">
              <div class="time">
                <span class="Font-Family">Total duration : {{timeFormatter(response['cheapest'][0]['time'])}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 border-r" (click)="setOpenDetailsEvent.emit(response)">
          <div class="option row">
            <div class="d-flex align-items-center">
              <div class="title">
                <span class="Font-Family">Fastest</span>
              </div>
              <div class="price">
                <span *ngIf="response && response['fastest'].length > 0">{{timeFormatter(response['fastest'][0]['time'])}}</span>
              </div>
            <ngx-skeleton-loader *ngIf="!response" count="1" [theme]="{height:'15'}"></ngx-skeleton-loader>
            </div>
            <div *ngIf="response && response['fastest'].length > 0" class="value">
              <div class="time">
                <span class="Font-Family"> Total price : {{response['fastest'][0]['price'] | currency}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 " (click)="setOpenDetailsEvent.emit(response)">
          <div class="option row">
            <div class="d-flex align-items-center">
              <div class="title">
                <span class="Font-Family">Recommended</span>
              </div>
              <div class="price">
                <span *ngIf="response && response['recommended'].length > 0">{{response['recommended'][0]['price'] | currency}}</span>
              </div>
            <ngx-skeleton-loader *ngIf="!response" count="1" [theme]="{height:'15'}"></ngx-skeleton-loader>
            </div>
            <div *ngIf="response && response['recommended'].length > 0" class="value">
              <div class="time">
                <span class="Font-Family">Total duration : {{timeFormatter(response['recommended'][0]['time'])}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="tripType!='OpenJaw'"  class="col-lg-2 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center">
          <div class="option" (click)="openAlternativeDates()">
            <button class="btn alternative">Alternative Dates</button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="filters" *ngIf="openDetails" style="margin-top: 15px">
    <div class="details" *ngIf="response">
      <div class="category cheapest">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row">
              <span class="title  color-007bff Font-Family" >Cheapest</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <ng-container *ngFor="let airline of response['cheapest']">
                <div class="item" (click)="selectTagId.emit(airline['TagID'])">
                  <img class="airCompanyLogo"
                       src="https://c.fareportal.com/n/common/air/3x/{{airline['code']}}.png"
                       alt="{{airline['code']}}" title="{{airline['code']}}">
                  <div class="price">
                    <span>{{airline['price'] | currency}}</span>
                  </div>
                  <div class="time ">
                    <span>{{timeFormatter(airline['time'])}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="category fastest">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row">
              <span class="title  color-007bff Font-Family">Fastest</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <ng-container *ngFor="let airline of response['fastest']">
                <div class="item" (click)="selectTagId.emit(airline['TagID'])">
                  <img class="airCompanyLogo"
                       src="https://c.fareportal.com/n/common/air/3x/{{airline['code']}}.png"
                       alt="{{airline['code']}}" title="{{airline['code']}}">
                  <div class="time ">
                    <span>{{timeFormatter(airline['time'])}}</span>
                  </div>
                  <div class="price">
                    <span>{{airline['price'] | currency}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="category recommended">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row">
              <span *ngIf="response && response['recommended'].length > 0" class="title color-007bff Font-Family">Recommended</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row data">
              <ng-container *ngFor="let airline of response['recommended']">
                <div class="item" (click)="selectTagId.emit(airline['TagID'])">
                  <img class="airCompanyLogo"
                       src="https://c.fareportal.com/n/common/air/3x/{{airline['code']}}.png"
                       alt="{{airline['code']}}" title="{{airline['code']}}">
                  <div class="price">
                    <span>{{airline['price'] | currency}}</span>
                  </div>
                  <div class="time">
                    <span>{{timeFormatter(airline['time'])}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="demo-swagger filters mb-3 " *ngIf="!response">
  <ngx-skeleton-loader *ngIf="!response" count="1" [theme]="{height:'300'}"></ngx-skeleton-loader>
  <div class="w-75 d-flex overflow-hidden">
    <ng-container *ngFor="let item of airLines; let i = index">
      <div class="animated-css bounceInRight" *ngIf="randomMin<i<airLines.length">
        <img class="" src="{{item['url']}}">
      </div>
    </ng-container>
  </div>
  <ngx-skeleton-loader *ngIf="!response" count="1" [theme]="{height:'300'}"></ngx-skeleton-loader>
</div> -->
