<div class="custom-container">
  <div class="col-3">
    <div class="row align-items-center justify-content-between align-items-center">
      <span>You will be redirected to search page</span>
      <div class="btn-groups">
        <button class="btn button-light" [mat-dialog-close]="true">Ok</button>
      </div>
    </div>
  </div>
</div>
