<app-navbar></app-navbar>

<div class="container mt-5 mb-4">
  <app-engine></app-engine>
  <div class="search-box">
    <input type="text" [(ngModel)]="name" placeholder="Search here..."/>
    <div class="select-box">
      <mat-form-field appearance="none" style="margin-bottom: -27px;" >
          <mat-label  >Select Country</mat-label>
          <mat-select  [(value)]="country_id">
            <mat-option > select country</mat-option>
              <mat-option (click)="selectCountries()" *ngFor="let item of countries" [value]="item.id">

                  {{item.name}}
              </mat-option>
          </mat-select>
      </mat-form-field>
  </div>
    <button class="search" (click)="search_Popular()" type="submit"><i class="fa fa-search"></i></button>

    <!--    <button>-->
    <!--    <img-->
    <!--      title="Search about trip"-->
    <!--      alt="search"-->
    <!--      src="./assets/images/blog/search.svg"-->
    <!--      width="30"-->
    <!--    />-->
    <!--  </button>-->
  </div>

  <div class="engine">
    <h1 class="Font-family">Popular Destination</h1>
    <h1 style="font-size: small">Cheap Flight Tickets</h1>
  </div>
</div>
<!-- .............................................................................................................................. -->
<!-- New Cards popular -->
<!-- .............................................................................................................................. -->
<div class="container">
  <div class="row">
    <ng-container *ngFor="let item of data; let i = index">
      <div class="col col-12 col-md-6 col-lg-4 p-16px">
        <div class="popular card-body">
          <div class="things-img">
            <a [routerLink]="['../popular-destinations/', item.city?.name ,item.slug]">
              <img
                src="{{  item.image }}"
                alt="{{ item.title_tag }}"
                title="{{ item.city?.name }}"
                class="rounded w-100 p-3"
                height="250px"
              />
              <div class="name">
                <h4 class="fao_mobile_title_med">
                  {{ item.city?.name  }}
                </h4>
              </div>
            </a>
          </div>
          <div
            style="
            background-color: #ddd4d4;
            height: 1px;
            width: 95%;
            margin: auto;
          "
          ></div>
          <div>
            <p class="fao_mobile_prgraph">
              {{ item.about | slice:0:155 }} ....
            </p>

            <div class="center-class">
              <button class="fao_des_btn_med mb-4" [routerLink]="['../popular-destinations/', item.city?.name,item.slug]">
                MORE DETAILS
              </button>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
    <!-- <ngb-pagination class="d-flex justify-content-center my-5 py-5" [collectionSize]="collectionSize"
                    (pageChange)="getPopularDestination($event)" [(page)]="page" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination> -->
    <ngb-pagination class="d-flex justify-content-center pagination  my-5 py-5" [collectionSize]="collectionSize"
                (pageChange)="getPopularDestination(page)" [(page)]="page" aria-label="Custom pagination">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
</ngb-pagination>
  </div>
</div>


<app-footer></app-footer>
