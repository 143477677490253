import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-hotel-slider',
  templateUrl: './hotel-slider.component.html',
  styleUrls: ['./hotel-slider.component.css']
})
export class HotelSliderComponent implements OnInit {
@Input() images:any;
  constructor() { }


  ngOnInit(): void {
    
  }

}
