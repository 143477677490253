<div class="content-details d-flex col-12">
  <div class="left-content-details d-flex col-3">
    <div class="col-3 p-0 m-0">
      <img src="https://c.fareportal.com/n/common/air/3x/{{flight.OperatingAirline}}.png"
        alt="{{flight.OperatingAirline}}">
    </div>
    <div class="col-9">
      <small>{{flight.OperatingAirlineName}}</small>
      <small>Aircraft {{flight?.AircraftCode}} | Flight {{flight.FlightNumber}}</small>
      <small >Flight Duration <p class="w-100 mx-4" style="font-size: 1em;">{{convertedElapsedTime}}</p> </small>
    </div>
  </div>
  <div class="center-content-details d-flex col-7">
    <div class="col-3 text-center">
      <p>{{flight.DepartureDateTime | date: 'EEE , MMM d'}}</p>
    </div>
    <div class="center-center-content col-6 row">
      <div class="col-12">
        <div class="col-5 ">
          <span>{{flight.DepartureDateTime | date: "h:mm a"}}</span>
        </div>
        <div class="col-2 dot">:</div>
        <div class="col-5 ">
          <span>{{flight.ArrivalDateTime | date: "h:mm a"}}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="col-5 ">
          <p>{{flight.DepartureAirport}}</p>
        </div>
        <div class="col-2"></div>
        <div class="col-5 ">
          <p>{{flight.ArrivalAirport}}</p>
        </div>
      </div>
    </div>
    <div class="col-3 text-center">
      <p>{{flight.ArrivalDateTime| date: 'EEE , MMM d'}}</p>
      <span style="color: #f00;font-size: small;" *ngIf="date(flight.DepartureDateTime , flight.ArrivalDateTime)">(next day)</span>
    </div>
  </div>
  <div class="right-content-details col-2 text-center" *ngIf="counter==0">
    <p>cabin : <span>{{itinerary.prices.FareClassName}}</span></p>
    <!-- <div class="d-flex justify-content-start" >
      <a class="otherFont fs-.1 fw-lighter cursor-pointer" [routerLink]="['/Bagging-Information/',flight.OperatingAirlineName]">
        <img title="Baggage Allowance" src="assets/icons/icons/baggage-allowance.png" style="width: 30px">
      </a>
      <a class="otherFont fs-.1 mx-1 cursor-pointer"
        style="border: 1px solid #ddd;border-top: 0; border-bottom: 0;padding: 0px 10px;"
        [routerLink]="['/Online-Check/',flight.OperatingAirlineName]">
        <img title="Online Check" src="assets/icons/icons/Online%20Check.png" style="width: 20px">
      </a>
      <a class="otherFont fs-.1 cursor-pointer" [routerLink]="['/FAQ/',flight.OperatingAirlineName]">
        <img title="FAQ" src="assets/icons/icons/faq.png" style="width: 20px">
      </a>
    </div> -->
  </div>
  <!-- <svg viewBox="0 0 24 24" class="sv undefined sv_baggage1 "><use class="sv-icon" xlink:href="#sv_baggage1"></use></svg>

  <svg viewBox="0 0 24 24" class="sv undefined sv_baggage "><use class="sv-icon" xlink:href="#sv_baggage"></use></svg>

  <svg viewBox="0 0 24 24" class="sv undefined sv_checkedbag "><use class="sv-icon" xlink:href="#sv_checkedbag"></use></svg> -->
  <!-- <div class="right-content-details col-2 text-center">
    <p>cabin: <span>coach</span></p>
    <div class="col-12 plt">
      <a [routerLink]="['/Bagging-Information/',flight.OperatingAirlineName]">
        <img title="Baggage Allowance" src="assets/icons/icons/baggage-allowance.png" >
      </a>
      <a class=" mx-1"
         [routerLink]="['/Online-Check/',flight.OperatingAirlineName]">
        <img title="Online Check" src="assets\icons\icons\Online Check.png">
      </a>
      <a [routerLink]="['/FAQ/',flight.OperatingAirlineName]">
        <img title="FAQ" src="assets\icons\icons\faq.png">
      </a>
    </div>
  </div> -->
</div>
<!-- <div class="col-12 mt-3">
  <div class="row">
    <div class="col-4 d-flex justify-content-center">
      <span class="entirtainment" *ngIf="itinerary.BrandFeatures">
        <span class="dropdown " *ngIf="itinerary.BrandFeatures"
          style="border-right: 1px solid #ddd4d4;padding-right: 10px;margin-right: 4px;">
          <img src="../../../assets/images/icons/wifi.jpg" width="15" height="15" class="entrtainImage ">
          <br>
          <span class="dropdown-content  otherFont">
            <small>{{itinerary.BrandFeatures.IE[0].commercialName}}</small><br>
            <small *ngIf="itinerary.BrandFeatures.IE[0].application=='C'">Chargable</small>
            <small *ngIf="itinerary.BrandFeatures.IE[0].application!='C'">Free</small></span></span>
        <span class="dropdown " *ngIf="itinerary.BrandFeatures.IE[2]">
          <img src="../../../assets/images/icons/commercial.png" width="15" height="15" class="entrtainImage">
          <span class="dropdown-content  otherFont">
            <small>{{itinerary.BrandFeatures.IE[2].commercialName}}</small><br>
            <small *ngIf="itinerary.BrandFeatures.IE[2].application=='C'">Chargable</small>
            <small *ngIf="itinerary.BrandFeatures.IE[2].application!='C'">Free</small></span></span>
        <span class="dropdown " *ngIf="itinerary.BrandFeatures.ML[0]">
          <img src="../../../assets/images/meal.jfif" width="15" height="15" class="entrtainImage">
          <span class="dropdown-content otherFont">
            <small>{{itinerary.BrandFeatures.ML[0].commercialName}}</small><br>
            <small *ngIf="itinerary.BrandFeatures.ML[0].application=='C'">Chargable</small>
            <small *ngIf="itinerary.BrandFeatures.ML[0].application!='C'">Free</small></span></span>
      </span>
    </div>
    <div class="col-6">
      <div class="d-flex h-100 justify-content-end">
        <div style="border-right: 1px solid #ddd;padding-right: 10px;margin-right: 4px;">
          <img width="20" height="20" style="margin-right: 2px;" src="../../../assets/icons/bags.svg">
          <span *ngIf="flightType=='Depart'" class="otherFont flightType">{{itinerary.flights[0][0]?.allow.Pieces}}
            Free Bags</span>
          <span *ngIf="flightType!=='Depart'" class="otherFont flightType">{{itinerary.flights[1][0]?.allow.Pieces}}
            Free Bags</span>
        </div>
        <div class="">
          <img title="Baggage Allowance" src="assets/icons/icons/baggage-allowance.png" style="width: 36px">
        </div>
        <span class="dropdown "><span class="clickable cursoer-pointer otherFont" *ngIf="itinerary.BrandFeatures.BG">Bagging
            Allowence</span>
          <span class="dropdown-content" *ngIf="itinerary.BrandFeatures.BG">
            <span *ngFor="let item of bagging ;let i=index">
              <small>{{item.commercialName}}</small>
              <small *ngIf="item.application=='C'">CHARGABLE</small>
              <small *ngIf="item.application!='C'">&nbsp;FREE</small> <br>
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="custom-container row">
  <div class="col-3">
    <div style="height: 100%" class="d-flex justify-content-between flex-column ">
      <div class="d-flex">
        <div class="company">
          <img title={{flight.OperatingAirlineName}} class="airCompanyLogo"
               src="https://c.fareportal.com/n/common/air/3x/{{flight.OperatingAirline}}.png"
               alt="{{flight.OperatingAirline}}"><br>
        </div>
        <span class="otherFont airlineInfo">
          <small class="otherFont bold mb-3 d-block work-break"
                 *ngIf="flight.OperatingAirlineName!='Alitalia'"> {{flight.OperatingAirlineName}}</small>
          <small class="otherFont bold" *ngIf="flight.OperatingAirlineName=='Alitalia'">ITA</small>
          <small class="otherFont bold d-block mt-2">{{convertedElapsedTime}}</small>
        </span>
      </div>
      <small class="otherFont d-block aircra">Aircraft {{flight?.AircraftCode}} | Flight {{flight.FlightNumber}}</small>
    </div>
  </div>
  <div class="col-5 flightTimeDiv">
    <span class="flightTime d-flex flex-column ">
      <span class="bold otherFont timeFont margin-6px">{{flight.DepartureDateTime | date: "h:mm a"
        }}</span>
      <span class="otherFont text-start clickable mb-1 mt-1" style="font-size: 18px"
            [routerLink]="['/airport-description/',flight.DepartureAirport]">{{flight.DepartureAirport}}</span>
      <span *ngIf="flightType=='Depart'" class="flightTime otherFont "
            style="font-size: 18px">{{flight.DepartureDateTime|
        date: 'EEE , MMM d'}}</span>
      <span *ngIf="flightType!=='Depart'" class="flightTime otherFont  "
            style="font-size: 18px">{{flight.DepartureDateTime |
        date: 'EEE , MMM d'}}</span>
    </span>
    <div class="d-flex flex-column">
      <span
        class="bold flightTime otherFont position-relative timeFont d-flex margin-6px">{{flight.ArrivalDateTime | date: "h:mm a"}}
        <pre class=" d-block"
             *ngIf="convertDateToMillisecond(flight.ArrivalDateTime) > convertDateToMillisecond(flight.DepartureDateTime)">(next day)</pre></span>
      <span class="otherFont dest text-start clickable mt-1 mb-1" style="font-size: 18px"
            [routerLink]="['/airport-description/',flight.ArrivalAirport]">{{flight.ArrivalAirport}}</span>
      <span *ngIf="flightType=='Depart'" class="flightTime otherFont text-start "
            style="font-size: 18px">{{flight.ArrivalDateTime|
        date: 'EEE , MMM d'}}</span>
      <span *ngIf="flightType!=='Depart'" class="flightTime otherFont  text-start "
            style="font-size: 18px">{{flight.ArrivalDateTime |
        date: 'EEE , MMM d'}}</span>
    </div>

  </div>
  <div class="col-4 latest otherFont">
    <div>
      <span class="airportName otherFont" [routerLink]="['/airport-description/',flight.DepartureAirportName]">{{flight.DepartureAirportName}}</span>
           <span *ngIf="flightType=='Depart' && counter==0 " class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',itinerary.flights[0][0]['DepartureAirport']]">{{itinerary.flights[0][0]['DepartureAirportName']}}</span>
           <span *ngIf="flightType=='Return' && counter==0" class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',flight.DepartureAirport]">{{flight.DepartureAirportName}}</span>
           <span *ngIf="flightType=='Depart' && counter==1 " class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',itinerary.flights[0][1]['ArrivalAirport']]">{{itinerary.flights[0][1]['ArrivalAirportName']}}</span>
           <span *ngIf="flightType=='Return' && counter==1" class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',itinerary.flights[1][1]['ArrivalAirport']]">{{itinerary.flights[1][1]['ArrivalAirportName']}}</span>
           <span *ngIf="flightType=='Depart' && counter==2" class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',flight.ArrivalAirport]">{{flight.ArrivalAirportName}}</span>
           <span *ngIf="flightType=='Return' && counter==2" class="airportName otherFont aircra clickable"
                 [routerLink]="['/airport-description/',flight.ArrivalAirport]">{{flight.ArrivalAirportName}}</span>
      <div>
               <ng-container *ngIf="flight?.DepartureAirportTerminal">
                 <span class="otherFont">Terminal {{flight?.DepartureAirportTerminal}}</span>
               </ng-container>
        <div *ngIf="flight?.FareBookingCode">
          <span class="fareClassName otherFont d-block"
                style="margin-bottom: 9px;margin-top: -4px;">{{itinerary?.prices.FareClassName}}</span>
          <span class="otherFont">Class - {{flight?.FareBookingCode[0]}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="counter!=0">
      <button mat-button class="checkButton btn mb-1" (click)="openDialog()">
        Check Requirements
      </button>
    </div>
  </div>
  <div class="col-12 mt-3" style="border: 1px solid #ddd;padding: 20px 0px;border-radius: 5px;">
    <div class="row">
      <div class="col-4" style="margin-left: -6px">
        <div class="text-center d-flex justify-content-start" *ngIf="counter==0">
          <a class="otherFont fs-.1 fw-lighter" [routerLink]="['/Bagging-Information/',flight.OperatingAirlineName]">
            <img title="Baggage Allowance" src="assets/icons/icons/baggage-allowance.png" style="width: 49px">
          </a>
          <a class="otherFont fs-.1 mx-1"
             style="border: 1px solid #ddd;border-top: 0; border-bottom: 0;padding: 0px 10px;"
             [routerLink]="['/Online-Check/',flight.OperatingAirlineName]">
            <img title="Online Check" src="assets/icons/icons/Online%20Check.png" style="width: 30px">
          </a>
          <a class="otherFont fs-.1" [routerLink]="['/FAQ/',flight.OperatingAirlineName]">
            <img title="FAQ" src="assets/icons/icons/faq.png" style="width: 30px">
          </a>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-center">
         <span class="entirtainment" *ngIf="itinerary.BrandFeatures.IE">
              <span class="dropdown " *ngIf="itinerary.BrandFeatures.IE[0]"
                    style="border-right: 1px solid #ddd4d4;padding-right: 10px;margin-right: 4px;">
                <img src="../../../assets/images/icons/wifi.jpg" width="15" height="15" class="entrtainImage "
                >
                <br>
                <span class="dropdown-content  otherFont">
                  <small>{{itinerary.BrandFeatures.IE[0].commercialName}}</small><br>
                <small *ngIf="itinerary.BrandFeatures.IE[0].application=='C'">Chargable</small>
                <small *ngIf="itinerary.BrandFeatures.IE[0].application!='C'">Free</small></span></span>
                <span class="dropdown " *ngIf="itinerary.BrandFeatures.IE[2]">
                  <img src="../../../assets/images/icons/commercial.png" width="15" height="15" class="entrtainImage">
                  <span class="dropdown-content  otherFont">
                    <small>{{itinerary.BrandFeatures.IE[2].commercialName}}</small><br>
                <small *ngIf="itinerary.BrandFeatures.IE[2].application=='C'">Chargable</small>
                <small *ngIf="itinerary.BrandFeatures.IE[2].application!='C'">Free</small></span></span>
                <span class="dropdown " *ngIf="itinerary.BrandFeatures.ML[0]">
                  <img src="../../../assets/images/meal.jfif" width="15" height="15" class="entrtainImage">
                  <span class="dropdown-content otherFont">
                    <small>{{itinerary.BrandFeatures.ML[0].commercialName}}</small><br>
                <small *ngIf="itinerary.BrandFeatures.ML[0].application=='C'">Chargable</small>
                <small *ngIf="itinerary.BrandFeatures.ML[0].application!='C'">Free</small></span></span>
            </span>
      </div>
      <div class="col-4">
        <div class="d-flex h-100">
          <div class="" style="border-right: 1px solid #ddd;padding-right: 10px;margin-right: 4px;">
            <img width="20" height="20" src="../../../assets/icons/bags.svg">
            <span *ngIf="flightType=='Depart'" class="otherFont flightType">{{itinerary.flights[0][0]?.allow.Pieces}}
              Free Bags</span>
            <span *ngIf="flightType!=='Depart'"
                  class="otherFont flightType">{{itinerary.flights[1][0]?.allow.Pieces}}
              Free Bags</span>
          </div>
          <div class="">
            <img title="Baggage Allowance" src="assets/icons/icons/baggage-allowance.png" style="width: 36px">
          </div>
          <span class="dropdown "><span class="clickable cursoer-pointer" *ngIf="itinerary.BrandFeatures.BG">Bagging Allowence</span>
      <span class="dropdown-content" *ngIf="itinerary.BrandFeatures.BG">
         <span *ngFor="let item of bagging ;let i=index">
         <small>{{item.commercialName}}</small>
         <small *ngIf="item.application=='C'">CHARGABLE</small>
        <small *ngIf="item.application!='C'">&nbsp;FREE</small> <br>
      </span>
    </span>
    </span>
        </div>
      </div>
    </div>
  </div>
</div> -->
