<div class="customContainer">
  <div class="view-filters-container onlyOnMobile">
    <button class="btn fare-alert-btn w-50" (click)="showFilters()">
      View Filters
    </button>
  </div>
  <div class="filters">
    <div class="category-title">Filter by <span *ngIf="searchResults?.pages.total_item"
        class="items-number">{{searchResults?.pages.total_item}}</span></div>

    <!-- ////////////////////////////////////////////////////////////////////////// -->
    <div class="filter mb-3">
      <div class="title">
        <h4>Stops</h4>
      </div>
      <ng-container *ngIf="!airlines">
        <ngx-skeleton-loader [theme]="{ height: '70px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="airlines">
        <ng-container *ngFor="let stop of stops ;let i=index">
          <mat-checkbox [checked]="value === false" (click)="value = true" (change)="setStopsValue(i,$event)">{{stop}}
          </mat-checkbox>
        </ng-container>
        <!-- <mat-checkbox [checked]="value === false" (click)="value = true" (change)="setStopsValue('1',$event)">1 Stop
        </mat-checkbox>
        <mat-checkbox [checked]="value === false" (click)="value = true" (change)="setStopsValue('2',$event)">2 Stops
        </mat-checkbox> -->
        <!-- <mat-radio-group (change)="setStopsValue($event)">
          <mat-radio-button class="example-margin" value="0">Non Stop</mat-radio-button>
          <mat-radio-button class="example-margin" value="1">1 Stop</mat-radio-button>
          <mat-radio-button class="example-margin" value="2">2 Stops</mat-radio-button>
        </mat-radio-group> -->
      </ng-container>
      <!-- <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          name="nostop"
          [checked]="value === 'nonStop'"
          (click)="value = 'nonStop'"
          id="defaultCheck1"
          (change)="setStopsValue('0', $event)"
        />

        <label class="form-check-label" for="defaultCheck1"> Non stop </label>
      </div>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          name="stop"
          [checked]="value === 'oneStop'"
          (click)="value = 'oneStop'"
          id="defaultCheck1"
          (change)="setStopsValue('1', $event)"
        />
        <label class="form-check-label" for="defaultCheck1"> 1 stop </label>
      </div>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          name="stop"
          [checked]="value === 'twoStop'"
          (click)="value = 'twoStop'"
          id="defaultCheck1"
          (change)="setStopsValue('2', $event)"
        />
        <label class="form-check-label" for="defaultCheck1"> 2 stops </label>
      </div> -->
    </div>
    <!-- ///////////////////////////////////////////////////////////////// -->

    <div class="filter py-2">
      <div class="title">
        <h4>Bags </h4>
      </div>
      <ng-container *ngIf="bagsNumber.length <= 0">
        <ngx-skeleton-loader [theme]="{ height: '70px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="bagsNumber.length > 0">

        <ng-container *ngFor="let bag of bagsNumber ; let i = index">
          <mat-checkbox *ngIf="bag==1" [checked]="value === false" (click)="value = true"
            (change)="setBagsNumber(bag, $event)">{{ bag }} Bag
          </mat-checkbox>
          <mat-checkbox *ngIf="bag!=1" [checked]="value === false" (click)="value = true"
            (change)="setBagsNumber(bag, $event)">{{ bag }} Bags
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>

    <!-- ///////////////////////////////////////////////////////////////// -->

    <div class="filter py-3">
      <div class="title">
        <h4>Price</h4>
      </div>
      <ng-container *ngIf="!PriceSliderValue">
        <ngx-skeleton-loader [theme]="{ height: '70px' }"></ngx-skeleton-loader>
      </ng-container>
      <div *ngIf="PriceSliderValue">
        <ng5-slider *ngIf="display" [(value)]="PriceSliderValue" [(highValue)]="PriceSliderHighValue"
          [options]="PriceSliderOptions" (userChangeEnd)="setPriceValue($event)">
        </ng5-slider>
      </div>
    </div>
    <!--///////////////////////////////////////////////////////////////// -->
    <div class="filter py-3">
      <div class="title">
        <h4>Flight Times</h4>
      </div>
      <ng-container *ngIf="!departureTimeValue">
        <ngx-skeleton-loader [theme]="{ height: '110px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="departureTimeValue">
        <h6>
          Going :
          <span style="font-size: 15px">{{ fullDest.fullDestination }}</span>
        </h6>
        <h6>
          Depart Time :
          <span style="font-size: 15px">{{ msToTime(departureTimeValue) }} -
            {{ msToTime(departureTimeSliderHighValue) }}</span>
        </h6>
        <div>
          <ng5-slider *ngIf="display" [(value)]="departureTimeValue" [(highValue)]="departureTimeSliderHighValue"
            (userChangeEnd)="setGoingDepartTime($event)" [options]="departureTimeSliderOptions"></ng5-slider>
        </div>
        <div *ngIf="goingArrivalTimeSliderValue">
          <p class="pb-3">
            Arrive :
            <span style="font-size: 15px">
              {{ msToTime(goingArrivalTimeSliderValue) }} -
              {{ msToTime(goingArrivalTimeSliderHighValue) }}</span>
          </p>
          <ng5-slider *ngIf="display" [(value)]="goingArrivalTimeSliderValue"
            [(highValue)]="goingArrivalTimeSliderHighValue" (userChangeEnd)="setArrivalTime($event)"
            [options]="goingArrivalTimeSliderOptions"></ng5-slider>
        </div>
        <div class="filter py-3">
          <i *ngIf="!filterValues" class="fas fa-spinner fa-spin moreFilterSpinner"></i>
          <div class="title d-flex justify-content-between " (click)="moreFilters =! moreFilters" *ngIf="filterValues">
            <p class="more-filters m-0">{{!moreFilters ? 'Show':'Hide'}} Arrival Times</p>
            <i class="fas fa-solid my-auto" [class]="moreFilters ?  'fa-chevron-up':'fa-chevron-down'   "></i>
          </div>
          <ng-container *ngIf="moreFilters">
            <h6>
              Returning :
              <span style="font-size: 15px">{{ fullDest.fullDestination }}</span>
            </h6>
            <h6>
              Depart Time :
              <span style="font-size: 15px">{{ msToTime(returnTripMinValue) }} -
                {{ msToTime(returnTripMaxValue) }}</span>
            </h6>
            <div *ngIf="returnTripMinValue">
              <ng5-slider *ngIf="display" [(value)]="returnTripMinValue" [(highValue)]="returnTripMaxValue"
                (userChangeEnd)="setDepartArriveTime($event)" [options]="returnTripOption"></ng5-slider>
            </div>
            <h6>
              Arrive :
              <span style="font-size: 15px">{{ msToTime(returnArriveTripMinValue) }} -
                {{ msToTime(returnArriveTripMaxValue) }}</span>
            </h6>
            <div *ngIf="returnArriveTripMaxValue">
              <ng5-slider *ngIf="display" [(value)]="returnArriveTripMinValue" [(highValue)]="returnArriveTripMaxValue"
                (userChangeEnd)="setReturnArriveTime($event)" [options]="returnArriveTripOption"></ng5-slider>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="filter py-3">
      <div class="title">
        <h4>Duration</h4>
      </div>
      <ng-container *ngIf="!departureTimeValue">
        <ngx-skeleton-loader [theme]="{ height: '110px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="durationTimeSliderValue">
        <h5>
          Going :
          <span style="font-size: 15px">{{ fullDest.fullDestination }}</span>
        </h5>
        <h6>
          <span style="font-size: 15px">Layover Duration: up to
            {{ hoursFormat(durationTimeSliderHighValue) }}</span>
        </h6>
        <ng5-slider *ngIf="display" [(value)]="durationTimeSliderHighValue" [options]="durationTimeSliderOptions"
          (userChangeEnd)="setGoingLayover($event)"></ng5-slider>
        <ng-container *ngIf="durationReturnTimeSliderValue">
          <h5>
            Return :
            <span style="font-size: 15px">{{ fullDest.fullDestination }}</span>
          </h5>
          <h6>
            <span style="font-size: 15px">Layover Duration: up to
              {{ hoursFormat(durationReturnTimeSliderHighValue) }}</span>
          </h6>
          <ng5-slider *ngIf="display" [(value)]="durationReturnTimeSliderHighValue"
            [options]="durationReturnTimeSliderOptions" (userChangeEnd)="setReturnLayover($event)"></ng5-slider>
        </ng-container>
      </ng-container>
    </div>
    <div class="filter d-none py-3">

      <div class="title d-flex justify-content-between ">
        <h4>Sort BY</h4>
        <button class="btn btn-outline-primary" (click)="clearSort()">Clear</button>
      </div>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedValue">
        <mat-radio-button (click)="setSortTypeValue('cheapest')" value="cheapest">
          Cheapest
        </mat-radio-button>
        <mat-radio-button (click)="setSortTypeValue('fastest')" value="fastest">
          Fastest
        </mat-radio-button>
        <mat-radio-button (click)="setSortTypeValue('recommended')" value="recommended">
          Recommended
        </mat-radio-button>
        <mat-radio-button (click)="setSortTypeValue('ElapsedTime')" value="ElapsedTime">
          Elapsed Time
        </mat-radio-button>
        <mat-radio-button (change)="setSortTypeValue('DepartureTime')" value="DepartureTime">
          Departure Time
        </mat-radio-button>
        <mat-radio-button (change)="setSortTypeValue('ConnectionTime')" value="ConnectionTime">
          Connection Time
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="moreFilters && filterValues">
      <div class="filter py-3"></div>
    </ng-container>
    <div class="filter py-3">
      <div class="title">
        <h4>Stop At</h4>
      </div>
      <ng-container *ngIf="!airlineStops">
        <ngx-skeleton-loader [theme]="{ height: '100px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="airlineStops">
        <ng-container *ngFor="let airline of airlineStops | keyvalue; let i = index">
          <mat-checkbox *ngIf="!stopFlag && i<5" [checked]="value === false" (click)="value = true"
            (change)="setStopsAtlineValue(airline.key, $event)">{{ airline.value }}</mat-checkbox>
          <!-- <a *ngIf="!stopFlag && i==4" (click)="changeStopFlag()">show all stops</a> -->
        </ng-container>

        <div *ngIf="stopFlag">
          <ng-container *ngFor="let airline of airlineStops | keyvalue; let i = index">
            <mat-checkbox [checked]="value === false" (click)="value = true"
              (change)="setStopsAtlineValue(airline.key, $event)">{{ airline.value }}</mat-checkbox>
          </ng-container>
        </div>
        <button class="btn btn-outline-primary my-2" (click)="changeStopFlag()">show {{!stopFlag ? 'all' : 'less'}}
          stops</button>
      </ng-container>
    </div>
    <div class="filter py-3">
      <div class="title">
        <h4>Airlines</h4>
      </div>
      <ng-container *ngIf="!airlines">
        <ngx-skeleton-loader [theme]="{ height: '100px' }"></ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="airlines">
        <!-- <div class="btn-AirLine pb-4 py-2">
        <a class="btn button active" role="button">By Airline</a>
      </div> -->
        <ng-container *ngFor="let airline of airlines | keyvalue; let i = index">
          <mat-checkbox *ngIf="i<length" class="checkAirline" (change)="setAirlineValue($event, airline.key)">{{
            airline.value }}</mat-checkbox>
        </ng-container>
        <!-- <a *ngIf="!airlineFlag" (click)="changeAirFlag(airlines)">show all airlines</a>
        <a *ngIf="airlineFlag" (click)="changeAirFlag(airlines)">show less airlines</a> -->
        <button class="btn btn-outline-primary my-2" (click)="changeAirFlag(airlines)">show {{!airlineFlag ? 'all' :
          'less'}} airlines</button>
      </ng-container>

      <!-- <div *ngIf="airlineFlag">
      <ng-container *ngFor="let airline of airlines | keyvalue; let i = index">
        <mat-checkbox
          [checked]="value === false"
          (click)="value = true"
          (change)="setAirlineValue($event, airline.key)"
          >{{ airline.value }}</mat-checkbox>

      </ng-container>
     <a *ngIf="airlineFlag" (click)="changeAirFlag(airlines)">show less airlines</a>
    </div> -->
    </div>
    <button *ngIf="!loadingFilter" type="button" class="btn btn-outline-danger w-100" (click)="clearFilters()">
      Clear All Filters
    </button>
  </div>
</div>