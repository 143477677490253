import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-room',
  templateUrl: './price-room.component.html',
  styleUrls: ['./price-room.component.css']
})
export class PriceRoomComponent implements OnInit {

hotelDetails: any;
copied: any;

  constructor() { }

  ngOnInit(): void {
  }

}
