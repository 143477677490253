<app-navbar></app-navbar>
<div class="container">
  <div class="table-bordered">
    <div class="row">
      <div class="col-md-1">
        <h3>Airlines</h3>
      </div>
      <div class="col-md-2">
        <h3>Destinations</h3>
      </div>
      <div class="col-md-3">
        <h3>CarryOn</h3>
      </div>
      <div class="col-md-2">
        <h3>1st Bag</h3>
      </div>
      <div class="col-md-2">
        <h3>2nd Bag</h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">Additional Policy</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img
            src="https://c.fareportal.com/n/common/air/3x/AN.png"
            alt="Advanced Air"
            title="Advanced Air"
          />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h1><p>Anywhere to Anywhere</p></h1>
      </div>
      <div class="col-md-3">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img
            src="https://c.fareportal.com/n/common/air/3x/AN.png"
            alt="Advanced Air"
            title="Advanced Air"
          />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img src="https://c.fareportal.com/n/common/air/3x/A3.png" />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <div class="items">
            <h4>
              <b> Cost: </b>
              Free
            </h4>
            <h4>
              <b> Weight: </b>
              8 kg / 17 lb
            </h4>
            <h4>
              <b> Size: </b>
              48" (22" x 17" x 9") or 126 cm (56 x 45 x 25)
            </h4>
            <h4>
              <b> Notes: </b>
              N/A
            </h4>
          </div>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img src="https://c.fareportal.com/n/common/air/3x/EI.png" />
          <span>Aer Lingus</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img src="https://c.fareportal.com/n/common/air/3x/A3.png" />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <div class="items">
            <h4>
              <b> Cost: </b>
              Free
            </h4>
            <h4>
              <b> Weight: </b>
              8 kg / 17 lb
            </h4>
            <h4>
              <b> Size: </b>
              48" (22" x 17" x 9") or 126 cm (56 x 45 x 25)
            </h4>
            <h4>
              <b> Notes: </b>
              N/A
            </h4>
          </div>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img src="https://c.fareportal.com/n/common/air/3x/AN.png" />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <h3>
          <img src="https://c.fareportal.com/n/common/air/3x/A3.png" />
          <span>Advanced Air</span>
        </h3>
      </div>
      <div class="col-md-2">
        <h3><p>Anywhere to Anywhere</p></h3>
      </div>
      <div class="col-md-3">
        <h3>
          <div class="items">
            <h4>
              <b> Cost: </b>
              Free
            </h4>
            <h4>
              <b> Weight: </b>
              8 kg / 17 lb
            </h4>
            <h4>
              <b> Size: </b>
              48" (22" x 17" x 9") or 126 cm (56 x 45 x 25)
            </h4>
            <h4>
              <b> Notes: </b>
              N/A
            </h4>
          </div>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3>
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
      <div class="col-md-2">
        <h3 class="border-right-0">
          <a href="https://www.advancedairlines.com/baggage">View policy</a>
        </h3>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
