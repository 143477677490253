<app-navbar></app-navbar>
<div class="container">
  <!-- search-Engine  -->
  <div class="row afterNav">
    <div class="col-12 pt-5">
      <app-engine></app-engine>
    </div>
  </div>

  <!-- city-name -->
  <div class="row g-0 mainRow headline add">
    <div class="col-12">
      <div class="flexDiv" style="justify-content: space-between">
        <div class="flexDiv" class="pt-3"><h2>&nbsp; City Name</h2></div>
      </div>
    </div>
  </div>

  <div class="row g-0 mainRow">
    <div class="col-12">
      <app-read-more
        [text]="about"
        maxLength="43"
        class="aboutText"
      ></app-read-more>
    </div>
  </div>

  <div class="row g-0">
    <!-- ======= City Details Section ======= -->
    <section id="portfolio-details" class="portfolio-details">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-8">
            <div class="portfolio-details-slider swiper">
              <div class="row g-0">
                <div class="col-12 g-0 mainRow">
                  <ngb-carousel>
                    <ng-template ngbSlide>
                      <div class="picsum-img-wrapper">
                        <img
                          src="../../../assets/images/city-details/city-4.png"
                          alt="Random first slide"
                          title="first slide"
                          width="100%"
                          height="540"
                          style="border-radius: 12px"
                        />
                      </div>
                      <div class="carousel-caption"></div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="picsum-img-wrapper">
                        <img
                          src="../../../assets/images/city-details/city-5.png"
                          alt="Random second slide"
                          title="second slide"
                          width="100%"
                          height="540"
                          style="border-radius: 12px"
                        />
                      </div>
                      <div class="carousel-caption"></div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="picsum-img-wrapper">
                        <img
                          src="../../../assets/images/city-details/aircity.png"
                          alt="Random third slide"
                          width="100%"
                          height="540"
                          style="border-radius: 12px"
                        />
                      </div>
                      <div class="carousel-caption"></div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="portfolio-info">
              <h1>City Detailes</h1>
              <ul>
                <li><strong>Al Kara</strong>: Asia</li>
                <li><strong>Country</strong>: Delhi</li>
                <li><strong>Travel Date</strong>: 01 March, 2020</li>
                <li>
                  <strong>CityMap URL</strong>: <a href="#">www.example.com</a>
                </li>
              </ul>
            </div>
            <div class="portfolio-description mx-2 pt-2 readMore">
              <h2>City Info</h2>
              <app-read-more
                [text]="about"
                maxLength="43"
                class="aboutText"
              ></app-read-more>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Obcaecati, adipisci!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= More Citys Section ======= -->
    <section id="more-citys" class="more-citys mb-5">
      <div class="container">
        <div class="titel py-5">
          <h2>Other Citys</h2>
          <div
            style="
              background-color: #000000;
              height: 3px;
              width: 100px;
              margin: auto;
            "
          ></div>
        </div>

        <div class="row">
          <div class="col-md-4 d-flex align-items-stretch">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-3.jpg');
              "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem .</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-3.jpg');
              "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem.</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-3.jpg');
              "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem .</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-stretch mt-4">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-3.jpg');
              "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem .</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-stretch mt-4">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-3.jpg');
              "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem .</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-stretch mt-4">
            <div
              class="card"
              style="
                background-image: url('../../../assets/images/city-details/city-4.png');
              "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="card-body">
                <h5 class="card-title"><a href="">City Name</a></h5>
                <p class="card-text">Nostrum eum sed et autem .</p>
                <div class="read-more">
                  <a href="#"><i class="bi bi-arrow-right"></i> Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End More Services Section -->
  </div>
</div>
<app-footer></app-footer>
