<mat-dialog-content class="mat-typography">
        <ol class="listTitle">
            <li *ngFor="let item of discount"><p class="title">{{item.title}}</p>
                <ul>
                    <li *ngFor="let itemOffers of item.offers"><p>{{itemOffers.offer}}</p></li>
                </ul>
            </li>
        </ol>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button mat-dialog-close color="warn">CLOSE</button>
</mat-dialog-actions>       