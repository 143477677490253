<div class="post-carousel">
  <div
    class="post"
    [ngClass]="{ 'mx-auto': posts.length == 2 }"
    (click)="navigateToUrl(post?.category_slug, post?.article_slug)"
  >
    <div class="photo" style="background-image:url({{ post.image }})">
      <img
        title="{{ post?.image_alt || 'flyallover' }}"
        class="sr-only"
        src="{{ post?.image }}"
        alt="{{ post?.image_alt || 'flyallover' }}"
      />
      <div class="overlay">
        <a
          (click)="navigateToUrl(post?.category_slug, '')"
          class="label"
          target="_blank"
        >
          {{ post?.category_title }}
        </a>
      </div>
    </div>
    <div class="description">
      <h3 title="{{ post?.title_content }}">
        {{ post?.title_content.slice(0, 50) }}
        <span *ngIf="post?.title_content?.length > 50">...</span>
      </h3>
      <div class="labels">
        <label></label>
        <label *ngIf="post?.created_at"
          >On {{ post?.created_at | date: "dd.MM.yyyy" }}</label
        >
      </div>
      <p class="d-none d-sm-block">
        {{ post?.short_description?.slice(0, 60) }}
        <a
          *ngIf="post?.short_description?.length > 60"
          (click)="navigateToUrl(post?.category_slug, post?.article_slug)"
          >...
        </a>
      </p>
      <p class="d-block d-sm-none">
        {{ post?.short_description?.slice(0, 80) }}
        <a
          *ngIf="post?.short_description?.length > 80"
          (click)="navigateToUrl(post?.category_slug, post?.article_slug)"
          >...
        </a>
      </p>
    </div>
  </div>
</div>
