import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(private meta: Meta, @Inject(DOCUMENT) private document: Document, private http: HttpClient) { }

  // this method update meta tags in index.html file that deals with og system
  updateOGMetaTag(metaKey: string, metaValue: string) {
    this.meta.updateTag({ property: metaKey, content: metaValue });
  }

  // this method update meta tags in index.html file that deals with twitter system or any meta tag that has
  //  name and content as attributes
  updateTwitterMetaTags(metaKey: string, metaValue: string) {
    this.meta.updateTag({ name: metaKey, content: metaValue })
  }

  createCanonicalTag(url: string) {
    const canonicalLink = this.document.getElementById('canonical')
    if (!canonicalLink) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('id', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', url);
    } else {
      canonicalLink.setAttribute('href', url);
    }
  }

  modifyMetaTags(metaData) {
    // metaData is an object, each key in this object represents a new meta tag
    Object.keys(metaData).forEach(key => {
      if (key.includes('og:')) {
        // if (Array.isArray(metaData[key])) {
        //   metaData[key] = metaData[key].join(', ')
        // }
        this.updateOGMetaTag(key, metaData[key])
      } else if (key === 'canonical_tag') {
        this.createCanonicalTag(metaData[key])
      } else {

        // if (Array.isArray(metaData[key])) {
        //   metaData[key] = metaData[key].join(', ')
        // }
        this.updateTwitterMetaTags(key, metaData[key])
      }
    })
  }

  addMetaScript(script: string) {
    const head = this.document.getElementsByTagName('head')[0];
    head.insertAdjacentHTML('beforeend', script)
  }
}
