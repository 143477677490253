<app-navbar></app-navbar>
<div class="container d-flex justify-content-center">
  <div class="card-body-payment">
    <div class="header-card-payment">
      <div class="tittle-card-payment text-center" *ngIf="pageParams.status != 'requier_documents'">Important Notice
      </div>
      <div class="tittle-card-payment text-center" *ngIf="pageParams.status == 'requier_documents'">
        <div class="tittle-card-payment-big">
          Urgent
        </div> <br> Verify Your
        Identity to Confirm <br> Your Flight Reservation
      </div>
    </div>
    <div class="body-card-payment text-left">
      <div class="body-card-payment-text" *ngIf="pageParams.status != 'requier_documents'">
        <span>We wanted to inform you that your airline ticket has been issued and is now ready for review. We kindly
          request that you carefully go through all the information provided, including the names, airports, and dates,
          to ensure their accuracy.</span>
        <span>Additionally, we would like to draw your attention to the airline rules and conditions associated with
          your ticket.
          It is important to note that some tickets are non-refundable or may incur high charges for date changes in
          case of any mistakes. We strongly encourage you to thoroughly review these terms to avoid any unforeseen costs
          or inconveniences.
        </span>
        <span class="col-12 pt-2">To ensure that any necessary changes or amendments can be made promptly, we kindly ask
          that you complete this review within 24 hours from the ticket issue. Please note that failure to do so may
          result in charges being applied.</span>
        <span>We would also like to clarify that Fly All Over is a ticketing company and not the airline itself.
          Therefore, we are unable to distribute any charges in the event of
          airline cancellations or rescheduling initiated by the airline.
          We highly recommend purchasing a travel insurance policy to safeguard against such situations.
        </span>
      </div>
      <div class="body-card-payment-text" *ngIf="pageParams.status == 'requier_documents'">
        <span>We would like to inform you that a <span class="we-kindly"> temporary hold has been placed on your
          flight reservation with Fly All Over.</span>
          In order to proceed with the booking confirmation, <div class="we-kindly"> We kindly request that you verify
            your identity within the
            next 2 hours.</div></span>
        <span>To ensure a smooth and secure travel experience, we require all passengers to complete the identity
          verification process.</span>
        <span> This is in accordance with industry regulations and airline requirements. By verifying
          your identity, we can proceed with the necessary documentation for your upcoming flight
        </span>
        <span><div class="we-kindly">Please note that there may be verification fees associated with this process,</div> which
          will be clearly communicated to you upon completion. We apologize for any inconvenience this may cause, but it
          is essential to comply with these requirements.</span>
        <span>If you fail to complete the identity verification process within the given time frame, <span class="we-kindly">We will have no
          choice but to cancel your reservation.</span> In such cases, <span class="we-kindly"> a refund will be issued to the original payment method,
          after deducting Fly All Over fees and any applicable cancellation charges. </span>
        </span>
        <span>We understand the importance of your travel plans and are here to assist you throughout the verification
          process. Should you have any questions or require further information, please do not hesitate to contact our
          customer support team at <a href="/contact-us"> Contact Us</a>

        </span>
        <span>Thank you for your immediate attention to this matter. We appreciate your cooperation and look forward to
          confirming your flight reservation soon.
        </span>
      </div>
      <div class="body-card-payment-text col-12 p-0">

        <span>Kind regard
        </span>
        <span class="mx-5">Fly All Over Customer Support Team
        </span>
      </div>
      <div class="btn-ticket-port">
        <div class="btn-ticket" *ngIf="pageParams.status != 'requier_documents'" (click)="goToReview()">
          Review Your Ticket
        </div>
        <div class="btn-ticket" *ngIf="pageParams.status == 'requier_documents'" (click)="verifyDocument()">
          <div class="spinner-border text-light cuz-size" role="status" *ngIf="openDocument">
          </div>
          <span class="px-2">
            Verify Document
          </span>
      </div>
      <!-- <div class="btn-ticket-v2" *ngIf="pageParams.status == 'requier_documents'" (click)="verifyDocument()">
          <div class="spinner-border text-light cuz-size" role="status" *ngIf="openDocument">
          </div>
          <span class="px-2">
            Check Document
          </span>
      </div> -->
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
