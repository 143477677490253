<app-navbar></app-navbar>
<div class="container">
  <div class="row">
    <div class="col-xl-4 col-xxl-3">
      <app-dash-sidebar></app-dash-sidebar>
    </div>
    <div class="col-xl-8 col-xxl-9">
      <div class="header-image">
        <h1 class="text-center">Welcome Back Hossam Mostafa</h1>
      </div>
      <div class="account-form">
        <div class="h4">Visa Data</div>
        <div class="form-details">
          <h5>Entry Restrictions</h5>
          <p>All Travellers Who Meet The Health/Travel Document Requirements May Enter This Destination.
            Please Refer To Our Entry And Health Requirements Section For Further Information
          </p>
        </div>
      </div>
      <div class="change-password-form">
        <div class="requirements">
            <div>
              <h6>Entry & Health Requirements</h6>
              <p>All Travellers Must Present A Printed Negative COVID-19 PCR Test Certificate,
                In English Or Arabic, For A Test Taken At Most 72 Hours Before Arrival. Children
                Under Six-Years-Old Are Exempt From This Requirement.</p>
              <p *ngIf="!readMoreEntryHealthRequirements">Travelers Departing From Japan, China, Thailand,
                North America, South America And Canada, As Well As London Heathrow, Paris Charles De Gaulle And Frankfurt Airports,
                Which Will Be Permitted To Enter With A Negative COVID-19 Test No Older Than 96 Hours.</p>
              <p *ngIf="readMoreEntryHealthRequirements" (click)="readMoreEntryHealthRequirements = !readMoreEntryHealthRequirements" class="readMore">Read More</p>
              <p *ngIf="!readMoreEntryHealthRequirements"
                 (click)="readMoreEntryHealthRequirements = !readMoreEntryHealthRequirements" class="readMore">Read Less</p>
            </div>
        </div>
        <div class="requirements">
          <div>
            <h6>Re-Entry Requirements</h6>
            <p>All Travellers Returning To The United States By Air Must Provide A Negative,
              Viral COVID-19 Test Result Issued No More Than 3 Days Prior To Departure.</p>
              <p *ngIf="!readMoreReentryRequirements">(Viral Tests Include A NAAT/PCR Test Or An Antigen Test.)  It Is Recommended But Not Required That
              Travellers Are Also Tested 3 To 5 Days After Entry And That They Plan To Self-Isolate For 7 Days Upon Arrival.
            </p>
            <p *ngIf="readMoreReentryRequirements" (click)="readMoreReentryRequirements = !readMoreReentryRequirements" class="readMore">Read More</p>
            <p *ngIf="!readMoreReentryRequirements"
               (click)="readMoreReentryRequirements = !readMoreReentryRequirements" class="readMore">Read Less</p>
          </div>
        </div>
      </div>
        <div class="btn-groups">
          <button class="btn share-btn"><img alt="share" title="Share Image" src="../../../assets/images/dashboard/share.png"> Share</button>
          <button class="btn save-btn">Get My Visa</button>
        </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
