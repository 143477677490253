<section class="thanks-modal">
  <div class="position-relative" *ngIf="itinerary != null">
    <div class="body-ticket-part">
      <div class="row pnr_home Font-Family-bold design_1">
        <div class="col-3 p-0">
          <img src="../../../../assets/images/arrow/logo_sign_shadow.png" alt="logo" (click)="payment()"
            class="logo-ticket-pnr">
        </div>
        <ng-container *ngIf="ticketStep==0">
          <div class="col-9 header-body">

            <p class="Font-Family fontHandel "><span class="mr-1"
                *ngIf="flightData?.departure_date">{{flightData?.departure_date| date:"dd MMM yyy"}}</span> <img
                *ngIf="flightData?.return_date" src="assets/images/arrow/Polygon.png" class="mx-2"> <span class="mx-1"
                *ngIf="flightData?.return_date">{{flightData?.return_date| date:"dd MMM yyy"}}</span>
            </p>
            <p class=" Font-Family trip-part" *ngIf="flightData?.origin_city_object.city_name"><small
                class=" font-ticket-lv-3 col-4 p-0">TRIP FROM</small> <span
                class="text2Handel col-8 p-0">{{flightData?.origin_city_object.city_name}}
                , {{flightData?.origin_city_object.country_code}} </span>
            </p>
            <p class=" Font-Family trip-part" *ngIf="flightData?.destination_city_object.city_name"><small
                class=" font-ticket-lv-3 col-4 p-0">TRIP TO</small> <span
                class="text2Handel col-8 p-0">{{flightData?.destination_city_object.city_name}}
                , {{flightData?.destination_city_object.country_code}}</span>
            </p>
            <div class="hr-part"></div>
            <div class="">
              <p class="font-ticket-lv-3 Font-Family"><small> RESERVATION CODE</small></p>
              <span class="font-ticket-lv-1 Font-Family-bold" *ngIf="flightData.pnr">{{flightData.pnr}}</span>
              <div class="">
                <p class="font-ticket-lv-3 " *ngIf="itinerary.trip_class"> Cabin <small
                    class="text2Handel">{{itinerary.trip_class}} </small></p>
                <p class="font-ticket-lv-3 " *ngIf="flightData?.status"> Status<small class="text2Handel text-capitalize">{{
                    flightData?.status}} </small></p>
              </div>
              <div class="hr-part"></div>

            </div>

          </div>
        </ng-container>
        <ng-container class="" *ngFor="let bigFlight of flightDetails?.flights ;let i = index ">
          <ng-container class="" *ngFor="let Flight of bigFlight;let x = index;">
            <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1">
              <div class="col-9 header-body">

                <div class=" Font-Family-bold ">
                  <span class="font-ticket-lv-2 Font-Family-bold" *ngIf="Flight.OperatingAirlineName">
                    {{Flight.OperatingAirlineName}} </span>
                  <p class="font4Handel_sec2" *ngIf="Flight.OperatingAirlineCode">{{Flight.OperatingAirlineCode}}
                    {{Flight.FlightNumber}} <br>
                    {{Flight.AircraftName}}</p>
                </div>
                <div class="">
                  <span class="font-ticket-lv-2 Font-Family-bold"> Duration: </span>
                  <p class="font4Handel_sec2 "> {{convertTime(Flight.ElapsedTime )}}</p>
                </div>
                <div class="" *ngIf="Flight.FlightLayoverTime ">
                  <span class="font-ticket-lv-2 Font-Family-bold"> Total Duration: </span>
                  <p class="font4Handel_sec2 "> {{convertTime(Flight.FlightLayoverTime + Flight.ElapsedTime +
                    bigFlight[x-1].ElapsedTime)}}</p>
                </div>
                <div class="hr-part"></div>

                <div class="">
                  <p class="font-ticket-lv-3 Font-Family" *ngIf="flightData.pnr"> RESERVATION CODE </p>
                  <span class="font-ticket-lv-1 Font-Family-bold">{{flightData.pnr}}</span>
                </div>
                <div class="hr-part"></div>


              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="ticketStep==0 &&( flightData?.order_status =='pnr_canceled'||flightData?.order_status =='requier_documents')" class="pnr_canceled">
        <div class="hight-text-cancel" *ngIf="flightData?.order_status =='pnr_canceled'">canceled</div>
        <div class="hight-text-cancel" *ngIf="flightData?.order_status =='requier_documents'">Missing Documents</div>
        <div class="hr-part"></div>
        <div class="information-text-cancel">For More Information please</div>
        <div class="information-text-cancel mb-5">contact us : 212-381-1167</div>
        <div class="col-12 p-0 btn-ticket-port" [ngClass]="{'d-flex justify-content-center': ticketStep==0}">
          <div class="btn-ticket back" *ngIf="ticketStep !=ticketMaxStep" (click)="goToPreviousStep()"
            [ngClass]="{'d-none': ticketStep==0}">
            <span>Back</span>

          </div>
          <div class="btn-ticket back" *ngIf="ticketStep ==ticketMaxStep"  mat-dialog-close="">

            <span>Cancel</span>

          </div>
          <div class="btn-ticket next"  mat-dialog-close="" *ngIf="flightData?.order_status =='pnr_canceled'">
            <span *ngIf="flightData?.order_status =='pnr_canceled'">Close</span>
          </div>
           <div class="btn-ticket next" *ngIf="flightData?.order_status =='requier_documents'" (click)="verifyDocument()" mat-dialog-close="">
            <span *ngIf="flightData?.order_status =='requier_documents'">Verify Document</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="ticketStep==0 && ( flightData?.order_status !='pnr_canceled'&&flightData?.order_status !='requier_documents')" class="ticket-step-1">
        <div class="up-title">PREPARED FOR</div>
        <div class="down-title pb-0 word-wrap">
          {{handelName(familyName?.last_name)+ ' / ' + handelName(familyName?.first_name)+ ' ' +handelName( familyName?.middle_name )+ ' '}}
          <span *ngIf="familyName?.gender=='Male'">MR</span>
          <span *ngIf="familyName?.gender!='Male'">MRS</span>
        </div>
        <div class="hr-part"></div>
        <div class="up-title" *ngIf="flightData?.departure_date">DEPARTURE :</div>
        <div class="down-title">{{flightData?.departure_date| date:"EEE dd MMM"}}</div>
        <div class="up-title" *ngIf="itinerary?.trip_type!='OneWay'">Return :</div>
        <div class="up-title" *ngIf="itinerary?.trip_type=='OneWay'">ARRIVAL :</div>
        <div class="down-title" *ngIf=" itinerary?.trip_type!='OneWay'&& itinerary?.return_date">{{itinerary?.return_date| date:"EEE dd MMM "}}</div>
        <div class="down-title" *ngIf="itinerary?.trip_type=='OneWay' && flightDetails.flights[0][flightDetails.flights[0].length - 1]?.ArrivalDateTime">
          {{flightDetails.flights[0][flightDetails.flights[0].length - 1]?.ArrivalDateTime | date:"EEE dd MMM "}}
        </div>
        <div class="mb-2 " *ngIf="flightDetails.BrandFeatures.length == 0"
             style="color: #585757; font-size: 20px; font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica Neue, Segoe UI, Roboto, sans-serif; font-weight: 400; word-wrap: break-word">
          <span *ngIf="flightDetails.BrandFeatures.BG"> Baggage Info</span>
        </div>
        <ul *ngFor="let item of flightDetails.BrandFeatures.BG; let i = index ">
          <li *ngIf="item.application =='F'">
            <div class="font-ticket-lv-2 Font-Family-bold">
              <p class="font4Handel_sec2">{{item.commercialName}} </p>
            </div>
          </li>
        </ul>
        <div class="down-title-p">Please verify flight times prior to departure</div>
        <div class="col-12 p-0 btn-ticket-port" [ngClass]="{'d-flex justify-content-center': ticketStep==0}">
          <div class="btn-ticket back" *ngIf="ticketStep !=ticketMaxStep" (click)="goToPreviousStep()"
            [ngClass]="{'d-none': ticketStep==0}">
            <span>Back</span>

          </div>
          <div class="btn-ticket back" *ngIf="ticketStep ==ticketMaxStep" (click)="openNote()" mat-dialog-close="">

            <span>Cancel</span>

          </div>
          <div class="btn-ticket next" (click)="goToNextStep()">
            <span *ngIf="nextName !='Payment'">{{nextName}}</span>
            <span *ngIf="nextName ==='Payment'" mat-dialog-close="">{{nextName}}</span>

          </div>
        </div>
      </ng-container>

      <div class="" *ngFor="let bigFlight of flightDetails?.flights ;let i = index ">
        <div class="" *ngFor="let Flight of bigFlight;let x = index;">
          <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1 && bigFlight[x].FlightLayoverTime">
            <div class="dialog-stop">
              <span class="border-stop" *ngIf="Flight.FlightLayoverTime ">
                <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span>
                  {{convertTime(Flight.FlightLayoverTime + Flight.ElapsedTime + bigFlight[x-1].ElapsedTime)}}</small>
                <div style="padding-left: 14px;">
                  <div class="d-flex">
                    <span class="stops-title white-space-no-wrap mr-1 font">Stop At : </span>
                    <span class="font white-space-no-wrap">{{ Flight.DepartureAirportName }} <br>{{
                      convertTime(Flight.FlightLayoverTime) }}
                      Layover </span>
                  </div>
                  <div class="d-flex mt-2 position-relative" *ngIf="Flight.length-1>1">
                    <span class="line-dot"></span>
                    <p class="stops-title white-space-no-wrap mr-1 font">Stop At: </p>
                    <span class="font white-space-no-wrap">{{ Flight.DepartureAirportName }} <br> {{
                      convertTime(Flight.FlightLayoverTime) }}
                      Layover </span>
                  </div>
                </div>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1">
            <div class="d-flex justify-content-between mb-3">
              <div class="col-6 p-0 text-center">
                <span class="city-code">{{Flight.DepartureAirport}}<br />
                </span>
                <span class="city-title">{{Flight.DepartureAirportName}}
                </span>
              </div>
              <div class=" p-0 text-center"><span class="city-code">{{Flight.ArrivalAirport}}<br /></span>
                <span class="city-title">{{Flight.ArrivalAirportName}}</span>

              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <div class="col-6 p-0 date-ticket">
                <span class="date-ticket-text" *ngIf="Flight.DepartureDateTime"> Departing
                  At</span><br>{{Flight.DepartureDateTime| date :"hh:mm a"}}
                <br />{{Flight.DepartureDateTime| date :"EEE , MMM dd"}}
              </div>
              <div class="col-6 p-0 date-ticket">
                <span class="date-ticket-text" *ngIf="Flight.ArrivalDateTime"> Arriving
                  At</span><br>{{Flight.ArrivalDateTime| date :"hh:mm a"}}
                <br />{{Flight.ArrivalDateTime| date :"EEE ,MMM dd"}}
              </div>

            </div>
            <div class="d-flex justify-content-between mb-4">
              <div class="col-6 p-0 text-center">

                <span class="date-ticket-text"> Terminal:</span><br />

                <span class="city-title" *ngIf="Flight.DepartureAirportTerminal">TERMINAL
                  {{Flight.DepartureAirportTerminal}}</span> <span class="city-title-none"
                  *ngIf="!Flight.DepartureAirportTerminal">Contact airline for more information</span>
              </div>
              <div class="col-6 p-0 text-center"><span class="date-ticket-text"> Terminal:</span><br />
                <span class="city-title" *ngIf="Flight.ArrivalAirportTerminal">TERMINAL
                  {{Flight.ArrivalAirportTerminal}}</span>
                <span class="city-title-none" *ngIf="!Flight.ArrivalAirportTerminal">Contact airline for more
                  information</span>
              </div>
            </div>
            <div class="col-12 p-0 btn-ticket-port" [ngClass]="{'d-flex justify-content-center': ticketStep==0}">
              <div class="btn-ticket back" *ngIf="ticketStep !=ticketMaxStep" (click)="goToPreviousStep()"
                [ngClass]="{'d-none': ticketStep==0}">
                <span>Back</span>

              </div>
              <div class="btn-ticket back" *ngIf="ticketStep ==ticketMaxStep" (click)="openNote()" mat-dialog-close="">

                <span>Cancel</span>

              </div>
              <div class="btn-ticket next" (click)="goToNextStep()">
                <span *ngIf="!bigFlight[x+1]">{{nextName}}</span>
                <span *ngIf="bigFlight[x+1]&&bigFlight[x+1].FlightLayoverTime"><span class="go-to-stop">Go
                    TO</span>Stop</span>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="itinerary == null">
    <div class="booking-error">
      <h1 class="Font-Family">Note !</h1>
      <p class="Font-Family">sorry we are not able to complete your booking please find details below</p>
      <p class="error" *ngIf="errorMessages">{{errorMessages}}</p>
      <p></p>
      <div *ngFor="let item of errorData">
        <ul class="error" *ngIf="errorData">
          <li class="Font-Family">{{item}}</li>
        </ul>
        <p class="error Font-Family" *ngIf="!errorData">There is a problem with your visa number</p>
      </div>
      <div class=" my-3">
        <button class="fao_mobile_btn_small" type="button" mat-dialog-close="" (click)="goToHome()">back to
          home</button>
      </div>
    </div>
  </div>
</section>
