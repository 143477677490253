<div class="last-section">
  <div class="items">
    <div class="item">
      <img title="Logo Image" alt="ARC" src="./assets/images/home/logos/ARC.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="cibt-visas-logo" src="./assets/images/home/logos/cibt-visas-logo1.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="EGYPTAIR LOGO" src="./assets/images/home/logos/EGYPTAIR-logo-33A4261781-seeklogo.com.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="EMIRTES LOGO" src="./assets/images/home/logos/EMIRTES.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="ETIHAD LOGO" src="./assets/images/home/logos/ETIHAD.png">
    </div>
    <div class="item">
      <img  title="Logo Image" alt="kisspng-royal-air-maroc-casablanca-airline-flight-paris-or" src="./assets/images/home/logos/kisspng-royal-air-maroc-casablanca-airline-flight-paris-or-5c0d9dda258212.6005143215443962501536.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="pngegg" src="./assets/images/home/logos/pngegg.png">
    </div>
    <div class="item">
      <img title="Logo Image" alt="toppng-logo-iata" src="./assets/images/home/logos/toppng.com-logo-iata-980x312.png">
    </div>
    <div class="item">
      <img title="Logo Image"  alt="CF LOGO" src="./assets/images/home/logos/CF%20LOGO.png">
    </div>
    <div class="item">
      <img  title="Logo Image" alt="AIRLINES LOGO" src="./assets/images/home/logos/GOL%20AIRLINES.png">
    </div>
  </div>
</div>
