<!-- end header -->
<div  class=" d-flex row " >
  <section class="find-adventure col-6">
  <div class="placeHolder element pointer input-search"   >
    <input type="text" class="checkin-hotel"  placeholder="Choose Date">
    <div *ngIf=" !departureDate">
      <span class="star">*</span>
      <span>Check in</span>
    </div>

    <div *ngIf="departureDate">
      <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{departureDate|date:'MMM d'}}</span>
        <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
        >{{departureDate|date:'yyy'}}</span>
      </div>
    </div>
    <div>
      <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
    </div>
  </div>

</section>
<!-- end header -->
<section class="find-adventure col-6">
  <div class="placeHolder element pointer input-search"  >
    <input type="text" class="checkin-hotel" placeholder="Choose Date">
    <div *ngIf=" !returnDate">
      <span class="star">*</span>
      <span>Check out</span>
    </div>

    <div *ngIf="returnDate">
      <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{returnDate|date:'MMM d'}}</span>
        <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
        >{{returnDate|date:'yyy'}}</span>
      </div>
    </div>
    <div>
      <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
    </div>
  </div>

</section>
</div>
