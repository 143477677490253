import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {
  title = 'My first AGM project';
  allLocation : any [] = [];
  @Input() longitude;
  @Input() Latitude;
zoom = 10;
minZoom = 2;
  // lat = 51.678418;
  // lng = 7.809007;
  // slug;
  // activities;
  curicon="/assets/newIcon/pin.png"
  constructor( private apis: ApisService,  private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {

  }

}
