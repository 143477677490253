import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-check-in-input',
  templateUrl: './check-in-input.component.html',
  styleUrls: ['./check-in-input.component.css']
})
export class CheckInInputComponent{
  @Input() departureDate;
  @Output() openDesktopCalenderEvent = new EventEmitter();
  isFocused = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-US');
  }

  openDesktopCalender() {
    this.openDesktopCalenderEvent.emit('depart');
  }

  onFocus(): void {
    if (this.departureDate && this.departureDate.length > 0) {
      this.isFocused = true;
    } else {
      this.isFocused = !this.isFocused;
    }
  }
}
