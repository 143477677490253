import {Router, NavigationExtras} from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { ApisService } from '../../services/apis.service';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  nameOrEmail:string = '';
  password:string = '';
  errorMessage:string = '';
  typePassword:string = 'password';
  remember:boolean = false;
  loginSpinner:boolean = false;
  // public user: any = SocialUser;

  constructor(
    private router: Router,
    // private SocialAuthService: AuthService,
    private apis: ApisService,
    private cookie: CookieService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.pageReady();
  }

  // page ready element to run index.js
  pageReady() {
    let script = this.document.createElement('script');
    script.innerHTML = `
      $('[placeholder]').focus(function () {
          $(this).attr('data-placeholder', $(this).attr('placeholder'));
          $(this).attr('placeholder', '');
      }).blur(function () {
          $(this).attr('placeholder', $(this).attr('data-placeholder'));
      });
    `;
    this.document.head.appendChild(script);
  }

  // sign in
  submitSignIn() {
    // ghaidaa@mail.com
    // 123456

    if (!this.nameOrEmail || !this.password) {

      this.errorMessage = 'All Fields are Required!';

    } else {
      this.loginSpinner = true;
      this.apis.signIn(this.nameOrEmail, this.password).subscribe( (data:any) => {


        if (data.status == true) {

          this.cookie.set('flyallover_token', data.data.access_token);
          this.cookie.set('user_id',data.data.user_id);

          setTimeout(() => {
            this.loginSpinner = false;
            this.errorMessage = '';
            setTimeout(() => {this.router.navigate(['/profile/dashboard']);}, 500);
          }, 1500);
        }
      }, (err:any) => {
        this.errorMessage = 'Invalid cardentials, wrong email or password.';
        this.loginSpinner = false;
        this.cookie.set('flyallover_token', '');
      });

    }
  }

  // show hide password
  showHidePassword() {
    if (this.typePassword == 'password') {
      this.typePassword = 'text';
    } else if (this.typePassword == 'text') {
      this.typePassword = 'password';
    }
  }

  // Remember Me
  rememberMe() {
    if (this.remember == false) {
      this.remember = true;
    } else if (this.remember == true) {
      this.remember = false;
    }
  }

  // Password Recovery
  passwordRecovery() {

  }

}
