<div [ngClass]="{'flight-card-search-engine':inPage}" >
  <!-- <div class="header-flight-card-search-engine">
    <h1>Compare flights from 100s of sites.</h1>
  </div> -->
  <div class="tab-flight-card-search-engine">
    <div class="tab-card-dropdown">
      <div class="title-tab-filght" (click)="viewTripType.classList.add('view-dropdown')">
        <span>{{tripType.name}}</span><svg viewBox="0 0 200 200" width="1.25em" height="1.25em"
          xmlns="http://www.w3.org/2000/svg" role="presentation">
          <path
            d="M100 132.5c-3.873 0 .136 2.376-64.801-51.738l9.603-11.523L100 115.237l55.199-45.999l9.603 11.523C99.806 134.924 103.855 132.5 100 132.5z">
          </path>
        </svg>
      </div>
      <div class="body-tab-card" #viewTripType (mouseleave)="viewTripType.classList.remove('view-dropdown')">
        <div class="tab-iem" *ngFor="let item of tripsTypeObj" [ngClass]="{'tab-iem-actvie':item.code==tripType.code}"
          (click)="selectedTripType(item);viewTripType.classList.remove('view-dropdown')">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="tab-card-dropdown">
      <div class="title-tab-filght" (click)="viewTravellersObj.classList.add('view-selected-trevellers-body')">
        <span>{{trevallerValue}}</span><svg viewBox="0 0 200 200" width="1.25em" height="1.25em"
          xmlns="http://www.w3.org/2000/svg" role="presentation">
          <path
            d="M100 132.5c-3.873 0 .136 2.376-64.801-51.738l9.603-11.523L100 115.237l55.199-45.999l9.603 11.523C99.806 134.924 103.855 132.5 100 132.5z">
          </path>
        </svg>
      </div>
      <div class="selected-trevellers-body" #viewTravellersObj
        (mouseleave)="viewTravellersObj.classList.remove('view-selected-trevellers-body')">
        <div class="selected-cart" *ngFor="let item of travellersObj |keyvalue">
          <div class="title-view-selected">{{item.value.name}}</div>
          <div class="btn-selected-trevellers">
            <div class="btn-del-selected-num-trevallers" (click)="inc(item.value.id)">-</div>
            <div class="btn-view-selected-num-trevallers">{{item.value.currentValue}}</div>
            <div class="btn-plus-selected-num-trevallers" (click)="plus(item.value.id)">+</div>
          </div>
        </div>
        <!-- <span class="text-dannger">{{errorMessage}}</span> -->
      </div>
    </div>
    <div class="tab-card-dropdown">
      <div class="title-tab-filght" (click)="classtype.classList.add('view-dropdown')">
        <span>{{classType.name}}</span><svg viewBox="0 0 200 200" width="1.25em" height="1.25em"
          xmlns="http://www.w3.org/2000/svg" role="presentation">
          <path
            d="M100 132.5c-3.873 0 .136 2.376-64.801-51.738l9.603-11.523L100 115.237l55.199-45.999l9.603 11.523C99.806 134.924 103.855 132.5 100 132.5z">
          </path>
        </svg>
      </div>
      <div class="body-tab-card" #classtype (mouseleave)="classtype.classList.remove('view-dropdown')">
        <div class="tab-iem" *ngFor="let item of classTypeObj" [ngClass]="{'tab-iem-actvie':item.code==classType.code}"
          (click)="selectedClassType(item);classtype.classList.remove('view-dropdown')">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="tab-card-dropdown">
      <div class="title-tab-filght" (click)="ViewStops.classList.add('view-dropdown')">
        <span>{{stopValue.name}}</span><svg viewBox="0 0 200 200" width="1.25em" height="1.25em"
          xmlns="http://www.w3.org/2000/svg" role="presentation">
          <path
            d="M100 132.5c-3.873 0 .136 2.376-64.801-51.738l9.603-11.523L100 115.237l55.199-45.999l9.603 11.523C99.806 134.924 103.855 132.5 100 132.5z">
          </path>
        </svg>
      </div>
      <div class="body-tab-card" #ViewStops (mouseleave)="ViewStops.classList.remove('view-dropdown')">
        <div class="tab-iem" *ngFor="let item of stopsObj" [ngClass]="{'tab-iem-actvie':item.code==stopValue.code}"
          (click)="selectedStops(item);ViewStops.classList.remove('view-dropdown')">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="engine-flight-card-search-engine">
    <span [ngSwitch]="tripType.code">
      <div *ngSwitchCase="'Return'">
        <app-search-engine-flight-return [localData]="localData" (sendNewrequset)="getFlighData($event)"></app-search-engine-flight-return>
      </div>
      <div *ngSwitchCase="'OneWay'">
        <app-search-engine-flight-oneway [localData]="localData" (sendNewrequset)="getFlighData($event)"></app-search-engine-flight-oneway>

      </div>
      <div *ngSwitchCase="'OpenJaw'">

      </div>
      <div *ngSwitchDefault>

      </div>
    </span>
  </div>
  <div class="footer-flight-card-search-engine"></div>
</div>
