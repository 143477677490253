import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
declare var $: any
interface Location {
  name: string;
  type: string;
  icon: string;
  latitude: string;
  longitude: string;
  country_code: string;
  model_type: string;
}

@Component({
  selector: 'app-search-engine-flight-return',
  templateUrl: './search-engine-flight-return.component.html',
  styleUrls: ['./search-engine-flight-return.component.css']
})
export class SearchEngineFlightReturnComponent implements OnInit, AfterViewInit {
  @Output() sendNewrequset = new EventEmitter<any>();
  @Input() localData: any;
  flightSearchForm!: FormGroup
  DepartDate: any;
  ReturnDate: any;
  todayDate: Date = new Date();
  locations: Location[] = [];
  filteredLocations: Location[] = [];
  isLoading = false;
  selectedLocation: Location | null = null;
  tomorrow: any;
  thrdDay: any;
  viewDepartDate: string;
  viewReturnDate: string;
  travellersObj: any;
  errorMessage: any;
  trevallerValue: any;
  travellersNumberSelected: any;
  hotelDest: any = {
    country_code: "",
    hotelCode: "",
    latitude: "",
    longitude: "",
    model_type: "",
    name: "",
  };
  origin: any;
  isLoadingOrigin: boolean;
  switchtripe: boolean;

  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.setValues()
    this.setData()
  }
  ngAfterViewInit(): void {
    this.datePicker()
  }

  datePicker() {
    $(".check-hotel").daterangepicker({
      autoUpdateInput: false,
      startDate: this.viewReturnDate ? this.datePipe.transform(this.viewReturnDate, "MM/dd/YYYY") : this.todayDate,
      endDate: this.viewReturnDate ? this.datePipe.transform(this.viewReturnDate, "MM/dd/YYYY") : this.todayDate,

      locale: {
        cancelLabel: 'close',
        format: 'MM/DD/YYYY'
      },
      autoApply: true,
      minDate: this.todayDate,
      isInvalidDate: (date) => {
        // Mark dates as invalid based on some condition
        return false;
      },
    });
    $('.check-hotel').on('apply.daterangepicker', (ev, picker) => {
      this.DepartDate = picker.startDate
      this.ReturnDate = picker.endDate
      this.viewDepartDate = this.datePipe.transform(picker.startDate, "EE MM-dd")
      this.viewReturnDate = this.datePipe.transform(picker.endDate, "EE MM-dd")
      this.setDateInterval(picker)
    });

    $('.check-hotel').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });
  }
  setDateInterval(picker) {
    $(".check-hotel").daterangepicker({
      autoUpdateInput: false,
      startDate: picker.startDate,
      endDate: picker.endDate,

      locale: {
        cancelLabel: 'close',
        format: 'MM/DD/YYYY'
      },
      autoApply: true,
      minDate: this.todayDate,
      isInvalidDate: (date) => {
        // Mark dates as invalid based on some condition
        return false;
      },
    });
    $('.check-hotel').on('apply.daterangepicker', (ev, picker) => {
      this.DepartDate = picker.startDate
      this.ReturnDate = picker.endDate
      this.viewDepartDate = this.datePipe.transform(picker.startDate, "EE MM-dd")
      this.viewReturnDate = this.datePipe.transform(picker.endDate, "EE MM-dd")
      this.setDateInterval(picker)
    });

    $('.check-hotel').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });
  }
  setValues() {
    this.tomorrow = this.todayDate.getDate() + 1
    this.thrdDay = this.todayDate.getDate() + 2
    this.flightSearchForm = this.fb.group({
      destenation: [''],
      origin: [''],
      Depart: [''],
      Return: [''],
      // guests: [''],
    });

    this.travellersObj =
    {
      adult: {
        id: 'adult',
        name: 'Adults',
        initialValue: 1,
        currentValue: 1,
        minValue: 1,
        maxValue: 12,
      },
      child: {
        id: 'child',
        name: 'Children',
        ageFrom: 2,
        ageTo: 12,
        initialValue: 0,
        currentValue: 0,
        minValue: 0,
        maxValue: 12,
      },
    }
  }
  setData() {
    if (this.localData) {
      console.log(this.localData, 'localData');

      this.flightSearchForm.patchValue({
        destenation: this.localData.fullDestination,
        origin: this.localData.fullOrigin,
      })
      this.DepartDate = new Date(this.localData.departure_date);
      this.ReturnDate = new Date(this.localData.return_date);
      this.viewDepartDate = this.datePipe.transform(this.localData.departure_date, "EE MM-dd")
      this.viewReturnDate = this.datePipe.transform(this.localData.return_date, "EE MM-dd")
    }
  }
  getReturnDate(event) {
    this.DepartDate =  new Date(event.DepartDate)
    this.ReturnDate =  new Date(event.ReturnDate);
  }
  onDestenationInput() {
    this.isLoading = true;
    setTimeout(() => {
      this.locations = [];
      const destenation =
        this.flightSearchForm.get('destenation')?.value?.toLowerCase() || null;
      if (destenation) {
        this.apis.geoAutocomplete_v2(destenation, 'AIR').subscribe(
          (data: any) => {
            this.locations = data;
            this.isLoading = false;

          },
          (err) => {
            this.isLoading = false;
          }
        );
      }
    }, 3000)
  }

  onOriginInput() {
    this.isLoadingOrigin = true;
    setTimeout(() => {
      const origin =
        this.flightSearchForm.get('origin')?.value?.toLowerCase() || null;
      if (origin) {
        this.apis.geoAutocomplete_v2(origin, 'AIR').subscribe(
          (data: any) => {
            this.origin = data;
            this.isLoadingOrigin = false;

          },
          (err) => {
            this.isLoadingOrigin = false;
          }
        );
      }
    }, 3000)

    const origin =
      this.flightSearchForm.get('origin')?.value?.toLowerCase() || '';

    this.apis.geoAutocomplete_v2(origin, 'AIR').subscribe(
      (data: any) => {
        this.origin = data;
        this.isLoading = false;

      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  switchFlights() {
    this.switchtripe = !this.switchtripe
    const destenation = this.flightSearchForm.value.destenation
    const origin = this.flightSearchForm.value.origin
    this.flightSearchForm.patchValue({
      origin: destenation,
      destenation: origin
    })
  }
  getIconForType(type: string): string {
    const typeToIcon: { [key: string]: string } = {
      City: 'assets/hotel-icon-search/cityIcon.svg',
      hotel: 'assets/hotel-icon-search/bedIcon.svg',
      location: 'assets/hotel-icon-search/locationIcon.svg',
      airport: 'assets/hotel-icon-search/airportIcon.svg',
      Place: 'assets/hotel-icon-search/locationIcon.svg',
    };
    return typeToIcon[type] || 'assets/hotel-icon-search/airportIcon.svg';
  }

  selectLocation(location: Location, formName?: string) {
    switch (formName) {
      case 'destenation':
        this.flightSearchForm.patchValue({ destenation: location });
        break
      case 'origin':
        this.flightSearchForm.patchValue({ origin: location });
        break
    }
    this.selectedLocation = location;
    this.hotelDest = this.selectedLocation
    this.locations = [];
    this.origin = [];
  }

  initFlightRequest() {
    return {
      Depart: this.DepartDate,
      Return: this.ReturnDate,
      destenation: this.flightSearchForm.value.destenation,
      origin: this.flightSearchForm.value.origin
    }
  }

  onSearch() {
    console.log(this.flightSearchForm);
    this.sendNewrequset.emit(this.initFlightRequest());
  }

}
