<!-- <mat-tab-group class="mainTabGroup" dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="mainTab">
          <img alt="hotel" title="hotel" style="width: 36px;height: 36px;" src="/assets/images/icons/plane.svg">
          <span class="type">Flights</span>
        </div>
      </ng-template> -->
<mat-tab-group [(selectedIndex)]="selectedIndex" class="flightsTabGroup " mat-align-tabs="left" dynamicHeight>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="secondaryTab">
        <span class="type">Round Trip</span>
      </div>
    </ng-template>
    <app-round-trip-form [disabeled]="searchRequest?.offers != 'false'" [color]="'#000'" [inputCodeAirLine]="airLine"
                         [className]=""
                         (applyModificationEvent)="applyModificationEvent($event)" (searchDDone)="searchDone($event)"
                         [classTrip]="classTrip"
                         (searchInputClose)="close()"></app-round-trip-form>
  </mat-tab>
  <mat-tab *ngIf="searchRequest?.offers != 'false'">
    <ng-template mat-tab-label>
      <div class="secondaryTab">
        <span class="type">One Way</span>
      </div>
    </ng-template>
    <app-one-way-trip-form [color]="'#000'" [classTrip]="classTrip"
                           (applyModificationEvent)="applyModificationEvent($event)"
                           (searchInputClose)="close()"></app-one-way-trip-form>
  </mat-tab>
  <mat-tab *ngIf="searchRequest?.offers != 'false'">
    <ng-template mat-tab-label>
      <div class="secondaryTab">
        <span class="type">Multi City</span>
      </div>
    </ng-template>
    <app-multi-city-form [color]="'#000'" (applyModificationEvent)="applyModificationEvent($event)"
                         [classTrip]="classTrip"
                         (offsetHeight)="setOffsetHeight()" (searchInputClose)="close()"></app-multi-city-form>
  </mat-tab>
</mat-tab-group>
<!-- </mat-tab> -->
<!-- <mat-tab class="hotel-tab-search">
      <ng-template mat-tab-label>
        <div class="mainTab hotelsTab">
          <img alt="hotel" title="hotel" style="width: 36px;height: 36px;" src="/assets/images/icons/bed.png">
          <span class="type">Hotels</span>
        </div>
      </ng-template>
      <app-hotels-form></app-hotels-form>
    </mat-tab> -->
<!-- </mat-tab-group> -->
