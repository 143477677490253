
//import { Component, Injector, OnInit, AfterViewInit, OnDestroy, PLATFORM_ID, Input,EventEmitter, ViewChild, Output, Inject } from '@angular/core';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, PLATFORM_ID, Inject, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import { Router, ActivatedRoute, Params, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HotelSearchComponent } from 'src/app/components/hotel-search/hotel-search.component';
import { HotelsEngineComponent } from 'src/app/components/home/hotels-engine/hotels-engine.component';
import { Subject } from 'rxjs';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-new-hotels',
  templateUrl: './new-hotels.component.html',
  styleUrls: ['./new-hotels.component.css']
})
export class NewHotelsComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Output() hotelsDataflow: EventEmitter<string> = new EventEmitter<string>();

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayTimeout: 2000,
    dots: false,
    navSpeed: 300,
    nav: true,
    navText: ['<i class="fas fa-arrow-left bg-nav"></i>', '<i class="fas fa-arrow-right bg-nav"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },

  };
  items = Array.from({ length: 2 }).map((_, i) => `Item #${i}`);
  step = 0;
  id: number;
  vacationDetails: any;
  long: any;
  lat: any;
  carouselImages: any;
  aminities: any;
  branchId: any;
  loadingSpinner: any;

  hotelsData: any;
  imgHotel: any;
  hotels: any;
  requestId: string;
  clearFilter = false;
  loadings = [

    { width: '300px' },
    { width: '300px' },
    { width: '300px' },
    { width: '300px' },

  ];
  isBrowser: boolean;
  @Inject(PLATFORM_ID) platformId: any
  searchData: any;
  currentRoute: string;
  @ViewChild(HotelsEngineComponent)
  private hotelSearchComponent: HotelsEngineComponent;
  ngSliderEvent$ = new Subject();
  hotelCityLong;
  hotelCityLat;
  loading = false;
  resultsArrived = true;
  moreAmenities = false;
  moreAmenityFilters = false;
  minValue = 0;
  maxValue = 10000;

  options: Options = {
    floor: this.minValue,
    ceil: this.maxValue,
    draggableRange: true
  };
  collectionSize: any;
  hotelsPagination: any;
  page: any;

  constructor(private activatedRoute: ActivatedRoute,
    private hotelsService: HotelsApisService,
    private router: Router,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private hotelApis: HotelsApisService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    const hotelSearchDataStr = this.localStorage.getItem('searchRequest');
    this.searchData = hotelSearchDataStr ? JSON.parse(hotelSearchDataStr) : {};

    this.currentRoute = "";
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart && this.currentRoute != '/no-result') {
        this.currentRoute = event.url
      }
      if (event instanceof NavigationEnd && this.currentRoute != '/no-result') {

        this.currentRoute = event.url;

        // console.log(this.currentRoute);

        const hotelSearchDataStr = this.localStorage.getItem('searchRequest');
        this.searchData = hotelSearchDataStr ? JSON.parse(hotelSearchDataStr) : {};

        this.dataHandler()
        // console.log(this.searchData);

        this.hotelSearchAPI(this.searchData)


      }

      if (event instanceof NavigationError) {

        // Present error to user
        // console.log(event.error);
      }
    })


  }


  ngAfterViewInit() {
    this.fillSearchFields();
    this.hotelSearchComponent.submit();
  }

  ngOnDestroy() {
    this.ngSliderEvent$.unsubscribe();
  }

  ngOnInit(): void {
    //this.seoUpdate();
    this.dataHandler();
    this.hotelSearchAPI();
  }

  // data might be the one the user added in home page or what user added to do anther search in hotel-results page
  fillSearchFields() {

    // this.hotelSearchComponent.submit();

    this.changeDetection.detectChanges();
  }

  dataHandler(): void {
    this.searchData = this.route.snapshot.queryParams;
    if (!this.searchData) {
      this.router.navigate(['/']);
    } else {
      this.hotelCityLat = this.searchData?.latitude;
      this.hotelCityLong = this.searchData?.longitude;
      this.localStorage.setItem('hotelSearchData', JSON.stringify(this.searchData));
    }
  }

  hotelSearchAPI(searchData = this.searchData) {
    this.resultsArrived = false;
    this.loading = true;
    this.loadingSpinner = true;
    // this.disableSearch();
    this.searchData = searchData;
    this.resultsArrived = false;

    console.log("start ----------------");
    console.log(searchData);
    console.log("end ----------------");

    this.hotelApis.searchHotels(searchData).subscribe((data: any) => {
      this.resultsArrived = true;
      this.loading = false;
      this.loadingSpinner = false;
      this.localStorage.setItem('hotelSearchData', JSON.stringify(this.searchData));
      // searchData.SearchFromComponent is true when search is coming from hotelSearchComponent
      if (this.searchData.SearchFromComponent) {
        delete this.searchData.SearchFromComponent;
        // to update hotelSearchData in local storage with the new searchData
        this.minValue = 0;
      }
      this.hotelsPagination = data.data.pages
      this.collectionSize = this.hotelsPagination.total_item
      this.page = this.hotelsPagination.offset
      this.assignDataToVars(data.data);
      // this.enableSearch();
      // console.log( data.data);

    }, err => {
      if (this.isBrowser) {
        this.resultsArrived = false;
        this.loading = false;
        this.router.navigate(['/no-result']);
      }
    });
  }

  // this method gets searchResults as an argument and assign parts of it to variables
  assignDataToVars(searchResults) {
    this.hotels = searchResults.hotels || searchResults.details;
    if (this.hotels.length === 0) {
      this.router.navigate(['/no-result']);
      localStorage.removeItem('hotelSearchData')
    }
    this.requestId = searchResults.RequestID;

    /* asign min and max price */
    if (this.clearFilter) {
      this.minValue = searchResults.min_price;
      this.maxValue = searchResults.max_price;
      this.options = {
        floor: parseInt(searchResults.min_price.toString()),
        ceil: parseInt(searchResults.max_price.toString()),
        draggableRange: true
      };

      this.clearFilter = false;
    } else {
      if (this.minValue == 0) {
        this.minValue = searchResults.min_price;
        this.maxValue = searchResults.max_price;

        this.options = {
          floor: parseInt(this.minValue.toString()),
          ceil: parseInt(this.maxValue.toString()),
          draggableRange: true
        };
      } else {
        this.options = {
          floor: parseInt(searchResults.min_price.toString()),
          ceil: parseInt(searchResults.max_price.toString()),
          draggableRange: true
        };
      }
    }
  }

  routToHotelPage() {
    this.router.navigate(['vacation-hotel-details/', this.id]);
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addhotelsDataNew(e): void {

    this.hotelsData = e;
    this.imgHotel = this.hotelsData.images;

    // console.log('this.hotelsDatthis.imgHotel', this.imgHotel);

  }
  goToDetails(hotelCode) {
    this.router.navigate([`hotel-details/${hotelCode}`])
  }
  pageChange(page) {
    this.hotelApis.newResultPage(this.requestId, page).subscribe({
      next: (hotels: any) => {
        this.assignDataToVars(hotels.data)
      }
    })

  }
  // getHotelsDetails(){
  //   this.hotelsService.hotelsData().subscribe((res: any) => {
  //     this.hotelsData = res.data;
  //     console.log('this.hotelsDatthis.hotelsDat', this.hotelsData);
  //   });

  // }

}
