<div class="shadow-box d-flex align-items-center w-30 justify-content-between row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trip-info">
      <ng-container>
        <div class="d-flex ">
          <div>
            <span>{{searchRequest.fullOrigin}}</span>
          </div>
          <div class="mt-2">
            <i class="fas fa-arrow-right d-md-inline d-block "></i>
          </div>
          <div>
            <span>{{searchRequest.fullDestination}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="searchRequest.trip_type =='Return'">
        <div class="d-flex mt-4">
          <div>
            <span>{{searchRequest.fullDestination}}</span>
          </div>
          <div class="mt-2">
            <i class="fas fa-arrow-left d-md-inline d-block "></i>
          </div>
          <div>
            <span>{{searchRequest.fullOrigin}}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trip-calender mt-4">
    <div class="d-flex search-calender">
      <div class="departDate">
        <img title="calendar" alt="calendar" src="assets/images/icons/calendar.svg">
        <span>{{searchRequest?.departure_date | date: 'EEE, d MMM yyyy'}}</span>
      </div>
      <div class="mx-2">
        <img alt="calendar" title="calendar" src="assets/images/icons/calendar.svg">
        <span>{{searchRequest.return_date | date: 'EEE, d MMM yyyy'}}</span>
      </div>
    </div>
    <!-- <div class="modify-search">
      <button class="modify-button btn " (click)="hideSearch = !hideSearch;">Modify Your Search</button>
    </div> -->
    </div>
  </div>