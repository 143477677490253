<div class="home-logos">
  <div class="container-fluid">
    <div class="row position-relative">
      <owl-carousel-o *ngIf="airLines" [options]="customOptions">
        <ng-container *ngFor="let item of airLines let i = index" >
          <ng-template carouselSlide>
            <div class="">
              <div class="slide d-flex flex-column align-items-center" >
                <a class="d-block"  [routerLink]="['/airline-description/',item.callsign]" (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')">
                  <img class="d-block" src="{{item.image}}" alt="{{item.name}}" title="{{item.name}}"
                 >
                </a>
              </div>
              <div >
                <div class="icons" #popCard (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')" >
                  <div class="slide d-flex flex-column align-items-center" >
                    <a class="d-block my-1" [routerLink]="['/airline-description/',item.callsign]">
                      <img class="d-block handel_img" src="{{item.image}}" alt="{{item.name}}" title="{{item.name}}"
                      >
                    </a>
                  </div>
                  <a class="d-flex size-links Font-family" [routerLink]="['/Bagging-Information/',item.callsign]">
                    Baggage Allowance
                  </a>
                  <a class="d-flex size-links Font-family" [routerLink]="['/cancellation-policy/',item.callsign]">
                    Cancellation Policy
                  </a>
                  <a class="size-links d-flex Font-family" [routerLink]="['/Online-Check/',item.callsign]" >
                    Online Check
                  </a>
                  <div *ngIf="item.name == 'Saudi Arabian Airlines'">
                    <a class="mt-1 size-links d-flex Font-family" href="https://visa.visitsaudi.com/" target="_blank"> eVisa</a>
                  </div>
                  <a class="size-links d-flex Font-family" [routerLink]="['/FAQ/',item.callsign]" >
                    FAQ
                  </a>

                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>



