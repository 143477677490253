import {Component, Input, OnInit} from '@angular/core';
import {Options, LabelType, ChangeContext} from 'ng5-slider';

@Component({
  selector: 'app-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.css']
})
export class TimeSliderComponent implements OnInit {
  options: any;

  constructor() {
  }

  @Input() data: any;
  minValue: any;
  maxValue: any;

  ngOnInit(): void {
    this.departureTimeSlider();
  }

  departureTimeSlider(data = this.data) {
    const minValue = Number(data.min?.split(':')[0]) * 60 + Number(data.min.split(':')[1]) * 1000;
    const maxValue = Number(data.max.split(':')[0]) * 60 + Number(data.max.split(':')[1]) * 1000;
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.options = {
      floor: this.minValue,
      ceil: this.maxValue,
      draggableRange: true,
      step: 1000,
      translate: (value: number, label: LabelType): string => {
        const time = new Date(value);
        const hours = time.getUTCHours();
        const minutes = time.getUTCMinutes();
        return new Date(value).toTimeString().replace('GMT+0200 (Eastern European Standard Time)', 'AM');
      }
    };

  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    const startDate = new Date(changeContext.value).toTimeString().replace('(Eastern European Standard Time)', '');
  }

}
