<app-navbar></app-navbar>

<div class="about-page">
  <!-- start about-header -->
  <div class="about-header">
    <div class="overlay">
      <div class="container">
        <h1 class="text-center Font-Family">About Us</h1>
        <h1 class="title">Cheap Flight Tickets</h1>
      </div>
    </div>
  </div>
  <!-- end about-header -->

  <!-- start about-body -->
  <div class="about-body">
    <div class="bringing text-center" *ngIf="!aboutUsDataExists">
      <div class="container">
        <h2>
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '300px', height: '50px' }"
          ></ngx-skeleton-loader>
        </h2>
        <p>
          <ngx-skeleton-loader
            count="4"
            [theme]="{ width: '80%' }"
          ></ngx-skeleton-loader>
        </p>
        <div class="video-container">
          <!-- [ngClass]="{show: playVideo}" -->
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '100%', height: '500px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- start bringing -->
    <div class="bringing text-center" *ngIf="aboutUsDataExists">
      <div class="container">
        <h2 [innerHTML]="aboutUsData['title_content']" class="fao_mobile_title_large"></h2>
        <p [innerHTML]="aboutUsData['content']" class="Font-Family"></p>
        <!-- <div class="video-container">
          
          <div class="video" [ngClass]="{ show: playVideo }">
            <iframe
              #iframe
              frameborder="0"
              [src]="safeUrl"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="cover" (click)="play()" [ngClass]="{ hide: playVideo }">
            <img
              title="About Us Image"
              alt="cover-video"
              src="./assets/images/about/cover-video.png"
            />
            <i class="fas fa-play"></i>
          </div>
        </div> -->
      </div>
    </div>
    <!-- end bringing -->

    <!-- start skeleton preloader for start_mission -->
    <div class="mission text-center" *ngIf="!aboutUsDataExists">
      <div class="container">
        <h2>
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '300px', height: '50px' }"
          ></ngx-skeleton-loader>
        </h2>
        <p>
          <ngx-skeleton-loader
            count="4"
            [theme]="{ width: '80%' }"
          ></ngx-skeleton-loader>
        </p>
        <div class="video-container">
          <!-- [ngClass]="{show: playVideo}" -->
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '100%', height: '300px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- end skeleton preloader for start_mission -->
    <!-- start mission -->
    <div class="mission text-center" *ngIf="aboutUsDataExists">
      <div class="container">
        <h2 class="fao_mobile_title_large">Our Mission</h2>
        <p [innerHTML]="aboutUsData['mission_description']" class="Font-Family"></p>
        <img
          title="Our Mission"
          alt="mission1.1"
          src="./assets/images/about/new/mission.png"
        />
      </div>
    </div>
    <!-- end mission -->

    <!-- start deliver -->
    <div class="deliver text-center">
      <div class="container">
        <h2 [innerHTML]="aboutUsData['deliver_best_title']" class="fao_mobile_title_large"></h2>
        <p [innerHTML]="aboutUsData['deliver_best_description']" class="Font-Family"></p>
        <div class="img">
          <img
            title="Our Mission"
            alt="mission2.1"
            src="./assets/images/about/new/alternative dates 1.png"
          />
          <img
            title="Our Mission"
            alt="mission3.1"
            src="./assets/images/about/new/alternative dates2.png"
          />
        </div>
      </div>
    </div>
    <!-- end deliver -->

    <!-- start covered -->
    <div class="covered text-center">
      <div class="container">
        <h2 [innerHTML]="aboutUsData['covered_title']" class="fao_mobile_title_large mt-4"></h2>
        <p [innerHTML]="aboutUsData['covered_description']" class="Font-Family"></p>
        <div class="img">
          <img
            title="Our Mission"
            alt="mission4.1"
            src="./assets/images/about/new/things to do.png"
          />
          <img
            title="Our Mission"
            alt="mission5.1"
            src="./assets/images/about/new/things to do_2.png"
          />
        </div>
      </div>
    </div>
    <!-- end covered -->

    <!-- start team -->
    <div class="team text-center">
      <div class="team-header">
        <div class="overlay">
          <div class="container">
            <h2 class="text-center fao_mobile_title_large"  style="color: #fff !important;">Our Team</h2>
          </div>
        </div>
      </div>
      <div class="team-body">
        <p [innerHTML]="aboutUsData['our_team_description']" class="Font-Family"></p>
      </div>
    </div>
    <!-- end team -->

    <!-- start reach -->
    <div class="reach">
      <div class="container">
        <form
          [formGroup]="contactUsForm"
          (ngSubmit)="onSubmitContactUsForm(contactUsForm.value)"
          *ngIf="!formSubmitted"
        >
          <h2 class="text-center fao_mobile_title_meduim"> We Aim to Reach you Anytime And Anywhere </h2>
          <div class="form">
            <div class="field">
              <label
                for="name"
                class="Font-Family"
                [ngClass]="
                  contactUsForm.get('fullName').invalid &&
                  contactUsForm.get('fullName').touched
                    ? 'text-danger'
                    : ''
                "
                >Full Name</label
              >
              <input
                id="name"
                type="text"
                formControlName="fullName"
                required
                [ngClass]="
                  contactUsForm.get('fullName').invalid &&
                  contactUsForm.get('fullName').touched
                    ? 'has-error'
                    : 'no-error'
                "
                (keypress)="navigateOnSamePage()"
              />
              <span *ngFor="let validation of validationMessages.fullName">
                <small
                  class="text-danger"
                  *ngIf="
                    contactUsForm.get('fullName').hasError(validation.type) &&
                    (contactUsForm.get('fullName').dirty ||
                      contactUsForm.get('fullName').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="field">
              <label
                for="email"
                class="Font-Family"
                [ngClass]="
                  contactUsForm.get('email').invalid &&
                  contactUsForm.get('email').touched
                    ? 'text-danger'
                    : ''
                "
                >Email Address</label
              >
              <input
                id="email"
                type="email"
                formControlName="email"
                required
                [ngClass]="
                  contactUsForm.get('email').invalid &&
                  contactUsForm.get('email').touched
                    ? 'has-error'
                    : 'no-error'
                "
                (keypress)="navigateOnSamePage()"
              />
              <span *ngFor="let validation of validationMessages.email">
                <small
                  class="text-danger"
                  *ngIf="
                    contactUsForm.get('email').hasError(validation.type) &&
                    (contactUsForm.get('email').dirty ||
                      contactUsForm.get('email').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="field">
              <label
                for="mobile"
                class="Font-Family"
                [ngClass]="
                  contactUsForm.get('mobileNumber').invalid &&
                  contactUsForm.get('mobileNumber').touched
                    ? 'text-danger'
                    : ''
                "
                >Mobile Number</label
              >
              <input
                id="mobile"
                type="text"
                formControlName="mobileNumber"
                required
                [ngClass]="
                  contactUsForm.get('mobileNumber').invalid &&
                  contactUsForm.get('mobileNumber').touched
                    ? 'has-error'
                    : 'no-error'
                "
                (keypress)="navigateOnSamePage()"
              />
              <span *ngFor="let validation of validationMessages.mobileNumber">
                <small
                  class="text-danger"
                  *ngIf="
                    contactUsForm
                      .get('mobileNumber')
                      .hasError(validation.type) &&
                    (contactUsForm.get('mobileNumber').dirty ||
                      contactUsForm.get('mobileNumber').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="field">
              <label
                for="subject"
                class="Font-Family"
                [ngClass]="
                  contactUsForm.get('subject').invalid &&
                  contactUsForm.get('subject').touched
                    ? 'text-danger'
                    : ''
                "
                >Subject</label
              >
              <input
                id="subject"
                type="text"
                formControlName="subject"
                required
                [ngClass]="
                  contactUsForm.get('subject').invalid &&
                  contactUsForm.get('subject').touched
                    ? 'has-error'
                    : 'no-error'
                "
                (keypress)="navigateOnSamePage()"
              />
              <span *ngFor="let validation of validationMessages.subject">
                <small
                  class="text-danger"
                  *ngIf="
                    contactUsForm.get('subject').hasError(validation.type) &&
                    (contactUsForm.get('subject').dirty ||
                      contactUsForm.get('subject').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div class="field">
              <label
                for="message"
                class="Font-Family"
                [ngClass]="
                  contactUsForm.get('message').invalid &&
                  contactUsForm.get('message').touched
                    ? 'text-danger'
                    : ''
                "
                >Your Message</label
              >
              <textarea
                id="message"
                placeholder="Tell us anything you want to.."
                rows="8"
                formControlName="message"
                required
                [ngClass]="
                  contactUsForm.get('message').invalid &&
                  contactUsForm.get('message').touched
                    ? 'has-error'
                    : 'no-error'
                "
              ></textarea>
              <span *ngFor="let validation of validationMessages.message">
                <small
                  class="text-danger"
                  *ngIf="
                    contactUsForm.get('message').hasError(validation.type) &&
                    (contactUsForm.get('message').dirty ||
                      contactUsForm.get('message').touched)
                  "
                >
                  {{ validation.message }}</small
                >
              </span>
            </div>
            <div>
              <re-captcha
                required
                (resolved)="resolved($event)"
                [siteKey]="siteKey"
              >
              </re-captcha>
            </div>
            <div class="field submit">
              <button
                type="submit"
                [ngClass]="
                  contactUsForm.valid && captchaKey
                    ? 'button active'
                    : 'button disabled'
                "
                type="submit"
                [disabled]="!contactUsForm.valid && !captchaKey"
              >
                SEND
              </button>
            </div>
          </div>
        </form>
        <div class="inside text-center" *ngIf="formSubmitted">
          <h2>Thank you!</h2>
          <h6>
            Your message has been received and we will be contacting you shortly
            to follow up
          </h6>
          <div class="img">
            <img
              title="Email-Image"
              alt="mail"
              src="./assets/images/help-center/mail.svg"
              width="80"
            />
          </div>
          <a href="#">Go back to homepage <i class="fas fa-angle-right"></i></a>
        </div>
      </div>
    </div>
    <!-- end reach -->
  </div>
  <!-- end about-body -->
</div>

<app-footer></app-footer>
