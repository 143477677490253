import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-view-review-details',
  templateUrl: './view-review-details.component.html',
  styleUrls: ['./view-review-details.component.css']
})
export class ViewReviewDetailsComponent implements OnInit {
  title:string;
  description:string;
  image:string;
  rate:any;
  first_name:string;
  last_name:string;
  id:number;
  constructor(
    private seoService: SEOService,
    private _ActivatedRoute:ActivatedRoute
  ) {
    this.id = this._ActivatedRoute.snapshot.params.id
    this._ActivatedRoute.queryParams.subscribe((querParams)=>{
      this.title = querParams.title;
      this.description = querParams.description;
      this.image = querParams.image;
      this.first_name = querParams.first_name;
      this.last_name = querParams.last_name;
      this.rate = querParams.rate;
      // console.log(querParams);
      let meta_title = `${this.first_name} ${this.last_name} added a ${this.description} for ${querParams.blogslug} with ${this.rate} stars`
      this.seoUpdate(meta_title, querParams.description , querParams.image);
    })
   }

  ngOnInit(): void {
  }
  seoUpdate(title , description , image) {
    this.seoService.updateTitle(title);
    this.seoService.updateDescription(description);
    this.seoService.updateUrl();
    this.seoService.updateImage(image);
  }

}
