import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-itinerary',
  templateUrl: './payment-itinerary.component.html',
  styleUrls: ['./payment-itinerary.component.css']
})
export class PaymentItineraryComponent implements OnInit {
  @Input() searchRequest: any;
  @Input() openDetails: any = false;
  @Input() itineraries;
  @Input() offers;
  @Input() itineraryNumber;
  // private isButtonVisible = true;
  countDown: any;
  counter = (30 * 60);
  tick = 1000;
  dialogRef: any;
  select_Flights:any[]=[];
  constructor() { }

  ngOnInit(): void {
  }

}
