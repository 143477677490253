<app-navbar></app-navbar>
<div class="container" >
  <!-- search-Engine  -->
  <div class="row afterNav">
    <div class="col-12 pt-5">
      <app-engine></app-engine>
    </div>
  </div>


<div class="row g-0">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="flexDiv" style="justify-content: space-between">
          <div class="ml-4 name-handel mt-3 py-2">
            <h1>Domestic Travel During COVID-19</h1>
            <p>Information for People Traveling within the United States and U.S. Territories</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">

        <div class="container">
            <img
            src="../../../assets/images/home-slider/1.png"
            alt="home slider"
            width="100%"
            height="420"
            style="border-radius: 12px"
          />
        </div>
    </div>
    <div class="col-4">
        <h3> Details </h3>
        <ul>
          <li><strong> Date</strong>:Feb. 25, 2022</li>
          <li><strong> URL</strong>: <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-during-covid19.html">Domestic Travel During COVID-19</a></li>
        </ul>
      <div class="description mt-5">
        <h2>More Information</h2>
        <li><a href="#">Frequently Asked Questions about Travel and COVID-19</a></li>
        <li><a href="#">Protect Yourself and Loved Ones When Gathering with Friends and Family</a></li>
        <li><a href="#">International Travel</a></li>
      </div>


    </div>
    </div>
  </div>
</div>

</div>

<!-- <div class="row mt-5 text-center">
  <div class="col">
      <h3>Domestic Travel</h3>
  </div>
  <div class="col">
      <h3>International Travel</h3>
  </div>
  <div class="col">
    <h3>Cruise Ship Travel</h3>
  </div>

</div> -->
<section class="travels  d-flex justify-content-center">
  <div class="row">
    <div class="section-header text-center">
      <h2 class="pt-3 mt-5">Travel</h2>
      <div
        style="
          background-color: #000000;
          height: 5px;
          width: 100px;
          margin: auto;
          border-radius: 2px;
        "
        class="mb-5"
      ></div>
    </div>
    <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
      <div class="icon-box text-center">
        <div class="icon"><img src="../../../assets/images/slider-home/Alitalia.png" alt="Altialia" style="width: 100%; height: 180px; margin-bottom: 10px;"></div>
        <h4 class="title"><a href="">Domestic Travel </a></h4>

      </div>
    </div>

    <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
      <div class="icon-box text-center">
        <div class="icon"><img src="../../../assets/images/slider-home/egyptair.png" style="width: 100%;  height: 180px; margin-bottom: 10px;"></div>
        <h4 class="title"><a href="">International Travel </a></h4>

      </div>
    </div>

    <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
      <div class="icon-box text-center">
        <div class="icon"><img src="../../../assets/images/slider-home/Delta.png" style="width: 100%;  height: 180px; margin-bottom: 10px;"></div>
        <h4 class="title"><a href="">Cruise Ship Travel </a></h4>

      </div>
    </div>

  </div>

</section>

<section class="travels ">
  <div class="container">
    <div class="row d-flex justify-content-start">
      <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box">
          <h2 class="my-3">Before You Travel</h2>
          <li>Check the current <a href="#">COVID-19 situation at your destination.</a></li>
          <li>Make sure you understand and follow all state, tribal, local, and territorial travel restrictions, including proper mask wearing, proof of vaccination, testing, or quarantine requirements.</li>
          <p> - For up-to-date information and travel guidance, check the <a href="">state, tribal, local and territorial</a> health department’s website where you are, along your route, and where you are going.</p>
          <li>If traveling by air, check if your airline requires any testing, vaccination, or other documents.</li>
          <li>Prepare to be flexible during your trip as restrictions and policies may change during your travel.</li>
        </div>
      </div>
    </div>

    <div class="row ">
      <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box">
          <h2 class="my-4">During Travel</h2>
          <div class="d-flex ">
          <img src="../../../assets/images/icon-travel/head-side-mask-solid.svg" class="" height="50px">
          <h3 class="p-3"> Masks </h3>
        </div>
      <div class="px-5">
          <li> <a href="">Wearing a mask over your nose and mouth is required</a> in indoor areas of public transportation (including on airplanes, buses, trains, and other forms of public transportation) traveling into, within, or out of the United States and indoors in U.S. transportation hubs (including airports and stations). Travelers are not required to wear a mask in outdoor areas of a conveyance (like on open deck areas of a ferry or the uncovered top deck of a bus).</li>
        </div>
        <div class="d-flex ">
          <img src="../../../assets/images/icon-travel/AdobeStock_66468826_Preview.svg" class="" height="80px">
          <h3 class="mt-4">Protect Yourself and Others </h3>
        </div>
          <div class="px-5">
            <li>Follow all  state, tribal, local, and territorial health recommendations and requirements at your destination, including properly wearing a well-fitting mask and following recommendations for protecting yourself and others.</li>  <p> - For up-to-date information and travel guidance, check the <a href="">state, tribal, local and territorial</a> health department’s website where you are, along your route, and where you are going.</p>
            <li>Travelers 2 years of age or older should wear well-fitting masks in indoor public places if they:</li>
                <p>- Are in an area where the <a href="">COVID-19 Community Level</a> is high</p>
                <p>- Are in an area where the COVID-19 Community Level is highAre immunocompromised or at high risk for severe disease</p>
            <li>In general, you do not need to wear a mask in outdoor settings.</li>
            <p>- In areas with <a href="">high numbers of COVID-19 cases,</a> consider properly wearing a well-fitting mask in crowded outdoor settings and for activities with close contact with others who are not up to date on their COVID-19 vaccines.</p>
            <li> <a href="">Wash your hands</a> often with soap and water or use hand sanitizer with at least 60% alcohol.</li>
          </div>
        </div>
      </div>
    </div>

    <div class="row ">
      <div class="col d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box">
          <h2 class="my-3">After Travel</h2>
          <p>You might have been exposed to COVID-19 on your travels. You might feel well and not have any symptoms, but you can still be infected and spread the virus to others.</p>
          <!-- <h4>If you are NOT Up to Date with your COVID-19 Vaccines</h4> -->
          <div class="d-flex ">
            <img src="../../../assets/images/icon-travel/test-tube.png" class="mt-3" height="50px">
            <h3 class="py-3 mt-3">If you are NOT Up to Date with your COVID-19 Vaccines </h3>
          </div>
          <div class="px-5">
          <h5>Self-quarantine and get tested after travel:</h5>
          <li>Get tested with a <a href="#">viral test</a>3-5 days after returning from travel.</li>
          <p class="px-3">- Check<a href=""> for COVID-19 testing locations near youexternal icon</a></p>
          <li>Stay home and self-quarantine for a full 5 days after travel.</li>
          <li>Follow additional recommendations below for ALL travelers.</li>
        </div>
          <div class="d-flex ">
            <img src="../../../assets/images/icon-travel/test-tube.png" class="mt-3" height="50px">
            <h3 class="py-3 mt-3">ALL Travelers </h3>
          </div>
          <div class="px-5">
          <li>Self-monitor for <a href=""> COVID-19 symptoms</a> ; isolate and get <a href="">tested</a> external icon if you develop symptoms.</li>
          <li>Follow all <a href="">state</a>, <a href="">tribal</a>, <a href="">local</a>, <a href="">and territorial</a>  recommendations or requirements after travel.</li>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
