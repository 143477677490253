<app-navbar></app-navbar>
<div class="help-center-page">
    <div class="help-center-header">
        <img
                *ngIf="backgroundImage"
                [src]="backgroundImage"
                alt="{{ state }}, {{ country }} | flyallover"
                title="pcr location"
        />
        <div class="title container">
            <h1 class="text-uppercase">pcr location in {{ state }}</h1>
        </div>
    </div>
    <div class="help-center-body container" *ngIf="!dataArrived && !backendError">
        <div class="topics">
            <div class="row">
                <div class="topic skeleton" *ngFor="let item of createRange(4)">
                    <h6>
                        <ngx-skeleton-loader
                                count="1"
                                [theme]="{ width: '250px', height: '20px' }"
                        ></ngx-skeleton-loader>
                    </h6>
                    <p class="update-text">
                        <ngx-skeleton-loader
                                count="1"
                                [theme]="{ width: '150px', height: '15px' }"
                        ></ngx-skeleton-loader>
                    </p>
                    <div class="topic-middle">
                        <div class="text">
                            <p>
                                <ngx-skeleton-loader
                                        count="5"
                                        [theme]="{ width: '100%', height: '10px', display: 'block' }"
                                >
                                </ngx-skeleton-loader>
                            </p>
                        </div>
                        <div>
                            <div *ngFor="let item of createRange(4)">
                                <ngx-skeleton-loader
                                        class="text-left"
                                        count="1"
                                        [theme]="{ width: '200px', height: '10px' }"
                                >
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <tr class="heads">
                                <th *ngFor="let item of createRange(5)">
                                    <ngx-skeleton-loader
                                            count="1"
                                            [theme]="{ width: '100px', height: '20px' }"
                                    >
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                            <tr>
                                <td *ngFor="let item of createRange(5)">
                                    <ngx-skeleton-loader
                                            count="1"
                                            [theme]="{ width: '100px', height: '10px' }"
                                    >
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let item of createRange(5)">
                                    <ngx-skeleton-loader
                                            count="1"
                                            [theme]="{
                      width: '100px',
                      height: '10px',
                      display: 'block'
                    }"
                                    >
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="backendError" class="help-center-body container">
        <h1 class="text-danger text-center">
            We have no data for testing locations in {{ state }} yet
        </h1>
    </div>

    <div class="help-center-body container" *ngIf="dataArrived && !backendError">
        <div class="topics">
            <div class="row">
                <div
                        [id]="location.name"
                        class="topic"
                        *ngFor="let location of locations; let i = index"
                >
                    <div class="col">
                        <a>
                            <h4>{{ location?.name }}</h4>

                            <div class="topic-middle">
                                <div class="text">
                                    <p>{{ location?.description }}</p>
                                </div>
                                <div>
                                    <div *ngIf="location?.physical_address?.address">
                                        {{ location?.physical_address?.address }}
                                    </div>
                                    <div *ngIf="location?.physical_address?.city">
                                        {{ location?.physical_address?.city }},
                                        {{ location?.physical_address?.state_province }}
                                        {{ location?.physical_address?.postal_code }}
                                    </div>
                                    <div *ngIf="location?.physical_address?.map">
                                        <a
                                                href="{{ location?.physical_address?.map }}"
                                                target="_blank"
                                        >
                                            View on map
                                        </a>
                                    </div>
                                    <div *ngIf="location?.phone?.number" class="mt-2">
                                        Telephone number: <a> {{ location?.phone?.number }} </a>
                                    </div>
                                    <div *ngIf="location?.phone?.language">
                                        Language: {{ location?.phone?.language }}
                                    </div>
                                </div>

                                <div class="col">
                                    <div *ngIf="location" class="col social text-right">
                                        <div class="social-images">
                                            <a
                                                    class="image-item link-sharing"
                                                    (click)="close_status = false"
                                            >
                                                <img
                                                        title="share image"
                                                        alt="share"
                                                        src="./assets/images/things-todo/share.svg"
                                                />
                                            </a>
                                            <a
                                                    class="image-item" rel="nofollow"
                                                    href="https://www.facebook.com/sharer/sharer.php?u={{hostUrl}}{{ url }}"
                                                    target="_blank">
                                                <img
                                                        title="facebook image"
                                                        alt="facebook"
                                                        src="./assets/images/things-todo/facebook.png"
                                                />
                                            </a>
                                            <a class="image-item" rel="nofollow"
                                               href="https://twitter.com/intent/tweet?url={{hostUrl}}{{ url }}"
                                               target="_blank"
                                            >
                                                <img title="twitter image" alt="twitter"
                                                     src="./assets/images/things-todo/twitter.png"
                                                />
                                            </a>
                                            <a
                                                    class="image-item"
                                                    rel="nofollow"
                                                    href="https://www.linkedin.com/shareArticle?mini=true&url={{hostUrl }}{{ url }}&title={{ location?.name }}"
                                                    target="_blank"
                                            >
                                                <img
                                                        title="linkedin image"
                                                        alt="linkedin"
                                                        src="./assets/images/things-todo/linkedin.png"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <app-share-popup
                                            [close_status]="close_status"
                                            [viewSocialLinks]="false"
                                            [linkToBeShared]="linkToBeShared"
                                            (close)="close_status = true"
                                    ></app-share-popup>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table overflow-hidden">
                                    <tr class="heads">
                                        <th *ngIf="weekDays[i][1]">Monday</th>
                                        <th *ngIf="weekDays[i][2]">Tuesday</th>
                                        <th *ngIf="weekDays[i][3]">Wednesday</th>
                                        <th *ngIf="weekDays[i][4]">Thursday</th>
                                        <th *ngIf="weekDays[i][5]">Friday</th>
                                        <th *ngIf="weekDays[i][6]">Saturday</th>
                                        <th *ngIf="weekDays[i][7]">Sunday</th>
                                    </tr>
                                    <tr>
                                        <td *ngIf="weekDays[i][1]">
                                            {{ weekDays[i][1]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][2]">
                                            {{ weekDays[i][2]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][3]">
                                            {{ weekDays[i][3]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][4]">
                                            {{ weekDays[i][4]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][5]">
                                            {{ weekDays[i][5]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][6]">
                                            {{ weekDays[i][6]?.opens_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][7]">
                                            {{ weekDays[i][7]?.opens_at }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="weekDays[i][1]">
                                            {{ weekDays[i][1].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][2]">
                                            {{ weekDays[i][2].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][3]">
                                            {{ weekDays[i][3].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][4]">
                                            {{ weekDays[i][4].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][5]">
                                            {{ weekDays[i][5].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][6]">
                                            {{ weekDays[i][6].closes_at }}
                                        </td>
                                        <td *ngIf="weekDays[i][7]">
                                            {{ weekDays[i][7].closes_at }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
