<ng-container *ngIf="offers != 'false' && payLater!='true'">
  <app-review-flight-add-traveller-form (selectTravellerEvent)="selectTraveller($event)" [searchRequest]="searchRequest"
    (setOwnerEvent)="setOwner($event)">
  </app-review-flight-add-traveller-form>
</ng-container>
<ng-container *ngIf="offers == 'false'|| payLater=='true'">
  <app-app-review-flight-offer-add-traveller-form [payLater]="payLater" (setPayLater)="getPayLater($event)"
    (selectTravellerEvent)="selectTraveller($event)" [searchRequest]="searchRequest" (setOwnerEvent)="setOwner($event)"
    [familyMembers]="familyMembers">
  </app-app-review-flight-offer-add-traveller-form>
</ng-container>
<div class="navigationButtons">
  <div class="col-12">
    <div class="row align-items-center justify-content-center flex-row">
      <button class="btn previousStepBtn navBtn Font-Family" (click)="previousStepEvent.emit()">Back To <br> Review
        Flight</button>
      <button class="btn nextStepBtn navBtn Font-Family" [disabled]="!validTravellers" *ngIf="payLater!='true'"
        (click)="nextStepEvent.emit()">
        Continue to Payment
      </button>
      <button class="btn nextStepBtn navBtn Font-Family " [disabled]="!validTravellers" *ngIf="payLater=='true'"
        (click)="createPnr()">
        Contiue Booking<i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
      </button>
      <!-- &<br><p style="font-size: 35px;text-transform: uppercase;">pay within 24 hours</p> -->
      <!-- *ngIf="loading" -->
    </div>
  </div>
</div>
