<div class="billing-information review-trip-section my-3" *ngIf="offers == 'false' && !loading">
  <ng-container>
    <ng-container *ngIf="!messageError">
      <div class="w-100 row">
        <span class="price col-4">PRICE :</span>
        <span class="price amount_returned col-4">{{dataPnr?.amount_returned | currency}}</span>
        <ng-container *ngIf="dataPnr?.amount_returned == dataPnr?.amount_specified">
          <span class="price text-danger col-4">There is no discount applied.</span>
        </ng-container>
        <ng-container *ngIf="dataPnr?.amount_returned != dataPnr?.amount_specified">
        <span class="price amount_specified col-4">{{dataPnr?.amount_specified | currency}}</span>
        </ng-container>
      </div>
      <hr>
      <div class="w-100 row">
        <span class="price col-4" *ngIf="dataPnr?.amount_returned_for_child">PRICE FOR CHILD :</span>
        <span class="price amount_returned col-4">{{dataPnr?.amount_returned_for_child | currency}}</span>
        <ng-container *ngIf="dataPnr?.amount_returned == dataPnr?.amount_specified">
          <span class="price text-danger col-4">There is no discount applied.</span>
        </ng-container>
        <ng-container *ngIf="dataPnr?.amount_returned != dataPnr?.amount_specified">
        <span class="price amount_specified col-4">{{dataPnr?.amount_specified_for_child | currency}}</span>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="messageError">
      <p class="alert alert-danger ">{{messageError}} </p>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="loading">
  <div class="box-img mb-3">
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
  <p class="mx-auto price-loading">Please wait we are working on getting you trip price </p>
  <!-- <i class="fas fa-spinner fa-spin airLineSpinner ml-2"></i> -->
</ng-container>
<div class="alert-body mb-3" *ngIf="messageError">
  <div class="alert-text col-12">
    <div class="alert-text  p-0">
      <div class="alert-Info">i</div>
      <label class="form-check-label " style="color: #fd5021;">We are unable to complete this booking.
      </label>
    </div>
  </div>
  <div class="alert-text ms-2 col-12" style="margin-left: 3.5rem!important;">
    <div class="form-check p-0 ">
      <label class="form-check-label" style="
      font-weight: 500 !important;
  ">You're on the final step. Only a few more minutes to finish. Please provide a valid credit or debit card number
</label>
    </div>
  </div>
</div>
<div class="billing-information review-trip-section" *ngIf="!loading && !messageError">
  <form [formGroup]="form">
    <div class="row title">
    </div>
    <div class="row form border1 herefounddate">
      <div class="col-12">
        <h6 class=" title">Billing Details</h6>
        <p>
          Please Enter your card details to proceed the payment process as written on your card to avoid any mistakes.
        </p>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-6 item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Name On Card</label>
            </div>
            <input formControlName="cardHolderFullName" type="text" class="form-control"
              placeholder="please Enter Name On Card" id="cardHolderFullName"
              (ngModelChange)="validationForm('cardHolderFullName')" autocomplete="off" required minlength="1"
              maxlength="50" />
            <mat-error *ngIf="form.controls.cardHolderFullName.errors?.pattern">
              Please Type Only Letters At least Three Names</mat-error>
            <mat-error
              *ngIf="form.controls.cardHolderFullName.errors?.required && form.controls.cardHolderFullName.dirty">
              Name On Card Is Requied</mat-error>
          </div>
          <div class="col-6 position-relative">
            <div class="item ">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Card Number</label>
              </div>


              <div class="position-relative">
                <input type="text" formControlName="card" class="num form-control" maxlength="16"
                  placeholder="Please Enter Card Number" autocomplete="off"
                  (ngModelChange)="testCreditCard();validationForm('card')" id="card" required />
                <img *ngIf="vendorCode" class="vendor-image" alt="vendor-image" title="Vendor Image"
                  src="assets/images/hotels/{{vendorCode}}.png">
              </div>
              <mat-error *ngIf="form.controls.card.errors?.pattern"> Please Type Only
                Numbers</mat-error>
                <mat-error *ngIf="form.controls.card.errors?.pattern"> Card Number Should be 16 Number
                </mat-error>
            </div>
          </div>

          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">cvc</label>
              </div>
              <input maxlength="4" formControlName="cvc" (ngModelChange)="validationForm('cvc')" type="text"
                class="num form-control" placeholder="cvc" id="cvc" autocomplete="off" required />
                <img (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" style="top: 60%;" class="vendor-image" alt="" title="Vendor Image"
                  src="assets/images/hotels/cvc.jpg">
            </div>
          </div>
          <div #dialog (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" class="col-9 position-absolute dialog-cvv">
            <div class="col-12">
            <h1>Why do we need this?</h1>
            <p>to Help us keep your data safe! Please provide us with your credit card verification number for added security measures.</p>
            </div>
            <div class="col-12 d-flex">
              <div class="col-6">
                <img src="assets/images/hotels/visa-cvv.png" alt="">
                <h2>The verification number for Visa, MasterCard, Discover, Diners Club and Carte Blanche</h2>
                <p>is the 3-digit number on the back of the credit card.</p>
              </div>
              <div class="col-6">
                <img src="assets/images/hotels/American-express-cvv.png" alt="">
                <h2>The Verification number for American Express credit cards</h2>
                <p>is the 4-digit number located on the front of the card.</p>
              </div>
            </div>
          </div>

          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Expiration Date</label>
              </div>
              <div class="d-flex justify-content-between " (keyup)="checkDate()">
                <div class="col-5">
                  <input type="text" formControlName="CreditCardExpireDateMonth" placeholder="MM"
                    class="num month form-control text-center"  required maxlength="2"
                    (ngModelChange)="validationForm('CreditCardExpireDateMonth')" id="CreditCardExpireDateMonth"
                    (change)="updateExpireDate()" (focusout)="format($event)" autocomplete="off" />

                </div>
                <div class="col-6">
                  <input type="text" formControlName="CreditCardExpireDateYear" placeholder="YYYY"
                    class="num year form-control text-center" required
                    (ngModelChange)="validationForm('CreditCardExpireDateYear')" id="CreditCardExpireDateYear"
                    (change)="updateExpireDate()" autocomplete="off" />
                </div>
              </div>
            </div>
          </div>
          <div class="errors col-12 position-relative ">
            <div class="mat-errors">
              <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern"><p>Please Type Only 3 Numbers</p>
              <p>4 Numbers for American Express</p></div>
              <div class="b-2" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                Type Only Two Numbers Such As 07</div>
              <div class="b-2" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
              <div class="b-2" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please Type
                Only Four Numbers Such As 2022</div>
              <div class="b-2" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row form billing
    " *ngIf="offers != 'false'">
      <div class="col-12 ">
        <h6 class="title">Billing Address</h6>
        <p>Your billing address is totally secure and confidential.</p>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
         <div class="col-6 position-relative">
            <div class="item">
                  <div class="d-flex">
                    <span class="star"></span>
                    <label class="date-label">Address</label>
                  </div>
              <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" formControlName="CreditCardstreetAddress" class="form-control input" />
              <!-- <p>Address: {{ userAddress }}</p> -->
          </div>
        </div>
        <div class="col-6 position-relative">
          <div class="item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Country</label>
            </div>
            <!-- [matAutocomplete]="country_id"  -->
            <!-- (click)="$event.target.select()" -->
            <!-- (ngModelChange)="validationCountry('CreditCardCountry')" -->
            <input class="form-control" type="text"
              formControlName="CreditCardCountry" autocomplete="on" placeholder="Select your Country"
               id="CreditCardCountry"
               [ngModel]="country" (ngModelChange)="onChangeCountry($event)">
            <!-- <mat-autocomplete #country_id="matAutocomplete">
              <mat-option *ngFor="let option of filteredCountries | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete> -->
          </div>
          <mat-error *ngIf="form.get('CreditCardCountry').errors?.someProp">
            {{form.get('CreditCardCountry').errors?.someProp}}
          </mat-error>
          <mat-error *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].dirty"> Country Is
            Required
          </mat-error>
        </div>
            <div class=" col-6 position-relative ">
              <div class="item letters">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">City</label>
                </div>
                <input formControlName="CreditCardCity" type="text" class="form-control" placeholder="City"
                (ngModelChange)="onChangeCity($event)" id="CreditCardCity" autocomplete="off" required  [ngModel]="city"/>
                <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only Letters</mat-error>
                <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is Required
                </mat-error>
              </div>
            </div>

            <div class="col-6 position-relative">
              <div class="item">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">Zip</label>
                </div>
                <input formControlName="CreditCardZip" type="text" class="num form-control" placeholder="Zip"
                  (ngModelChange)="onChangeZip($event)" id="CreditCardZip" autocomplete="off" required [ngModel]="ZipCode" />
              </div>
              <!-- <mat-error *ngIf="form.controls.CreditCardZip.errors?.pattern"> Please Type Only
                Numbers
              </mat-error> -->
              <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
              </mat-error>
            </div>

            <div class="col-6 position-relative">
              <div class="item">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">State</label>
                </div>
                <input formControlName="State" type="text" placeholder="State Code" class="form-control"
                (ngModelChange)="onChangeState($event)" id="State" autocomplete="off"
                  required [ngModel]="state" />
                <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
                </mat-error>
                <mat-error *ngIf="f['State'].errors?.pattern" >Please Type Only Letters</mat-error>
              </div>
            </div>
            <div class="col-6 position-relative">
              <div class="item">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">creditCardRegion</label>
                </div>
                <input formControlName="creditCardRegion" type="text" placeholder="credit Card Region" class="form-control"
                (ngModelChange)="onChangeState($event)" id="creditCardRegion" autocomplete="off"
                    />

              </div>
            </div>


          <div class="col-12 agree-share-contact my-3">
            <input type="checkbox" id="agreeShareContact" name="agreeShareContact" checked >
            <label for="agreeShareContact">I agree to share this contact information with the airline(s) associated with this booking</label>
          </div>

        </div>
      </div>
    </div>
    <div class="row form billing
    " *ngIf="offers != 'false'">
      <div class="col-12 ">
        <h6 class="title">contact billing information</h6>
        <p class="text-danger">we kindly request your attention to ensure that your credit card information matches your contact information. In case of any discrepancies, further information may be required to process your request efficiently.</p>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Contact Email</label>
              </div>
              <input formControlName="contact_email" type="email" placeholder="exmple@flyallover.com" class="form-control" [(ngModel)]="contactUserEmail"

                required  />
              <mat-error *ngIf="f['contact_email'].errors?.required  && f['contact_email'].dirty"> Email Is Required
              </mat-error>
              <mat-error *ngIf="f['contact_email'].errors?.email" >Please you email</mat-error>
            </div>
          </div>
          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Contact Phone</label>
              </div>
              <input formControlName="contact_phone" type="text" placeholder="1234567890" class="form-control" [(ngModel)]="contactUserPhone"

              required/>
              <mat-error *ngIf="f['contact_phone'].errors?.required  && f['contact_phone'].dirty">Phone Is Required
              </mat-error>
            </div>
          </div>
           <span for="agreeShareContact">By providing your phone number, you are giving permission to the Lead Management team to reach out to you via SMS/TEXT messages.Kindly review our
            <a href="https://flyallover.com/privacy" title="go to privacy policy for more details" style="text-decoration: none;">privacy policy</a>  for more details</span>
        </div>
      </div>
    </div>
    <div class="text-danger text-center blockquote" *ngIf="!checkName">
      If the information provided for the traveler does not match the information on the Credit Card, additional
      verification may be required. This could involve contacting the cardholder to verify the transaction or requesting
      additional documentation to confirm the traveler's identity. In some cases, a fee of $5.00 may be charged for the
      extra security verification process.
    </div>
  </form>
</div>
<div class="navigationButtons">
  <div class="col-12 my-4">
    <div class="row justify-content-center d-flex flex-row align-items-center">
      <button   class="btn navBtn previousStepBtn" (click)="previousStepEvent.emit()">Back To Travellers Details</button>
      <button  [disabled]="form.invalid" class="btn navBtn nextStepBtn" (click)="submit()">
        Review & Book
      </button>
    </div>
  </div>
</div>
