import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-itinerary-select-round',
  templateUrl: './itinerary-select-round.component.html',
  styleUrls: ['./itinerary-select-round.component.css', './list.component.scss']
})
export class ItinerarySelectRoundComponent implements OnInit {
  @Output() select = new EventEmitter();
  @Output() selectRound = new EventEmitter();
  @Output() selectedTicket = new EventEmitter();
  @Input() reviewFlight: any;
  @Input() itineraries: any;
  @Input() sabre: any;
  @Input() flights: any;
  @Input() origin: any;
  @Input() destination: any;
  @Input() alternativeDatesResponse: any;
  @Input() search: any;
  @Input() page: number;
  @Input() resultsArrived: boolean;
  @Input() loading: boolean;
  @Input() skeletons: number;
  @Output() fareData = new EventEmitter();
  @Output() collectionTotalItemsCount = new EventEmitter();
  @Input() airLines: any;
  @Input() toggleDetails: boolean;
  @Input() review: boolean;
  @Input() cheapestFlights;
  @Input() selectedIndex;
  @Input() selectedItineraryTagId;
  val = 0;
  dataSearchBFM: any;
  searchRequest: any;
  viewFlight: any[] = [];
  viewFlightTrip: any[] = [];
  lostFlight: any;
  selectedItinerary: any[] = [];
  returnIndex: any;
  constructor(private route: ActivatedRoute,
    private apis: ApisService) {
    this.searchRequest = this.route.snapshot.queryParams;
  }

  ngOnInit(): void {
    this.getTripById(this.selectedItineraryTagId);
  }
  getTripById(id) {
    this.apis.getTripById(id, 'Return').subscribe(
      (data: any) => {
        this.selectedItinerary = data.data.Itineraries;
        if (this.selectedIndex == 1) {
          this.selectedItinerary[0].flights.shift();
        }
        else {
          this.selectedItinerary[0].flights.pop();
        }
        this.returnIndex = this.selectedItinerary[0].flights[0].length
        this.getSearchBMF();
      })

  }

  getSearchBMF(){
    this.apis.searchBFM(this.selectedItineraryTagId, this.selectedIndex).subscribe((data: any) => {
      this.dataSearchBFM = data.data;
      this.itineraries = data.data.Itineraries;
      this.collectionTotalItemsCount.emit(data.data.pages.total_item);
    for (let i = 0; i < this.itineraries.length; i++) {


      if (this.selectedIndex == 0) {
        this.itineraries[i].flights.shift();
      }
      else {
        this.itineraries[i].flights.pop();
      }
    }

    },
      err => {
      });

    
  }

  getItineraries(event) {
    var first_tag_id = this.selectedItineraryTagId;
    var first_index_id = this.selectedIndex;
    var second_tag_id = event.TagID;
    var second_index_id = 1;
    if (this.selectedIndex == 1) {
      second_index_id = 0;
    }

    this.apis.getMultiTicketTrib(first_tag_id, first_index_id, second_tag_id, second_index_id).subscribe(
      (data: any) => {
        
      }
    )
    const oneResult = {
      tagId: this.selectedItineraryTagId,
      second_tag_id: second_tag_id,
      first_index_id: first_index_id,
      second_index_id: second_index_id,
    }
    this.selectedTicket.emit(oneResult);
  }

  
  get(e): boolean {
    if (e == 'Aus-Air') {
      return true;
    }
    return false;
  }
  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = ` ${Math.floor(minutes / 60)}h`;
    }
    if (Math.floor(minutes % 60) > 0) {
      convertedMinutes = ` ${minutes % 60}m`;
    }
    return convertedHours + ' ' + convertedMinutes;
  }

}
