import {Component, EventEmitter, Input, OnInit, Output, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class EngineComponent implements OnInit {
  @Output() modifiedSearchRequestEvent = new EventEmitter();
  @Output() offsetHeight = new EventEmitter();
  @Input() searchRequest;
  @Input() airLine;
  @Input() color;
  @Input() disabeled :Boolean = true;
  @Input() payLater = true;
  classTrip  = true ;
  className: any = 'Economy';
  codeAirLine = '';
  private dialogRef = null;
  airline: any;
  tab = 1;
  openTapHeade: any=1;
  // public dialogRef: MatDialogRef<EngineComponent>

  constructor() {

  }

  close(): void {

  }

  ngOnInit(): void {
   this.payLater =true


  }

  setOffsetHeight() {
    this.offsetHeight.emit();
  }

  applyModificationEvent(searchRequest): void {

    this.searchRequest = searchRequest;
    this.modifiedSearchRequestEvent.emit(this.searchRequest);

  }
  isActive(tabId: any): boolean {
    return this.tab === tabId;
  }

  openTab(tabId: any): void {
    this.tab = tabId;
  }
  setClassName(){

  }
  viewClickap(tap:any){
    setTimeout(()=>{ this.openTapHeade=tap},500)
    console.log('afs',this.openTapHeade);


  }
}
