<app-navbar></app-navbar>

<!-- start header-centre -->
<div class="header-centre">
  <div class="overlay">
    <div class="container">
      <h1 class="text-center Font-Family ">What can we help you with?</h1>
      <div class="search-box">
        <input
          type="text"
          [ngbTypeahead]="searchSuggestions"
          [(ngModel)]="searchInput"
          placement="bottom-left"
          (keyup.enter)="triggerSearch()"
          (click)="$event.target.select()"
          (selectItem)="goToArticle($event)"
          placeholder="Search here..."
        />
        <button (click)="triggerSearch()">
          <img
            title="Search About Trip  "
            alt="search"
            src="./assets/images/blog/search.svg"
            width="30"
          />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- end header-centre -->

<!-- start navbar-centre -->
<div class="navbar-centre">
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <div
        [ngbCollapse]="isMenuCollapsed"
        class="collapse navbar-collapse"
        id="navbarNavCentre"
      >
        <!-- for large screens -->
        <ul
          *ngIf="!mobile && categories"
          [ngClass]="{ 'd-none': loading }"
          #categoriesList
          class="navbar-nav"
          (click)="categoriesUI($event)"
        >
          <li class="nav-item Font-Family">
            <a
              [routerLink]="['/support']"
              class="nav-link px-3 active Font-Family"
              id="first-nav-item"
              >All</a
            >
          </li>
          <li class="nav-item Font-Family" *ngFor="let category of categories | slice: 0:4">
            <a [id]="category.slug" class="nav-link">
              <!-- <img alt="flyallover"  class="nav-img" [src]="category.image" alt=""> -->
              {{ category.title_content }}
            </a>
            <!-- <a class="nav-link" [id]="category.slug">{{category.title_content}}</a> -->
          </li>
          <li
            *ngIf="categories?.length > 4"
            ngbDropdown
            class="d-inline-block nav-item Font-Family"
          >
            <button class="btn" id="restOfCats" ngbDropdownToggle>
              <!-- <span><i class="fas fa-ellipsis-v"></i></span> -->
            </button>
            <div ngbDropdownMenu aria-labelledby="restOfCats">
              <button
                [id]="category.slug"
                *ngFor="let category of categories | slice: 4"
                ngbDropdownItem
              >
                <!-- <img alt="flyallover"  class="nav-img" [src]="category.image" alt=""> -->
                {{ category.title_content }}
              </button>
            </div>
          </li>
        </ul>
        <ul *ngIf="!mobile && loading" class="navbar-nav">
          <li class="nav-item" *ngFor="let category of createRange(4)">
            <a class="nav-link">
              <ngx-skeleton-loader
                count="1"
                [theme]="{ width: '100px', margin: '0', height: '17px' }"
              >
              </ngx-skeleton-loader>
            </a>
          </li>
        </ul>
        <!-- for screens smaller than lg screen -->
        <ul
          *ngIf="mobile && categories"
          class="navbar-nav"
          (click)="categoriesUI($event)"
        >
          <li class="nav-item Font-Family">
            <a
              [routerLink]="['/support']"
              class="nav-link px-3 active"
              id="first-nav-item Font-Family"
              >All</a
            >
          </li>
          <li
            #categoriesListMobile
            class="nav-item Font-Family"
            *ngFor="let category of categories"
          >
            <a class="nav-link px-3 Font-Family" [id]="category.slug">
              {{ category.title_content }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<!-- end navbar-centre -->

<!-- start posts-centre -->
<div
  class="posts-centre"
  [ngClass]="{ 'pt-3': categoriesBreadCrumb?.length > 0 }"
>
  <div class="container">
    <!-- start breadcrumb appears only in case of displaying articles of a categories-->
    <nav *ngIf="categorySlug">
      <ol *ngIf="!loading" class="breadcrumb">
        <li
          *ngFor="let breadcrumb of categoriesBreadCrumb"
          class="breadcrumb-item Font-Family"
        >
          <a href="{{ breadcrumb.url }}">{{ breadcrumb.title }}</a>
        </li>
      </ol>
      <ol *ngIf="loading" class="breadcrumb">
        <li *ngFor="let breadcrumb of createRange(2)" class="breadcrumb-item Font-Family">
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '90px', margin: '0px' }"
          ></ngx-skeleton-loader>
        </li>
      </ol>
    </nav>
    <!-- end breadcrumb -->
    <div *ngIf="!loading" class="posts-centre-title Font-Family">
      <!-- this appears only when tha page starts and when there is no category or query as query params in URL -->
      <h6 *ngIf="!showResultSearch && !categorySlug">Latest Trends</h6>
      <!-- this appears when posts are coming from a certain category (there is categorySlug) in url query param -->
      <h4 *ngIf="!showResultSearch && categorySlug">
        {{ posts[0]?.category_title }}
      </h4>
      <!-- appears when search is done -->
      <h4 *ngIf="showResultSearch">Search results for: {{ searchInput }}</h4>
      <p *ngIf="showResultSearch && posts.length > 1">
        {{ collectionSize }} Posts are found.
      </p>
      <p *ngIf="showResultSearch && posts.length == 1">
        {{ collectionSize }} Post is found.
      </p>
      <!-- appears when there is no posts to show -->
      <p *ngIf="posts.length == 0" class="text-danger Font-Family">No Posts are found.</p>
    </div>
    <!-- skeleton for loading -->
    <ng-container *ngIf="loading">
      <ngx-skeleton-loader
        count="1"
        [theme]="{ width: '200px', margin: '0' }"
      ></ngx-skeleton-loader>
    </ng-container>

    <div class="posts-centre-body">
      <div *ngIf="!loading" class="row">
        <div class="col" *ngFor="let post of posts; let i = index">
          <div
            class="post Font-Family"
            [routerLink]="['/support', post?.category_slug, post?.post_slug]"
          >
            <div
              class="photo"
              style="background-image:url('{{ post?.image }}')"
            >
              <img
                class="sr-only"
                src="{{ post?.image }}"
                title="Support-Post-Image"
                alt="{{ post?.image_alt || 'flyallover' }}"
              />
              <!-- add href of the nav -->
              <div class="overlay">
                <a
                  [routerLink]="['/support', post?.category_slug]"
                  class="label"
                >
                  {{ post?.category_title }}
                </a>
              </div>
            </div>
            <div class="description">
              <!-- this is for first card in large screen -->
              <div class="ByFlyAllOver labels d-flex justify-content-between">
                <label>By. FlyAllOver</label>
                <label> {{ post?.created_at | date: "dd MMM yyyy" }}</label>
              </div>
              <div class="post_title d-block">
                <h3 title="{{ post?.title_content }}">
                  {{ post?.title_content }}
                </h3>
              </div>
              <p class=" fao_mobile_prgraph d-block">
                {{ post?.short_description | slice:0:160}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- skeleton cards -->
      <div *ngIf="loading" class="row">
        <div class="col" *ngFor="let post of createRange(2)">
          <div class="post">
            <div class="photo">
              <div class="overlay p-0">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '100%', height: '100%' }"
                >
                </ngx-skeleton-loader>
              </div>
            </div>
            <div class="description">
              <ngx-skeleton-loader
                count="1"
                [theme]="{ width: '200px', 'max-width': '100%' }"
              >
              </ngx-skeleton-loader>
              <ngx-skeleton-loader
                count="1"
                [theme]="{ width: '150px' }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                count="1"
                [theme]="{ width: '100%' }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="posts.length > 0" class="ngb-pagination">
      <ngb-pagination
        class="d-flex justify-content-end"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="changePage($event)"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
<!-- end posts-centre -->

<app-footer></app-footer>
