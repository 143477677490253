<div class="customContainer">
  <div class="title">
    <div class="d-flex justify-content-between">
      <span>Select Your Seats (optional)</span>
      <a (click)="nextStepEvent.emit()">
        <span>Skip to Payment</span>
      </a>
    </div>
  </div>
  <div class="body">
    <ng-container *ngIf="!flights || !seats">
      <div class="col-12">
        <ngx-skeleton-loader [theme]="{height:'375px'}"></ngx-skeleton-loader>
      </div>
    </ng-container>
    <ng-container *ngIf="flights && seats">
      <app-review-flight-seats-header [flights]="flights"
                                      [travellers]="travellers"
                                      [currentTravellerIndex]="currentTravellerIndex"
                                      [currentFlightIndex]="currentFlightIndex"
                                      (jumpToFlightEvent)="jumpToFlight($event)"
                                      (jumpToTravellerEvent)="jumpToTraveller($event)">
      </app-review-flight-seats-header>
      <app-review-flight-one-flight-seats [currentFlight]="flights[currentFlightIndex]"
                                          [currentTraveller]="travellers[currentTravellerIndex]"
                                          [currentFlightIndex]="currentFlightIndex"
                                          [currentTravellerIndex]="currentTravellerIndex"
                                          [seats]="seats[currentFlightIndex]"
                                          (selectSeatEvent)="selectSeat($event)">
      </app-review-flight-one-flight-seats>
    </ng-container>
  </div>
</div>
<div class="navigationButtons">
  <div class="col-12">
    <div class="row justify-content-between">
      <button class="btn navBtn previousStepBtn" (click)="previousStepEvent.emit()">Protection</button>
      <button class="btn navBtn nextStepBtn" (click)="nextStepEvent.emit()">Payment</button>
    </div>
  </div>
</div>
