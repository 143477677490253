<div class="hotel-search-container">
  <form [formGroup]="hotelSearchForm" (ngSubmit)="onSearch()">
    <div class="search-box">
      <!-- Location Input -->
      <div class="form-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/bedIcon.svg" alt="Location Icon" width="24" height="24" />
        </span>
        <input type="text" id="location" formControlName="location" autocomplete="off"
          placeholder="Enter a city, hotel, airport or landmark" required (input)="onLocationInput()" />
        <div class="dropdown" *ngIf="locations?.length > 0 || isLoading">
          <!-- Loading Spinner -->
          <div class="loading-spinner" *ngIf="isLoading">
            <div class="spinner"></div>
          </div>
          <div class="dropdown-item" *ngFor="let location of locations" (click)="selectLocation(location)">
            <div class="icon-container">
              <img [src]="getIconForType(location.model_type)" alt="Icon" width="24" height="24" />
            </div>
            <div class="location-details">
              <span class="location-name">{{ location.name }}</span>
              <span class="location-type">{{ location.model_type }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Check-in and Check-out Dates Input -->
      <div class="form-group date-picker-group">
        <span class="icon">
          <img src="assets/hotel-icon-search/calendarIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        <input type="text" formControlName="checkin" placeholder="Wed 7/24" class="check-hotel"
          [(ngModel)]="viewwCheckinDate" required />
        <span class="icon">
          <img src="assets/hotel-icon-search/twoarrowsIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        <span class="icon">
          <img src="assets/hotel-icon-search/lineIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
        <input type="text"  formControlName="checkout" placeholder="Fri 7/26" class="check-hotel"
          [(ngModel)]="viewwCheckoutDate" required />
        <span class="icon">
          <img src="assets/hotel-icon-search/twoarrowsIcon.svg" alt="Calendar Icon" width="24" height="24" />
        </span>
      </div>
      <div class="trevaller-view" >
        <div class="form-group" (click)="viewTravellersObj.classList.add('view-selected-trevellers-body')">
          <span class="icon">
            <img src="assets/hotel-icon-search/guestsIcon.svg" alt="Guests Icon" width="24" height="24" />
          </span>
          <input type="text"  formControlName="guests" [(ngModel)]="trevallerValue"
            placeholder="1 room 2 guests" required />
        </div>
        <div class="selected-trevellers-body" #viewTravellersObj (mouseleave)="viewTravellersObj.classList.remove('view-selected-trevellers-body')">
          <div class="selected-cart" *ngFor="let item of travellersObj |keyvalue">
            <div class="title-view-selected">{{item.value.name}}</div>
            <div class="btn-selected-trevellers">
              <div class="btn-del-selected-num-trevallers" (click)="inc(item.value.id)">-</div>
              <div class="btn-view-selected-num-trevallers">{{item.value.currentValue}}</div>
              <div class="btn-plus-selected-num-trevallers" (click)="plus(item.value.id)">+</div>
            </div>
          </div>
          <span class="text-dannger">{{errorMessage}}</span>
        </div>

      </div>
      <!-- Guests and Rooms Input -->


      <!-- Search Button -->
      <button type="submit" [disabled]="hotelSearchForm.invalid" class="search-button">
        <span class="icon">
          <img src="assets/hotel-icon-search/searchIcon.svg" alt="Search Icon" width="24" height="24" />
        </span>
      </button>
    </div>
  </form>

</div>
