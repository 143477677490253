import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from 'src/app/services/webApis/window.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.css']
})
export class ArticleCardComponent implements OnInit {
  @Input() post: any;
  @Input() posts: any;
  constructor(
    private router: Router,
    private windowService: WindowService,
  ) { }

  ngOnInit(): void {
  }

  navigateToUrl(param1, param2) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/blog/${param1}/${param2}`])
    );
    // this.router.navigate([`/blog/${param1}/${param2}`])
    this.windowService.nativeWindow.open(url, '_blank');
  }
}
