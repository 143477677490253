import {
    Component,
    Inject,
    Input,
    OnInit,
    Optional,
    PLATFORM_ID,
} from "@angular/core";
import {SEOService} from "../../services/seo.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: "app-share-reviews",
    templateUrl: "./share-reviews.component.html",
    styleUrls: ["./share-reviews.component.css"],
})
export class ShareReviewsComponent implements OnInit {
    location: any;

    // data: any;
    constructor(
        private seoService: SEOService,
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private _ActivatedRoute: ActivatedRoute
    ) {
    }

    @Input() data: any;
    @Input() image: any;

    ngOnInit(): void {
    }


}

/*@Input() url = location.href;
constructor() {
  // initialise facebook sdk after it loads if required
  if (!window['fbAsyncInit']) {
    window['fbAsyncInit'] = function () {
      window['FB'].init({
        appId: 'your-app-id',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.0'
      });
    };
  }

  // load facebook sdk if required
  const url = 'https://connect.facebook.net/en_US/sdk.js';
  if (!document.querySelector(`script[src='${url}']`)) {
    let script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
  }
}

ngAfterViewInit(): void {
  // render facebook button
  window['FB'] && window['FB'].XFBML.parse();
}*/
