import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-flight-flight-price',
  templateUrl: './review-flight-flight-price.component.html',
  styleUrls: ['./review-flight-flight-price.component.css']
})
export class ReviewFlightFlightPriceComponent implements OnInit {
  @Input() itineraries: any;
  constructor() { }

  ngOnInit(): void {


  }
}
