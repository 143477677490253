import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";


@Component({
  selector: 'app-travellers-classes-input',
  templateUrl: './travellers-classes-input.component.html',
  styleUrls: ['./travellers-classes-input.component.css']
})
export class TravellersClassesInputComponent implements OnInit {
  @Output() openTravellersDialogEvent = new EventEmitter();
  @Output() travellersDetails: EventEmitter<any> = new EventEmitter<any>();
  travelForm: FormGroup;
  @Input() className: any = 'Economy';
  @Input() rooms: any = 1;
  @Input() totalTravellers: any = 1;
  @Input() adult: any = '1';
  @Input() child: any;
  @Input() lap: any;
  @Input() seat: any;
  @Input() hotelStatus: any = false;
  @Input() classTrip = false;
  seatExceedError = ' Airlines allow a maximum of 2 children under 2 years old per Adult traveler ';
  error = false;
  errorMax = false;
  errorMin = false;
  travelView = false;
  errorInfants = false;
  traveller;
  travellers: any = {
    adult: {
      id: 'adult',
      name: 'Adults',
      initialValue: 1,
      currentValue: 1,
      minValue: 0,
      maxValue: 12,
    },
    child: {
      id: 'child',
      name: 'Children',
      ageFrom: 2,
      ageTo: 12,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
    seat: {
      id: 'seat',
      name: 'Seat infants',
      ageFrom: 0,
      ageTo: 2,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
    lap: {
      id: 'lap',
      name: 'Lap infants',
      ageFrom: 0,
      ageTo: 2,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
  };

  openTravelMenu() {
    this.travelView = !this.travelView;
  }

  constructor(private route: ActivatedRoute, private builder: FormBuilder
  ) {
    this.travelForm = this.builder.group({
      adult: [''],
      child: [''],
      seat: [''],
      lap: [''],
    });
    
  }

  setTravellersDetails() {
    const travellersDetailsData = [{
      className: this.className,
      totalTravellers: this.totalTravellers,
      adult:  this.travellers.adult.currentValue,
      child:  this.travellers.child.currentValue,
      lap:  this.travellers.seat.currentValue,
      seat:  this.travellers.lap.currentValue,
    }
    ];
    this.travelView = false;
    this.travellersDetails.emit(travellersDetailsData['0']);
  }

  ngOnInit(): void {

    if (this.className) {
      this.className = this.className;
    }
    if (this.totalTravellers) {
      this.totalTravellers = this.totalTravellers;
    }
    if (this.adult) {
      this.travellers.adult.currentValue = this.adult;
    }
    if (this.child) {
      this.travellers.child.currentValue = this.child;
    }
    if (this.seat) {
      this.travellers.seat.currentValue = this.seat;
    }
    if (this.lap) {
      this.travellers.lap.currentValue = this.lap;
    }
  }

  inc(traveller): void {
    if (this.totalTravellers < 9) {
      traveller.value.currentValue++;
      this.totalTravellers++;
      if (this.totalTravellers >= 1) {
        this.errorMin = false;
      }
      if (this.travellers.seat.currentValue + Number(this.travellers.lap.currentValue) > this.travellers.adult.currentValue * 2) {
        this.errorInfants = true;
      }

      this.errorMax = false;
    }
    if (this.totalTravellers >= 9) {
      this.errorMax = true;
    }
  }

  dec(traveller): void {
    if (this.totalTravellers >= 1) {
      traveller.value.currentValue--;
      this.traveller = traveller.value.currentValue
      this.totalTravellers--;
      if (this.totalTravellers <= 9) {
        this.errorMax = false;
      }
      if (this.travellers.seat.currentValue + Number(this.travellers.lap.currentValue) <= this.travellers.adult.currentValue * 2) {
        this.errorInfants = false;
      }
      this.errorMin = false;
    }
    if (this.totalTravellers < 1) {
      this.errorMin = true;
    }
  }
}
