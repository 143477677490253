<app-navbar></app-navbar>
<!-- <div class="vaction-header ">
  <h1 class="title"> HOTELS </h1>
</div> -->

<div class="container mt-5">
  <div class="row">
    <div class="small-screen-container">
      <app-hotels-engine [loadingSpinner]="loadingSpinner"
        (hotelsDataflow)="addhotelsDataNew($event)"></app-hotels-engine>
    </div>

    <div class="col-3 filter my-3">
      <app-hotels-filter></app-hotels-filter>
      <!-- <app-filters></app-filters> -->
    </div>
    <div class="col-8 result-hotels team mb-4">


<div *ngIf="!hotels">
  <div class=" all-card col-12 p-0">
    <div class="vt-placeholder-loading " *ngFor="let Loading of loadings" [style]="{height: Loading.width}">
      <div class="vt-animated-background-loading" [style]="{height: Loading.width}"></div>
    </div>
  </div>

</div>

      <ng-container *ngFor="let hotel of hotels; let i = index">
        <div class="member d-flex align-items-start my-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="pic">
            <owl-carousel-o [options]="customOptions">
              <ng-container *ngIf="hotel.basis_info.HotelImageInfo?.Url">
                <ng-template carouselSlide>
                  <img [src]="hotel.basis_info.HotelImageInfo?.Url" [alt]="hotel.basis_info.HotelName" class="w-100" height="420px"
                    style="object-fit: cover" />
                </ng-template>
              </ng-container>
              <ng-container *ngIf="!hotel.basis_info.HotelImageInfo?.Url">
                <ng-template carouselSlide>
                  <img src="./../../../assets/images/arrow/Hotel.png" alt="hotels ph " class="w-100" height="420px"
                    style="object-fit: cover" />
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
          <div class="member-info">
            <a [routerLink]="['/hotel-details/']" style="text-decoration: none">
              <h4>{{ hotel.basis_info.HotelName }}</h4>
            </a>

            <span>{{
              hotel.basis_info.Address.AddressLine1 +
              " - " +
              hotel.basis_info.Address.CityName +
              " - " +
              hotel.basis_info.Address.CountryName
              }}
            </span>
            <p *ngFor="let amenity of hotel.basis_info.Amenities.Amenity | slice:0:4; let ii = index">
              <img style="width: 17px; margin-bottom: 7px" class="mx-1"
                src="assets/images/hotels/icon/dot-small-svgrepo-com.svg" />
              {{ amenity.Description }}
            </p>
            <p style="color: blue;">
              and more ...
            </p>
            <div class="price-rate pt-3">
              <div class="col-6 p-0">
                <div class="rating d-flex align-items-center">
                  <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill"></span><i class="fa fa-star"></i>
                    </span>
                  </ng-template>
                  <ngb-rating [rate]="hotel.basis_info.Rate" [starTemplate]="t" [readonly]="true"
                    [max]="5"></ngb-rating>
                </div>

                <div class="d-flex" *ngIf="hotel.basis_info.reviews.length > 0">
                  <!-- {{item.fao_hotel.hotel_rate}} -->
                  <!-- {{item.fao_hotel.number_of_rates}} -->
                  <span class="fw-bold pl-2">{{ hotel.basis_info.ReviewsAverage / hotel.basis_info.reviews.length  }}</span><span>/10 -</span>
                  <span class="mx-1"> (  {{ hotel.basis_info.reviews.length  }}-reviews)</span>
                </div>
                <div class="d-flex" *ngIf="hotel.basis_info.reviews.length == 0">
                  <span class="mx-1"> Be the first to rate this hotel...</span>
                </div>
              </div>
              <div class="featured text-right">
                <!--              <h3>Business Plan</h3>-->
                <h4>${{ hotel.basis_info.HotelPriceInfo.RateInfo[0]. AmountBeforeTax}}</h4>
                <span>${{ hotel.basis_info.HotelPriceInfo.RateInfo[0]. AverageNightlyRate}} per night</span>
                <span>${{ (hotel.basis_info.HotelPriceInfo.RateInfo[0]. AmountAfterTax -
                  hotel.basis_info.HotelPriceInfo.RateInfo[0]. AmountBeforeTax).toFixed(2) }} taxes and charges</span>
                <span>Includes taxes & fees</span>
                <!--              <a href="#" class="buy-btn">Get Started</a>-->
              </div>
            </div>
            <div class="d-flex justify-content-end pt-3">


              <button (click)="goToDetails(hotel.basis_info.HotelCode)" class="vt-btn-nurmal" type="button">
                Book Now</button>

            </div>


          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hotelsPagination">

      </ng-container>
      <ngb-pagination class="d-flex justify-content-center pagination  my-5 py-5" [collectionSize]="collectionSize"
        (pageChange)="pageChange($event)" [(page)]="page" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>

  </div>
</div>

<app-footer></app-footer>
