import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-result-error',
  templateUrl: './no-result-error.component.html',
  styleUrls: ['./no-result-error.component.css']
})
export class NoResultErrorComponent implements OnInit {
  @Input() homePath: string;
  constructor() { }

  ngOnInit(): void {
  }

}
