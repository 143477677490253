import { Component, OnInit } from '@angular/core';
import {SEOService} from "../../../services/seo.service";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private seoService: SEOService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }
  seoUpdate() {

    this.seoService.updateTitle('Welcome | Flyallover');
    this.seoService.updateDescription('Welcome to Flyallover');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
