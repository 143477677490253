import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {SEOService} from "../../services/seo.service";
import { ApisService } from 'src/app/services/apis.service';
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-help-newsletter',
  templateUrl: './help-newsletter.component.html',
  styleUrls: ['./help-newsletter.component.css']
})
export class HelpNewsletterComponent implements OnInit {

  submit: boolean = false;

  newsLetterForm: FormGroup;
  validationMessages = {
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Enter a valid email'}
    ]
  }
  roundSpinner: boolean = false;
  url: string;

  // google recaptcha
  siteKey: string = '';
  captchaKey: string = '';

  constructor(
    private apis: ApisService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private seoService: SEOService
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.siteKey = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
    this.createForm();
  }

  seoUpdate() {

    this.seoService.updateTitle('News Letter | flyallover ');
    this.seoService.updateDescription('News Letter in flyallover will recieve all updates for travel, flights,hotels, cruises and vacations');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createForm() {
    this.newsLetterForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]))
    }),
      { recaptcha: new FormControl('', Validators.required) }

  }

  onSubmitEmail(formValue) {
    this.roundSpinner = true;
    this.navigateOnSamePage();
    if (this.captchaKey) {
      this.apis.subscribeByEmail(formValue.email, this.captchaKey).subscribe(
        (res) => {
          this.submit = true;
          this.roundSpinner = false;
        },
        (err) => {
        }
      )
    }
  }

  resolved(captchaResponse: string) {
    this.captchaKey = captchaResponse;
  }

  navigateOnSamePage() {
    this.router.navigate(['newsletter'])
  }

}
