<app-navbar></app-navbar>
<!-- start things-todo-page -->
<div class="things-todo-page">
  <!-- start things-todo-header -->
  <div class="things-todo-header" *ngIf="loading">
    <ngx-skeleton-loader
      count="1"
      [theme]="{ width: '100%', height: '350px', margin: '0px' }"
    >
    </ngx-skeleton-loader>
  </div>
  <div class="things-todo-header" *ngIf="!loading && data?.image">
    <img
      alt="{{ data?.image_alt || 'flyallover' }}"
      [src]="data?.image"
      title="things-todo"
    />
  </div>

  <!-- start breadcrumb -->
  <div class="container">
    <nav>
      <ol *ngIf="!loading" class="breadcrumb" [ngClass]="{ 'm-0': !data }">
        <li
          *ngFor="let breadcrumb of data?.bread_crumb"
          class="breadcrumb-item"
        >
          <a href="{{ breadcrumb.url }}">{{ breadcrumb.title }}</a>
        </li>
      </ol>
      <ol *ngIf="loading" class="breadcrumb">
        <li *ngFor="let breadcrumb of createRange(2)" class="breadcrumb-item">
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '90px', margin: '0px' }"
          ></ngx-skeleton-loader>
        </li>
      </ol>
      <!-- <a [routerLink]="['/blog/6tips-to-book-vacation-with-best-price']" fragment="carousel">go to carousel</a> -->
    </nav>
  </div>
  <!-- end breadcrumb -->

  <!-- start things-todo-body -->
  <div class="things-todo-body">
    <div class="container">
      <div class="row" id="article-container-bottom">
        <!-- start things-todo-sidebar -->
        <div class="things-todo-sidebar col">
          <div class="sidebar" id="sidebar">
            <div class="row">
              <div class="col-12">
                <div
                  [ngClass]="{
                    'd-none':
                      !loading && data?.article_content?.headers?.length == 0
                  }"
                  class="col"
                  id="headers"
                >
                  <h6 *ngIf="data?.article_content?.headers?.length > 0">
                    Content
                  </h6>
                  <div class="links">
                    <ul *ngIf="!loading">
                      <ng-container
                        *ngFor="
                          let header of data?.article_content?.headers
                            | slice: 0:3
                        "
                      >
                        <li
                          class="link-item"
                          [ngClass]="{
                            'active-header': header.includes(fragment)
                          }"
                          [innerHTML]="sanitizeHtml(header)"
                        ></li>
                      </ng-container>
                      <ng-container *ngIf="moreHeaders">
                        <ng-container
                          *ngFor="
                            let header of data?.article_content?.headers
                              | slice: 3
                          "
                        >
                          <li
                            class="link-item"
                            [ngClass]="{
                              'active-header': header.includes(fragment)
                            }"
                            [innerHTML]="sanitizeHtml(header)"
                          ></li>
                        </ng-container>
                      </ng-container>
                      <!-- buttons to toggle more or less headers -->
                      <ng-container
                        *ngIf="data?.article_content?.headers?.length > 3"
                      >
                        <p class="more-less" *ngIf="!moreHeaders">
                          <span (click)="moreHeaders = true">
                            See More
                            <i class="fas fa-chevron-down ml-2"></i>
                          </span>
                        </p>
                        <p class="more-less" *ngIf="moreHeaders">
                          <span (click)="moreHeaders = false">
                            See Less
                            <i class="fas fa-chevron-up ml-2"></i>
                          </span>
                        </p>
                      </ng-container>
                    </ul>
                    <!-- skeleton when loading headers of content -->
                    <ul *ngIf="loading">
                      <li *ngFor="let item of createRange(3)">
                        <ngx-skeleton-loader
                          count="1"
                          class="mt-2"
                          [theme]="{ width: '100%', 'margin-bottom': '0' }"
                        ></ngx-skeleton-loader>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <h6>Plan A Trip</h6>
                <app-multiple-search-engines
                  [hotelSearch]="undefined"
                ></app-multiple-search-engines>
              </div>
            </div>
          </div>
        </div>
        <!-- start things-todo-content -->
        <div class="things-todo-content col">
          <div class="post" #article id="article">
            <div class="row">
              <div
                *ngIf="!loading && data?.article_content?.content"
                class="col"
              >
                <div class="post-header">
                  <h1>{{ data?.title_content }}</h1>
                </div>
                <!-- WARNING:  fr-view class is added for tha transformedDescription to be styled by froala_styles (editor's css file)  -->
                <div
                  class="post-body fr-view"
                  [innerHTML]="sanitizeHtml(data?.article_content?.content)"
                ></div>
                <div class="post-footer">
                  <div class="logo">
                    <img
                      alt="Logo"
                      title="artical logo"
                      src="./assets/images/things-todo/logo.png"
                      width="77"
                    />
                  </div>
                  <div class="labels">
                    <label>By. FlyAllOver</label>
                    <label>{{ data?.created_at | date: "dd MMM yyyy" }}</label>
                  </div>
                </div>
              </div>
              <!-- appears when error occurs -->
              <div
                *ngIf="!loading && !data?.article_content?.content"
                class="col"
              >
                <h4 class="text-center text-danger">
                  An error Occurred while trying to get the article
                </h4>
              </div>
              <!-- skeleton article content for loading  -->
              <div *ngIf="loading" class="col">
                <div class="post-header">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '50%' }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="post-body">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '30%' }"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="3"
                    [theme]="{ width: '100%' }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="post-footer">
                  <div class="logo">
                    <ngx-skeleton-loader
                      count="1"
                      appearance="circle"
                      [theme]="{
                        height: '77px',
                        width: '77px',
                        'vertical-align': 'super'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                  <div class="labels">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '160px' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '160px' }"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>

              <div
                *ngIf="!loading && data?.article_content?.content"
                class="col social text-right"
              >
                <ul id="social">
                  <li>
                    <a (click)="close_status = false">
                      <img
                        alt="share"
                        title="Share Artical"
                        src="./assets/images/things-todo/share.svg"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.facebook.com/sharer/sharer.php?u={{
                        hostUrl
                      }}{{ routerUrl }}"
                      target="_blank"
                    >
                      <img
                        alt="facebook"
                        title="facebook image"
                        src="./assets/images/things-todo/facebook.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://twitter.com/intent/tweet?url={{ hostUrl }}{{
                        routerUrl
                      }}"
                      target="_blank"
                    >
                      <img
                        alt="twitter"
                        title="twitter image"
                        src="./assets/images/things-todo/twitter.png"
                      />
                    </a>
                  </li>
                  <!-- <li><a href="#"><img alt="flyallover"  src="./assets/images/things-todo/youtube.png" /></a></li> -->
                  <!-- <li><a href="#"><img alt="flyallover"  src="./assets/images/things-todo/instagram.png" /></a></li> -->
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.linkedin.com/shareArticle?mini=true&url={{
                        hostUrl
                      }}{{ routerUrl }}&title={{ data?.title }}"
                      target="_blank"
                    >
                      <img
                        alt="linkedin"
                        title="linkedin image"
                        src="./assets/images/things-todo/linkedin.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <app-share-popup
                [close_status]="close_status"
                [viewSocialLinks]="false"
                [linkToBeShared]="linkToBeShared"
                (close)="close_status = true"
              ></app-share-popup>
            </div>
          </div>
        </div>
        <!-- end things-todo-content -->
      </div>
    </div>
  </div>
  <!-- end things-todo-body -->

  <!-- start also-read -->
  <div *ngIf="posts.length > 0" class="also-read">
    <div class="container">
      <h6>People Also Read</h6>

      <div class="carousel" id="carousel">
        <owl-carousel-o *ngIf="!loading" [options]="carouselOptions">
          <ng-container *ngFor="let post of posts; let i = index">
            <ng-template carouselSlide>
              <div class="post-carousel">
                <div
                  class="post"
                  [routerLink]="[
                    '/blog',
                    post?.category_slug,
                    post?.article_slug
                  ]"
                  [ngClass]="{ 'mx-auto': posts.length == 2 }"
                >
                  <div
                    class="photo"
                    style="background-image:url({{ post.image }})"
                  >
                    <img
                      class="sr-only"
                      title="post image"
                      src="{{ post?.image }}"
                      alt="{{ post?.image_alt || 'flyallover' }}"
                    />
                    <!-- add link to category -->
                    <div class="overlay">
                      <a
                        [routerLink]="['/blog', post?.category_slug]"
                        class="label"
                      >
                        {{ post?.category_title }}
                      </a>
                    </div>
                  </div>
                  <div class="description">
                    <h3 title="{{ post?.title_content }}">
                      {{ post?.title_content.slice(0, 50) }}
                      <span *ngIf="post?.title_content?.length > 50">...</span>
                    </h3>
                    <div class="labels">
                      <label>By. FlyAllOver</label>
                      <label *ngIf="post?.created_at"
                        >On {{ post?.created_at | date: "dd MMM yyyy" }}</label
                      >
                    </div>
                    <p class="d-none d-sm-block">
                      {{ post?.short_description?.slice(0, 120) }}
                      <a
                        *ngIf="post?.short_description?.length > 120"
                        [routerLink]="[
                          '/blog',
                          post?.category_slug,
                          post?.article_slug
                        ]"
                      >
                        ...
                      </a>
                    </p>
                    <p class="d-block d-sm-none">
                      {{ post?.short_description?.slice(0, 90) }}
                      <a
                        *ngIf="post?.short_description?.length > 90"
                        [routerLink]="[
                          '/blog',
                          post?.category_slug,
                          post?.article_slug
                        ]"
                        >...
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <!-- skeleton cards -->
        <div *ngIf="loading" class="row">
          <div
            class="col-md-4 post-carousel"
            *ngFor="let post of createRange(2)"
          >
            <div class="post">
              <div class="photo">
                <div class="overlay p-0">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '100%', height: '100%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="description">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '200px', 'max-width': '100%' }"
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '150px' }"
                ></ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="2"
                  [theme]="{ width: '100%' }"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  broood
</div>
<app-footer></app-footer>
