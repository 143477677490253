<app-navbar></app-navbar>
<div class="header-blog">
  <div class="overlay">
    <div class="container">
<!--      <h1 class="text-center">Blogs</h1>-->
    </div>
  </div>
</div>
<div class="search-box Font-family">
  <input type="text" [(ngModel)]="word" placeholder="Search here..."/>

  <button class="search" type="submit"><i class="fa fa-search"></i></button>

<!--    <button>-->
<!--    <img-->
<!--      title="Search about trip"-->
<!--      alt="search"-->
<!--      src="./assets/images/blog/search.svg"-->
<!--      width="30"-->
<!--    />-->
<!--  </button>-->
</div>
<section id="offers" class="section-with-bg">
  <div class="container">
    <div class="row mainRow headline">
      <div class="container" data-aos="fade-up">
        <div class="section-header text-center">
          <h2 class="fao_mobile_title_large " style="color: #fff !important;">Blogs</h2>
          <div class="hr"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 mt-5" *ngFor="let item of data| search : word">
        <div class="offer">
        <div class="popular card-body">
          <div class="offer-img">
            <img
              src="{{item.image}}"
              [alt]="item.title"
              class="img-fluid rounded"
            />
            <div>
              <h4 class="fao_mobile_title_meduim mt-1 px-3">
                {{item.title}}
              </h4>
            </div>
          </div>
          <div
            style="
              background-color: #ddd4d4;
              height: 1px;
              width: 95%;
              margin: auto;">
          </div>
          <div>
            <p class="fao_mobile_prgraph">
              {{item.description | slice:0:155}}.... </p>

            <div class="center-class">
              <button class="fao_des_btn_med mb-3" [routerLink]="['/blog-description/',item.slug]">
                MORE DETAILS
              </button>
            </div>

          </div>

<!--          <div class="offerPrice text-center">-->
<!--          </div>-->
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngb-pagination class="d-flex justify-content-center my-5 py-5" [collectionSize]="collectionSize"
                (pageChange)="getpaginationblogs($event)" [(page)]="page" aria-label="Custom pagination">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
</ngb-pagination>

<app-footer></app-footer>
