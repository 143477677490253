<input (click)="showList();" [(ngModel)]="bindClass" name="total" type="text"
       [ngClass]="{'travellers-arrow-up': showPassengerList }" class="form-control" readonly autocomplete="off"/>
<div *ngIf="showPassengerList" class="list list-travellers">
  <h5>Flight Class</h5>
  <div class="classes">

    <span [ngClass]="{'active': activeClassType == 'Economy'}" (click)="getClass('Economy')"
          class="class">Economy</span>
    <span [ngClass]="{'active': activeClassType == 'Premium Economy'}" (click)="getClass('Premium Economy')"
          class="class">Premium Economy</span>
    <span [ngClass]="{'active': activeClassType == 'First'}" (click)="getClass('First')" class="class">First</span>
    <span [ngClass]="{'active': activeClassType == 'Business'}" (click)="getClass('Business')"
          class="class">Business</span>
  </div>
  <div class="travellers">
    <h5>Travellers</h5>
    <!-- warning when number of passengers = 0 -->
    <div *ngIf="zeroWarning" class="alert alert-warning fade show" role="alert">
      Please select the number of people traveling <strong>(Adults or Seniors or Youth)</strong>
    </div>
    <!-- if the number of seat and lap infants more than double the number of adults -->
    <div *ngIf="infantsNumberWarning" class="alert alert-warning fade show" role="alert">
      Airlines allow a <strong>maximum of 2 children</strong> under 2 years old per Adult or Senior traveler.
    </div>
    <!-- warning when lap child number is more than adults number -->
    <div *ngIf="lapInfantsWarning" class="alert alert-warning fade show" role="alert">
      Each child <strong>under 2</strong> (lap) should have at least one Adult or Senior traveling with them.
    </div>
    <!-- warning if number of passengers exceeds 9 passengers -->
    <div *ngIf="activeTravellers == 9" class="alert alert-warning fade show" role="alert">
      Dear Traveller, Flyallover can search for flights for up to <strong>9 passengers</strong> at once. If you are planning to travel
      with a group, please call our Group Travel Experts at <a href="tel:1-212-381-1167">1-212-381-1167</a> . We will
      find your group the best deal!
    </div>
    <div *ngIf="lessThan1Warning" class="alert alert-warning fade show" role="alert">
      Can't select less than 1 travelller
    </div>
    <div *ngFor="let passenger of passengers" class="traveller adult">
      {{passenger.disc}}
      <div class="counter">
        <i (click)="dec(passenger.id)" class="minus count fas fa-minus"></i>
        <span class="result" id="adults">{{passenger.count}}</span>
        <i (click)="inc(passenger.id);countInfants(infantsNumberWarning);"
           [ngClass]="{'unclickable' : activeTravellers == 9 || infantsNumberWarning}"
           class="plus count far fa-plus-square"></i>
      </div>
      <ng-container *ngIf="passenger.id == 'children' ">
        <ng-container *ngIf="passenger.count > 0">
          <div class="children-ages-select-container">
            <select *ngFor="let childrenObj of childrenNumbers" class="form-control children-ages-select">
              <option value="0">Age</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
            </select>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <button class="btn" (click)="closeList()">Save</button>
  </div>
</div>




