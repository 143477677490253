<app-navbar></app-navbar>

<div class="col-12" style="margin-top: 0px; background-color: #d4d4d4">
  <div class="container d-flex" style="align-items: flex-start">
    <div class="col-8" style="margin-top: 32px">
      <div class="billing-information review-trip-section my-3">
        <ng-container>
          <div class="w-100 row">
            <span class="price col-4">PRICE :</span>
            <span class="price amount_returned col-4"></span>

            <ng-container>
              <span class="price amount_specified col-4"
                >${{ numberOfTravellers * hotelDetails.data.price }}</span
              >
            </ng-container>
          </div>
          <hr />
          <div class="col-12 row" style="justify-content: space-evenly">
            <form
              [formGroup]="formFirstNameAndLastName"
              style="display: flex; justify-content: center"
            >
              <div class="col-6 item">
                <!-- <mat-form-field appearance="fill">
                        <mat-label>First Name</mat-label>
                        <input matInput required formControlName="first_name" type="text"
                            name="firstName" />
                    </mat-form-field> -->

                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">First Name</label>
                </div>
                <input
                  required
                  formControlName="firstName"
                  (ngModelChange)="validationFirstAndLast('firstName')"
                  type="text"
                  id="firstName"
                  name="firstName"
                  class="form-control"
                  autocomplete="off"
                  required
                />

                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">Last Name</label>
                </div>
                <input
                  required
                  formControlName="lastName"
                  (ngModelChange)="validationFirstAndLast('lastName')"
                  type="text"
                  id="lastName"
                  name="lastName"
                  class="form-control"
                  autocomplete="off"
                  required
                />
              </div>
            </form>
          </div>
        </ng-container>
      </div>

      <ng-container> </ng-container>
      <div class="billing-information review-trip-section">
        <form [formGroup]="form">
          <div class="row justify-content-center">
            <div
              class="col title"
              style="align-items: center; display: contents"
            >
              Billing Details
            </div>
          </div>

          <div class="row form border1 herefounddate">
            <div class="col-12 text-center">
              <p>
                Please Enter your card details to proceed the payment process as
                written on your card to avoid any mistakes.
              </p>
            </div>
            <div class="col-12">
              <div
                class=""
                style="display: flex; flex-wrap: wrap; justify-content: center"
              >
                <div class="col-7 item">
                  <div class="d-flex">
                    <span class="star"></span>
                    <label class="date-label">Name On Card</label>
                  </div>
                  <input
                    formControlName="cardHolderFullName"
                    type="text"
                    class="form-control"
                    placeholder="please Enter Name On Card"
                    id="cardHolderFullName"
                    (ngModelChange)="validationForm('cardHolderFullName')"
                    autocomplete="off"
                    required
                    minlength="1"
                    maxlength="50"
                  />
                  <mat-error
                    *ngIf="form.controls.cardHolderFullName.errors?.pattern"
                  >
                    Please Type Only Letters At least Three Names</mat-error
                  >
                  <mat-error
                    *ngIf="
                      form.controls.cardHolderFullName.errors?.required &&
                      form.controls.cardHolderFullName.dirty
                    "
                  >
                    Name On Card Is Requied</mat-error
                  >
                </div>

                <div class="col-7 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Card Number</label>
                    </div>

                    <div class="position-relative">
                      <input
                        type="text"
                        formControlName="card"
                        class="num form-control"
                        placeholder="Please Enter Card Number"
                        autocomplete="off"
                        (ngModelChange)="
                          testCreditCard(); validationForm('card')
                        "
                        id="card"
                        required
                      />
                      <img
                        *ngIf="vendorCode"
                        class="vendor-image"
                        alt="vendor-image"
                        title="Vendor Image"
                        src="assets/images/hotels/{{ vendorCode }}.png"
                      />
                    </div>
                    <mat-error *ngIf="form.controls.card.errors?.pattern">
                      Please Type Only Numbers</mat-error
                    >
                    <mat-error *ngIf="form.controls.card.errors?.pattern">
                      Card Number Should be 16 Number
                    </mat-error>
                  </div>
                </div>
                <div class="d-flex col-7" style="justify-content: center">
                  <div class="col-4 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">cvc</label>
                      </div>
                      <input
                        pattern="[0-9]{1,}"
                        formControlName="cvc"
                        (ngModelChange)="validationForm('cvc')"
                        type="text"
                        class="num form-control"
                        placeholder="cvc"
                        id="cvc"
                        autocomplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-9 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Expiration Date</label>
                      </div>
                      <div
                        class="d-flex justify-content-between"
                        (keyup)="checkDate()"
                      >
                        <div class="col-5">
                          <input
                            type="text"
                            formControlName="CreditCardExpireDateMonth"
                            placeholder="MM"
                            class="num month form-control text-center"
                            required
                            maxlength="2"
                            (ngModelChange)="
                              validationForm('CreditCardExpireDateMonth')
                            "
                            id="CreditCardExpireDateMonth"
                            (change)="updateExpireDate()"
                            (focusout)="format($event)"
                            autocomplete="off"
                          />
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            formControlName="CreditCardExpireDateYear"
                            placeholder="YYYY"
                            class="num year form-control text-center"
                            required
                            (ngModelChange)="
                              validationForm('CreditCardExpireDateYear')
                            "
                            id="CreditCardExpireDateYear"
                            (change)="updateExpireDate()"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="errors col-12 position-relative">
                  <div class="mat-errors">
                    <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern">
                      Please Type Only 3 Numbers
                    </div>
                    <div
                      class="b-2"
                      *ngIf="
                        form.controls.CreditCardExpireDateMonth.errors?.pattern
                      "
                    >
                      Please Type Only Two Numbers Such As 07
                    </div>
                    <div class="b-2" *ngIf="errorMessageYear">
                      {{ errorMessageYear }}
                    </div>
                    <div
                      class="b-2"
                      *ngIf="
                        form.controls.CreditCardExpireDateYear.errors?.pattern
                      "
                    >
                      Please Type Only Four Numbers Such As 2022
                    </div>
                    <div class="b-2" *ngIf="errorMessageMonth">
                      {{ errorMessageMonth }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form billing my-4">
            <div class="col-12 text-center">
              <h6 class="title">Billing Address</h6>
              <p>Your billing address is totally secure and confidential.</p>
            </div>
            <div
              class="col-12"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="" style="width: 50%">
                <div class="position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Country</label>
                    </div>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="CreditCardCountry"
                      autocomplete="on"
                      placeholder="Select your Country"
                      (ngModelChange)="validationCountry('CreditCardCountry')"
                      id="CreditCardCountry"
                      (click)="$event.target.select()"
                      [matAutocomplete]="country_id"
                    />
                    <mat-autocomplete #country_id="matAutocomplete">
                      <mat-option
                        *ngFor="let option of filteredCountries | async"
                        [value]="option.name"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <mat-error
                    *ngIf="form.get('CreditCardCountry').errors?.someProp"
                  >
                    {{ form.get("CreditCardCountry").errors?.someProp }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      f['CreditCardCountry'].errors?.required &&
                      f['CreditCardCountry'].dirty
                    "
                  >
                    Country Is Required
                  </mat-error>
                </div>
                <div class="position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Address</label>
                    </div>
                    <input
                      formControlName="CreditCardstreetAddress"
                      type="text"
                      placeholder="Address"
                      autocomplete="off"
                      (ngModelChange)="
                        validationForm('CreditCardstreetAddress')
                      "
                      id="CreditCardstreetAddress"
                      class="form-control"
                      id="CreditCardstreetAddress"
                      required
                    />
                    <mat-error
                      *ngIf="
                        f['CreditCardstreetAddress'].errors?.required &&
                        f['CreditCardstreetAddress'].dirty
                      "
                    >
                      Address Is Required
                    </mat-error>
                  </div>
                </div>
                <div class="position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Zip</label>
                    </div>
                    <input
                      formControlName="CreditCardZip"
                      type="text"
                      class="num form-control"
                      placeholder="Zip"
                      (ngModelChange)="validationForm('CreditCardZip')"
                      id="CreditCardZip"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <mat-error
                    *ngIf="form.controls.CreditCardZip.errors?.pattern"
                  >
                    Please Type Only Numbers
                  </mat-error>
                  <mat-error
                    *ngIf="
                      f['CreditCardZip'].errors?.required &&
                      f['CreditCardZip'].dirty
                    "
                  >
                    Zip Is Required
                  </mat-error>
                </div>
                <div class="position-relative">
                  <div class="item letters">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">City</label>
                    </div>
                    <input
                      formControlName="CreditCardCity"
                      type="text"
                      class="form-control"
                      placeholder="City"
                      (ngModelChange)="validationForm('CreditCardCity')"
                      id="CreditCardCity"
                      autocomplete="off"
                      required
                    />
                    <mat-error
                      *ngIf="form.controls.CreditCardCity.errors?.pattern"
                    >
                      Please Type Only Letters</mat-error
                    >
                    <mat-error
                      *ngIf="
                        f['CreditCardCity'].errors?.required &&
                        f['CreditCardCity'].dirty
                      "
                    >
                      City Is Required
                    </mat-error>
                  </div>
                </div>
                <div class="position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">State</label>
                    </div>
                    <input
                      formControlName="State"
                      type="text"
                      placeholder="State Code"
                      class="form-control"
                      (ngModelChange)="validationForm('State')"
                      id="State"
                      autocomplete="off"
                      required
                    />
                    <mat-error
                      *ngIf="f['State'].errors?.required && f['State'].dirty"
                    >
                      State Is Required
                    </mat-error>
                    <mat-error *ngIf="f['State'].errors?.pattern"
                      >Please Type Only Letters</mat-error
                    >
                  </div>
                </div>
                <div class="position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Email</label>
                    </div>
                    <input
                      class="form-control"
                      formControlName="email"
                      type="email"
                      email
                      placeholder="Email"
                      id="email"
                      autocomplete="off"
                      (change)="validationForm('email')"
                      required
                    />
                  </div>
                  <!-- <div  class="col-1 validationMark">
                    <div *ngIf="f['email'].valid && !messageCheckEmail" class="ml-2 ">
                      <img style="height:3vh;" src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e" class="check-icon" alt="check" title="check" />
                  </div>
                </div> -->
                  <!-- <mat-error *ngIf="messageCheckEmail">{{messageCheckEmail}}</mat-error> -->
                  <mat-error
                    *ngIf="f['email'].errors?.required && f['email'].dirty"
                    >Your email is required</mat-error
                  >
                  <mat-error
                    *ngIf="f['email']?.errors?.email && f['email'].dirty"
                  >
                    Please Enter a Valid Email
                  </mat-error>
                </div>
                <div class="mobile-number position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Phone Number</label>
                    </div>
                    <input
                      matInput
                      class="form-control border"
                      ng2TelInput
                      placeholder="Phone Number"
                      type="text"
                      name="phone"
                      formControlName="phone"
                      id="phone"
                      (ngModelChange)="validationForm('phone')"
                      style="display: block; width: 160%"
                    />
                  </div>
                  <mat-error *ngIf="form.controls.phone.errors?.pattern">
                    Phone Number Is Required and Type Only Numbers
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-4 mt-5">
      <div class="card position-relative" style="width: 100%">
        <div class="position-absolute" style="top: 10px; left: 10px">
          <span class="badge rounded-pill" style="background: #202843"
            >Vip Access</span
          >
        </div>
        <div class="position-absolute" style="top: 8%; left: 5%">
          <h3 style="color: white; font-size: 20px; font-weight: bolder">
            {{ hotelDetails.data.hotel.name }}
          </h3>
        </div>
        <div class="card-img">
          <img
            [src]="hotelDetails.data.main_image"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
          <div class="mb-3">
            <span class="card-title" style="font-size: 14px; font-weight: bold"
              >8.6/10 Excellent (1,003 reviews)</span
            >
          </div>
          <p class="card-text" style="font-size: 12px; font-weight: bold">
            Guests rated this property 9/10 for cleanliness
          </p>
          <div class="room-summary">
            <span style="font-weight: bolder; font-size: 15px; color: #616161">
              1 Room :
            </span>
            <span style="font-size: 14px; font-weight: bold; color: #616161">
              Allure Junior Suite Tropical View King
            </span>
          </div>
          <div class="p-card" style="margin-top: 20px">
            <strong>Check-in: </strong
            ><span style="font-size: 14px">{{
              hotelDetails.data.check_in
            }}</span>
          </div>
          <div class="p-card">
            <strong>Check-out: </strong
            ><span style="font-size: 14px">{{
              hotelDetails.data.check_out
            }}</span>
          </div>
          <p class="p-card">
            {{ hotelDetails.data.number_of_nights }}-night stay
          </p>
          <hr />
          <div
            class="d-flex gap-3"
            style="justify-content: start; align-items: self-start"
          >
            <!-- <div class="bg-danger rounded-circle position-relative"><span class="position-absolute top-50 start-50 translate-middle">VIP</span></div> -->
            <!-- <div class=" position-relative bg-danger rounded-circle"><p class="position-absolute top-50 start-50 translate-middle"> VIP</p></div> -->
            <div
              class="rounded-circle"
              style="
                padding: 10px;
                font-size: 13px;
                font-weight: 900;
                background-color: #202843;
                color: white;
              "
            >
              VIP
            </div>
            <p style="font-size: 12px">
              Expect outstanding service at this top-rated VIP Access stay.
            </p>
          </div>
        </div>
      </div>
      <div class="card mt-3" style="width: 100%">
        <div class="card-body">
          <h3 class="card-title" style="font-size: 25px; font-weight: 600">
            Price details
          </h3>
          <hr />
          <div class="d-flex justify-content-between">
            <p style="font-size: 14px">1 room x 1 night</p>
            <p style="font-size: 14px">$369.39</p>
          </div>
          <div
            class="mod-logo bg-success rounded d-inline text-light"
            style="padding: 5px; font-size: 14px; font-weight: 700"
          >
            <img
              src="../../../assets/images/form-vacation/tag_logo.svg"
              alt=""
              style="width: 6%"
            />
            35% off
          </div>
          <div class="d-flex justify-content-between mt-3">
            <p style="font-size: 14px">Taxes</p>
            <p style="font-size: 14px">included</p>
          </div>
          <div class="d-flex justify-content-between">
            <p style="font-size: 14px">Hotel Booking Protection Plus</p>
            <p style="font-size: 14px">$20.32</p>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <p style="font-size: 14px">Total</p>
            <p style="font-size: 14px">$389.71</p>
          </div>
          <a href="" style="font-size: 13px; font-weight: 700"
            >Use a coupon , credit or promotion code</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="navigationButtons mt-4">
    <div class="col-12">
      <div
        class="row pb-2 justify-content-center d-flex flex-row align-items-center"
      >
        <!-- (click)="previousStepEvent.emit()" -->
        <button
          [routerLink]="['/vacation-hotel-traveller-form']"
          class="btn navBtn previousStepBtn"
        >
          Back To Vacation Details
        </button>
        <!-- (click)="submit()" -->
        <!-- [disabled]="form.invalid" -->
        <button class="btn navBtn nextStepBtn">Review & Book</button>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
