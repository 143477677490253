<div class="formContainer">
  <div *ngIf="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{errorMessage}}
  </div>
  <div class="row" >

    <div *ngIf="flightMax" class="alert-div alert alert-danger alert-dismissible fade show" role="alert">
      <span>Dear Traveller, Flyallover can only book up to 5 trips at a time.</span>
    </div>

    <div class="col-sm-12 col-lg-8 col-xxl-9 pl-0">
      <div class="row justify-content-between align-items-center">
      <ng-container *ngFor="let flight of flights; let i = index">
        <div class="row">
          <div class="col-12">
        <div class="row flight" [ngClass]="{'firstFlight': i ==0}">
          <div class="col-md-4">
            <div class="row">
              <div class="inputContainer">
                <app-from-input (setOriginEvent)="setOrigin($event, i )"

                                [inputValue]=origins?origins[i]:null></app-from-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="inputContainer">
                <app-to-input (setDestinationEvent)="setDestination($event, i)"
                              [inputValue]=destinations?destinations[i]:null></app-to-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="inputContainer">
                <app-depart-input
                  (openDesktopCalenderEvent)="openDesktopCalender(i)"
                  [departureDate]=departureDates?departureDates[i]:null>
                </app-depart-input>
              </div>
            </div>
          </div>

        </div>
      </div>
        <div class="col-1 deleteFlightContainer" *ngIf="i>1">
          <div class="deleteFlight" (click)="deleteFlight(i)">
            <i class="fas fa-times" style="margin-top: 6vh;"></i>
          </div>
        </div>
      <!-- </div> -->
      </div>
      </ng-container>
      <div class="col-12 col-xs-12">
        <div class="row">
          <div class="d-flex  addDirectFlight">
            <div class="col-8 p-0 filters">
              <app-direct-flight-input [stops]="stops"
                                       (setDirectFlightEvent)="setDirectFlight($event)"></app-direct-flight-input>
            </div>
            <div class="col-sm-12  col-lg-4 row p-0" style="justify-content: end;">
              <div class=" addFlightBtn" (click)="addFlight()" disabled="flightMax">
                <span style="
    display: flex;
    justify-content: flex-end;
">+ Add Flight</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="col-sm-12 p-0 col-lg-4 col-xxl-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-5 p-0 col-12-sm">
          <div class="row ml-15">
            <div class="inputContainer">
              <app-travellers-classes-input [className]="className"  [classTrip]="classTrip"  [adult]=adult [child]=child [seat]=seat [lap]=lap
                                            [totalTravellers]="totalTravellers" (travellersDetails)="openTravellersDialog($event)">
              </app-travellers-classes-input>

            </div>
          </div>
        </div>
        <div class="col-7 p-0 hidden-sm">
          <div >
            <div class="inputContainer submitBtn ">
              <button (click)="submit(); searchClose()">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
