<ngb-carousel class="text-lg-center" [interval]="5000">
  <ng-template ngbSlide *ngFor="let img of images">
    <div class="picsum-img-wrapper">
      <img
        class="image-slider"
        [src]="img"
        alt="Random first slide"
        title="first slide"
      />
      <div class="filter"></div>
    </div>
    <div class="carousel-caption"></div>
  </ng-template>
  <!-- <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img
        class="image-slider"
        src="./assets/images/flyallover-homepage/2.png"
        alt="Random second slide"
        title="second slide"
      />
    </div>
    <div class="carousel-caption"></div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img
        class="image-slider"
        src="./assets/images/flyallover-homepage/3.png"
        alt="Random third slide"
        title="third slide"
      />
    </div>
    <div class="carousel-caption"></div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img
        class="image-slider"
        src="./assets/images/flyallover-homepage/4.png"
        alt="Random fourth slide"
        title="fourth slide"
      />
    </div>
    <div class="carousel-caption"></div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img
        class="image-slider"
        src="./assets/images/flyallover-homepage/air.jpg"
        alt="Random fifth slide"
        title="fifth slide"
      />
    </div>
    <div class="carousel-caption"></div>
  </ng-template> -->
</ngb-carousel>
