import { Component, Injector, OnInit, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FormVacationHotelTravellerDataComponent } from "src/app/components/form-vacation-hotel-traveller-data/form-vacation-hotel-traveller-data.component";
import { HotelsApisService } from "src/app/services/hotels-apis.service";

@Component({
  selector: "app-vacations-hotels",
  templateUrl: "./vacations-hotels.component.html",
  styleUrls: ["./vacations-hotels.component.css"],
})
export class VacationsHotelsComponent implements OnInit {
  items = Array.from({ length: 2 }).map((_, i) => `Item #${i}`);
  step = 0;
  id: number;
  vacationDetails: any;
  long: any;
  lat: any;
  carouselImages: any;
  aminities: any;
  branchId: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private hotelsService: HotelsApisService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get("id");
    // console.log(this.id);
    this.hotelsService.vacationDetails(this.id).subscribe((res: any) => {
      this.vacationDetails = res.data;
      this.hotelsService.hotelInfo = this.vacationDetails;
      this.branchId = this.vacationDetails.hotel.Branches[0].id;
      this.lat = this.vacationDetails.address.latitude;
      this.long = this.vacationDetails.address.longitude;
      this.carouselImages = this.vacationDetails.images;
      // console.log(this.vacationDetails);
      // console.log(this.branchId);
      this.hotelsService
        .getVacationsAminities(this.branchId)
        .subscribe((res: any) => {
          this.aminities = res.data;
          // console.log("aminities", this.aminities);
        });
    });
  }
  routToHotelPage() {
    this.router.navigate(["vacation-hotel-details/", this.id]);
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  openDialog() {
    const dialogRef = this.matDialog.open(
      FormVacationHotelTravellerDataComponent,
      {
        data: this.vacationDetails,
        maxWidth: "70%",
        panelClass: "booking-dialog",
      }
    );
  }
}
