 <!-- <span>
      From {{shortOrigin}} to {{shortDestination}}
    </span>       -->

<div class="engine-static">
  <form>
    <!-- <app-from-input></app-from-input>
    <app-to-input></app-to-input> -->
  
   
    <div class="row">
      <app-from-input-static></app-from-input-static>
      <app-to-input-static></app-to-input-static>
      
      <div class="col-12">
        
        <label>Depart</label>
        <app-depart-input
          (openDesktopCalenderEvent)="openDesktopCalender($event)"
          [departureDate]=departureDate></app-depart-input>
      </div>
      <div class="col-12">
        <label>Return</label>
        <app-return-input
          (openDesktopCalenderEvent)="openDesktopCalender($event)"
          [returnDate]=returnDate></app-return-input>
      </div>
      <div class="col-12">
        <div class="select-box">
          <label>Adults</label>
          <select [value]="'1'" (change)="setAdults($event.target.value)">
            <option *ngFor="let num of numberPassenger">{{num + 1}}</option>
          </select>
        </div>
        <div class="select-box">
          <label>Children</label>
          <select (change)="setChildren($event.target.value)">
            <option *ngFor="let num of numberPassenger">{{num}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 justify-content-center mt-4">
        <button (click)="submit()">Search</button>
      </div>
    </div>
  </form>
</div>
