import { Component, Inject, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { HotelsApisService } from "src/app/services/hotels-apis.service";

@Component({
  selector: "app-form-vacation-hotel-traveller-data",
  templateUrl: "./form-vacation-hotel-traveller-data.component.html",
  styleUrls: ["./form-vacation-hotel-traveller-data.component.css"],
})
export class FormVacationHotelTravellerDataComponent implements OnInit {
  hotelDetails: any;
  numberOfTravellers: number = 1;
  NOTravellers = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
  ];
  constructor(
    public dialogRef: MatDialogRef<FormVacationHotelTravellerDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.hotelDetails = this.data;
    
  }
  changeNumber(e) {
    this.numberOfTravellers = e;
  }
  routeToPayment() {
    this.dialogRef.close();
    this.router.navigate([
      "/vacation-hotel-payment-form/",
      this.data.id,
      this.numberOfTravellers,
    ]);
  }
}
