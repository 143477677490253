<app-navbar></app-navbar>
<!-- start page body -->
<div class="container">
  <div class="change-page-body">
    <div class="header-change-page">
      <div class="title-header-change-page">
        Change Password
      </div>
    </div>
    <div class="body-change-page">
      <!-- start contact-us -->
      <div class="container">
        <div class="contact-us">
          <form [formGroup]="resetPasswordForm">

            <div class="form-group">
              <p class="text-center">Please set your new password </p>
              <!-- Password -->
              <div class="field col-6">
                <label for="password"
                >*New
                  Password</label>
                <input class="form-control" (keyup)="resetError()" id="password" type="password" required
                       [(ngModel)]="newPassword"
                       formControlName="password" minlength="6"
                />
                <!--                            <span *ngFor=" let validation of validationMessages.password">-->
                <!--                                <small class="text-danger"-->
                <!--                                    *ngIf="resetPasswordForm.get('password').hasError(validation.type) && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)">-->
                <!--                                    {{validation.message}}</small>-->
                <!--                            </span>-->
              </div>
              <!-- confirm password -->
              <div class="field col-6">
                <label for="confirmPassword"
                >*Confirm Password</label>
                <input class="form-control" (keyup)="checkpass()" type="password" required
                       formControlName="confirmPassword" id="confirmPassword" [(ngModel)]="ConfirmPassword"
                       minlength="6"
                />
                <!--                            <span *ngFor=" let validation of validationMessages.confirmPassword">-->
                <!--                                <small class="text-danger"-->
                <!--                                    *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)">-->
                <!--                                    {{validation.message}}</small>-->
                <!--                            </span>-->
                <small class="text-danger" *ngIf="checkpass()">Password and confirm password must
                  match</small>
                <small class="text-danger" *ngIf="errorMessage">{{errorMessage}}</small>
              </div>
              <div class="vt-mine-btn-port">

                        <span class="vt-mine-btn true" (click)="createPassword()">
                            <span class="vt-mine-btn "
                            >
                     CONFIRM
                                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i></span>
                        </span>
              </div>

            </div>
          </form>
          <!--<div class="inside text-center session-expired " *ngIf="sessionExpiration">
              <h2 class="text-danger">Your session has been expired</h2>
              <a routerLink="/forget-password">Reset your password again <i class="fas fa-angle-right"></i></a>
          </div>-->
        </div>
      </div>
      <!-- end contact-us -->
    </div>


  </div>
</div>
<!-- end page body -->
<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
