<div class="tourmaster-room-item clearfix tourmaster-room-item-style-grid">
  <div class="tourmaster-room-item-holder gdlr-core-js-2 clearfix" data-layout="fitrows">
    <div class="p-1">
      <div class="tourmaster-room-grid tourmaster-grid-frame" style="margin-bottom: 40px;">
        <div class="tourmaster-room-grid-inner" style="
                                                      box-shadow: 0px 20px 35px rgba(10, 10, 10, 0.06);
                                                      -moz-box-shadow: 0px 20px 35px rgba(10, 10, 10, 0.06);
                                                      -webkit-box-shadow: 0px 20px 35px rgba(10, 10, 10, 0.06);
                                                      border-radius: 20px;
                                                      -moz-border-radius: 20px;
                                                      -webkit-border-radius: 20px;
                                                  ">
          <div
            class="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
            <a routerLink="/room-details/{{hotelCode}}/{{room.id}}">
              <img src="../../../../assets/images/hotel-info/andrew-neel-T0eb55DxDN4-unsplash-700x450.jpg" alt=""
                width="700" height="250" />
            </a>
            <div class="tourmaster-price-wrap tourmaster-with-bg"
              style="border-radius: 10px; -moz-border-radius: 10px; -webkit-border-radius: 10px;">
              <span class="tourmaster-head">From</span><span class="tourmaster-price">$90</span><span
                class="tourmaster-tail"> / night</span>
            </div>
          </div>
          <div class="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
            data-sync-height="room-item-10" style="padding-top: 45px; padding-bottom: 30px;">
            <ng-container *ngFor="let item of changeRoomName(room.room_description_name); let i = index">
              <h3 *ngIf="i==0" class="tourmaster-room-title gdlr-core-skin-title" style="text-transform: none;">
                <a routerLink="/room-details/{{hotelCode}}/{{room.id}}">{{item}}</a>
              </h3>
              <ul>
                <li *ngIf="i>0">{{item}}</li>
              </ul>
            </ng-container>

            <div class="tourmaster-info-wrap clearfix">
              <div class="d-flex flex-column">
                <ng-container *ngFor="let item of room.Beds">
                  <div class="tourmaster-info tourmaster-info-bed-type pb-2">
                    <i class="gdlr-icon-double-bed2"></i><span class="tourmaster-tail">{{item.count }}
                      {{item.BedType[0]?.ota_code_list_name}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="tourmaster-info tourmaster-info-guest-amount"><i class="gdlr-icon-group"></i><span
                  class="tourmaster-tail">{{room.occupancy}} Guests</span></div>
            </div>
            <div class="tourmaster-room-rating">
              <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                class="fa fa-star"></i>
              <span class="tourmaster-room-rating-text">1 Review</span>
            </div>
            <a class="tourmaster-read-more tourmaster-type-text"
              routerLink="/room-details/{{hotelCode}}/{{room.id}}">Book Now<i class="icon-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
