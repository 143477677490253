import {Component, EventEmitter, Input, Output, OnChanges, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-direct-flight-input',
  templateUrl: './direct-flight-input.component.html',
  styleUrls: ['./direct-flight-input.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DirectFlightInputComponent implements OnChanges {
  @Output() setDirectFlightEvent = new EventEmitter();
  @Input() stops;
  @Input() color;
  control = new FormControl();
  checked = false;

  ngOnChanges(changes) {
    if (changes.stops.currentValue && changes.stops.currentValue === '0') {
      this.checked = true;
    }
  }

  setDirectFlight(): void {
    this.setDirectFlightEvent.emit(this.checked);
  }

}
