<input (click)="showList()" [(ngModel)]="passengers[0]?.count + passengers[1].count" name="total" type="text"
    class="form-control" readonly autocomplete="off" />
<div *ngIf="showPassengerList" class="list list-travellers">

    <div class="travellers">
      <h5>Travellers</h5>
        <!-- warning if number of passengers exceeds 9 passengers -->
        <div *ngIf="maxNumbersWarning" class="alert alert-warning fade show" role="alert">
            Our site can search up to <strong>6 adults and 6 children</strong> at once. If you are planning to travel
            with a bigger group, please call our Group Travel Experts at <a href="tel:1-212-381-1167">1-212-381-1167</a>
            . We will find your group the best deal!
        </div>
        <div *ngFor="let passenger of passengers" class="traveller adult">
            {{passenger.disc}}
            <div class="counter">
                <i (click)="dec(passenger.id)" class="minus count fas fa-minus"></i>
                <span class="result" id="adults">{{passenger.count}}</span>
                <i (click)="inc(passenger.id)" class="plus count far fa-plus-square"></i>
            </div>
        </div>
        <div *ngIf="childrenAges?.length > 0" class="row children-ages w-100">
            <p class="traveller w-100 mb-2">Children Ages</p>
            <div class="col-4" *ngFor="let child of childrenAges; let i = index">
                <select [(ngModel)]="childrenAges[i]" class="w-100 my-2" (change)="notify.emit()">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                </select>
            </div>
        </div>
    </div>
</div>
