import { Component, OnInit, Input, Output, EventEmitter, Inject, SimpleChanges, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-engine-search-cards',
  templateUrl: './engine-search-cards.component.html',
  styleUrls: ['./engine-search-cards.component.css']
})
export class EngineSearchCardsComponent implements OnInit {
  route: string;
  airline: any;
  // @Input() myinputMsg: string;
  // myInputMessage: string = 'I am the parent comppnent';

  constructor(public dialogRef: MatDialogRef<EngineSearchCardsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, location: Location, router: Router) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        //   this.route = location.path();
        // } else {
        //   this.route = "Home";
        this.close();
      }
    });

  }

  ngOnInit(): void {
    this.airline = this.data

  }

  close(): void {
    this.dialogRef.close();
  }

}