<div class="bady-engine">
  <div class="tabs-search-engine">
    <div class="tab-title-engine" *ngFor="let tab of tabsObj" [ngClass]="{'active':tab.code==tabs.code}"
      (click)="setTab(tab)">
      <img alt="hotel" title="hotel" style="width: 24px;height: 24px; padding-right: 6px;" [src]="tab.image"
        [ngClass]="{'color-img':tab.code==tabs.code}"> <span>{{tab.name}}</span>
    </div>
  </div>
  <div class="body-search-engine" [ngSwitch]="tabs.code">
    <ng-container *ngSwitchDefault>
      <app-search-engine-flight></app-search-engine-flight>

    </ng-container>
    <ng-container *ngSwitchCase="'hotel'">
      <app-search-engine-hotel></app-search-engine-hotel>
    </ng-container>

  </div>
</div>
