import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SEOService } from "../../services/seo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Clipboard } from "@angular/cdk/clipboard";
import { AppComponent } from '../../app.component';
import { WindowService } from 'src/app/services/webApis/window.service';

@Component({
  selector: 'app-share-flight-details',
  templateUrl: './share-flight-details.component.html',
  styleUrls: ['./share-flight-details.component.css']
})
export class ShareFlightDetailsComponent implements OnInit ,AfterViewInit{
  val = 0;
  val2 = 1;
  tagId: any;
  dataFlight: any;
  tripType: string;
  flightData: any;
  itinary: any;
  itinerary: any = [];
  data: any;
  loading = false;
  modalReference: any;
  copied = false;
  loadings = [

    { width: '300px' },
    { width: '300px' },
    { width: '300px' },
    { width: '300px' },

  ];
  loadingsShare = [
    { width: '40px' },
    { width: '40px' },
    { width: '40px' },
    { width: '50px' },
  ];
  selectRound: boolean;
  selectedIndex: any;
  selectedItineraryTagId: any;
  selectDepartReturn: any;
  toggleDetails: any;
  flights: any;
  itineraries: any;
  metaD: string;
  title: string;
  imgDes: any;
  dataShare: any;
  imgShareDes: any;
  origin: any;
  trip_type: any;
  destination: any;
  imgOrig: any;
  imgShareOrig: any;
  flightDataShare: any;
  flightsShare: any;
  totalPrice: any;
  trip_type_Sh: string;

  constructor(
    private localStorage: LocalStorageService,
    private seoService: SEOService,
    private _ActivatedRoute: ActivatedRoute,
    private apis: ApisService,
    private cookie: CookieService,
    private _Router: Router,
    private modalService: NgbModal,
    private router: Router,
    private clipboard: Clipboard,
    private _Window: WindowService

  ) {
    this.tagId = this._ActivatedRoute.snapshot.paramMap.get('tagId')
    this.tripType = this._ActivatedRoute.snapshot.paramMap.get('tripType')
    this.CallFlightDataShare();
    //this.seoUpdate();

  }

  ngOnInit(): void {
    console.log('this.tagId', this.tagId);
    console.log('this.tripType', this.tripType);
    // this.callFlightAPi();
  }

  ngAfterViewInit() {
    //this.callFlightAPi();
  }


  seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }


  callFlightAPi() {
    this.apis.getTripById(this.tagId, this.tripType).subscribe(
      (data: any) => {
        this.data = data.data;
        // this.imgDes = this.data?.destination?.main_image;
        // this.imgOrig = this.data?.origin?.main_image;
        this.flightData = data.data?.flight_data;
        this.flights = this.data.flight_data.flightDetails;
        this.itinerary = data.data?.Itineraries['0'];
        this.itineraries = data.data?.Itineraries;
        console.log('itinerary', this.itinerary);

        this.loading = true;
      }
    );
  }
  // this.metaD =' Book your'+ this.trip_type + 'From' + this.origin +'To' + this.destination +
  // ' don’t miss the chance and book online now'
  CallFlightDataShare() {
    this.apis.getFlightDataShare(this.tagId, this.tripType).subscribe(
      (data: any) => {
        this.dataShare = data.data;
        this.imgShareDes = this.dataShare?.destination?.main_image;
        this.imgShareOrig = this.dataShare?.origin?.main_image;
        this.trip_type = this.dataShare?.flight_data?.trip_type;
        if(this.trip_type == "Return"){
          this.trip_type_Sh = "Round Trip";
        }else{
          this.trip_type_Sh= this.trip_type ;
        }
        this.origin = this.dataShare?.origin?.city_name;
        this.destination = this.dataShare?.destination?.city_name;
        this.flightDataShare = this.dataShare?.flight_data;
        this.flightsShare = this.dataShare.flight_data.flightDetails;
        this.totalPrice= this.dataShare.flight_detail.itin_total_fare;

        console.log(this.dataShare, 'this.dataShare');
        console.log("shared image ", this.imgShareOrig);

        // this.metaD =' Book your tern From cai To jfk  don’t miss the chance and book online now'
        this.metaD = ' For just $ ' + this.totalPrice + ' Book the cheapest ' +  this.trip_type_Sh +  ' flight From ' + this.origin + ' To ' + this.destination
        this.title = 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips ' 
        // "Book the cheapest return flight from Cairo to New York for just $0000"
        // this.seoUpdate();
        this.seoUpdate(  this.metaD, this.title, this.imgShareDes);
      }
    );
  }

  // callSearchAPi(request = this.searchRequest) {
  //   this.loading = true;
  //   this.noResult = false;
  //   this.apis.search(request).subscribe(
  //     (data: any) => {
  //       this.loading = false;
  //       this.searchResults = data.data;
  //       if(this.searchResults.Itineraries.length < 10) {
  //         this.itineraries=this.searchResults.Itineraries;
  //         console.log('itineraries if', this.itineraries)
  //       }else {
  //         this.itineraries = this.searchResults.Itineraries.slice((this.page * this.pageSize), ((this.page + 1) * (this.pageSize)));
  //         console.log('itineraries else', this.itineraries)
  //       }
  //       this.flights = this.searchResults.flight;
  //       this.origin = this.searchResults.origin;
  //       this.destination = this.searchResults.destination;
  //       this.requestId = this.searchResults.RequestID;
  //       this.collectionSize = this.searchResults.pages.total_item;
  //       if (this.requestId) {
  //         this.callBestFlightsApi();
  //       }
  //     },
  //     (err) => {
  //       this.noResult = true;
  //     }
  //   );
  // }
  openTransfer(ShareIcon) {
    this.modalReference = this.modalService.open(ShareIcon, {
      centered: true,
      size: 'md'
    });


  }
  closeModal() {

    this.modalReference.close();
  }
  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied = !this.copied
    setTimeout(() => {
      this.copied = false;
    }, 1500)
  }

  fb() {
    let url = 'www.google.com';
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + 'https://www.flyallover.com' + this.router.url,
      'facebook-popUp',
      'width=600px,height=350px,'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  linked() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }


  // SelectFlightDetails() {
  //   this.router.navigate(['/review-flight', this.tagId, this.tripType]);
  // }

  get(e): boolean {
    if (e == 'Aus-Air') {
      return true;
    }
    return false;
  }

  selectRoundIti(e, event) {
    this.selectRound = !this.selectRound;
    this.selectedIndex = e;
    this.selectedItineraryTagId = event.TagID;

    this.selectDepartReturn.emit({
      selectedIndex: this.selectedIndex,
      selctedRound: this.selectRound,
      selectedItineraryTagId: this.selectedItineraryTagId
    });

  }
  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = ` ${Math.floor(minutes / 60)}h`;
    }
    if (Math.floor(minutes % 60) > 0) {
      convertedMinutes = ` ${minutes % 60}m`;
    }
    return convertedHours + ' ' + convertedMinutes;
  }
  SelectFlightDetails(){
    this.router.navigate(['/review-flight',this.tagId,this.tripType]);

  }

}
