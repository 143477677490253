<div class="home-logos">
  <div class="container-fluid">
    <div class="row">
      <owl-carousel-o *ngIf="airLines" [options]="customOptions">
        <ng-container *ngFor="let item of airLines let i = index" >
          <ng-template carouselSlide>
            <div class="slide d-flex flex-column align-items-center">
              <a class="d-block"  [routerLink]="['/airline-description/',item.callsign]">
                <img class="d-block" src="{{'https://api.flyallover.com/storage/'+item.image}}" alt="{{item.name}}" title="{{item.name}}"
               >
              </a>
              <div class="  icons">
                <a class="d-flex size-links" [routerLink]="['/Bagging-Information/',item.callsign]">
                  Baggage Allowance
                </a>
                <a class="size-links d-flex" [routerLink]="['/Online-Check/',item.callsign]" >
                  Online Check
                </a>
                <div *ngIf="item.name == 'Saudi Arabian Airlines'">
                  <a class="mt-1 size-links d-flex" href="https://visa.visitsaudi.com/" target="_blank"> eVisa</a>
                </div>
                <a class="size-links d-flex" [routerLink]="['/FAQ/',item.callsign]" >
                  FAQ
                </a>

              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
