import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-bg-home',
  templateUrl: './bg-home.component.html',
  styleUrls: ['./bg-home.component.css']
})
export class BgHomeComponent implements OnInit {

  constructor() { }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayTimeout: 7000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  };
  ngOnInit(): void {
  }

}
