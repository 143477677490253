<owl-carousel-o  [options]="customOptions" class="d-flex justify-content-center">
  <!-- <ng-container *ngFor="let item of data | reverse;  let i = index"> -->
    <!-- <ng-container *ngIf="i<= 5"> -->
      <ng-template carouselSlide>
        <div class="col-lg-4 p-0">
          <div class="trend-item1 box-shadow bg-white text-center">
            <div class="trend-image position-relative">
              <img src="images/new-deal/deal1.jpg" alt="image" class="">
              <div class="trend-content1 px-4  top-50 bottom-inherit translatey-50">
                <h5 class="theme1 mb-0"><i class="flaticon-location-pin"></i> Norway</h5>
                <h2 class="mb-1 white"><a href="grid-leftfilter.html" class="white">Norway Lake</a></h2>
                <div class="rating-main pb-1">
                  <div class="rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="ms-2 white">(16)</span>
                  </div>
                </div>
                <div class="entry-meta text-center">
                  <div class="entry-author mb-1">
                    <p class="mb-0 white"><span class="theme1 fw-bold fs-5"> $180.00</span> | Per person</p>
                  </div>
                  <div class="entry-author">
                    <i class="icon-calendar white"></i>
                    <span class="fw-bold white"> 6 Days Tours</span>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div></ng-template><ng-template carouselSlide>
        <div class="col-lg-4 p-0">
          <div class="trend-item1 box-shadow bg-white text-center">
            <div class="trend-image position-relative">
              <img src="images/new-deal/deal2.jpg" alt="image" class="">
              <div class="trend-content1 px-4 top-50 bottom-inherit translatey-50">
                <h5 class="theme1 mb-0"><i class="flaticon-location-pin"></i> Moscow</h5>
                <h2 class="mb-1 white"><a href="grid-leftfilter.html" class="white">Capital Tour</a></h2>
                <div class="rating-main pb-1">
                  <div class="rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="ms-2 white">(21)</span>
                  </div>

                </div>
                <div class="entry-meta text-center">
                  <div class="entry-author mb-1">
                    <p class="mb-0 white"><span class="theme1 fw-bold fs-5"> $240.00</span> | Per person</p>
                  </div>
                  <div class="entry-author">
                    <i class="icon-calendar white"></i>
                    <span class="fw-bold white"> 5 Days Tours</span>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div></ng-template><ng-template carouselSlide>
        <div class="col-lg-4 p-0">
          <div class="trend-item1 box-shadow bg-white text-center">
            <div class="trend-image position-relative">
              <img src="images/new-deal/deal3.jpg" alt="image" class="">
              <div class="trend-content1 px-4 top-50 bottom-inherit translatey-50">
                <h5 class="theme1 mb-0"><i class="flaticon-location-pin"></i> Italy</h5>
                <h2 class="mb-1 white"><a href="grid-leftfilter.html" class="white">Rome Historical</a></h2>
                <div class="rating-main pb-1">
                  <div class="rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="ms-2 white">(18)</span>
                  </div>

                </div>
                <div class="entry-meta text-center">
                  <div class="entry-author mb-1">
                    <p class="mb-0 white"><span class="theme1 fw-bold fs-5"> $190.00</span> | Per person</p>
                  </div>
                  <div class="entry-authorr">
                    <i class="icon-calendar white"></i>
                    <span class="fw-bold white"> 9 Days Tours</span>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div></ng-template><ng-template carouselSlide>
        <div class="col-lg-4 p-0">
          <div class="trend-item1 box-shadow bg-white text-center">
            <div class="trend-image position-relative">
              <img src="images/new-deal/deal4.jpg" alt="image" class="">
              <div class="trend-content1 px-4 top-50 bottom-inherit translatey-50">
                <h5 class="theme1 mb-0"><i class="flaticon-location-pin"></i> America</h5>
                <h2 class="mb-1 white"><a href="grid-leftfilter.html" class="white">Golden Gate</a></h2>
                <div class="rating-main pb-1">
                  <div class="rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="ms-2 white">(34)</span>
                  </div>

                </div>
                <div class="entry-meta text-center">
                  <div class="entry-author mb-1">
                    <p class="mb-0 white"><span class="theme1 fw-bold fs-5"> $310.00</span> | Per person</p>
                  </div>
                  <div class="entry-author">
                    <i class="icon-calendar white"></i>
                    <span class="fw-bold white"> 10 Days Tours</span>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div></ng-template><ng-template carouselSlide>
        <div class="col-lg-4 p-0">
          <div class="trend-item1 box-shadow bg-white text-center">
            <div class="trend-image position-relative">
              <img src="images/new-deal/deal5.jpg" alt="image" class="">
              <div class="trend-content1 px-4 top-50 bottom-inherit translatey-50">
                <h5 class="theme1 mb-0"><i class="flaticon-location-pin"></i> Austria</h5>
                <h2 class="mb-1 white"><a href="grid-leftfilter.html" class="white">Vienna, Hallstatt</a></h2>
                <div class="rating-main pb-1">
                  <div class="rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="ms-2 white">(22)</span>
                  </div>

                </div>
                <div class="entry-meta text-center">
                  <div class="entry-author mb-1">
                    <p class="mb-0 white"><span class="theme1 fw-bold fs-5"> $150.00</span> | Per person</p>
                  </div>
                  <div class="entry-author">
                    <i class="icon-calendar white"></i>
                    <span class="fw-bold white"> 3 Days Tours</span>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div></ng-template>
        </owl-carousel-o>
