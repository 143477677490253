<div class="EasyAccess">
  <h2 class="footer-info__header d-none d-lg-flex Font-Family-bold">Easy Access</h2>
  <div class="row d-none d-lg-flex">
    <mat-tab-group>
      <ul class="col-12 col-md-4 mb-4 footer-info__list " style="list-style: none;">
        <li class="footer-info__item">
          <mat-tab label="Top International Destinations">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="mt-1 mb-3 fontAir Font-Family">Get cheap air travel to global destinations in Europe, Asia and beyond.</div>
              <div class="row">
                <ul class="col-12 col-md-3 footer-info__list" style="list-style: none;" *ngFor="let item of thingstodo let i = index">
                  <li class="footer-info__item">
                    <div class="" >
                      <div class="card-body iti__cities airlines Font-Family" [routerLink]="['/popular-destinations/',item.city?.name, item.slug]">
                        {{item.city?.name}}
                      </div>
                    </div>
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Sydney/australia-sydney-ayr-airport">Sydney</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Algiers/algeria-algiers-houari-boumedienne-airport">Algiers</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Paris/france-paris-charles-de-gaulle-airport"> Paris</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Manama/bahrain-manama-bahrain-airport">Manama</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Toronto/canada-toronto-toronto-city-center-airport">Toronto</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <ul class="col-12 col-md-3 footer-info__list" style="list-style: none;">-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Cairo/egypt-cairo-cairo-airport"> Cairo</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Antwerp/belgium-antwerp-brussels-airport">Antwerp</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/London/united-kingdom-london-london-city-airport">London</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Berlin/germany-berlin-berlin-airport"> Berlin</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Jakarta/indonesia-jakarta-halim-perdana-kusuma-airport">Jakarta</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <ul class="col-12 col-md-3 footer-info__list" style="list-style: none;">-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Buenos%20Aires/argentina-buenos-aires-ministro-pistarini-airport">Buenos Aires</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Douala/cameroon-douala-doulala-airport"> Douala</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Rome/italy-rome-rome-airport">Rome</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Dubai/united-arab-emirates-dubai-airport"> Dubai</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Riyadh/saudi-arabia-riyadh-king-khaled-airport">Riyadh</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <ul class="col-12 col-md-3 footer-info__list" style="list-style: none;">-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Mexico%20City/mexico-mexico-city-merida-airport"> Mexico City</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Salalah/oman-salalah-salalah-airport">Salalah</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Baku/azerbaijan-baku-baku-airport">Baku</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Antwerp/belgium-antwerp-brussels-airport">Antwerp</a>-->
<!--                  </li>-->
<!--                  <li class="footer-info__item">-->
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/Hong%20Kong/hong-kong-hong-kong-airport">Hong Kong</a>-->
<!--                  </li>-->
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Top U.S. Destinations">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="mt-1 mb-3 Font-Family">Find cheap airline tickets to popular U.S. destinations from coast to coast.</div>
              <div class="row">
                <ul class="col-lg-6 col-xxl-4 footer-info__list" style="list-style: none;" *ngFor=" let item of populardestinations let i = index">
                  <li class="footer-info__item" >
<!--                    <a class="footer-info__link" target="_blank" href="https://flyallover.com/popular-destinations/New%20York/united-states-of-america-new-york-jfk-airport"> New York</a>-->
                    <div class="" >
                      <div class="card-body iti__cities airlines Font-Family" [routerLink]="['/popular-destinations/',item.city?.name, item.slug]">
                        {{item.city?.name}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Popular Airlines">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="mt-1 mb-3 Font-Family">Explore our cheap airfare options by carrier, with over 400 options to choose
                from.
              </div>
              <div class="row">
                <ul class="col-12 col-md-3 footer-info__list" style="list-style: none;" *ngFor="let item of airLines let i = index">
                  <li class="footer-info__item">
                    <div class="" >
                      <div class="card-body iti__cities airlines Font-Family" [routerLink]="['/airline-description/',item.callsign]">
                        {{item.name}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Popular Airports">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="mt-1 mb-3"></div>
              <div class="row">
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;" *ngFor="let item of airports let i = index">
                  <li class="footer-info__item" >
                    <div class="" >
                      <div class="card-body iti__cities airlines Font-Family" [routerLink]="['/airport-description/',item.code]">
                        {{item.name}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Site Directories">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="mt-1 mb-3 Font-Family">DIRECTORIES</div>
              <div class="row">
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.flyallover.com/offers">Offers</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.flyallover.com/blog">Blogs</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.flyallover.com/popular-destinations">Popular Destination
                    </a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.flyallover.com/things-to-do">Things To Do</a>
                  </li>
                </ul>
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://flyallover.com/pcr-time-slider-for-travel">PCR Locations</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.travelinsured.com/agency/?r=http:%2F%2Fwww.flyallover.com%2F&p=">Travel Insurance</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://flyallover.com/visa">Visa New</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.flyallover.com/about">About Us</a>
                  </li>
                </ul>

              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Stay Connected">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div class="row">
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.facebook.com/flyallover/">Flyallover on Facebook</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://twitter.com/FlyAllOverUS">Flyallover on Twitter</a>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.instagram.com/flyalloverus/">Flyallover on Instagram</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.linkedin.com/company/flyallover?challengeId=AQFH0P0MxALxZQAAAYBrr95LBpkhSzWZQFdMuulW_YmPZz88KkXwihQzXdXD4IDAT0VWcgqGexkmk-vjPhZnDOe8zeM8MpdQTg&submissionId=a87a473a-2ccb-e916-5a7c-a98c7b80b35a">Flyallover on LinkedIn</a>
                  </li>
                  <li class="footer-info__item">
                    <a class="footer-info__link Font-Family" target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured">Flyallover on YouTube</a>
                  </li>
                </ul>
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                </ul>
                <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                </ul>
              </div>
            </div>
          </mat-tab>
        </li>
        <li class="footer-info__item">
          <mat-tab label="Quick Links">
            <div class="tab-pane fade active show footer-info-Easy-Access">
              <div id="footer-info__tab--01">
                <div class="row">
                  <ul class="col-12 col-md-4 footer-info__list" style="list-style: none;">
                    <li class="footer-info__item">
                      <a class="Font-Family footer-info__link"[routerLink]="['/terms']" href="https://flyallover.com/terms">Terms & Conditions</a>
                    </li>
                    <li class="footer-info__item">
                      <a class="Font-Family footer-info__link"[routerLink]="['/privacy']" href="https://flyallover.com/privacy">Privacy Policies</a>
                      </li>
                    <li class="footer-info__item">
                      <a class="Font-Family footer-info__link"[routerLink]="['/chargeback-policy']" href="https://flyallover.com/privacy">Chargeback policy</a>
                    </li>
                    <li class="footer-info__item">
                      <a class="Font-Family footer-info__link"[routerLink]="['/cancellation-policy']" >Cancellation Policy</a>
                    </li>
                    </ul>
<!--                  <ul class="col-12 col-md-3 footerInfo__list" style="list-style: none;" *ngFor="let item of data; let i = index ">-->
<!--                    <li class="footer-info__item">-->
<!--                      <mat-drawer-container class="" autosize>-->
<!--                        <mat-drawer position="end" #drawer class="" mode="side">-->
<!--                          <div class="" *ngFor="let element of item.popular_destinations">-->
<!--                            <div class=" card-body iti__cities" [routerLink]="['../popular-destinations/', element.city.name,element.slug]">-->
<!--                              {{element.city.name}}-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </mat-drawer>-->

<!--                        <div class="footer-info__title">-->
<!--                          <button type="button" mat-button (click)="drawer.toggle();toggleArrow($event);" (blur)="removeArrow($event)">-->
<!--                            {{item.name}}-->
<!--                            <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi hide bi-play-fill" viewBox="0 0 16 16">-->
<!--                              <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>-->
<!--                            </svg>-->
<!--                          </button>-->
<!--                        </div>-->

<!--                      </mat-drawer-container>-->
<!--                    </li>-->
<!--                    <li class="footer-info__item">-->
<!--                  </ul>-->
                </div>
              </div>
            </div>
          </mat-tab>
        </li>
      </ul>
    </mat-tab-group>
  </div>
</div>
