import {Component, EventEmitter, Input, Output, OnChanges, OnInit} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-seats',
  templateUrl: './review-flight-seats.component.html',
  styleUrls: ['./review-flight-seats.component.css']
})

export class ReviewFlightSeatsComponent implements OnChanges ,OnInit{
  @Output() updateTravellersEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();
  @Input() tagId: any;
  @Input() itineraries: any;
  @Input() travellers: any;
  @Input() seats1;
  @Output() seats2 = new EventEmitter();
  currentFlightIndex: any = 0;
  currentTravellerIndex: any = 0;
  flights: any;
  seats: any;
  colors = [
    '#e67e22',
    '#3498db',
    '#30336b',
  ];

  constructor(
    private apis: ApisService,
    private seoService: SEOService
  ) {}

  ngOnInit() {
    this.seoUpdate();
    this.getSeats();
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  ngOnChanges(changes) {
    if (changes.tagId?.currentValue) {
      this.seats2.emit();
      this.getSeats();
    }
    if (changes.itineraries?.currentValue) {
      this.flights = Array();
      this.itineraries[0].forEach(el => {
        this.flights.push(el);
      });
      this.itineraries[1].forEach(el => {
        this.flights.push(el);
      });
    }

    if (changes.travellers?.currentValue) {
      for (let i = 0; i < this.travellers.length; i++) {
        this.travellers[i].color = this.colors[i];
      }
    }
  }

  getSeats() {
    if(this.seats1){
      this.seats = this.seats1;
    }
  }

  selectSeat(e) {
    let seat = {};
    let seatIndex = {};
    if (this.travellers[this.currentTravellerIndex].seatNumber) {
      seat = this.travellers[this.currentTravellerIndex].seatNumber;
    }
    if (this.travellers[this.currentTravellerIndex].seatIndex) {
      seatIndex = this.travellers[this.currentTravellerIndex].seatIndex;
    }
    seatIndex[this.currentFlightIndex] = {};
    seat[this.currentFlightIndex] = e.row + '' + e.column;
    seatIndex[this.currentFlightIndex]['i'] = e.i;
    seatIndex[this.currentFlightIndex]['j'] = e.j;
    seatIndex[this.currentFlightIndex]['k'] = e.k;
    this.travellers[this.currentTravellerIndex].seatNumber = seat;
    this.travellers[this.currentTravellerIndex].seatIndex = seatIndex;
    this.updateTravellersEvent.emit();
    if (this.currentTravellerIndex < this.travellers.length - 1) {
      this.currentTravellerIndex++;
    } else {
      if (this.currentFlightIndex < this.flights.length - 1) {
        this.currentTravellerIndex = 0;
        this.currentFlightIndex++;
      }
    }
  }

  jumpToFlight(e) {
    this.currentFlightIndex = e;
  }

  jumpToTraveller(e) {
    this.currentTravellerIndex = e;
  }

}
