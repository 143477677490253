import { Component, OnInit } from '@angular/core';
import {SEOService} from "../../../services/seo.service";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-view-itinerary',
  templateUrl: './view-itinerary.component.html',
  styleUrls: ['./view-itinerary.component.css']
})
export class ViewItineraryComponent implements OnInit {

  constructor(
    private seoService: SEOService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }
  seoUpdate() {

    this.seoService.updateTitle('Itinerary Email | Flyallover');
    this.seoService.updateDescription('Itinerary Email');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
