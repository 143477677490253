<div class="plan-trip benefit">
  <div class="tags">
    <ul>
      <li>
        <a
          (click)="selectSearchEngine('flights')"
          [ngClass]="searchEngine === 'flights' ? 'active' : ''"
        >
          <img
            title="flights search"
            alt="plane"
            [ngClass]="searchEngine === 'flights' ? 'd-none' : 'd-inline'"
            id="flight"
            src="./assets/images/plane (1)-grey.svg"
          />
          <img
            title="flights search"
            alt="flight"
            [ngClass]="searchEngine === 'flights' ? 'd-inline' : 'd-none'"
            src="./assets/images/things-todo/flight.svg"
          />
          Flights
        </a>
      </li>

      <li>
        <a
          (click)="selectSearchEngine('hotels')"
          [ngClass]="searchEngine === 'hotels' ? 'active' : ''"
        >
          <img
            title="hotels search"
            alt="hotel"
            [ngClass]="searchEngine === 'hotels' ? 'd-none' : 'd-inline'"
            src="./assets/images/things-todo/hotel.svg"
          />
          <img
            title="hotels search"
            alt="hotel-white"
            [ngClass]="searchEngine === 'hotels' ? 'd-inline' : 'd-none'"
            src="./assets/images/things-todo/hotel-white.svg"
          />

          Hotels
        </a>
      </li>
    </ul>
  </div>
  <div class="search mb-4">
    <app-hotel-search
      [ngClass]="{ 'd-none': !(searchEngine === 'hotels') }"
      (searchDataReady)="hotelSearchAPI($event)"
    >
    </app-hotel-search>
    <app-search
      [ngClass]="{ 'd-none': !(searchEngine === 'flights') }"
      (searchDataReady)="callSearchAPi($event)"
    >
    </app-search>
  </div>
</div>
