import {Component, EventEmitter, Input, Output, OnChanges, OnInit} from '@angular/core';

declare var $: any;
import 'jquery';
import {ApisService} from '../../../services/apis.service';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-seats-header',
  templateUrl: './review-flight-seats-header.component.html',
  styleUrls: ['./review-flight-seats-header.component.css']
})

export class ReviewFlightSeatsHeaderComponent implements OnChanges, OnInit {
  @Output() jumpToFlightEvent = new EventEmitter();
  @Output() jumpToTravellerEvent = new EventEmitter();
  @Input() flights: any;
  @Input() travellers: any;
  @Input() currentTravellerIndex: any;
  @Input() currentFlightIndex: any;

  constructor(
    private seoService: SEOService
  ) {
  }

  ngOnInit() {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle('Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnChanges(changes) {
    let offset = document.querySelector<HTMLElement>('#flightsHeader .active')?.offsetLeft;
    document.getElementById('flightsHeader')?.scrollTo(offset, 0);
  }

  scrollRight(): any {
    document.getElementById('flightsHeader').scrollLeft += 300;
    // let offset = document.querySelector<HTMLElement>('#flightsHeader .active').offsetLeft;
    // document.getElementById('flightsHeader').scrollTo(offset, 0);

  }

  jumpToFlight(e): any {
    this.jumpToFlightEvent.emit(e);
  }

  jumpToTraveller(e): any {
    this.jumpToTravellerEvent.emit(e);
  }

}
