<div class="card-body-note">
  <div class="">
    <div
      class="note-text">
      NOTE !
    </div>
    <div
      class="pargraph-text">
      We noticed that you did not proceed to the payment section to complete your flight booking on FLY OVER. No
      worries! We understand that sometimes there might be uncertainties or interruptions during the booking
      process.<br/><br/>To ease your concerns, we want to assure you that we will hold your reservation for at least
      12 hours. This means that you can come back anytime within the next 12 hours and continue with the booking
      process. We value your interest in flying with us and want to provide you with the flexibility to complete your
      reservation at your convenience.
      <br/><br/>
      Please keep in mind that flight prices are subject to change. While we strive to maintain the same price, there
      might be slight fluctuations due to various factors. However, we will do our best to ensure that any changes in
      price are minimal.
      <br/><br/>
      If you have any further questions or need assistance with your booking, please don't hesitate to reach out to our customer support team at 212-381-1167. We are here to help!
      <br/><br/>
      Thank you for considering FLY ALL OVER, and we look forward to welcoming you onboard soon.
    </div>
  </div>
  <div class="btn-btn-note" mat-dialog-close="">
    <span class="btn-note">
       HOME</span>
  </div>
</div>
