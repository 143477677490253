import {Component, Inject, OnInit} from '@angular/core';
import {SEOService} from '../../services/seo.service';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../services/apis.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import {BlogService} from '../../services/blog/blog.service';

@Component({
  selector: 'app-health-entry',
  templateUrl: './health-entry.component.html',
  styleUrls: ['./health-entry.component.css']
})
export class HealthEntryComponent implements OnInit {

  readMoreEntryHealthRequirements = true;
  readMoreCountryExperience = true;
  readMoreTransitRestrictions = true;
  readMoreEntryRequirements = true;
  linkToBeShared: any = `${AppComponent.hostUrl}${this.router.url}`;
  requestData: any;
  visaData: any;
  coverImage: any = '/assets/images/health/bg.png';
  loading = true;

  constructor(
    private seoService: SEOService,
    private apis: ApisService,
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    if (!this.route.snapshot.queryParams) {
      this.router.navigate(['visa']);
    }
    this.loading = true;
    this.apis.checkVisa(this.route.snapshot.queryParams).subscribe((res: any) => {
      this.visaData = res.data;
      const data = this.visaData.MetaData.meta_data.data;
      this.seoUpdate(data.title, data.description, data['og:image']);
      this.coverImage = data['og:image'];
      this.loading = false;
    }, err => {
      this.visaData = undefined;
      this.loading = false;
    });
  }

  seoUpdate(title = null, description = null, image = null) {

    this.seoService.updateTitle(this.router.url);
    this.seoService.updateDescription(this.router.url);
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }
}
