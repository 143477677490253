import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SEOService} from '../../services/seo.service';
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Clipboard } from "@angular/cdk/clipboard";
import { AppComponent } from '../../app.component';
import { WindowService } from 'src/app/services/webApis/window.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.css']
})
export class NewTripComponent implements OnInit {
  word = '';
  showResultSearch: boolean = false;
  searchInput: string = '';
  page: any =0;
  pageSize: number = 9;
  data: any = [];
  about: any;
  collectionSize: number;
  dataGnrl: any;
  tripId: any;
  title: any;
  formData: FormGroup;
  count: any;
  datashare: any;
  copied: boolean;
  constructor(
    private apis: ApisService,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute,
    private cookie: CookieService,
    private _Router: Router,
    private modalService: NgbModal,
    private router: Router,
    private clipboard: Clipboard,
    private _Window: WindowService,
    private formBuilder: FormBuilder,
  ) {

    this.tripId = this._ActivatedRoute.snapshot.paramMap.get('slug')
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });
    this.getTripsInfo();
    
   
  }


  ngOnInit(): void {
    this.seoUpdate();
  }


  getTripsInfo() {
    // if (typeof document !== 'undefined') {
    //   window.scrollTo({top: 0, behavior: 'smooth'});
    // }
    this.apis.getTripsById(this.tripId).subscribe((data: any) => {
      // this.data = data.reverse();
      this.title = data.data.title ;
      this.datashare = data.data;
      this.data = data.data.trip_includes;
      console.log(this.datashare ,'this.data details')
      
      },
      err => {
        console.log(err);
      });
  }

  seoUpdate() {
    this.seoService.updateTitle(this.datashare.title);
    this.seoService.updateDescription(this.datashare.meta_description);
    this.seoService.updateUrl();
    this.seoService.updateImage(this.datashare.image);
  }

  onSubmit(): void {
   
    const formData = new FormData();
    formData.append('name', this.formData.value.name );
    formData.append('email', this.formData.value.email );
    formData.append('phone', this.formData.value.phone );
    formData.append('slug', this.tripId );
    // this.formData.patchValue({
    //   trip_id: this.tripId,
      
    // });
    // @ts-ignore
    this.apis.getTripContact(formData).subscribe({
      next: (res: any) => {console.log(res); },
      error: (err: any) => {console.log(err); }
    });
    this.formData.reset();

  }
  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied = !this.copied
    setTimeout(() => {
      this.copied = false;
    }, 1500)
  }

  fb() {
    let url = 'www.google.com';
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + 'https://www.flyallover.com' + this.router.url,
      'facebook-popUp',
      'width=600px,height=350px,'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  linked() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }


}
