import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Input,
  PLATFORM_ID,
  AfterViewInit,
  OnChanges,
  DoCheck,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, DOCUMENT, ViewportScroller } from "@angular/common";
import { ApisService } from "src/app/services/apis.service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { WindowService } from "src/app/services/webApis/window.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SEOService } from "../../services/seo.service";
import { MatDialog } from "@angular/material/dialog";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { LoadingFlightResultsDialogComponent } from "src/app/components/loading-flight-results-dialog/loading-flight-results-dialog.component";
import { DataService } from '../../services/data.service';

@Component({
  selector: "app-flights-results",
  templateUrl: "./flights-results.component.html",
  styleUrls: ["./flights-results.component.css"],
})
export class FlightsResultsComponent
  implements OnInit {
  searchRequest: any;
  noResult = false;
  page = 1;
  selectedIndex: any;
  pageSize = 10;
  collectionSize: number;
  countRequest = 10;
  loading = false;
  resultsArrived = false;
  selectRound=false;
  searchAlternative = true;
  errorSearch = false;
  searchResults: any;
  itineraries: any[] = [];
  flights: any;
  origin: any;
  destination: any;
  requestId: string;
  airlines: any;
  airlinesNumber: number;
  airlinesNameChecked: string[] = [];
  detailsSorts: any;
  openSortDetails: any = false;
  dialogLoadingRef: any;
  alternativeDatesResponse: any;
  display: boolean = true;
  metaTitle: any;
  metDescription: any;
  offerId: any;
  message: any = "cheap travel deals";
  cheapestFlights: any;
  response: any;
  tripType: any;
  lowestPrice: any;
  lowestflight: any;
  selectedItineraryTagId: any;
  selected_Itinerary: any[] = [];
  selected_Flights: any[] = [];
  select_Flights: any[] = [];
  Bg_color = false;
  alternativeSend: any;
  viewAlternativeDates = false;
  interval: NodeJS.Timeout;
  randomMin: any;
  airlineForSearchData: any;
  airlineImg: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private seoService: SEOService,
    private router: Router,
    private apis: ApisService,
    private dataFake: DataService,
    private localStorage: LocalStorageService,
    private windowService: WindowService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // this.handleSeoData();
  }

  ngOnInit(): void {
    // this.getArrayValues(0);
    this.searchRequest = this.route.snapshot.queryParams;
    this.tripType = this.searchRequest.trip_type
    this.dataHandler();
    this.localStorage.removeItem('selectedItinerary')
    // this.getBestAlternativeDate(this.searchRequest);
  }
getAirlineForSearch(){
    const airlneRequestId = {
  request_id: this.requestId
    };
    this.apis.getAirlineForSearch(airlneRequestId).subscribe(
       (airline: any) => {
         this.flights = airline?.data;
  },
      err => {
        console.error(err);

      }
    );
}
  getArrayValues(index) {
    this.interval = setInterval(() => {
      if (index == this.dataFake.airlinesLog.length) {
        this.airlineImg = [];
        index = 0;
        return;
      }
      this.airlineImg.push(this.dataFake.airlinesLog[index]);
      index++;
    }, 500);
  }


  applyFiltersEvent(searchRequest): void {
    this.searchRequest = searchRequest;
    this.callFiltersNewApi();
  }

  BactToIti() {
    this.selectRound = false;
    window.location.reload()

  }

  changeCollectionNumberOfPages(event) {
    this.collectionSize = event;
  }
  getSelecteditinerary(event) {
    this.selectRound = event.selctedRound;
    this.selectedIndex = event.selectedIndex
    this.selectedItineraryTagId = event.selectedItineraryTagId
  }
  selectedItinerary(e) {
    this.Bg_color = true;
    this.selected_Itinerary.splice(0);
    this.selected_Itinerary.push(e);
    this.selected_Flights = e.flights;
  }

  getBestAlternativeDate(formData) {
    this.apis.searchAlternativeDate(formData).subscribe(
      (data: any) => {
        this.alternativeDatesResponse = data.data;
        if (this.noResult == true) {
          this.loading = false;

          this.itineraries = this.alternativeDatesResponse.Itineraries
          this.flights = this.alternativeDatesResponse.flight
          this.lowestflight = this.alternativeDatesResponse.Itineraries.reduce(
            (acc, loc) => acc.prices?.ItinTotalFare < loc.prices?.ItinTotalFare ? acc : loc
          )
          this.lowestPrice = this.lowestflight.prices.ItinTotalFare
          this.origin = this.alternativeDatesResponse.origin;
          this.destination = this.alternativeDatesResponse.destination;
          this.requestId = this.alternativeDatesResponse.RequestID;
          this.collectionSize = this.alternativeDatesResponse.pages.total_item;
          if (this.requestId) {
            this.getSortValues();
            this.getAirlineForSearch()
          }
        }
      },
      (err) => {
        this.searchAlternative = false
      }
    );
  }

  callFiltersNewApi() {
    this.loading = true;
    this.apis.newFilters(this.searchRequest).subscribe(
      (data: any) => {
        this.noResult = false;
        this.loading = false;

        this.searchResults = data.data;
        this.itineraries = this.searchResults.Itineraries;
        this.flights = this.searchResults.flights;
        this.requestId = this.searchResults.RequestID;
        if (this.requestId) {
          this.getAirlineForSearch();
        };
        this.page = 1;
        this.collectionSize = this.searchResults.pages.total_item;
      },
      (err) => {
        this.noResult = true;
      }
    );
  }

  closeFlightSort(): any {
    this.openSortDetails = false;
  }

  applyModifiedSearch(searchRequest): void {
    this.searchRequest = searchRequest;
    this.tripType = this.searchRequest.trip_type
    this.callSearchAPi();
  }
  dataHandler(): void {
    // this.searchRequest = this.route.snapshot.queryParams;
    // if (this.searchRequest.return_date && this.searchRequest.departure_date) {
    //   this.metaTitle = this.searchRequest.origin + ' ' + 'TO' + ' ' + this.searchRequest.destination + ' ' + this.searchRequest.departure_date.substring(5,) + ' ' + 'To'
    //     + ' ' + this.searchRequest.return_date.substring(5,);
    // }
    // this.metDescription = 'FlyAllOver en providing you the cheapest flight tickets, hotels';
    // this.seoUpdate(this.metaTitle, this.metDescription);
    // if (!this.searchRequest) {
    //   this.router.navigate(['/']);
    // }
    this.callSearchAPi();
  }

  handleSeoData(): void {
    this.searchRequest = this.route.snapshot.queryParams;
    if (this.searchRequest.return_date && this.searchRequest.departure_date) {
      this.metaTitle =
        this.searchRequest.origin +
        " " +
        "TO" +
        " " +
        this.searchRequest.destination +
        " --- " +
        this.searchRequest.departure_date.substring(5) +
        " " +
        "To" +
        " " +
        this.searchRequest.return_date.substring(5);
    }
    this.metDescription =
      "FlyAllOver en providing you the cheapest flight tickets, hotels";
    this.seoUpdate(this.metaTitle, this.metDescription);
  }


  seoUpdate(
    title = "flights results",
    description = "flights results",
    image = "https://flyallover.com/assets/images/home/metaDataImageHome.png",
    url = "https://api.flyallover.com/storage/"
  ) {
    if (title != null) {
      this.seoService.updateTitle(title);
    }

    if (description != null) {
      this.seoService.updateDescription(description);
    }

    this.seoService.updateUrl();

    if (image != null) {
      this.seoService.updateImage(image);
    }
  }


  callSearchAPi(request = this.searchRequest) {
    this.loading = true;
    this.noResult = false;
    this.apis.search(request).subscribe(
      (data: any) => {

        this.loading = false;
        this.searchResults = data.data;
        if (this.searchResults.Itineraries.length < 10) {
          this.itineraries = this.searchResults.Itineraries;
        } else {
          this.itineraries = this.searchResults.Itineraries.slice((0), ((this.page) * (this.pageSize)));
        }
        this.flights = this.searchResults.flight;
        this.origin = this.searchResults.origin;
        this.destination = this.searchResults.destination;
        this.requestId = this.searchResults.RequestID;
        this.collectionSize = this.searchResults.pages.total_item;
        if (this.requestId) {
          this.getSortValues();
          this.getAirlineForSearch()
        }


      },
      (err) => {
        console.error(err);

        this.noResult = true;
        if(this.searchAlternative == false){
          this.errorSearch = true;
          this.loading= false;
        }
      }
    );
  }

  offerCollection() {
    this.offerId = this.searchRequest.offer_id;
    const obj = {
      offer_id: this.offerId,
      request_id: this.requestId,
      iata: this.cheapestFlights.code,
      new_price: this.cheapestFlights.price,
    };
    this.updateOffers(obj);
  }

  filterAlternativeDates(data): void {
    const obj = {
      departure_date: data.departureDate,
      return_date: data.returnDate,
    };
    this.router
      .navigate([], {
        queryParams: obj,
        queryParamsHandling: "merge",
        skipLocationChange: true,
      })
      .then(() => {
        const request = JSON.parse(
          JSON.stringify(this.route.snapshot.queryParams)
        );
        this.callSearchAPi(request);
      });
  }

  setOpenSortDetails(): void {
    this.openSortDetails = !this.openSortDetails;
  }


  getNewPage() {
    if (isPlatformBrowser(this.platformId)) {
      this.windowService.nativeWindow.scrollTo(0, 0);
    }

    this.resultsArrived = false;
    this.loading = true;
    this.itineraries = this.searchResults.Itineraries.slice((this.page * this.pageSize), ((this.page + 1) * (this.pageSize)));
    this.resultsArrived = true;
    this.loading = false;
  }

  openLoadingDialog() {
    this.dialogLoadingRef = this.dialog.open(
      LoadingFlightResultsDialogComponent,
      {
        maxHeight: "70vh",
        width: "50vw",
        maxWidth: "100vw",
        data: { message: this.message },
      }
    );
    this.dialogLoadingRef.afterClosed().subscribe((result) => { });
  }

  createRange(range) {
    return Array(range);
  }

  setCheapest(e) {
    this.cheapestFlights = e["cheapest"][0];
  }

  select(TagID) {

    if (TagID.tagId) {
      const oneResult = {
        tagId: TagID.tagId,
        firstIndexId: TagID.first_index_id,
        second_index_id: TagID.second_index_id,
        second_tag_id: TagID.second_tag_id,
        origin: this.origin,
        class: this.searchRequest.class,
        trip_type: this.searchRequest.trip_type,
        selectedBy: 'tagId',
        offers: this.searchRequest.offers,
        payLater: this.searchRequest.payLater,
        multiTicket: true
      }
      this.localStorage.setItem("selectedFlight", JSON.stringify(oneResult));
      this.router.navigate([`/review-flight/${TagID?.tagId}/${this.searchRequest?.trip_type}`]);
    }
    else {
      const oneResult = {
        tagId: TagID,
        origin: this.origin,
        class: this.searchRequest.class,
        trip_type: this.searchRequest.trip_type,
        selectedBy: 'tagId',
        offers: this.searchRequest.offers,
        payLater: this.searchRequest.payLater,
        multiTicket: false
      };

      const array = TagID.split('-');
      this.router.navigate([`/review-flight/${TagID}/${this.searchRequest?.trip_type}`]);

    }
  }
  selectedObject(itinerary) {
    const oneResult = {
      tagId: itinerary.TagID,
      origin: this.origin,
      class: this.searchRequest.class,
      trip_type: this.searchRequest.trip_type,
      selectedBy: 'object',
      offers: this.searchRequest.offers,
      payLater: this.searchRequest.payLater
    };
    this.localStorage.setItem("selectedFlight", JSON.stringify(oneResult));
    this.localStorage.setItem("selectedItinerary", JSON.stringify([itinerary]));
    this.router.navigate(["review-flight"], { queryParams: oneResult });


  }
  updateOffers(data) {
    this.apis.updateOffers(data).subscribe(
      (res: any) => { },
      (err) => {
      }
    );
  }

  getSortValues() {
    this.apis.getSortValues(this.requestId).subscribe(
      (response: any) => {
        this.response = response.data;

        this.cheapestFlights = this.response["cheapest"][0];
        if (this.searchRequest.offer_id && this.cheapestFlights) {
          this.offerCollection();
        }
        //this.getBestAlternativeDate(this.searchRequest);
      },
      (err) => {
      }
    );
  }

  removeRedundantElements(array): string[] {
    const uniqueElementsArray = [];
    array.forEach((el) => {
      if (uniqueElementsArray.indexOf(el) === -1) {
        uniqueElementsArray.push(el);
      }
    });
    return uniqueElementsArray;
  }
  alternative(e) {
    this.alternativeSend = e;
  }
  ShowMenu(event: any) {
    if (event == 'viewAlternativeDates') {

      this.viewAlternativeDates = !this.viewAlternativeDates

    }
    this.selected_Itinerary.splice(0);
  }
}

