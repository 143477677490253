import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-login',
  templateUrl: './help-login.component.html',
  styleUrls: ['./help-login.component.css']
})
export class HelpLoginComponent implements OnInit {

  constructor(private seoService: SEOService) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  window.scrollTo(0,0);
  }

  seoUpdate() {

    this.seoService.updateTitle('Login | flyallover ');
    this.seoService.updateDescription('Login for your account in Flyallover for booking The cheapest tickets with best airlines');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

}
