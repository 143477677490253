<!--<video width="320" height="240" controls>-->
<!--  <source src="https://www.youtube.com/embed/nj90nbkcq6o" type="video/mp4">-->
<!--&lt;!&ndash;  <source src="https://www.youtube.com/watch?v=nj90nbkcq6o" type="video/ogg">&ndash;&gt;-->
<!--  Your browser does not support the video tag.-->
<!--</video>-->


<!--<video id='plyrID' controls crossorigin playsinline poster="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg">-->
<!--  <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4" type="video/mp4" size="576">-->
<!--  <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4" type="video/mp4" size="720">-->
<!--  <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4" type="video/mp4" size="1080">-->

<!--  &lt;!&ndash; Caption files &ndash;&gt;-->
<!--  <track kind="captions" label="English" srclang="en" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.en.vtt"-->
<!--         default>-->
<!--  <track kind="captions" label="Français" srclang="fr" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.fr.vtt">-->
<!--  &lt;!&ndash; Fallback for browsers that don't support the <video> element &ndash;&gt;-->
<!--  <a href="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4" download>Download</a>-->
<!--</video>-->

<div class="video w-100">
  <video class="w-100" controls (click)="toggleVideo()" #videoPlayer>
    <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
    Browser not supported
  </video>
</div>
