<!-- radio buttons -->
<mat-radio-group [(ngModel)]="tripType" *ngIf="!hideTripType">
  <mat-radio-button value="Return" checked="true">Round trip</mat-radio-button>
  <mat-radio-button value="OneWay">One Way</mat-radio-button>
  <mat-radio-button value="OpenJaw">Multi-City</mat-radio-button>
</mat-radio-group>

<!-- trip of type return -->
<div *ngIf="tripType === 'Return'" (click)="closePassengerList($event)">
  <!-- if any required field is missing -->
  <div *ngIf="submitErrorReturn" class="alert alert-danger fade show" role="alert">
    {{errorReturn}}
  </div>
  <form class="row" [formGroup]="searchForm">
    <!-- didn't put all group controls in one div because the architecture of the design -->
    <div class="col-md-12" formArrayName="flightData">
      <div formGroupName="0">
        <label>From</label>
        <input type="text" class="form-control" formControlName="origin" [ngbTypeahead]="search"
               placement="bottom-left" (click)="$event.target.select()"/>
        <label>To</label>
        <input type="text" class="form-control" formControlName="destination" [ngbTypeahead]="search"
               placement="bottom-left" (click)="$event.target.select()"/>
      </div>
    </div>
    <div class="col-md-12" formArrayName="flightData">
      <div class="row" formGroupName="0">
        <div class="col-md-12 mb-3">
          <label>Depart At</label>
          <input readonly (focus)="departure.open()" [matDatepicker]="departure"
                 [min]="initialDate" [max]="searchForm.value.flightData[0]?.return_date"
                 formControlName="departure_date" class="form-control search-results-departure-date-input"
          >
          <mat-datepicker #departure></mat-datepicker>
        </div>
        <div class="col-md-12">
          <label>Return At</label>
          <input readonly (focus)="return.open()" [matDatepicker]="return"
                 [min]="searchForm.value.flightData[0]?.departure_date || initialDate"
                 formControlName="return_date" class="form-control search-results-return-date-input"
                 [attr.disabled]="!searchForm.value.flightData[0]?.departure_date">
          <mat-datepicker #return></mat-datepicker>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-4">
      <label>Travellers Round</label>
      <app-passengers-number class="passenger-list"
                             (classType)="setClassType($event)"
                             [bindClass]="bindClass"
                             [activeTravellers] = "activeTravellers"
                             (notify)="updateFlightPassengerData()"
                             [passengerData]="passengerData">
      </app-passengers-number>
    </div>
    <div class="col-md-12 col-12 mx-md-0 mx-auto">
      <button (click)="submitRoundTrip()" class="search-btn btn">
        Search
        <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>
      </button>
      <!-- <button class="btn blue-btn advanced-search">
          more advanced search
          <i class="fas fa-chevron-right"></i>
      </button> -->
    </div>
  </form>
</div>

<!-- trip of type oneway -->
<div *ngIf="tripType === 'OneWay'" (click)="closePassengerList($event)">
  <!-- if any required field is missing -->
  <div *ngIf="submitErrorOneWay" class="alert alert-danger fade show" role="alert">
    {{errorOneWay}}
  </div>
  <form class="row" [formGroup]="searchForm">
    <div class="col-md-12" formArrayName="flightData">
      <div formGroupName="0">
        <label>From</label>
        <input type="text" class="form-control" formControlName="origin" [ngbTypeahead]="search"
               placement="bottom-left" (click)="$event.target.select()"/>
        <label>To</label>
        <input type="text" class="form-control" formControlName="destination" [ngbTypeahead]="search"
               placement="bottom-left" (click)="$event.target.select()"/>
      </div>
    </div>
    <div class="col-md-12" formArrayName="flightData">
      <div formGroupName="0">
        <label>Date</label>
        <input (focus)="departure.open()" [matDatepicker]="departure" readonly [value]="openDate" [min]="initialDate"
               formControlName="departure_date" class="form-control">
        <mat-datepicker-toggle [for]="departure">
          <mat-icon matDatepickerToggleIcon><img title="Date" alt="calendar"
                                                 src="/images/calendar-check-line.svg">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #departure></mat-datepicker>
      </div>
    </div>
    <div class="col-md-12 col-sm-4">
      <label>Travellers</label>
      <app-passengers-number class="passenger-list" (notify)="updatePassengerData()"
                             [passengerData]="passengerData">
      </app-passengers-number>
    </div>
    <div class="col-md-12 col-12 mx-md-0 mx-auto">
      <button (click)="submitOneWay()" class="search-btn btn">
        Search
      </button>
      <!-- <button class="btn blue-btn advanced-search">
          more advanced search
          <i class="fas fa-chevron-right"></i>
      </button> -->
    </div>
  </form>
</div>

<!-- trip of type multi destinations (open jaw) -->
<div *ngIf="tripType === 'OpenJaw'" class="open-jaw" (click)="closePassengerList($event)">
  <!-- if any required field is missing -->
  <div *ngIf="submitErrorOpenJaw" class="alert alert-danger fade show" role="alert">
    {{errorOpenJaw}}
  </div>
  <form class="row" [formGroup]="searchForm">
    <div class="col-12" FormArrayName="flightData">

      <div *ngFor="let flight of flightData.controls; let i = index" [formGroup]="flightData.controls[i]"
           class="row flight-container">

        <h6 class="mb-1 pl-3">Flight {{i + 1}}</h6>

        <button (click)="removeFlight(i)" class="btn remove-flight"
                [attr.disabled]="flightData.value?.length <= minFLights">
          <i class="far fa-times-circle"></i>
        </button>

        <div class="col-12">
          <label>From</label>
          <input type="text" class="form-control" formControlName="origin" [ngbTypeahead]="search"
                 placement="bottom-left" (click)="$event.target.select()"/>
        </div>
        <div class="col-12">
          <label>To</label>
          <input type="text" class="form-control" formControlName="destination" [ngbTypeahead]="search"
                 placement="bottom-left" (click)="$event.target.select()"/>
        </div>
        <div class="col-12">
          <label>Date</label>
          <input (focus)="departure.open()" readonly [matDatepicker]="departure"
                 [min]="flightData.controls[i - 1]?.value.departure_date || initialDate"
                 formControlName="departure_date" class="form-control"
                 [max]="flightData.controls[i + 1]?.value.departure_date">
          <mat-datepicker #departure></mat-datepicker>
        </div>

      </div>
    </div>
    <div class="col-12">
      <label>Travellers</label>
      <app-passengers-number class="passenger-list" (notify)="updatePassengerData()"
                             [passengerData]="passengerData">
      </app-passengers-number>
    </div>
    <div class=" col-12">
      <button (click)="addFlight()" class="w-100 btn mt-3">
        <i class="fas fa-plus"></i>
        Add Flight
      </button>
    </div>
    <div class="col-md-12 col-12 mx-md-0 mx-auto">
      <button (click)="submitOpenJaw()" class="search-btn btn">
        Search
      </button>
      <!-- <button class="btn blue-btn advanced-search">
          more advanced search
          <i class="fas fa-chevron-right"></i>
      </button> -->
    </div>
  </form>
</div>
