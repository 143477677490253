<div class="sidebar-col">
  <div class="sidebar px-2">
    <div class="top-profile">
      <h6 class="letter Font-family ">{{ firstLetter }}</h6>
      <div class="container"><img
        title="first letter"
        alt="first letter"
        class="mb-3"
        src="../../../../assets/images/dashboard/Group%2015263.svg"
      />
      <div class="name-profile centered Font-family"><span>{{ firstName|slice:0:1 }}</span></div>
    </div>

      <div class="profile-info">
        <div class="email">{{ email }}</div>
      </div>
    </div>
    <hr class="hr" />
    <div class="menu" id="menu">
      <a
        class="nav-link"
        routerLink="/profile/dashboard"
        [routerLinkActive]="['selected']"
      >
        <img
          title="dashboard"
          alt="dashboard"
          src="../../../../assets/images/dashboard/dashboard.svg"
        />
        <span class=" Font-family">Dashboard</span>
      </a>
      <a
        class="nav-link"
        [routerLinkActive]="['selected']"
        routerLink="/profile/travel-details-dashboard"
        href="#"
        data-toggle="collapse"
        data-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <img
          title="travel-details-dashboard"
          alt="travel-details-dashboard"
          src="../../../../assets/images/dashboard/bag2.svg"
        />
        <span class=" Font-family">Travelers</span>
      </a>
      <a
        class="nav-link"
        routerLink="/profile/account-info"
        [routerLinkActive]="['selected']"
      >
        <img
          alt="account-info"
          title="account-info"
          src="../../../../assets/images/dashboard/My-Account-Info.svg"
        />
        <span class=" Font-family">My Account Info</span>
      </a>
      <a
        class="nav-link"
        [routerLinkActive]="['selected']"
        routerLink="/profile/my-booking"
      >
        <img
          alt="my-booking"
          title="my-booking"
          src="../../../../assets/images/dashboard/bag.svg"
        />
        <span class=" Font-family">Orders</span>
      </a>
      <a
        routerLink="/profile/feedback-dashboard"
        [routerLinkActive]="['selected']"
        class="nav-link"
      >
        <img
          title="feedback-dashboard"
          alt="feedback-dashboard"
          src="../../../../assets/images/dashboard/Feedback.svg"
        />
        <span class=" Font-family">Give Us Your Feedback</span>
      </a>
      <a
        class="nav-link"
        [routerLinkActive]="['selected']"
        (click)="goSignOut()"
      >
        <img
          title="flyallover"
          alt="flyallover"
          src="../../../../assets/images/profile/profile-icon-sidebar-login.svg"
        />
        <span class=" Font-family">Sign out</span>
      </a>
    </div>
  </div>
</div>
