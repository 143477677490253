
<!-- stars rating -->
<div class="row mt-5">
  <div class="col-sm-12 d-flex">
    <!-- star ★ -->
    <span class="heading pt-4 Font-family">Add Review</span>
      <ul class="list-inline rating-list" *ngFor="let star of stars; let i= index" style="display: inline-block">
          <li (click)="countStar(star); gitRating()" id="{{'starId'+i}}" [ngClass]="{'selected': (star <= selectedValue)}"
           (mouseover)="addClass(star)" (mouseout)="removeClass(star)"> <i class="fa fa-star"></i>
          </li>
          <!-- {{rating}} -->
      </ul>

      <h6 class=" mt-10 px-2"> {{rating}} </h6>
  </div>
</div>
<!-- End stars rating -->


<!-- Rating Form -->
  <form [formGroup]="checkoutForm" >
    <div *ngIf="token =='undefined'">
    <div >
      <label for="name"[ngClass]="checkoutForm.get('name').invalid &&checkoutForm.get('name').touched? 'text-danger' : ''">* Name</label>

      <input type="text" formControlName="name"class="form-control" id="validationTooltip01" placeholder="Your Name" required (keyup)="spiltName()">
        <span *ngFor="let validation of validationMessages.name">
        <small class="text-danger"*ngIf="checkoutForm.get('name').hasError(validation.type) &&
                    (checkoutForm.get('name').dirty ||checkoutForm.get('name').touched) ">
            {{ validation.message }} </small>
        </span>
    </div>
        <div class="my-3">
            <label for="email"[ngClass]="checkoutForm.get('email').invalid &&checkoutForm.get('email').touched? 'text-danger' : ''">*Email Address</label>
            <input C type="email" formControlName="email" class="form-control" id="exampleFormControlInput1"placeholder="name@example.com">
            <span *ngFor="let validation of validationMessages.email">
                <small class="text-danger"*ngIf="checkoutForm.get('email').hasError(validation.type) &&
                    (checkoutForm.get('email').dirty ||checkoutForm.get('email').touched) ">
                  {{ validation.message }}</small>
              </span>
        </div>
    </div>
  <div>
    <label for="titleReview" class="form-label Font-family"> Title of your review</label>
    <input id="titleReview" type="text" formControlName="titleReview"  class="form-control" placeholder="Summarize your visit or highlight an interesting detail">
  </div>

  <div>
    <label for="review" class="form-label mt-2 Font-family"> Your review </label>
    <!-- <textarea class="form-control" id="review"  formControlName="review" rows="4"  ></textarea> -->
    <textarea autosize class="my-textarea"class="form-control" id="review"  formControlName="review" rows="3"> </textarea>

  </div>
      <div class="d-flex justify-content-center">
          <button class="fao_Show_btn_large mt-4" type="submit" [ngClass]="{'white-orange' : toggle, 'orangered': !toggle}" (click)="onSubmit()" >Submit</button>
      </div>
  </form>

<!-- End  Rating Form -->

