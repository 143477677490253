import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {AppComponent} from '../../app.component';
import {SEOService} from '../../services/seo.service';
import {OwlOptions} from "ngx-owl-carousel-o";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-airport-description',
    templateUrl: './airport-description.component.html',
    styleUrls: ['./airport-description.component.css']
})
export class AirportDescriptionComponent implements OnInit {
    [x: string]: any;
    airportCode: any;
    data: any;
    code: number;
    otherAirportsData: any = [];
    locationService: any;
    location: any;
    airport: any;
    about: any;
    countryId: any;
    airportId: number;
    images: any = [];
    relatedAirPorts: any;
    reviewData: any;
    showRev = false;
    metaTitle: any;
    metaDescription: string;
    metaImage: any;
    copied = false;
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        navSpeed: 700,
        nav: true,
        navText: [
            '<i class="fas fa-arrow-left"></i>',
            '<i class="fas fa-arrow-right"></i>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            740: {
                items: 3,
            },
            940: {
                items: 3,
            },
        },
    };

    constructor(private router: Router,
                private apis: ApisService,
                public activatedRoute: ActivatedRoute,
                public clipboard: Clipboard,
                private seoService: SEOService,) {
        if (typeof document !== 'undefined') {
            window.scrollTo(0, 0);
        }
        this.seoUpdate();
    }

    ngOnInit(): void {
        this.airportCode = this.activatedRoute.snapshot.paramMap.get('code');
        this.getAirport();
    }

    seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
        this.seoService.updateTitle(this.router.url);
        this.seoService.updateDescription(this.router.url);
        this.seoService.updateUrl();
        this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

        if (title != null) {
            this.seoService.updateTitle(title);
        }
        if (description != null) {
            this.seoService.updateDescription(description);
        }
        if (image != null) {
            this.seoService.updateImage(url + image);
        }
    }

    refresh(code) {
        this.airportCode = code;
        this.getReviews()
        this.getAirport();
        window.scrollTo(0, 0);
    }

    getrelatedAirPorts(countryId) {
        this.apis.relatedAirPorts(countryId).subscribe((data: any) => {
            this.relatedAirPorts = data.data;
        });
    }

    getAirport(): any {
        this.apis.getAirports(this.airportCode).subscribe((data: any) => {
                this.images = [];
                this.data = data.data;
                this.airport = this.data;
                this.airportId = this.data.id;
                this.countryId = this.data.country_id;
                this.metaTitle = this.data.title_tag;
                this.metaDescription = this.data.meta_description;
                this.metaImage = this.data.main_image;

                this.images.push(this.airport.main_image);
                for (let i = 0; i < this.airport.images.length; i++) {
                    this.images.push(this.airport.images[i].full_url);
                }
                this.about = this.airport.description;
                this.location = 'http://www.google.com/maps/place/' + this.airport.lat + ',' + this.airport.lon;
                this.getrelatedAirPorts(this.countryId);
                this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);
                this.getReviews();
                this.otherAirports();
                // console.log('id', this.metaImage)
                // console.log('idd', this.data)
            },
            err => {
                console.log(err);
            });
    }

    otherAirports() {
        this.apis.countryAirports(this.countryId).subscribe((response: any) => {
            this.otherAirportsData = response.data;
        }, error => {
            console.log(error);
        });
    }

    getReviews() {
        this.apis.getAirportReviews(this.airportId).subscribe((data: any) => {
                this.reviewData = data.data;
            },
            err => {
                console.log(err, 'error');
            });
    }

    setReview(event:any){
        this.apis.addAirportRviews(event).subscribe((data:any)=>{
            this.data=data.data;
            this.getReviews();
        })
    }
    showReviews() {
        this.showRev = !this.showRev;
    }

    cop() {
        this.clipboard.copy('https://flyallover.com' + this.router.url);
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)
    }

    fb() {
        let url = 'www.google.com';
        var facebookWindow = window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + 'flyallover.com' + this.router.url,
            'facebook-popup',
            'height=350,width=600',
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }
        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }
}
