import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-flight-bagging-information',
  templateUrl: './review-flight-bagging-information.component.html',
  styleUrls: ['./review-flight-bagging-information.component.css']
})
export class ReviewFlightBaggingInformationComponent implements OnInit {
  @Input() itineraries: any;
  constructor() { }

  ngOnInit(): void {

    // console.log( this.itineraries[0]?.passengerDetails[0].Tax);




  }
}
