import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, PLATFORM_ID, Inject, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
// import { OwlOptions } from 'ngx-owl-carousel-o';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { Title } from '@angular/platform-browser';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Options } from 'ng5-slider';
import { Router, ActivatedRoute, Params, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HotelSearchComponent } from 'src/app/components/hotel-search/hotel-search.component';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { metaData } from '../home-meta-tag.component';
import { AppComponent } from "../../app.component";
import { SEOService } from "../../services/seo.service";
import { HotelsEngineComponent } from 'src/app/components/home/hotels-engine/hotels-engine.component';
import { Console } from 'console';

@Component({
  selector: 'app-results',
  templateUrl: './hotels-results.component.html',
  styleUrls: ['./hotels-results.component.css'],

  styles: [`
    .size-vertical {
      height: 100vh;
    }
  `]
})


export class HotelsResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  // this component is viewed to update search fields data

  @ViewChild(HotelsEngineComponent)
  private hotelSearchComponent: HotelsEngineComponent;
  skeleton = Array(3);
  closeResult = '';
  clearFilter = false;
  searchData: any;
  rates: Array<number>;
  amenities;
  cancelPenalties;
  searchRequest: any;
  hotels: any;
  requestId: string;
  priceInfo: any[] = [];
  loading = false;
  resultsArrived = true;
  moreAmenities = false;
  moreAmenityFilters = false;
  page = 1;
  pageSize = 10;
  collectionSize;
  minValue = 0;
  maxValue = 10000;
  options: Options = {
    floor: this.minValue,
    ceil: this.maxValue,
    draggableRange: true

  };
  loadingSpinner: boolean;
  // dynamic filters coming from the server (displayed in the page)
  amenityCodes: any;
  securityFeatureCodes: any;
  propertyTypeCodes: any;

  // filter variables that are the data behind filters displayed in the page
  filters: any = {};
  ngSliderEvent$ = new Subject();

  distance = {
    less_one_km: false,
    less_three_km: false
  };

  allInclusive = false;
  RefundableOnly = false;
  hotelCityLong;
  hotelCityLat;

  isBrowser: boolean;
  enable = false;
  currentRoute: string;
  constructor(
    private seoService: SEOService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private hotelApis: HotelsApisService,
    private localStorage: LocalStorageService,
    private title: Title,
    private metaService: MetaTagsService,
    private modalService: NgbModal,
    private changeDetection: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    const hotelSearchDataStr = this.localStorage.getItem('searchRequest');
    this.searchData = hotelSearchDataStr ? JSON.parse(hotelSearchDataStr) : {};

    this.currentRoute = "";
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart && this.currentRoute != '/no-result') {
        this.currentRoute = event.url
      }
      if (event instanceof NavigationEnd && this.currentRoute != '/no-result') {
        this.currentRoute = event.url;
        const hotelSearchDataStr = this.localStorage.getItem('searchRequest');
        this.searchData = hotelSearchDataStr ? JSON.parse(hotelSearchDataStr) : {};
        this.dataHandler()
        this.hotelSearchAPI(this.searchData)
      }

      if (event instanceof NavigationError) {
      }
    })

  }


  ngOnInit(): void {
    this.seoUpdate();
    this.dataHandler();
    this.hotelSearchAPI();

  }

  ngAfterViewInit() {
    this.fillSearchFields();
    this.hotelSearchComponent.submit();
  }

  ngOnDestroy() {
    this.ngSliderEvent$.unsubscribe();
  }


  dataHandler(): void {
    this.searchData = this.route.snapshot.queryParams;
    if (!this.searchData) {
      this.router.navigate(['/']);
    } else {
      this.hotelCityLat = this.searchData?.latitude;
      this.hotelCityLong = this.searchData?.longitude;
      this.localStorage.setItem('hotelSearchData', JSON.stringify(this.searchData));
    }
  }

  closeing(popularFilters) {
    this.modalService.open(popularFilters, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  search_modal() {
    document.getElementsByClassName('modify')[0].classList.toggle('d-block');
  }

  distanceFilter(distance) {
    this.modalService.open(distance, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  budgetFilter(budget) {
    this.modalService.open(budget, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  securityFilter(securityfeatures) {
    this.modalService.open(securityfeatures, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  PropertyFilter(Property) {
    this.modalService.open(Property, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  FacilitiesFilter(Facilities) {
    this.modalService.open(Facilities, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  seoUpdate() {

    this.seoService.updateTitle('Hotels Search | FlyAllOver');
    this.seoService.updateDescription('Hotels Search | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }


  subscribeToURL() {
    // subscribing to the query parameters, so that when it changes we do things based on it.
    this.route.queryParams.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(params => {

      // add the value in URL to filter variables (to view filters correctly)
      // if a filter is not in the Url assign it a default value (value after ||)

      if (params['budget_min']) {
        this.minValue = params['budget_min'];
        this.maxValue = params['budget_max'];
      }

      this.distance.less_one_km = params['less_one_km'] == 1;

      this.distance.less_three_km = params['less_three_km'] == 1;

      this.RefundableOnly = params['RefundableOnly'] == 1;

      this.allInclusive = params['allInclusive'] == 1;

      // extracting the property filters from the URL
      let propertiesArray;
      if (params['PropertyTypeCode']) {
        // array of property code [3,20] after parsing the json string
        propertiesArray = JSON.parse(params['PropertyTypeCode'])
        this.checkingArrayCheckboxes(this.propertyTypeCodes, propertiesArray);
      }
      // extracting the security features filters from the URL
      let featuresArray;
      if (params['SecurityFeatureCode']) {
        featuresArray = JSON.parse(params['SecurityFeatureCode']);
        this.checkingArrayCheckboxes(this.securityFeatureCodes, featuresArray);
      }
      // extracting the amenity filters from the URL
      let amenitiesArray;
      if (params['AmenityCode']) {
        amenitiesArray = JSON.parse(params['AmenityCode']);
        this.checkingArrayCheckboxes(this.amenityCodes, amenitiesArray);
      }

      // put whatever in params inside this.filters
      Object.keys(params).forEach(key => {
        this.filters[key] = params[key];
      });

      // if there is any filters inside this.filters call filterResults() that will call for a new filtered search
      if (Object.keys(this.filters).length > 0) {
        this.filterResults();
      }
    });
  }

  // this method takes 2 arrays, one is the array of all filterCodes (e.g: this.propertyTypeCodes)
  // and the other an array of codes corresponding to the first array in the url (the checked this.propertyTypeCodes)
  checkingArrayCheckboxes(filterArray, urlCodesArray) {

    urlCodesArray.forEach(el => {
      for (let i = 0; i < filterArray.length; i++) {
        if (filterArray[i].code === el) {
          filterArray[i].checked = true;
          break;
        }
      }
    });
  }

  // this method gets searchResults as an argument and assign parts of it to variables
  assignDataToVars(searchResults) {
    this.hotels = searchResults.hotels || searchResults.details;
    if (this.hotels.length === 0) {
      this.router.navigate(['/no-result']);
      localStorage.removeItem('hotelSearchData')
    }
    this.requestId = searchResults.RequestID;

    /* asign min and max price */
    if (this.clearFilter) {
      this.minValue = searchResults.min_price;
      this.maxValue = searchResults.max_price;
      this.options = {
        floor: parseInt(searchResults.min_price.toString()),
        ceil: parseInt(searchResults.max_price.toString()),
        draggableRange: true
      };

      this.clearFilter = false;
    } else {
      if (this.minValue == 0) {
        this.minValue = searchResults.min_price;
        this.maxValue = searchResults.max_price;

        this.options = {
          floor: parseInt(this.minValue.toString()),
          ceil: parseInt(this.maxValue.toString()),
          draggableRange: true
        };
      } else {
        this.options = {
          floor: parseInt(searchResults.min_price.toString()),
          ceil: parseInt(searchResults.max_price.toString()),
          draggableRange: true
        };
      }
    }


    this.collectionSize = searchResults.pages.total_item;
    this.page = (searchResults.pages.offset + 9) / 10; // time-slider
    this.hotels.forEach(hotel => {
      if (!hotel.basis_info.HotelImageInfo) {
        hotel.basis_info['HotelImageInfo'] = {
          Url: "../../../../../assets/images/hotel-img.png",
        };
      }
    });
    this.buildArrays();
    /* Enable */

  }

  // for every hotel in hotels array push the rate of such hotel into rates array (used to add certain class in template html file)
  buildArrays() {
    this.rates = [];
    this.amenities = [];
    this.cancelPenalties = [];
    this.priceInfo = [];
    this.hotels.forEach((hotel, i) => {
      // rates array
      this.rates.push(parseInt(hotel.basis_info.Rate))
      if (hotel.basis_info.HotelPriceInfo.ConvertedRateInfo && hotel.basis_info.HotelPriceInfo.ConvertedRateInfo.length > 0) {
        this.priceInfo.push(hotel.basis_info.HotelPriceInfo.ConvertedRateInfo)
      } else {
        this.priceInfo.push(hotel.basis_info.HotelPriceInfo.RateInfo);
      }
      this.amenities[i] = [];
      // for amenities array (only add amenities with amenity.value)
      hotel.basis_info.Amenities.Amenity.forEach(amenity => {
        if (amenity.value) {
          this.amenities[i].push(amenity.Description)
        }
      });
      this.cancelPenalties.push(hotel.basis_info.RoomsInfo[0].RatePlans.RatePlan.CancelPenalties.CancelPenalty[0])
    });


  }

  // this method is used to persist data in the search fields
  // data might be the one the user added in home page or what user added to do anther search in hotel-results page
  fillSearchFields() {

    // this.hotelSearchComponent.submit();

    this.changeDetection.detectChanges();
  }

  addFilter(filterType: string, event?) {

    switch (true) {
      case filterType === 'budget':
        this.filters['budget_min'] = parseInt(this.minValue.toString());
        this.filters['budget_max'] = parseInt(this.maxValue.toString());
        this.router.navigate(['/hotel-results'], { queryParams: this.filters })
        break;
      case filterType === 'distance':
        Object.keys(this.distance).forEach(key => {
          // if the value of a key is true add if to filters object
          if (this.distance[key]) {
            this.filters[key] = 1;
          } else {
            this.filters[key] = 0;
          }
        })
        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters })
        break;
      case filterType === 'refundable':
        if (event && event.currentTarget.checked) {
          this.filters['RefundableOnly'] = 1;
          this.RefundableOnly = true;
        } else {
          this.filters['RefundableOnly'] = 0;
          this.RefundableOnly = false;
        }
        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters })
        break;
      case filterType === 'inclusive':
        if (event && event.currentTarget.checked) {
          this.filters['allInclusive'] = 1;
          this.allInclusive = true;
        } else {
          this.filters['allInclusive'] = 0;
          this.allInclusive = false;
        }

        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters })
        break;
      case filterType === 'property':
        // if the property of index i is true add it to the filters object then navigate with query parameters
        let propertiesArray = [];
        this.propertyTypeCodes.forEach((el, i) => {
          if (el.checked) {
            propertiesArray.push(+this.propertyTypeCodes[i].code)
          }
        })
        // to be able to add an array in the URl we sholud stringify it first
        /* Check if no property option added we did not send the paramter  */
        if (propertiesArray.length > 0) {

        }
        this.filters['PropertyTypeCode'] = JSON.stringify(propertiesArray);
        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters });
        break;
      case filterType === 'feature':
        // if the feature of index i is true add it to featuresArray then stringify the array and put it in
        //  filters object then navigate with query parameters
        let featuresArray = [];
        this.securityFeatureCodes.forEach((el, i) => {
          if (el.checked) {
            featuresArray.push(+this.securityFeatureCodes[i].code)
          }
        })

        this.filters['SecurityFeatureCode'] = JSON.stringify(featuresArray);
        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters });
        break;
      case filterType === 'amenity':
        let amenitiesArray = [];
        this.amenityCodes.forEach(amenity => {
          if (amenity.checked) {
            amenitiesArray.push(+amenity.code);
          }
        });
        this.filters['AmenityCode'] = JSON.stringify(amenitiesArray);
        // maintain filters in the URL
        this.router.navigate(['/hotel-results'], { queryParams: this.filters });
        break;
    }

  }

  clearFilters() {
    this.minValue = 0;
    Object.keys(this.filters).forEach(key => {
      delete this.searchData[key];
    })
    this.filters = {};
    this.clearFilter = true;
    this.router.navigate(['/hotel-results'], { queryParams: null });
    this.hotelSearchAPI(this.searchData);
  }

  filterResults() {
    const filterSearchData = this.searchData;
    Object.keys(this.filters).forEach(key => {
      filterSearchData[key] = this.filters[key];
    });
    this.hotelSearchAPI(filterSearchData);
  }

  getNewPage() {
    this.loading = true;
    this.resultsArrived = false;
    this.hotelApis.newResultPage(this.requestId, this.page).subscribe((data: any) => {
      this.loading = false;
      this.resultsArrived = true;
      this.assignDataToVars(data.data);
    }, err => {
      this.loading = false;
      this.resultsArrived = false;
      // TODO find a good scenario
    });
  }

  hotelSearchAPI(searchData = this.searchData) {
    this.resultsArrived = false;
    this.loading = true;
    this.loadingSpinner = true;
    // this.disableSearch();
    this.searchData = searchData;
    this.resultsArrived = false;

    console.log("start ----------------");
    console.log(searchData);
    console.log("end ----------------");

    this.hotelApis.searchHotels(searchData).subscribe((data: any) => {
      this.resultsArrived = true;
      this.loading = false;
      this.loadingSpinner = false;
      this.localStorage.setItem('hotelSearchData', JSON.stringify(this.searchData));
      // searchData.SearchFromComponent is true when search is coming from hotelSearchComponent
      if (this.searchData.SearchFromComponent) {
        delete this.searchData.SearchFromComponent;
        // to update hotelSearchData in local storage with the new searchData
        this.minValue = 0;
      }
      this.assignDataToVars(data.data);
      // this.enableSearch();
      // console.log( data.data);

    }, err => {
      if (this.isBrowser) {
        this.resultsArrived = false;
        this.loading = false;
        this.router.navigate(['/no-result']);
      }
    });
  }

  toggleAmenitiesView(event) {
    event.target.closest('.parking').querySelector('.more-btn').classList.toggle('d-none');
    event.target.closest('.parking').querySelector('.less-btn').classList.toggle('d-none');
    event.target.closest('.parking').querySelector('.more-amenities').classList.toggle('d-none');
  }

  toggleTextView(event) {
    event.target.closest('.address').querySelector('.text-hidden').classList.toggle('d-none')
    event.target.closest('.address').querySelector('.more-btn').classList.toggle('d-none')
    event.target.closest('.address').querySelector('.less-btn').classList.toggle('d-none')
  }

  // disableSearch() {
  //   this.enable = true;
  // }

  // enableSearch() {
  //   this.enable = false;
  // }

}
