<app-navbar></app-navbar>

<div class="page404">
  <div class="container text-center">
    <h1 class="ooops">Ooops !</h1>
    <h4 class="tip">Looks like there is no results for what you are searching for</h4>
    <img title="Ooops" alt="image404"  class="image404" src="./assets/images/404.svg" />
  </div>
</div>

<app-footer></app-footer>
