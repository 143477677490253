import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApisService } from '../../../services/apis.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { log } from 'console';


@Component({
  selector: 'app-travel-card-popup',
  templateUrl: './travel-card-popup.component.html',
  styleUrls: ['./travel-card-popup.component.css']
})
export class TravelCardPopupComponent implements OnInit {
  countries
  @Output() reloadFamilyMember = new EventEmitter();
  set_birth_date
  set_expiry_date
  set_issue_date
  token = localStorage.getItem("flyallover_token")
  getFamliyMember: FormGroup;
  setFamliyMember: any = {
    familyMemberId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    birthOfMonth: '',
    birthOfDay: '',
    birthOfYear: '',
    gender: '',
    passport_number: '',
    expiryOfMonth: '',
    expiryOfDay: '',
    expiryOfYear: '',
    issueOfMonth: '',
    issueOfDay: '',
    issueOfYear: '',
    issuedCountry: '',
    issue_date: '',
    expiry_date: '',
    nationality_id: '',
    nationality_code: '',
    country_code: '',
    countryPassport: '',
    nationality: '',
  }
  months: any = [
    { text: 'January', num: '01' },
    { text: 'February', num: '02' },
    { text: 'March', num: '03' },
    { text: 'April', num: '04' },
    { text: 'May', num: '05' },
    { text: 'June', num: '06' },
    { text: 'July', num: '07' },
    { text: 'August', num: '08' },
    { text: 'September', num: '09' },
    { text: 'October', num: '10' },
    { text: 'November', num: '11' },
    { text: 'December', num: '12' }
  ];
  constructor(private apis: ApisService, private MatDialogRef: MatDialogRef<TravelCardPopupComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.countries = this.data.countries
    this.getFamliyMember = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      gender: [''],
      birthData: this.formBuilder.group({
        birthOfYear: [''],
        birthOfMonth: [''],
        birthOfDay: [''],
      }),
      passport_number: [''],
      nationality_id: [''],
      nationality_code: [''],
      country_code: [''],
      countryPassport: [''],
      nationality: [''],
      issuedCountry: [''],
      traveller_gender: [''],
      phone_type: [''],
      PassengerType: [''],
      expiry_date: this.formBuilder.group({
        expiryOfYear: [''],
        expiryOfMonth: [''],
        expiryOfDay: [''],
      }),
      issue_date: this.formBuilder.group({
        issueOfYear: [''],
        issueOfMonth: [''],
        issueOfDay: [''],
      }),
      set_birth_date: [''],
      set_expiry_date: [''],
      set_issue_date: [''],
    });
  }

  getCountryObject(country): any {
    const index = this.countries.findIndex((obj) => obj.id === country);
    console.log('countries', this.getFamliyMember.value);

    return this.countries[index];

  }
  initRequest(): any {
    const formData: FormData = new FormData();
    formData.append("first_name", this.getFamliyMember.value.firstName);
    formData.append("middle_name", this.getFamliyMember.value.middleName);
    formData.append("last_name", this.getFamliyMember.value.lastName);
    formData.append("gender", this.getFamliyMember.value.gender);
    formData.append("date_of_birth", this.set_birth_date);
    formData.append("passport_number", this.getFamliyMember.value.passport_number);
    formData.append("expiry_date", this.set_expiry_date);
    formData.append("issue_date", this.set_issue_date);
    formData.append("issuedCountry", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.code);
    formData.append("nationality_id", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.id);
    formData.append("country_id", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.id);
    formData.append("nationality_code", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.code);
    formData.append("country_code", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.code);
    formData.append("nationality", this.getCountryObject(this.getFamliyMember.value.issuedCountry)?.code);
    return formData;
  }
  convertDate(d) {
    const parts = d.split(" ");
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
  }
  checkDate(formGroupName) {
    if (formGroupName === undefined) {
      return
    }
    switch (formGroupName) {
      case "travellerDob": {
        const formRes = this.getFamliyMember.value.birthData;
        // const { formRes.birthOfYear, formRes.birthOfMonth, formRes.birthOfDay } = formGroupName;
        if (formRes.birthOfYear && formRes.birthOfMonth && formRes.birthOfDay) {
          this.set_birth_date = `${formRes.birthOfYear}-${formRes.birthOfMonth}-${formRes.birthOfDay.toString().padStart(2, '0')}`
        }

        break;
      }
      case "expireDate": {
        const formRes = this.getFamliyMember.value.expiry_date;
        if (formRes.expiryOfYear && formRes.expiryOfMonth && formRes.expiryOfDay) {
          this.set_expiry_date = `${formRes.expiryOfYear}-${formRes.expiryOfMonth}-${formRes.expiryOfDay.toString().padStart(2, '0')}`
        }
        break;
      }
      case "issueDate": {
        const formRes = this.getFamliyMember.value.issue_date;
        if (formRes.issueOfYear && formRes.issueOfMonth && formRes.issueOfDay) {
          this.set_issue_date = `${formRes.issueOfYear}-${formRes.issueOfMonth}-${formRes.issueOfDay.toString().padStart(2, '0')}`;
        }
        break;
      }
      default: {
        break;
      }
    }


  }


  close() {

  }

  addTraveler() {
    console.log('this.getFamliyMember', this.getFamliyMember)
    this.apis.addNewMember(this.token, this.initRequest()).subscribe(
      (data: any) => {
        this.MatDialogRef.close()
      },
      (err) => {

      }
    );
  }
}
