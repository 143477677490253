
<section id="testimonials" class="testimonials section-bg">
  <div class="container  p-3 mt-2">

    <div class="section-title title-rev">
      <h1 >{{data.title}}</h1>
    </div>

    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
      <section class="swiper-wrapper">

        <div class="swiper-slide">
          <div class="testimonial-item" data-aos="fade-up">
            <h2 class=" text center">
              <!-- <img src="../../../assets/images/icon/left-quotes-sign.png"  class="mb-3 handel-quot "> -->
              <app-read-more
                      [text]="data.review"
                      maxLength="4"
                      class="aboutText"
              ></app-read-more>
              <!-- <img src="../../../assets/images/icon/right-quotes-symbol.png"  class="mt-3 handel-quot "> -->
            </h2>
            <img *ngIf="image" src="{{image}}"  class=" testimonial-img">
            <img *ngIf="!image" src="../../../assets/newIcon/user-profile.svg"  class=" testimonial-img">
            <!-- <h4 class="review revUser"> {{item.user.first_name}} {{item.user.last_name}}</h4> -->
            <!-- <h4>Ceo &amp; Founder</h4> -->
            <div>
            <h3 *ngIf="data.user" class="review "> {{data.user.first_name}} {{data.user.last_name}}</h3>
            <h3 *ngIf="!data.user" class="review ">user {{data.id}}</h3></div>
            <div class="handel-comment">
              <ng-template #t let-fill="fill">
                              <span class="star" [class.full]="fill === 100">
                                <span class="half" [style.width.%]="fill"><i class="fa fa-star"></i></span><i class="fa fa-star"></i>
                              </span>
              </ng-template>
              <ngb-rating [(rate)]="data.rate" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            </div>
          </div>
        </div><!-- End testimonial item -->
      </section>
    </div>
  </div>
</section>
