<div class="">
  <div class="gdlr-core-price-table-item  ">
    <div class=" ">
      <div class="gdlr-core-price-table">
        <div class="gdlr-core-price-table-head text-center">
          <h3 class="gdlr-core-price-table-title">choice Your Room </h3>
          <div class="gdlr-core-price-table-caption">{{changeRoomName()[0]}}</div>
        </div>
        <div class="gdlr-core-price-table-price gdlr-core-title-font">
          <div class="gdlr-core-price-table-price-info">
            <div class=""> <span class="gdlr-core-price-prefix">$</span><span
                class="gdlr-core-price-table-price-number">{{data.RateInfo[0].AmountAfterTax}}</span></div>
            <div (click)="viewPriceDetails()" class="info-icon">i</div>
          </div>
          <div class="total-price-details" *ngIf="viewDetails">
            <ul>
              <li><span class="title-price-details">AverageNightlyRate</span><span
                  class="num-price-details">{{data.RateInfo[0].AverageNightlyRate |currency :
                  data.RateInfo[0].CurrencyCode }}</span></li>
              <li><span class="title-price-details">AmountBeforeTax</span><span
                  class="num-price-details">{{data.RateInfo[0].AmountBeforeTax |currency : data.RateInfo[0].CurrencyCode
                  }}</span></li>
              <li><span class="title-price-details">AmountAfterTax</span><span
                  class="num-price-details">{{data.RateInfo[0].AmountAfterTax |currency : data.RateInfo[0].CurrencyCode
                  }}</span></li>
            </ul>
          </div>
         
          <ng-container   >
            <!-- *ngFor="let item of Info ;  let i = index" -->
          <div class="boxPrice">
            <div class="row px-2 pt-2" *ngFor="let Infoooo of info ;  let i = index">
              <div class="col-6">
              <p style="font-size: 17px;font-weight: 700;">{{ Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[i].FeeDescription_Text}}</p>
              </div>
              <div class="col-6">
                <p>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[i].Amount |currency }} </p>
              </div>
            </div>
            <hr>
            <div class="row p-2" *ngFor="let Infoooo of info ;  let i = index">
              <div class="col-6">
                <p style="font-size: 17px;font-weight: 700;"> total </p>
            </div>
            <div class="col-6">
              <p>{{Infoooo.FeesRateInfoRoom[i].Amount |currency }}</p>
            </div>
            </div>
          </div>
          
          <table class="table mt-4" >
            <thead>
              <tr>
                
                <th scope="col">Service charge</th>
                <th scope="col">VAT</th>
                <th scope="col">City tax</th>
                <th scope="col">Total</th>
                  <!--<th scope="col">VAT</th>
                <th scope="col">City tax</th> -->
              </tr>
            </thead>
            <tbody *ngFor="let Infoooo of info ;  let i = index">
              <!-- *ngFor="let item of Infoooo.FeesRateInfoRoom[i]" -->
              <tr>
                <td>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[0].Amount |currency }} </td>
                <td>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[1].Amount |currency }}</td>
                <td>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[2].Amount |currency }}</td>
                <td>{{Infoooo.FeesRateInfoRoom[i].Amount |currency }}</td>
                <!--<td>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[4].Amount}}</td>
                <td>{{Infoooo.FeesRateInfoRoom[i].FeeGroupsRateInfoRoom[5].Amount}}</td> -->

                <!-- <td>>{{item.Amount}}</td>
                <td>>{{item.Amount}}</td> -->
              </tr>
            </tbody>
          </table>
        </ng-container>
          <!-- <span class="gdlr-core-price-suffix">/Total</span> -->
        </div>
        <div class="gdlr-core-price-table-content-wrap gdlr-core-center-align">
          <div class="gdlr-core-price-table-content">
            <div class="gdlr-core-price-list-shortcode gdlr-core-with-divider">
              <ul>
                <ng-container *ngFor="let item of changeRoomName() ;let i =index">
                  <li *ngIf="i>0"><i class="fa fa-check"></i>{{item}}</li>
                </ng-container>
                <li *ngIf="data.MealsIncluded[0].Breakfast>0"><i class="fa fa-check"></i>Breakfast</li>
                <li *ngIf="data.MealsIncluded[0].Lunch>0"><i class="fa fa-check"></i>Lunch</li>
                <li *ngIf="data.MealsIncluded[0].Dinner>0"><i class="fa fa-check"></i>Dinner</li>
                <!-- <li *ngIf="data.MealsIncluded[0].Dinner>0"><i class="fa fa-check"></i>{{item}}</li> -->
                <!-- <li><i class="fa fa-times"></i>List item here</li> -->
                <li *ngIf="+data?.AvailableQuantity<=5" class="text-danger">Only {{data.AvailableQuantity}} Rooms left
                  on our site</li>
                <li *ngIf="+data?.AvailableQuantity>5" class="text-success">{{data.AvailableQuantity}} Rooms available
                  on our site </li>
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                  style="padding: 15px; font-size: 20px; font-weight: 600; letter-spacing: 0px; text-transform: none;">
                  Cancelation Policy<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h3>
                  <h3  class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                  style="color: green; padding: 15px; font-size: 15px; font-weight: 500; letter-spacing: 0px; text-transform: none;" *ngIf="data.RateInfo[0].CancelPenalties[0].Refundable == 1" class="text-sucess">Refundable</h3>
                  <h3  class="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                  style="color: red; padding: 15px; font-size: 15px; font-weight: 500; letter-spacing: 0px; text-transform: none;" *ngIf="data.RateInfo[0].CancelPenalties[0].Refundable == 0" class="text-sucess">None Refundable</h3>
                  <li class="text-danger">{{data.RateInfo[0].CancelPenalties[0].PenaltyDescription_Text}} </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
