import { Component, Inject, Input, OnInit } from "@angular/core";
@Component({
  selector: "app-order-pnr",
  templateUrl: "./order-pnr.component.html",
  styleUrls: ["./order-pnr.component.css"],
})
export class OrderPnrDashboardComponent implements OnInit {
  @Input() item: any;

  constructor(  ) {

  }

  ngOnInit(): void {

  }

  handelName(name) {
    var nameUpdata= name != null ? name : ` `;
    return nameUpdata;
  }

}
