<div class="login-page">
  <app-navbar></app-navbar>

  <!-- start header-vertical -->
  <div class="header-vertical float-right">
    <img
      title="login_page"
      alt="bg_login_page"
      src="./assets/images/bg_login_page.jpg"
    />
  </div>
  <!-- end header-vertical -->

  <!-- start header-vertical -->
  <div class="login-content">
    <div class="login-forms">
      <div class="container">
        <div class="tabs-windows">
          <div class="tabs">
            <div
              class="tab tab1"
              [ngClass]="{ active: selectedItem == 'create-account' }"
              (click)="listClick($event, 'create-account')"
            >
              Sign in
            </div>
            <div
              class="tab tab2"
              [ngClass]="{ active: selectedItem == 'sign-in' }"
              (click)="listClick($event, 'sign-in')"
            >
              Sign in
            </div>
          </div>
          <div class="windows">
            <div class="window" *ngIf="selectedItem == 'create-account'">
              <app-create-account></app-create-account>
            </div>
            <div class="window" *ngIf="selectedItem == 'sign-in'">
              <app-sign-in></app-sign-in>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="copyright">
      <div>
        <div class="links float-right">
          <a href="https://flyallover.com/support/terms-conditions/"
            >Terms & Conditions</a
          >
          <a href="https://flyallover.com/support/privacy-policy/">PRIVACY</a>
          <a href="https://flyallover.com/support/flight-component-policies/"
            >Flight Component Policies</a
          >
        </div>
        <div class="sentence float-left">
          <h1 class="rightsReserved">
            &copy; Copyright {{ currentYear }} Flyallover - All Rights Reserved
          </h1>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <!-- end header-vertical -->
</div>
