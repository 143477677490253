<header class="header-home">
    <app-navbar></app-navbar>

    <div class="img-container">
        <div class="chick">
            <app-visa-engine></app-visa-engine>
            <div class="bgTitle">
                <h1>International Travel During <br> COVID-19 Is Complicated</h1>
                <div class="bg1 d-flex justify-content-between align-items-center">
                    <h4 class="m-0">We Make It Simple</h4>
                    <!--<div class="position-relative">
                        <div style="cursor:pointer"><a style="width: 10%;" (mouseover)="shareBox.classList.add('show')" (mouseout)="shareBox.classList.remove('show')"><img style="width: 26px" src="../../../assets/newIcon/share-icon.png"
                                                         alt=""></a></div>
                        <div #shareBox (mouseover)="shareBox.classList.add('show')" (mouseout)="shareBox.classList.remove('show')" class="box-share">
                            <div class=" social-images">
                                &lt;!&ndash;<a class="image-item link-sharing" (click)="close_status = false">
                                    <img title="flyallover" alt="flyallover" src="./assets/images/things-todo/share.svg"/>
                                </a>&ndash;&gt;
                                <a class="image-item"  (click)="cop()"
                                   target="_blank">
                                    <img  *ngIf="!copied" title="flyallover" class="copy" alt="flyallover" src="./assets/newIcon/copy-blue-icon.svg"/>
                                    <img  *ngIf="copied" title="flyallover" class="copy" alt="flyallover" src="./assets/newIcon/copied.png"/>
                                </a>
                                <a class="image-item"  (click)="fb()"
                                   target="_blank">
                                    <img title="flyallover" alt="flyallover"
                                         src="./assets/images/things-todo/facebook.png"/>
                                </a>
                                <a class="image-item"  (click)="tw()"
                                   target="_blank">
                                    <img title="flyallover" alt="flyallover" src="./assets/images/things-todo/twitter.png"/>
                                </a>
                                <a class="image-item"
                                   rel="nofollow" (click)="in()"
                                   target="_blank">
                                    <img title="flyallover" alt="flyallover"
                                         src="./assets/images/things-todo/linkedin.png"/>
                                </a>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
        <div class="share">
            <section>

                <div class="sticky-content">
                    <div class="social-icons social-icons-colored social-icons-vertical">
                        <div class="icon-share">
                            <span class="social-label" style="margin-left: -8.8rem">SHARE:</span>
                            <a class="social-icon image-item" (click)="cop()"
                               target="_blank">
                                <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                                     src="./assets/newIcon/copy-blue-icon.svg"/>
                                <img *ngIf="copied" title="copied" class="copied" alt="copied"
                                     src="./assets/newIcon/copied.png"/>
                            </a>
                            <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                                <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                                <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                                <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                                <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                                <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</header>
<app-visa-features></app-visa-features>
<app-visa-faqs></app-visa-faqs>
<app-footer></app-footer>

