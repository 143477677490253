import {Component} from '@angular/core';

@Component({
  selector: 'app-top-deals',
  templateUrl: './top-deals.component.html',
  styleUrls: ['./top-deals.component.css']
})

export class TopDealsComponent {
  countries: any = [
    {
      banner: '/assets/images/top-deals/1.jpg',
      flights: [
        {
          dest: 'CAI - JFK',
          date: '28 jul - 27 Aug',
          price: '$1605',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'HOU - MAD',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - AMS',
          date: '28 Jul - 27 Aug',
          price: '$1797',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - MIL',
          date: '28 Jul - 27 Aug',
          price: '$1723',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - RIO',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
      ],
    },
    {
      banner: '/assets/images/top-deals/2.jpg',
      flights: [
        {
          dest: 'CAI - JFK',
          date: '28 jul - 27 Aug',
          price: '$1605',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'HOU - MAD',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - AMS',
          date: '28 Jul - 27 Aug',
          price: '$1797',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - MIL',
          date: '28 Jul - 27 Aug',
          price: '$1723',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - RIO',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
      ],
    },
    {
      banner: '/assets/images/top-deals/3.jpg',
      flights: [
        {
          dest: 'CAI - JFK',
          date: '28 jul - 27 Aug',
          price: '$1605',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'HOU - MAD',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - AMS',
          date: '28 Jul - 27 Aug',
          price: '$1797',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - MIL',
          date: '28 Jul - 27 Aug',
          price: '$1723',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
        {
          dest: 'NYC - RIO',
          date: '28 Jul - 27 Aug',
          price: '$1694',
          sub: '.5',
          img: 'https://www.farebuzz.com/i/ai/AM.gif',
          banner: '/assets/images/home/eg.jpg',
        },
      ],
    },
  ];
}

