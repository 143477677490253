<!-- header -->
<header class="header-home text-center pb-3">
  <app-navbar></app-navbar>
  <div class="img-container">
    <div class="cover"></div>
    <div class="img-fluid home-img d-lg-block d-none w-100">
      <div
        class="carousel slide carousel-fade"
        data-ride="carousel"
        data-interval="3000"
      >
        <!-- Indicators -->
        <ul class="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" class="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
        </ul>
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              title="slider"
              src="../../../assets/images/home_page.png"
              alt="slider"
            />
            <div class="carousel-caption d-none d-md-block">
              <h1>Cheap flights, hotels and tours...</h1>
              <p>Book now and FlyAllOver !</p>
            </div>
          </div>
          <div class="carousel-item">
            <img
              title="home page"
              src="../../../assets/images/home_page.png"
              alt="slider"
            />
            <div class="carousel-caption d-none d-md-block">
              <h2>Cheap flights, hotels and tours...</h2>
              <p>Book now and FlyAllOver !</p>
            </div>
          </div>
          <div class="carousel-item">
            <img
              title="Cheap flights, hotels and tours"
              src="../../../assets/images/home_page.png"
              alt="slider"
            />
            <div class="carousel-caption d-none d-md-block">
              <h2>Cheap flights, hotels and tours...</h2>
              <p>Book now and FlyAllOver !</p>
            </div>
          </div>
          <div class="carousel-item">
            <img
              title="Cheap flights, hotels and tours"
              src="../../../assets/images/home_page.png"
              alt="slider"
            />
            <div class="carousel-caption d-none d-md-block">
              <h2>Cheap flights, hotels and tours...</h2>
              <p>Book now and FlyAllOver !</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay">
    <div class="search-container row">
      <app-engine></app-engine>
    </div>
  </div>
</header>
<div
  class="fix-height"
  style="width: 100%; height: 200px; background: #f9f9f9"
></div>
<section class="flight-page">
  <div class="container">
    <div class="row">
      <div class="col-4">
        <div class="item">
          <div class="icon_image">
            <img
              src="../../../assets/images/flights/speed.svg"
              alt="flight"
              title="flight"
            />
          </div>
          <div class="content_text">
            <h3>Easy&Fast Booking</h3>
            <p>Search, compare and book your tickets at the lowest fares.</p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="item">
          <div class="icon_image">
            <img
              title="Easy&Fast Booking"
              alt="Easy&Fast Booking"
              src="../../../assets/images/flights/credit-card.svg"
            />
          </div>
          <div class="content_text">
            <h3>Easy&Fast Booking</h3>
            <p>Search, compare and book your tickets at the lowest fares.</p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="item">
          <div class="icon_image">
            <img
              title="Easy&Fast Booking"
              alt="Easy&Fast Booking"
              src="../../../assets/images/flights/customer-service.svg"
            />
          </div>
          <div class="content_text">
            <h3>Easy&Fast Booking</h3>
            <p>Search, compare and book your tickets at the lowest fares.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <h2 class="header col-12">Top Travelling Destinations</h2>
      <div class="col4">
        <div class="item_card">
          <img
            title="Cairo"
            alt="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
      <div class="col4">
        <div class="item_card">
          <img
            title="Cairo"
            alt="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
      <div class="col4">
        <div class="item_card">
          <img
            alt="Cairo"
            title="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
      <div class="col4">
        <div class="item_card">
          <img
            alt="Cairo"
            title="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
      <div class="col4">
        <div class="item_card">
          <img
            alt="cairo"
            title="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
      <div class="col4">
        <div class="item_card">
          <img
            alt="Cairo"
            title="Cairo"
            src="../../../assets/images/flights/pyramids.png"
          />
          <h5>Cairo</h5>
        </div>
      </div>
    </div>
    <div class="blog also-read">
      <div class="container">
        <h2 class="header col-12">
          Cheap Flights to Popular Destinations during this month
        </h2>
        <owl-carousel-o [options]="carouselOptions">
          <ng-container>
            <ng-template carouselSlide>
              <div class="item_card">
                <img
                  title="Cairo"
                  alt="Cairo"
                  src="../../../assets/images/flights/Rectangle.png"
                />
                <h5>Cairo</h5>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item_card">
                <img
                  alt="Cairo"
                  title="Cairo"
                  src="../../../assets/images/flights/Rectangle.png"
                />
                <h5>Cairo</h5>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item_card">
                <img
                  alt="Cairo"
                  title="Cairo"
                  src="../../../assets/images/flights/Rectangle.png"
                />
                <h5>Cairo</h5>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div class="row">
      <h2 class="header col-12">Top Flight Destinations</h2>
      <div class="col-4">
        <div class="flight_dest">
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="flight_dest">
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="flight_dest">
          <img
            title="Cairo Flights"
            alt="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="flight_dest">
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="flight_dest">
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="flight_dest">
          <img
            title="Cairo Flights"
            alt="Cairo Flights"
            src="../../../assets/images/flights/flight.svg"
          />
          <span class="flights_name">Cairo Flights</span>
          <img
            alt="Cairo Flights"
            title="Cairo Flights"
            src="../../../assets/images/flights/arrow-down.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- this component contains offers, city guide and email subscription view -->
<app-index></app-index>

<!-- footer -->
<app-footer></app-footer>
