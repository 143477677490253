<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 col-xxl-3 col-lg-5">
      <app-dash-sidebar></app-dash-sidebar>
    </div>
    <div class="col-xl-8 col-xxl-9 col-lg-7">
      <div class="header-image">
        <h1 class="text-center Font-Family">Welcome Back {{firstName}} {{lastName}}</h1>
      </div>
      <div class="account-form">
        <h4 class="h4 Font-Family" >My Account Information</h4>
        <div class="form-details">
          <ngx-skeleton-loader *ngIf="loading" count="1" [theme]="{width: '100%',height: '300px', 'margin': '0px'}">
          </ngx-skeleton-loader>
          <h3 *ngIf="loginSpinner" class="alert alert-success alert-dismissible Font-Family" role="alert">
            Your account information has been successfully updated.
          </h3>
          <form *ngIf="!loading" [formGroup]="accountForm" (submit)="submit()" class="row">
            <div class="col-3 position-relative">
              <label class="star Font-Family"> First Name</label>
              <input type="text" [(ngModel)]="firstName" pattern="[A-Za-z//s]{2,50}" formControlName="first_name"
                     required>
              <div class="mat-error error-age required position-absolute Font-Family"
                   *ngIf="accountForm.get('first_name').touched && accountForm.get('first_name').errors?.required">
                First Name Is Required
              </div>
              <div class="mat-error error-age position-absolute"
                   *ngIf=" accountForm.get('first_name').errors?.pattern">Please type Only Characters At Least 2
              </div>

            </div>
            <div class="col-3 position-relative Font-Family">
              <label class="Font-Family"> Middle Name</label>
              <input type="text" [(ngModel)]="middleName" pattern="[A-Za-z//s]{2,50}" formControlName="middle_name"
                     required>
              <div class="mat-error error-age position-absolute Font-Family"
                   *ngIf="accountForm.get('middle_name').touched && accountForm.get('middle_name').errors?.required">
                Middle Name Is Required
              </div>
              <div class="mat-error error-age position-absolute Font-Family"
                   *ngIf=" accountForm.get('middle_name').errors?.pattern">Please type Only Characters At Least 2
              </div>

            </div>
            <div class="col-3 position-relative">
              <label class="star Font-Family"> Last Name</label>
              <input type="text" [(ngModel)]="lastName" pattern="[A-Za-z//s]{2,50}" formControlName="last_name"
                     required>
              <div class="mat-error error-age required position-absolute Font-Family"
                   *ngIf="accountForm.get('last_name').touched && accountForm.get('last_name').errors?.required"> Last
                Name Is Required
              </div>
              <div class="mat-error error-age position-absolute"
                   *ngIf=" accountForm.get('last_name').errors?.pattern">Please type Only Characters At Least 2
              </div>
            </div>
            <div class="col-3 position-relative">
              <label class="star"> Email</label>
              <input type="text" [(ngModel)]="email" email formControlName="EM" required>
              <div class="mat-error error-age required position-absolute"
                   *ngIf="accountForm.get('EM').touched && accountForm.get('EM').errors?.required">Email Is Required
              </div>
              <div class="mat-error error-age  position-absolute Font-Family"
                   *ngIf=" accountForm.get('EM').invalid">Email Not Valid Please TYpe Valid Email.
              </div>

            </div>
            <div class="col-3 position-relative">
              <label for="nationality" class="star Font-Family"> Nationality</label>
              <select id="nationality" class="list" formControlName="nationality" [(ngModel)]="nationality" required>
                <option [value]="country.id" *ngFor="let country of countries" [ngValue]="country.id">
                  {{country.name}}
                </option>
              </select>
              <div class="mat-error error-age required position-absolute Font-Family"
                   *ngIf="accountForm.get('nationality').touched && accountForm.get('nationality').errors?.required">
                Nationality Is Required
              </div>
            </div>
            <div class="col-3 position-relative">
              <label for="country" class="star Font-Family"> Country</label>
              <select id="country" class="list" formControlName="country" [(ngModel)]="countryName" required>
                <option [value]="country.id" *ngFor="let country of countries" [ngValue]="country.id">
                  {{country.name}}
                </option>
              </select>
              <div class="mat-error error-age  required position-absolute Font-Family"
                   *ngIf="accountForm.get('country').touched && accountForm.get('country').errors?.required">Country Is
                Required
              </div>
            </div>
            <div class="col-3 position-relative">
              <label>address</label>
              <input type="text" [(ngModel)]="address" formControlName="address" required>
              <div class="mat-error error-age required position-absolute Font-Family"
                   *ngIf="accountForm.get('address').touched && accountForm.get('address').errors?.required">address Is
                Required
              </div>
            </div>

            <div class="col-3 position-relative">
              <label class="star Font-Family"> Mobile Number</label>
              <input type="text" [(ngModel)]="phone" pattern="[0-9]{8,}" formControlName="phone" required>
              <div class="mat-error error-age  position-absolute Font-Family"
                   *ngIf="accountForm.get('phone').touched && accountForm.get('phone').errors?.required">Mobile Number
                Is Required
              </div>
              <div class="mat-error error-age err-phone position-absolute"
                   *ngIf="accountForm.get('phone').touched && accountForm.get('phone').errors?.pattern">Please Type Only
                Numbers At Least 8 Numbers
              </div>

            </div>

            <div class="col-3 position-relative Font-Family">
              <label class="star Font-Family"> Gender</label>
              <select class="list" formControlName="gender" [(ngModel)]="gender" required>
                <option>Gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>
              <div class="mat-error error-age required position-absolute Font-Family"
                   *ngIf="accountForm.get('gender').touched && accountForm.get('gender').errors?.required">Gender Is
                Required
              </div>
            </div>
            <div class="col-5 position-relative Font-Family">
              <label class="star Font-Family"> Date of Birth</label>
              <input placeholder="mm-dd-yyyy" [(ngModel)]="birthDate" formControlName="birth_date" type="date"
                     required/>
              <div class="mat-error error-age position-absolute Font-Family"
                   *ngIf="accountForm.get('birth_date').touched && accountForm.get('birth_date').errors?.required">Birth
                Date Is Required
              </div>

            </div>
            <div class="col-4 btn-group position-relative">
              <div class="btn-groups">
                <button class="btn btn fao_mobile_btn_V_small " type="submit" [disabled]="accountForm.status=='INVALID'">SAVE <i
                  *ngIf="loginSpinner"
                  class="fas fa-spinner fa-spin"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="change-password-form">
        <h6 class="h4 Font-Family">Change Password</h6>
        <div class="form-details">
          <h3 *ngIf="passwordError" class="alert-danger alert Font-Family">Please make sure that the the new password is the same as
            the confirmation password</h3>
          <h3 *ngIf="passwordLengthError" class="alert-danger alert">Password should contain at least 6 characters, an
            upper case letter and a symbol</h3>

          <h3 *ngIf="matchError" class="alert-danger alert Font-Family">Current password must match your account password</h3>
          <h3 *ngIf="passwordChange" class="alert alert-success alert-dismissible Font-Family" role="alert">Your password was
            updated successfully</h3>
          <form [formGroup]="passwordForm" (ngSubmit)="submitPassword()" class="row">
            <div class="col-3">
              <label>Current Password</label>
              <input placeholder="Current Password" [type]="oldPassword ? 'text' : 'password'"
                     formControlName="currentPassword" required>
              <i
                class="fa icon"
                [ngClass]="{
                      'fa-eye-slash': !oldPassword,
                        'fa-eye': oldPassword
                          }"
                (click)="showOld()"
              ></i>
            </div>
            <div class="col-3 Font-Family">
              <label>New Password</label>
              <input placeholder="New Password" [type]="newPassword ? 'text' : 'password'" formControlName="newPassword"
                     required>
              <i
                class="fa icon"
                [ngClass]="{
                      'fa-eye-slash': !newPassword,
                        'fa-eye': newPassword
                          }"
                (click)="showNew()"
              ></i>
            </div>
            <div class="col-3 Font-Family">
              <label>Confirm Password</label>
              <input placeholder="Confirm Password" [type]="confirmPassword ? 'text' : 'password'"
                     formControlName="confirmPassword" required>
              <i
                class="fa icon"
                [ngClass]="{
                      'fa-eye-slash': !confirmPassword,
                        'fa-eye': confirmPassword
                          }"
                (click)="showConfirm()"
              ></i>
            </div>
            <div class="col-3 btn-group">
              <div class="btn-groups">
                <button class="btn fao_mobile_btn_V_small"type="submit" [disabled]="passwordForm.status=='INVALID'">SAVE <i
                  *ngIf="passwordSpinner"
                  class="fas fa-spinner fa-spin"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
