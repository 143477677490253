import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { ApisService } from "../../../services/apis.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { ThisReceiver } from "@angular/compiler";
import { FlightDetailsDashboardComponent } from "../../flight-details-dashboard/flight-details-dashboard.component";
import { NotePayLaterComponent } from "../note-pay-later/note-pay-later.component";
@Component({
  selector: "app-pnr-in-dashboard",
  templateUrl: "./pnr-in-dashboard.component.html",
  styleUrls: ["./pnr-in-dashboard.component.css",'../../../pages/review-flight/checkout/checkout.component.css'],
})
export class PnrInDashboardComponent implements OnInit {
  itinerary: any;
  token: any;
  flights: any;
  noteLog: any;
  class: any;
  allStep = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  flightDetails: any;
  personalData: any;
  departureDate: any;
  errorMessages;
  paymentErrorMessages;
  flightData: any;
  familyName: any;
  travellersNum: number;
  continueToPayment = true;
  errorData: any[];
  ticketStep = 0;
  moveStep = 0;
  ticketMaxStep = 2;
  nextName = "NEXT";
  backName = "BACK";
  plaidToken: any;
  plaidReady: boolean;
  plaidInstance: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialogRef: MatDialogRef<PnrInDashboardComponent>,
    private router: Router,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private _HttpClient: HttpClient,
    public dialog: MatDialog
  ) {
    this.token = this.localStorage.getItem("flyallover_token");
    this.itinerary = this.data?.flightData;
    this.errorMessages = this.data?.error;
    this.flightData = this.data?.flightData;
    this.errorData = this.errorMessages?.error?.data;

  }
  handelName(name) {
    var nameUpdata= name != null ? name : ` `;
    return nameUpdata;
  }
  orderError = {}

  ngOnInit(): void {
    // if (this.flightData?.order_status == 'requier_documents') {
    //   this.getPlaidToken()
    //   if (isPlatformBrowser(this.platformId)) {
    //     this.loadPlaidScript().then(() => {

    //       this.plaidReady = true; // Enable the button
    //     }).catch(error => {
    //       console.error('Failed to load the Plaid script', error);
    //     });
    //   }
    // }

    if (this.itinerary) {
      this.flightDetails = JSON.parse(this.itinerary?.itinerary_details);
      this.familyName = this.flightData?.family_members[0]?.family_member;
      this.flightNum();
      this.flightName();
    }
    console.log('prn with dash');

  }

  goToHome() {
    this.router.navigate(["/"]);
  }

  flightName() {
    if (this.ticketStep == this.ticketMaxStep) {
      this.nextName = "Payment";
      this.backName = "Cancel";
    } else {
      this.nextName = "Next";
      this.backName = "Back";
    }
  }

  flightNum() {
    if (this.flightDetails.flights.length) {
      var flightNumFirst = this.flightDetails.flights.length;
      if (flightNumFirst == 2) {
        // var flightnum2 =
      }
    } else {
      var flightNumFirst = this.flightDetails.flights[0].length;
    }
    const flightNumChild = [];
    let totalLength = 0;
    for (let i = 0; i < flightNumFirst; i++) {
      const flightDetails = this.flightDetails.flights[i];

      if (flightDetails) {
        flightNumChild[i] = flightDetails.length;
        totalLength += flightDetails.length;
      }
    }
    this.ticketMaxStep = totalLength;
  }

  payment() {
    this.paymentMothed();
  }

  routToDashboard(orderId) {

  }



  goToNextStep() {
    if (this.ticketStep >= 0 && this.ticketStep < this.ticketMaxStep) {
      // if(this.ticketStep>1 && this.ticketStep<3 && this.ticketStep !=2.2){
      //   this.ticketStep = this.ticketStep +0.1

      //else  {
      this.ticketStep++;

      // }
      this.flightName();
      console.log("step", this.ticketStep);
    } else if (this.ticketStep == this.ticketMaxStep) {
      this.dialogRef.close();
      this.payment();
    }
  }

  convertTime(minutes) {
    let convertedHours = "";
    let convertedMinutes = "";
    let space = " ";
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}hr(s)`;
    }
    convertedMinutes = `${minutes % 60}min(s)`;
    return convertedHours + space + convertedMinutes;
  }

  goToPreviousStep() {
    if (this.ticketStep > 0 && this.ticketStep <= this.ticketMaxStep) {
      this.flightName();
      this.ticketStep--;
    } else if (this.ticketStep == this.ticketMaxStep) {
      this.openNote();
      this.dialogRef.close();
    }
  }

  openNote(): void {
    // this.noteLog = this.dialog.open(NotePayLaterComponent, {
    //   panelClass: 'alternativeDateDialog',
    //   autoFocus: false,
    //   minHeight: '97vh',
    //   width: "max-content",
    // });
    // this.noteLog.afterClosed().subscribe((data) => {
    //   this.router.navigate(['/']);
    // });
  }
  paymentMothed() {
    this.router.navigate(['/review-book', this.flightData.id]);

  }
  getPlaidToken() {
    this.apis.plaidCreateToken().subscribe({
      next: (res: any) => {
        this.plaidToken = res?.link_token;
        console.log('plaidToken', this.plaidToken);
        this.initPlaid();
      },
      error: error => {
        console.log(error?.error);
      }
    })
  }
  verifyDocument() {

    this.router.navigate([`/payment-details/${this.flightData.order_status}/${this.flightData.id}`]);

    // if (this.plaidReady) {
    //   this.plaidInstance.open();
    // }

  }
  checkDocument(){

  }

  loadPlaidScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).Plaid) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.onload = () => resolve();
      script.onerror = () => reject('Failed to load the Plaid script');
      document.body.appendChild(script);
    });
  }
  initPlaid() {
    this.plaidInstance = (window as any).Plaid.create({
      clientName: 'Fly All Over',
      env: 'sandbox',
      token: this.plaidToken,
      product: ['identity'],
      onSuccess: (public_token: string, metadata: any) => {
        console.log('Public Token:', public_token);
        console.log('metadata :', metadata);
      },
      onExit: (err: any, metadata: any) => {
        if (err != null) {
          console.error('Error:', err);
        }
      }
    });
  } protected readonly length = length;
}
 