import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { AppComponent } from "../app.component";
import "reflect-metadata"

@Injectable({
  providedIn: "root",
})
export class SEOService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc
  ) {}

  updateTitle(title: string): void {
    // if(title) return
    title = title.replace(/<\/?[^>]+(>|$)/g,"");
    this.title.setTitle(title);
    // this.meta.addTag({ name: "title", content: title });
    // this.meta.addTag({ property: "og:title", content: title });
    // this.meta.addTag({ name: "twitter:title", content: title });

    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });
    this.meta.updateTag({ name: "twitter:title", content: title });
  }

  updateDescription(desc: string): void {
    // if(desc) return
    desc = desc.replace(/<\/?[^>]+(>|$)/g,"");
    this.meta.updateTag({ name: "description", content: desc });
    this.meta.updateTag({ property: "og:description", content: desc });
    this.meta.updateTag({ name: "twitter:description", content: desc });
  }

  updateUrl(url = this.doc.location.href): void {
    this.meta.updateTag({ property: "og:url", content: url + "/" });
    this.meta.updateTag({ name: "twitter:url", content: url + "/" });
    this.meta.updateTag({ name: "canonical_tag", content: url + "/" });
    this.updateCanonicalURL(url);
  }

  updateImage(
    image = "https://flyallover.com/assets/images/home/metaDataImageHome.png"
  ): void {
    this.meta.updateTag({ property: "og:image", content: image });
    this.meta.updateTag({ property: "image", content: image });
    this.meta.updateTag({ name: "twitter:image", content: image });
  }

  updateCanonicalURL(url: string): void {
    const canonicalLink = this.doc.getElementById("canonical");
    canonicalLink.setAttribute("href", url + "/");
  }
}
