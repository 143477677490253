<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center mt-5">
  <div class="container">
    <div class="row gy-4">
      <div
        class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
      >
        <p id="best" >We Are The Best</p>
        <h2 class="Font-Family-bold ">Why Book ... With Us ?</h2>
<!--        <h3 class="pb-3">With Us ?</h3>-->
        <div>
          <ul class="mainMenu">
            <li>
              <img
                src="../../../assets/images/icon/like.png"
                class="p-1"
                alt="Your payments are protected"
                title="Your payments are protected"
              />
              <p >
                - FlyAllOver, we are dedicated to helping you save money on your next trip.
              </p>
            </li>
            <li>
              <img
                src="../../../assets/images/icon/dollar-currency-symbol.png"
                class="p-1"
                alt="Cheap Flight"
                title="Cheap Flight Tickets"
              />
              <p >
                - Here at We offer a variety of services that
                can help you book your travel arrangements at the best possible price.
              </p>
            </li>
            <li>
              <img
                src="../../../assets/images/icon/star.png"
                class="p-1"
                alt="Exclusive Offers"
                title="Exclusive Offers"
              />
              <p >
                - Whether you're looking for cheap flight tickets
                or exclusive offers on hotels and tours, we can help you find the perfect deal. And, our secure and
                private booking
                system ensures that your personal information will always be safe.
              </p>
            </li>
            <li>
              <img
                src="../../../assets/images/icon/love.png"
                class="p-1"
                alt="Secure and private booking"
                title="Secure and private booking"
              />
              <p >
                - So why wait? Book with us today and start planning
                your dream vacation.
              </p>
            </li>
          </ul>
<!--          <a href="#" routerLink="/about" class="btn-get-started scrollto"-->
<!--          >Read More-->
<!--          </a>-->
          <div>
            <div class="row">
              <div class="col-8">
                <button class="fao_des_btn_med mb-3 Font-Family" [routerLink]="['/about']">
                  Read More
                </button>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div class="col-lg-6 order-1 order-lg-2 hero-img">
        <img
          src="../../../assets/images/arrow/mobile-travel png.png"
          class=""
          alt="FlyAllOver - Cheap Flight Tickets"
          title="Booking With Us"
        />
      </div>
    </div>
  </div>
</section>
<!-- End Hero -->
<p>

</p>
