<div class="home-offers">
  <div class="container">
    <div class="section-header text-center mt-5">
      <h4 class="mt-5 fao_mobile_title_large Font-Family-bold"> Offers</h4>
      <div
        style="
          background-color: #000000;
          height: 3px;
          width: 70px;
          margin: auto;
          border-radius: 2px;
        "
      ></div>
    </div>
    <div class="row my-5 ml-5">
      <owl-carousel-o *ngIf="data" [options]="customOptions" class="d-flex justify-content-center">
        <ng-container *ngFor="let item of data | reverse;  let i = index">
          <ng-container *ngIf="i<= 5">
            <ng-template carouselSlide>
              <div class="col col-md-12 col-lg-12 hover" >
                <div class="offer card-body">
                  <div class="offer-img">
                    <a [routerLink]="['offers/', item.slug]">
                    <img
                      src="{{ item.images[0].full_url }}"
                      alt="{{ item.name }}"
                      class="rounded w-100"
                    />
                  </a>
                    <div class="name">
                      <a [routerLink]="['offers/', item.slug]">
                      <h3 class="fao_mobile_title_meduim text-center mt-3">
                        {{ item.name }}
                      </h3>
                      </a>
                      <h6 class="d-flex justify-content-center fw-bold pt-1 Font-Family" >
                        <span class=" pt-1 DataFont Font-Family">{{ item.departure_date | date: "MM/dd/yyyy" }} </span>
                        <span class="px-2 bold text-dark DataFont Font-Family"> To </span>
                        <span class=" pt-1 DataFont Font-Family">{{ item.return_date | date: "MM/dd/yyyy" }}    </span>
                      </h6>
                    </div>
                  </div>
                  <div
                    style="
                      background-color: #ddd4d4;
                      height: 1px;
                      width: 95%;
                      margin: auto;
                    "
                  ></div>
                  <div>
                    <p class="fao_mobile_prgraph">
                      {{ item.offer_description |slice:0:150}}
                    </p>
                    <div class="line-height px-4">
                      <h4 class="price end_div Font-Family">{{item.price | currency}}</h4>
                       <span class="d-flex justify-content-end text-muted Font-Family">Round Trip</span>
                    </div>
                    <div class="center-class">
                      <button class="fao_des_btn_med my-4" [routerLink]="['/offers/' ,item.slug]"  (click)="refresh(item.slug)">
                        MORE DETAILS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div class="col-12 d-flex justify-content-center hide-button mp-4 ">
      <button class="my-4 fao_des_btn_large" [routerLink]="['/offers']">
        More Offers
      </button>
<!--      <a href="/offers" class="booking ">   <h4 class="mt-2"> More Offers </h4>-->
<!--        <i aria-hidden="true" class="fa-fw fas fa-arrow-right mt-3"></i>-->
<!--      </a>-->
    </div>
  </div>
</div>
