import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ApisService} from 'src/app/services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-user-docs',
  templateUrl: './user-docs.component.html',
  styleUrls: ['./user-docs.component.css']
})

export class UserDocsComponent implements OnInit {
  messages: string[] = [];
  token: string;
  docsForm = this.formBuilder.group({
    bank_statement: ['', Validators.required],
    ID: ['', Validators.required],
    passport: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder,
              private apis: ApisService, private cookie: CookieService,
              private localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
    this.token = this.cookie.get('flyallover_token');
    this.token =this.localStorage.getItem('flyallover_token');
  }

  submitDocs() {
    if (this.docsForm.valid) {
      this.apis.userDocuments(this.docsForm.value, this.token).subscribe(data => {
        this.messages = [];
      }, err => {
        this.messages = [];
        Object.keys(err.error.error).forEach(el => {
          this.messages.push(err.error.error[el][1]);
        });
      });
    } else {
      this.messages = ['Check your inputs validity'];
    }
  }
}
