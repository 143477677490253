<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Birthday Email</title>
</head>
<style>
    body {
        display: flex;
        justify-content: center;
        width: 100%;
        font-family: "poppins", sans-serif;
        background-color: #F9F9F9;
    }

    nav {
        background-color: #1573BC;
        height: 180px;
        width: 700px;
    }

    nav img {
        width: 350px;
    }

    ul {
        list-style: none;
        display: inline-block;
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
        margin-bottom: 0;
        padding-left: 0;
    }

    li {
        display: inline-block;
        margin: 20px 40px;
    }

    .title {
        width: 700px;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        padding: 30px 10px;
        background-color: white;
    }

    .text , .text h1 , .text h4 , .text h6{
        width: 700px;
        font-size: 22px !important;
        text-align: center;
        margin: 40px auto;
    }

    .button {
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .button button {
        background-color: #009FE2;
        border-radius: 5px;
        color: white;
        padding: 10px 20px;
        font-size: 20px;
        border-style: none;
    }

    .arrow {
        width: 20px;
    }

    @media(max-width: 320px) {
        nav img {
            width: 200px;
        }

        ul {
            font-size: 15px
        }

        li {
            margin: 20px 10px;
        }

        .banner img {
            width: 320px;
        }

        .title {
            font-size: 25px
        }

        .text {
            width: 270px;
        }

    }

    @media(min-width: 321px) and (max-width: 375px) {
        nav img {
            width: 250px;
        }

        ul {
            font-size: 15px
        }

        li {
            margin: 20px 10px;
        }

        .banner img {
            width: 385px;
        }

        .title {
            font-size: 25px
        }

        .text {
            width: 270px;
        }
    }

    @media(max-width: 425px) and (min-width: 376px) {
        li {
            margin: 20px 10px;
        }

        .banner img {
            width: 420px;
        }

        .title {
            font-size: 25px
        }

        .text {
            width: 270px;
        }
    }

    @media(max-width: 768px) and (min-width: 426px) {
        li {
            margin: 20px 10px;
        }

        .banner img {
            width: 420px;
        }

        .title {
            font-size: 25px
        }

        .text {
            width: 400px;
        }
    }
</style>

<body>
    <div style="display: flex; align-items: center; flex-direction: column;">
        <nav style="display: flex; justify-content: center;">
            <img title="flyallover" alt="logo flyallover"  src="../../../../assets/images/logo-white2.png" style="margin: auto;">
        </nav>
        <div style="background-color: white; width: 700px; display: flex; justify-content: center;">
            <ul>
                <li>Home</li>
                <li>Offers</li>
                <li>Flights</li>
                <li>Tours</li>
                <li>Blog</li>
            </ul>
        </div>
        <div class="banner">
            <img alt="birthday"  title="Birthday Linda" src="../../../../assets/images/emails/birthday-lede.jpg" width="700px">
        </div>
        <h3 class="title">
            Happy birthday, Linda!
        </h3>
        <div class="text">
            <h6>It’s time to celebrate YOUR special day! Why not take your
                celebration global?</h6>
            <img alt="Ballon" title="Birthday Linda"  src="../../../../assets/images/emails/ballon.png" width="150px" style="margin: 20px auto;">
            <h1>
                Enjoy your birthday around the world with <strong> an extra $20* off</strong> your
                next flight with promo code<strong>TIME2PARTY</strong> .
            </h1>
        </div>
        <div class="button">
            <button>Treat yourself <img alt="arrow"  title="Treat Yourself" src="../../../../assets/images/right-arrow.png" class="arrow"></button>
        </div>
        <div class="banner">
            <img alt="fun"  title="Share Fun Image" src="../../../../assets/images/emails/share-fun.PNG" width="700px">
        </div>
        <div class="text">
            <h4>Share The Fun With Us!</h4>
            <img alt="camera"  title="Camera Image" src="../../../../assets/images/emails/camera.png" width="75px" style="margin: 20px auto;">
            <h4>
                Whether your celebrating on the white sandy beaches of Hawaii or
                stuffing your face with cake in bed at home, we want to join in! Follow us
                on Instagram and share your Birthday celebration with the world!
                <br>
                #It’sMyBirthdayAndI’llFlyIfIWantTo
            </h4>
        </div>
        <div class="button">
            <button style="width: 100%;">Follow Now <img alt="right-arrow"  src="../../../../assets/images/right-arrow.png"
                    class="arrow"  title="Follow Now"></button>
        </div>
    </div>
</body>

</html>
