import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-dest-description',
  templateUrl: './popular-dest-description.component.html',
  styleUrls: ['./popular-dest-description.component.css']
})
export class PopularDestDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
