import {Component, Input, OnInit} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-navigation',
  templateUrl: './review-flight-navigation.component.html',
  styleUrls: ['./review-flight-navigation.component.css']
})

export class ReviewFlightNavigationComponent implements  OnInit{
  @Input() currentStep;
  steps = [
    'Review Flight',
    'Travellers Details',
    // 'Protection',
    'Review & Book',
  ];
  constructor(private seoService: SEOService) {
    this.seoUpdate();
  }
  ngOnInit() {
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
