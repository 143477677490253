<app-navbar></app-navbar>
<div class="vaction-header">
  <h1 class="title">Vacations</h1>
</div>

<div class="container">
  <div class="row">
    <section id="vacations" class="section-with-bg my-5">
      <div class="container">
        <div class="row mx-16">
          <div
            class="col-4 col-md-4 col-lg-4 col-sm-4 my-3"
            *ngFor="let vacation of hotelsVacations"
          >
            <div class="post-item position-relative h-100">
              <div class="post-img position-relative overflow-hidden">
                <img
                  [src]="vacation.main_image"
                  class="w-100"
                  alt=""
                  style="height: 250px !important"
                />
              </div>

              <div class="post-content d-flex flex-column">
                <div style="height: 13vh">
                  <div class="handleNAme">
                    <h6 class="post-name">{{ vacation.hotel.name }}</h6>
                  </div>
                  <div class="rating">
                    <!-- <div style="width: 90px"> -->
                    <span
                      [ngClass]="getRating(vacation.hotel.hotel_rate, 1)"
                      class="fa fa-star"
                    ></span>
                    <span
                      [ngClass]="getRating(vacation.hotel.hotel_rate, 2)"
                      class="fa fa-star"
                    ></span>
                    <span
                      [ngClass]="getRating(vacation.hotel.hotel_rate, 3)"
                      class="fa fa-star"
                    ></span>
                    <span
                      [ngClass]="getRating(vacation.hotel.hotel_rate, 4)"
                      class="fa fa-star"
                    ></span>
                    <span
                      [ngClass]="getRating(vacation.hotel.hotel_rate, 5)"
                      class="fa fa-star"
                    ></span>
                    <!-- </div> -->
                  </div>
                </div>
                <hr />
                <div class="handelTitle">
                  <h3 class="post-title">{{ vacation.title }}</h3>
                </div>

                <!-- <div class="rating"  >
                  <span  class="fa fa-star"></span>
                </div> -->
                <!-- class="crop"
                    [style.width.px]="starWidth" -->

                <div class="nights">
                  <h6 class="price">${{ " " + vacation.price }}</h6>
                </div>
                <div class="nights">
                  <h6 class="nights-num">
                    {{ vacation.number_of_nights + " " }}nights
                  </h6>
                </div>
                <hr />
                <a
                  [routerLink]="['/vacations-details/', vacation.id]"
                  class="readmore stretched-link"
                  ><span>More Details</span>
                  <!--            <i class="bi bi-arrow-right"></i>-->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--   -----------------------  new  -------------------->
    <!--    <section class="section">-->
    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          <div class="gallery gallery&#45;&#45;home">-->
    <!--            <figure class="gallery__img-box gallery__img-box&#45;&#45;home">-->
    <!--              <img src="./assets/images/hotels/img/gallery1.jpg" class = "gallery__img" alt="Gallery Image 1">-->
    <!--              <a href="#" class="btn btn&#45;&#45;transparent">Book Room</a>-->
    <!--              <h2 class="heading&#45;&#45;2">Standard Room</h2>-->
    <!--              <h3 class="heading&#45;&#45;3"><span>NGN 20,000</span> / Night</h3>-->
    <!--            </figure>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="col-12">-->
    <!--          <div class="gallery gallery&#45;&#45;home">-->
    <!--            <figure class="gallery__img-box gallery__img-box&#45;&#45;home">-->
    <!--              <img src="./assets/images/hotels/img/gallery1.jpg" class = "gallery__img" alt="Gallery Image 1">-->
    <!--              <a href="#" class="btn btn&#45;&#45;transparent">Book Room</a>-->
    <!--              <h2 class="heading&#45;&#45;2">Standard Room</h2>-->
    <!--              <h3 class="heading&#45;&#45;3"><span>NGN 20,000</span> / Night</h3>-->
    <!--            </figure>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </section>-->
  </div>
</div>

<app-footer></app-footer>
