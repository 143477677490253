<section class="protectionSection mb-5 travel-protection">
  <div class="title">
    <div class="col-12">
      <div class="row justify-content-between align-items-center">
        <span class="head">Travel Protection (Recommended)</span>
        <span *ngIf="insuranceFacilities" class="insurance-price">{{insuranceCost | currency}}</span>
        <ng-container *ngIf="!insuranceFacilities">
          <ngx-skeleton-loader [theme]="{height:'20px', width:'80px','margin-bottom':'0px'}">
          </ngx-skeleton-loader>
        </ng-container>
      </div>
      <div class="row justify-content-end">
        <a class="underline" *ngIf="insuranceFacilities" [href]="insuranceUrl" target="_blank">
          <span>View Plan Summary for terms and conditions</span>
        </a>
      </div>
    </div>
  </div>
  <div class="protectionBody col-12">
    <div>
      <span *ngIf="insuranceError" class="text-danger insurance-error">{{insuranceError}}</span>
      <mat-radio-group #radioGroup="matRadioGroup"
                       [(ngModel)]="protectionAccept" class="advanced-search-buttons">
        <mat-radio-button (change)="setProtection(false)" value="no">
          No, I'm willing to risk my {{itineraries[0]?.prices?.ItinTotalFare + itineraries[0]?.Fees | currency}}
          trip.
        </mat-radio-button>
        <mat-radio-button (change)="setProtection(true)" value="yes">
          Yes, I want to protect my trip. Recommended
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="accordions">
      <div class="accordion">
        <ng-container *ngIf="insuranceFacilities">
          <div class="accordion-header">
            <div class="row">
              <div class="col">
                <span>Reason You Might Need To Travel With Protection :</span>
              </div>
            </div>
          </div>
          <div class="accordion-body">
            <div class="row">
              <ng-container *ngFor="let facility of insuranceFacilities">
                <div *ngIf="facility !== ''" class="col-6 facility">
                  <div class="d-flex justify-content-start">
                    <img alt="hotels tick"  title="facility-image" src="./assets/images/hotels/tick.svg"/>
                    <span>{{facility[1][0].Name}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!insuranceFacilities">
          <ngx-skeleton-loader [theme]="{height:'40px', width:'100%','margin-bottom':'0px'}">
          </ngx-skeleton-loader>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<div class="navigationButtons">
  <div class="col-12">
    <div class="row justify-content-between">
      <button class="btn navBtn previousStepBtn" (click)="previousStepEvent.emit()">Travellers Details</button>
      <button class="btn navBtn nextStepBtn" (click)="nextStepEvent.emit()">Payment</button>
    </div>
  </div>
</div>

