import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-flight-details-header',
  templateUrl: './flight-details-header.component.html',
  styleUrls: ['./flight-details-header.component.css']
})

export class FlightDetailsHeaderComponent implements OnInit {
  @Input() itinerary;
  @Input() flightType;
  // bagging: any;
  // BF: any;
  // LG: any;
  constructor(
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    // this.bagging = this.itinerary.BrandFeatures.BG;
    // this.BF = this.itinerary.BrandFeatures.BF;
    // this.LG = this.itinerary.BrandFeatures.LG;

  }

  // convertTime(minutes) {
  //   let convertedHours = '';
  //   let convertedMinutes = '';
  //   if (Math.floor(minutes / 60) > 0) {
  //     convertedHours = `${Math.floor(minutes / 60)}h`;
  //   }
  //   convertedMinutes = `${minutes % 60}m`;
  //   return convertedHours + ' ' + convertedMinutes;
  // }

  // calcTotalFlightTime(flights) {
  //   let totalTime = 0;
  //   flights.forEach(function(flight) {
  //     if (Array.isArray(flight)) {
  //       flight.forEach(function(stop) {
  //         totalTime += stop.ElapsedTime;
  //         totalTime += stop.FlightLayoverTime;
  //       });
  //     } else {
  //       totalTime += flight.ElapsedTime;
  //       totalTime += flight.FlightLayoverTime;
  //     }
  //   });
  //   return (`${Math.floor(totalTime / 60)}h ${totalTime % 60}m`);
  // }

  // toggleBagsDetails(selector) {
  //   this.document.querySelector('.bagsDetails.' + selector).classList.toggle('show');
  // }
}
