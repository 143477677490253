<!-- end header -->
<div class=" d-flex row ">
  <section class="find-adventure col-6">
    <div class="placeHolder element pointer input-search">
      <input
        #dpFromDate
        (click)="datepicker.toggle()"
        [ngModel]="formatter.format(toDate)"
        [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        class="datepicker__return departure_date " placeholder="Choose Date">
      <div *ngIf=" !departureDate">
        <span class="star">*</span>
        <span>departure</span>
      </div>

      <div *ngIf="departureDate">
        <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{departureDate|date:'MMM d'}}</span>
          <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
          >{{departureDate|date:'yyy'}}</span>
        </div>
      </div>
      <div (click)="datepicker.toggle()">
        <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
      </div>
    </div>
    <div class="form-group hidden">
      <div class="input-group">
        <input
               class="form-control"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="fromDate!"
               tabindex="-1">
        <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
        </ng-template>
      </div>
    </div>
  </section>
  <!-- end header -->
  <section class="find-adventure col-6">
    <div class="placeHolder element pointer input-search">
      <input
        #dpToDate
        [ngModel]="formatter.format(toDate)"
        [value]="formatter.format(toDate)"
        (click)="datepicker.toggle()"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        class="datepicker__return return_date" placeholder="Choose Date">
      <div *ngIf=" !returnDate">
        <span class="star">*</span>
        <span>return</span>
      </div>

      <div *ngIf="returnDate">
        <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{returnDate |date:'MMM d'}}</span>
          <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
          >{{returnDate |date:'yyy'}}</span>
        </div>
      </div>
      <div (click)="datepicker.toggle()">
        <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
      </div>
    </div>
  </section>
</div>
