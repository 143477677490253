<app-navbar></app-navbar>
<section class="thanks-modal">
  <div class="logo text-center">
    <img src="/assets/images/logo3.png" alt="logo" title="FlyAllOver">
    <span> FlyAllOver </span>
  </div>
  <div class="d-flex justify-content-center success">
    <img src="/assets/images/success.svg" alt="logo" title="FlyAllOver">
  </div>
  <h4 class="text-center ticket-details-header">Your reservation has been confirmed! Please<a href="/profile/dashboard">click here</a>
    to log into your
    account for important information regarding your booking.</h4>
</section>
<app-footer></app-footer>
