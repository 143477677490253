<app-navbar *ngIf="navbar"></app-navbar>
<section class="chart-page padding-top-100 padding-bottom-100">
  <div class="" [ngClass]="{'container':navbar,'row':!navbar}">
    <div class="col-12 p-0 " >
      <!-- Payments Steps -->
      <div class="cs-mb20 mt-5 p-3" *ngIf="user">
        <ul class="cs-list cs-style1">
          <li>
            <div class="cs-list_left"><b class="cs-primary_color">Passenger Name: </b> <span
                *ngIf="user?.gender=='Male'">MR</span>
              <span *ngIf="user?.gender!=='Male'">MRS</span>
              {{handelName(user?.last_name) + ' / ' + handelName(user?.first_name) + ' ' + handelName(user?.middle_name)
              +
              ' '}}
            </div>
          </li>

        </ul>
      </div>
      <div class="cs-invoice_head cs-50_col cs-mb15 p-3" *ngIf="filghtDetails">
        <div class="cs-invoice_left">
          <ul class="cs-bar_list">
            <li><b class="cs-primary_color" *ngIf="filghtDetails?.origin_city_object">From: </b>
              {{filghtDetails?.origin_city_object.city_name}}
              , {{filghtDetails?.origin_city_object.country_code}}</li>
            <li><b class="cs-primary_color" *ngIf="filghtDetails?.destination_city_object">To: </b>
              {{filghtDetails?.destination_city_object.city_name}}
              , {{filghtDetails?.destination_city_object.country_code}}</li>
          </ul>
        </div>
        <div class="cs-invoice_right">
          <ul class="cs-bar_list">
            <li><b class="cs-primary_color" *ngIf="filghtDetails?.departure_date">Department Date: </b>
              {{filghtDetails?.departure_date| date:"dd MMM yyy"}}</li>
            <li><b class="cs-primary_color" *ngIf="filghtDetails?.return_date">Return Date: </b>
              {{filghtDetails?.return_date| date:"dd MMM yyy"}}</li>
          </ul>
        </div>
      </div>
      <div class="shopping-cart">

        <!-- SHOPPING INFORMATION -->
        <div class="cart-ship-info" [formGroup]="form">
          <div class="row">

            <!-- ESTIMATE SHIPPING & TAX -->
            <div class="col-sm-7">
              <h6 class="mb-3">Billing Address</h6>
              <p class="mb-5">Your billing address is totally secure and confidential.</p>

              <ul class="row">

                <!-- Name -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>ADDRESS
                    <input type="text" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                      formControlName="CreditCardstreetAddress"  placeholder="">
                  </label>
                </li>
                <!-- LAST NAME -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>CITY
                    <input type="text" [ngModel]="city" formControlName="CreditCardCity" placeholder="">
                  </label>
                  <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only
                    Letters</mat-error>
                  <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is
                    Required
                  </mat-error>
                </li>

                <li class="col-md-6">
                  <!-- ADDRESS -->
                  <label><span class="red-star">*</span>STATE
                    <input type="text" formControlName="State" [ngModel]="state" placeholder="">
                  </label>
                  <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
                  </mat-error>
                  <mat-error *ngIf="f['State'].errors?.pattern">Please Type Only Letters</mat-error>
                </li>


                <!-- COUNTRY -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>COUNTRY
                    <input type="text" formControlName="CreditCardCountry" [ngModel]="country" placeholder="">
                  </label>
                  <mat-error *ngIf="form.get('CreditCardCountry').errors?.someProp">
                    {{form.get('CreditCardCountry').errors?.someProp}}
                  </mat-error>
                  <mat-error *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].dirty"> Country
                    Is
                    Required
                  </mat-error>
                </li>
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>ZIP
                    <input type="text" [ngModel]="ZipCode" formControlName="CreditCardZip" placeholder="">
                  </label>
                  <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
                  </mat-error>
                </li>
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>CREDIT CARD REGION
                    <input type="text" formControlName="creditCardRegion" placeholder="" [ngModel]="creditRegion">
                  </label>
                </li>
              </ul>
              <h6 class="margin-top-50 mb-5">Contact Billing Information</h6>
              <ul class="row">
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>EMAIL ADDRESS
                    <input type="text" formControlName="contactEmail" placeholder="">
                  </label>
                </li>
                <!-- PHONE -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>PHONE
                    <input type="text" formControlName="contactPhone" placeholder="">
                  </label>
                </li>

                <!-- PHONE -->

              </ul>

              <h6 class="margin-top-50 mb-3">Billing Details</h6>
              <p class="mb-5">Please Enter your card details to proceed the payment process as written on your card to
                avoid any mistakes.</p>


              <ul class="row">

                <!-- Name -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>NAME ON CARD

                    <input type="text" (ngModelChange)="validationForm('cardHolderFullName')"
                      formControlName="cardHolderFullName" placeholder="">
                  </label>
                  <mat-error *ngIf="form.controls.cardHolderFullName.errors?.pattern">
                    Please Type Only Letters At least Three Names</mat-error>
                  <mat-error
                    *ngIf="form.controls.cardHolderFullName.errors?.required && form.controls.cardHolderFullName.dirty">
                    Name On Card Is Requied</mat-error>
                </li>
                <!-- LAST NAME -->
                <li class="col-md-6">
                  <label> <span class="red-star">*</span>CARD NUMBER

                    <input type="text" formControlName="card" (ngModelChange)="testCreditCard();validationForm('card')"
                      maxlength="16" placeholder="">
                  </label>
                  <mat-error *ngIf="form.controls.card.errors?.pattern"> Please Type Only
                    Numbers</mat-error>
                  <mat-error *ngIf="form.controls.card.errors?.pattern"> Card Number Should be 16 Number
                  </mat-error>
                </li>
                <li class="col-md-6">
                  <!-- COMPANY NAME -->
                  <label><span class="red-star">*</span>CVC
                    <input type="text" formControlName="cvc" (ngModelChange)="validationForm('cvc')" placeholder="" >
                  </label>
                  <div class="errors col-12 position-relative ">
                    <div class="mat-errors">
                      <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern">
                        <p>Please Type Only 3 Numbers</p>
                        <p>4 Numbers for American Express</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="col-md-6">
                  <!-- ADDRESS -->
                  <label><span class="red-star">*</span>
                    EXPIRATION DATE
                    <div class="d-flex">
                      <div class="col-4 p-0 pe-2" (keyup)="checkDate()">
                        <input type="text" formControlName="CreditCardExpireDateMonth" (change)="updateExpireDate()"
                          (ngModelChange)="validationForm('CreditCardExpireDateMonth')" placeholder="MM" maxlength="2">
                      </div>
                      <div class="col-8 p-0" (keyup)="checkDate()">
                        <input type="text" formControlName="CreditCardExpireDateYear" (change)="updateExpireDate()"
                          (ngModelChange)="validationForm('CreditCardExpireDateYear')" placeholder="Year" maxlength="4">
                      </div>
                    </div>
                  </label>
                  <div class="errors col-12 position-relative ">
                    <div class="mat-errors">
                      <div class="b-2" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                        Type Only Two Numbers Such As 07</div>
                      <div class="b-2" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
                      <div class="b-2" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please Type
                        Only Four Numbers Such As 2022</div>
                      <div class="b-2" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
                    </div>
                  </div>
                </li>
                <li class="col-md-12 p-0">
                  <div class="checkbox">
                    <input (change)="validateCheckboxes($event);" [formControl]="checkbox2" class="styled"
                      type="checkbox">
                    <label for="checkbox3-4" class="red-star">
                      Credit Card and Contact Information Matching Confirmation</label>
                  </div>
                  <p class="ps-4">My credit card information is matching my contact information.
                  </p>
                </li>
              </ul>

            </div>

            <!-- SUB TOTAL -->
            <div class="row col-sm-5 ">
              <div class="col-12 mb-3" *ngIf="!navbar">
                <h6>FARE RULES</h6>
                <app-review-flight-fare-rules [itineraries]="fareRulesData"
                  [title]="false"></app-review-flight-fare-rules>
              </div>
              <div class="col-12">
                <h6>Price Details</h6>
                <div class="order-place">
                  <div class="order-detail" *ngIf="itinerary?.passengerDetails">
                    <ng-container *ngFor="let item of itinerary.passengerDetails">
                      <p *ngIf="item.Quantity > 0"> {{item.Quantity}} x {{item.PassengerType}} <span>$ {{item.Price}}
                        </span></p>
                    </ng-container>
                    <p *ngIf="itinerary?.prices?.AdultPriceWithOffer>'0'">Adult Price With Offer <span>
                        ${{itinerary.prices?.AdultPriceWithOffer}}</span></p>

                    <!-- SUB TOTAL -->
                    <p *ngIf="itinerary?.prices?.ItinTotalFare>'0'" class="all-total">Total Price (USD) <span>
                        ${{itinerary?.prices?.ItinTotalFare}}</span></p>
                  </div>
                  <div class="pay-meth">
                    <ul>

                      <li>
                        <div class="checkbox">
                          <input (change)="validateCheckboxes($event);" [formControl]="checkbox1" class="styled"
                            type="checkbox">
                          <label for="checkbox3-4" class="red-star"> This itinerary is for an alternate date and nearby
                            airport. Depart
                            ({{itinerary?.flights[0][0].DepartureDateTime | date: 'EEE, MMM d ' }}), Return
                            ({{itinerary?.flights[itinerary?.flights.length - 1][0].ArrivalDateTime | date: 'EEE, MMM d'
                            }}).
                          </label>
                          <p class="ps-4">Please check this box to indicate that you've confirmed the date(s) and
                            airport(s)
                            to be correct and that you're ready to book this flight.
                          </p>
                        </div>
                      </li>

                      <li>
                        <p class="red-star ps-4">Please note: your credit/ debit Card may be billed in multiple charges
                          totaling the final total price.
                        </p>
                      </li>
                      <li>
                        <div class="checkbox">
                          <input (change)="validateCheckboxes($event);" [formControl]="checkbox3" class="styled"
                            type="checkbox">
                          <label for="checkbox3-4"> I Agree that I have read and accepted the fly All Over's <a
                              class="color" [routerLink]="['/terms']"> TERMS & CONDITIONS </a>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="checkbox">
                          <input (change)="validateCheckboxes($event);" [formControl]="checkbox4" class="styled"
                            type="checkbox">
                          <label for="checkbox3-4"> I Agree on the <a class="color"
                              (click)="openFareRulesDialog(itinerary)">Fare Rules </a>of each one of the airlines of
                            this
                            trip.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="checkbox">
                          <input (change)="validateCheckboxes($event);" [formControl]="checkbox5" class="styled"
                            value="1" type="checkbox">
                          <label for="checkbox3-4">Please Acknowledge Our <a
                              href="https://flyAllOver.com/cancellation-policy/" class="color">Cancellation Policy.</a>
                          </label>
                        </div>
                      </li>
                    </ul>
                    <a class="vt-btn-lager margin-top-50"
                      [ngClass]="{'disable-btn': checkPoliceyBox <5&&this.form.status == 'VALID'}"
                      (click)="submit()">REVIEW
                      & BOOK</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 border-1px mb-3 " *ngIf="!navbar">
              <!-- <h6>Secure SSL Booking</h6> -->
              <app-review-flight-secure-section [title]="true" [offers]="false"></app-review-flight-secure-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer *ngIf="navbar"></app-footer>
