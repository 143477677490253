<div class="loading" >
  <div class="box-img" >
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
<!-- <div class="box-text" style="min-width: 70ch !important;">
  <div class="typewriter2">Please be patient while fetching your request <span class="fadein">...</span></div>
</div> -->
<div class="message">
  <span>Please wait<br><p style="font-size: 14px;text-transform: uppercase;">
  this might take a minute</p></span>
</div>
</div>
