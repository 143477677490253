<app-navbar></app-navbar>
<div class="container m-auto">
  <div class="row " >
    <header class="header">
      <div class="header__info">
        <!-- A resting <span> place that suits you</span> -->
        <h1 class="heading--0 header__heading"> titile </h1>
        <p class="paragraph"   >Our suites are made of the best failities to bring you the comfort you deserve.</p>
      </div>

      <div class="header__video-container">
        <img src="assets/images/hotels/4.jpg" class="header__video" alt="">
      </div>

      <div class="header__cta">
        <div class="header__cta-content">
          <span class="header__cta-title">ٍStart Date</span>
          <span class="header__cta-date"> 22/2/2023 </span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <span class="header__cta-title">End Date</span>
          <span class="header__cta-date"> 22/2/2023 </span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <span class="header__cta-title">Vacation Highlights</span>
          <span class="header__cta-date">5 nights accommodations</span>
          <i class="header__cta-icon"></i>
        </div>

        <div class="header__cta-content">
          <a  class = "btn btn--book">Book Now</a>
        </div>
        <div class="warningPriceMessage">
          <p style="color: red;">the 16 dining experiences on the resort</p>
        </div>
      </div>

    </header>

    <!-- ======= Our Services Section ======= -->
    <section id="services" class="services sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Our Details </h2>
          <!--            <p class="details-info">{{vacationDetails.title}} | Vacation Package <span class="color-info"> {{vacationDetails.number_of_nights}} nights from ${{' '+vacationDetails.price}} </span> </p> <br>-->
          <!--            <p class="details-info" style="color: red;">Book by: {{vacationDetails.book_by|date: 'EEEE, MMMM d, y'}}</p>-->
          <!--            <p class="details-info">The price above is for travel from June 25 - 29, 2023.</p>-->
        </div>

        <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">

          <div class="col-lg-4 col-md-6 ">
            <div class="service-item  position-relative">
              <div class="icon">
                <i class="bi bi-house-heart-fill"></i>
              </div>
              <h3>Vacation Highlights</h3>
              <ng-container>

                <p> <img src="assets/images/hotels/3.jpg"  width="25px"> <span class="px-2 mt-2">name</span> </p>

              </ng-container>
              <!--                <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>-->
            </div>
          </div><!-- End Service Item-->

          <div class="col-lg-4 col-md-6 ">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-chat-square-text"></i>
              </div>
              <h3>Special Notes</h3>
              <p class="bold"></p>
              <p class="bold">  <span> ENJOY </span> the Rockaway Bay Water Park, with 26 water slides.</p>
              <p>  <span> INDULGE </span>  in one of the 16 dining experiences on the resort</p>
            </div>
          </div><!-- End Service Item -->

          <div class="col-lg-4 col-md-6 ">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-info-square"></i>
              </div>
              <h3>Information</h3>
              <p class="bold">  <span> Check in : </span> 9:30 </p>
              <p class="bold">  <span> Check out : </span> 4:50</p>
              <p>  <span> Room Number </span> 532 </p>
            </div>
          </div><!-- End Service Item-->

        </div>

      </div>
    </section>
    <!-- End Our Services Section -->

      <!-- ======= Amenities Section ======= -->

      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="content  mb-4">
              <h3> Amenities </h3>
              <p class="my-4 handel-pAmenities">Hotel amenities cater to guests' every need, providing relaxation and convenience.</p>
            </div>
            <mat-accordion class="example-headers-align" multi="true">

              <mat-expansion-panel showToggle="true">
                <!-- *ngFor="let ami of aminities"  -->
                <mat-expansion-panel-header   >
                  aminties for aminties
                </mat-expansion-panel-header>
                <div class="row handel-amenities"  >
                  <div class="col-6" >
                    <p>- all</p>
                    <p>- all action </p>
                  </div>
                  <div class="col-6">
                    <p>- iPod docking</p>
                  </div>
                </div >
              </mat-expansion-panel>
              <mat-expansion-panel showToggle="true">
                <mat-expansion-panel-header>
                  Dining & Entertainment
                </mat-expansion-panel-header>
                <div class="row handel-amenities">
                  <div class="col-6">
                    <p>- Restaurant (10)</p>
                  </div>
                  <div class="col-6">
                    <p>- Room service</p>
                  </div>
                </div >
              </mat-expansion-panel>
              <mat-expansion-panel showToggle="true">
                <mat-expansion-panel-header>
                  Disabled Facilities
                </mat-expansion-panel-header>
                <div class="row handel-amenities">
                  <div class="col-12">
                    <p>- Elevator access</p>
                    <p>- Wheelchair access throughout hotel</p>
                    <p>- Wheelchair access to shower</p>
                  </div>

                </div >
              </mat-expansion-panel>
              <mat-expansion-panel showToggle="true">
                <mat-expansion-panel-header>
                  Leisure & Recreation
                </mat-expansion-panel-header>
                <div class="row handel-amenities">
                  <div class="col-6">
                    <p>- Swimming pool (2)</p>
                    <p>- Fitness centre on-site</p>
                  </div>
                  <div class="col-6">
                    <p>- Sauna</p>
                    <p>- Scuba diving</p>
                  </div>
                </div >
              </mat-expansion-panel>
              <mat-expansion-panel showToggle="true">
                <mat-expansion-panel-header>
                  Nearby
                </mat-expansion-panel-header>
                <div class="row handel-amenities">
                  <div class="col-6">
                    <p>- Beach</p>
                    <p>- City centre</p>
                  </div>
                  <div class="col-6">
                    <p>- Shopping</p>
                    <p>- Restaurants</p>
                  </div>
                </div >
              </mat-expansion-panel>

            </mat-accordion>
          </div>
          <div class="col-6">
            <app-google-map ></app-google-map>
          </div>
        </div>

      </div>

      <!-- End Amenities  Section -->

      <div class="section__img-box">
        <app-hotel-slider></app-hotel-slider>
      </div>
    <!-- </section> -->

  </div>
</div>
<div class="hotel-room">
  <app-hotel-room></app-hotel-room>
</div>

<app-villas></app-villas>

<app-footer></app-footer>


