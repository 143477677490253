<!-- <div *ngIf="!alternativeDatesResponse">
  <div class=" all-card col-12 p-0" >
    <div class="vt-placeholder-loading " *ngFor="let Loading of loadings" [style]="{height: Loading.width}">
      <div class="vt-animated-background-loading" [style]="{height: Loading.width}"></div>
    </div>
  </div>
</div> -->
<div class="loading" *ngIf="!alternativeDatesResponse">
  <div class="box-img" >
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
<div class="box-text" style="min-width: 70ch !important;">
  <div class="typewriter2">Please be patient while fetching your request <span class="fadein">...</span></div>
</div>
</div>

<div class="box-container">
  <div class="row position-relative overflow-auto " *ngIf="alternativeDatesResponse">
    <h3 class="Return mt-2"  *ngIf="tripType != 'OneWay' ">Return <img src="../../../assets/images/arrow/right-arrow2.png" width="20px"></h3>
    <h3 class="Depart" *ngIf="tripType != 'OneWay' " > Depart <img src="../../../assets/images/arrow/down-arrow.png" width="20px" > </h3>
    <!-- <i class="fas fa-times closeDialogBtn" (click)="close()"></i> -->
    <!-- <h2 class="handelBeforeGo">ِALTERNATIVE DATES</h2> --> 
    <table id="table" *ngIf="tripType =='Return'" class="table table-bordered table-responsive-sm">
      <!--*ngIf="tripType == 'RoundTrip' " -->
      <tr>
        <th class="table-head thead DepartBg">
            
        </th>
        <!-- <td class="notAllowedDate dates-cells return-{{returnDate}}"
            *ngFor="let length of getReturnDatesLength(returnDatesArray)">N/A
        </td> -->
        <td style="border: 2px solid #c8c8c8;" *ngFor="let itemReturnDate of returnDatesArray" class="dates-cells return-{{itemReturnDate}}">
          {{itemReturnDate | date: 'EEE,d, MMM '}}
        </td>
      </tr>
      <tr *ngFor="let depDateKey of departDatesArray">
        <td style="border: 2px solid #c8c8c8;" class="depart-{{depDateKey}}">{{depDateKey | date: 'EEE,d, MMM '}}</td>
        <!-- <td class="notAllowedDate depart-{{depDateKey}}"
            *ngFor="let length of getReturnDatesLength(depDates[depDateKey]['returnDates'])">N/A
        </td> -->
        <ng-container *ngFor="let returnDate of depDates[depDateKey]">
          <td style="border: 2px solid #c8c8c8;" *ngIf="returnDate != 0" (click)="selectDate(returnDate.TagID)" class="item-dates"
          [ngClass]="{'lowest-value': lowestPrice.prices?.ItinTotalFare == returnDate.prices?.ItinTotalFare}">
            <span class="air-carrier">
              <!-- <button class="top-right" mat-raised-button
               matBadgePosition="before" >
                {{getPrice(returnDate) | currency}}
            </button> -->

              <!-- <span class="contain">
                <img [title]="returnDate.flights[0][0].OperatingAirlineName" class="airCompanyLogo"
                  src="https://c.fareportal.com/n/common/air/3x/{{returnDate.flights[0][0].OperatingAirline}}.png"
                  alt="{{returnDate.flights[0][0].OperatingAirline}}">
              </span> -->
              <span class="PriceView">
                {{returnDate.prices.ItinTotalFare | currency}}
              </span>

            </span>
            <!-- <div class="container">
              <img title="Return"
              src="https://c.fareportal.com/n/common/air/3x/{{getAirCarrierCode(returnDate)}}.png"
              alt="{{getAirCarrierCode(returnDate)}}" width="70" height="70">
              <span class="top-left">{{getPrice(returnDate) | currency}}</span>
            </div> -->
          </td>
          <td style="border: 2px solid #c8c8c8;" *ngIf="returnDate == 0">
            -----
          </td>
        </ng-container>
      </tr>
    </table>
    <table id="table" *ngIf="tripType == 'OneWay' " class="table table-bordered table-responsive-sm">
      <th style="border: 2px solid #c8c8c8;height: 9vh;" class="table-head thead">
        <div >Depart</div>
        
      </th>
      <td style="border: 2px solid #c8c8c8;" class="table-head thead"> <div >Price</div></td>
      <tr  *ngFor="let depDateKey of departDatesArray">

        <td style="border: 2px solid #c8c8c8;" class="depart-{{depDateKey}}">{{depDateKey | date: 'EEE,d, MMM '}}</td>
        <!-- <td class="notAllowedDate depart-{{depDateKey}}"
            *ngFor="let length of getReturnDatesLength(depDates[depDateKey]['returnDates'])">N/A
        </td> -->
        <ng-container *ngFor="let returnDate of depDates[depDateKey]">

          <td style="border: 2px solid #c8c8c8;" *ngIf="returnDate != 0" (click)="selectDate(returnDate.TagID)" class="item-dates"
          [ngClass]="{'lowest-value': lowestPrice.prices?.ItinTotalFare == returnDate.prices?.ItinTotalFare}">
            <span class="air-carrier">
              <!-- <button class="top-right" mat-raised-button
               matBadgePosition="before" >
                {{getPrice(returnDate) | currency}}
            </button> -->

              <span class="contain">
                <!-- <img [title]="returnDate.flights[0][0].OperatingAirlineName" class="airCompanyLogo"
                  src="https://c.fareportal.com/n/common/air/3x/{{returnDate.flights[0][0].OperatingAirline}}.png"
                  alt="{{returnDate.flights[0][0].OperatingAirline}}"> -->
                  <!-- <P class="carrireName">{{returnDate.flights[0][0].OperatingAirlineName}}</P> -->
              </span>
              <span class="PriceView">
                {{returnDate.prices.ItinTotalFare | currency}}
              </span>

            </span>
            <!-- <div class="container">
              <img title="Return"
              src="https://c.fareportal.com/n/common/air/3x/{{getAirCarrierCode(returnDate)}}.png"
              alt="{{getAirCarrierCode(returnDate)}}" width="70" height="70">
              <span class="top-left">{{getPrice(returnDate) | currency}}</span>
            </div> -->
          </td>
          <td *ngIf="returnDate == 0">
            -----
          </td>
        </ng-container>
      </tr>
    </table>
    <div class="col-12 arrow-section">
      <i class="fas fa-arrow-left" (click)="scrollLeft()"></i>
      <i class="fas fa-arrow-right" (click)="scrollRight()"></i>
    </div>
    <div class="col-12 dates-indicator-container">
      <div class="carrier-name">
        <span class="title">Air Carrier</span>
        <span>-</span>
        <span class="value">KLM Royal Dutch Airlines</span>
      </div>
      <div *ngIf="tripType == 'RoundTrip' " class="dates-indicator">
        <span class="from-title">From</span>
        <span class="from-date"></span>
        <span class="dash">-</span>
        <span class="to-title">To</span>
        <span class="to-date"></span>
        <span class="number-of-days"></span>
      </div>
    </div>

  </div>
  
</div>
