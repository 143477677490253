import {DOCUMENT} from '@angular/common';
import {Component, OnInit, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {AppComponent} from '../../app.component';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-covid-state',
  templateUrl: './covid-state.component.html',
  styleUrls: ['./covid-state.component.css']
})
export class CovidStateComponent implements OnInit {
  state = '';
  country = '';
  location = '';
  locations: any = [];
  dataArrived = false;
  weekDays: any = {};
  currentDate = null;
  pageFragment = '';
  backendError = false;
  url: string;
  hostUrl: string;
  linkToBeShared: string;
  backgroundImage = AppComponent.hostUrl + '/assets/images/pcr-locations.jpg';
  // booleans
  close_status: boolean = true;

  constructor(
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private centerApis: HelpCenterServices,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  seoUpdate(metaData) {
    this.seoService.updateTitle(metaData['og:title']);
    this.seoService.updateDescription(metaData['og:description']);
    this.seoService.updateUrl(metaData['og:url']);
    if (metaData['og:image']) {
      this.seoService.updateImage(metaData['og:image']);
    } else {
      this.seoService.updateImage(this.backgroundImage);
    }
  }

  ngOnInit(): void {
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.url = this.router.url;
    this.state = this.activatedRoute.snapshot.params.state;
    this.country = this.activatedRoute.snapshot.params.country;
    this.location = this.activatedRoute.snapshot.params.location;
    this.currentDate = new Date().getMonth() + 1;
    this.linkToBeShared = `${hostUrl}${this.url}`;
    this.centerApis.getCovidLocations(this.state, this.country).subscribe(
      (res: any) => {
        if (res?.meta_data) {
          this.seoUpdate(res?.meta_data?.data);
          if (res?.meta_data?.data['og:image']) {
            this.backgroundImage = res?.meta_data?.data['og:image'];
          }
        } else {
          const metaData = {
            'og:title': `Locations to perform COVID-19 PCR TESTS in ${this.state}, ${this.country} | flyallover`,
            'og:description': `Find a testing center near you, learn how to get tested, and find key information about Locations to perform COVID-19 PCR TESTS in ${this.state}, ${this.country}`,
            'og:image': hostUrl + '/assets/images/pcr/pcr_meta.jpg'
          }
          this.seoUpdate(metaData);
        }
        this.locations = res.data;
        if (this.locations?.length === 0) {
          this.backendError = true;
          return;
        }
        this.workDaysSchedule();
        this.activatedRoute.fragment.subscribe((fragment: string) => {
          this.pageFragment = fragment;
          this.dataArrived = true;
          // setTimeout(() => {
          //   this.scrollToAnchor(this.pageFragment);
          // }, 0);
        });
      },
      (err) => {
        this.backendError = true;
      }
    );
  }

  scrollToAnchor(location: string, wait = 0): void {
    // const element = this.document.getElementById(location);
    const element = this.document.getElementById(location);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, wait);
    }
  }

  workDaysSchedule() {
    for (let i = 0; i < this.locations.length; i++) {
      this.weekDays[+i] = {};
      for (let ii = 0; ii < this.locations[i]?.regular_schedule?.length; ii++) {
        this.weekDays[+i][+this.locations[i]?.regular_schedule[ii]?.weekday] = this.locations[i]?.regular_schedule[ii];
      }
    }
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
