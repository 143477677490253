import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelsApisService} from 'src/app/services/hotels-apis.service';
import {ApisService} from 'src/app/services/apis.service';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {WindowService} from 'src/app/services/webApis/window.service';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  pageUrl;
  hotelCode: string;
  roomKey: string;
  hotelInfo: any = {};
  rate: number;
  hotelInfoArrived = false;
  loading = true;
  roomName: string;
  pricesInfo: any = {};
  hotelImg: string;
  adults;
  bookingKey: string;


  // imported this component to access fetchData method in ReviewTripForm1Component

  passengerFormData; // this will hold the return value of fetchData()

  // imported this component to access fetchData method in ReviewTripForm2Component

  billingFormData; // this will hold the return value of fetchData()

  checkbox1 = new FormControl('');
  checkbox2 = new FormControl('');
  checkbox3 = new FormControl('');

  spinner = false;
  submitted = false;
  // this is an array holding the number and type of passengers (for hotels only one adult)
  // passengerDetails = [{ PassengerType: "Adult", PassengerTypeCode: "ADT" }];
  passengerDetails = [];
  paymentMethodData: any; // an object containing data needed for create payment method API

  // for biller name and one of the passengers name
  billerMissmatch;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private seoService: SEOService,
    private route: ActivatedRoute,
    private hotelApis: HotelsApisService,
    private apis: ApisService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private metaService: MetaTagsService,
    private viewportScroller: ViewportScroller,
    private windowService: WindowService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    // getting the url
    this.activatedRoute.url.subscribe(
      (activeUrl) => {
        this.pageUrl = this.windowService.nativeWindow.location.pathname
      }
    );
    // getting roomKey from URL
    this.roomKey = this.route.snapshot.paramMap.get('roomKey');
    this.hotelApis.checkRoomPrice(this.roomKey).subscribe((data: any) => {
      this.bookingKey = data.data.PriceCheckInfo.BookingKey
      this.hotelInfo = data.data.PriceCheckInfo.HotelInfo;
      this.rate = parseInt(data.data.PriceCheckInfo.HotelInfo.SabreRating);
      this.roomName = data.data.PriceCheckInfo.HotelRateInfo.Rooms.Room[0].Name;
      if (data.data.PriceCheckInfo.HotelRateInfo.RateInfos.ConvertedRateInfo) {
        this.pricesInfo = data.data.PriceCheckInfo.HotelRateInfo.RateInfos.ConvertedRateInfo[0];
      } else {
        this.pricesInfo = data.data.PriceCheckInfo.HotelRateInfo.RateInfos.RateInfo[0];
      }
      this.hotelImg = this.localStorage.getItem('hotelImgUrl') || '';
      this.hotelInfoArrived = true;
      this.loading = false;
      setTimeout(() => {
        let _script = this.document.createElement('script');
        _script.src = './assets/js/index.js';
        this.document.head.appendChild(_script);
        let __script = this.document.createElement('script');
        __script.src = './assets/js/review-trip.js';
        this.document.head.appendChild(__script);
      }, 200);
     
    }, err => {
      console.log(err);
      this.hotelInfoArrived = false;
      this.loading = false;
    })

    let searchData = JSON.parse(this.localStorage.getItem('hotelSearchData')) || {};
    let adultsPassengers = searchData?.adults;
    let childrenPassengers = searchData?.children;
    for (let i = 0; i < adultsPassengers; i++) {
      this.passengerDetails.push({
        PassengerType: 'Adult',
        num: i,
        code: 'ADT'
      })
    }
    for (let i = 0; i < childrenPassengers; i++) {
      this.passengerDetails.push({
        PassengerType: 'Child',
        num: i,
        code: 'CNN'
      })
    }
    this.adults = searchData?.adults + searchData?.children;
  }

  seoUpdate() {

    this.seoService.updateTitle('Payment |Flyallover');
    this.seoService.updateDescription('All you payment detials with flyallover website will be protected and you will be save don\'t worry');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkBillerName() {
    let result;
    for (let i = 0; i < this.passengerFormData.first_name.length; i++) {
      let passengerName;
      if (this.passengerFormData.middle_name[i]) {
        passengerName = this.passengerFormData.first_name[i] + ' ' + this.passengerFormData.middle_name[i] + ' ' + this.passengerFormData.last_name[i]
      } else {
        passengerName = this.passengerFormData.first_name[i] + ' ' + this.passengerFormData.last_name[i]
      }
      // if (passengerName.length === this.billingFormData.nameCc.length) {

      let billingNames = this.billingFormData.nameCc.split(' ');
      let passengerNames = passengerName.split(' ');

      if (billingNames[0] === passengerNames[0] && billingNames[1] === passengerNames[1] && billingNames[2] === passengerNames[2]) {
        result = true;
        break;
      } else {
        result = false
      }

    }
    return result;
  }

  submitBook() {

    // fist check that the 3 checkboxes are checked
    if (this.checkbox1.value && this.checkbox2.value && this.checkbox3.value) {
      // this is an input to reviewtripform1 & reviewtripform2
      this.submitted = true; // the user clicked on the button, but if the forms are not valid, no action will take place

      // getting data from the forms
      this.passengerFormData = [];
      this.billingFormData = [];
      // the object that will hold billing data, traveller data, booking key and payment-intent key
      let formsData = {};
      // adding the booking key
      formsData['bookingKey'] = this.bookingKey;

      // if there is no data coming from billing form error will appear, if there is data assign them to formsData object

      if (!this.billingFormData) {
       // this.form2Component.submitError = 'This form is invalid please fill it correctly';
      } else {

     //   this.form2Component.submitError = '';
        // this data is what we need to call create payment method API
        this.paymentMethodData = {
          card_number: this.billingFormData.cardNumber,
          CreditCardExpireDate: this.billingFormData.expirationDateCc,
          cvc: this.billingFormData.ccv,
          name: this.billingFormData.nameCc,
          email: this.billingFormData.emailCc,
          amount: this.pricesInfo.AmountAfterTax,
          reservation_type: 'hotel'
        }
        formsData['amount'] = this.paymentMethodData.amount;
        formsData["email"] = this.billingFormData.emailCc;
        formsData["CreditCardCity"] = this.billingFormData.cityCc;
        formsData["cvc"] = this.billingFormData.ccv;
        formsData["creditCardstateOrProvince"] = this.billingFormData.stateOrProvince;
        formsData["state-code"] = this.billingFormData.stateCodeCc;
        formsData["CreditCardstreetAddress"] = this.billingFormData.addressLine1 + ' ' + this.billingFormData.addressLine2;
        formsData["cardHolderName"] = this.billingFormData.nameCc;
        formsData["CreditCardCountry"] = this.billingFormData.countryCc;
        formsData["CreditCardZip"] = this.billingFormData.zipCc;
        formsData["CreditCardVendorCode"] = this.billingFormData.vendorCode;
        formsData["CreditCardExpireDate"] = this.billingFormData.expirationDateCc;
        formsData["CreditCardNumber"] = this.billingFormData.cardNumber;
      }

      // checking traveller data if it exists
      if (!this.passengerFormData) {
        // this.form1Component.submitError = 'This form is invalid please fill it correctly';
      } else {
        // this.form1Component.submitError = '';
        for (let i = 0; i < this.passengerDetails.length; i++) {

          let indexInt = i + 1;
          let indexStr = indexInt.toString();
          formsData[indexStr] = {
            "PassengerType": this.passengerDetails[i].code,
            "GivenName": this.passengerFormData.first_name[i],
            "Surname": this.passengerFormData.last_name[i],
            "phone_type": "H",
            "phone": this.passengerFormData.phone[i],
            "traveller_gender": this.passengerFormData.gender[i],
            "traveller_dob": this.passengerFormData.birth_date[i],
            "country-code": this.passengerFormData.passport_info[i].issued_country,
            "nationality": this.passengerFormData.passport_info[i].nationality,
            "passport_number": this.passengerFormData.passport_info[i].passport_number,
            "expiry_date": this.passengerFormData.passport_info[i].expiryDate,
          };
        }
      }
      if (this.passengerFormData && this.billingFormData) {
        // this will check if 1 passenger then the billing form must have his name
        let billerMatching = this.checkBillerName()
        this.billerMissmatch = billerMatching
        if (billerMatching) {

          //////////////////////////////////////////////////////////////////////////
          this.spinner = true;
          // checking the validation of the credit card, if valid payment_intent field is added to formsData
          this.apis.createPaymentMethod(this.paymentMethodData).subscribe((data: any) => {

            // this.windowService.nativeWindow.open(data.next_action.redirect_to_url.url)
            formsData['passengerscount'] = this.passengerDetails.length;
            this.localStorage.setItem('FormsData', JSON.stringify(formsData))
            this.localStorage.setItem('roomKey', this.roomKey)
            this.windowService.nativeWindow.location.href = data.next_action.redirect_to_url.url;
          }, err => {
            this.spinner = false;
            if (err.error.code == 400) {
              // passing 0 if there is 400 error
              this.router.navigate(['hotel/confirmation'], {queryParams: {payment_intent: '0'}});
            } else {
              // passing  1 if there is another error
              this.router.navigate(['hotel/confirmation'], {queryParams: {payment_intent: '1'}});
            }
          })
        } else {
          this.router.navigate([`${this.pageUrl}`], {fragment: 'match'})
          // this.windowService.nativeWindow.scrollTo(0, 0)
        }
        /////////////////////////////////////////////////////////////////////////////////////////////
        // this.apis.createPaymentMethod(this.paymentMethodData).subscribe((data: any) => {
        //   // this.windowService.nativeWindow.location.href = data.next_action.redirect_to_url.url;
        //   // check that the control arrays have the same length as passengerDetails length
        //   // this.bookData["createPnr"] = creditCard;
        //   // this.bookData["staticResult"] = this.staticResult;
        //   // this.bookData["totalPrices"] = this.totalPrices;
        //   // this.bookData["access_token"] = this.cookie.get('flyallover_token');
        //   // this.spinner = false;
        //   // this.router.navigate(['confirmation'], { state: this.bookData })
        // }, err => {
        //   this.spinner = false;
        //   this.router.navigate['confirmation'];
        // })


      } else {
        this.spinner = false;
        // this.windowService.nativeWindow.scrollTo(0, 0);
        this.viewportScroller.scrollToPosition([0, 0])
      }
    }


  }

  scrollToElement($element) {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  modifyMetaTag() {
    this.metaService.modifyMetaTags({robots: 'noindex'})
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
